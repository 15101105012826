<div [ngClass]="'m-form-field rb-custom-input ' + customClass">
  <div class="a-dropdown position-relative" [class.no-label]="!label">
    <label *ngIf="label" [for]="id" class="rb-select-label">
      <rb-render-tpl [tpl]="label"></rb-render-tpl>
    </label>

    <div
      [rbDropdown]="selectMenu"
      [autoClose]="false"
      [id]="id"
      [class.disabled]="disabled"
      (blur)="onTouched()"
      [ngClass]="'rb-select-btn selector select-dropdown d-flex pb-0'"
      data-cy="dataSelectorInputDropdown"
    >
      <span class="flex-grow-1 text-truncate">
        <ng-container *ngFor="let pp of pathParts; let i = index">
          <span *ngIf="!pp.isArray">{{ i > 0 ? '.' : '' }}{{ pp.part }}</span>
          <a
            href="#"
            *ngIf="pp.isArray"
            (click)="handleArrayPathPartClick($event)"
            [rbDropdown]="partDropdown"
            [attr.data-cy]="'dataSelectorIterator-' + i"
          >
            <!-- ng-container prevents whitespace-->
            <ng-container>{{ i > 0 ? '.' : '' }}{{ pp.part }}</ng-container>
          </a>
          <ng-template #partDropdown>
            <a
              href="#"
              class="rb-dropdown-item"
              [class.active]="pp.isIterator"
              (click)="setIterate(i)"
            >
              {{ 'dataWidgets.dataSelector.iterateHere' | translate }}
            </a>
            <a
              href="#"
              class="rb-dropdown-item"
              [class.active]="pp.index === '' || pp.index === '0'"
              (click)="setStaticValue(pp, '0')"
            >
              {{ 'dataWidgets.dataSelector.static0' | translate }}
            </a>
            <a
              href="#"
              class="rb-dropdown-item"
              [class.active]="pp.index === '1'"
              (click)="setStaticValue(pp, '1')"
            >
              {{ 'dataWidgets.dataSelector.static1' | translate }}
            </a>
            <a
              href="#"
              class="rb-dropdown-item"
              [class.active]="pp.index === '2'"
              (click)="setStaticValue(pp, '2')"
            >
              {{ 'dataWidgets.dataSelector.static2' | translate }}
            </a>
            <a
              href="#"
              class="rb-dropdown-item"
              [class.active]="pp.index === '3'"
              (click)="setStaticValue(pp, '3')"
            >
              {{ 'dataWidgets.dataSelector.static3' | translate }}
            </a>
          </ng-template>
        </ng-container>
      </span>

      <span
        class="text-danger"
        *ngIf="showErrors && ((requireIteration && !hasIterator) || !isMatchingType)"
      >
        <ng-template #issues>
          <ng-container *ngIf="requireIteration && !hasIterator">
            {{ 'dataWidgets.dataSelector.noIterator' | translate }}
          </ng-container>
          <ng-container *ngIf="!isMatchingType">
            {{ 'dataWidgets.dataSelector.noTypeMatch' | translate }}
            <br />
          </ng-container>
        </ng-template>
        <span class="rb-ic rb-ic-alert-warning" [rbTooltip]="issues"></span>
      </span>

      <button
        type="button"
        class="rb-btn rb-link remove-btn"
        (click)="selectPath('')"
        *ngIf="!required && _path !== ''"
      >
        <span class="rb-ic rb-ic-close"></span>
      </button>
    </div>
  </div>

  <rb-notification-bar *ngIf="showValidationMessage && !showErrors" [type]="'error'">
    {{
      'processingPipeline.devicePositionStep.notificationMessages.requiredField'
        | translate: { fieldName: label }
    }}
  </rb-notification-bar>
</div>

<ng-template #selectMenu let-close="close">
  <app-data-structure-selector
    [paths]="paths"
    [selected]="_path"
    [allowedTypes]="allowedTypes"
    (selectedChange)="selectPath($event); close()"
  ></app-data-structure-selector>
</ng-template>
