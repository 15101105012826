import { NgModule } from '@angular/core';
import { ActionButtonComponent } from './action-button/action-button.component';
import { ActionLinkComponent } from './action-link/action-link.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  CalloutModule,
  FormFieldsModule,
  LoadingModule,
  PopoverModule
} from '@inst-iot/bosch-angular-ui-components';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetCommonsModule } from '../../dashboards/widget-commons/widget-commons.module';
import { FormsModule } from '@angular/forms';
import { ActionTableButtonComponent } from './action-table-button/action-table-button.component';
import { SharedModule } from '../../shared/shared.module';
import { HttpResponseStatusComponent } from './http-response-status/http-response-status.component';

@NgModule({
  declarations: [
    ActionButtonComponent,
    ActionLinkComponent,
    ActionTableButtonComponent,
    HttpResponseStatusComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    PopoverModule,
    CalloutModule,
    LoadingModule,
    TranslateModule,
    WidgetCommonsModule,
    FormFieldsModule,
    FormsModule,
    SharedModule
  ],
  exports: [
    ActionButtonComponent,
    ActionLinkComponent,
    ActionTableButtonComponent,
    HttpResponseStatusComponent
  ]
})
export class ActionButtonsModule {}
