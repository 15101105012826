import { OperatorType } from '../../query-condition-input/models/operator.model';
import {
  ConditionChip,
  SupportedPathType
} from '../../query-condition-input/models/condition-chip.model';
import {
  LogicalOperatorType,
  SyntaxNode
} from '../../query-condition-input/models/syntax-node.model';
import { Moment } from 'moment';

export interface DisableCondition {
  type: DisableConditionType;
  payload: DisableConditionPayload | LogicalOperatorType;
}

export enum DisableConditionType {
  LogicalOperator = 'logicalOperator',
  Condition = 'condition'
}

export interface DisableConditionPayload {
  propertyPath: string;
  operator: OperatorType;
  pathType: DisableConditionPathTypes;
  value: any;
}

export type DisableConditionPathTypes = Exclude<SupportedPathType, 'null' | 'objectId' | 'json'>;

export type DisableConditionValue = boolean | string | Date | number | Moment;

export interface DisableConditionChips {
  syntaxTree: SyntaxNode;
  chips: ConditionChip[];
}
