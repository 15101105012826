<rb-form-input
  [ngModel]="userMail"
  (ngModelChange)="updateValue($event)"
  [label]="label"
  [class.white]="white"
  maxlength="100"
  data-cy="userSelectorMailInput"
  [rbInitialOpen]="true"
  [rbFormInputAutocomplete]="getSearchFunc()"
  [rbAutocompleteList]="dropDownContent"
></rb-form-input>
<ng-template #dropDownContent let-active="active" let-focus="focus" let-select="select">
  <div *ngIf="!userList?.length" class="p-3">
    {{ 'users.searchUser.noUsersFound' | translate }}
  </div>
  <a
    href="#"
    class="rb-dropdown-item"
    *ngFor="let email of emails"
    (click)="select(email)"
    [attr.data-cy]="'userSelectorMailDropdown-' + email"
    [class.active]="active === label"
    [class.focus]="focus === label"
  >
    {{ email }}
  </a>
  <a
    href="#"
    class="rb-forward rb-link rb-dropdown-item"
    *ngIf="hasMore"
    (click)="$event.stopPropagation(); loadMore()"
  >
    {{ 'widget.users.loadMore' | translate }}
    <span class="rb-ic rb-ic-refresh rb-ic-spin" *ngIf="userListLoader.loading"></span>
  </a>
</ng-template>
