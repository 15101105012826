import { ValidationErrors } from '@angular/forms';
import { isEmpty } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { translate } from '../../../../../shared/translation-util';
import { DataLoaderRegistryService } from '../../services/data-loader-registry.service';
import { resolveParameter } from '../data-source-utils';
import { assignWithDefaults } from '../model-utils';
import { WidgetContext } from '../widget-context';
import { DataSource, dataSourceTypes } from './data-source-base';
import { DataSourceType, DsParameter } from './models';
import { ThingDataSource } from './thing';
import { DeviceTypesSelection } from '../../../data-source-config/device-types-selection';

export class ThingsCountDataSource extends DataSource {
  type: DataSourceType = 'things-count';
  label = 'Device Count';
  filter: DsParameter = null;
  selectedDeviceType = '';
  deviceTypes = [];
  namespace = '';

  constructor(props?: Partial<ThingsCountDataSource>) {
    super(props);
    assignWithDefaults(this, props);
  }

  validate(): ValidationErrors | null {
    // we have no validation here but still need to implement this abstract method from the super class
    return null;
  }

  loadData(ctx: WidgetContext): Observable<any[]> {
    const namespace = this.namespace.replace(/\s/g, '');

    let filter = this.filter ? resolveParameter(this.filter, ctx) : '';
    if (this.selectedDeviceType !== '' && isEmpty(this.deviceTypes)) {
      this.deviceTypes.push(this.selectedDeviceType);
      this.selectedDeviceType = '';
    }
    filter = DeviceTypesSelection.buildFilter(filter, this.deviceTypes);

    const devicesService = ThingDataSource.initDevicesService(ctx);
    const source = devicesService.countDevices(filter, namespace).pipe(map((count) => [{ count }]));
    const registryService = ctx.injector.get(DataLoaderRegistryService, null);
    return registryService
      ? source.pipe(
          registryService.registerDataSource(`thingCount:${filter}&${namespace}`, ctx.priority)
        )
      : source;
  }
}

dataSourceTypes.push({
  name: 'things-count',
  i18nLabel: translate('dataSourceConfig.thingsCount'),
  constructor: ThingsCountDataSource
});
