<div class="rb-custom-input not-empty">
  <div class="input-wrapper pt-3">
    <div class="label">
      <rb-render-tpl [tpl]="label"></rb-render-tpl>
    </div>
  </div>
</div>
<div class="d-flex pl-3 left-border">
  <value-input
    [dataType]="parameter.dataType"
    [label]="'queryTemplate.startValue' | translate"
    [validationRegExp]="parameter.valueRegex"
    [required]="!parameter.optional"
    [(value)]="fromValue"
    class="d-inline-block"
    style="flex-grow: 1"
  ></value-input>
  <value-input
    [dataType]="parameter.dataType"
    [label]="'queryTemplate.endValue' | translate"
    [validationRegExp]="parameter.valueRegex"
    [required]="!parameter.optional"
    [(value)]="toValue"
    class="d-inline-block"
    style="flex-grow: 1"
  ></value-input>
</div>
