<div>
  <span class="rb-ic rb-ic-security"></span>
  {{ 'validation.urlSchemeValidation.forbiddenUrlScheme' | translate }}
  <span class="allowed-schemes" [rbTooltip]="toolTip">
    {{ 'validation.urlSchemeValidation.allowedSchemes' | translate }}
  </span>
</div>

<ng-template #toolTip>
  <div class="p-3">
    <h5>{{ 'validation.urlSchemeValidation.allowedSchemes' | translate | ucfirst }}</h5>
    <ng-container *ngFor="let scheme of schemes">
      <div>{{ scheme }}:</div>
    </ng-container>
  </div>
</ng-template>
