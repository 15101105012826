/**
 * Used in Role checks.
 * If the project is empty or falsy, the current project is used.
 */

export type RoleName =
  | string
  | 'owner'
  | 'admin'
  | 'manager'
  | 'power_user'
  | 'user'
  | 'data_provider';
export const excludeAccessAndCustomRoles: RoleName[] = [
  'owner',
  'admin',
  'manager',
  'power_user',
  'user',
  'data_provider'
];

export class ProjectRole {
  constructor(public project: string, public role: RoleName) {}

  toString(): string {
    return `${this.project}_${this.role}`;
  }

  static user(project?: string): ProjectRole {
    return new ProjectRole(!project ? '' : project, 'user');
  }

  static owner(project?: string): ProjectRole {
    return new ProjectRole(!project ? '' : project, 'owner');
  }

  static admin(project?: string): ProjectRole {
    return new ProjectRole(!project ? '' : project, 'admin');
  }

  static manager(project?: string): ProjectRole {
    return new ProjectRole(!project ? '' : project, 'manager');
  }

  static powerUser(project?: string): ProjectRole {
    return new ProjectRole(!project ? '' : project, 'power_user');
  }

  static dataProvider(project?: string): ProjectRole {
    return new ProjectRole(!project ? '' : project, 'data_provider');
  }

  static access(project?: string): ProjectRole {
    return new ProjectRole(!project ? '' : project, 'access');
  }

  static excludeAccessAndCustomProjectRoles(project?: string): ProjectRole[] {
    return excludeAccessAndCustomRoles.map((role) => new ProjectRole(project, role));
  }
}
