import { DataConditionalFormattingModule } from '../data-conditional-formatting/data-conditional-formatting.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { DataSelectionModule } from '../data-selection/data-selection.module';
import { ValueFormattingComponent } from './value-formatting.component';

@NgModule({
  declarations: [ValueFormattingComponent],
  exports: [ValueFormattingComponent],
  imports: [CommonModule, SharedModule, DataConditionalFormattingModule, DataSelectionModule]
})
export class ValueFormattingModule {}
