import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { ProjectsService } from '../../shared-projects/services/projects.service';
import { concat, Observable } from 'rxjs';
import { AZURE_ERROR_CODE, RestReqDefinition } from './models/rest-request.model';
import { executeDownload } from '../../shared/download-utils';
import { objectIdSyntax } from '../query-condition-input/utils/filter-definitions';
import { catchError } from 'rxjs/operators';
import { readBlob } from '../action-buttons/action-config-util';
import { UserAuthService } from '../../core/services/user-auth.service';
import { handleAzureSessionError } from './utils/azure-session-error.util';
import { GlobalAlertService } from '../../core/services/global-alert.service';

export interface RestRequestRun {
  context?: any;
  data?: any;
}

@Injectable({
  providedIn: 'root'
})
export class RestRequestService {
  static readonly HEADER_RESPONSE_CACHED_RESULT_ID = 'X-Cached-Rest-Request-Id';
  static readonly HEADER_REQUEST_CACHED_RESULT = 'X-Cache_time';

  constructor(
    private http: HttpClient,
    private projectsService: ProjectsService,
    private alertService: GlobalAlertService,
    private userAuthService: UserAuthService
  ) {}

  get baseUrl() {
    return `/project-management-service/v1/${this.projectsService.projectName}`;
  }

  getRestRequestDefinitionUrl(id = null): string {
    let url = `${this.baseUrl}/rest-request-definition`;
    if (id) {
      url = `${url}/${id}`;
    }
    return url;
  }

  getRestRequestExecutionUrl(id = null): string {
    return this.getRestRequestDefinitionUrl(id) + '/execute';
  }

  getRestRequestCachedResultUrl(id = null): string {
    return this.getRestRequestDefinitionUrl(id) + '/result-from-cache';
  }

  createRestRequestDefinition(data: RestReqDefinition): Observable<RestReqDefinition> {
    return this.http.post<RestReqDefinition>(this.getRestRequestDefinitionUrl(), data);
  }

  getRestRequestDefinition(id: string): Observable<RestReqDefinition> {
    return this.http.get<RestReqDefinition>(this.getRestRequestDefinitionUrl(id));
  }

  deleteRestRequestDefinition(id: string): Observable<null> {
    return this.http.delete<null>(this.getRestRequestDefinitionUrl(id));
  }

  getCachedRestRequestResponse(id: string) {
    return this.http.get(this.getRestRequestCachedResultUrl(id), {
      observe: 'response',
      responseType: 'blob'
    });
  }

  downloadCachedRestRequestResponse(objectStoragePath = '') {
    const storageId = objectStoragePath.match(objectIdSyntax);

    if (storageId.length) {
      const url = this.getRestRequestCachedResultUrl(storageId[0]);
      executeDownload(url);
    }
  }

  updateRestRequestDefinition(id: string, data: RestReqDefinition): Observable<RestReqDefinition> {
    return this.http.put<RestReqDefinition>(this.getRestRequestDefinitionUrl(id), data);
  }

  executeRestRequestDefinition(id: string, data: RestRequestRun, options = {}): Observable<any> {
    return this.http.post(this.getRestRequestExecutionUrl(id), data, options);
  }

  executeRestRequestDefinitionFromCache(
    id: string,
    data: RestRequestRun,
    cacheTime: number
  ): Observable<HttpResponse<any>> {
    let headers = {};
    if (cacheTime) {
      headers = { [RestRequestService.HEADER_REQUEST_CACHED_RESULT]: String(cacheTime) };
    }
    return this.executeRestRequestDefinition(id, data, {
      headers,
      observe: 'response'
    });
  }

  executeRestRequestDefinitionWithObserverWithCache(
    id: string,
    data: RestRequestRun,
    cacheTime: number
  ): Observable<HttpResponse<Blob>> {
    let headers = {};
    if (cacheTime) {
      headers = { [RestRequestService.HEADER_REQUEST_CACHED_RESULT]: String(cacheTime) };
    }
    return this.executeRestRequestDefinitionWithObserver(id, data, headers);
  }

  executeRestRequestDefinitionWithObserver(
    id: string,
    data: RestRequestRun,
    headers = {}
  ): Observable<HttpResponse<Blob>> {
    return this.http
      .post(this.getRestRequestExecutionUrl(id), data, {
        observe: 'response',
        responseType: 'blob',
        headers
      })
      .pipe(
        catchError((error: any) => {
          if (error?.status === 440) {
            if (error.error instanceof Blob) {
              readBlob(error.error).then((message) => {
                if (message?.includes(AZURE_ERROR_CODE)) {
                  handleAzureSessionError(error, this.alertService, this.userAuthService);
                }
              });
            }
            if (typeof error?.error === 'string' && error?.error?.includes(AZURE_ERROR_CODE)) {
              this.userAuthService.performLoginAndRetainLocation();
            }
          }
          if (error?.status === HttpStatusCode.NotModified) {
            const cacheID = error.headers.get(RestRequestService.HEADER_RESPONSE_CACHED_RESULT_ID);
            return concat(
              this.getCachedRestRequestResponse(cacheID),
              this.executeRestRequestDefinitionWithObserver(id, data)
            );
          } else {
            throw error;
          }
        })
      );
  }

  createOrUpdateRestRequestDefinition(
    restRequestDefinition: RestReqDefinition
  ): Observable<RestReqDefinition> {
    if (restRequestDefinition.id) {
      return this.updateRestRequestDefinition(restRequestDefinition.id, restRequestDefinition);
    } else {
      return this.createRestRequestDefinition(restRequestDefinition);
    }
  }
}
