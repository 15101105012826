import { Component } from '@angular/core';
import { Constants } from '../../../constants';

@Component({
  selector: 'logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent {
  routing = Constants.routing;
}
