<div class="main-bg">
  <div class="container d-flex justify-content-center" *rbLoading="candidateLoader">
    <div class="col-8 text-center">
      <h1 class="mt-3 componentHeader u-TextColor--darkBlue">
        {{ 'userInfo.userMigrationTitle' | translate }}
      </h1>

      <ng-container *ngIf="!mergeCandidate">
        <p>{{ 'userInfo.userMigrationNoCandidate' | translate }}</p>
        <a [routerLink]="routing.home" class="rb-btn">
          {{ 'generalConfig.backToHome' | translate }}
        </a>
      </ng-container>
      <ng-container *ngIf="mergeCandidate">
        <p class="subline-scaled">
          {{ 'userInfo.userMigrationIntroduction' | translate }}
          {{ mergeCandidate.email }}
        </p>

        <rb-callout type="warning">
          {{ 'userInfo.userMigrationWarningText' | translate }}
        </rb-callout>
        <p>{{ 'userInfo.userMigrationProceed' | translate }}</p>
        <p>
          <button
            type="button"
            class="rb-btn rb-primary"
            [disabled]="migrationFailed"
            (click)="migrate()"
          >
            {{ 'userInfo.migrateButton' | translate }}
          </button>
        </p>
        <rb-callout type="error" *ngIf="migrationFailed">
          {{ 'userInfo.userMigrationFailed' | translate }}&nbsp;
          <a href="https://bosch-iot-insights.atlassian.net/servicedesk/customer/portal/2">
            {{ 'userInfo.userMigrationContactSupport' | translate }}
          </a>
          .
        </rb-callout>
      </ng-container>
    </div>
  </div>
</div>
