import { QueryTemplateParameter } from '../../../shared/api-model';
import {
  convertDataTypeForParameterValue,
  convertParameterType,
  createDefaultValue
} from './query-parameter-converter';
import { ParameterType } from '../../../shared-query/services/query.service';

export class QueryParameter {
  /**
   * The initial value
   */
  value: any;

  /**
   * Whether the parameter is provided at all
   * Used i.e. for Query Parameter Validation in Template Runner
   */
  isSet = false;

  /**
   * Whether choices can be offered of the type of the parameter
   */
  choicesVisible = false;

  constructor(public parameter: QueryTemplateParameter) {
    if (this.parameter.defaultValue !== null) {
      this.value = this.parameter.defaultValue;
    } else if (!this.parameter.optional) {
      this.value = createDefaultValue(this.parameter.dataType, this.parameter.parameterType);
    }
    this.detectSet();

    this.choicesVisible = this.parameter.parameterType === 'SCALAR';
  }

  /**
   * Returns the value, that is entered as string as correct JS data type.
   */
  static getValueByType(value: any, dataType: string) {
    if (dataType === 'INT') {
      return parseInt(value, 10);
    }
    if (dataType === 'FLOAT') {
      return parseFloat(value);
    }
    return value;
  }

  updateParameterType(newType: ParameterType) {
    const oldType = this.parameter.parameterType;
    if (oldType === newType) {
      return;
    }
    this.parameter.parameterType = newType;
    this.value = convertParameterType(this.value, this.parameter.dataType, oldType, newType);
    this.choicesVisible = this.parameter.parameterType === 'SCALAR';
    this.resetChoices();
    if (!this.parameter.nullable || this.parameter.defaultValue !== null) {
      this.parameter.defaultValue = convertParameterType(
        this.parameter.defaultValue,
        this.parameter.dataType,
        oldType,
        newType
      );
    }
  }

  updateDataType(newType) {
    const oldType = this.parameter.dataType;
    if (oldType === newType) {
      return;
    }
    this.parameter.dataType = newType;
    this.value = convertDataTypeForParameterValue(
      this.value,
      oldType,
      newType,
      this.parameter.parameterType
    );
    if (this.parameter.valueChoices) {
      this.parameter.valueChoices.forEach((vc) => {
        vc.value = convertDataTypeForParameterValue(
          vc.value,
          oldType,
          newType,
          this.parameter.parameterType
        );
      });
    }
    if (!this.parameter.nullable || this.parameter.defaultValue !== null) {
      this.parameter.defaultValue = convertDataTypeForParameterValue(
        this.parameter.defaultValue,
        oldType,
        newType,
        this.parameter.parameterType
      );
    }
  }

  updateNullable(newValue: boolean) {
    this.parameter.nullable = newValue;
    if (!newValue) {
      this.value = this.generateDefaultValue();
      this.parameter.defaultValue = this.generateDefaultValue();
      if (this.parameter.valueChoices) {
        this.parameter.valueChoices.forEach((vc) => {
          if (vc.value === null) {
            vc.value = this.generateDefaultValue();
          }
        });
      }
    }
  }

  updateValue(v: any) {
    this.value = v;
    this.detectSet();
  }

  detectSet() {
    let isSet = false;
    if (this.parameter.optional && (this.value === null || this.value === undefined)) {
      isSet = false;
    } else if (this.parameter.nullable && this.value === null) {
      isSet = true;
    } else if (this.parameter.parameterType === 'LIST' && this.value && this.value.length) {
      isSet = true;
    } else if (
      this.parameter.parameterType === 'MAP' &&
      this.value &&
      Object.keys(this.value).length
    ) {
      isSet = true;
    } else if (
      this.parameter.parameterType === 'SCALAR' &&
      this.parameter.dataType === 'STRING' &&
      this.value.length
    ) {
      isSet = true;
    } else if (this.parameter.parameterType === 'SCALAR' && this.parameter.dataType !== 'STRING') {
      isSet = true;
    } else if (
      this.parameter.parameterType === 'RANGE' &&
      this.value &&
      this.value.from !== undefined &&
      this.value.to !== undefined
    ) {
      isSet = true;
    } else if (
      this.parameter.parameterType === 'QUERY' &&
      (Object.keys(this.value).every((val) => !!val) || this.parameter.optional)
    ) {
      isSet = true;
    }
    this.isSet = isSet;
  }

  generateDefaultValue() {
    return createDefaultValue(this.parameter.dataType, this.parameter.parameterType);
  }

  resetChoices() {
    this.parameter.valueChoices = null;
  }
}
