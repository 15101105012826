import { Pipe, PipeTransform } from '@angular/core';
import { LanguagesService } from '../../core/services/languages.service';
import { transformDate } from '../date-pipe-util';

@Pipe({
  name: 'date',
  pure: true
})
export class DatePipe implements PipeTransform {
  constructor(private languagesService: LanguagesService) {
    this.languagesService.updateTZ(); // needs to be called here to be sure DatePipe and LanguagesService use the same moment instance
  }

  transform(value: any, format = 'mediumDate', disableTimezoneConversion = false): any {
    return transformDate(value, format, disableTimezoneConversion);
  }
}
