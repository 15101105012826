import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UserPending } from '../models/user-pending.model';
import { User } from '../models/user.model';

@Injectable()
export class UserAddService {
  get onUserInvited$(): Observable<UserPending | true> {
    return this._onUserInvited$;
  }

  private _onUserInvited$ = new Subject<UserPending | true>();

  get onApiUserCreated$(): Subject<User> {
    return this._onApiUserCreated$;
  }

  private _onApiUserCreated$ = new Subject<User>();

  notifyUserInvite(userPending: UserPending | true): void {
    this._onUserInvited$.next(userPending);
  }

  notifyApiUserCreated(apiUser: User): void {
    this._onApiUserCreated$.next(apiUser);
  }
}
