import { FieldConfig } from '../../../dashboards/widgets/input-widget/models/input.model';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterParameterConfig } from '../../../dashboards/widgets/filter-widget/filter-widget.model';
import { DsParameter } from '../../data-widgets/data-widgets-common';
import { PathInfo } from '../data-info-util';
import { SelectType } from '../models/select-types.model';

export interface StyleSelection {
  background?: DsParameter;
  color?: DsParameter;
  'border-color'?: DsParameter;
}
@Component({
  selector: 'style-selection',
  templateUrl: './style-selection.component.html'
})
export class StyleSelectionComponent {
  @Input() style: StyleSelection;
  @Input() filterParameters: FilterParameterConfig[] = [];
  @Input() inputParameters: FieldConfig[] = [];
  @Input() paths: PathInfo[] = [];

  @Input() hideBorderColor = false;
  @Output() styleChange = new EventEmitter<StyleSelection>();

  selectType = SelectType;

  get borderColor() {
    return this.style && this.style['border-color'];
  }

  resetStyleAttribute(attribute: 'backgroundColor' | 'textColor' | 'border-color') {
    switch (attribute) {
      case 'backgroundColor':
        this.style.background = null;
        break;
      case 'textColor':
        this.style.color = null;
        break;
      case 'border-color':
        this.style['border-color'] = null;
        break;
    }
  }

  changeColorStyle(style: { [key: string]: DsParameter }) {
    this.styleChange.emit({
      ...this.style,
      ...style
    });
  }
}
