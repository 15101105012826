import { AccessRightChoice } from '../../devices/models/device-access-right-choice';
import { Role } from '../../project-admin/role/models/role.model';
import { AccessRight } from './access-right.model';

export class ObjectAcl {
  readers: string[];
  writers: string[];

  constructor(acl: Partial<ObjectAcl> = {}) {
    const { readers = [], writers = [] } = acl;

    this.readers = readers;
    this.writers = writers;

    return this;
  }

  /**
   * @example
   * // returns READ_WRITE
   * ObjectAcl.mapObjectAclToChoice(['roleName:manager', 'roleName:user], ['roleName:manager'], {roleName: 'manager'});
   */
  static mapObjectAclToChoice(readers: string[], writers: string[], role: Role): AccessRightChoice {
    if (role.roleName === 'admin') {
      return AccessRightChoice.readWrite;
    }

    if (!readers && !writers) {
      return AccessRightChoice.noAccess;
    }

    let choice: AccessRightChoice = AccessRightChoice.noAccess;
    readers.forEach((reader) => {
      const _role = ObjectAcl.mapRoleFromAclString(reader);
      if (_role.roleName === role.roleName) {
        const found = writers.find((writer) => writer === reader);
        choice = found ? AccessRightChoice.readWrite : AccessRightChoice.read;
      }
    });
    return choice;
  }

  /**
   * @example
   * // returns { roleName: 'data_provider', projectPrefix: 'proj1234' }
   * ObjectAcl.mapRoleFromAclString('roleName:proj1234.data_provider')
   */
  static mapRoleFromAclString(role: string): Role {
    if (role.indexOf('.') === -1) {
      const roleName = role.split('roleName:')[1];
      return new Role({ roleName });
    } else {
      const removedRoleName = role.replace('roleName:', '');
      const projectPrefix = removedRoleName.split('.')[0];
      const roleName = removedRoleName.split('.')[1];
      return new Role({ roleName, project: projectPrefix });
    }
  }

  /**
   * @example
   * // returns ['my-role', 'manager']
   * ObjectAcl.extractRoleNamesFromAclString(['roleName:proj1234.my-role', 'roleName:manager'])
   */
  static extractRoleNamesFromAclString(roles: string[]): string[] {
    return roles.map((role) => ObjectAcl.mapRoleFromAclString(role).roleName);
  }

  /**
   * @example
   * // returns 'roleName:proj1234.data_provider'
   * ObjectAcl.mapAccessRightToAclString({ roleName: 'data_provider', projectPrefix: 'proj1234' })
   */
  static mapAccessRightToAclString(accessRight: AccessRight): string {
    return `roleName:${accessRight.role?.project ? accessRight.role.project + '.' : ''}${
      accessRight.role.roleName
    }`;
  }

  /**
   * @example
   * // returns {readers: ['roleName:1234project.myRole'], writers: ['roleName:1234project.myRole']}
   * ObjectAcl.mapAccessRightsToObjectAcl([{
   *   role: {roleName: 'myRole', projectPrefix: '1234project'},
   *   accessRight: READ_WRITE,
   *   hasThisRole: true,
   *   isAdminRole: false,
   * }])
   */
  static mapAccessRightsToObjectAcl(accessRights: AccessRight[]): ObjectAcl {
    const acl = new ObjectAcl();
    acl.writers = ObjectAcl.mapAccessRightsToRoleNames(accessRights, AccessRightChoice.readWrite);
    acl.readers = acl.writers.concat(
      ObjectAcl.mapAccessRightsToRoleNames(accessRights, AccessRightChoice.read)
    );
    return acl;
  }

  /**
   * @example
   * // returns ['roleName:1234project.myRole']
   * ObjectAcl.mapAccessRightsToRoleNames([
   * {
   *   role: {roleName: 'myRole', projectPrefix: '1234project'},
   *   accessRight: READ_WRITE,
   * },
   * {
   *   role: {roleName: 'user', projectPrefix: ''},
   *   accessRight: READ,
   * }
   * ], READ_WRITE)
   */
  static mapAccessRightsToRoleNames(
    accessRights: AccessRight[],
    filter: AccessRightChoice
  ): string[] {
    return accessRights
      .filter(
        (accessRight) => accessRight.accessRight === filter && accessRight.role.roleName !== 'admin'
      )
      .map((accessRight) => ObjectAcl.mapAccessRightToAclString(accessRight));
  }
}
