import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpStatusCode
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DetailedError } from '@inst-iot/bosch-angular-ui-components';
import { TranslateService } from '@ngx-translate/core';
import {
  EMPTY,
  MonoTypeOperatorFunction,
  Observable,
  of,
  Subscriber,
  throwError,
  timer
} from 'rxjs';
import {
  catchError,
  expand,
  filter,
  finalize,
  map,
  mergeMap,
  skipWhile,
  switchMap,
  take
} from 'rxjs/operators';
import { ProjectsService } from '../../shared-projects/services/projects.service';
import {
  AggregationParametersRequest,
  CancelQueryResult,
  CombinedQueryResult,
  ObjectAclReq,
  QueryTemplate,
  QueryTemplateExecutionParameters,
  QueryTemplateParameters,
  RequestStatusComposite
} from '../../shared/api-model';
import { QueryConfig } from '../models/query-config';
import { errorStates } from '../models/query-states';
import { workerScheduler } from './worker-scheduler';
import { createTemplateForTimeseries } from '../../explore/query-template/query-template-timeseries';
import { RequestModel } from '../models/request';
import { executeDownload } from '../../shared/download-utils';

/**
 * Contains End points of V2 Queries
 */
export enum QueryEndPointTypes {
  Aggregation = 'aggregation-query',
  Find = 'find-query',
  Count = 'count-query',
  Distinct = 'distinct-query'
}

export type DataType = 'STRING' | 'TIMESTAMP' | 'BOOLEAN' | 'INT' | 'FLOAT' | 'DEVICE' | 'JSON';
/*
 * ParameterType QUERY is hidden in the UI.
 * Automatically set and used for the JSON DataType
 * */
export type ParameterType = 'SCALAR' | 'RANGE' | 'LIST' | 'MAP' | 'QUERY';

@Injectable({ providedIn: 'root' })
export class QueryService {
  dataTypes: DataType[] = ['STRING', 'TIMESTAMP', 'BOOLEAN', 'INT', 'FLOAT', 'DEVICE', 'JSON'];

  parameterTypes: ParameterType[] = ['SCALAR', 'RANGE', 'LIST', 'MAP'];
  private lastCancelledQueryId: string;

  constructor(
    private http: HttpClient,
    private projectsService: ProjectsService,
    private translateService: TranslateService
  ) {}

  get baseUrl() {
    return '/mongodb-query-service/v2/' + this.projectsService.projectName;
  }

  getQueriesUrl(id = null): string {
    let url = this.baseUrl + '/queries';
    if (id) {
      url += '/' + id;
    }
    return url;
  }

  getTemplatesUrl(id = null): string {
    let url = this.baseUrl + '/query-templates';
    if (id) {
      url += '/' + id;
    }
    return url;
  }

  getQueries(page: number, pageSize: number): Observable<HttpResponse<RequestModel[]>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());

    return this.http.get<RequestModel[]>(this.getQueriesUrl(), {
      params: params,
      observe: 'response'
    });
  }

  getQueriesFromParams(params: HttpParams): Observable<HttpResponse<RequestModel[]>> {
    return this.http.get<RequestModel[]>(this.getQueriesUrl(), {
      params: params,
      observe: 'response'
    });
  }

  deleteQuery(id: string): Observable<string> {
    // TODO check when this is available again in the backend
    return this.http.delete(this.getQueriesUrl(id), { responseType: 'text' });
  }

  deleteAll(): Observable<any> {
    const bseUrl = this.baseUrl + '/queries';
    return this.http.delete<any>(bseUrl, { observe: 'response' });
  }

  createAggregateQuery(
    aggregate: any[],
    collection: string,
    tag = null,
    sample = false,
    hint = null
  ): Observable<RequestStatusComposite> {
    const data: AggregationParametersRequest = {
      query: aggregate,
      sample: sample,
      collection: collection,
      tag: tag,
      hint: hint
    };
    return this.http.post<RequestStatusComposite>(
      this.baseUrl + '/' + QueryEndPointTypes.Aggregation,
      data
    );
  }

  getQuery(id: string): Observable<RequestModel> {
    return this.http.get<RequestModel>(this.getQueriesUrl(id));
  }

  getQueryStatus(id: string): Observable<RequestStatusComposite> {
    return this.http.get<RequestStatusComposite>(this.getQueriesUrl(id) + '/status');
  }

  getQueryResult(id: string): Observable<HttpEvent<any>> {
    return this.http.get<any>(this.getQueriesUrl(id) + '/result', {
      reportProgress: true,
      observe: 'events'
    });
  }

  runQueryWithResult(aggregate: any[], collection: string, tag = null): Observable<any> {
    const queryConfig = new QueryConfig({
      type: QueryEndPointTypes.Aggregation,
      query: aggregate,
      collection,
      tag
    });
    return this.runQueryConfig(queryConfig).pipe(
      skipWhile((status) => status.result === undefined),
      map((status) => {
        return status.result;
      })
    );
  }

  runQueryForDownload(aggregate: any[], collection: string, tag = null): Observable<string> {
    return this.runQuery(aggregate, collection, tag, false).pipe(
      skipWhile((status) => status.query.status !== 'SUCCESSFUL'),
      map((status) => {
        return this.getQueriesUrl(status.query.requestId) + '/result';
      })
    );
  }

  runQuery(
    aggregate: any[],
    collection: string,
    tag = null,
    fetchResult = true
  ): Observable<CombinedQueryResult> {
    return this.runQueryWithPolling(
      this.createAggregateQuery(aggregate, collection, tag),
      fetchResult
    );
  }

  /**
   * Run the query config returning a stream of events {CombinedQueryResult}
   * It starts with a sync request, that can return a 202 response if the query takes longer,
   * then the service starts pulling.
   */
  runQueryConfig(config: QueryConfig, fetchResult = true): Observable<CombinedQueryResult> {
    if (!fetchResult) {
      const queryRequestStatus = this.submitQueryRequestForConfig(config);
      return this.runQueryPolling(queryRequestStatus, fetchResult);
    }
    const queryRequest = this.executeQueryRequestForConfig(config);
    return this.runQueryWithPossibleAsyncResponse(queryRequest);
  }

  fetchCachedQueryResult(historyId: string): Observable<CombinedQueryResult> {
    return this.runQueryPolling(of({ requestId: historyId, status: 'SUCCESSFUL' }), true);
  }

  private executeQueryRequestForConfig(config: QueryConfig): Observable<HttpEvent<string>> {
    let headers = new HttpHeaders();

    headers = headers.append('X-accepts_incomplete', 'true');

    if (config?.cacheTime) {
      headers = headers.append('X-cache_time', String(config.cacheTime));
    }

    if (config.templateId) {
      return this.http.post(
        this.getTemplatesUrl(config.templateId) + '/execute-query',
        config.getQueryTemplateParameter(),
        {
          headers: headers,
          observe: 'events',
          responseType: 'text'
        }
      );
    } else {
      const parameters = config.getQueryParameters();
      return this.http.post(this.baseUrl + '/execute-' + config.type, parameters, {
        headers: headers,
        observe: 'events',
        responseType: 'text'
      });
    }
  }

  private submitQueryRequestForConfig(config: QueryConfig): Observable<RequestStatusComposite> {
    if (config.templateId) {
      return this.http.post(
        this.getTemplatesUrl(config.templateId) + '/submit-query',
        config.getQueryTemplateParameter()
      );
    } else {
      const parameters = config.getQueryParameters();
      return this.http.post(this.baseUrl + '/submit-' + config.type, parameters);
    }
  }

  private runQueryWithPossibleAsyncResponse(
    queryRequest: Observable<HttpEvent<string>>
  ): Observable<CombinedQueryResult> {
    return queryRequest.pipe(
      filter((event) =>
        [HttpEventType.Response, HttpEventType.DownloadProgress].includes(event.type)
      ),
      switchMap((event) => {
        if (event.type === HttpEventType.Response && event.status === HttpStatusCode.Accepted) {
          const queryStatus: RequestStatusComposite = {
            requestId: parseResponseBody<RequestStatusComposite>(event).requestId,
            status: 'PENDING'
          };
          return this.runQueryWithPolling(of(queryStatus));
        }
        if (event.type === HttpEventType.DownloadProgress) {
          const queryStatus: RequestStatusComposite = {
            requestId: null,
            status: 'PENDING'
          };
          return of({
            query: queryStatus,
            result: undefined,
            loaded: event.loaded
          });
        }

        if (event.type === HttpEventType.Response && event.status === HttpStatusCode.Ok) {
          const queryStatus: RequestStatusComposite = {
            requestId: event.headers.get('X-QUERY-ID') ?? null,
            status: 'FINISHED'
          };
          return of({
            query: queryStatus,
            result: parseResponseBody(event),
            loaded: event.body.length,
            isCachedResult: false
          });
        }
        throw new DetailedError(`Unexpected query error`, event);
      }),
      catchError((httpErrorResponse: DetailedError | HttpErrorResponse) => {
        const isHttpError = httpErrorResponse instanceof HttpErrorResponse;
        if (isHttpError && httpErrorResponse.status === HttpStatusCode.NotModified) {
          const location = httpErrorResponse.headers.get('location');
          const id = location ? location.match('/queries/([a-zA-Z0-9]+)') : '';
          const lastCached = httpErrorResponse.headers.get('lastcached');

          return this.runQueryPolling(
            of({ requestId: id?.length ? id[1] : '', status: 'SUCCESSFUL' }),
            true
          ).pipe(map((res) => ({ ...res, isCachedResult: true, lastCached })));
        }
        throw httpErrorResponse;
      })
    );
  }

  runQueryByTemplateWithResult(
    templateId: string,
    values: any,
    tag = null
  ): Observable<CombinedQueryResult> {
    return this.runQueryWithPolling(
      this.submitQueryTemplate(templateId, {
        parameterValues: values,
        tag: tag
      }),
      true
    ).pipe(skipWhile((status) => status.result === undefined));
  }

  /**
   * Creates a new query and runs it asynchronously.
   * The observable will emit every backend request and will contain the data that is available at that time.
   * - Create
   * - Poll...n
   * - Result
   */
  runQueryWithPolling(
    createQueryResponse: Observable<RequestStatusComposite>,
    fetchResult = true
  ): Observable<CombinedQueryResult> {
    return createQueryResponse.pipe(this.runAsyncQuery(fetchResult));
  }

  private runQueryPolling(
    createQueryResponse: Observable<RequestStatusComposite>,
    fetchResult = true
  ): Observable<CombinedQueryResult> {
    let tries = 0;
    let finished = false;
    let loadedBytes = 0; // progress events will update this variable
    let query: RequestStatusComposite;
    const mapQueryToCombinedResult = (
      queryStatus: RequestStatusComposite,
      resultResponse: any = undefined
    ): CombinedQueryResult => ({ query: queryStatus, loaded: loadedBytes, result: resultResponse });
    const mapOnlyQueryToCombinedResult = (queryResponse: RequestStatusComposite) =>
      mapQueryToCombinedResult(queryResponse);
    return createQueryResponse.pipe(
      map(mapOnlyQueryToCombinedResult),
      expand((result) => {
        query = result.query;
        tries++;
        if (['PENDING', 'RUNNING', 'QUEUED'].includes(query.status)) {
          const delaySec = QueryService.calcDelay(tries);
          // Use workerScheduler to make polling background tabs work
          return timer(delaySec * 1000, workerScheduler) // timer wants ms (not seconds of course)
            .pipe(
              mergeMap(() => this.getQueryStatus(query.requestId)),
              map(mapOnlyQueryToCombinedResult)
            );
        } else if (!finished && query.status === 'SUCCESSFUL') {
          finished = true;
          if (fetchResult) {
            return this.getQueryResult(query.requestId).pipe(
              filter((event) =>
                [HttpEventType.Response, HttpEventType.DownloadProgress].includes(event.type)
              ),
              map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                  return mapQueryToCombinedResult({ ...query, status: 'FINISHED' }, event.body);
                } else if (event.type === HttpEventType.DownloadProgress) {
                  loadedBytes = event.loaded;
                }
                return mapQueryToCombinedResult(query);
              })
            );
          } else {
            return of(mapOnlyQueryToCombinedResult(query));
          }
        } else if (errorStates.includes(query.status)) {
          return this.getQueryAndThrowError(query.requestId);
        } else {
          return EMPTY;
        }
      }),
      finalize(() => {
        // Cancel the query if it is still active to save read tickets, e.g. when switching Dashboards
        if (['PENDING', 'QUEUED', 'RUNNING'].includes(query.status) && query.requestId) {
          {
            this.cancelQuery(query.requestId).pipe(take(1)).subscribe();
          }
        }
      })
    );
  }

  /**
   * Called when an error status is returned to retrieve and throw the correct error message.
   */
  private getQueryAndThrowError(queryId: string) {
    // Predefined messages that do not need additional information from the backend.
    const status2Message = {
      NO_TICKET: this.translateService.instant('query.noReadTicketAvailable'),
      EXPIRED: this.translateService.instant('query.queryResultExpired'),
      INTERRUPTED: this.translateService.instant('query.queryInterrupted'),
      POLLING_TIMEOUT: this.translateService.instant('query.pollingTimeout'),
      CANCELED: this.translateService.instant('query.queryCanceled')
    };
    return this.getQuery(queryId).pipe(
      switchMap((queryInfo) => {
        const message =
          queryInfo.status in status2Message
            ? status2Message[queryInfo.status]
            : queryInfo.statusMessage;
        return throwError(new DetailedError(`${queryInfo.status}: ${message}`, queryInfo));
      })
    );
  }

  private static calcDelay(tries: number) {
    const min_delay = 0.05; // wait at least 50ms
    const max_delay = 5; // but not longer than 5 sec
    const shift_factor = 1.2; // this factor keeps the initial increase

    // lower than a pure cosine function would
    const tries_normalized = Math.min(tries, 180) / 180.0; // map tries to a value between 0 and 1
    const tries_shifted = Math.pow(tries_normalized, shift_factor);
    const tries_shifted_degrees = tries_shifted * 180; // tries as degrees for cosine, from 0° up to 180°
    const tries_radians = (tries_shifted_degrees / 180) * Math.PI; // but Math.cos takes radians
    const delay_factor = 1 - (1 + Math.cos(tries_radians)) / 2; // starts at 0 and goes up to 1
    //    ^ delay_factor
    //    |
    // 1.0+                                              _,,...............
    //    |                                          _.-'
    //    |                                        ,'
    //    |                                      ,'
    //    |                                     /
    //    |                                   ,'
    //    |                                  /
    //    |                                ,'
    //    |                               ,'
    //    |                              /
    //    |                             /
    //    |                            /
    //    |                          ,'
    //    |                         /
    //    |                        /
    //    |                       /
    //    |                     ,'
    //    |                    /
    //    |                   /
    //    |                 ,'
    //    |                /
    //    |              ,'
    //    |            ,'
    //    |         _,'
    //    |       ,'
    // 0.0L...,-'...................................................|.........>
    //     1                          90                          180         tries
    //     0s                        58s                         405s        total elapsed time
    return Math.max(min_delay, delay_factor * max_delay);
  }

  runAsyncQuery(fetchResult = true): MonoTypeOperatorFunction<any> {
    return (source: Observable<RequestStatusComposite>) =>
      new Observable((observer: Subscriber<CombinedQueryResult>) => {
        return this.runQueryPolling(source, fetchResult).subscribe({
          next: (queryStatus) => {
            observer.next(queryStatus);
          },
          error: (err) => {
            observer.error(err);
          },
          complete: () => {
            observer.complete();
          }
        });
      });
  }

  getQueryTemplateLabels(): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.getTemplatesUrl() + '/labels', {
      reportProgress: true,
      observe: 'response'
    });
  }

  getQueryTemplates(
    page: number,
    pageSize: number,
    userId?: string,
    labels?: string[],
    filterQuery?: object,
    executionTime = true
  ): Observable<HttpResponse<QueryTemplate[]>> {
    let params = new HttpParams().set('page', page.toString()).set('pageSize', pageSize.toString());

    let filter = {};
    if (userId) {
      filter['createdBy.userId'] = userId;
    }

    if (labels) {
      filter['labels'] = { $all: labels };
    }

    if (filterQuery) {
      filter = filterQuery;
    }

    if (Object.keys(filter).length) {
      params = params.set('filterQuery', JSON.stringify(filter));
    }

    if (executionTime) {
      params = params.set('executionTime', executionTime);
    }

    return this.http.get<QueryTemplate[]>(this.getTemplatesUrl(), {
      params: params,
      observe: 'response'
    });
  }

  downloadCachedQueryResult(queryId: string, format: 'json' | 'csv') {
    const url = this.getQueriesUrl(queryId) + '/result?format=' + format;

    executeDownload(url);
  }

  createQueryTemplate(data: QueryTemplateParameters): Observable<QueryTemplate> {
    return this.http.post<QueryTemplate>(this.getTemplatesUrl(), data);
  }

  createQueryTemplateTimeseries(
    collection: string,
    name: string,
    timeField: string,
    objectAcl: ObjectAclReq
  ): Observable<QueryTemplate> {
    return this.http.post<QueryTemplate>(
      this.getTemplatesUrl(),
      createTemplateForTimeseries(collection, name, timeField, objectAcl)
    );
  }

  deleteQueryTemplate(id: string): Observable<string> {
    return this.http.delete(this.getTemplatesUrl(id), { responseType: 'text' });
  }

  getQueryTemplate(id: string): Observable<QueryTemplate> {
    return this.http.get<QueryTemplate>(this.getTemplatesUrl(id));
  }

  updateQueryTemplate(id: string, data: QueryTemplateParameters): Observable<QueryTemplate> {
    return this.http.put<QueryTemplate>(this.getTemplatesUrl(id), data);
  }

  executeFindQuery(data): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + '/execute-find-query', data);
  }

  executeCountQuery(data): Observable<number> {
    return this.http.post<number>(this.baseUrl + '/execute-count-query', data);
  }

  submitQueryTemplate(
    id: string,
    data: QueryTemplateExecutionParameters
  ): Observable<RequestStatusComposite> {
    return this.http.post<RequestStatusComposite>(this.getTemplatesUrl(id) + '/submit-query', data);
  }

  processQueryTemplate(id: string, data: QueryTemplateExecutionParameters): Observable<any> {
    return this.http.post<any>(this.getTemplatesUrl(id) + '/process-template', data);
  }

  cancelQuery(id: string): Observable<CancelQueryResult> {
    if (this.lastCancelledQueryId === id) {
      return of(null);
    }
    this.lastCancelledQueryId = id;
    return this.http.post<CancelQueryResult>(this.baseUrl + '/queries/' + id + '/cancel', id);
  }
}

export type JSONTypes = object | number | string | null;

function parseResponseBody<T = JSONTypes>(event: HttpResponse<string>): T {
  try {
    return window['JSONbigString'].parse(event.body);
  } catch (e) {
    throw new DetailedError(
      `Error parsing response from ${event.url} (${event.status} ${event.statusText})`,
      event
    );
  }
}
