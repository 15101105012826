import {
  Directive,
  EmbeddedViewRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { UserAuthService } from '../../core/services/user-auth.service';
import { ProjectsService } from '../services/projects.service';
import { Subscription } from 'rxjs';
import { RoleName } from '../../core/services/models/project-role';

export type RoleCondition = RoleName | RoleName[];

@Directive({
  selector: '[hasProjectRole]'
})
export class HasProjectRoleDirective implements OnDestroy, OnInit {
  roles: RoleCondition = null;

  private viewRef: EmbeddedViewRef<any> = null;

  private subscription: Subscription;

  constructor(
    private projectsService: ProjectsService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: UserAuthService
  ) {}

  @Input()
  set hasProjectRole(roles: RoleCondition) {
    this.roles = roles;
    this.updateView();
  }

  ngOnInit(): void {
    this.subscription = this.authService.isLoggedIn().subscribe(() => {
      this.updateView();
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  updateView() {
    let hasRole = false;
    if (Array.isArray(this.roles)) {
      for (const role of this.roles) {
        hasRole = this.hasRoleOfCurrentProject(role);
        if (hasRole) {
          break;
        }
      }
    } else {
      hasRole = this.hasRoleOfCurrentProject(this.roles);
    }

    try {
      if (hasRole && !this.viewRef) {
        this.viewContainer.clear();
        this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
      } else if (!hasRole) {
        this.viewContainer.clear();
        this.viewRef = null;
      }
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Checks only project roles for matches with roleName
   */
  hasRoleOfCurrentProject(roleName: string) {
    return this.projectsService.hasRoleOfCurrentProject(roleName);
  }
}
