import { Component, HostListener, Input } from '@angular/core';
import { AttachmentEntry } from '../../../devices/services/device-attachment.service';
import { encodeLabelInAttachmentUrl } from '../../../devices/models/device';

@Component({
  selector: 'image-gallery-dialog',
  styleUrls: ['./image-gallery-dialog.component.scss'],
  templateUrl: './image-gallery-dialog.component.html'
})
export class ImageGalleryDialogComponent {
  @Input() previewImage: AttachmentEntry = null;

  @Input() images: AttachmentEntry[] = [];

  get selectedImage() {
    return { ...this.previewImage, url: encodeLabelInAttachmentUrl(this.previewImage.url) };
  }

  isLoading = true;
  isMaximized = true;

  hasMultiple() {
    return this.images.length > 1;
  }

  showPrev() {
    const pos = this.images.indexOf(this.previewImage);
    const len = this.images.length;
    this.isLoading = true;
    this.previewImage = this.images[(pos + len - 1) % this.images.length];
  }

  showNext() {
    const pos = this.images.indexOf(this.previewImage);
    const len = this.images.length;
    this.isLoading = true;
    this.previewImage = this.images[(pos + len + 1) % this.images.length];
  }

  toggleViewSize() {
    this.isMaximized = !this.isMaximized;
  }

  onImageLoad(): void {
    this.isLoading = false;
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'ArrowRight') {
      this.showNext();
    } else if (event.key === 'ArrowLeft') {
      this.showPrev();
    }
  }
}
