import { ParamMap, Params } from '@angular/router';

export class UrlSearchParamMap implements ParamMap {
  constructor(private params?: URLSearchParams) {}

  get keys(): string[] {
    return [...new Set(this.params.keys())];
  }

  get(name: string): string | null {
    return this.params.get(name);
  }

  getAll(name: string): string[] {
    return this.params.getAll(name);
  }

  has(name: string): boolean {
    return this.params.has(name);
  }

  /**
   * Convert url search params to a query parameters.
   * Only not null values are available here.
   */
  toParams(): Params {
    const result: Params = {};

    for (const key of this.keys) {
      const values = this.getAll(key);
      result[key] = values.length > 1 ? values : values[0];
    }

    return result;
  }

  /**
   * Convert url search params to a query parameters.
   * Null values are also included if queryParameterKeys are provided.
   *
   * For routing purposes, i.e. null values will be removed from the url.
   * @param queryParameterKeys - all parameter keys
   */
  toParamsWithNulls(queryParameterKeys: string[] = []): Params {
    const nulls = {};
    queryParameterKeys.forEach((key) => (nulls[key] = null));

    return {
      ...nulls,
      ...this.toParams()
    };
  }
}
