export function transformTranslatedString(value, language: string): string {
  if (typeof value === 'object' && value) {
    if (value[language] !== undefined) {
      return value[language];
    } else {
      return value.en;
    }
  } else {
    return String(value);
  }
}
