import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { ProjectConfig } from '../../shared-projects/models/project.model';
import {
  LastVisitedPageItem,
  LatestProjectItem,
  ProjectsService
} from '../../shared-projects/services/projects.service';
import { AppThemingService } from '../services/app-theming.service';
import { UserAuthService } from '../services/user-auth.service';
import { Event, EventType, NavigationEnd, Router, Scroll } from '@angular/router';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { isProjectRoute, isWidgetEditRoute } from '../../shared/project-utils';

@Injectable()
export class LayoutService implements OnDestroy {
  language: string;

  projectConfig: ProjectConfig;

  isLoggedIn = false;

  lastUsedProjects$ = new BehaviorSubject<LatestProjectItem[]>([]);

  lastUsedPages$ = new BehaviorSubject<LastVisitedPageItem[]>([]);

  projectLabel: string;

  projectName: string;

  currentHeaderTitle: BehaviorSubject<string> = new BehaviorSubject<string>('');

  destroy = new Subject<null>();

  constructor(
    public authService: UserAuthService,
    public appThemingService: AppThemingService,
    private languageService: TranslateService,
    private projectsService: ProjectsService,
    private router: Router
  ) {
    this.initService();
    this.initLastViewedPagesObserver();
  }

  initLastViewedPagesObserver() {
    this.router.events
      .pipe(
        filter(
          (event) => this.isNavigationEnded(event as any) && this.isLastVisiblePageRoute(event)
        ),
        takeUntil(this.destroy),
        debounceTime(200)
      )
      .subscribe({
        next: (event) => {
          this.updateAndTriggerLastVisitedPageChange(event);
        }
      });
  }

  private updateAndTriggerLastVisitedPageChange(event: Event) {
    this.projectsService.updateLastVisitedPages(
      this.composeLastVisitedPageItem(event),
      this.authService.getUserId()
    );
    this.lastUsedPages$.next(
      this.projectsService.getLastVisitedPagesForProject(this.authService.userId, this.projectName)
    );
  }

  private composeLastVisitedPageItem(event: any): LastVisitedPageItem {
    const url = event?.routerEvent?.url ?? event?.url;
    return LastVisitedPageItem.fromURLString(url, this.currentHeaderTitle.value);
  }

  initService() {
    this.language = this.languageService.currentLang;

    combineLatest([
      this.projectsService.projectConfigEvents,
      this.authService.isLoggedInStream()
    ]).subscribe(([configEvent, isLoggedIn]) => {
      this.isLoggedIn = isLoggedIn;
      if (isLoggedIn) {
        this.lastUsedProjects$.next(
          this.projectsService.getLastUsedProjects(this.authService.getUserId())
        );
      }

      this.projectConfig = configEvent?.config;
      this.setProjectProps(configEvent?.config);
    });
  }

  private setProjectProps(config: ProjectConfig): LayoutService {
    if ((this.isLoggedIn || this.appThemingService.appTheme.theme === 'CUSTOM') && config) {
      this.projectLabel =
        config.label[this.language] || config.label.en || this.projectsService.projectName;
      this.projectName = config.name;
    } else {
      this.projectLabel = null;
      this.projectName = null;
    }

    return this;
  }

  private isLastVisiblePageRoute(event: any): boolean {
    // Only save last visible pages for project routes without widget edit pages
    const url = event?.routerEvent?.url ?? event?.url;
    return isProjectRoute(url) && !isWidgetEditRoute(url);
  }

  private isNavigationEnded(event: Scroll | NavigationEnd): boolean {
    if (event instanceof Scroll) {
      // Catching the Scroll Event as it is triggered on inital page load by routing
      return event.routerEvent.type === EventType.NavigationEnd;
    }
    return event instanceof NavigationEnd;
  }

  ngOnDestroy() {
    this.destroy.next(null);
  }
}
