import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { LoadingEntity } from '@inst-iot/bosch-angular-ui-components';
import { matchMediaQueries } from '../media-query/media-query.model';
import { Observable, of, Subject } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PickListItem, PicklistItemData } from './model/picklist.model';
import {
  findItemIndex,
  searchItems,
  toItemsWithCheckedProperty,
  uncheckAllItems,
  uncheckItem
} from './utils/picklist.utils';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'picklist',
  templateUrl: './picklist.component.html',
  styleUrls: ['./picklist.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PicklistComponent),
      multi: true
    }
  ]
})
export class PicklistComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() loader: LoadingEntity<any>;
  @Input() availableItems: PickListItem[];
  @Input() selectedItems: PickListItem[];
  @Input() resetOrRemoveAllClicked$: Observable<string>;
  @Input() availableItemsSearchFn: (value: string) => Observable<any>;
  @Input() infiniteScrollFn: () => any = () => null;

  @Output() removeSelectedItem = new EventEmitter<PicklistItemData<any>>();
  @Output() itemSearch = new EventEmitter<void>();

  availableItemsLoader = new LoadingEntity<any>();
  selectedItemsFiltered: PickListItem[] = [];

  protected readonly matchMediaQueries = matchMediaQueries;

  private selectedItemsSearchTerm = '';
  private destroy = new Subject<void>();
  private cdr = inject(ChangeDetectorRef);

  onChange = (label: string) => {};

  onTouched = () => {};

  ngOnInit() {
    this.availableItemsLoader
      .run(this.availableItemsSearchFn(''))
      .subscribe(() => this.cdr.detectChanges());

    this.resetOrRemoveAllClicked$
      .pipe(takeUntil(this.destroy))
      .subscribe((exceptionId) => uncheckAllItems(this.availableItems, exceptionId));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.availableItems && this.selectedItems) {
      this.availableItems = toItemsWithCheckedProperty(this.availableItems, this.selectedItems);
    }

    if (changes.selectedItems) {
      this.selectedItemsFiltered = searchItems(this.selectedItems, this.selectedItemsSearchTerm);
    }
  }

  ngOnDestroy() {
    if (this.loader) {
      this.loader.complete();
    }

    this.availableItemsLoader.complete();
    this.destroy.next();
    this.destroy.complete();
  }

  writeValue(value: any) {}

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  onCheck(checked: boolean, item: PickListItem) {
    this.onTouched();

    if (checked) {
      this.onChange(item.label);
    } else {
      const uncheckedItemIndex = findItemIndex(this.selectedItems, item);
      this.removeSelectedItem.emit({ item, index: uncheckedItemIndex });
    }
  }

  /*
   * Preventing the propagation of the click because it closes the filter widget popover window
   * The clicking of the parent is necessary to properly save the changes
   * when closing the filter widget popover directly after clicking the delete button.
   */
  onDeleteClick(event: Event, item: PickListItem) {
    uncheckItem(item, this.availableItems);
    const target = event.target as HTMLElement;
    const parent = target.parentElement;
    parent.click();
    event.stopPropagation();
    const itemIndex = findItemIndex(this.selectedItems, item);
    this.removeSelectedItem.emit({ item, index: itemIndex });
  }

  searchSelectedItems(searchTerm: string) {
    this.selectedItemsSearchTerm = searchTerm.trim();
    this.selectedItemsFiltered = searchItems(this.selectedItems, this.selectedItemsSearchTerm);
    this.cdr.markForCheck();
    return of(this.selectedItemsFiltered);
  }
}
