import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { RoleSearchComponent } from './role-search.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [RoleSearchComponent],
  imports: [CommonModule, SharedModule, ReactiveFormsModule],
  exports: [RoleSearchComponent]
})
export class RoleSearchModule {}
