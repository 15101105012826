import { Injectable } from '@angular/core';
import { parse } from 'date-fns';
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { LanguagesService } from './languages.service';

@Injectable()
export class FlatpickrOptionsService {
  private timezone;
  private readonly dateFormat;

  constructor(languagesService: LanguagesService) {
    this.dateFormat = 'Z';
    this.timezone = languagesService.timezone;
  }

  parseDate = (dateString: string): Date => {
    let date;
    if (dateString.endsWith('Z')) {
      date = utcToZonedTime(dateString, this.timezone);
    } else {
      const format = dateString.length === 10 ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm';
      date = parse(dateString, format, new Date());
    }
    return date;
  };

  formatDate = function (date: Date) {
    // this will reference to the instance of flatpickr where both, enableTime and timezone, are being present
    return format(date, this.enableTime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd', {
      timeZone: this.timezone
    });
  };

  toZonedDate = (date: Date): Date => {
    return zonedTimeToUtc(date, this.timezone);
  };
}
