import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './core/error/error.component';
import { UserRequiredGuard } from './core/services/user-required.guard';
import { AlreadyLoggedInGuard } from './core/services/already-logged-in.guard';
import { HomeComponent } from './core/home/home.component';
import { translate } from './shared/translation-util';
import { RoleRequiredGuard } from './core/services/role-required.guard';
import { LayoutComponent } from './core/layout/layout.component';
import { AutoOpenProjectGuard } from './core/services/auto-open-project.guard';
import { isInFrame } from './shared/embed-utils';
import { OutletComponent } from './shared/outlet/outlet.component';
import { CustomDomainBoundaryGuard } from './core/services/custom-domain-boundary.guard';

const uiRoutes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AutoOpenProjectGuard],
    data: {
      i18nTitle: translate('nav.home')
    }
  },
  {
    path: 'projects',
    loadChildren: () => import('./projects/projects.module').then((m) => m.ProjectsModule),
    canActivate: [UserRequiredGuard, CustomDomainBoundaryGuard]
  },
  {
    path: 'project',
    loadChildren: () => import('./project/project.module').then((m) => m.ProjectModule),
    canActivate: [UserRequiredGuard]
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./project-admin/project-admin.module').then((m) => m.ProjectAdminModule),
    canActivate: [UserRequiredGuard, RoleRequiredGuard],
    data: {
      role: 'sfde_manager',
      i18nTitle: translate('nav.admin.title')
    }
  },
  {
    path: 'sfde-admin',
    loadChildren: () => import('./sfde-admin/sfde-admin.module').then((m) => m.SfdeAdminModule),
    canActivate: [UserRequiredGuard, RoleRequiredGuard],
    data: {
      role: 'sfde_admin',
      i18nTitle: translate('nav.admin.title')
    }
  },
  {
    path: 'pages',
    loadChildren: () =>
      import('./content-pages/content-pages.module').then((m) => m.ContentPagesModule),
    data: {
      i18nTitle: null
    }
  },
  {
    path: 'user-internal',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
    canActivate: [UserRequiredGuard],
    data: {
      i18nTitle: translate('nav.user.title')
    }
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule)
  },
  {
    path: 'logout',
    redirectTo: 'user/logout'
  },
  {
    path: 'error/:error',
    component: ErrorComponent
  },
  {
    path: '**',
    redirectTo: 'error/noMatchingRoute'
  }
];

const routes: Routes = [
  {
    path: 'nolayout',
    component: OutletComponent,
    children: uiRoutes
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '',
    component: isInFrame() ? OutletComponent : LayoutComponent,
    children: uiRoutes
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [UserRequiredGuard, AlreadyLoggedInGuard, RoleRequiredGuard]
})
export class AppRoutingModule {}
