<div class="d-flex">
  <div *ngIf="inputType === 'default'" class="flex-grow-1">
    <value-input
      [dataType]="typeOption?.valueType"
      [validationRegExp]="typeOption?.validation"
      [label]="defaultLabel || ('dataWidgets.parameterInput.default' | translate) + label"
      [value]="value"
      (valueChange)="updateValue($event)"
    ></value-input>
  </div>
  <div *ngIf="inputType === 'queryParameter'" class="flex-grow-1">
    <parameter-value
      data-cy="dataWidgetsQueryParameter"
      [parameter]="queryParameter.parameter"
      [value]="value"
      (valueChange)="updateQueryParameterValue($event)"
      [isSet]="queryParameter.isSet"
      [readonly]="readonly"
    ></parameter-value>
  </div>
  <div *ngIf="inputType === 'deviceSearch'" class="flex-grow-1 mb-3">
    <device-search
      [label]="
        defaultLabel ||
        (parameterVariant === 'ref' ? ('dataWidgets.parameterInput.default' | translate) : '') +
          label
      "
      [selectedDeviceTypes]="deviceTypes"
      [ngModel]="value"
      (ngModelChange)="updateValue($event)"
    ></device-search>
  </div>
  <div *ngIf="inputType === 'queryCollection'" class="flex-grow-1">
    <rb-form-custom-select
      [ngModel]="value"
      (ngModelChange)="updateValue($event)"
      [label]="'queryInterface.targetCollection' | translate"
      required
      class="required"
      name="targetCollection"
    >
      <rb-form-select-option *ngFor="let collection of targetCollections" [value]="collection.name">
        {{ collection.label + ' (' + collection.name + ')' }}
      </rb-form-select-option>
    </rb-form-custom-select>
  </div>

  <div class="ml-1">
    <rb-form-custom-select
      data-cy="dataWidgetsParameterRefInput"
      [selectionTpl]="dropdown"
      [disabled]="isParameterRefInputEnabled()"
    >
      <span *ngIf="parameterVariant === 'ref'" class="rb-ic rb-ic-link" title="Reference"></span>
      <span *ngIf="parameterVariant === 'value'" class="rb-ic rb-ic-keyboard" title="Value"></span>
    </rb-form-custom-select>
  </div>

  <ng-template #dropdown>
    <a
      *ngIf="parameterVariant === 'ref'"
      href="#"
      class="rb-dropdown-item"
      (click)="updateParameterVariant('value')"
    >
      <span class="rb-ic rb-ic-keyboard"></span>
      {{ 'dataWidgets.parameterInput.convert' | translate }}
    </a>

    <h5 class="mx-3 my-3" *ngIf="inputParameters?.length" style="min-width: 300px">
      {{ 'dataWidgets.parameterInput.availableReference' | translate }}
      <small>{{ 'dataWidgets.parameterInput.fromInputWidget' | translate }}</small>
    </h5>

    <ng-container *ngFor="let p of inputParameters">
      <a
        href="#"
        class="rb-dropdown-item"
        [attr.data-cy]="'dataWidgetsParameterRefInput-' + p.technicalName"
        [class.active]="referenceText === 'inputFields.' + p.technicalName"
        (click)="setReference(setInputReference, p)"
      >
        <span class="rb-ic rb-ic-link"></span>
        {{ p.label | translated }}
      </a>
    </ng-container>

    <h5 class="mx-3 my-3" *ngIf="parameters.length" style="min-width: 300px">
      {{ 'dataWidgets.parameterInput.availableReference' | translate }}
      <small>{{ 'dataWidgets.parameterInput.fromFilterWidget' | translate }}</small>
    </h5>
    <ng-container *ngFor="let p of parameters">
      <a
        href="#"
        class="rb-dropdown-item"
        [attr.data-cy]="'dataWidgetsParameterRefInput-' + p.name"
        [class.active]="referenceText === 'filterParams.' + p.name"
        (click)="setReference(setFilterReference, p)"
      >
        <span class="rb-ic rb-ic-link"></span>
        {{ p.label | translated }}
        <ng-container *ngIf="p.type === 'device'">({{ p.valuePath }})</ng-container>
      </a>
      <a
        *ngIf="p.type === 'device'"
        [attr.data-cy]="'dataWidgetsParameterRefInput-' + p.name + '-ThingId'"
        href="#"
        class="rb-dropdown-item"
        [class.active]="referenceText === 'filterParams.' + p.name + 'ThingId'"
        (click)="setReference(setFilterReference, p, p.name + 'ThingId')"
      >
        <span class="rb-ic rb-ic-link"></span>
        {{ p.label | translated }} (ThingId)
      </a>
    </ng-container>
    <h5 class="mx-3 my-3" *ngIf="userContextParameter.length" style="min-width: 300px">
      {{ 'dataWidgets.parameterInput.availableReference' | translate }}
      <small>{{ 'dataWidgets.parameterInput.fromUserContext' | translate }}</small>
    </h5>
    <ng-container *ngFor="let userP of userContextParameter">
      <a
        href="#"
        class="rb-dropdown-item"
        [class.active]="referenceText === userP.value"
        [attr.data-cy]="'dataWidgetsParameterRefInput-' + userP.value"
        (click)="setReference(setUserContextReference, userP)"
      >
        <span class="rb-ic rb-ic-link"></span>
        {{ userP.label | translated }}
      </a>
    </ng-container>
  </ng-template>
</div>
