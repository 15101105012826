<ng-template #languageSelector>
  <language-selector></language-selector>
</ng-template>

<ng-template #projectSelector>
  <project-selector></project-selector>
</ng-template>

<ng-template #pageSelector>
  <page-selector></page-selector>
</ng-template>

<ng-template #userMenu>
  <user-menu *ngIf="layoutService.isLoggedIn"></user-menu>

  <login-provider-select
    *ngIf="!layoutService.isLoggedIn && !loginStatus.loading"
    [inHeader]="true"
    (loginClicked)="login($event)"
  ></login-provider-select>
</ng-template>
