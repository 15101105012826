export function assignWithDefaults(
  target: any,
  source: any,
  converters: { [key: string]: (source: any) => any } = {}
): any {
  const keys = Object.keys(target);
  for (const key of keys) {
    if (source && key in source) {
      const targetValue = target[key];
      const expectedType = typeof targetValue;
      let value = source[key];
      if (key in converters && converters[key] === undefined) {
        continue;
      }
      if (key in converters) {
        value = converters[key](value);
      }
      const actualType = typeof value;
      if ((targetValue !== null && actualType === expectedType) || targetValue === null) {
        target[key] = value;
      } else {
        console.warn(
          `Could not assign key ${key}. Actual type: ${actualType} value: ${value} Expected type: ${expectedType}`
        );
      }
    }
  }
  return target;
}

export function assignNotNull(target: any, source: any): any {
  const keys = Object.keys(source);
  for (const key of keys) {
    const value = source[key];
    if (value !== null && value !== undefined) {
      target[key] = value;
    }
  }
  return target;
}
