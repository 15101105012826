import { DsParameterValue } from '../shared-modules/data-widgets/data-widgets-common/lib/data-sources/models';

export interface EventColor {
  name: string;
  description: string;
  color: DsParameterValue;
}

/**
 * Calculates the contrasting color (black or white) based on the input color.
 *
 * @param {string} color - The color in the format '#RRGGBB' or 'RRGGBB'.
 * @returns {string} The contrasting color, either '#fff' (white) or '#000' (black).
 */

export function getContrastColor(color: string): string {
  let red = 0,
    green = 0,
    blue = 0;

  if (color.length === 7) {
    color = color.substring(1);
  }

  red = parseInt(color.substring(0, 2), 16);
  green = parseInt(color.substring(2, 4), 16);
  blue = parseInt(color.substring(4, 6), 16);

  const hsp = Math.sqrt(red * red * 0.241 + green * green * 0.691 + blue * blue * 0.068);

  return hsp < 130 ? '#fff' : '#000';
}

export const defaultPredefinedEventColors: EventColor[] = [
  {
    name: 'Color 1',
    color: {
      value: '#005691'
    },
    description: 'Event Category 1'
  },
  {
    name: 'Color 2',
    color: {
      value: '#008ECF'
    },
    description: 'Event Category 2'
  },
  {
    name: 'Color 3',
    color: {
      value: '#E20015'
    },
    description: 'Event Category 3'
  },
  {
    name: 'Color 4',
    color: {
      value: '#B90276'
    },
    description: 'Event Category 4'
  },
  {
    name: 'Color 5',
    color: {
      value: '#78BE20'
    },
    description: 'Event Category 5'
  },
  {
    name: 'Color 6',
    color: {
      value: '#00A8B0'
    },
    description: 'Event Category 6'
  }
];
