<ng-container *ngIf="layoutService.isLoggedIn">
  <ng-container *ngIf="layoutService.projectLabel">
    <div class="project-label-wrapper">
      <button
        *ngIf="(layoutService.appThemingService.appTheme$ | async)?.theme !== 'CUSTOM'"
        class="a-button a-button--integrated project-label"
        type="button"
        [rbPopover]="projectPopover"
        [distance]="-7"
        [attr.data-cy]="'projectLabel-' + layoutService.projectLabel"
      >
        {{ layoutService.projectLabel }}
      </button>
      <button
        *ngIf="(layoutService.appThemingService.appTheme$ | async)?.theme === 'CUSTOM'"
        class="a-button a-button--integrated project-label"
        type="button"
        routerLinkActive
        #route="routerLinkActive"
        [disabled]="route.isActive"
        [routerLink]="'/' | projectUrl: layoutService.projectName"
      >
        {{ layoutService.projectLabel }}
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="!layoutService.projectLabel">
    <div class="project-label-wrapper">
      <button
        class="a-button a-button--integrated project-label"
        type="button"
        [distance]="-7"
        [rbPopover]="projectPopover"
      >
        {{ (layoutService.appThemingService.appTheme$ | async)?.productName }}
      </button>
    </div>
  </ng-container>
</ng-container>

<ng-template #projectPopover let-close="close">
  <ul class="o-context-menu__menuItems">
    <li class="a-menu-item">
      <span class="a-menu-item__link a-menu-item__link--disabled">
        {{ 'nav.lastViewedProjects' | translate }}
      </span>
    </li>
  </ul>

  <ul class="o-context-menu__menuItems">
    <ng-container *ngFor="let project of layoutService.lastUsedProjects$ | async">
      <li class="a-menu-item" *ngIf="project.name !== projectsService.projectName">
        <a
          class="a-menu-item__link"
          [routerLink]="'/' | projectUrl: project.name"
          [routerLinkActive]="'a-menu-item__link--active'"
        >
          {{ project.label | translated }}
        </a>
      </li>
    </ng-container>
  </ul>

  <hr class="m-0" />

  <ul class="o-context-menu__menuItems">
    <li class="a-menu-item">
      <a
        class="a-menu-item__link"
        [routerLinkActive]="'a-menu-item__link--active'"
        [routerLink]="routing.projects"
        (click)="close()"
      >
        {{ 'nav.allProjects' | translate }}
      </a>
    </li>
  </ul>
</ng-template>
