import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'alphabeticalSort'
})
export class AlphabeticalSortPipe implements PipeTransform {
  transform(array: any[], order: 'asc' | 'desc' = 'asc', path = ''): any[] {
    if (!array?.length) {
      return array;
    }

    // Copy the array to avoid modifying the original array
    const sortedArray = [...array];

    return sortedArray.sort((a, b) => {
      if (path) {
        const aValue = this.getPropertyValue(a, path);
        const bValue = this.getPropertyValue(b, path);
        return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      } else {
        // If 'path' is not provided, assume array of strings
        return order === 'asc' ? a.localeCompare(b) : b.localeCompare(a);
      }
    });
  }

  private getPropertyValue(obj: any, path: string): any {
    if (!obj || !path) {
      return obj;
    }

    const properties = path.split('.');
    let value = obj;

    for (const prop of properties) {
      value = value[prop];
    }

    return value;
  }
}
