<notification-banner></notification-banner>

<header></header>

<ng-container *ngIf="(fullscreenService.isActive | async) === true; else renderNavigation">
  <button
    id="exit-fullscreen"
    type="button"
    class="close-btn rb-btn rb-passive-link"
    data-cy="headerCloseFullScreenButton"
    (click)="closeFullScreen()"
  >
    <i
      class="a-icon boschicon-bosch-ic-fullscreen-exit"
      title="Exit Fullscreen"
      aria-hidden="true"
    ></i>
  </button>

  <router-outlet></router-outlet>
</ng-container>

<ng-template #renderNavigation>
  <rb-app-layout
    [popoverDistance]="-7"
    [footerModel]="footerComponent?.footer"
    [headerModel]="headerComponent?.header"
    [sideNavigationModel]="sideNavigation"
    (pinnedSideNavChange)="savePinState($event)"
  >
    <router-outlet></router-outlet>

    <ng-container slot-below-supergraphic>
      <small
        *ngIf="router.url === routing.home && appTheming.appTheme.theme !== 'CUSTOM'"
        class="d-block w-100 text-center -secondary"
      >
        This offer is only addressed to commercial customers including freelancers and
        entrepreneurs. All prices are exclusive of value added tax (VAT).
      </small>
      <ng-container *ngTemplateOutlet="notificationComponent.freeplanNotification"></ng-container>
      <ng-container *ngTemplateOutlet="notificationComponent.projectNotification"></ng-container>
    </ng-container>

    <ng-container *ngIf="appTheming.appTheme?.themeLogo || appTheming.appTheme?.theme === 'CUSTOM'">
      <ng-container *rbLogoHeader>
        <a
          *ngIf="appTheming.appTheme.themeLogoUrl; else customLogo"
          [href]="appTheming.appTheme.themeLogoUrl"
          unsafeUrlHandler
        >
          <ng-container *ngTemplateOutlet="customLogo"></ng-container>
        </a>
      </ng-container>
    </ng-container>
    <ng-template #customLogo>
      <img
        *ngIf="appTheming.appTheme?.themeLogo"
        src="{{ appTheming.appTheme.themeLogo }}"
        alt="{{ layoutService.projectLabel }} Logo"
        [ngClass]="{ 'customer-project-logo': appTheming.hasCustomLogo }"
      />
    </ng-template>
  </rb-app-layout>
</ng-template>

<footer></footer>
