export interface RoleDefinition {
  role: string;
  label: string;
  inherits: string[];
  mainRole: boolean;
}

export class Role {
  roleName: string;
  description: string;
  roleDisplayName: string;
  createTs: string;
  updateTs: string;
  editable?: boolean;
  assignable?: boolean;
  disabled?: boolean;
  project?: string;

  constructor(role: Partial<Role> = {}) {
    const {
      roleName = '',
      description = '',
      roleDisplayName = '',
      createTs = '',
      updateTs = '',
      editable = true,
      assignable = true,
      disabled = false,
      project = ''
    } = role;

    this.roleName = roleName;
    this.description = description;
    this.roleDisplayName = roleDisplayName;
    this.createTs = createTs;
    this.updateTs = updateTs;
    this.disabled = disabled;
    this.editable = editable;
    this.assignable = assignable;
    this.project = project;
    return this;
  }

  static namesAsArray(roles: Role[]): string[] {
    return roles.map((role) => role.roleName);
  }

  static findRoleByName(roleName: string, roles: Role[]): undefined | Role {
    return roles.find((role: Role) => role.roleName === roleName);
  }

  static removeRoleByName(roleName: string | string[], roles: Role[]): Role[] {
    if (Array.isArray(roleName)) {
      return roles.filter((role: Role) => !roleName.includes(role.roleName));
    } else if (typeof roleName === 'string') {
      return roles.filter((role: Role) => role.roleName !== roleName);
    } else {
      return roles;
    }
  }

  get isEditable(): boolean {
    return this.editable;
  }

  get isAssignable(): boolean {
    return this.assignable;
  }
}
