import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Role } from '../../role/models/role.model';
import { RoleStoreService } from '../../role/services/role-store.service';
import { LoadingEntity } from '@inst-iot/bosch-angular-ui-components';
import { first } from 'rxjs/operators';

@Component({
  selector: 'user-role-selector',
  templateUrl: './user-role-selector.component.html',
  styleUrls: ['./user-role-selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserRoleSelectorComponent implements OnDestroy {
  @Input()
  showTitle = true;

  @Input()
  set userRoles(roles: string[]) {
    this._userRoles = roles;
    this.preSelectRoles();
  }

  @Output()
  roleSelectionChange = new EventEmitter<string[]>();

  roles: Role[] = [];
  selectedRoles: Role[] = [];
  roleSearchTerm: string;
  rolesLoader = new LoadingEntity<any>();

  private _userRoles: string[];

  constructor(public roleStoreService: RoleStoreService) {
    this.getRoles();
  }

  ngOnDestroy(): void {
    if (this.rolesLoader) {
      this.rolesLoader.complete();
    }
  }

  selectRole(role: Role) {
    if (!this.selectedRoles.find((r) => r.roleName === role.roleName)) {
      this.selectedRoles.push(role);
      this.roles.push(role);
      this.roleSelectionChange.emit(this.selectedRoles.map((r) => r.roleName));
    }
  }

  removeRole(role: Role) {
    this.selectedRoles = Role.removeRoleByName(role.roleName, this.selectedRoles);
    this.roleSelectionChange.emit(this.selectedRoles.map((r) => r.roleName));
  }

  private preSelectRoles() {
    this.selectedRoles = [];
    this.rolesLoader
      .run(this.roleStoreService.getRolesByName(this._userRoles))
      .subscribe((roles) => {
        roles.forEach((role) => {
          this.selectedRoles.push(role);
          if (!Role.findRoleByName(role.roleName, this.roles)) {
            this.roles.push(role);
          }
        });
      });
  }

  private getRoles() {
    this.roleStoreService.roles$.pipe(first()).subscribe((roles: Role[]) => {
      this.roles = roles;
    });
  }
}
