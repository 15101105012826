import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProjectConfig } from '../../shared-projects/models/project.model';
import { CUSTOM_DOMAIN_THEME, ETAS_THEME, INSIGHTS_THEME, Theme } from './models/app-theme';
import { AuthProviderPrefix } from './models/auth-provider';
import { ProjectUiConfigPublic } from '../../project-admin/white-labeling/custom-homepage-config/custom-homepage-config.model';

@Injectable({
  providedIn: 'root'
})
export class AppThemingService {
  get appTheme(): Theme {
    return this._appTheme$.getValue();
  }

  get appTheme$(): Observable<Theme> {
    return this._appTheme$.asObservable();
  }

  hasCustomLogo = false;

  /*
    Custom Domain Feature locally:
    Assign this constant to a technical projectName you want to use
  * */
  readonly MOCK_CUSTOM_DOMAIN_CONTEXT_ON_LOCAL: string; // = 'angelostest';

  private _defaultTheme: Theme;

  private loginUrl = '/auth/start?source=ui';

  private readonly _appTheme: Theme = INSIGHTS_THEME;

  private _appTheme$: BehaviorSubject<Theme>;

  constructor() {
    this._appTheme = this.setDefaultTheme();
    this._appTheme$ = new BehaviorSubject<Theme>(this._appTheme);
  }

  assembleLoginUrl(providerPrefix: AuthProviderPrefix, redirectPath = ''): string {
    let url = `${this.loginUrl}&provider=${providerPrefix}-${this._appTheme.providerSuffix}`;
    if (redirectPath) {
      url += '&redirectPath=' + encodeURIComponent(redirectPath);
    }
    return url;
  }

  hasAuthenticationProvider(prefix: AuthProviderPrefix): boolean {
    return !!this._appTheme.authenticationProviders.find((p) => p.providerPrefix === prefix);
  }

  setThemeProps(config: ProjectConfig): AppThemingService {
    this._appTheme.themeLogo = config?.logo ? config.logo : this._defaultTheme.themeLogo;
    this.hasCustomLogo = !!config?.logo ?? false;

    if (config?.logo) {
      this._appTheme.themeLogoUrl = config?.logoUrl ? config.logoUrl : '';
    }

    this._appTheme.supportLink = config?.contactLink ?? this._defaultTheme.supportLink;
    this._appTheme.newsLink = config?.properties.newsLink ?? this._defaultTheme.newsLink;
    this._appTheme.helpLink = config?.properties.helpLink ?? this._defaultTheme.helpLink;
    this._appTheme.apiDocsLink = config?.properties.apiDocsLink ?? this._defaultTheme.apiDocsLink;

    this._appTheme$.next(this._appTheme);
    return this;
  }

  loadCustomDomainThemeFromPublicConfig(publicConfig: ProjectUiConfigPublic) {
    Object.assign(this._appTheme, publicConfig.mapToAppTheme());
    this.hasCustomLogo = !!publicConfig.themeLogo;
    this._appTheme$.next(this._appTheme);
  }

  private setDefaultTheme(): Theme {
    this._defaultTheme = new Theme(INSIGHTS_THEME);

    if (this.isLocalhost()) {
      // Uncomment the line below to test ETAS theme or CUSTOM DOMAIN theme
      // this._defaultTheme = new Theme(LOCAL_ETAS_THEME);
      // this._defaultTheme = new Theme(CUSTOM_DOMAIN_THEME);
    } else if (this.isEtasHostName()) {
      this._defaultTheme = new Theme(ETAS_THEME);
    } else if (this.isCustomDomainHostName()) {
      this._defaultTheme = new Theme(CUSTOM_DOMAIN_THEME);
    }

    return new Theme(this._defaultTheme);
  }

  private isEtasHostName() {
    return this.currentHostContains('etas-data-insights');
  }

  private isCustomDomainHostName() {
    return !this.isEtasHostName() && !this.isStrictInsightsHostName();
  }

  public isLocalhost() {
    return this.currentHostContains('localhost') || this.currentHostContains('127.0.0.1');
  }

  public isStrictInsightsHostName() {
    return (
      this.currentHostNameStartsWith('bosch-iot-insights.com') ||
      this.currentHostNameStartsWith('bosch-iot-insights.cn') ||
      this.currentHostNameStartsWith('dev0.bosch-iot-insights.cn') ||
      this.currentHostNameStartsWith('dev0.bosch-iot-insights-azure.com')
    );
  }

  private currentHostContains(pattern: string) {
    return location.hostname.indexOf(pattern) >= 0;
  }

  private currentHostNameStartsWith(pattern: string) {
    return location.hostname.startsWith(pattern);
  }
}
