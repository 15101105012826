export function transformProjectUrl(value: string | string[], project?: string): string | string[] {
  if (!project) {
    return value;
  }
  if (Array.isArray(value)) {
    return ['/project', project].concat(value);
  } else {
    return '/project/' + project + value;
  }
}
