<gridster
  class="dashboard gridster w-100"
  [id]="tabWidgetId || 'main'"
  [options]="options"
  [class.sorting-mode]="activateDragAndResize"
  [class.tab-widget-context]="tabWidgetContext"
  [class.maximized]="maximizedIndex !== -1"
  [class.full-screen]="dashboardService.isSpecificWidgetRoute"
  #gridster
>
  <ng-container *ngFor="let item of dashboardGridService.items; let i = index">
    <gridster-item
      [attr.data-cy]="'dashboardGridWidget-' + (tabWidgetContext ? 'tab-' : '') + i"
      [item]="item"
      *ngIf="
        (!dashboardService.isWidgetVisible(item.widget) && (activateDragAndResize || editing)) ||
        dashboardService.isWidgetVisible(item.widget)
      "
      class="gridster-item"
      [ngClass]="overflowVisible(item) ? 'overflow-visible' : 'overflow-hidden'"
      [class.overflow-auto]="isMainRelayoutContext"
      [hidden]="
        (maximizedIndex !== -1 && maximizedIndex !== i) ||
        (item.widget.id === 'playback' && dashboardService.isSpecificWidgetRoute)
      "
      [class.maximized]="maximizedIndex === i"
      [class.full-screen]="dashboardService.isSpecificWidgetRoute"
    >
      <dashboard-widget
        #dashboardWidgetComponent
        [widget]="item.widget"
        [tabId]="tabId"
        [canEdit]="canEdit"
        [widgetResized]="widgetContainerResized"
        (maximize)="setMaximize($event, i, item.widget.id)"
      ></dashboard-widget>

      <div
        *ngIf="item.widget.type === 'tabwidget' && activateDragAndResize"
        id="{{ item.widget.id }}Overlay"
        class="gridster-tab-widget-overlay"
      >
        <i
          class="rb-ic rb-ic-components-add"
          [rbTooltip]="
            'widget.dashboard.gridTabWidgetOverlay'
              | translate
                : { key: disableWidgetPushKey === 'Meta' ? '⌘ command' : disableWidgetPushKey }
          "
        ></i>
      </div>
    </gridster-item>
  </ng-container>
</gridster>
