import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[valueValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ValueValidatorDirective, multi: true }]
})
export class ValueValidatorDirective implements Validator {
  @Input() valueValidator = null;

  @Input() required = false;

  @Input() dataType = null;

  validate(c: AbstractControl): ValidationErrors | any {
    const value = c.value;
    if (typeof value !== 'string') {
      return null;
    }
    if (!this.required && value === '') {
      return null;
    }
    if (this.dataType === 'INT') {
      return this.validateINT(value) || this.validateCustom(value);
    }
    if (this.dataType === 'FLOAT') {
      return this.validateFLOAT(value) || this.validateCustom(value);
    }
    return this.validateCustom(value);
  }

  validateINT(value) {
    if (value.match(/^[0-9]+$/)) {
      return null;
    } else {
      return { invalidValue: true };
    }
  }

  validateFLOAT(value) {
    if (value.match(/^[0-9]+(\.[0-9]+)?$/)) {
      return null;
    } else {
      return { invalidValue: true };
    }
  }

  validateCustom(value) {
    if (this.valueValidator) {
      const regExp = new RegExp(this.valueValidator);
      if (regExp && this.exactMatch(regExp, value)) {
        return null;
      } else {
        return { invalidCustomValue: true };
      }
    }
    return null;
  }

  exactMatch(regex: RegExp, value) {
    const match = value.match(regex);
    return match && value === match[0];
  }
}
