import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PropertyPathType, SearchType } from '../../../data-selection/data-info-util';
import { FieldDefinitionType } from '../../../dynamic-filter/dynamic-filter.model';
import {
  ConditionChip,
  SupportedPathType
} from '../../../query-condition-input/models/condition-chip.model';
import { Operator } from '../../../query-condition-input/models/operator.model';
import { InputObject } from '../../../query-condition-input/models/util-objects.model';
import { FieldSelectOption } from '../../../../shared/filter-model/filter.model';

@Injectable()
export abstract class SimpleSearchInputService<T> {
  get inputValue(): any {
    return typeof this._inputValue === 'string' ? this._inputValue.trim() : this._inputValue;
  }

  set inputValue(value: any) {
    this._inputValue = typeof value === 'string' ? value.trim() : value;
  }

  inputs: T[];
  displayLabels: string[];
  filteredSelectOptions: FieldSelectOption[];
  showFieldName: boolean;
  activeChip: ConditionChip;
  currentInput;
  showOperator = false;
  fieldName: string;
  inputLabel = { displayLabel: '', i18nLabel: '' };
  input: string;
  _chips: ConditionChip[] = [];
  propertyPathType: PropertyPathType;

  searchType: SearchType;
  dataTypeInput: SupportedPathType | FieldDefinitionType;
  operatorInput: Operator;
  supportedDataTypes: string[];
  defaultDataType: SupportedPathType | FieldDefinitionType;
  searchInputLabel: string;
  showDuplicateButton = true;
  customValuePath: string;
  alreadySelectedDevices: string[] = [];
  valueLabel: string;

  private _inputValue: any;

  resetInputValue() {
    this._inputValue = undefined;
  }

  selectOption(value) {
    this._inputValue = value;
    const option = this.currentInput?.selectOptions?.find((o) => o.value === value);
    this.customValuePath = option?.customValuePath;
    this.valueLabel = option?.label;
  }

  loadInputsFromChip(chip: ConditionChip) {
    this.activeChip = chip;
    this._inputValue =
      typeof this.activeChip.value !== 'string' && this.activeChip.type === 'json'
        ? JSON.stringify(this.activeChip.value)
        : this.activeChip.value;
    this.inputLabel = {
      displayLabel: this.activeChip.i18nLabel ? this.activeChip.i18nLabel : this.activeChip.input,
      i18nLabel: this.activeChip.i18nLabel
    };
    this.input = this.activeChip.input;
    this.dataTypeInput = this.activeChip.type;
    this.operatorInput = this.activeChip.operator;
    this.fieldName = this.activeChip.fieldName;
    this.showFieldName =
      this.activeChip.input === 'metaData' ||
      (this.activeChip.input === 'queryParameters' && this.searchType === 'filter');
    this.setShowDuplicateButton();
    this.setAlreadySelectedDevicesFromChips();
  }

  validateValue(): string | null {
    if (this.operatorInput.technicalID === 'exists') {
      return '';
    } else if (this.dataTypeInput === 'null' || this.dataTypeInput === null) {
      return null;
    } else {
      return this._inputValue;
    }
  }

  resetInputs() {
    this.activeChip = null;
    this._inputValue = '';
    this.fieldName = '';
    this.input = '';
    this.inputLabel = { displayLabel: '', i18nLabel: '' };
    this.dataTypeInput = this.defaultDataType;
    this.showFieldName = false;
    this.showOperator = this.searchType === 'path';
    this.setAlreadySelectedDevicesFromChips();
  }

  setAlreadySelectedDevicesFromChips() {
    const chipDevices: string[] = [];
    this._chips.forEach((chip) => {
      if (chip?.type === 'device-selection') {
        chipDevices.push(chip.value);
      }
    });
    this.alreadySelectedDevices = chipDevices;
  }

  setPropertyPathType(context: PropertyPathType) {
    this.propertyPathType = context ?? PropertyPathType.PREDEFINED_PATH;
  }

  abstract setSupportedDataTypes(dataTypes?: string[]): void;

  abstract getInputsAsObject(): InputObject;

  abstract search(text: string, ...args): Observable<string[]>;

  abstract detectDataType(field: string, ...args): void;

  abstract setInputs(inputs: T[], ...args): void;

  abstract setChips(chipsList: ConditionChip[], ...args): void;

  abstract setShowDuplicateButton(): void;
}
