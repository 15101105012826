import { get } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * PickList can be used to sync two lists. The items which are in the selectedItems list won't appear in the filteredItems list and vice
 * versa.
 */
export class PickList<T> {
  readonly filteredItems$: Observable<T[]>;
  readonly selectedItems$: Observable<T[]>;

  /**
   * If PickList is used with {Objects} this itemSelectorId can be used to identify / select the item
   */
  readonly itemSelectorId: string;

  private _itemList: T[] = [];
  private _filteredItems = new BehaviorSubject<T[]>([]);
  private _selectedItems = new BehaviorSubject<T[]>([]);

  set selectedItems(value: T[]) {
    this._selectedItems.next(value);
  }

  get selectedItems(): T[] {
    return this._selectedItems.getValue();
  }

  get itemList(): T[] {
    return this._itemList;
  }

  set itemList(value: T[]) {
    this._itemList = value;
    this.setFilteredList();
  }

  get filteredItems(): T[] {
    return this._filteredItems.getValue();
  }

  set filteredItems(value: T[]) {
    this._filteredItems.next(value);
  }

  constructor(pickList: Partial<PickList<T>> = {}) {
    this.filteredItems$ = this._filteredItems.asObservable();
    this.selectedItems$ = this._selectedItems.asObservable();
    this.itemSelectorId = pickList.itemSelectorId ?? undefined;
    this.selectedItems = pickList.selectedItems ?? [];
    this.itemList = pickList.itemList ?? [];
    this.filteredItems = pickList.filteredItems ?? [];
  }

  destroy() {
    this._filteredItems.complete();
    this._selectedItems.complete();
  }

  /**
   * Selects an item <T> by adding it to the selectedItemList and removing it from the filteredItemList
   */
  selectItem(item: T): void {
    // prevent duplicates
    if (this.findItem(item, this.selectedItems)) {
      return undefined;
    }
    this.updateSelectedItems(item);
    this.setFilteredList();
  }

  /**
   * Removes an item from the selectedItemList and returns the rest
   */
  removeSelectedItem(item: T): T[] {
    this.selectedItems = this.selectedItems.filter((i) => {
      return get(item, this.itemSelectorId, item) !== get(i, this.itemSelectorId, i);
    });
    this.setFilteredList();
    return this.selectedItems;
  }

  /**
   * Removes all items from the selectedItemList and returns the rest
   */
  removeAllSelectedItems(): T[] {
    this.selectedItems = [];
    this.setFilteredList();
    return this.selectedItems;
  }

  selectAll(): void {
    this.filteredItems.forEach((item) => this.selectItem(item));
  }

  private setFilteredList(): T[] {
    this.filteredItems = this._itemList.filter((i) => !this.findItem(i, this.selectedItems));

    return this.filteredItems;
  }

  private findItem(item: T, list: T[]): T | undefined {
    return list.find(
      (i) => get(item, this.itemSelectorId, item) === get(i, this.itemSelectorId, i)
    );
  }

  private updateSelectedItems(item: T): T[] {
    const isInFilteredList = this.findItem(item, this.filteredItems);
    if (!isInFilteredList) {
      this.selectedItems = [...this.selectedItems, item];
    } else {
      const found = this.findItem(isInFilteredList, this._itemList);
      if (found) {
        this.selectedItems = [...this.selectedItems, item];
      }
    }
    return this.selectedItems;
  }
}
