import {
  CalendarWidgetService,
  DevicePropertyFilter,
  PropertyState
} from '../services/calendar-widget.service';
import { Component, Input, OnInit } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';

@Component({
  selector: 'device-details-filter',
  templateUrl: './device-details-filter.component.html'
})
export class DeviceDetailsFilterComponent implements OnInit {
  @Input() deviceGroup: DevicePropertyFilter[] = [];

  isFilterApplied = false;
  readonly LOCAL_STORAGE_FILTER_KEY = 'calendarDeviceDetailsFilter';

  constructor(
    private calendarWidgetService: CalendarWidgetService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.setDeviceDetailsFilter();
  }

  notifyDevicePropertiesChange(type: any, item: string, state: boolean) {
    this.deviceGroup.forEach((device: DevicePropertyFilter) => {
      if (device.type === type) {
        device.deviceProperties.forEach((property: PropertyState) => {
          if (property.item === item) {
            property.state = state;
          }
        });
      }
    });

    this.localStorageService.set(this.LOCAL_STORAGE_FILTER_KEY, this.deviceGroup);

    setTimeout(() => {
      this.calendarWidgetService.setCalendarApiRenderState(true);
    });
  }

  private setDeviceDetailsFilter() {
    const localStorageFilter: DevicePropertyFilter[] = this.localStorageService.get(
      this.LOCAL_STORAGE_FILTER_KEY
    );

    if (localStorageFilter) {
      const filterActiveDevice = localStorageFilter.filter((storageDevice) =>
        this.deviceGroup.some((device) => device.type === storageDevice.type)
      );
      this.setIconFilterState(localStorageFilter);

      this.deviceGroup.forEach((group) => {
        const indexOfDeviceGroup = this.deviceGroup.findIndex(
          (device) => device.type === group.type
        );

        if (
          this.deviceGroup[indexOfDeviceGroup]?.type &&
          filterActiveDevice[indexOfDeviceGroup]?.deviceProperties?.length
        ) {
          this.deviceGroup[indexOfDeviceGroup].deviceProperties.forEach((property) => {
            filterActiveDevice[indexOfDeviceGroup]?.deviceProperties.forEach((filteredProperty) => {
              if (property.item === filteredProperty.item) {
                property.state = filteredProperty.state;
              }
            });
          });
        }
      });
    }
  }

  private setIconFilterState(localStorageFilter: DevicePropertyFilter[]) {
    this.isFilterApplied = localStorageFilter
      .map((filter) => filter.deviceProperties)
      .some((property: PropertyState[]) => property.some((el) => !el.state));
  }
}
