import { ValidationErrors } from '@angular/forms';
import { EMPTY, from, Observable, of } from 'rxjs';
import { DataSource, dataSourceTypes } from './data-source-base';
import { DataSourceType } from './models';
import { assignWithDefaults } from '../model-utils';
import { WidgetContext } from '../widget-context';
import { translate } from '../../../../../shared/translation-util';
import { DataLoaderRegistryService } from '../../services/data-loader-registry.service';
import { RestRequestService } from '../../../../rest-request/rest-request.service';
import { createRandomIdContext, createWidgetPlaceHolderContext } from '../widget-context-utils';
import { merge } from 'lodash-es';
import { catchError, switchMap } from 'rxjs/operators';

export class RestRequestsDataSource extends DataSource {
  type: DataSourceType = 'rest-requests';
  label = 'External Data Source';
  restRequestId = null;
  exampleContext = null;
  cacheTime = null;

  constructor(props?: Partial<RestRequestsDataSource>) {
    super(props);
    assignWithDefaults(this, props);
  }

  validate(): ValidationErrors | null {
    return this.restRequestId ? null : { missingRestRequestId: true };
  }

  /*
   * Loads the rest-request by retrieving a cached result if a CacheTime is defined.
   * If defined, a second, background request is triggered to get the fresh result.
   * If undefined, the fresh result is retrieved in the first place.
   * */
  loadData(ctx: WidgetContext): Observable<any> {
    const dataLoaderRegistryService = ctx.injector.get(DataLoaderRegistryService, null);

    const source = this.executeLoadRequest(ctx);

    if (dataLoaderRegistryService) {
      const loaderRegistryId = ctx?.filterParams
        ? this.restRequestId + JSON.stringify(ctx.filterParams)
        : this.restRequestId;
      return source.pipe(
        dataLoaderRegistryService.registerDataSource(loaderRegistryId, ctx.priority)
      );
    } else {
      return source;
    }
  }

  private executeLoadRequest(ctx: WidgetContext) {
    const restReqDSService: RestRequestService = ctx.injector.get(RestRequestService);
    const isInEditing = ctx.filterParams === undefined;
    const context = isInEditing
      ? {
          ...merge(createWidgetPlaceHolderContext(ctx), createRandomIdContext()),
          ...(this.exampleContext ?? {})
        }
      : merge(createWidgetPlaceHolderContext(ctx), createRandomIdContext());

    return restReqDSService
      .executeRestRequestDefinitionWithObserverWithCache(
        this.restRequestId,
        { context },
        this.cacheTime
      )
      .pipe(
        switchMap((response) => {
          if (response.body instanceof Blob) {
            return from(response.body.text());
          }
          return of('');
        }),
        switchMap((response) => {
          return of(window['JSONbigString'].parse(response)).pipe(
            catchError((error) => {
              if (error.status === 440) {
                throw error;
              }
              return EMPTY;
            })
          );
        })
      );
  }
}

dataSourceTypes.push({
  name: 'rest-requests',
  i18nLabel: translate('dataSourceConfig.restRequests'),
  constructor: RestRequestsDataSource
});
