import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserAuthService } from './user-auth.service';
import { Constants } from '../../../constants';
import { catchError, map } from 'rxjs/operators';
import { Location } from '@angular/common';

/**
 * Starts a login flow if the user is not logged in
 */
@Injectable({
  providedIn: 'root'
})
export class UserRequiredGuard implements CanActivate {
  constructor(
    private router: Router,
    private location: Location,
    private authService: UserAuthService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkLogin(state.url);
  }

  checkLogin(url: string) {
    return this.authService.isLoggedIn().pipe(
      map((isLoggedIn) => {
        if (isLoggedIn || this.isPathAccessibleWithoutLogin(url)) {
          return true;
        } else {
          if (url !== Constants.routing.home) {
            this.authService.redirectUrl = url;
          }
          const redirectPath = this.location.prepareExternalUrl(url);
          this.router.navigate([Constants.routing.userLogin], {
            queryParams: {
              redirectPath
            }
          });

          return false;
        }
      }),
      catchError((err) => {
        console.error('failed to check login', err);
        this.router.navigate([Constants.routing.error, 'loginCheckFailed']);
        return of(false);
      })
    );
  }

  private isPathAccessibleWithoutLogin(url: string): boolean {
    return url.includes(Constants.routing.userApi);
  }
}
