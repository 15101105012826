import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';

export interface Settings {
  /**
   * metric or imperial
   */
  units: string;

  /**
   * Unit for temperature '°C'
   */
  temperatureUnit: string;

  /**
   * The user selected time format (12 or 24), 24 hours for default
   */
  timeFormat: string;

  /**
   * The user selected language (de or en), null for default
   */
  language: null | string;

  /**
   * The user selected timezone, null for default
   */
  customTimezone: null | string;
}

/**
 * This service should only be used as a local data persistence layer
 * and should not contain any business logic.
 * Related business logic service: see language.service.ts
 */
@Injectable({
  providedIn: 'root'
})
export class SettingsService implements Settings {
  get units(): string {
    return this._units;
  }
  set units(value: string) {
    this._units = value;
    this.persist();
  }

  get temperatureUnit(): string {
    return this._temperatureUnit;
  }
  set temperatureUnit(value: string) {
    this._temperatureUnit = value;
  }

  get timeFormat(): string {
    return this._timeFormat;
  }
  set timeFormat(value: string) {
    this._timeFormat = value;
    this.persist();
  }
  /**
   *  Returns the timezone, system timezone for default
   */
  getTimezone() {
    return this.customTimezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  get customTimezone(): string {
    return this._customTimezone;
  }
  set customTimezone(value: string) {
    this._customTimezone = value;
    this.persist();
  }

  get language(): string {
    return this._language;
  }
  set language(value: string) {
    this._language = value;
    this.persist();
  }

  private _units = 'metric';
  private _temperatureUnit = '°C';
  private _timeFormat = '24';
  private _language: string = null;
  private _customTimezone: string = null;

  constructor(private localStorage: LocalStorageService) {
    const settings = localStorage.get<Settings>('currentSettings');
    if (settings) {
      Object.assign(this, settings);
    }
  }

  persist() {
    const storeSettings: Settings = {
      units: this._units,
      temperatureUnit: this._temperatureUnit,
      timeFormat: this._timeFormat,
      language: this._language,
      customTimezone: this._customTimezone
    };
    this.localStorage.set('currentSettings', storeSettings);
  }
}
