import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BackdateChangeModule } from '../../devices/device-history/backdate-change/backdate-change.module';
import { SharedModule } from '../../shared/shared.module';
import { DeviceImgComponent } from './device-img/device-img.component';
import { DevicePropertySearchComponent } from './device-property-search/device-property-search.component';
import { DeviceSearchMapToLabelPipe } from './device-search/device-search-map-to-label.pipe';
import { DeviceSearchComponent } from './device-search/device-search.component';
import { DeviceTypeSearchComponent } from './device-type-search/device-type-search.component';
import { DeviceInfoBlockIconComponent } from './device-info-block-icon/device-info-block-icon.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PicklistModule } from '../picklist/picklist.module';
import { ImageGalleryDialogComponent } from './image-gallery-dialog/image-gallery-dialog.component';
import { DeviceChangeBadgeComponent } from './device-change-badge/device-change-badge.component';
import { DeviceHistoryChangeHighlightDirective } from './directives/device-history-change-highlight.directive';

@NgModule({
  declarations: [
    DeviceSearchComponent,
    DeviceImgComponent,
    DeviceTypeSearchComponent,
    DevicePropertySearchComponent,
    DeviceSearchMapToLabelPipe,
    DeviceInfoBlockIconComponent,
    ImageGalleryDialogComponent,
    DeviceChangeBadgeComponent,
    DeviceHistoryChangeHighlightDirective
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    BackdateChangeModule,
    DragDropModule,
    InfiniteScrollModule,
    PicklistModule,
    NgOptimizedImage
  ],
  exports: [
    DeviceSearchComponent,
    DeviceImgComponent,
    DeviceTypeSearchComponent,
    DevicePropertySearchComponent,
    DeviceInfoBlockIconComponent,
    ImageGalleryDialogComponent,
    DeviceChangeBadgeComponent,
    DeviceHistoryChangeHighlightDirective
  ]
})
export class DevicesCommonModule {}
