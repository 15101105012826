import { cloneDeep } from 'lodash-es';
import { CustomFeatureProperty, DeviceTypeDefinition } from '../../devices/models/device-types';
import {
  DeviceProperty,
  DevicePropertyGroup
} from '../../dashboards/widgets/filter-widget/filter-widget-edit/filter-widget-edit.model';
import { Device } from '../../devices/models/device';

/**
 * Regular expression for validating device property names.
 *
 * This regular expression validates strings based on the following criteria:
 * - The string can start with a word character (a-z, A-Z, 0-9, _) or a hyphen.
 * - The string cannot be just a digit by itself. It should be followed by some other character.
 * - The string can contain any number of word-characters or hyphens.
 *
 * The regular expression is defined as follows:
 * - `^(?!\d+$)` : This is a negative lookahead makes sure that the string is not just one or many digits.
 * - `[\w-\s]+$` : This part matches any word character (equivalent to `[a-zA-Z0-9_]`) or hyphen and spaces, one or more times, until
 * the end of the string.
 *
 * @link See "device.utils.spec.ts" for test cases.
 */
export const PROPERTY_NAME_REGEX = /^(?!\d+$)[\w-\s]+$/;

export function getFeatureProperties(
  deviceTypeDefinition: DeviceTypeDefinition,
  devicePropertyToFilterOut: DeviceProperty[]
): DevicePropertyGroup[] {
  return deviceTypeDefinition.features
    .map((feature) => {
      const properties = feature.customProperties ? cloneDeep(feature.customProperties) : [];
      if (
        feature.definition === 'insights:general' &&
        !properties.some((p) => p.name === 'thingId')
      ) {
        properties.unshift({ name: 'thingId' } as CustomFeatureProperty);
      }

      if (feature.definition === 'insights:general' && !properties.some((p) => p.name === 'name')) {
        properties.unshift({ name: 'name' } as CustomFeatureProperty);
      }

      return {
        featureDefaultName: feature.defaultName,
        featureDefinition: feature.definition,
        properties: properties
          .filter((property) =>
            keepProperty(feature.defaultName, property, devicePropertyToFilterOut)
          )
          .map((property) => {
            return {
              featureDefaultName: feature.defaultName,
              propertyName: property.name,
              type: property.type
            };
          })
      };
    })
    .filter((item) => item.properties && item.properties.length > 0);
}

interface Conditions {
  namespace: string;
  term?: string;
  selectedDeviceTypes?: string | string[];
  valuePath?: string;
  advancedFilter?: string;
  filterWidgetQuery?: string;
  selectionModeFilter?: string;
  thingIds?: string[];
}

export function assembleDeviceSearchFilter({
  namespace,
  term = '',
  selectedDeviceTypes,
  valuePath,
  advancedFilter,
  filterWidgetQuery,
  selectionModeFilter,
  thingIds
}: Conditions): string {
  const filters: string[] = [];
  let filter = '';
  if (term.length) {
    filters.push(
      `or(ilike(thingId,"${namespace}:*${term}*"),ilike(features/general/properties/name,"*${term}*"))`
    );
  }
  if (selectionModeFilter) {
    filters.push(selectionModeFilter);
  }
  if (Array.isArray(selectedDeviceTypes) && selectedDeviceTypes.length) {
    filters.push(`in(attributes/type,"${selectedDeviceTypes.join('","')}")`);
  }
  if (valuePath) {
    filters.push(`exists(${valuePath})`);
  }
  if (advancedFilter?.length) {
    filters.push(advancedFilter);
  }
  if (filterWidgetQuery) {
    filters.push(filterWidgetQuery);
  }
  if (thingIds?.length) {
    let filter = thingIds.map((id) => `eq(thingId,"${id}")`).join(',');
    if (thingIds.length > 1) {
      filter = 'or(' + filter + ')';
    }
    filters.push(filter);
  }
  if (filters.length) {
    filter = `and(${filters.join(',')})`;
  }
  return filter;
}
function keepProperty(
  defaultName: string,
  property: CustomFeatureProperty,
  devicePropertyToFilterOut: DeviceProperty[]
): boolean {
  const shouldBeRemoved = devicePropertyToFilterOut.find(
    (deviceProperty) =>
      deviceProperty.propertyName === property.name &&
      deviceProperty.featureDefaultName === defaultName
  );
  return shouldBeRemoved === undefined;
}

export function getDeviceTypeIcon(device: Device, deviceTypes: DeviceTypeDefinition[]) {
  if (deviceTypes && device?.attributes?.type) {
    const deviceType = deviceTypes.find((t) => t.type === device.attributes.type);
    if (deviceType) {
      return deviceType.icon;
    }
  }
  return 'rb-ic-devices';
}
