import { Component } from '@angular/core';
import { Constants } from '../../../../../constants';
import { AuthProviderPrefix } from '../../../services/models/auth-provider';
import { UserAuthService } from '../../../services/user-auth.service';

@Component({
  selector: 'user-menu',
  styleUrls: ['./user-menu.component.scss'],
  templateUrl: './user-menu.component.html'
})
export class UserMenuComponent {
  readonly routing = Constants.routing;

  constructor(public userAuthService: UserAuthService) {}

  get showSingleKeyId() {
    return this.userAuthService.provider.startsWith('skid' as AuthProviderPrefix);
  }

  logout() {
    this.userAuthService.logout();
  }
}
