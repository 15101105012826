import { Directive, InjectionToken, OnInit, TemplateRef, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslatedString } from '../../shared-projects/models/project.model';

@Directive()
export abstract class WidgetEditComponentBase implements OnInit {
  /**
   * The properties from the Widget Config
   */
  properties: any;

  /**
   * The id or null if it is a new widget
   */
  widgetId?: string | null;

  /**
   * Name of the dashboard where the widget is or should be placed
   */
  dashboardName?: string | null;

  /**
   * Is called from the WidgetEditComponent before the properties are being saved.
   */
  abstract updateProperties();

  /**
   * Is called from the WidgetEditComponent to check if the properties can be saved now.
   */
  isValid?(): boolean;

  /**
   * If provided, is called before the widget is saved. The widget is saved after the observable completes
   */
  preSave?(): Observable<unknown>;

  /**
   * If provided, is called after the widget is saved
   */
  postSave?(): Observable<any>;

  /**
   * If provided, is called before the widget is removed. The widget is removed after the observable completes
   */
  preRemove?(): Observable<any>;

  /**
   * Is called by the edit component to register a template, that contains the preview.
   * Is implemented by the WidgetEditComponent
   */
  registerPreviewTemplate?(previewTpl: TemplateRef<any>) {
    // do nothing by default
  }

  abstract ngOnInit(): void;
}

export interface WidgetTypeDefinition {
  type: string; // Technical name
  label: TranslatedString;
  description: TranslatedString;
  image?: string;
  component: Type<any>;
  editComponent: Type<WidgetEditComponentBase>;
  defaultColumns?: number;
  defaultTitle?: TranslatedString;
  beta?: boolean; // is this a widget type in development and should only be seen by project admins
  requireOption?: string[]; // are there project options required for this widget to be shown in the add widgets dialog
  hidden?: boolean; // is this widget type hidden from the add widgets dialog
  presets?: WidgetTypePresetDefinition[];
  uniqueId?: string; // In case the widget is unique per dashboard
  category: 'chart' | 'general';
  disallowedPlans?: string[];
  gridLayoutConfig?: GridLayoutConfig;
  reloadable: false | true | 'withDataSource';
  loadingPlaceholder?: Type<any>;
}

/**
 * Optional configuration for dashboards using the grid layout.
 */
export interface GridLayoutConfig {
  defaultRowsCount?: number;
  minRowsCount?: number;
  minColsCount?: number;
  disableWidgetHeightBasedCalculation?: boolean;
  ignoreGridsterItemMargin?: boolean;
  /**
   * Set to 'true' only if you want a widget to be able to be vertically resized bigger than the actual content requires it to be.
   */
  disableFixedHeight?: boolean;
  hideOuterScrollbar?: boolean;
}

export interface WidgetTypePresetDefinition {
  id: string;
  label: TranslatedString;
  description: TranslatedString;
  image?: string;
  data?: any;
}

export type WidgetVisibility = 'VISIBLE' | 'VISIBLE_FOR_ROLES' | 'HIDDEN' | 'HIDDEN_FOR_ROLES';

export const WIDGET_TYPES = new InjectionToken<Array<WidgetTypeDefinition>>('DashboardWidgetTypes');
export const WIDGET_MAX_REFRESH_LIMIT = 1;
