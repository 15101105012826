import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import Picker from 'vanilla-picker/dist/vanilla-picker.js';
import { PickerColor } from '../models/color-picker.model';

@Component({
  selector: 'color-selector',
  templateUrl: './color-selector.component.html',
  styleUrls: ['./color-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ColorSelectorComponent implements AfterViewInit, OnChanges {
  @ViewChild('picker') pickerContainer: ElementRef;

  @Input() alpha = false;

  @Input() color: string;

  @Output() colorChange = new EventEmitter<string>();

  dragging: boolean;

  private colorInitialized = false;

  private picker;

  private _changedColor: string;

  constructor(private zone: NgZone, private changeDetector: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.picker = new Picker({
      parent: this.pickerContainer.nativeElement,
      popup: false,
      alpha: this.alpha,
      editor: true,
      color: this.color,
      onChange: (color) => {
        this.zone.run(() => {
          if (this.colorInitialized) {
            this.colorChanged(color);
          } else {
            this.colorInitialized = true;
          }
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.color && changes.color.currentValue) {
      this.setColor(changes.color.currentValue);
    }
  }

  colorChanged(color: PickerColor) {
    if (color.rgbaString !== this._changedColor) {
      this._changedColor = this.alpha ? color.hex : color.hex.slice(0, 7);
      if (!this.dragging) {
        this.colorChange.next(this._changedColor);
        this.changeDetector.markForCheck();
      }
    }
  }

  setColor(color: string) {
    if (this.picker && color !== this._changedColor) {
      this.picker.setColor(color, true);
    }
  }

  onDragStop() {
    this.dragging = false;
    this.colorChanged(this.picker.color);
  }
}
