import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { LanguageItem } from '@inst-iot/bosch-angular-ui-components/molecules/language-selector/language-selector.component';
import { interval, Subscription } from 'rxjs';
import { Constants } from '../../../../../constants';
import { LanguagesService } from '../../../services/languages.service';
import { LayoutService } from '../../layout.service';

@Component({
  selector: 'language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageSelectorComponent implements OnInit, OnDestroy {
  languages: LanguageItem[] = [];

  now = Date.now();

  readonly routing = Constants.routing;

  private sub: Subscription;

  constructor(
    public languagesService: LanguagesService,
    public layoutService: LayoutService,
    private cd: ChangeDetectorRef
  ) {
    this.sub = interval(1000).subscribe(() => {
      this.now = Date.now();
      this.cd.detectChanges();
    });
  }

  ngOnInit(): void {
    this.languages = this.languagesService.mapLanguages();
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
