import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  ValidatorFn
} from '@angular/forms';
import { Constants } from '../../../constants';
import { isEmpty } from 'lodash-es';

@Directive({
  selector: '[urlValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: UrlValidatorDirective, multi: true }]
})
export class UrlValidatorDirective implements Validator {
  @Input() urlValidator: boolean;

  validate(c: AbstractControl): { [key: string]: boolean } | null {
    return validateUrl(c, this.urlValidator);
  }
}

export function urlValidatorReactive(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return validateUrl(control, true);
  };
}

function validateUrl(c: AbstractControl, urlValidator: boolean) {
  if (urlValidator && !c.value?.match(Constants.validation.urlRegEx)) {
    if (!isEmpty(c.value)) {
      c.markAsDirty();
      c.markAsTouched();
    }
    return { invalidUrl: true };
  }
  return null;
}
