import { Inject, Injectable, Optional } from '@angular/core';
import { WIDGET_TYPES, WidgetTypeDefinition } from '../models/widget-registry.model';

@Injectable()
export class WidgetsRegistryService {
  constructor(@Optional() @Inject(WIDGET_TYPES) public widgetTypes: Array<WidgetTypeDefinition>) {}

  getTypeDefinition(type: string): WidgetTypeDefinition | null {
    return this.widgetTypes.find((wt) => wt.type === type);
  }
}
