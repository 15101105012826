import { DeviceUser } from './device-user';

export class DeviceHistoryComment {
  modifiedAt?: string;
  text?: string;
  user: DeviceUser;

  constructor(comment: Partial<DeviceHistoryComment> = {}) {
    const { text = '', user, modifiedAt = '' } = comment;

    this.text = text;
    this.user = new DeviceUser(user);
    this.modifiedAt = modifiedAt;
    return this;
  }
}
