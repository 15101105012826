import { DsParameter } from '../data-widgets/data-widgets-common';

export enum ColorType {
  BACKGROUND_COLOR = 'backgroundColor',
  TEXT_COLOR = 'textColor'
}

export enum SelectionType {
  STATIC = 'static',
  RANGE = 'range'
}

export interface ColorSettings<T> {
  staticColor?: T;
  colorRangeSettings?: ColorRanges<T>;
}

export interface ColorRanges<T> {
  defaultColor: T;
  defaultLabel?: string;
  colorRanges?: ColorRange<T>[];
}

export interface ColorRange<T> {
  min?: T;
  max?: T;
  equals?: T;
  color: T;
  label?: string;
}

export enum DataFormattingInputType {
  NUMBER = 'number',
  STRING = 'string',
  DATETIME = 'datetime',
  BOOLEAN = 'boolean',
  JSON = 'json'
}

export interface CalculatedColor {
  color: string;
  label?: string;
}

export enum ArrayValueSeparatorType {
  LINE_BREAK = 'lineBreak',
  WHITESPACE = 'whitespace',
  COMMA = 'comma',
  SEMICOLON = 'semicolon',
  LIST_BASED = 'listBased'
}

export type ValueFormattingResolvedConfig =
  | ValueFormattingJsonConfig<string>
  | ValueFormattingStringConfig<string>
  | ValueFormattingNumberConfig<string>
  | ValueFormattingDatetimeConfig<string>
  | ValueFormattingBooleanConfig<string>;

export type ValueFormattingConfig =
  | ValueFormattingJsonConfig<DsParameter>
  | ValueFormattingStringConfig<DsParameter>
  | ValueFormattingNumberConfig<DsParameter>
  | ValueFormattingDatetimeConfig<DsParameter>
  | ValueFormattingBooleanConfig<DsParameter>;

/**
 * Base config for data conditional formatting.
 * Applicable for all data types.
 */
export interface ValueFormattingBaseConfig<T> {
  /**
   * Type of the value to be formatted.
   */
  dataType: DataFormattingInputType;

  /**
   * Is it array or not
   */
  multipleValues: boolean;

  /**
   * Separator to be used between every array item.
   */
  arrayValueSeparator?: ArrayValueSeparatorType;

  /**
   * Prefix(word, letter and etc.) to be placed before the original value.
   */
  prefix: T;
  /**
   * Suffix(word, letter and etc.) to be placed after the original value.
   */
  suffix: T;
  /**
   * Flag to hide/show the value.
   */
  hidden: boolean;
  /**
   * Background color configuration for the value.
   */
  backgroundColor: ColorSettings<T>;
  /**
   * Text color configuration for the value.
   */
  textColor: ColorSettings<T>;
  /**
   * Flag to add/remove inline padding on array values with background color
   */
  automaticPadding: boolean;
}

export interface ValueFormattingStringConfig<T> extends ValueFormattingBaseConfig<T> {
  /**
   * Flag to disable or enable text wrapping, i.e. show it on a single line or multiple.
   */
  wrapText?: boolean;
}

export interface ValueFormattingNumberConfig<T> extends ValueFormattingBaseConfig<T> {
  /**
   * Show or hide the thousands separator for numbers.
   */
  thousandsSeparator: boolean;
  /**
   * Round the number to given decimal points number.
   */
  decimalPoints: number;
}

export interface ValueFormattingDatetimeConfig<T> extends ValueFormattingBaseConfig<T> {
  /**
   * Format the date to available list of predefined formats.
   */
  dateFormat: TimeFormat | string;
  /**
   * Configuration to disable or enable timezone conversion.
   * Datetime is shown as UTC if disabled.
   */
  convertTimezone: boolean;
}

export interface ValueFormattingBooleanConfig<T> extends ValueFormattingBaseConfig<T> {
  /**
   * Displayed string for true values.
   */
  trueText: string;
  /**
   * Displayed string for false values.
   */
  falseText: string;
}

export interface ValueFormattingJsonConfig<T> extends ValueFormattingBaseConfig<T> {
  /**
   * Prettify the provided JSON or keep it in original format.
   */
  autoFormat: boolean;
  /**
   * How many lines the JSON editor should show w/o scrolling.
   */
  jsonEditorMaxLines: number;
  /**
   * Show the full JSON content in a popover.
   */
  showInPopover: boolean;
}

export enum DateFormatType {
  NONE = 'none',
  MEDIUM = 'medium',
  SHORT = 'short',
  TIMESTAMP = 'timeStamp',
  LONG_DATE = 'longDate',
  MEDIUM_DATE = 'mediumDate',
  SHORT_DATE = 'shortDate',
  MEDIUM_TIME = 'mediumTime',
  SHORT_TIME = 'shortTime',
  LOG_TIMESTAMP = 'logTimeStamp',
  LONG = 'long',
  WEEKDAY = 'weekDay',
  ISO = 'iso',
  MEDIUM_SECONDS = 'mediumSeconds',
  MEDIUM_MS = 'mediumMs',
  SHORT_SECONDS = 'shortSeconds',
  SHORT_MS = 'shortMs',
  RELATIVE = 'relative'
}

export interface TimeFormat {
  value: DateFormatType;
  label: string;
}
