import { Component, forwardRef, Input, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  DateRangePickerPreset,
  emptyFunction,
  FormInputComponent,
  getRelativeInfo,
  isAbsolute,
  isRelative,
  PickerValue,
  TimeInputOptions
} from '@inst-iot/bosch-angular-ui-components';
import { FlatpickrOptions } from '@inst-iot/bosch-angular-ui-components/atoms/form-date-fields/flatpickr-date-utils';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import { isString } from 'lodash-es';
import { DatePickerService } from '../services/date-picker.service';
import { translate } from '../translation-util';

export interface TimeRange {
  from: number | string;
  to: number | string;
}

const ONE_DAY: number = 3600000 * 24;

@Component({
  selector: 'date-time-range-popover',
  templateUrl: './date-time-range-popover.component.html',
  styleUrls: ['./date-time-range-popover.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeRangePopoverComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateTimeRangePopoverComponent),
      multi: true
    }
  ]
})
export class DateTimeRangePopoverComponent implements ControlValueAccessor {
  @Input() label: string | TemplateRef<any> = 'dateRange';

  @Input() allowRelative = true;

  @Input() disabled = false;

  @Input() options: FlatpickrOptions = {
    enableTime: true
  };

  @Input() enableTimeSlider = false;

  tempRange: PickerValue = [-ONE_DAY, 0];

  updatedRange: PickerValue = [-ONE_DAY, 0];

  onChange = emptyFunction;

  onTouched = emptyFunction;

  disableApplyButton = false;

  presets: DateRangePickerPreset[];

  timeInputOptions: TimeInputOptions = {
    units: [
      { value: 1, unit: 'ms', label: translate('relTimeInput.milliseconds') },
      { value: 1000, unit: 's', label: translate('relTimeInput.seconds') },
      { value: 1000 * 60, unit: 'm', label: translate('relTimeInput.minutes') },
      { value: 1000 * 60 * 60, unit: 'h', label: translate('relTimeInput.hours') },
      { value: 1000 * 60 * 60 * 24, unit: 'd', label: translate('relTimeInput.days') },
      { value: 1000 * 60 * 60 * 24 * 7, unit: 'w', label: translate('relTimeInput.weeks') }
    ]
  };

  @ViewChild('valueControl') valueComponent: FormInputComponent;

  constructor(
    private datePickerService: DatePickerService,
    private translateService: TranslateService
  ) {
    this.presets = this.datePickerService.getDefaultPresets();
  }

  ngOnInit() {
    this.translateFields();
  }

  translateFields() {
    this.timeInputOptions.units.forEach((element) => {
      element.label = this.translateService.instant(element.label);
    });
  }

  writeValue(value: any) {
    if (!value) {
      this.setDefaultUpdatedRange();
      this.onChange(this.updatedRange);
    } else if (value?.length && this.updatedRange !== value) {
      this.updatedRange = value;
      this.tempRange = value;
    }
  }

  get duration() {
    if (isAbsolute(this.tempRange) && this.tempRange[0] && this.tempRange[1]) {
      const start = new Date(this.tempRange[0]).getTime();
      const end = new Date(this.tempRange[1]).getTime();
      return end - start;
    }
    return 0;
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  validate() {
    if (this.valueComponent?.ngControl.valid) {
      return null;
    }
    return this.valueComponent?.ngControl.errors;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  updateModel() {
    this.updatedRange = this.tempRange;
    this.onChange(this.updatedRange);
  }

  get displayValue() {
    if (isAbsolute(this.updatedRange)) {
      const start = format(new Date(this.updatedRange[0]), 'yyyy-MM-dd HH:mm');
      const end = format(new Date(this.updatedRange[1]), 'yyyy-MM-dd HH:mm');
      return `${start} to ${end}`;
    }
    if (isRelative(this.updatedRange)) {
      const info1 = getRelativeInfo(this.updatedRange[0], this.timeInputOptions.units);
      const info2 = getRelativeInfo(this.updatedRange[1], this.timeInputOptions.units);
      return info1.displayText + (info2.count ? ' - ' + info2.displayText : '');
    }
    return '';
  }

  tempRangeChanged(tempRange: PickerValue) {
    // valid values - non-empty strings and numbers
    const isSelectionInvalid = (selection) =>
      (!isFinite(selection) && !isString(selection)) || selection === '';

    this.disableApplyButton = isSelectionInvalid(tempRange[0]) || isSelectionInvalid(tempRange[1]);
  }

  private setDefaultUpdatedRange() {
    if (this.options.enableTime) {
      this.updatedRange = [-ONE_DAY, 0];
    } else {
      const startOfDay = new Date();
      startOfDay.setHours(0, 0, 0, 0);
      // start of current date - one day
      const from = -(new Date().getTime() - startOfDay.getTime()) - ONE_DAY;
      // start of the current date
      const to = -(new Date().getTime() - startOfDay.getTime());
      this.updatedRange = [from, to];
    }
  }
}
