import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Device, resolutions } from '../../../devices/models/device';
import { DeviceTypeDefinition } from '../../../devices/models/device-types';
import { LoadingEntity, ModalService } from '@inst-iot/bosch-angular-ui-components';
import { AttachmentEntry } from '../../../devices/services/device-attachment.service';
import { getDeviceTypeIcon } from '../device.utils';

@Component({
  selector: 'device-img',
  templateUrl: './device-img.component.html',
  styleUrls: ['./device-img.component.scss']
})
export class DeviceImgComponent implements OnInit, OnChanges {
  @Input() device: Device;
  @Input() deviceTypes: DeviceTypeDefinition[];
  @Input() isClickable = true;
  @Input() maxHeight = '55px';
  @Input() maxWidth = '100%';

  @Input() useCustomSrc = false;
  @Input() customSrc?: string;
  @Input() lazyLoadImg = false;
  @Input() resolution: resolutions = 256;

  // dialog related inputs
  @Input() showOriginalOnClick = false;
  @Input() previewImage: AttachmentEntry;
  @Input() images: AttachmentEntry[] = [];

  @ViewChild('imageDialog') imageDialog: TemplateRef<any>;

  src = '';

  loader = new LoadingEntity<string>();

  constructor(private cdr: ChangeDetectorRef, private modalService: ModalService) {}

  ngOnInit() {
    this.setSource();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.device && changes.device.currentValue) || changes?.previewImage) {
      this.setSource();
    }
  }

  getDeviceTypeIcon(device: Device) {
    return getDeviceTypeIcon(device, this.deviceTypes);
  }

  showDeviceImage() {
    if (this.useCustomSrc && this.customSrc) {
      return true;
    }
    return !this.useCustomSrc && this.device?.hasDeviceImageProperty();
  }

  getFontSizeIcon(): string {
    if (this.maxHeight === this.maxWidth) {
      return this.maxHeight || this.maxWidth;
    } else if (this.maxHeight !== this.maxWidth) {
      const fontSize =
        parseInt(this.maxHeight) > parseInt(this.maxWidth) ? this.maxWidth : this.maxHeight;
      return `${fontSize}`;
    }
    return '55px';
  }

  openOriginalImage() {
    if (this.showOriginalOnClick) {
      this.modalService.open(this.imageDialog);
    }
  }
  private setSource() {
    this.src = this.useCustomSrc ? this.customSrc : this.device.getImageUrl(null, this.resolution);
  }
}
