import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserAuthService } from './user-auth.service';
import { Constants } from '../../../constants';
import { catchError, map, tap } from 'rxjs/operators';
import { ProjectsService } from '../../shared-projects/services/projects.service';
import { ProjectRole } from './models/project-role';

/**
 * Verifies if the role given by the data.role property of a route
 * has access
 */
@Injectable({
  providedIn: 'root'
})
export class RoleRequiredGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: UserAuthService,
    private projectsService: ProjectsService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const isProjectError = next.data['isProjectError'];
    if (next.data['anyRole']) {
      return this.checkLoginAndRedirect(
        () => next.data['anyRole'].some((role) => this.checkRole(role)),
        isProjectError
      );
    }
    if (next.data['role']) {
      return this.checkLoginAndRedirect(() => this.checkRole(next.data['role']), isProjectError);
    }
    this.router.navigate([Constants.routing.error, 'invalidPermission']);
    return false;
  }

  private checkRole(role: string | ProjectRole): boolean {
    if (typeof role === 'string' && this.authService.hasSystemRole(role)) {
      return true;
    }
    return (
      role instanceof ProjectRole &&
      ((role.project && this.projectsService.hasRoleOfProject(role.project, role.role)) ||
        (!role.project && this.projectsService.hasRoleOfCurrentProject(role.role)))
    );
  }

  private checkLoginAndRedirect(checkRoles: () => boolean, isProjectError: boolean) {
    return this.authService.isLoggedIn().pipe(
      map((isLoggedIn) => {
        return isLoggedIn && checkRoles();
      }),
      tap((result) => {
        if (result) {
          return;
        }
        if (isProjectError) {
          const projectErrorRouting = `/project/${this.projectsService.projectName}/error`;
          this.router.navigate([projectErrorRouting, 'invalidPermission']);
        } else {
          this.router.navigate([Constants.routing.error, 'invalidPermission']);
        }
      }),
      catchError((err) => {
        console.error('failed to check login', err);
        this.router.navigate([Constants.routing.error, 'loginCheckFailed']);
        return of(false);
      })
    );
  }
}
