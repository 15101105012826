import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { toNumber } from 'lodash-es';
import { Observable, ReplaySubject } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
import { Constants } from '../../../constants';
import { ProjectUiConfigPublic } from '../../project-admin/white-labeling/custom-homepage-config/custom-homepage-config.model';
import { UiConfig } from '../../shared/api-model';
import { isInFrame, reloadEmbeddedUi } from '../../shared/embed-utils';
import { translate } from '../../shared/translation-util';
import { AppThemingService } from './app-theming.service';
import { GlobalAlertService, GlobalError } from './global-alert.service';
import { AuthProviderPrefix } from './models/auth-provider';
import { ProjectRole } from './models/project-role';
import { SystemRole } from './models/system-role';
import { DialogCloseEventWithResult } from '@inst-iot/bosch-angular-ui-components';

@Injectable({
  providedIn: 'root'
})
export class UserAuthService {
  userName = 'Unknown';

  userEmail = null;

  userId = null;

  provider = null;

  identityProvider = null;

  // e.g. { 'project123': ['admin', 'user'], 'projectABC': ['admin'] }
  projectRoles: Record<string, string[]> = {};

  systemRoles: string[] = [];

  redirectUrl: string;

  logoutTimeout = null;
  logoutTimeoutTimer = null;

  sessionExpired = false;

  properties: { [key: string]: string } = {};

  uiConfigSubject = new ReplaySubject<UiConfig>(1);

  uiConfig: Observable<UiConfig>;

  lastUiConfig: UiConfig = null;

  loginChecked = false;

  isExpiredWarningOpen = false;

  constructor(
    private http: HttpClient,
    private alertService: GlobalAlertService,
    private appTheming: AppThemingService,
    private window: Window,
    private location: Location,
    private router: Router
  ) {
    if (this.http) {
      const uiInfoSource = this.http
        .get<UiConfig>('/ui/api/ui/config')
        .pipe(this.mockCustomDomainContextOnLocal(), share());

      this.uiConfig = new Observable((observer) => {
        if (this.lastUiConfig) {
          observer.next(this.lastUiConfig);
          observer.complete();
        } else {
          uiInfoSource.subscribe({
            next: (data) => {
              this.lastUiConfig = data;
              console.log('uiConfig', data);
              this.parseUiInfo(data);
              this.uiConfigSubject.next(data);
              observer.next(data);
            },
            error: (err) => observer.error(err),
            complete: () => observer.complete()
          });
        }
      });
    }
  }

  /**
   * Observable that completes after emitting once
   */
  getUiConfig(invalidate = false): Observable<UiConfig> {
    if (invalidate) {
      this.lastUiConfig = null;
    }
    return this.uiConfig;
  }

  /**
   * Observable that emits every new UI Config. But emits never an error or completes.
   */
  getUiConfigStream(): Observable<UiConfig> {
    return this.uiConfigSubject;
  }

  parseUiInfo(info: UiConfig) {
    if (info.loggedIn) {
      this.systemRoles = info.systemRoles;
      this.projectRoles = info.projectRoles;
    } else {
      this.systemRoles = [];
      this.projectRoles = {};
    }
    this.userName = info.userName;
    this.userEmail = info.userEmail;
    this.userId = info.userId;
    this.identityProvider = info.userIdentityProvider;
    this.provider = info.provider || this.provider;
    this.loginChecked = info.loginChecked;
    this.properties = info.properties ?? {};
    if (info.loggedIn) {
      this.setupAutoLogout(info.expiration);
    }
  }

  getMaxParallelRequests(): number | null {
    const maxParallelRequests = toNumber(this.properties['max-parallel-requests']);
    if (Number.isFinite(maxParallelRequests)) {
      return maxParallelRequests;
    }
    return null;
  }

  getUserId(): string {
    return this.userId;
  }

  setupAutoLogout(expire: number) {
    if (!expire || this.logoutTimeout === expire) {
      return;
    }
    this.logoutTimeout = expire;
    if (this.logoutTimeoutTimer) {
      clearTimeout(this.logoutTimeoutTimer);
    }
    this.logoutTimeoutTimer = setTimeout(() => {
      this.checkLoginStatus();
    }, expire - new Date().getTime() + 1000);
  }

  checkAutoLogout() {
    const now = new Date().getTime();
    if (this.logoutTimeout && this.logoutTimeout < now) {
      this.checkLoginStatus();
    }
  }

  checkLoginStatus(redirectToLogin = false) {
    this.getUiConfig(true).subscribe({
      next: (info) => {
        if (info.loggedIn) {
          console.log('Expiration UI Info check OK');
          this.sessionExpired = false;
        } else {
          console.log('Expiration UI Info not logged in');
          this.handleSessionExpired(redirectToLogin);
        }
      },
      error: () => {
        console.log('UIInfo Error, session might be expired');
        this.handleSessionExpired(redirectToLogin);
      }
    });
  }

  handleSessionExpired(redirectToLogin = false) {
    this.sessionExpired = true;
    if (redirectToLogin) {
      this.performLogin();
      return;
    }
    if (!this.isExpiredWarningOpen) {
      this.isExpiredWarningOpen = true;
      this.alertService
        .showWarning(
          GlobalError.SessionTimedOut,
          translate('error.sessionTimeoutTitle'),
          translate('nav.login')
        )
        .then((login: DialogCloseEventWithResult) => {
          if (login.event === 'confirmed') {
            this.checkLoginStatus(true);
          }
          this.isExpiredWarningOpen = false;
        });
    }
  }

  hasAnySystemRole(roleName: SystemRole[] | string[]): boolean {
    return !roleName?.length || roleName.some((role) => this.hasSystemRole(role));
  }

  hasSystemRole(roleName: SystemRole | string): boolean {
    return this.systemRoles.includes(roleName);
  }

  hasProjectRole(projectRole: ProjectRole): boolean {
    const managerInheritedRoles = ['manager', 'user', 'access', 'power_user', 'data_provider'];

    // user is an SFDE_ADMIN or SFDE_MANAGER with a role of this project
    if (
      this.isSystemAdmin() ||
      (this.isSystemManager() && managerInheritedRoles.includes(projectRole.role))
    ) {
      return true;
    }

    // if user has no project role or is not logged in we return false
    if (!projectRole.project || !this.projectRoles[projectRole.project]) {
      return false;
    }

    const hasAdminRole = this.projectRoles[projectRole.project].includes(ProjectRole.admin().role);
    return (
      this.projectRoles[projectRole.project].includes(projectRole.role) ||
      (hasAdminRole && projectRole.role !== 'owner')
    );
  }

  isSystemAdmin(): boolean {
    return this.hasSystemRole(SystemRole.admin);
  }

  isSystemManager(): boolean {
    return this.hasSystemRole(SystemRole.manager);
  }

  isLoggedIn(): Observable<boolean> {
    return this.getUiConfig().pipe(map((info) => info.loggedIn));
  }

  isLoggedInStream(): Observable<boolean> {
    return this.getUiConfigStream().pipe(map((info) => info.loggedIn));
  }

  getLoginUrl(authProvider: AuthProviderPrefix, redirectPath?: string) {
    return this.appTheming.assembleLoginUrl(authProvider, redirectPath);
  }

  performLogin(
    redirectPath?: string,
    authProviderPrefix?: AuthProviderPrefix,
    isInIframe = isInFrame()
  ) {
    Object.keys(sessionStorage).forEach((key) => {
      if (key.includes(Constants.notificationBanner.storageKey)) {
        sessionStorage.removeItem(key);
      }
    });

    if (!authProviderPrefix && this.provider) {
      // If no authProviderPrefix use the last used provider, if available
      authProviderPrefix = this.provider.split('-')[0];
    }
    if (isInIframe) {
      this.performIframeLogin(redirectPath, authProviderPrefix);
      return;
    }
    if (authProviderPrefix === 'ciam' && this.appTheming.hasAuthenticationProvider('skid')) {
      this.router.navigate([Constants.routing.userLegacyLoginInfo], {
        queryParams: { newProvider: 'skid-insights', redirectPath: redirectPath }
      });
      return;
    }
    if (authProviderPrefix) {
      this.window.location.href = this.getLoginUrl(authProviderPrefix, redirectPath);
      return;
    }
    this.router.navigate([Constants.routing.userLogin]);
  }

  performIframeLogin(redirectPath: string, authProvider: AuthProviderPrefix) {
    const loginFlowRedirectPath = this.location.prepareExternalUrl('user/login-complete');
    const popup = this.window.open(this.getLoginUrl(authProvider, loginFlowRedirectPath));
    const pollTimer = this.window.setInterval(() => {
      if (popup.closed !== false) {
        this.window.clearInterval(pollTimer);
        reloadEmbeddedUi(redirectPath);
      }
    }, 200);
  }

  createLoginCallback(redirectPath?: string) {
    return (authProvider: AuthProviderPrefix) => this.performLogin(redirectPath, authProvider);
  }

  logout() {
    if (!this.appTheming.appTheme.logoutUrl) {
      this.http.post('/logout', '').subscribe({
        next: () => {
          location.href = '/login?logout';
        },
        error: () => {
          location.href = '/login?logout';
        }
      });
    } else {
      location.href = this.appTheming.appTheme.logoutUrl;
    }
  }

  performLoginAndRetainLocation() {
    const redirectPath = this.location.prepareExternalUrl(this.location.path());
    this.performLogin(redirectPath);
  }

  private mockCustomDomainContextOnLocal() {
    return map((uiConfig: UiConfig) => {
      if (this.appTheming.MOCK_CUSTOM_DOMAIN_CONTEXT_ON_LOCAL) {
        return { ...uiConfig, currentProject: this.appTheming.MOCK_CUSTOM_DOMAIN_CONTEXT_ON_LOCAL };
      } else {
        return uiConfig;
      }
    });
  }

  hasRolesOverwritten(): boolean {
    if (!this.lastUiConfig?.rolesBeforeOverride) {
      return false;
    }
    return (
      this.lastUiConfig?.rolesBeforeOverride['systemRoles'].includes('sfde_admin') ||
      Object.values(this.lastUiConfig?.rolesBeforeOverride['projectRoles'] || {}).some(
        (roles: string[]) => roles.includes('admin')
      )
    );
  }

  getCustomDomainUiConfig(projectName: string): Observable<ProjectUiConfigPublic> {
    const url = `/ui/api/ui/config/${projectName}/public-info`;
    return this.http.get<ProjectUiConfigPublic>(url).pipe(
      map((config) => new ProjectUiConfigPublic(config)),
      tap((config) => {
        if (config) {
          this.appTheming.loadCustomDomainThemeFromPublicConfig(config);
        }
      })
    );
  }
}
