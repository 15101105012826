import {
  DataSourceConfig,
  DsParameterRef,
  DsParameterValue
} from '../../data-widgets/data-widgets-common';
import { LinkDefinitionTarget } from '../../data-widgets/data-widgets-common/lib/hyperlink.service';
import { TranslatedString } from '../../../shared-projects/models/project.model';
import {
  AuthType,
  OAuthTokenRequestConfig
} from '../../../shared/authorization-config/authorization-config.component';
import { DisableConditionChips } from './disable-condition.model';

export type ReqMethod = 'POST' | 'DELETE' | 'GET' | 'PUT' | 'PATCH';

export interface RestReqDefinition {
  id: string;
  uri: string;
  method: ReqMethod;
  headers: Record<string, string>;
  secretHeaders: string[];
  bodyType: string;
  body: any;
  queuingTimeoutInSeconds?: number;
  requestTimeoutInSeconds?: number;
  description?: string;
  restRequestWidgetType?: 'ACTION_BUTTON' | 'EXTERNAL_DATA_SOURCE';
  timeout?: number;
  decoderName?: string;
  oauth2Config?: OAuthTokenRequestConfig;
  authType?: AuthType;
}

export interface RestReqResponse {
  error?: string;
  status: number;
  statusText: string;
  response: any;
}

export interface RestReqDefinitionContainer {
  restReqDefinition: RestReqDefinition;
  exampleContext: any;
  cacheTime: number;
  isValid: boolean;
  hasChanges: boolean;
}

export interface ActionButtonConfiguration {
  title: TranslatedString;
  dataSourceConfig: DataSourceConfig;
  actionConfigId?: string;
  buttonLabel?: string;
  styling?: ActionButtonStyle;
  linkUrl?: string;
  linkTarget?: string;
  triggerConfiguration?: TriggerConfiguration;
  restRequest?: RestReqDefinition;
  restRequestCacheTime?: number;
  restResponse?: RestReqResponse;
  disableConditionsChips?: DisableConditionChips;
}

export enum UploadDataFormat {
  MULTI_PART = 'multiFormPart',
  BINARY = 'binary', // stream upload
  TUS = 'tus'
}

export enum UploadDestination {
  INPUT_HISTORY = 'inputHistory',
  DEVICE_ATTACHMENT = 'deviceAttachment',
  CUSTOM_URL = 'customUrl'
}

export interface UploadWidgetConfiguration {
  title: TranslatedString;
  dataSourceConfig: DataSourceConfig;
  mimeType?: string;
  dataFormat?: UploadDataFormat;
  destinationType?: UploadDestination;
  clearBeforeStart?: boolean;
  actionConfigId?: string;
  restRequest?: RestReqDefinition;
  restResponse?: RestReqResponse;
  restRequestCacheTime?: number;
  disableConditionsChips?: DisableConditionChips;
  allowMultiFileSelection?: boolean;
  allowParallelUpload?: boolean;
  formDataFileKey?: string;
  tusChunkSizeInBytes?: number;
  inputHistory?: InputHistory;
  deviceAttachment?: DeviceAttachment;
}

export interface ActionButtonStyle {
  background?: DsParameterValue | DsParameterRef;
  color?: DsParameterValue | DsParameterRef;
  'border-color'?: DsParameterValue | DsParameterRef;
}

export interface TriggerUrl {
  target: LinkDefinitionTarget;
  url?: string;
  statusCode?: number;
}

export interface TriggerConfiguration {
  triggerUrls?: TriggerUrl[];
  defaultUrl?: { url: string; target: LinkDefinitionTarget };
}

export interface InputHistory {
  deviceId?: string;
  metadata: { keyName: string; value: string }[];
}

export interface DeviceAttachment {
  deviceId: string;
  deviceType: string;
  attachment: string;
}

export const AZURE_ERROR_CODE = 'AADSTS500133';
