import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LogicalOperatorType } from '../models/syntax-node.model';

@Component({
  selector: 'logical-operator-display',
  templateUrl: './logical-operator-display.component.html',
  styleUrls: ['./logical-operator-display.component.scss']
})
export class LogicalOperatorDisplayComponent {
  @Input() logic: LogicalOperatorType = 'and';

  @Output() logicalOperatorChange = new EventEmitter();

  switchLogicalOperator() {
    this.logicalOperatorChange.emit();
  }
}
