import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { SearchFormComponent } from './search-form.component';

@NgModule({
  imports: [CommonModule, FormsModule, SharedModule],
  declarations: [SearchFormComponent],
  exports: [SearchFormComponent]
})
export class SearchFormModule {}
