import { ProjectConfig } from '../models/project.model';

/* The execution of function shadeHexColor takes in a float for its second parameter percent.
    Valid range for the second parameter is -1.0 (darker color) to 1.0 (lighter color). */
export function generateStylesheet(config: ProjectConfig) {
  if (
    !config.properties['primaryColor'] &&
    !config.properties['font'] &&
    !config.properties['supergraphic'] &&
    !config['pageContent']
  ) {
    return;
  }
  let styles;

  if (config.properties['primaryColor']) {
    const primaryColor = config.properties['primaryColor'];
    const minorHoverColor = shadeHexColor(primaryColor, 0.9);
    const majorHoverColor = shadeHexColor(primaryColor, -0.2);
    styles = `
    :root {
      --minor-accent__enabled__front__default: ${primaryColor};
      --minor-accent__enabled__fill__hovered: ${minorHoverColor};
      --minor-accent__enabled__front__hovered: ${majorHoverColor};
      --major-accent__enabled__fill__default: ${primaryColor};
      --major-accent__enabled__fill__hovered: ${majorHoverColor};
      --integrated__enabled__front__hovered: ${primaryColor};
      --integrated__enabled__front__pressed: ${majorHoverColor};
    }
    .-floating, .a-tooltip, [data-tooltip]:hover:before {
      --minor-accent__enabled__front__default: ${primaryColor};
      --minor-accent__enabled__fill__hovered: ${minorHoverColor};
      --minor-accent__enabled__front__hovered: ${majorHoverColor};
      --major-accent__enabled__fill__default: ${primaryColor};
      --major-accent__enabled__fill__hovered: ${majorHoverColor};
      --integrated__enabled__front__hovered: ${primaryColor};
      --integrated__enabled__front__pressed: ${majorHoverColor};
    }
     `;
  }

  if (config.properties['font']) {
    const font = config.properties['font'];
    styles = (styles || '') + `body {font-family: ${font} !important; }`;
  }

  if (config.properties['supergraphic']) {
    styles = (styles || '') + `.rb-app-layout-main__supergraphic { display: none!important; }`;
  }

  if (config['pageContent']) {
    styles = (styles || '') + generateStylesForCustomDomain(config as any);
  }

  const head = document.getElementsByTagName('head')[0];
  const styleElement = document.createElement('style');
  styleElement.id = 'projectStyles';
  styleElement.appendChild(document.createTextNode(styles));
  head.appendChild(styleElement);
}

export function removeStylesheet() {
  const styleElement = document.getElementById('projectStyles');
  if (styleElement) {
    styleElement.parentNode.removeChild(styleElement);
  }
}

/* Takes in a float for its second parameter percent.
   Valid range for the second parameter is -1.0 (darker color) to 1.0 (lighter color)*/
function shadeHexColor(color: string, percent: number) {
  // parse color
  const colors = parseInt(color.slice(1), 16),
    red = colors >> 16,
    green = (colors >> 8) & 0x00ff,
    blue = colors & 0x0000ff,
    // calculation of target value and percent
    target = percent < 0 ? 0 : 255,
    pct = percent < 0 ? percent * -1 : percent;
  /* Calculation with formula returns hex color for majorHoverColor and minorHoverColor
     For the secured functionality the formula should not be changed! */
  return (
    '#' +
    (
      0x1000000 +
      (Math.round((target - red) * pct) + red) * 0x10000 +
      (Math.round((target - green) * pct) + green) * 0x100 +
      (Math.round((target - blue) * pct) + blue)
    )
      .toString(16)
      .slice(1)
  );
}

function generateStylesForCustomDomain(config: ProjectConfig): string {
  const pageContent = config.pageContent;
  let styles = '';

  if (config.logo) {
    styles = (styles || '') + `.home-logo { background-image: url(${config.logo});} `;
  }
  if (pageContent.headerText) {
    styles = (styles || '') + `.slogan:before { content: "${pageContent.headerText}"; } `;
    styles = (styles || '') + `.slogan-text { display: none; } `;
  }
  if (pageContent.descriptionText) {
    styles = (styles || '') + `.teaser:before { content: "${pageContent.descriptionText}"; } `;
    styles = (styles || '') + `.teaser-text { display: none; } `;
  }

  return styles;
}
