import { isNil, isPlainObject } from 'lodash-es';
import {
  DsParameter,
  DsParameterValue,
  replacePlaceholders,
  resolveParameterValue,
  resolvePlaceholders,
  WidgetContext
} from '../data-widgets/data-widgets-common';
import { getDataPathIterator } from '../data-selection/data-info-util';
import {
  ArrayValueSeparatorType,
  CalculatedColor,
  ColorRange,
  ColorSettings,
  ColorType,
  DataFormattingInputType,
  ValueFormattingConfig,
  ValueFormattingResolvedConfig
} from './data-conditional-formatting.model';

/**
 * Used to replace all references(user context, filter params, data source) with the actual values.
 * @param config {ValueFormattingConfig}: Original formatting configuration, contains reference paths
 * @param ctx {WidgetContext}: Context of the widget
 * @param dataSourceArray {any[]}: Data Source data. Used to resolve the 'data source' type references
 * @param index {number}: The index of the data you want to use by getDataPathIterator. This is needed to resolve the 'data source' type references.
 *
 * @returns {ValueFormattingConfig} return the resolved data formatting configuration
 */
export function resolveDataFormattingValues(
  config: ValueFormattingConfig,
  ctx: WidgetContext,
  dataSourceArray: any[],
  index?: number
): ValueFormattingResolvedConfig {
  const resolvedData = {} as ValueFormattingResolvedConfig;
  for (const key in config) {
    const param = config[key];
    if (isPlainObject(param)) {
      // resolve reference, currently supports
      // User Context('insights.'), Filter Params('filterParams.') and Data Source
      if ('ref' in param) {
        const ref = param['ref'];

        if (
          ref.startsWith('insights.') ||
          ref.startsWith('filterParams.') ||
          ref.startsWith('inputFields.')
        ) {
          resolvedData[key] = resolveParameterValue(param as DsParameter, ctx);
        } else {
          resolvedData[key] = isNil(index)
            ? resolvePlaceholders('${' + ref + '}', null, undefined, dataSourceArray, ctx)
            : [...getDataPathIterator(dataSourceArray, ref)][index];
        }
      } else if ('value' in param) {
        resolvedData[key] = param['value'];
      } else {
        // in case no 'ref' and 'value' available, try to resolve the inner properties using recursion
        // used for backgroundColor object
        resolvedData[key] = resolveDataFormattingValues(config[key], ctx, dataSourceArray, index);
      }
    } else if (Array.isArray(param)) {
      resolvedData[key] = param.map((p) =>
        resolveDataFormattingValues(p, ctx, dataSourceArray, index)
      );
    } else {
      resolvedData[key] = config[key];
    }
  }

  return resolvedData;
}

export function calculateColor(
  value: string,
  valueFormattingConfig: ValueFormattingResolvedConfig,
  colorProperty = ColorType.BACKGROUND_COLOR
) {
  if (valueFormattingConfig?.[colorProperty]) {
    return resolveColorForValue(
      value,
      valueFormattingConfig?.[colorProperty],
      valueFormattingConfig.dataType
    ).color;
  }
  return 'transparent';
}

export function resolveColorForValue(
  value: any,
  colorConfig: ColorSettings<string>,
  dataType: DataFormattingInputType
): CalculatedColor {
  if (colorConfig.colorRangeSettings === undefined) {
    return {
      color: colorConfig.staticColor
    };
  } else {
    const matchingColorSettings = colorConfig.colorRangeSettings.colorRanges.filter(
      (colorRange) => {
        if (dataType === DataFormattingInputType.NUMBER) {
          const floatValue = parseFloat(value);
          const min = parseFloat(colorRange.min);
          const max = parseFloat(colorRange.max);
          return min <= floatValue && max >= floatValue;
        } else if (dataType === DataFormattingInputType.STRING) {
          try {
            const regex = new RegExp(colorRange.equals);
            if (regex.test(value)) {
              return true;
            }
          } catch (e) {
            return false;
          }
        } else if (dataType === DataFormattingInputType.DATETIME) {
          const currentDate = new Date(value).getTime();
          const min =
            typeof colorRange.min === 'number'
              ? Date.now() + Number(colorRange.min)
              : new Date(colorRange.min).getTime();
          const max =
            typeof colorRange.max === 'number'
              ? Date.now() + Number(colorRange.max)
              : new Date(colorRange.max).getTime();
          return currentDate >= min && currentDate <= max;
        }
        return false;
      }
    );
    return setCalculatedColor(matchingColorSettings, colorConfig);
  }
}

export function resolveReferenceValue(
  param: DsParameter,
  ctx: WidgetContext,
  dataSource: any[]
): DsParameterValue {
  let resolvedData = null;

  if (param && param['ref']) {
    const ref = param['ref'];

    if (
      ref.startsWith('insights.') ||
      ref.startsWith('filterParams.') ||
      ref.startsWith('inputFields.')
    ) {
      resolvedData = resolveParameterValue(param, ctx);
    } else if (param['ref']) {
      param['ref'].startsWith('[i]')
        ? (resolvedData = null)
        : (resolvedData = replacePlaceholders('${' + param['ref'] + '}', dataSource));
    } else {
      resolvedData = [...getDataPathIterator(dataSource, ref)];
    }
  } else if (param && param['value']) {
    resolvedData = param['value'];
  }
  return resolvedData;
}

export const ArrayValueSeparator: Record<ArrayValueSeparatorType, (value: string) => string> = {
  [ArrayValueSeparatorType.COMMA](value: string): string {
    return value.split('\n').join(', ');
  },
  [ArrayValueSeparatorType.SEMICOLON](value: string): string {
    return value.split('\n').join('; ');
  },
  [ArrayValueSeparatorType.WHITESPACE](value: string): string {
    return value.split('\n').join(' ');
  },
  [ArrayValueSeparatorType.LIST_BASED](value: string): string {
    // Unicode \u2022 is the Bullet Symbol •
    return value
      .split('\n')
      .map((val) => '\u2022 ' + val)
      .join('\n');
  },
  [ArrayValueSeparatorType.LINE_BREAK](value: string): string {
    return value;
  }
};

export const ArrayValueSeparatorForArrayTooltips: Record<
  ArrayValueSeparatorType,
  (value: string, isLast: boolean) => string
> = {
  [ArrayValueSeparatorType.COMMA](value: string, isLast: boolean): string {
    return isLast ? value : value + ', ';
  },
  [ArrayValueSeparatorType.SEMICOLON](value: string, isLast: boolean): string {
    return isLast ? value : value + '; ';
  },
  [ArrayValueSeparatorType.WHITESPACE](value: string, isLast: boolean): string {
    return isLast ? value : value + ' ';
  },
  [ArrayValueSeparatorType.LIST_BASED](value: string): string {
    console.log(value);
    // Unicode \u2022 is the Bullet Symbol •
    return '\u2022 ' + value + '\n';
  },
  [ArrayValueSeparatorType.LINE_BREAK](value: string): string {
    return value + '\n';
  }
};

function setCalculatedColor(
  colorRange: ColorRange<string>[],
  colorConfig: ColorSettings<string>
): CalculatedColor {
  return colorRange.length > 0
    ? {
        color: colorRange[0].color,
        label: colorRange[0].label
      }
    : {
        color: colorConfig.colorRangeSettings.defaultColor,
        label: colorConfig.colorRangeSettings.defaultLabel
      };
}
