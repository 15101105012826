import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { ConditionInputComponent } from './condition-input.component';
import { DataSelectionModule } from '../data-selection/data-selection.module';

@NgModule({
  declarations: [ConditionInputComponent],
  imports: [CommonModule, SharedModule, DataSelectionModule],
  exports: [ConditionInputComponent]
})
export class ConditionInputModule {}
