import { CollectionOverview } from '../../../shared/api-model';
import { FieldDefinition, FieldDefinitionType } from '../../dynamic-filter/dynamic-filter.model';
import { translate } from '../../../shared/translation-util';
import { OperatorType } from '../models/operator.model';
import { cloneDeep } from 'lodash-es';
import { identifyDeepPaths } from '../../data-selection/data-info-util';
import { SupportedPathType } from '../models/condition-chip.model';
import { Role } from '../../../project-admin/role/models/role.model';

export const dateMatcher = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{3})Z/;
export const objectIdSyntax = '[a-f\\d]{24}';
export const objectIdMatcher = new RegExp(`^${objectIdSyntax}$`, 'i');
export const archivedFilterName = 'archivedAt';

const standardBooleanSelectOptions = [
  { value: 'true', label: translate('yes') },
  { value: 'false', label: translate('no') }
];

export function getDashboardConfigViewFilters(
  tags: string[] = [],
  roles: Role[] = []
): FieldDefinition[] {
  return [
    {
      name: 'label',
      type: 'text',
      i18nLabel: translate('admin.projectConfig.name'),
      multipleValuesAllowed: false
    },
    {
      name: 'name', // Technical Dashboard ID
      type: 'text',
      i18nLabel: translate('admin.projectConfig.id'),
      multipleValuesAllowed: false
    },
    {
      name: 'requiredRoles',
      type: 'selection',
      i18nLabel: translate('admin.projectConfig.requiredRoles'),
      selectOptions: roles.map((role: Role) => ({
        value: role.roleName,
        label: role.roleDisplayName,
        avoidTranslate: true
      })),
      customValuesAllowed: true,
      multipleValuesAllowed: false
    },
    {
      name: 'updatedBy.userName',
      type: 'text',
      i18nLabel: translate('admin.projectConfig.updatedBy'),
      multipleValuesAllowed: false
    },
    {
      name: 'updatedAt',
      type: 'datetimerange',
      i18nLabel: translate('admin.projectConfig.updatedAt'),
      multipleValuesAllowed: false
    },
    {
      name: 'tags',
      type: 'text-autocomplete',
      i18nLabel: translate('admin.projectConfig.dashboardTags'),
      selectOptions: tags.map((tag) => ({
        value: tag,
        label: tag,
        avoidTranslate: true
      })),
      customValuesAllowed: true,
      multipleValuesAllowed: false
    }
  ];
}

export function getDataExplorerViewFilters(labels: string[]): FieldDefinition[] {
  return [
    {
      name: 'name',
      type: 'text',
      i18nLabel: translate('queryTemplate.name'),
      multipleValuesAllowed: false
    },
    {
      name: 'creator',
      type: 'text',
      i18nLabel: translate('engFour.createdBy'),
      multipleValuesAllowed: false
    },
    {
      name: 'lastUpdated',
      type: 'datetimerange',
      i18nLabel: translate('dataExplorer.lastUpdated'),
      multipleValuesAllowed: false
    },
    {
      name: 'lastExecution',
      type: 'datetimerange',
      i18nLabel: translate('dataExplorer.lastExecution'),
      multipleValuesAllowed: false
    },
    {
      name: 'executions',
      type: 'number',
      i18nLabel: translate('dataExplorer.executions'),
      multipleValuesAllowed: false
    },
    {
      name: 'labels',
      type: 'selection',
      i18nLabel: translate('queryTemplate.labels'),
      selectOptions: labels.map((l) => ({
        value: l,
        label: l,
        avoidTranslate: true
      })),
      multipleValuesAllowed: true
    }
  ];
}

export function getUserListViewFilters(
  projectHasConsent: boolean,
  roles: Role[]
): FieldDefinition[] {
  return [
    {
      name: 'name',
      type: 'text',
      i18nLabel: translate('users.list.name'),
      multipleValuesAllowed: false
    },
    {
      name: 'email',
      type: 'text',
      i18nLabel: translate('users.list.email'),
      multipleValuesAllowed: false
    },
    {
      name: 'createdAt',
      type: 'datetimerange',
      i18nLabel: translate('users.list.createdAt'),
      multipleValuesAllowed: false
    },
    {
      name: 'lastSeenAt',
      type: 'datetimerange',
      i18nLabel: translate('users.list.lastSeenAt'),
      multipleValuesAllowed: false,
      disableTime: true
    },
    ...(projectHasConsent
      ? [
          {
            name: 'consentStatus',
            type: 'selection' as FieldDefinitionType,
            i18nLabel: translate('users.list.consent'),
            selectOptions: [
              {
                value: 'pending',
                label: translate('users.list.consentStatus.pending')
              },
              {
                value: 'accepted',
                label: translate('users.list.consentStatus.accepted')
              }
            ],
            multipleValuesAllowed: false
          }
        ]
      : []),
    {
      name: 'roles',
      type: 'selection',
      i18nLabel: translate('users.list.tableRole'),
      selectOptions: roles.map((role) => ({
        value: role.roleName,
        label: role.roleDisplayName,
        avoidTranslate: true
      }))
    }
  ];
}

export function getInputDataViewFilters(collections: CollectionOverview[]): FieldDefinition[] {
  return [
    {
      name: 'receivedAt',
      type: 'datetimerange',
      i18nLabel: translate('dynamicFilter.receivedDateRange'),
      incompatible: ['id', 'processedData']
    },
    {
      name: 'sender.user.userName',
      type: 'text',
      i18nLabel: translate('dynamicFilter.senderName')
    },
    {
      name: 'md5',
      type: 'text',
      i18nLabel: translate('inputDataView.md5')
    },
    {
      name: 'size',
      type: 'number',
      i18nLabel: translate('inputDataView.size')
    },
    {
      name: 'duplicates',
      type: 'boolean',
      i18nLabel: translate('processingLog.duplicate')
    },
    {
      name: 'metaData',
      type: 'nested',
      i18nLabel: translate('processingLog.metadataFields')
    },
    {
      name: 'checksumFailure',
      type: 'static',
      i18nLabel: translate('processingLog.checksumFailure')
    },
    {
      name: 'sender.ip',
      type: 'text',
      i18nLabel: translate('dynamicFilter.senderIp')
    },
    {
      name: 'id',
      type: 'text',
      i18nLabel: translate('dynamicFilter.inputDataId'),
      incompatible: ['processedData', 'receivedAt', 'sender.user.userName', 'sender.ip'],
      validator: (value) => (!value.match(/^[a-fA-F0-9]{24}$/) ? ['invalid'] : null)
    },
    {
      name: 'processedData',
      type: 'datasetRequiredId',
      i18nLabel: translate('dynamicFilter.processedData'),
      selectOptions: collections.map((c) => {
        return { value: c.name, label: c.label || c.name, name: c.name };
      }),
      incompatible: [
        'id',
        'receivedAt',
        'sender.user.userName',
        'sender.ip',
        'md5',
        'size',
        'duplicates',
        'metaData',
        'checksumFailure',
        'contentType'
      ]
    },
    {
      name: 'contentType',
      type: 'text',
      i18nLabel: translate('dynamicFilter.type')
    },
    {
      name: 'thingIds',
      type: 'device-selection',
      i18nLabel: translate('inputDataView.device')
    },
    {
      name: archivedFilterName,
      type: 'boolean-selection-with-exists',
      i18nLabel: translate('inputDataView.archivedFilter'),
      selectOptions: standardBooleanSelectOptions
    },
    {
      name: 'deletedAt',
      type: 'boolean-selection-with-exists',
      i18nLabel: translate('inputDataView.deleted'),
      selectOptions: standardBooleanSelectOptions
    }
  ];
}

export function getProcessingLogFilters(collections: CollectionOverview[]): FieldDefinition[] {
  return [
    {
      name: 'updateTime',
      type: 'datetimerange',
      i18nLabel: translate('dynamicFilter.processedDateRange')
    },
    {
      name: 'id.inputId',
      type: 'text',
      i18nLabel: translate('dynamicFilter.inputDataId')
    },
    {
      name: 'processedData',
      type: 'dataset',
      i18nLabel: translate('dynamicFilter.processedData'),
      selectOptions: collections.map((c) => {
        return { value: c.name, label: c.label || c.name, name: c.name };
      })
    },
    {
      name: 'status',
      type: 'selection' as FieldDefinitionType,
      i18nLabel: translate('dynamicFilter.processingStatus'),
      selectOptions: [
        { value: 'SUCCESS', label: 'Successful' },
        { value: 'COMPLETED_WITH_FAILURES', label: 'Failed' },
        { value: 'NEW', label: 'Triggered' },
        { value: 'STARTED', label: 'In Progress' }
      ]
    },
    {
      name: '_id.pipelineId',
      type: 'text' as FieldDefinitionType,
      i18nLabel: translate('processingPipeline.processingLogs.pipelineId')
    }
  ];
}

export function getInputDataIdQueryParams(id: string | Record<string, unknown>) {
  return {
    1: JSON.stringify({
      id,
      operator: 'eq' as OperatorType
    })
  };
}

function getDeepObjectValue(processedDataId: { _id: string | Record<string, any> }, path: string) {
  const splitPath = path.split('.');
  let currentValue = cloneDeep(processedDataId);
  for (const string of splitPath) {
    if (string in currentValue) {
      currentValue = currentValue[string];
    }
  }
  return currentValue;
}

export function getExploreBrowserQueryParams(id: string | Record<string, any>, collection: string) {
  const queryParams = {};
  const processedDataId = { _id: id };
  const paths = identifyDeepPaths(processedDataId);

  paths
    .map((path) => path.path)
    .filter((path) => {
      const splitPath = path.split('.');
      let clonedID = cloneDeep(processedDataId);
      if (path === '_id' && paths.length > 1) {
        return false;
      } else if (splitPath.length > 1) {
        for (const string of splitPath) {
          if (string in clonedID) {
            clonedID = clonedID[string];
          }
        }
        return path.length && clonedID;
      } else {
        return true;
      }
    })
    .forEach((path, index) => {
      queryParams[index + 1] = JSON.stringify({
        [path]: getDeepObjectValue(processedDataId, path),
        type: getValueDataType(getDeepObjectValue(processedDataId, path)),
        operator: 'eq' as OperatorType
      });
    });

  queryParams['collection'] = collection;

  return queryParams;
}

function getValueDataType(id: string | Record<string, any>): SupportedPathType {
  if (typeof id === 'string') {
    if (objectIdMatcher.test(id)) {
      return 'objectId';
    } else if (dateMatcher.test(id)) {
      return 'date';
    }
    return 'string';
  }
  return 'objectId';
}
