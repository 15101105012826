<ng-container *ngIf="showDeviceImage(); else showIcon">
  <img
    [ngSrc]="src"
    [class.pointer-cursor]="isClickable"
    [style.max-height]="maxHeight"
    [style.max-width]="maxWidth"
    width="55"
    height="100"
    (click)="openOriginalImage()"
    data-cy="devicePrimaryImage"
    class="device-img"
    alt="Image"
  />
</ng-container>

<ng-template #showIcon>
  <span
    *ngIf="getDeviceTypeIcon(device) !== undefined"
    [class.pointer-cursor]="isClickable"
    [style.max-height]="maxHeight"
    [style.max-width]="maxWidth"
    [style.font-size]="getFontSizeIcon()"
    data-cy="deviceTypeIcon"
    class="device-img rb-ic {{ getDeviceTypeIcon(device) }} icon-style"
  ></span>
</ng-template>

<ng-template #imageDialog>
  <image-gallery-dialog [images]="images" [previewImage]="previewImage"></image-gallery-dialog>
</ng-template>
