import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableViewWidgetPlaceholderComponent } from './table-view-widget-placeholder.component';
import { SharedModule } from '../../../../shared/shared.module';

@NgModule({
  declarations: [TableViewWidgetPlaceholderComponent],
  imports: [CommonModule, SharedModule],
  exports: [TableViewWidgetPlaceholderComponent]
})
export class TableViewWidgetPlaceholderModule {}
