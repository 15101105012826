import { get } from 'lodash-es';
import { Device } from './device';
import { DeviceHistoryChange } from './device-history-change';
import { DeviceHistoryComment } from './device-history-comment';
import { Thing, ThingFeature, ThingProperties } from './thing';
import { DeviceUser } from './device-user';

export class DeviceHistoryVersionListItem {
  id: string;
  link: string;
  validityBegin: string;
  user: DeviceUser;
  active: boolean;

  constructor(deviceVersion: DeviceHistory) {
    this.id = deviceVersion.id;
    this.link = deviceVersion.id;
    this.validityBegin = deviceVersion.validityBegin;
    this.user = deviceVersion.change[0]?.user;
  }
}

export type VersionListPagination = {
  loadDirection: number;
  upperPage: number;
  lowerPage: number;
  pageSize: number;
  totalCount: number | null;
};

export type DeviceHistoryChanges = {
  featureIds: HistoryFeature[];
  changes: Record<string, string[]>;
};

export enum HistoryFeature {
  All = 'all',
  General = 'general',
  Details = 'details',
  JsonData = 'jsonData',
  Attachments = 'attachments',
  Hyperlinks = 'hyperlinks',
  Notes = 'notes',
  Images = 'images',
  Geofence = 'geofence',
  DeviceLinks = 'deviceLinks'
}

export type HistoryListQuery = Record<'$or', Record<string, string>[]>;

export interface ThingSnapshot {
  thingId?: string;
  policyId: string;
  attributes: ThingProperties;
  features: { [name: string]: ThingFeature };
  revision?: number;
  modified?: string;
}

export interface DeviceHistoryDto {
  thingId?: string;
  labels?: string[];
  comment?: DeviceHistoryComment;
  change?: DeviceHistoryChange[];
  readonly _id?: string;
  readonly snapshots?: ThingSnapshot[];
  readonly hasSnapshot: boolean;
  readonly createdAt?: string; // date-time
  readonly validityBegin?: string; // date-time
}

export class DeviceHistory implements DeviceHistoryDto {
  get icon(): string {
    return this._icon;
  }

  get id(): string {
    return this._id;
  }

  thingId?: string;
  labels?: string[];
  comment?: DeviceHistoryComment;
  change?: DeviceHistoryChange[];
  readonly _id?: string;
  readonly snapshots?: ThingSnapshot[];
  readonly hasSnapshot: boolean;
  readonly createdAt?: string; // date-time
  readonly validityBegin?: string; // date-time

  private _icon: string;

  constructor(deviceHistory = {} as DeviceHistoryDto | DeviceHistory) {
    const history = deviceHistory as DeviceHistory;
    const {
      _id,
      thingId = '',
      labels = [],
      comment,
      change = [],
      snapshots = [],
      hasSnapshot = false,
      createdAt,
      validityBegin
    } = history;

    this._id = _id;
    this.thingId = thingId;
    this.labels = labels;
    this.comment = new DeviceHistoryComment(comment ?? {});
    this.change = DeviceHistory.mapChangeList(change);
    this.snapshots = snapshots;
    this.hasSnapshot = hasSnapshot;
    this.createdAt = createdAt;
    this.validityBegin = validityBegin;
    if (this.change.length === 0) {
      // the UI need a dummy change to display something:
      this.change.push(DeviceHistoryChange.unspecificChange);
    }
    return this;
  }

  static mapChangeList(change) {
    const mappedList: DeviceHistoryChange[] = [];
    change.forEach((aChange) => {
      mappedList.push(new DeviceHistoryChange(aChange));
    });
    return mappedList;
  }

  static asDevice(snapshot: ThingSnapshot, projectName: string): Device {
    if (!snapshot) {
      return null;
    }
    const virtualThing: Thing = {
      thingId: snapshot.thingId,
      policyId: snapshot.policyId,
      attributes: snapshot.attributes,
      features: snapshot.features,
      _modified: snapshot.modified,
      _revision: snapshot.revision
    };
    return new Device(virtualThing, projectName);
  }

  getRoot(): ThingSnapshot | undefined {
    return this.snapshots.find((snapshot: ThingSnapshot) => snapshot.thingId === this.thingId);
  }

  setIcon(icon: string) {
    this._icon = icon;
    return this;
  }

  /**
   * Tries to get the name from a Thing. If the name is not set it returns the ThingId as fallback.
   */
  getName(): string {
    if (!this.getRoot()) {
      return this.thingId;
    }
    const name = get(this.getRoot(), 'features.general.properties.name');
    return name ? name : this.thingId;
  }

  getChildByThingId(thingId: string) {
    return this.snapshots?.filter((snapshot) => snapshot.thingId === thingId)[0];
  }
}
