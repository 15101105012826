import { ApplicationRef, createComponent, Injectable, Injector, Type } from '@angular/core';

@Injectable()
export class ComponentResolver {
  constructor(private injector: Injector, private appRef: ApplicationRef) {}

  public instantiateComponent<T>(
    component: Type<T>,
    componentPropertySetter?: (type: T) => void,
    withWrapper = true
  ): HTMLDivElement {
    const componentRef = createComponent(component, {
      environmentInjector: this.appRef.injector,
      elementInjector: this.injector
    });

    if (componentPropertySetter !== undefined) {
      componentPropertySetter(componentRef.instance);
      componentRef.changeDetectorRef.detectChanges();
    }

    this.appRef.attachView(componentRef.hostView);

    if (withWrapper) {
      const div = document.createElement('div');
      div.appendChild(componentRef.location.nativeElement);
      return div;
    } else {
      return componentRef.location.nativeElement;
    }
  }
}
