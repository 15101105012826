import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserAuthService } from './user-auth.service';
import { v4 as uuid } from 'uuid';
import { HttpStatusCode } from '../../shared/http-utils';
import { Router } from '@angular/router';
import { GlobalAlertService } from './global-alert.service';
import { translate } from '../../shared/translation-util';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private userAuthService: UserAuthService,
    private router: Router,
    private alertService: GlobalAlertService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const reqid = uuid();

    const ignoreUrls = [
      /\/iot-things-api\//,
      /\/rest-request\//,
      /\/rest-request-definition\/\w+\/execute/
    ];

    return next
      .handle(
        request.clone({
          setHeaders: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-Request-Id': reqid
          }
        })
      )
      .pipe(
        tap({
          next: (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse && event.headers.get('X-SessionRefreshed')) {
              this.alertService
                .showInfo(
                  translate('refreshBrowserWarning.description'),
                  translate('refreshBrowserWarning.refreshSessionButton')
                )
                .then(() => {
                  this.userAuthService.performLogin('/ui' + this.router.url);
                });
            }
          },
          error: (error: HttpErrorResponse) => {
            if (error.status === 304) {
              console.debug('Cached request id: ', reqid, ' details: ', error);
            } else {
              console.error('Error in request id: ', reqid, ' error: ', error);
              error['requestId'] = reqid;
              if (
                error.status === HttpStatusCode.unauthorized &&
                !ignoreUrls.some((matcher) => matcher.test(error.url))
              ) {
                this.userAuthService.handleSessionExpired();
              }
            }
            return throwError(error);
          }
        })
      );
  }
}
