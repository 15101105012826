/**
 * Extracts project name from provided url. If the path is not a project one, null will be returned.
 *
 * @example
 * /project/test-project will return test-project
 * /nolayout/project/test-project will return test-project
 * /home will return null
 */
export function getProjectFromPath(url: string): string {
  const urlParts = url.split('/');
  if (urlParts[1] === 'project' && urlParts[2] !== undefined) {
    return urlParts[2];
  } else if (urlParts[1] === 'nolayout' && urlParts[2] === 'project' && urlParts[3] !== undefined) {
    return urlParts[3];
  }
  return null;
}

export function isProjectRoute(url: string): boolean {
  return !!getProjectFromPath(url);
}

/**
 * Identifies a widget edit route within a project's dashboard
 *
 * @example
 * /project/test-project/views/dashboards // will return false
 * /project/test-project/views/dashboards/edit/w0 // will return true
 */
export function isWidgetEditRoute(url: string): boolean {
  const urlParts = url.split('/');
  return (
    urlParts.length === 8 &&
    urlParts[3] === 'views' &&
    urlParts[4] === 'dashboards' &&
    urlParts[5] !== undefined &&
    urlParts[6] === 'edit'
  );
}
