import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAuthService } from '../../core/services/user-auth.service';
import { Constants } from '../../../constants';
import { isInFrame } from '../../shared/embed-utils';
import { combineLatest } from 'rxjs';
import { LoadingEntity } from '@inst-iot/bosch-angular-ui-components';
import { AuthProviderPrefix } from '../../core/services/models/auth-provider';

@Component({
  selector: 'login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
  loader = new LoadingEntity();

  login: (authProvider: AuthProviderPrefix) => void;

  routing = Constants.routing;

  private sub;

  constructor(
    private route: ActivatedRoute,
    private authService: UserAuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.sub = combineLatest([
      this.loader.run(this.authService.isLoggedIn()),
      this.route.queryParams
    ]).subscribe(([loggedIn, params]) => {
      if (params.redirectPath && loggedIn) {
        this.router.navigate([params.redirectPath]);
      }
      this.login = this.authService.createLoginCallback(params.redirectPath);
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  showCookiesInfo() {
    return isInFrame() && !window.navigator.cookieEnabled;
  }

  showLoginButton() {
    return !isInFrame() || window.navigator.cookieEnabled;
  }
}
