import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserSelectorComponent } from './user-selector.component';
import { SharedModule } from '../../../../shared/shared.module';
import { UserModule } from '../../../../user/user.module';

@NgModule({
  declarations: [UserSelectorComponent],
  exports: [UserSelectorComponent],
  imports: [CommonModule, SharedModule, UserModule]
})
export class UserSelectorModule {}
