import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UiConfig } from '../../shared/api-model';
import { Router } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { UserAuthService } from './user-auth.service';
import { Location } from '@angular/common';

export interface MergeCandidate {
  displayName: string;
  email: string;
}

@Injectable({
  providedIn: 'root'
})
export class MergeUserService {
  private userMigrateUrl = '/user/migrate-adfs';
  private userMergeUrl = '/project-management-service/v1/user-merge';
  private mergeCandidates: MergeCandidate;
  private previousUrl = '';

  constructor(
    private http: HttpClient,
    private router: Router,
    private userAuthService: UserAuthService,
    private location: Location
  ) {}

  loadMergeCandidates(): Observable<MergeCandidate> {
    if (this.mergeCandidates) {
      return of(this.mergeCandidates);
    }
    return this.http.get<MergeCandidate>(this.userMergeUrl).pipe(
      tap((mergeCandidates) => {
        this.mergeCandidates = mergeCandidates;
      })
    );
  }

  checkUserMerge(uiConfig: UiConfig): Observable<MergeCandidate> {
    if (
      uiConfig.mergeChecked === false &&
      uiConfig.provider &&
      uiConfig.provider.startsWith('aad')
    ) {
      return this.loadMergeCandidates().pipe(
        switchMap((mergeCandidate) => {
          if (mergeCandidate !== null) {
            this.previousUrl = this.router.url === this.userMigrateUrl ? '/home' : this.router.url;
            this.router.navigate([this.userMigrateUrl]);
          }
          return of(null);
        })
      );
    }
    return of(null);
  }

  migrateUser(): Observable<void> {
    return this.http.post<void>(this.userMergeUrl, null).pipe(
      tap(() => {
        const externalUrl = this.location.prepareExternalUrl(this.previousUrl);
        this.userAuthService.performLogin(externalUrl, 'aad');
      })
    );
  }
}
