<rb-dialog [dialogTitle]="selectedImage.label">
  <div class="mt-3 d-flex justify-content-between align-items-center">
    <button *ngIf="hasMultiple()" type="button" class="rb-btn rb-tiny mr-1" (click)="showPrev()">
      <span class="rb-ic rb-ic-back-left"></span>
    </button>

    <div class="mx-2">
      <ng-container *rbLoading="isLoading"></ng-container>
      <a [href]="selectedImage.url" target="_blank">
        <img
          [ngClass]="{ 'image-maximized': isMaximized, 'image-minimized': !isMaximized }"
          [alt]="selectedImage.label"
          [src]="selectedImage.url"
          [hidden]="isLoading"
          (load)="onImageLoad()"
          (error)="isLoading = false"
        />
      </a>
    </div>

    <button *ngIf="hasMultiple()" type="button" class="rb-btn rb-tiny" (click)="showNext()">
      <span class="rb-ic rb-ic-forward-right"></span>
    </button>
  </div>
  <div class="text-center mt-3">
    <button type="button" class="rb-btn rb-tiny" (click)="toggleViewSize()">
      <ng-container *ngIf="!isMaximized">
        <span class="rb-ic rb-tiny rb-ic-maximize"></span>
        <span>{{ 'devices.images.maximize' | translate }}</span>
      </ng-container>
      <ng-container *ngIf="isMaximized">
        <span class="rb-ic rb-tiny rb-ic-minimize"></span>
        <span>{{ 'devices.images.minimize' | translate }}</span>
      </ng-container>
    </button>
  </div>
</rb-dialog>
