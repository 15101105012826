<div class="rb-custom-input not-empty">
  <div class="input-wrapper pt-3">
    <div class="label">
      <rb-render-tpl [tpl]="label"></rb-render-tpl>
    </div>
  </div>
</div>
<div class="mb-3 pl-3 left-border">
  <div *ngFor="let entry of list; index as i" class="d-flex justify-content-start mb-2">
    <rb-form-input
      [label]="'queryTemplate.key' | translate"
      [ngModel]="entry.key"
      (ngModelChange)="entry.updateKey($event)"
    ></rb-form-input>
    <value-input
      [dataType]="parameter.dataType"
      [label]="'queryTemplate.value' | translate"
      [validationRegExp]="parameter.valueRegex"
      [required]="!parameter.optional"
      [(value)]="entry.value"
      style="flex-grow: 1"
    ></value-input>
    <div>
      <button class="rb-btn rb-tiny" (click)="remove(i)">
        <span class="rb-ic rb-ic-close"></span>
      </button>
    </div>
  </div>
  <button class="rb-btn rb-tiny" (click)="add()">
    <span class="rb-ic rb-ic-add"></span>
    <span class="vlign-icons">{{ 'queryTemplate.addNewKeyPair' | translate }}</span>
  </button>
</div>
