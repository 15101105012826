<ng-template #picker>
  <div>
    <div class="d-inline-flex justify-content-start">
      <div>
        <div *ngIf="isRange" translate>dateTimePicker.from</div>
        <input
          type="text"
          name="from"
          *ngIf="_options.dateInput"
          [ngModel]="fromAsInputText()"
          [dateTimeRangeValidator]="getInputFormats()"
          (blur)="updateFromInputFromEvent($event)"
          (keyup.enter)="updateFromInputFromEvent($event); onEnter(fromControl)"
          #fromControl="ngModel"
          class="form-control"
        />
        <ngb-datepicker
          [ngModel]="fromDate"
          (ngModelChange)="updateFromDate($event)"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [dayTemplate]="dayTplFrom"
          [startDate]="fromDate"
        ></ngb-datepicker>
        <ngb-timepicker
          *ngIf="_options.withTime"
          [minuteStep]="_options.timeMinuteStep"
          [ngModel]="fromTime"
          (ngModelChange)="updateFromTime($event)"
        ></ngb-timepicker>
      </div>
      <div *ngIf="isRange">
        <div *ngIf="isRange" translate>dateTimePicker.to</div>
        <input
          type="text"
          name="to"
          [ngModel]="toAsInputText()"
          [dateTimeRangeValidator]="getInputFormats()"
          (blur)="updateToInputFromEvent($event)"
          (keyup.enter)="updateToInputFromEvent($event); onEnter(toControl)"
          #toControl="ngModel"
          class="form-control"
          *ngIf="_options.dateInput"
        />
        <ngb-datepicker
          [ngModel]="toDate"
          (ngModelChange)="updateToDate($event)"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [dayTemplate]="dayTplTo"
          [startDate]="toDate"
        ></ngb-datepicker>
        <ngb-timepicker
          *ngIf="_options.withTime"
          [minuteStep]="_options.timeMinuteStep"
          [ngModel]="toTime"
          (ngModelChange)="updateToTime($event)"
        ></ngb-timepicker>
      </div>
    </div>
    <div *ngIf="ranges" class="my-3">
      <button class="rb-btn rb-tiny mr-1 mb-1" *ngFor="let r of ranges" (click)="setRange(r.range)">
        {{ r.label }}
      </button>
    </div>

    <div *ngIf="_options.popup" class="d-flex justify-content-between align-items-center mt-3">
      <span>
        <ng-container *ngIf="isRange">
          {{ 'dateTimePicker.duration' | translate }}: {{ duration | duration: 'dhm' }}
        </ng-container>
      </span>
      <span>
        <button class="rb-btn btn-secondary" (click)="onCancel()" translate>
          dateTimePicker.cancel
        </button>
        &nbsp;
        <button class="rb-btn rb-primary" (click)="onApply()" translate>
          dateTimePicker.apply
        </button>
      </span>
    </div>

    <div
      *ngIf="!_options.modal && !_options.popup"
      class="d-flex justify-content-between align-items-center mt-3"
    >
      <span>
        <ng-container *ngIf="isRange">
          {{ 'dateTimePicker.duration' | translate }}: {{ duration | duration: 'dhm' }}
        </ng-container>
      </span>
    </div>

    <div *ngIf="_options.modal" class="d-flex justify-content-between align-items-center mt-3">
      <span>
        <ng-container *ngIf="isRange">
          {{ 'dateTimePicker.duration' | translate }}: {{ duration | duration: 'dhm' }}
        </ng-container>
      </span>
      <span>
        <button class="rb-btn" (click)="onCancel()" translate>dateTimePicker.cancel</button>
        &nbsp;
        <button class="rb-btn rb-primary" (click)="onApply()" translate>
          dateTimePicker.apply
        </button>
      </span>
    </div>
  </div>
</ng-template>
<ng-container *ngIf="_options.inline">
  <ng-container *ngTemplateOutlet="picker"></ng-container>
</ng-container>

<ng-template #dayTplFrom let-date="date" let-focused="focused">
  <ng-container
    *ngTemplateOutlet="dayTpl; context: { date: date, focused: focused, type: 'from' }"
  ></ng-container>
</ng-template>
<ng-template #dayTplTo let-date="date" let-focused="focused">
  <ng-container
    *ngTemplateOutlet="dayTpl; context: { date: date, focused: focused, type: 'to' }"
  ></ng-container>
</ng-template>
<ng-template #dayTpl let-date="date" let-focused="focused" let-type="type">
  <span
    class="custom-day"
    [ngClass]="{
      focused: focused,
      range: isFrom(date) || isTo(date) || isInside(date) || isHovered(date, type),
      faded:
        isInside(date) ||
        (((isFrom(date) && type === 'to') || (isTo(date) && type === 'from')) &&
          !(isFrom(date) && isTo(date))),
      from: isFrom(date) && isRange,
      disabled: isDisabled(date),
      to: isTo(date) && isRange
    }"
    (mouseenter)="onDateHover(date, type)"
    (mouseleave)="onDateHover(null, type)"
  >
    {{ date.day }}
  </span>
</ng-template>
