import {
  Component,
  inject,
  Injector,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  AppLayoutHeaderModel,
  AppMinimalHeaderAction,
  LoadingEntity,
  ModalService
} from '@inst-iot/bosch-angular-ui-components';
import { TranslateService } from '@ngx-translate/core';
import { Subject, timer } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Constants } from '../../../../constants';
import { AppThemingService } from '../../services/app-theming.service';
import { FullscreenService } from '../../services/fullscreen.service';
import { AuthProviderPrefix } from '../../services/models/auth-provider';
import { UserAuthService } from '../../services/user-auth.service';
import { LayoutService } from '../layout.service';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { ProjectSelectorComponent } from './project-selector/project-selector.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { transformProjectUrl } from '../../../shared/project-url-pipe-util';
import { ProjectRole } from '../../services/models/project-role';
import { UserRoleOverwriteComponent } from './user-role-overwrite/user-role-overwrite.component';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { PageSelectorComponent } from './page-selector/page-selector.component';

@Component({
  selector: 'header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  get header(): AppLayoutHeaderModel {
    return this._header;
  }

  loginStatus = new LoadingEntity<boolean>();

  @ViewChild('pageSelector', { static: true })
  pageSelectorComponent: TemplateRef<PageSelectorComponent>;

  @ViewChild('projectSelector', { static: true })
  projectSelectorComponent: TemplateRef<ProjectSelectorComponent>;

  @ViewChild('languageSelector', { static: true })
  languageSelectorComponent: TemplateRef<LanguageSelectorComponent>;

  @ViewChild('userMenu', { static: true })
  userMenuComponent: TemplateRef<UserMenuComponent>;

  login: (authProvider: AuthProviderPrefix) => void;

  readonly routing = Constants.routing;

  private _header: AppLayoutHeaderModel;
  private router: Router = inject(Router);
  private destroy = new Subject<null>();

  constructor(
    public authService: UserAuthService,
    public layoutService: LayoutService,
    private modalService: ModalService,
    private injector: Injector,
    public appThemingService: AppThemingService,
    private fullscreenService: FullscreenService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.login = this.authService.createLoginCallback();
    this.updateHeader();
    this.subscribeToRouterEvents();
  }

  ngOnDestroy(): void {
    this.loginStatus.complete();
    this.destroy.next(null);
  }

  updateHeader(pageTitle?: string): void {
    this.layoutService.currentHeaderTitle.next(pageTitle);

    this._header = {
      ...this._header,
      ...(pageTitle ? { pageTitle } : {}),
      actions: this.getHeaderActions()
    };
  }

  runLoginCheck(tries = 0): void {
    this.loginStatus
      .run(timer(Math.log2(tries + 1) * 1000).pipe(switchMap(() => this.authService.isLoggedIn())))
      .subscribe({
        error: (err) => {
          console.log('loginError', err);
          this.runLoginCheck(tries + 1);
        }
      });
  }

  private shouldDisplayImpersonationButton(): boolean {
    return (
      this.authService.hasProjectRole(ProjectRole.admin(this.layoutService.projectName)) ||
      this.authService.isSystemAdmin() ||
      this.authService.hasRolesOverwritten()
    );
  }

  private getHeaderActions(): AppMinimalHeaderAction[] {
    return [
      this.getHeaderLastViewedPageSelector(),
      this.getHeaderNews(),
      this.getHeaderContact(),
      this.getHeaderHelp(),
      this.getHeaderFullscreen(),
      this.getHeaderLanguageSelector(),
      this.getHeaderUserMenu(),
      this.getHeaderProjectSelector()
    ].filter(Boolean);
  }

  private getHeaderNews(): AppMinimalHeaderAction {
    const newsLink = this.appThemingService.appTheme.newsLink;
    if (!newsLink) {
      return null;
    }
    return {
      title: this.translateService.instant('nav.news'),
      icon: 'boschicon-bosch-ic-worldwideweb',
      href: newsLink,
      targetBlank: true
    };
  }

  private getHeaderContact(): AppMinimalHeaderAction {
    const supportLink = this.appThemingService.appTheme.supportLink;
    if (!supportLink) {
      return null;
    }
    return {
      title: this.translateService.instant('nav.contact'),
      icon: 'boschicon-bosch-ic-service-agent',
      href: supportLink,
      targetBlank: true
    };
  }

  private getHeaderHelp(): AppMinimalHeaderAction {
    const helpLink = this.appThemingService.appTheme.helpLink;
    const apiDocsLink = this.appThemingService.appTheme.apiDocsLink;
    if (helpLink === '' && apiDocsLink === '') {
      return null;
    }

    const subActions: AppMinimalHeaderAction[] = [];
    if (apiDocsLink) {
      subActions.push({
        title: this.translateService.instant('nav.user.api'),
        icon: 'boschicon-bosch-ic-directory-cloud',
        href: apiDocsLink,
        targetBlank: true,
        dataCy: 'apiDocsLink'
      });
    } else if (apiDocsLink !== '') {
      subActions.push({
        title: this.translateService.instant('nav.user.api'),
        icon: 'boschicon-bosch-ic-directory-cloud',
        routerLink: transformProjectUrl(
          this.routing.userApi,
          this.layoutService.projectName
        ) as string,
        dataCy: 'apiDocsLink'
      });
    }
    if (helpLink) {
      subActions.push({
        title: this.translateService.instant('nav.user.guides'),
        icon: 'boschicon-bosch-ic-book-questionmark',
        href: helpLink,
        targetBlank: true,
        dataCy: 'headerHelpLink'
      });
    }

    return {
      title: this.translateService.instant('nav.help'),
      icon: 'boschicon-bosch-ic-book-questionmark',
      routerLink: this.routing.help,
      dataCy: 'headerHelpButtonPopover',
      subActions: subActions
    };
  }

  private getHeaderFullscreen(): AppMinimalHeaderAction {
    if (!this.layoutService.isLoggedIn || !this.layoutService.projectConfig) {
      return null;
    }
    return {
      title: this.translateService.instant('nav.openFullScreen'),
      icon: 'boschicon-bosch-ic-fullscreen',
      dataCy: 'headerOpenFullScreenButton',
      actionPerformed: () => {
        this.fullscreenService.activate();
      }
    };
  }

  private subscribeToRouterEvents(): void {
    this.router.events
      .pipe(
        takeUntil(this.destroy),
        filter(
          (event) =>
            event instanceof NavigationEnd && !event.url.includes(this.layoutService.projectName)
        )
      )
      .subscribe(() => {
        this.updateHeader();
      });
  }

  private getHeaderLanguageSelector(): AppMinimalHeaderAction {
    return {
      title: 'Language Selector',
      dataCy: 'headerLanguageSelector',
      templateRef: this.languageSelectorComponent
    };
  }

  private getImpersonationStatus(): string {
    return ` (${
      this.authService.hasRolesOverwritten()
        ? this.translateService.instant('nav.user.active')
        : this.translateService.instant('nav.user.inactive')
    })`;
  }

  private getHeaderUserMenu(): AppMinimalHeaderAction {
    const impersonationMenuItem: AppMinimalHeaderAction = {
      title:
        this.translateService.instant('nav.user.impersonation') + this.getImpersonationStatus(),
      icon: 'rb-ic rb-ic-user--setting',
      disabled: !location.pathname.includes(this.layoutService.projectName),
      actionPerformed: () => this.modalService.open(UserRoleOverwriteComponent),
      dataCy: 'headerImpersonationButton'
    };

    if (!this.layoutService.isLoggedIn) {
      return {
        title: 'Sign in',
        templateRef: this.userMenuComponent
      };
    }

    return {
      title: 'User Menu', // User Menu
      icon: 'boschicon-bosch-ic-my-brand-frame',
      dataCy: 'headerUserPopoverButton',
      subActions: [
        {
          title: '',
          templateRef: this.userMenuComponent,
          divider: true
        },
        {
          title: this.translateService.instant('nav.user.info'),
          icon: 'boschicon-bosch-ic-user-warning',
          routerLink: transformProjectUrl(
            this.routing.userInfo,
            this.layoutService.projectName
          ) as string,
          dataCy: 'userInfoLink'
        },
        ...(this.shouldDisplayImpersonationButton() ? [impersonationMenuItem] : []),
        {
          title: this.translateService.instant('nav.user.logout'),
          icon: 'boschicon-bosch-ic-logout',
          actionPerformed: () => {
            this.authService.logout();
          },
          dataCy: 'headerLogoutButton'
        }
      ]
    };
  }

  private getHeaderLastViewedPageSelector(): AppMinimalHeaderAction {
    if (!this.layoutService.isLoggedIn) {
      return null;
    }
    return { title: 'Page Selector', templateRef: this.pageSelectorComponent };
  }

  private getHeaderProjectSelector(): AppMinimalHeaderAction {
    if (!this.layoutService.isLoggedIn) {
      return null;
    }
    return {
      title: 'Project Selector',
      templateRef: this.projectSelectorComponent
    };
  }
}
