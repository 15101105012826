import { Pipe, PipeTransform } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ProjectsService } from '../services/projects.service';
import { transformProjectUrl } from '../../shared/project-url-pipe-util';

@Pipe({
  name: 'projectUrl',
  pure: true
})
export class ProjectUrlPipe implements PipeTransform {
  constructor(private projects: ProjectsService, private router: Router) {}

  transform(value: string | string[], project = this.projects.projectName): any {
    return transformProjectUrl(value, project);
  }

  navigate(url: string | string[], extras?: NavigationExtras): Promise<boolean> {
    const projectUrl = this.transform(url);
    if (Array.isArray(projectUrl)) {
      return this.router.navigate(projectUrl, extras);
    } else {
      return this.router.navigate([projectUrl], extras);
    }
  }
}
