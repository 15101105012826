import { translate } from '../../../../shared/translation-util';
import { DsAggregatorConfig, DsAggregatorName } from './data-sources';
import { ValidationErrors } from '@angular/forms';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Aggregates multiple results, which are lists to a single list.
 */
export abstract class DataAggregator {
  protected constructor(
    public name: DsAggregatorName,
    public i18nLabel: string,
    public i18nDesc: string
  ) {}

  getDefaultConfig(): DsAggregatorConfig {
    return {};
  }

  abstract validateConfig(config: DsAggregatorConfig): ValidationErrors | null;

  abstract aggregate(
    config: DsAggregatorConfig,
    sourceStreams: Observable<any[]>[]
  ): Observable<any[]>;
}

/**
 * Puts the results of each source in a list
 * [
 *  [source0entry0, source0entry1, ...],
 *  [source1entry0, source1entry1, ...]
 * ]
 */
export class ListDataAggregator extends DataAggregator {
  constructor() {
    super(
      'list',
      translate('dataSourceConfig.aggregateList'),
      translate('dataSourceConfig.aggregateListDesc')
    );
  }

  aggregate(config: DsAggregatorConfig, sourceStreams: Observable<any[]>[]): Observable<any[]> {
    return combineLatest(sourceStreams);
  }

  validateConfig(config: DsAggregatorConfig): ValidationErrors | null {
    return null;
  }
}

/**
 * Merges each entry of same index into one result entry
 * [
 *  [source0entry0, source1entry0],
 *  [source0entry1, source1entry1],
 *  ...
 * ]
 * with mergeProperties = true:
 * [
 *  {...source0entry0, ...source1entry0},
 *  {...source0entry1, ...source1entry1},
 *  ...
 * ]
 */
export class ZipDataAggregator extends DataAggregator {
  constructor() {
    super(
      'zip',
      translate('dataSourceConfig.aggregateZip'),
      translate('dataSourceConfig.aggregateZipDesc')
    );
  }

  getDefaultConfig(): DsAggregatorConfig {
    return {
      mergeProperties: false // whether to merge the properties of two objects into a single one
    };
  }

  aggregate(config: DsAggregatorConfig, sourceStreams: Observable<any[]>[]): Observable<any[]> {
    return combineLatest(sourceStreams).pipe(
      map((sourceResults) => {
        let maxLen = 0;
        for (let sourceIndex = 0; sourceIndex < sourceResults.length; sourceIndex++) {
          const list = sourceResults[sourceIndex];
          if (Array.isArray(list) && list.length > maxLen) {
            maxLen = list.length;
          }
          if (!Array.isArray(list)) {
            sourceResults[sourceIndex] = [];
          }
        }

        const results = new Array(maxLen).fill(null);
        for (let i = 0; i < results.length; i++) {
          if (config.mergeProperties) {
            results[i] = {};
            for (const item of sourceResults) {
              const entry = item[i];
              if (entry && typeof entry === 'object') {
                Object.assign(results[i], entry);
              }
            }
          } else {
            const entry = [];
            results[i] = entry;
            for (let col = 0; col < sourceResults.length; col++) {
              const value = sourceResults[col][i];
              if (value !== undefined) {
                entry[col] = value;
              } else {
                entry[col] = null;
              }
            }
          }
        }

        return results;
      })
    );
  }

  validateConfig(config: DsAggregatorConfig): ValidationErrors | null {
    return null;
  }
}

/**
 * Merges entries like zip, but by properties which need to have the same value
 */
/**
 * To be implemented later - see https://support.bosch-si.com/browse/SFDE-2682
 */
export class CommonKeyDataAggregator extends DataAggregator {
  constructor() {
    super(
      'commonKey',
      translate('dataSourceConfig.aggregateCommonKey'),
      translate('dataSourceConfig.aggregateCommonKeyDesc')
    );
  }

  getDefaultConfig(): DsAggregatorConfig {
    return {
      keys: [], // needs a json path for every data source
      mergeProperties: false // whether to merge the properties of two objects into a single one
    };
  }

  aggregate(config: DsAggregatorConfig, sourceStreams: Observable<any[]>[]): Observable<any[]> {
    // TODO implement
    return undefined;
  }

  validateConfig(config: DsAggregatorConfig): ValidationErrors | null {
    // TODO implement
    return null;
  }
}

/**
 * Merges entries of all sources into a single list one after another
 * [
 *  source0entry0,
 *  source0entry1,
 *  ...
 *  source1entry0,
 *  source1entry1,
 *  ...
 * ]
 */
export class UnionDataAggregator extends DataAggregator {
  constructor() {
    super(
      'union',
      translate('dataSourceConfig.aggregateUnion'),
      translate('dataSourceConfig.aggregateUnionDesc')
    );
  }

  aggregate(config: DsAggregatorConfig, sourceStreams: Observable<any[]>[]): Observable<any[]> {
    return combineLatest(sourceStreams).pipe(
      map((sourceResults) => {
        const result = [];
        for (const results of sourceResults) {
          result.push(...results);
        }
        return result;
      })
    );
  }

  validateConfig(config: DsAggregatorConfig): ValidationErrors | null {
    return null;
  }
}

export const dataAggregators: DataAggregator[] = [
  new ListDataAggregator(),
  new ZipDataAggregator(),
  // new CommonKeyDataAggregator(),
  new UnionDataAggregator()
];

export function findAggregator(name: DsAggregatorName): DataAggregator {
  return dataAggregators.find((a) => a.name === name);
}
