import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserAuthService } from './user-auth.service';
import { ProjectsService } from '../../shared-projects/services/projects.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { UiConfig } from '../../shared/api-model';

@Injectable({
  providedIn: 'root'
})
export class AutoOpenProjectGuard implements CanActivate {
  constructor(
    private authService: UserAuthService,
    private router: Router,
    private projectService: ProjectsService
  ) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.authService.getUiConfig().pipe(
      this.trackCustomDomainContext(),
      map((config: UiConfig) => {
        return config.loggedIn ? this.autoOpenProject(next) : true;
      })
    );
  }

  private trackCustomDomainContext() {
    return switchMap((config: UiConfig) => {
      const isCustomDomainContext =
        config?.currentProject && config?.currentProject !== '_DEFAULT_';
      if (isCustomDomainContext) {
        this.projectService.restrictedSingleProjectNavigation = config.currentProject;

        return this.authService.getCustomDomainUiConfig(config.currentProject).pipe(
          tap((publicConfig) => {
            this.projectService.projectRedirectPath =
              publicConfig?.properties?.['projectRedirectPath'];
            this.projectService.projectConfigEvents.next(publicConfig.mapToProjectConfigEvent());
          }),
          map(() => config)
        );
      } else {
        return of(config);
      }
    });
  }

  private autoOpenProject(route: ActivatedRouteSnapshot) {
    const source = route.queryParams['source'];
    if (
      this.projectService.restrictedSingleProjectNavigation &&
      this.projectService.projectRedirectPath
    ) {
      console.log(
        'redirect to custom domain project',
        this.projectService.restrictedSingleProjectNavigation
      );
      this.router.navigateByUrl('project/' + this.projectService.restrictedSingleProjectNavigation);
    }
    if (source === 'login' || source === 'oauth') {
      const lastUsedProjects = this.projectService.getLastUsedProjects(this.authService.userId);
      if (lastUsedProjects && lastUsedProjects[0]) {
        console.log('redirect to last used project', lastUsedProjects[0].name);
        this.router.navigateByUrl('project/' + lastUsedProjects[0].name);
      } else {
        this.projectService.getAllProjects().subscribe((projects) => {
          if (projects.length === 1) {
            console.log('redirect to only project', projects[0].name);
            this.router.navigateByUrl('project/' + projects[0].name);
          }
        });
      }
    }
    return true;
  }
}
