import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollectionSelectorComponent } from './collection-selector.component';
import { SharedModule } from '../../shared/shared.module';
import { CollectionCategoriesPopoverComponent } from './collection-categories-popover/collection-categories-popover.component';
import { CollectionRetentionTimeComponent } from './collection-retention-time/collection-retention-time.component';
import { CollectionRetentionTimePopoverComponent } from './collection-retention-time/collection-retention-time-popover/collection-retention-time-popover.component';
import { RouterLinkWithHref } from '@angular/router';
import { SharedProjectsModule } from '../../shared-projects/shared-projects.module';

@NgModule({
  declarations: [
    CollectionSelectorComponent,
    CollectionCategoriesPopoverComponent,
    CollectionRetentionTimeComponent,
    CollectionRetentionTimePopoverComponent
  ],
  imports: [CommonModule, SharedModule, RouterLinkWithHref, SharedProjectsModule],
  exports: [
    CollectionSelectorComponent,
    CollectionCategoriesPopoverComponent,
    CollectionRetentionTimeComponent,
    CollectionRetentionTimePopoverComponent
  ]
})
export class CollectionSelectorModule {}
