import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {
  FormInputAutocompleteDirective,
  LoadingEntity
} from '@inst-iot/bosch-angular-ui-components';
import { Role } from '../../project-admin/role/models/role.model';
import { RoleStoreService } from '../../project-admin/role/services/role-store.service';

@Component({
  selector: 'role-search',
  templateUrl: './role-search.component.html',
  styleUrls: ['./role-search.component.scss']
})
export class RoleSearchComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() label: string;

  @Input() dataCyInput;

  @Input() dataCyRoleEntry;

  @Input()
  set selectedRoles(roles: Role[]) {
    this._selectedRoles = roles;
    this.setFilteredRoles();
  }

  @Output() roleSelected = new EventEmitter<Role>();

  @ViewChild(FormInputAutocompleteDirective) autoCompleteDirective;

  control: UntypedFormControl;

  roleName: string;

  deviceLabels = [];

  term = '';

  currentTerm = '';

  loader = new LoadingEntity<any>();

  searchFunc;

  roles: Role[];

  filteredRoles: Role[];

  hasMore = false;

  rolesLimit = 100;

  _selectedRoles: Role[] = [];

  private sub;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private roleStoreService: RoleStoreService,
    private cdr: ChangeDetectorRef
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
    this.initFormControl();

    this.searchFunc = this.search.bind(this);
  }

  onChange = (value: string) => {
    // do nothing
  };
  onTouched = () => {
    // do nothing
  };

  ngOnInit() {
    this.sub = this.control.valueChanges.subscribe((label) => this.updateValue(label));
  }

  ngOnDestroy(): void {
    this.loader.complete();
    this.sub?.unsubscribe();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(roleName: string): void {
    if (!roleName) {
      this.roleName = '';
      this.control.patchValue('');
      return;
    }

    this.loader
      .run(
        this.roleStoreService.getRoleByRoleName(roleName).pipe(
          catchError((e) => {
            this.writeValue(null);
            return throwError(e);
          })
        )
      )
      .subscribe((name) => {
        this.roleName = name;
        this.control.patchValue(name);
        this.cdr.markForCheck();
      });
  }

  selectRole(role: Role) {
    this.roleSelected.next(role);
    this.filteredRoles = Role.removeRoleByName(role.roleName, this.filteredRoles);
    this.roleName = role.roleName;
  }

  updateValue(roleName: string) {
    if (roleName === '') {
      this.roleName = roleName;
      this.onChange('');
    } else {
      this.control.markAsDirty();
      this.onChange(this.roleName);
      this.control.updateValueAndValidity({ emitEvent: false });
    }
  }

  onEnterPressed(keyDownEvent: KeyboardEvent): void {
    if (keyDownEvent.code === 'Enter' || keyDownEvent.code === 'NumpadEnter') {
      keyDownEvent.preventDefault();
      const role = Role.findRoleByName(this.control.value, this.roles);
      if (role) {
        this.selectRole(role);
        this.autoCompleteDirective.select(role.roleName);
      }
    }
  }

  search(text: string, start = 0): Observable<string[]> {
    if (typeof text !== 'string') {
      return null;
    }
    this.term = text.trim();
    if (this.term === this.roleName) {
      this.term = '';
    }
    if (start === 0) {
      this.roles = [];
    }

    this.currentTerm = this.term;

    if (this.term.includes(' ')) {
      this.term = this.term.split(' ').join('*');
    }

    return this.loader.run(
      this.roleStoreService.getRoleList(this.term, start / this.rolesLimit, this.rolesLimit).pipe(
        map((results) => {
          this.hasMore = results.length === this.rolesLimit;

          if (!results.length) {
            return null;
          }

          return results.map((d) => {
            this.roles.push(d);
            return d.roleName;
          });
        }),
        tap(() => {
          this.setFilteredRoles();
          this.cdr.markForCheck();
        })
      )
    );
  }

  loadMore() {
    this.loader.run(this.search(this.currentTerm, this.roles.length)).subscribe();
  }

  setFilteredRoles() {
    if (this.roles) {
      this.filteredRoles = [...this.roles];
      this._selectedRoles.forEach((role) => {
        this.filteredRoles = Role.removeRoleByName(role.roleName, this.filteredRoles);
      });
    }
  }

  private initFormControl() {
    this.control = new UntypedFormControl('', [
      () => {
        if (this.ngControl.invalid) {
          return { invalid: true };
        }
        return null;
      }
    ]);
  }
}
