import { BaseFieldDefinition } from '../../shared/filter-model/filter.model';

export type FieldDefinitionType = typeof SupportedFieldDefinitionTypes[number];

/*
 * Please keep these lines sorted alphabetically
 * */
export const SupportedFieldDefinitionTypes: string[] = [
  'boolean',
  'boolean-selection-with-exists',
  'collections',
  'custom',
  'dataset',
  'datasetRequiredId',
  'datetimerange',
  'device-selection',
  'hyperlink',
  'multi-selection',
  'multi-string-selection',
  'nested',
  'number',
  'range',
  'selection',
  'static',
  'text',
  'text-autocomplete'
];

export interface FieldDefinition extends BaseFieldDefinition {
  /**
   * Type of the field
   */
  type: FieldDefinitionType;

  /**
   * Whether the filter can be removed
   */
  mandatory?: boolean;

  /**
   * What is initially selected
   */
  initialValue?: any;

  /**
   * Other fields that are required in conjunction with this filter
   * first level: OR, second level AND
   * [['fieldA', 'fieldB'],['fieldC']] = (fieldA and fieldB) or (fieldC)
   */
  requires?: string[][];

  /**
   * Fields that this one is incompatible with
   */
  incompatible?: string[];

  /**
   * Function to render a label
   */
  getLabel?: (value: any) => string;

  /**
   * Property Type 'Selection'
   * Allow Custom Values
   */
  customValuesAllowed?: boolean;

  /**
   * Property Type 'Selection'
   * Allow Multiple Values
   */
  multipleValuesAllowed?: boolean;

  /**
   * Used to disable time selection in datetimerange filter
   */
  disableTime?: boolean;
}

export interface DynamicFilterConfig {
  fields: FieldDefinition[];
}

export interface DynamicFilter {
  field: FieldDefinition;
  value: any;
  valueLabel?: string;
}

export interface FilterValues {
  [fieldName: string]: any;
}
