<div class="selector">
  <rb-form-input
    [label]="'dataSelection.filterManuallyEnter' | translate"
    class="static w-100"
    #filterInput
    [ngModel]="filter"
    (ngModelChange)="updateFilter($event)"
    data-cy="dataStructureSelectorInput"
  ></rb-form-input>

  <span class="rb-dropdown-item text-danger" *ngIf="!paths?.length">
    <span class="rb-ic rb-ic-alert-warning"></span>
    {{ 'dataSelection.noPaths' | translate }}
  </span>

  <a
    class="rb-dropdown-item"
    *ngFor="let info of displayPaths"
    href="#"
    [hidden]="!isAllowed(info)"
    [class.active]="info.path === _selected"
    (mousedown)="mouseSelect = true"
    (click)="select(info.path)"
    [attr.data-cy]="'dataStructureItem-' + info.path"
  >
    <span
      *ngFor="let p of info.getPath(); let i = index; let isLast = last"
      [class.last-path-part]="isLast"
    >
      {{ i ? '.' : '' }}{{ p }}
    </span>
    &nbsp;
    <small class="type">{{ info.types }}</small>
  </a>
</div>
