import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimpleSearchEditorComponent } from './simple-search-editor/simple-search-editor.component';
import { SimpleSearchChipComponent } from './simple-search-chip/simple-search-chip.component';
import {
  CalloutModule,
  DropdownModule,
  FormFieldsModule,
  LoadingModule,
  LoadingSpinnerModule
} from '@inst-iot/bosch-angular-ui-components';
import { FormsModule } from '@angular/forms';
import { SimpleSearchComponent } from './simple-search.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { SelectDatasetFilterModule } from '../select-dataset-filter/select-dataset-filter.module';
import { SimpleSearchInputFilterService } from './simple-search-input/services/simple-search-input-filter.service';
import { SimpleSearchInputPathService } from './simple-search-input/services/simple-search-input-path.service';
import { SimpleSearchInputComponent } from './simple-search-input/simple-search-input.component';
import { DevicesCommonModule } from '../devices-common/devices-common.module';
import { QueryConditionInputModule } from '../query-condition-input/query-condition-input.module';
import { ManualPropertyPathValidatorDirective } from './simple-search-input/directives/manual-property-path-validator.directive';

@NgModule({
  declarations: [
    SimpleSearchEditorComponent,
    SimpleSearchChipComponent,
    SimpleSearchComponent,
    SimpleSearchInputComponent,
    ManualPropertyPathValidatorDirective
  ],
  exports: [
    SimpleSearchChipComponent,
    SimpleSearchEditorComponent,
    SimpleSearchComponent,
    SimpleSearchInputComponent
  ],
  imports: [
    CommonModule,
    FormFieldsModule,
    FormsModule,
    DropdownModule,
    TranslateModule,
    LoadingSpinnerModule,
    CalloutModule,
    LoadingModule,
    SharedModule,
    SelectDatasetFilterModule,
    DevicesCommonModule,
    QueryConditionInputModule
  ],
  providers: [SimpleSearchInputFilterService, SimpleSearchInputPathService]
})
export class SimpleSearchModule {}
