import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Constants } from '../../../constants';
import { DevicesService } from '../../devices/services/devices.service';
import { ProjectConfig } from '../../shared-projects/models/project.model';
import { ProjectUrlPipe } from '../../shared-projects/pipes/project-url.pipe';
import { ProjectsService } from '../../shared-projects/services/projects.service';
import { excludeAccessAndCustomRoles, ProjectRole } from './models/project-role';
import {
  createNavigation,
  NavigationItem,
  NavigationSubItem,
  NavigationTabItem
} from './models/side-navigation.model';
import { SystemRole } from './models/system-role';
import { UserAuthService } from './user-auth.service';
import { translate } from '../../shared/translation-util';
import { ServicePlanType } from '../../shared-projects/models/service-plan.model';

export type InsightsNavigation = { side: NavigationItem[]; bottom: NavigationItem[] };

@Injectable()
export class NavigationFactoryService {
  private _insightsNavigation = new ReplaySubject<InsightsNavigation>(1);
  insightsNavigation$: Observable<InsightsNavigation> = this._insightsNavigation.asObservable();
  isSystemMenu = false;

  readonly routing = Constants.routing;

  private projectConfig: ProjectConfig;

  /* Only valid if user is Access-Only */
  private deviceCount: number;

  private isLoggedIn: boolean;

  private isCustomMenuUsed: boolean;

  constructor(
    private translateService: TranslateService,
    private projectUrlPipe: ProjectUrlPipe,
    private authService: UserAuthService,
    private deviceService: DevicesService,
    private projectsService: ProjectsService
  ) {
    this.isCustomMenuUsed = !!this.projectsService.customMenu.value;
    this.subscribeToServices();
  }

  subscribeToServices(): void {
    combineLatest([this.projectsService.projectConfigEvents, this.authService.isLoggedIn()])
      .pipe(
        switchMap(([projectConfigEvent, isLoggedIn]) => {
          this.isLoggedIn = isLoggedIn;
          this.projectConfig = projectConfigEvent?.config ?? null;

          return this.projectConfig &&
            this.projectsService.isAccessOnlyUser() &&
            this.projectsService.projectName
            ? this.deviceService.countDevices()
            : of(-1);
        })
      )
      .subscribe({
        next: (count: number) => {
          this.deviceCount = count;
          if (this.projectsService.inProject() && this.projectConfig) {
            this.isCustomMenuUsed = !!this.projectsService.customMenu.value;
            const sideNavigation = this.createSideNavigation();

            this._insightsNavigation.next({
              side: sideNavigation,
              bottom: this.buildInsightsBottomNavigation()
            });
          } else if (this.authService.hasAnySystemRole([SystemRole.admin, SystemRole.manager])) {
            this._insightsNavigation.next({
              side: this.buildInsightsAdminNavigation(),
              bottom: []
            });
          } else {
            this._insightsNavigation.next({ side: [], bottom: [] });
          }
        },
        error: (error) => console.error('Navigation error', error)
      });
  }

  buildInsightsBottomNavigation(): NavigationItem[] {
    const menuSwitchItem = this.buildInsightsBottomNavigationMenuSwitch();

    return menuSwitchItem ? [menuSwitchItem] : [];
  }

  private buildInsightsBottomNavigationMenuSwitch(): NavigationItem {
    const isProjectAdmin = this.authService.hasProjectRole(
      ProjectRole.admin(this.projectsService.projectName)
    );

    if (!isProjectAdmin) {
      return null;
    }

    return {
      id: 'switch',
      title: this.isSystemMenu
        ? this.toTranslation(translate('nav.customMenu'))
        : this.toTranslation(translate('nav.systemMenu')),
      icon: this.isSystemMenu ? 'boschicon-bosch-ic-user' : 'boschicon-bosch-ic-user-service',
      actionPerformed: this.onSwitchMenuClick.bind(this)
    };
  }

  buildInsightsSideNavigation(): NavigationItem[] {
    const isSystemAdminOrManager = this.hasSystemRoles([SystemRole.manager, SystemRole.admin]);

    const navigation: NavigationItem[] = this.insightsNavigation
      .filter((item: NavigationItem) => this.isAllowedNav(item))
      .filter((item: NavigationItem) => this.projectsService.hasAnyRole(item.roles))
      .filter(
        (item: NavigationItem) =>
          !item.systemRoles || this.authService.hasAnySystemRole(item.systemRoles)
      )
      .filter(
        (item: NavigationItem) =>
          item.title !== this.toTranslation('nav.devices.devices') ||
          this.devicesNavigationIsVisible()
      )
      .filter(
        (item: NavigationItem) =>
          item.title !== this.toTranslation('nav.explore') ||
          this.projectsService.hasQueryTemplatesAccess()
      )
      .filter(this.getOptionsFilter());

    navigation.forEach((navigationItem: NavigationItem) => {
      if (navigationItem.subItems?.length) {
        navigationItem.subItems = this.buildInsightsSubNavigations(navigationItem.subItems);
      }
    });
    return navigation;
  }

  buildInsightsSubNavigations(navigationSubItem: NavigationSubItem[]): NavigationSubItem[] {
    const subItems = this.filterNavigationSubItems(navigationSubItem);

    subItems.forEach((subItem) => {
      if (subItem.subItems?.length) {
        subItem.subItems = this.filterNavigationSubItems(subItem.subItems);
      }
    });

    return subItems;
  }

  private filterNavigationSubItems(
    navigationSubItems: NavigationSubItem[] | NavigationTabItem[]
  ): NavigationSubItem[] | NavigationTabItem[] {
    const isSystemAdminOrManager = this.hasSystemRoles([SystemRole.manager, SystemRole.admin]);

    return navigationSubItems
      .filter((nav) => this.isAllowedNav(nav))
      .filter((nav) => this.projectsService.hasAnyRole(nav.roles) || isSystemAdminOrManager)
      .filter((nav) => !nav.systemRoles || this.authService.hasAnySystemRole(nav.systemRoles))
      .filter(this.getOptionsFilter())
      .filter(
        (item) =>
          item.title !== this.toTranslation('nav.devices.history') ||
          !this.projectsService.isAccessOnlyUser()
      );
  }

  buildInsightsAdminNavigation(): NavigationItem[] {
    return this.adminNavigation.filter((nav) => {
      if (nav.subItems?.length) {
        nav.subItems = nav.subItems.filter((subItem) => {
          return this.authService.hasAnySystemRole(subItem.roles);
        });
      }
      return this.authService.hasAnySystemRole(nav.roles);
    });
  }

  getRequiredRolesForNavigationItem(router: string): string[] {
    for (const nav of this.insightsNavigation) {
      const roles = this.findRolesInNavigation(nav, router);
      if (roles.length > 0) {
        return roles;
      }
    }

    return [];
  }

  private findRolesInNavigation(nav: NavigationItem, router: string): string[] {
    if (nav.subItems?.length) {
      for (const subNav of nav.subItems) {
        if (subNav.routerLink?.includes(router)) {
          return subNav.roles || [];
        } else if (subNav.subItems?.length) {
          const roles = this.findRolesInNavigation(subNav as NavigationItem, router);
          if (roles.length > 0) {
            return roles;
          }
        }
      }
    }

    return [];
  }

  private onSwitchMenuClick() {
    this.isSystemMenu = !this.isSystemMenu;

    this._insightsNavigation.next({
      side: this.createSideNavigation(),
      bottom: this.buildInsightsBottomNavigation()
    });
  }

  private toTranslation(key: string) {
    return this.translateService.instant(key);
  }

  private getOptionsFilter() {
    return (item: NavigationItem) =>
      !item?.options ||
      (this.projectConfig &&
        item?.options?.some(
          (option) => !!this.projectConfig?.options && this.projectConfig?.options[option]
        ));
  }

  private hasSystemRoles(roles: string[]): boolean {
    return this.authService.hasAnySystemRole(roles) ?? this.isLoggedIn;
  }

  private devicesNavigationIsVisible(): boolean {
    if (this.projectsService.isAccessOnlyUser()) {
      return this.deviceCount > 0;
    }
    return true;
  }

  private isAllowedNav(nav: NavigationItem | NavigationSubItem): boolean {
    if (nav.routerLink === Constants.routing.processingPipelines) {
      return this.projectsService.isPayAsYouGoPlan || this.projectsService.isPipelineProject;
    }

    // hide services if they are not allowed to be shown
    if (
      nav.routerLink === Constants.routing.services &&
      this.authService.properties['show-services'] !== 'true'
    ) {
      return false;
    }

    if (this.authService.hasSystemRole(SystemRole.admin)) {
      return true;
    }

    const onlySystemAdminRoutes = [Constants.routing.adminCollectionConfig];
    return !onlySystemAdminRoutes.includes(nav.routerLink);
  }

  get insightsNavigation(): NavigationItem[] {
    return this.pureInsightsNavigation.map(this.transformPureNavigation.bind(this));
  }

  get pureInsightsNavigation(): NavigationItem[] {
    return [
      {
        id: 'overview',
        title: translate('nav.overview'),
        routerLink: this.routing.home,
        routerActiveExactMatch: true,
        icon: 'boschicon-bosch-ic-home'
      },
      {
        id: 'explore',
        title: translate('nav.explore'),
        icon: 'boschicon-bosch-ic-document-search',
        subItems: [
          {
            id: 'explorer',
            title: translate('nav.sfde.explorer'),
            routerLink: this.routing.exploreExplorer,
            routerActiveExactMatch: false
          },
          {
            id: 'browser',
            title: translate('nav.sfde.browser'),
            routerLink: this.routing.exploreBrowser,
            routerActiveExactMatch: true,
            roles: excludeAccessAndCustomRoles
          },
          {
            id: 'analyzer',
            title: translate('nav.sfde.analyzer'),
            routerLink: this.routing.exploreAnalyzer,
            routerActiveExactMatch: true,
            roles: ['power_user']
          },
          {
            id: 'queryHistory',
            title: translate('nav.sfde.queryHistory'),
            routerLink: this.routing.exploreQueryHistory,
            routerActiveExactMatch: true,
            roles: excludeAccessAndCustomRoles
          }
        ]
      },
      {
        id: 'services',
        title: translate('nav.service.title'),
        icon: 'boschicon-bosch-ic-gears-interlocked-arrow',
        roles: ['power_user', 'data_provider'],
        subItems: [
          {
            id: 'decoder',
            title: translate('nav.service.decoder'),
            routerLink: this.routing.servicesDecoder,
            routerActiveExactMatch: false,
            roles: ['power_user'],
            options: ['enableDecoderService']
          },
          {
            id: 'notifications',
            title: translate('nav.service.notifications'),
            routerLink: this.routing.servicesNotifications,
            routerActiveExactMatch: false,
            roles: ['power_user'],
            options: ['enableNotifications']
          }
        ]
      },
      {
        id: 'devices',
        title: translate('nav.devices.devices'),
        icon: 'boschicon-bosch-ic-devices',
        options: ['enableDevices'],
        subItems: [
          {
            id: 'deviceType',
            title: translate('nav.devices.deviceType'),
            routerLink: this.routing.deviceType,
            routerActiveExactMatch: false
          },
          {
            id: 'deviceAll',
            title: translate('nav.devices.deviceAll'),
            routerLink: this.routing.devicesAll,
            routerActiveExactMatch: false
          },
          {
            id: 'history',
            title: translate('nav.devices.history'),
            routerLink: this.routing.deviceHistory,
            routerActiveExactMatch: true,
            options: ['enableDeviceHistory']
          }
        ]
      },
      {
        id: 'processing',
        title: translate('nav.processing'),
        icon: 'boschicon-bosch-ic-settings-arrows',
        roles: excludeAccessAndCustomRoles,
        subItems: [
          {
            id: 'inputHistory',
            title: translate('nav.sfde.inputHistory'),
            routerLink: this.routing.exploreInputHistory,
            routerActiveExactMatch: true
          },
          {
            id: 'statistics',
            title: translate('nav.sfde.statistics'),
            routerLink: this.routing.processingStatistics,
            routerActiveExactMatch: true,
            options: ['enablePipelines'],
            roles: ['power_user']
          },
          ...((this.projectConfig.plan !== ServicePlanType.free
            ? [
                {
                  id: 'pipelines',
                  title: translate('nav.sfde.pipelines'),
                  routerLink: this.routing.processingPipelines,
                  routerActiveExactMatch: false,
                  roles: ['power_user']
                }
              ]
            : []) as NavigationSubItem[]),
          {
            id: 'processingInfo',
            title: translate('nav.sfde.processingInfo'),
            routerLink: this.routing.exploreProcessingInfo,
            routerActiveExactMatch: true
          },
          {
            id: 'dataUpload',
            title: translate('nav.service.dataUpload'),
            routerLink: this.routing.servicesDataUpload,
            routerActiveExactMatch: true,
            roles: ['data_provider']
          }
        ]
      },
      {
        id: 'admin',
        title: translate('nav.admin.title'),
        icon: 'boschicon-bosch-ic-user-service',
        roles: ['manager'],
        subItems: [
          {
            id: 'subscriptionDetails',
            title: translate('nav.admin.subscriptionDetails'),
            routerLink: this.routing.adminSubscriptionDetails,
            routerActiveExactMatch: true,
            roles: ['manager']
          },
          {
            id: 'usageConfig',
            title: translate('nav.admin.usageConfig'),
            routerLink: this.routing.adminUsageConfig,
            routerActiveExactMatch: true,
            roles: ['admin']
          },
          {
            id: 'consentConfig',
            title: translate('nav.admin.consentConfig'),
            routerLink: this.routing.adminConsentConfig,
            routerActiveExactMatch: true,
            roles: ['admin']
          },
          {
            id: 'userManagement',
            title: translate('nav.admin.userManagement'),
            routerLink: this.routing.adminUsers,
            routerActiveExactMatch: true,
            roles: ['manager']
          },
          {
            id: 'roles',
            title: translate('nav.admin.roles'),
            routerLink: this.routing.adminRoles,
            routerActiveExactMatch: true,
            roles: ['manager']
          },
          // TODO: Enable when custom widgets are available
          // {
          //   id: 'customWidgets',
          //   title: translate('nav.admin.customWidgets'),
          //   routerLink: this.routing.customWidgets,
          //   routerActiveExactMatch: true,
          //   roles: ['admin']
          // },
          {
            id: 'dbStats',
            title: translate('nav.admin.dbStats'),
            routerLink: this.routing.adminDbStats,
            routerActiveExactMatch: true,
            roles: ['manager']
          },
          {
            id: 'adminLogs',
            title: translate('nav.admin.adminLogs'),
            routerLink: this.routing.adminLogs,
            routerActiveExactMatch: true,
            roles: ['manager']
          },
          {
            id: 'whiteLabeling',
            title: translate('nav.admin.whiteLabeling'),
            roles: ['admin'],
            subItems: [
              {
                id: 'whiteLabelingBasic',
                title: translate('admin.whiteLabeling.basicSettingsConfig.basicSettings'),
                routerLink: this.routing.adminWhiteLabelingBasic,
                routerActiveExactMatch: true,
                roles: ['admin']
              },
              {
                id: 'whiteLabelingFooter',
                title: translate('admin.whiteLabeling.footerConfig.footer'),
                routerLink: this.routing.adminWhiteLabelingFooter,
                routerActiveExactMatch: true,
                roles: ['admin']
              },
              {
                id: 'whiteLabelingEmail',
                title: translate('admin.whiteLabeling.emailTemplatesConfig.emailTemplates'),
                routerLink: this.routing.adminWhiteLabelingEmail,
                routerActiveExactMatch: true,
                roles: ['admin']
              },
              {
                id: 'whiteLabelingColor',
                title: translate('admin.whiteLabeling.colorSettingsConfig.colorSettings'),
                routerLink: this.routing.adminWhiteLabelingColor,
                routerActiveExactMatch: true,
                roles: ['admin']
              },
              {
                id: 'whiteLabelingDomain',
                title: translate('admin.whiteLabeling.customDomainConfig.domain'),
                routerLink: this.routing.adminWhiteLabelingDomain,
                routerActiveExactMatch: true,
                roles: ['admin']
              },
              {
                id: 'whiteLabelingHomepage',
                title: translate('admin.whiteLabeling.customHomepageConfig.homepage'),
                routerLink: this.routing.adminWhiteLabelingHomepage,
                routerActiveExactMatch: true,
                roles: ['admin']
              },
              {
                id: 'whiteLabelingCustomMenu',
                title: translate('nav.admin.customMenu'),
                routerLink: this.routing.adminWhiteLabelingCustomMenu,
                routerActiveExactMatch: true,
                roles: ['admin']
              }
            ]
          },
          {
            id: 'projectConfig',
            title: translate('nav.admin.projectConfig'),
            roles: ['manager'],
            subItems: [
              {
                id: 'projectConfigPerformance',
                title: translate(
                  'admin.projectConfig.performanceSettingsConfig.performanceSettings'
                ),
                routerLink: this.routing.adminProjectConfigPerformance,
                routerActiveExactMatch: true,
                roles: ['admin']
              },
              {
                id: 'projectConfigNotifications',
                title: translate('admin.projectConfig.notifications'),
                routerLink: this.routing.adminProjectConfigNotifications,
                routerActiveExactMatch: true,
                roles: ['admin']
              },
              {
                id: 'projectConfigNotificationBanner',
                title: translate('admin.projectConfig.notificationBannerConfig.notificationBanner'),
                routerLink: this.routing.adminProjectConfigNotificationBanner,
                routerActiveExactMatch: true,
                roles: ['admin']
              },
              {
                id: 'projectConfigAccess',
                title: translate('admin.projectConfig.accessSettings.access'),
                routerLink: this.routing.adminProjectConfigAccess,
                routerActiveExactMatch: true,
                roles: ['admin']
              },
              {
                id: 'projectConfigMapExtensions',
                title: translate('admin.projectConfig.mapExtensions'),
                routerLink: this.routing.adminProjectConfigMapExtensions,
                routerActiveExactMatch: true,
                roles: ['manager']
              },
              {
                id: 'projectConfigThings',
                title: translate('admin.projectConfig.iotThings'),
                routerLink: this.routing.adminProjectConfigThings,
                routerActiveExactMatch: true,
                roles: ['admin']
              },
              {
                id: 'projectConfigUpload',
                title: translate('admin.projectConfig.upload.label'),
                routerLink: this.routing.adminProjectConfigUpload,
                routerActiveExactMatch: true,
                roles: ['admin']
              },
              {
                id: 'projectConfigSecurity',
                title: translate('admin.projectConfig.securitySettings.label'),
                routerLink: this.routing.adminProjectConfigSecurity,
                routerActiveExactMatch: true,
                roles: ['admin']
              },
              {
                id: 'projectConfigArchiving',
                title: translate('admin.projectConfig.archiving.label'),
                routerLink: this.routing.adminProjectConfigArchiving,
                routerActiveExactMatch: true,
                roles: ['admin']
              }
            ]
          },
          {
            id: 'viewsDashboardsConfig',
            title: translate('nav.admin.viewsDashboardsConfig'),
            routerLink: this.routing.adminViewsDashboardsConfig,
            roles: ['manager'],
            routerActiveExactMatch: true
          },
          {
            id: 'collectionConfig',
            title: translate('nav.admin.collectionConfig'),
            routerLink: this.routing.adminCollectionConfig,
            routerActiveExactMatch: true,
            systemRoles: ['sfde_admin', 'sfde_manager']
          },
          {
            id: 'retentionConfig',
            title: translate('nav.admin.retentionConfig'),
            routerLink: this.routing.adminRetentionConfig,
            routerActiveExactMatch: true,
            roles: ['manager']
          }
        ]
      }
    ];
  }

  get adminNavigation(): NavigationItem[] {
    return [
      {
        id: 'projects',
        title: this.translateService.instant('nav.projects'),
        routerLink: this.routing.projects,
        routerActiveExactMatch: true,
        icon: 'boschicon-bosch-ic-folder'
      },
      {
        id: 'admin',
        title: this.translateService.instant('nav.admin.title'),
        routerLink: this.routing.admin,
        routerActiveExactMatch: true,
        roles: [SystemRole.manager, SystemRole.admin],
        icon: 'boschicon-bosch-ic-user-service',
        subItems: [
          {
            id: 'dbStats',
            title: this.translateService.instant('nav.admin.dbStats'),
            routerLink: this.routing.adminDbStats,
            routerActiveExactMatch: true
          },
          {
            id: 'createProject',
            title: this.translateService.instant('nav.admin.createProject'),
            routerLink: this.routing.adminCreateProject,
            routerActiveExactMatch: true,
            roles: [SystemRole.admin]
          },
          {
            id: 'jobs',
            title: this.translateService.instant('nav.admin.jobs'),
            routerLink: this.routing.adminJobs,
            routerActiveExactMatch: true,
            roles: [SystemRole.admin]
          },
          {
            id: 'domains',
            title: this.translateService.instant('nav.admin.domains'),
            routerLink: this.routing.adminCustomDomainsOverview,
            routerActiveExactMatch: true,
            roles: [SystemRole.admin]
          },
          {
            id: 'extended-support',
            title: this.translateService.instant('nav.admin.extendedSupport'),
            routerLink: this.routing.adminExtendedSupport,
            routerActiveExactMatch: true,
            roles: [SystemRole.admin]
          }
        ]
      }
    ];
  }

  private transformPureNavigation(menuItem: NavigationItem) {
    const transformedMenu = { ...menuItem };

    transformedMenu.title = this.translateService.instant(menuItem.title);
    if (transformedMenu.routerLink) {
      transformedMenu.routerLink = this.projectUrlPipe.transform(menuItem.routerLink);
    }

    if (transformedMenu.subItems && transformedMenu.subItems.length > 0) {
      transformedMenu.subItems = transformedMenu.subItems.map(
        this.transformPureNavigation.bind(this)
      );
    }

    return transformedMenu;
  }

  private createSideNavigation(): NavigationItem[] {
    // the custom menu items are already filtered on BE to verify if the user has access
    if (this.isCustomMenuUsed && !this.isSystemMenu) {
      return this.createCustomMenu();
    } else {
      return this.buildInsightsSideNavigation();
    }
  }

  private createCustomMenu(): NavigationItem[] {
    if (!this.isCustomMenuUsed) {
      return [];
    }
    return createNavigation(
      this.projectsService.customMenu.value,
      this.translateService,
      this.projectUrlPipe
    );
  }

  getAllPossibleSideProjectNavigationItems(): NavigationItem[] {
    const customMenu = this.createCustomMenu();
    const basicNavigation = this.buildInsightsSideNavigation();
    return [...customMenu, ...basicNavigation];
  }
}
