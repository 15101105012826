import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { urlValidatorReactive } from '../../../shared/validators/url-validator.directive';
import { CustomMenuItem, EntryType, MenuItemType } from '../models/custom-menu-item.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { duplicateNameValidator } from '../../../shared/validators/duplicate-name-validator';
import { urlSchemeValidatorReactive } from '../../../shared/validators/url-scheme-validator.directive';

const techNameRegEx = /^[-A-Za-z0-9_]+$/;

export class MenuReactiveForm {
  form: FormGroup;
  destroyObserver: Subject<void>;
  allowedUrlSchemes: string[];

  constructor(
    item: CustomMenuItem,
    technicalNamesExceptCurrent: string[],
    allowedUrlSchemes: string[],
    destroyObserver?: Subject<void>
  ) {
    this.allowedUrlSchemes = allowedUrlSchemes;
    this.form = new FormGroup({
      entryType: new FormControl(item.entryType, [Validators.required, entryValidator(item)]),
      label: new FormControl(item.label, [Validators.required]),
      description: new FormControl(item.description),
      name: new FormControl(item.name, [
        Validators.required,
        duplicateNameValidator(technicalNamesExceptCurrent),
        Validators.pattern(techNameRegEx)
      ]),
      roles: new FormControl(item.roles),
      visible: new FormControl(item.visible),
      hidden: new FormControl(item.hidden),
      icon: new FormControl(item.icon),
      // NODE TYPE END
      // PAGE TYPE SPECIFIC
      ...(item.entryType === EntryType.NODE
        ? {}
        : {
            type: new FormControl(item.type, [Validators.required]),
            url: new FormControl(
              { value: item.url, disabled: item.type === MenuItemType.INTERNAL },
              this.getUrlValidators(item.type)
            ),
            internalType: new FormControl(
              item.url,
              item.type === MenuItemType.INTERNAL ? [Validators.required] : []
            )
          })
    });
    this.destroyObserver = destroyObserver || new Subject<void>();
    this.setFormHandlers();
  }

  get formValue() {
    return this.form.value;
  }

  destroy() {
    this.destroyObserver.next();
    this.form = null;
  }

  setFormHandlers() {
    if (this.formValue.type === MenuItemType.LINK || this.formValue.type === MenuItemType.IFRAME) {
      this.form.get('url').setValidators([Validators.required, urlValidatorReactive()]);
    }

    this.entryTypeChangeListener();
    this.visibleControlChangeListener();

    // PAGE SPECIFIC
    if (this.formValue.entryType === 'PAGE') {
      this.internalTypeChangeListener();
      this.typeControlChangeListener();
    }
  }

  entryTypeChangeListener() {
    this.form
      .get('entryType')
      .valueChanges.pipe(takeUntil(this.destroyObserver))
      .subscribe((entryType) => {
        if (entryType === 'NODE') {
          this.form.removeControl('type');
          this.form.removeControl('url');
          this.form.removeControl('internalType');
        } else {
          this.form.addControl('type', new FormControl('INTERNAL', [Validators.required]));
          this.form.addControl('url', new FormControl('', [Validators.required]));
          this.form.addControl('internalType', new FormControl('', [Validators.required]));
          this.typeControlChangeListener();
          this.internalTypeChangeListener();
        }
      });
  }

  typeControlChangeListener() {
    this.form
      .get('type')
      .valueChanges.pipe(takeUntil(this.destroyObserver))
      .subscribe((type: MenuItemType) => {
        const urlControl = this.form.get('url');
        const internalTypeControl = this.form.get('internalType');

        urlControl.setValue('');

        if (type === MenuItemType.INTERNAL) {
          urlControl.disable();
          internalTypeControl.setValidators([Validators.required]);
        } else {
          urlControl.enable();
          internalTypeControl.clearValidators();
        }
        if (type === MenuItemType.LINK || type === MenuItemType.IFRAME) {
          urlControl.setValidators([
            Validators.required,
            urlSchemeValidatorReactive(this.allowedUrlSchemes)
          ]);
        } else {
          urlControl.setValidators([Validators.required]);
        }
      });
  }

  internalTypeChangeListener() {
    const urlControl = this.form.get('url');
    this.form
      .get('internalType')
      .valueChanges.pipe(takeUntil(this.destroyObserver))
      .subscribe((url) => {
        // do this because setValue is not working for disabled inputs
        urlControl.enable();
        urlControl.setValue(url);
        urlControl.disable();
      });
  }

  visibleControlChangeListener() {
    this.form
      .get('visible')
      .valueChanges.pipe(takeUntil(this.destroyObserver))
      .subscribe((visible) => {
        this.form.get('hidden').setValue(!visible);
      });
  }

  getUrlValidators(type: MenuItemType) {
    if (type === MenuItemType.LINK || type === MenuItemType.IFRAME) {
      return [Validators.required, urlSchemeValidatorReactive(this.allowedUrlSchemes)];
    } else {
      return [Validators.required];
    }
  }
}

function entryValidator(item: CustomMenuItem): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (
      (control.value === 'PAGE' && item.subItems?.length) ||
      (control.value === 'NODE' && item.level === 3)
    ) {
      return { invalidEntry: true };
    }

    return null;
  };
}
