import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserRequiredGuard } from '../core/services/user-required.guard';
import { InfoComponent } from './info/info.component';
import { SettingsComponent } from './settings/settings.component';
import { AcceptInviteComponent } from './accept-invite/accept-invite.component';
import { LogoutComponent } from './logout/logout.component';
import { LoginCompleteComponent } from './login-complete/login-complete.component';
import { translate } from '../shared/translation-util';
import { LoginComponent } from './login/login.component';
import { MigrateAdfsComponent } from './migrate-adfs/migrate-adfs.component';
import { ProjectIncorrectProviderComponent } from './project-incorrect-provider/project-incorrect-provider.component';
import { ProjectNoAccessComponent } from './project-no-access/project-no-access.component';
import { DomainProjectNoAccessComponent } from './domain-project-no-access/domain-project-no-access.component';

const routes: Routes = [
  {
    path: 'info',
    component: InfoComponent,
    canActivate: [UserRequiredGuard],
    data: {
      i18nTitle: translate('nav.user.info')
    }
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [UserRequiredGuard],
    data: {
      i18nTitle: translate('nav.user.settings')
    }
  },
  {
    path: 'accept-invite',
    component: AcceptInviteComponent,
    canActivate: [UserRequiredGuard],
    data: {
      i18nTitle: translate('nav.user.acceptInvite')
    }
  },
  {
    path: 'logout',
    component: LogoutComponent,
    data: {
      i18nTitle: translate('nav.user.logout')
    }
  },
  {
    path: 'login-complete',
    component: LoginCompleteComponent,
    data: {
      i18nTitle: translate('nav.user.login-complete')
    }
  },
  {
    path: 'incorrect-identity-provider',
    component: ProjectIncorrectProviderComponent,
    data: {
      i18nTitle: translate('nav.user.incorrectIdentityProvider')
    }
  },
  {
    path: 'no-project-access',
    component: ProjectNoAccessComponent,
    data: {
      i18nTitle: translate('nav.user.projectNoAccess')
    }
  },
  {
    path: 'no-domain-access',
    component: DomainProjectNoAccessComponent,
    data: {
      i18nTitle: translate('nav.user.projectNoAccess')
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      i18nTitle: translate('nav.user.login')
    }
  },
  {
    path: 'migrate-adfs',
    component: MigrateAdfsComponent,
    data: {
      i18nTitle: translate('nav.user.migrate-adfs')
    }
  },
  {
    path: '',
    redirectTo: 'info',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class UserRoutingModule {}
