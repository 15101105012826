import { User } from './user.model';

export class UserPending {
  activationCode?: string;
  inviteId?: string;
  email: string;
  projectRoles?: string[];
  expiresAt?: string; // ISO-Date

  get id(): string {
    return this.inviteId;
  }

  get displayName(): string {
    return this.email;
  }

  constructor(user: Partial<UserPending> = {}) {
    const { activationCode, inviteId, email = '', expiresAt = '', projectRoles = [] } = user;

    this.activationCode = activationCode;
    this.inviteId = inviteId;
    this.email = email;
    this.expiresAt = expiresAt;
    this.projectRoles = projectRoles;
    this.sortRoleByName();
    return this;
  }

  sortRoleByName(): this {
    this.projectRoles.sort((a, b) => User.roleSortCompare(a, b));
    return this;
  }
}
