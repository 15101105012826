import { ProjectConfig } from '../../../shared-projects/models/project.model';

/**
 * This is a view model for the footer note represented based on the backend configuration stored in {@link ProjectConfig#properties}.
 */
export class FooterNote {
  default = 'Powered by Robert Bosch GmbH';
  isCustom = false;
  customLabel?: string;
  private static configKey = 'footerNote';

  constructor(config?: ProjectConfig) {
    if (!FooterNote.containsCustomLabel(config)) {
      return;
    }
    this.customLabel = config.properties[FooterNote.configKey];
    this.isCustom = true;
  }

  setTo(config: ProjectConfig) {
    if (!this.isCustom) {
      // sets to the default configuration
      delete config.properties[FooterNote.configKey];
    } else {
      config.properties[FooterNote.configKey] = this.customLabel;
    }
  }

  reset() {
    this.isCustom = false;
    this.customLabel = undefined;
  }

  static containsCustomLabel(config: ProjectConfig): boolean {
    return config?.properties && config?.properties[FooterNote.configKey];
  }

  get label(): string {
    return this.isCustom ? this.customLabel : this.default;
  }
}
