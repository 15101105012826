import { HttpErrorResponse, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { DetailedError } from '@inst-iot/bosch-angular-ui-components';
import { forEach, has } from 'lodash-es';
import { of, OperatorFunction } from 'rxjs';
import { catchError } from 'rxjs/operators';

export enum HttpStatusCode {
  ok = 200,
  created = 201,
  accepted = 202,
  noContent = 204,
  badRequest = 400,
  unauthorized = 401,
  forbidden = 403,
  notFound = 404,
  requestTimeout = 408,
  conflict = 409,
  payloadTooLarge = 413,
  unsupportedMediaType = 415,
  unprocessableEntity = 422,
  queueFull = 423,
  internalServerError = 500,
  serviceUnavailable = 503
}

/**
 * Transforms the query into HttpParams format
 *
 * @param {Object} query - A query object
 * @param {number} query.pageSize - The pageSize of the response (max: 2000)
 * @param {number} query.page - The page you want to get
 */
export function getPagableParams(query: any): HttpParams {
  let params = new HttpParams({
    encoder: CustomHttpParamEncoder.INSTANCE // workaround https://github.com/angular/angular/issues/11058
  });
  if (query) {
    forEach(query, (value, key) => {
      params = params.set(key, `${value}`);
    });
  }
  return params;
}

export function map404ToNull(): OperatorFunction<any, any> {
  return catchError((err) => {
    if (err.status === 404) {
      return of(null);
    }
    throw err;
  });
}

export function httpDetailError(error: Error | HttpErrorResponse): Error | DetailedError {
  if (error instanceof HttpErrorResponse && has(error, 'error.message')) {
    return new DetailedError(error.error.message, error.error);
  }
  return error;
}

export function isIncorrectProviderError(error: HttpErrorResponse) {
  return (
    error?.status === HttpStatusCode.forbidden &&
    error?.error?.message?.includes('Unsupported Identity Provider')
  );
}

export function isNoProjectAccessError(error: HttpErrorResponse) {
  return (
    (error?.status === HttpStatusCode.forbidden &&
      !error?.error?.message?.includes('Unsupported Identity Provider')) ||
    (error?.status === HttpStatusCode.internalServerError &&
      error?.error?.message?.includes('no project')) ||
    (error?.status === HttpStatusCode.badRequest &&
      error?.error?.message?.includes('Unknown project'))
  );
}

export function isHandledError(error: HttpErrorResponse) {
  return isIncorrectProviderError(error) || isNoProjectAccessError(error);
}

export class CustomHttpParamEncoder implements HttpParameterCodec {
  static INSTANCE = new CustomHttpParamEncoder();
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
