import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { fromEvent, Subject, takeUntil } from 'rxjs';
import { tap, throttleTime } from 'rxjs/operators';

@Directive({
  selector: '[mediaQuery]'
})
export class MediaQueryDirective implements OnInit, OnDestroy {
  @Input() mediaQuery: string;

  private mediaQueryList: MediaQueryList;
  private isCreated: boolean;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private viewContainerRef: ViewContainerRef, private templateRef: TemplateRef<any>) {}

  ngOnInit() {
    if (!this.mediaQuery) {
      return;
    }

    this.mediaQueryList = matchMedia(this.mediaQuery);

    fromEvent(this.mediaQueryList, 'change')
      .pipe(
        takeUntil(this.destroy$),
        throttleTime(400),
        tap<MediaQueryListEvent>(({ matches }) => this.update(matches))
      )
      .subscribe();

    this.update(this.mediaQueryList.matches);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private update(matches: boolean) {
    if (this.isCreated) {
      this.viewContainerRef.clear();
    }

    if (!matches) {
      return;
    }

    const ref = this.viewContainerRef.createEmbeddedView(this.templateRef);
    ref.markForCheck();
    this.isCreated = true;
  }
}
