import { Pipe, PipeTransform } from '@angular/core';
import { LanguagesService } from '../../core/services/languages.service';
import { transformTranslatedString } from '../translation-pipe-util';

/**
 * Takes a map of language to string and returns the correct string for the current language.
 */
@Pipe({
  name: 'translated',
  pure: false
})
export class TranslatedPipe implements PipeTransform {
  constructor(private languages: LanguagesService) {}

  transform(value: { [lang: string]: string } | string, args?: any): string {
    return transformTranslatedString(value, this.languages.currentLanguage);
  }
}
