export class DeviceUser {
  userId?: string;
  userName?: string;
  tenantName?: string;

  constructor(user = {} as DeviceUser) {
    const { userId = '', userName = '', tenantName = '' } = user;

    this.userId = userId;
    this.userName = userName;
    this.tenantName = tenantName;
    return this;
  }

  getName() {
    return this.userName ? this.userName : this.getShortId();
  }

  getShortId() {
    return this.userId.slice(0, 20) + '...';
  }

  getShortName() {
    return this.userName ? this.userName.split('@')[0] : this.getShortId();
  }
}
