import { has, isEqual, union } from 'lodash-es';

// Helper types
type Difference = Record<string, { oldValue: any; newValue: any }>;

type Differences = {
  differences: Difference;
  id: any;
};

// Functions
export function range(from: number, to?: number, stepping?: number): Array<number> {
  const start: number = to ? from : 0;
  const stop: number = to ? to : from;
  const step: number = stepping ? stepping : 1;
  const result: Array<number> = [];

  for (let i = start; i < stop; i = i + step) {
    result.push(i);
  }

  return result;
}

export function splitStringOnWhitespace(string: string) {
  return string.trim().split(/\s+/);
}

export function getTotalWidthOfElement(element: HTMLElement | Element) {
  const computedStyles = window.getComputedStyle(element);

  const width = parseFloat(computedStyles.width) || 0;
  const marginLeft = parseFloat(computedStyles.marginLeft) || 0;
  const marginRight = parseFloat(computedStyles.marginRight) || 0;
  const paddingLeft = parseFloat(computedStyles.paddingLeft) || 0;
  const paddingRight = parseFloat(computedStyles.paddingRight) || 0;

  return width + marginLeft + marginRight + paddingLeft + paddingRight;
}

export function isArrayString(inputString: string): boolean {
  const regex = /^\s*\[.*\]\s*$/;
  return regex.test(inputString);
}

export function isObjectString(inputString: string): boolean {
  const regex = /^\{.*\}$/;
  return regex.test(inputString);
}

export function convertRemToPixels(rem): number {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export function getObjectDifferences(
  obj1: Record<string, any> | null | undefined,
  obj2: Record<string, any> | null | undefined
): Difference {
  obj1 = obj1 ?? {};
  obj2 = obj2 ?? {};

  const differences: Difference = {};
  const keys: string[] = union(Object.keys(obj1), Object.keys(obj2)) || [];

  keys.forEach((key) => {
    if (!isEqual(obj1[key], obj2[key])) {
      differences[key] = {
        oldValue: obj1[key],
        newValue: obj2[key]
      };
    }
  });

  return differences;
}

export function getArrayDifferences(
  newState: any[],
  previousState: any[],
  comparator: string
): Differences[] {
  if (!newState || !previousState) {
    return [];
  }

  const differences: any[] = [];

  // Iterate over the new state
  newState.forEach((newObj) => {
    // Find the corresponding object in the previous state based on some key (e.g., 'id')
    const prevObj = previousState?.find((o) => o[comparator] === newObj[comparator]);

    // Compare the objects
    const objectDifferences = getObjectDifferences(prevObj || {}, newObj);

    // If there are differences, add them to the result array
    if (Object.keys(objectDifferences).length > 0) {
      differences.push({
        id: newObj[comparator],
        differences: objectDifferences
      });
    }
  });

  return differences;
}

export function getFeaturesDifferences(obj1: any, obj2: any): any {
  const differences: any = {};

  // Iterate over features in obj1 (the source of truth)
  for (const featureName in obj1) {
    if (has(obj1, featureName)) {
      // Check if the feature exists in obj2
      if (has(obj2, featureName)) {
        // Compare properties of the feature
        const propsDiff = getDifferencesForFeature(
          obj1[featureName].properties,
          obj2[featureName].properties
        );

        // If there are differences, add them to the result
        if (propsDiff.length > 0) {
          differences[featureName] = propsDiff;
        }
      } else {
        // Feature exists in obj1 but not in obj2, treat it as an addition
        differences[featureName] = Object.keys(obj1[featureName].properties);
      }
    }
  }

  return differences;
}

function getDifferencesForFeature(props1: any, props2: any): string[] {
  const propsDiff: string[] = [];

  // Iterate over properties of the feature
  for (const propName in props2) {
    if (!has(props2, propName)) {
      continue;
    }
    if (!has(props1, propName) || props1[propName] !== props2[propName]) {
      // Property exists in both obj1 and obj2 with different values or only in obj2
      propsDiff.push(propName);
    }
  }

  return propsDiff;
}
