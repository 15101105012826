import { BaseFieldDefinition, FieldSelectOption } from './filter.model';
import { CollectionOverview } from '../api-model';
import { isObject } from 'lodash-es';
import { Params, Router } from '@angular/router';

export function getFilterValuesByParams(
  params: Record<string, string>,
  fields: BaseFieldDefinition[]
): Record<string, any> {
  const filterValues: Record<string, any> = {};
  fields.forEach((field) => {
    if (field.fromParams) {
      const value = field.fromParams(params);
      if (value !== undefined) {
        filterValues[field.name] = field.fromParams(params);
      }
    } else if (params[field.name] !== undefined) {
      filterValues[field.name] = params[field.name];
    }
  });
  return filterValues;
}

export function appendQueryToUrl(
  queryValues: Record<string, any>,
  fields: BaseFieldDefinition[],
  currentParamsInRoute: Params,
  router: Router
) {
  const queryAsParams = getParamsByFilterValues(queryValues, fields);
  const allKeys = new Set(Object.keys(queryAsParams).concat(Object.keys(currentParamsInRoute)));
  allKeys.add('project');
  const isChanged = Array.from(allKeys).some(
    (key) => currentParamsInRoute[key] !== queryAsParams[key]
  );
  if (isChanged) {
    router.navigate([], { queryParams: queryAsParams });
  }
}

export function getParamsByFilterValues(
  values: Record<string, any>,
  fields: BaseFieldDefinition[]
): Record<string, string | object | string[]> {
  const params: Record<string, string | object | any[]> = {};
  fields.forEach((field) => {
    if (field.name in values) {
      const value = values[field.name];
      if (field.toParams) {
        Object.assign(params, field.toParams(value));
      } else if (Array.isArray(value)) {
        params[field.name] = value;
      } else if (isObject(value)) {
        params[field.name] = {};
        Object.keys(value).forEach((selection) => {
          params[field.name][selection] = value[selection];
        });
      } else if (value !== undefined && value !== null) {
        params[field.name] = String(value);
      }
    }
  });
  return params;
}

export function collectionsToOptions(colls: CollectionOverview[]): FieldSelectOption[] {
  return colls.map((coll) => ({
    label: coll.label || coll.name,
    value: coll.name
  }));
}

export const commonParamsConverter = {
  range: {
    toParams(value: any): Record<string, string> {
      const params = {};
      params[this.name + 'From'] = value.from;
      params[this.name + 'To'] = value.to;
      return params;
    },
    fromParams(params: Record<string, string>): any {
      if (params[this.name + 'From'] || params[this.name + 'To']) {
        return {
          from: params[this.name + 'From'],
          to: params[this.name + 'To']
        };
      }
      return undefined;
    }
  },
  boolean: {
    toParams(value: any): Record<string, string> {
      const params = {};
      params[this.name] = value ? 'true' : 'false';
      return params;
    },
    fromParams(params: Record<string, string>): any {
      if (params[this.name]) {
        return params[this.name] === 'true';
      }
      return undefined;
    }
  },
  tuple: {
    toParams(value: any): Record<string, string> {
      const params = {};
      params[this.name] = value[0] + ',' + value[1];
      return params;
    },
    fromParams(params: Record<string, string>): any {
      if (params[this.name]) {
        return params[this.name].split(',', 2);
      }
      return undefined;
    }
  },
  json: {
    toParams(value: any): Record<string, string> {
      const params = {};
      params[this.name] = JSON.stringify(value);
      return params;
    },
    fromParams(params: Record<string, string>): any {
      if (params[this.name]) {
        if (typeof params[this.name] === 'string') {
          try {
            return JSON.parse(params[this.name]);
          } catch (e) {
            console.warn('Parsing ' + this.name + ' parameter failed', e);
          }
        }
        if (typeof params[this.name] === 'object') {
          return params[this.name];
        }
      }
      return undefined;
    }
  }
};

export const defaultParamsConverter = {
  dataset: {
    toParams(value: any): Record<string, string> {
      const params = {};
      params[this.name + 'Collection'] = value.collection;
      params[this.name + 'Id'] = value.id;
      return params;
    },
    fromParams(params: Record<string, string>): any {
      if (params[this.name + 'Collection'] && params[this.name + 'Id']) {
        return {
          collection: params[this.name + 'Collection'],
          id: params[this.name + 'Id']
        };
      }
      return undefined;
    }
  },
  datetimerange: {
    toParams(value: any): Record<string, string> {
      const params = {};
      params[this.name + 'From'] = value.from === '0' ? '+' + value.from : value.from;
      params[this.name + 'To'] = value.to === '0' ? '+' + value.to : value.to;
      return params;
    },
    fromParams(params: Record<string, string>): any {
      if (params[this.name + 'From'] && params[this.name + 'To']) {
        return {
          from: params[this.name + 'From'],
          to: params[this.name + 'To']
        };
      }
      return undefined;
    }
  },
  metadata: {
    toParams(value: any): Record<string, string> {
      const params = {};
      params[this.name + 'fieldName'] = value.fieldName;
      params[this.name + 'fieldValue'] = value.fieldValue;
      return params;
    },
    fromParams(params: Record<string, string>): any {
      if (params[this.name + 'fieldName'] && params[this.name + 'fieldValue']) {
        return {
          fieldName: params[this.name + 'fieldName'],
          fieldValue: params[this.name + 'fieldValue']
        };
      }
      return undefined;
    }
  },
  range: commonParamsConverter.range,
  boolean: commonParamsConverter.boolean,
  'multi-selection': commonParamsConverter.json,
  selection: {
    fromParams(params: Record<string, string | object>): any {
      if (
        params[this.name] !== undefined &&
        this.selectOptions.find(
          (o) => o.value === params[this.name] || o.value === params[this.name][o.label]
        )
      ) {
        return params[this.name];
      }
      return undefined;
    }
  },
  static: {
    toParams(): Record<string, string> {
      const params = {};
      params[this.name] = 'true';
      return params;
    },
    fromParams(params: Record<string, string>): any {
      if (params[this.name] === 'true') {
        return '';
      }
      return undefined;
    }
  }
};
