import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function duplicateNameValidator(names: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!names || names.length === 0) {
      return null;
    }

    const value = control.value as string;

    if (names.includes(value)) {
      return { duplicateName: true };
    }

    return null;
  };
}
