<rb-language-selector
  class="mx-2"
  [popoverDistance]="-7"
  [usePopover]="true"
  [languages]="languages"
  [language]="languagesService.currentLanguage"
  (languageChange)="languagesService.updateLanguage($event)"
>
  <ng-container currentItem *ngIf="languagesService.hasCustomTimezone">
    | {{ now | date: 'LT' }}
  </ng-container>

  <ng-container beforeItems *ngIf="layoutService.isLoggedIn">
    <ul class="o-context-menu__menuItems --settings">
      <li class="a-menu-item">
        <span
          class="a-menu-item__link a-menu-item__link--disabled"
          (click)="$event.stopPropagation()"
        >
          {{ 'settings.timezone' | translate }}: {{ languagesService.timezone }} ({{
            languagesService.timezoneFormatted
          }})
        </span>
      </li>

      <li class="a-menu-item">
        <a class="a-menu-item__link" [routerLink]="routing.userSettings | projectUrl">
          {{ 'settings.moreSettings' | translate }}
        </a>
      </li>
    </ul>

    <hr class="m-0" />
  </ng-container>
</rb-language-selector>
