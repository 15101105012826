import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
  HttpResponseBase
} from '@angular/common/http';
import jsep from 'jsep';
import * as moment from 'moment';
import { DashboardDataService } from '../../dashboards/services/dashboard-data.service';
import { DashboardStateService } from '../../dashboards/services/dashboard-state.service';
import { INPUT_WIDGET_LOCAL_STORAGE_KEY } from '../../dashboards/widgets/input-widget/models/input.model';
import { runBlobDownload } from '../../shared/download-utils';
import {
  resolvePlaceholders,
  resolveStringWithModifiers,
  WidgetPlaceholderContext
} from '../data-widgets/data-widgets-common';
import { OperatorType } from '../query-condition-input/models/operator.model';
import { LogicalOperatorType, SyntaxNode } from '../query-condition-input/models/syntax-node.model';
import {
  DisableCondition,
  DisableConditionPayload,
  DisableConditionType,
  DisableConditionValue
} from '../rest-request/models/disable-condition.model';
import {
  ActionButtonConfiguration,
  TriggerConfiguration
} from '../rest-request/models/rest-request.model';
import { assembleDisableConditions } from '../rest-request/utils/disable-condition.util';

export const actionButtonDefaultLabel = 'Call a REST API';
const momentGranularity = 'minute';

export function resolveActionConfiguration(
  resolvedPlaceholders: any[],
  actionConfig: ActionButtonConfiguration,
  data: any
) {
  const actionArray: ActionButtonConfiguration[] = [];

  for (const [resolvedLabel, resolvedDefaultTrigger, resolvedTriggers] of resolvedPlaceholders) {
    const rowAction = { ...actionConfig };
    const rowTrigger = { ...actionConfig.triggerConfiguration };
    rowAction.buttonLabel = resolvedLabel;

    if (rowAction.triggerConfiguration?.defaultUrl) {
      rowTrigger.defaultUrl = { ...rowTrigger.defaultUrl, url: resolvedDefaultTrigger };
    }

    if (rowAction.triggerConfiguration?.triggerUrls?.length) {
      rowTrigger.triggerUrls = [...rowTrigger.triggerUrls];
      rowTrigger.triggerUrls.forEach((trigger, idx, triggerUrls) => {
        triggerUrls[idx] = { ...trigger, url: resolvedTriggers[idx] };
      });
    }

    rowAction.triggerConfiguration = rowTrigger;

    actionArray.push({ ...rowAction });
  }

  if (!actionArray.length) {
    data.forEach(() => {
      actionArray.push({ ...actionConfig });
    });
  }

  return actionArray;
}

function processRestRequestResponse(
  response: HttpResponse<Blob>,
  dataSourceData: any,
  action: ActionButtonConfiguration
) {
  const bodyType = response.body.type;
  if (bodyType === 'application/json') {
    readBlobAsJsonString(response.body).then(
      (resolvedString) => (action.restResponse.response = resolvedString)
    );
  } else if (bodyType !== undefined) {
    downloadFile(response);
  }
}

export async function processRestRequestError(response: any): Promise<any> {
  let processedError: any;
  if (response.error instanceof Blob) {
    if (response.error.type === 'application/json') {
      processedError = await extractBlobCheckWhitelistSetError(response);
    } else if (response.error.type.startsWith('text')) {
      processedError = await readBlob(response.error);
    }
  }
  return processedError ?? response;
}

export function processRestResponse(
  response: HttpResponse<any> | HttpErrorResponse,
  action: ActionButtonConfiguration,
  data: any
) {
  setRestResponse(response, action);

  if (response instanceof HttpResponse) {
    processRestRequestResponse(response, data, action);
  } else {
    processRestRequestError(response).then((error) => {
      action.restResponse.error = error;
    });
  }
}

export function getTriggerRouteIfAvailable(
  response: HttpResponse<any> | HttpErrorResponse,
  datasourceData: any,
  trigger: TriggerConfiguration
) {
  const triggerConfiguration = trigger;
  let matchingTriggerUrl;
  if (triggerConfiguration?.triggerUrls || triggerConfiguration?.defaultUrl?.url) {
    matchingTriggerUrl = findMatchingTriggerUrl(triggerConfiguration, response);
  }
  return matchingTriggerUrl;
}

function findMatchingTriggerUrl(
  triggerConfiguration: TriggerConfiguration,
  response: HttpResponseBase
): { url?: string; target: string } {
  const definedTriggerUrl = triggerConfiguration.triggerUrls?.find(
    (triggerUrl) => Number(triggerUrl.statusCode) === response.status
  );
  if (definedTriggerUrl !== undefined) {
    return definedTriggerUrl;
  }
  return triggerConfiguration?.defaultUrl?.url ? triggerConfiguration?.defaultUrl : undefined;
}

async function extractBlobCheckWhitelistSetError(error: HttpErrorResponse): Promise<any> {
  const response = error.error as Blob;
  const errorJson = await readBlobAsJson(response);
  if (errorJson === undefined) {
    return error;
  } else if (errorJson?.message?.includes('whitelisted')) {
    // necessary for german translation
    return 'whitelist';
  } else if (errorJson?.message) {
    return errorJson.message;
  } else {
    return JSON.stringify(errorJson, null, 2);
  }
}

export function setRestResponse(response: any, action: ActionButtonConfiguration) {
  action.restResponse = {
    status: response?.status,
    statusText: response?.statusText,
    response: response?.body,
    error: response?.error
  };
}

function downloadFile(response) {
  const fileName = getFileName(response.headers);

  runBlobDownload(response.body, fileName);
}

export function getFileName(headers, defaultName = 'download') {
  let filename = defaultName;
  const disposition = headers.get('Content-Disposition');
  if (disposition) {
    const filenameRegex = /filename\*?=([^']*'')?([^;]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches !== null && matches[2]) {
      filename = matches[2].replace(/['"]/g, '');
    }
  }
  return filename;
}

/**
 * returns undefined if string cannot be parsed as JSON.
 */
export function readBlobAsJson(blob: Blob | string): Promise<any> {
  if (!blob) {
    return Promise.resolve(undefined);
  }
  return (typeof blob === 'string' ? Promise.resolve(blob) : readBlob(blob))
    .then((text) => JSON.parse(text))
    .catch(() => undefined);
}

export function readBlobAsJsonString(blob: Blob): Promise<string> {
  return readBlob(blob).then((blobString) => {
    let response = blobString;
    try {
      response = JSON.stringify(JSON.parse(response), null, 2);
    } catch (e) {
      // do nothing
    }
    return response;
  });
}

export function readBlob(blob: Blob): Promise<any> {
  return typeof blob.text === 'function' ? blob.text() : Promise.resolve(blob);
}

export function getHeaders(config, data, widgetInstance) {
  const headers = {};
  if (config.authPass && config.authUser) {
    headers['Authorization'] = 'Basic ' + btoa(config.authUser + ':' + config.authPass);
  }
  config.headers.forEach((header) => {
    headers[header.key] = resolvePlaceholders(
      header.value,
      0,
      resolveStringWithModifiers,
      data,
      widgetInstance
    );
  });
  return headers;
}

export function getFormData(config, data, widgetInstance) {
  const formData = {};
  config.body.forEach((fd) => {
    formData[fd.key] = resolvePlaceholders(
      fd.value,
      0,
      resolveStringWithModifiers,
      data,
      widgetInstance
    );
  });

  return JSON.stringify(formData);
}

export function getRawData(config, data, widgetInstance) {
  return resolvePlaceholders(config.body, 0, resolveStringWithModifiers, data, widgetInstance);
}

export function getBody(config, data, widgetInstance) {
  let body;

  switch (config.bodyType) {
    case 'document':
      body = JSON.stringify(data);
      break;
    case 'form-data':
      body = getFormData(config, data, widgetInstance);
      break;
    case 'raw-data':
      body = getRawData(config, data, widgetInstance);
      break;
    case 'decoder-specs':
      body = '';
      break;
    default:
      break;
  }

  return body;
}

export function createLinkDefinitionContext(
  response: HttpResponse<any> | HttpErrorResponse,
  context: WidgetPlaceholderContext,
  data: any,
  triggerUrl: any
): Promise<any> {
  const isHttpResponse = response instanceof HttpResponse;
  const httpResponse = isHttpResponse ? response : undefined;
  const httpErrorResponse = !isHttpResponse ? response : undefined;
  let blobAsJson;

  return readBlobAsJson(isHttpResponse ? httpResponse.body : httpErrorResponse.error).then(
    (body) => {
      blobAsJson = body;
      const httpContextObject: any = {
        ...response,
        headers: headersToMap(response.headers)
      };
      if (isHttpResponse) {
        httpContextObject.body = blobAsJson || httpResponse.body;
      } else {
        httpContextObject.error = blobAsJson || httpErrorResponse.error;
      }

      const dataSources: any[] = [{ http: httpContextObject }, context, data].filter(
        (elem) => !!elem
      );
      const unresolvedLinkDefinition = {
        path: triggerUrl.url,
        target: triggerUrl.target,
        isTriggerUrl: true
      };

      return { linkDefinition: unresolvedLinkDefinition, dataSources: dataSources };
    }
  );
}

function headersToMap(headers: HttpHeaders): Record<string, string> {
  const result: Record<string, string> = {};
  if (!headers) {
    return result;
  }
  for (const key of headers.keys()) {
    result[key] = headers.get(key);
  }
  return result;
}

export function checkDisabledCondition(
  syntaxTree: SyntaxNode,
  data: any,
  context: WidgetPlaceholderContext
): boolean {
  if (!syntaxTree) {
    return false;
  }

  const disableConditions = assembleDisableConditions(syntaxTree);

  const conditionalString = assembleConditionalString(disableConditions, data, context);

  // Parses conditional string (e.g "true && false") to an AST representation
  const parsedExpression = jsep(conditionalString);

  return evaluateParsedExpression(parsedExpression);
}

/**
 * Evaluates a parsed expression represented as an abstract syntax tree (AST).
 * This function handles binary expressions (e.g., logical AND and OR) and literals (e.g., true and false) within the AST.
 *
 * @param {Object} node - The AST node representing an expression to be evaluated.
 *
 * @returns {*} The result of evaluating the given AST node.
 *   For binary expressions, it returns the result of the logical operation (e.g., AND or OR).
 *   For literals, it returns their boolean values.
 *
 * @throws {Error} An error with a message indicating an unsupported node type
 *   if the provided `node` type is not recognized.
 *
 * @example
 * const parsedExpression = {
 *   type: 'BinaryExpression',
 *   operator: '&&',
 *   left: { type: 'Literal', raw: 'true' },
 *   right: { type: 'Literal', raw: 'false' },
 * };
 *
 * const result = evaluateParsedExpression(parsedExpression);
 * console.log(result); // Output: false
 */
function evaluateParsedExpression(node: jsep.Expression) {
  if (node.type === 'BinaryExpression') {
    const left = evaluateParsedExpression(node.left as any);
    const right = evaluateParsedExpression(node.right as any);

    if (node.operator === '&&') {
      return left && right;
    } else if (node.operator === '||') {
      return left || right;
    }
  } else if (node.type === 'Literal') {
    if (node.raw === 'true') {
      return true;
    } else if (node.raw === 'false') {
      return false;
    }
  } else {
    return false;
  }

  return false;
}

function evaluateCondition(
  operator: OperatorType,
  placeholderValue: DisableConditionValue,
  comparisonValue: DisableConditionValue
): boolean {
  const falsyStrings = ['0', 'null', 'undefined', 'NaN', '', 'false'];

  const isFalsyValues =
    falsyStrings.includes(placeholderValue.toString()) &&
    falsyStrings.includes(comparisonValue.toString());

  if (isFalsyValues) {
    return true;
  }

  if (moment.isMoment(placeholderValue) && moment.isMoment(comparisonValue)) {
    return evaluateDateCondition(operator, placeholderValue, comparisonValue);
  }

  return evaluateGenericCondition(operator, placeholderValue, comparisonValue);
}

function evaluateDateCondition(
  operator: OperatorType,
  placeholderValue: moment.Moment,
  comparisonValue: moment.Moment
): boolean {
  if (operator === 'eq') {
    return placeholderValue.isSame(comparisonValue, momentGranularity);
  } else if (operator === 'gt') {
    return placeholderValue.isAfter(comparisonValue, momentGranularity);
  } else if (operator === 'gte') {
    return placeholderValue.isSameOrAfter(comparisonValue, momentGranularity);
  } else if (operator === 'lt') {
    return placeholderValue.isBefore(comparisonValue, momentGranularity);
  } else if (operator === 'lte') {
    return placeholderValue.isSameOrBefore(comparisonValue, momentGranularity);
  } else {
    return !placeholderValue.isSame(comparisonValue, momentGranularity);
  }
}

function evaluateGenericCondition(
  operator: OperatorType,
  placeholderValue: DisableConditionValue,
  comparisonValue: DisableConditionValue
): boolean {
  if (operator === 'exists') {
    return !!placeholderValue;
  } else if (operator === 'eq') {
    return placeholderValue === comparisonValue;
  } else if (operator === 'gt') {
    return placeholderValue > comparisonValue;
  } else if (operator === 'gte') {
    return placeholderValue >= comparisonValue;
  } else if (operator === 'lt') {
    return comparisonValue > placeholderValue;
  } else if (operator === 'lte') {
    return comparisonValue >= placeholderValue;
  } else {
    return placeholderValue !== comparisonValue;
  }
}

function assembleConditionalString(
  disableConditions: DisableCondition[],
  data: any,
  context: WidgetPlaceholderContext
): string {
  const conditionalString = disableConditions.reduce((acc, condition) => {
    if (condition.type === DisableConditionType.Condition) {
      const payload = condition.payload as DisableConditionPayload;
      const resolvedPlaceholderValue = resolvePlaceholders(
        '${' + payload.propertyPath + '}',
        0,
        resolveStringWithModifiers,
        ...[context, data]
      );

      const { transformedPlaceholderValue, transformedComparisonValue } = transformConditionValues(
        payload,
        resolvedPlaceholderValue
      );

      const evaluatedCondition = evaluateCondition(
        payload.operator,
        transformedPlaceholderValue,
        transformedComparisonValue
      );

      acc += ` ${evaluatedCondition}`;

      return acc;
    }

    const operator = condition.payload as LogicalOperatorType;
    acc += ` ${operator === 'and' ? '&&' : '||'}`;
    return acc;
  }, '');

  return conditionalString.trimStart();
}

function transformConditionValues(
  payload: DisableConditionPayload,
  placeholderValue: DisableConditionValue
) {
  const values = {
    transformedPlaceholderValue: '' as DisableConditionValue,
    transformedComparisonValue: '' as DisableConditionValue
  };

  switch (payload.pathType) {
    case 'boolean':
      values.transformedPlaceholderValue = coerceBooleanProperty(placeholderValue);
      values.transformedComparisonValue = coerceBooleanProperty(payload.value);
      break;
    case 'number':
      values.transformedPlaceholderValue = Number(placeholderValue);
      values.transformedComparisonValue = Number(payload.value);
      break;
    case 'date':
      values.transformedPlaceholderValue = moment(placeholderValue as any);
      values.transformedComparisonValue = moment(payload.value);
      break;
    default:
      values.transformedPlaceholderValue = placeholderValue;
      values.transformedComparisonValue = payload.value;
  }

  return values;
}

export function validateInputReference(
  dashboardDataService: DashboardDataService,
  dashboardStateService: DashboardStateService,
  widgetConfig: any
): boolean {
  const matchedTechnicalName = JSON.stringify(widgetConfig).match(
    /(\${inputFields\.(?<technicalNamePlaceholder>\w+)})|("inputFields.(?<technicalNameReference>\w+)")/
  );

  if (!matchedTechnicalName) {
    return true;
  }

  const technicalName =
    matchedTechnicalName.groups.technicalNamePlaceholder ||
    matchedTechnicalName.groups.technicalNameReference;

  let inputReference = null;

  if (dashboardDataService?.currentConfig?.widgets) {
    const inputWidgets = dashboardDataService.currentConfig.widgets.filter(
      (w) => w.type === 'input'
    );
    inputReference = inputWidgets.find((w) => {
      return w.properties['fieldDefinitions'].some(
        (field) => field.technicalName === technicalName
      );
    });
  }
  if (!inputReference) {
    return true;
  }
  const parameter = dashboardStateService.getParameter(
    INPUT_WIDGET_LOCAL_STORAGE_KEY + technicalName,
    inputReference.id
  );

  if (!parameter?.options?.validationFn) {
    return true;
  }
  return parameter.options.validationFn();
}
