import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { PathInfo, PathItemType } from '../data-info-util';
import { fromEvent, merge, Subject } from 'rxjs';
import { filter, skipWhile, takeUntil } from 'rxjs/operators';
import { FormInputComponent } from '@inst-iot/bosch-angular-ui-components';

@Component({
  selector: 'app-data-structure-selector',
  templateUrl: './data-structure-selector.component.html',
  styleUrls: ['./data-structure-selector.component.scss']
})
export class DataStructureSelectorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() paths: PathInfo[];

  _selected: string;

  @Output() selectedChange = new EventEmitter<string>();

  filter = '';

  displayPaths: PathInfo[];

  @Input() allowedTypes: PathItemType[] = null;

  @ViewChild('filterInput', { static: true }) filterInput: FormInputComponent;

  private destroy = new Subject<null>();

  mouseSelect = false;

  ngOnInit() {
    merge(
      fromEvent(this.filterInput.input.nativeElement, 'blur').pipe(
        skipWhile(() => this.mouseSelect)
      ),
      fromEvent(this.filterInput.input.nativeElement, 'keydown').pipe(
        filter((e: KeyboardEvent) => e.key === 'Enter')
      )
    )
      .pipe(takeUntil(this.destroy))
      .subscribe((e: UIEvent | string) => {
        if (typeof e === 'string') {
          this.select(e);
        } else {
          e.preventDefault();
          if (this.filter.length) {
            this.select(this.filter);
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy.next(null);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.paths) {
      if (!changes.paths.currentValue) {
        this.paths = [];
      } else {
        this.paths = changes.paths.currentValue;
      }
      this.updateFilter(this.filter);
    }
  }

  @Input() set selected(value: string | string[]) {
    if (Array.isArray(value)) {
      this._selected = value.join('.');
    } else {
      this._selected = value || '';
    }
    if (!this.paths.find((p) => p.path === this._selected)) {
      this.filter = this._selected;
    }
  }

  select(path: string) {
    this._selected = path || '';
    this.selectedChange.next(path);
  }

  updateFilter(value) {
    this.filter = value;
    this.displayPaths = this.paths.filter((p) => p.path.indexOf(this.filter) !== -1);
  }

  isAllowed(pathInfo: PathInfo) {
    return (
      (this.allowedTypes && this.allowedTypes.some((type) => pathInfo.types.includes(type))) ||
      !this.allowedTypes
    );
  }
}
