<div *rbLoading="availableItemsLoader ?? false" class="row">
  <div class="col-12 col-md-5 pr-md-0">
    <h2 class="h4 font-weight-bold mb-4">{{ 'deviceSearch.available' | translate }}</h2>
    <rb-form-input
      data-cy="pickList-availableSearch"
      [searchItems]="availableItemsSearchFn"
      [loader]="loader"
      class="static"
      icon="rb-ic-search"
      [placeholder]="placeholder"
      (input)="itemSearch.emit()"
    ></rb-form-input>
    <article
      class="picklist-column-wrapper"
      infiniteScroll
      (scrolled)="infiniteScrollFn()"
      [scrollWindow]="false"
      [infiniteScrollDistance]="1"
    >
      <div
        class="px-3 py-2"
        *ngIf="!availableItems?.length && (loader?.loading$ | async) === false"
      >
        {{ 'pickList.noItemsFound' | translate }}
      </div>
      <div
        class="picklist-column d-flex justify-content-between align-items-center"
        *ngFor="let item of availableItems | alphabeticalSort: 'asc':'label'; let i = index"
      >
        <span class="picklist-column__label">{{ item.label }}</span>
        <div class="m-0 pt-0 pb-0">
          <rb-form-checkbox
            class="static"
            [attr.data-cy]="'pickList-checkbox-available-' + item.label"
            label="&nbsp;"
            [(ngModel)]="item.checked"
            (ngModelChange)="onCheck($event, item)"
          ></rb-form-checkbox>
        </div>
      </div>
      <span class="rb-ic rb-ic-refresh rb-ic-spin p-3" *ngIf="loader?.loading$ | async"></span>
    </article>
  </div>

  <div class="col-12 col-md-2 d-flex justify-content-center align-items-center px-md-0">
    <ng-container *mediaQuery="matchMediaQueries.untilTablet">
      <article class="my-4">
        <span class="rb-ic rb-ic-lg rb-ic-up"></span>
        <span class="rb-ic rb-ic-lg rb-ic-down"></span>
      </article>
    </ng-container>

    <ng-container *mediaQuery="matchMediaQueries.fromTablet">
      <span class="rb-ic rb-ic-lg rb-ic-back-left"></span>
      <span class="rb-ic rb-ic-lg rb-ic-forward-right"></span>
    </ng-container>
  </div>

  <div class="col-12 col-md-5 pl-md-0">
    <h2 class="h4 font-weight-bold mb-4">{{ 'deviceSearch.selected' | translate }}</h2>
    <rb-form-input
      data-cy="pickList-selectedSearch"
      [searchItems]="searchSelectedItems.bind(this)"
      class="static"
      icon="rb-ic-search"
      [placeholder]="placeholder"
    ></rb-form-input>
    <article class="picklist-column-wrapper">
      <div class="px-3 py-2" *ngIf="!selectedItemsFiltered?.length">
        {{ 'pickList.noSelectedItems' | translate }}
      </div>
      <div
        class="picklist-column d-flex justify-content-between align-items-center"
        *ngFor="let item of selectedItemsFiltered | alphabeticalSort: 'asc':'label'; let i = index"
      >
        <span class="picklist-column__label" [attr.data-cy]="'pickList-selected-' + item.label">
          {{ item.label }}
        </span>
        <button
          type="button"
          class="rb-btn rb-link rb-tiny rb-ic rb-ic-lg rb-ic-delete"
          (click)="onDeleteClick($event, item)"
        ></button>
      </div>
    </article>
  </div>
</div>
