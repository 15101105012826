import { PrimitiveDashboardParameterType } from '../../../models/primitive-dashboard-parameter';
import { KeyValue } from '../../../../shared-modules/key-value-editor/key-value';
import { translate } from '../../../../shared/translation-util';
import { StyleSelection } from '../../../../shared-modules/data-selection/style-selection/style-selection.component';

export enum FieldType {
  TEXT = 'text',
  MULTILINE_TEXT = 'multilineText',
  DATE = 'date',
  DATE_RANGE = 'dateRange',
  NUMBER = 'number',
  NUMBER_RANGE = 'numberRange',
  BOOLEAN = 'boolean',
  SELECTION = 'selection',
  MULTI_SELECTION = 'multiSelection'
}
export const INPUT_WIDGET_LOCAL_STORAGE_KEY = 'inputFields.';
export const fieldOptions = [
  {
    type: FieldType.TEXT,
    label: translate('input.textLabel')
  },
  {
    type: FieldType.MULTILINE_TEXT,
    label: translate('input.multilineTextLabel')
  },
  {
    type: FieldType.DATE,
    label: translate('input.dateLabel')
  },
  {
    type: FieldType.DATE_RANGE,
    label: translate('input.dateRangeLabel')
  },
  {
    type: FieldType.NUMBER,
    label: translate('input.numberLabel')
  },
  {
    type: FieldType.NUMBER_RANGE,
    label: translate('input.numberRangeLabel')
  },
  {
    type: FieldType.BOOLEAN,
    label: translate('input.booleanLabel')
  },
  {
    type: FieldType.SELECTION,
    label: translate('input.selectionLabel')
  },
  {
    type: FieldType.MULTI_SELECTION,
    label: translate('input.multiSelectionLabel')
  }
];

export type FieldConfigEdit<T> = T & { isNew?: boolean };

export type FieldConfig =
  | TextFieldConfig
  | MultilineTextFieldConfig
  | DateFieldConfig
  | DateRangeFieldConfig
  | NumberFieldConfig
  | NumberRangeConfig
  | BooleanFieldConfig
  | SelectionFieldConfig
  | MultiselectFieldConfig;

export class BaseFieldConfig {
  id: number;
  type: FieldType;
  technicalName: string;
  label: string;
  description?: string;
  placeholder?: string;
  resolvedPlaceholder?: string;
  required = false;
  style: StyleSelection;

  constructor(config: Partial<BaseFieldConfig> = {}) {
    this.id = Math.random();
    Object.assign(this, config);
  }
}

export class TextFieldConfig extends BaseFieldConfig {
  defaultValue = '';
  type = FieldType.TEXT;
  regexValidation: string;

  constructor(config: Partial<TextFieldConfig> = {}) {
    super(config);
    Object.assign(this, config);
  }
}

export class MultilineTextFieldConfig extends BaseFieldConfig {
  defaultValue = '';
  type = FieldType.MULTILINE_TEXT;
  regexValidation: string;

  constructor(config: Partial<MultilineTextFieldConfig> = {}) {
    super(config);
    Object.assign(this, config);
  }
}

export class NumberFieldConfig extends BaseFieldConfig {
  defaultValue: number;
  type = FieldType.NUMBER;
  rangeValidation: [number, number] = [undefined, undefined];
  constructor(config: Partial<NumberFieldConfig> = {}) {
    super(config);
    Object.assign(this, config);
  }
}

export class NumberRangeConfig extends BaseFieldConfig {
  defaultValue: [number, number] = [undefined, undefined];
  type = FieldType.NUMBER_RANGE;
  rangeValidation: [number, number] = [undefined, undefined];
  constructor(config: Partial<NumberRangeConfig> = {}) {
    super(config);
    Object.assign(this, config);
  }
}

export class DateFieldConfig extends BaseFieldConfig {
  defaultValue = '';
  type = FieldType.DATE;
  rangeValidation: [number, number] | [string, string] = [undefined, undefined];
  constructor(config: Partial<DateFieldConfig> = {}) {
    super(config);
    Object.assign(this, config);
  }
}

export class DateRangeFieldConfig extends BaseFieldConfig {
  defaultValue: [string, string] = [undefined, undefined];
  type = FieldType.DATE_RANGE;
  rangeValidation: [number, number] | [string, string] = [undefined, undefined];
  constructor(config: Partial<DateFieldConfig> = {}) {
    super(config);
    Object.assign(this, config);
  }
}

export class BooleanFieldConfig extends BaseFieldConfig {
  defaultValue = false;
  type = FieldType.BOOLEAN;

  constructor(config: Partial<BooleanFieldConfig> = {}) {
    super(config);
    Object.assign(this, config);
  }
}

export class SelectionFieldConfig extends BaseFieldConfig {
  selectionValues: KeyValue[] | string; // manually defined or string(reference to data source)
  defaultValue = '';
  dynamicValues = false;
  type = FieldType.SELECTION;

  constructor(config: Partial<SelectionFieldConfig> = {}) {
    super(config);
    Object.assign(this, config);
  }
}

export class MultiselectFieldConfig extends BaseFieldConfig {
  selectionValues: KeyValue[] | string; // manually defined or string(reference to data source)
  defaultValue: Record<string, unknown> = null;
  dynamicValues = false;
  type = FieldType.MULTI_SELECTION;

  constructor(config: Partial<MultiselectFieldConfig> = {}) {
    super(config);
    Object.assign(this, config);
  }
}

const fieldTypes = {
  [FieldType.TEXT]: TextFieldConfig,
  [FieldType.MULTILINE_TEXT]: MultilineTextFieldConfig,
  [FieldType.DATE]: DateFieldConfig,
  [FieldType.DATE_RANGE]: DateRangeFieldConfig,
  [FieldType.BOOLEAN]: BooleanFieldConfig,
  [FieldType.SELECTION]: SelectionFieldConfig,
  [FieldType.MULTI_SELECTION]: MultiselectFieldConfig,
  [FieldType.NUMBER]: NumberFieldConfig,
  [FieldType.NUMBER_RANGE]: NumberRangeConfig
};

export function createInputField<T>(type: FieldType, data?: Partial<T>): FieldConfig {
  const constructor = fieldTypes[type];
  if (type) {
    return new constructor(data);
  }
  throw new Error('Unknown type: ' + type);
}

export function mapFieldTypeToDashboardParameter(
  fieldType: FieldType
): PrimitiveDashboardParameterType {
  switch (fieldType) {
    case FieldType.TEXT:
    case FieldType.MULTILINE_TEXT:
    case FieldType.DATE:
    case FieldType.SELECTION:
    case FieldType.MULTI_SELECTION:
    case FieldType.DATE_RANGE:
    case FieldType.NUMBER_RANGE:
      return 'string';
    case FieldType.NUMBER:
      return 'number';
    case FieldType.BOOLEAN:
      return 'boolean';
  }
  return 'string';
}

export interface InputWidgetStorage {
  widgetId: string;
  value: FieldConfig[];
}
