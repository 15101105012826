<div class="main-bg d-flex">
  <div class="container text-center col-md-4 col-lg-3 m-auto" *rbLoading="loader">
    <ng-container *ngIf="showCookiesInfo()">
      <h1 class="mt-3">{{ 'userInfo.pleaseEnableCookies' | translate }}</h1>

      <p>
        {{ 'userInfo.cookiesAreRequiredBecause' | translate }}
      </p>

      <p>
        {{ 'userInfo.cookiesEnableFirefox' | translate }}
      </p>

      <p>
        {{ 'userInfo.cookiesEnableChromeEdge' | translate }}
      </p>

      <p>
        <a [href]="routing.helpEnableCookies" target="_blank">
          {{ 'userInfo.howToEnableCookies' | translate }}
        </a>
      </p>
    </ng-container>

    <ng-container *ngIf="showLoginButton()">
      <p class="mt-3">
        <span class="rb-ic rb-ic-user-hand-stop rb-3x"></span>
      </p>

      <h2 class="mt-3">{{ 'userInfo.loginRequiredHeader' | translate }}</h2>

      <p class="mb-4 rb-1-3x">{{ 'userInfo.loginRequired' | translate }}</p>

      <login-provider-select
        class="d-block mw-300 mx-auto"
        (loginClicked)="login($event)"
      ></login-provider-select>
    </ng-container>

    <br />
  </div>
</div>
