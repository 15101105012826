import { TranslatedString } from '../../../shared-projects/models/project.model';
import { v4 as uuid } from 'uuid';
import { MenuReactiveForm } from '../utils/menu-form.utils';

export class CustomMenuItemApiModel {
  name = '';
  hidden = false;
  label: TranslatedString = { en: '' };
  description: TranslatedString = { en: '' };
  type: MenuItemType;
  icon?: string;
  url?: string;
  roles: string[];
  searchable?: boolean;
  subItems?: CustomMenuItemApiModel[];

  constructor(model?: Partial<CustomMenuItemApiModel>) {
    this.name = model?.name || this.name;
    this.hidden = model?.hidden || this.hidden;
    this.label = model?.label || this.label;
    this.description = model?.description || this.description;
    this.type = model?.type || this.type;
    this.icon = model?.icon || this.icon;
    this.url = model?.url || this.url;
    this.roles = model?.roles || this.roles;
    this.searchable = model?.searchable || this.searchable;
    this.subItems = model?.subItems || this.subItems;
  }
}

interface CustomMenuItemArgs {
  opened: boolean;
  level: number;
  takenTechnicalNames: string[];
  allowedUrlSchemes: string[];
  isNew?: boolean;
  parent?: CustomMenuItem;
}

export class CustomMenuItem extends CustomMenuItemApiModel {
  id?: string;
  opened?: boolean;
  level?: number;
  entryType: EntryType;
  visible: boolean;
  invalid: boolean;
  takenTechnicalNames: string[];
  allowedUrlSchemes: string[];
  isNew = false;

  declare subItems: CustomMenuItem[];

  constructor(
    model: Partial<CustomMenuItemApiModel>,
    { opened, level, takenTechnicalNames, isNew, allowedUrlSchemes }: CustomMenuItemArgs
  ) {
    super(model);

    this.id = uuid();
    this.opened = opened;
    this.level = level;
    this.entryType = this.type === MenuItemType.NODE ? EntryType.NODE : EntryType.PAGE;
    this.visible = !this.hidden;
    this.takenTechnicalNames = takenTechnicalNames;
    this.allowedUrlSchemes = allowedUrlSchemes;
    this.subItems = this.subItems || [];
    this.isNew = isNew || false;
    this.validate();
    return this;
  }

  update(item: CustomMenuItem) {
    this.name = item.name;
    this.hidden = item.hidden;
    this.label = item.label;
    this.description = item.description;
    this.type = item.type;
    this.icon = item.icon;
    this.url = item.url;
    this.roles = item.roles;
    this.searchable = item.searchable;
    this.entryType = item.entryType;
    this.visible = item.visible;

    this.validate();

    return this;
  }

  updateLevel(level: number): CustomMenuItem {
    if (this.level !== level) {
      this.level = level;
      if (level === 1 && !this.icon) {
        this.icon = 'rb-ic-home';
      } else if (level !== 1 && this.icon) {
        this.icon = '';
      }

      if (level === 3) {
        this.subItems = [];
      }
    }
    this.validate();

    return this;
  }

  validate() {
    const reactiveForm = new MenuReactiveForm(
      this,
      this.takenTechnicalNames,
      this.allowedUrlSchemes
    );
    this.invalid = !reactiveForm.form.valid || !this.label.en;
    reactiveForm.destroy();

    return this.invalid;
  }
}

export enum EntryType {
  PAGE = 'PAGE',
  NODE = 'NODE'
}

export enum MenuItemType {
  INTERNAL = 'INTERNAL',
  DASHBOARD = 'DASHBOARD',
  LINK = 'LINK',
  IFRAME = 'IFRAME',
  NODE = 'NODE'
}

export const internalUrls = [];
