import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {
  detectInitialValues,
  getFullScreen,
  getInitialLang,
  getPrefixURL,
  goToInitialPathIfSpecified,
  isInFrame
} from './app/shared/embed-utils';
import { EmbeddingService } from './app/core/services/embedding.service';
import { INITIAL_LANGUAGE } from './app/core/services/languages.service';
import { INITIAL_FULLSCREEN_MODE } from './app/core/services/fullscreen.service';
import { INITIAL_PREFIX_URL } from './app/shared-modules/data-widgets/data-widgets-common/lib/hyperlink.service';
import { installNestedDragAndDropPatch } from './app/shared/nested-drag-and-drop-patch';

if (environment.production) {
  enableProdMode();
}

detectInitialValues(location);
goToInitialPathIfSpecified();
installNestedDragAndDropPatch();
const initialLang = getInitialLang();
const fullscreen = getFullScreen();
const prefixURL = getPrefixURL();
const platformProvider = [];
if (initialLang !== null) {
  platformProvider.push({ provide: INITIAL_LANGUAGE, useValue: initialLang });
}
if (fullscreen !== null) {
  platformProvider.push({ provide: INITIAL_FULLSCREEN_MODE, useValue: fullscreen });
}
if (prefixURL !== null) {
  platformProvider.push({ provide: INITIAL_PREFIX_URL, useValue: prefixURL });
}
const moduleRefPromise = platformBrowserDynamic(platformProvider).bootstrapModule(AppModule);

if (isInFrame()) {
  moduleRefPromise.then((module) => {
    module.injector.get(EmbeddingService);
  });
  import('iframe-resizer/js/iframeResizer.contentWindow.js').then(() => {
    console.log('loaded iframe-resizer window');
  });
}
