import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { QueryTemplateParameter } from '../../../../shared/api-model';

@Component({
  selector: 'choice-value',
  templateUrl: './choice-value.component.html'
})
export class ChoiceValueComponent {
  @Input() label: string | TemplateRef<any>;

  @Input() parameter: QueryTemplateParameter;

  @Output() valueChange = new EventEmitter<any>();

  @Input() value: any;

  get valueChoice(): number {
    const compareValue = JSON.stringify(this.value);
    return this.parameter.valueChoices.findIndex((vc) => JSON.stringify(vc.value) === compareValue);
  }

  set valueChoice(index: number) {
    const vc = this.parameter.valueChoices[index];
    if (vc) {
      this.value = vc.value;
    } else {
      this.value = undefined;
    }
    this.valueChange.next(this.value);
  }
}
