import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  BreadcrumbsService,
  ERROR_FORMATTER,
  ErrorFormatPipe,
  ModalService
} from '@inst-iot/bosch-angular-ui-components';
import { CustomChartsModule } from '../shared-modules/custom-charts/custom-charts.module';
import { ProjectUrlPipe } from '../shared-projects/pipes/project-url.pipe';
import { SharedProjectsModule } from '../shared-projects/shared-projects.module';
import { DatePipe } from '../shared/pipes/date.pipe';
import { NumberPipe } from '../shared/pipes/number.pipe';
import { TranslatedPipe } from '../shared/pipes/translated.pipe';
import { UnitPipe } from '../shared/pipes/unit.pipe';
import { SharedModule } from '../shared/shared.module';
import { ErrorComponent } from './error/error.component';
import { GlobalAlertComponent } from './global-alert/global-alert.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { LanguageSelectorComponent } from './layout/header/language-selector/language-selector.component';
import { ProjectSelectorComponent } from './layout/header/project-selector/project-selector.component';
import { UserMenuComponent } from './layout/header/user-menu/user-menu.component';
import { LayoutComponent } from './layout/layout.component';
import { LayoutService } from './layout/layout.service';
import { NotificationDisplayComponent } from './layout/notification-display/notification-display.component';
import { CustomErrorFormatter } from './services/error-formatter';
import { NavigationFactoryService } from './services/navigation-factory.service';
import { TranslatedBreadcrumbsService } from './services/translated-breadcrumbs.service';
import { UserRoleOverwriteComponent } from './layout/header/user-role-overwrite/user-role-overwrite.component';
import { UserSelectorModule } from '../dashboards/widgets/calendar-widget/user-selector/user-selector.module';
import { UserModule } from '../project-admin/user/user.module';
import { PageSelectorComponent } from './layout/header/page-selector/page-selector.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    CustomChartsModule,
    SharedProjectsModule,
    UserModule,
    UserSelectorModule
  ],
  declarations: [
    ErrorComponent,
    HomeComponent,
    LayoutComponent,
    GlobalAlertComponent,
    LanguageSelectorComponent,
    ProjectSelectorComponent,
    UserMenuComponent,
    FooterComponent,
    HeaderComponent,
    NotificationDisplayComponent,
    UserRoleOverwriteComponent,
    PageSelectorComponent
  ],
  exports: [],
  providers: [
    ModalService,
    LayoutService,
    NavigationFactoryService,
    { provide: BreadcrumbsService, useClass: TranslatedBreadcrumbsService },
    UnitPipe,
    DecimalPipe,
    DatePipe,
    NumberPipe,
    ErrorFormatPipe,
    TranslatedPipe,
    ProjectUrlPipe,
    { provide: ERROR_FORMATTER, useClass: CustomErrorFormatter, multi: true },
    { provide: Window, useValue: window }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
