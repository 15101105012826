import { Pipe, PipeTransform } from '@angular/core';
import { truncateString } from '../truncate-pipe-util';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, length: number): string {
    return truncateString(value, length);
  }
}
