<div class="d-flex flex-column">
  <form #form="ngForm">
    <!--radio buttons-->
    <div class="mt-2 mb-3">
      <rb-form-radio
        class="custom-control-inline"
        name="imageType"
        label="{{ 'slateEditor.image.deviceImage' | translate }}"
        [value]="imageType.DEVICE_IMAGE"
        [(ngModel)]="imageSettings.imageType"
        (ngModelChange)="imageTypeChange(imageType.DEVICE_IMAGE)"
        data-cy="imageTypeDeviceImage"
      ></rb-form-radio>
      <rb-form-radio
        class="custom-control-inline"
        name="imageType"
        label="{{ 'slateEditor.image.base64Image' | translate }}"
        [value]="imageType.BASE_64"
        [(ngModel)]="imageSettings.imageType"
        (ngModelChange)="imageTypeChange(imageType.BASE_64)"
      ></rb-form-radio>
      <rb-form-radio
        class="custom-control-inline"
        name="imageType"
        label="{{ 'slateEditor.image.fromUrl' | translate }}"
        [value]="imageType.FROM_URL"
        [(ngModel)]="imageSettings.imageType"
        (ngModelChange)="imageTypeChange(imageType.FROM_URL)"
      ></rb-form-radio>
      <rb-form-radio
        *ngIf="!hideFilePicker"
        class="custom-control-inline"
        name="imageType"
        [label]="'slateEditor.image.uploadFromFile' | translate"
        [value]="imageType.FROM_FILE"
        [(ngModel)]="imageSettings.imageType"
        (ngModelChange)="imageTypeChange(imageType.FROM_FILE)"
      ></rb-form-radio>
    </div>

    <!--url picker-->
    <rb-form-input
      [label]="'slateEditor.image.imageUrl' | translate"
      *ngIf="imageSettings.imageType === imageType.FROM_URL"
      class="flex-grow-1"
      [(ngModel)]="imageSettings.imageSrc"
      [required]="true"
      name="imageSrc"
      data-cy="imagePropertiesUrl"
      [placeholder]="externalUrlPlaceholder"
      urlSchemeValidator
    >
      <ng-template rbFormValidationMessage="schemeMatchError" let-errorData="data">
        <url-scheme-error [schemes]="errorData"></url-scheme-error>
      </ng-template>
    </rb-form-input>

    <!--base64 or device image-->
    <data-selector-input
      class="flex-grow-1 selector-container"
      *ngIf="
        imageSettings.imageType === imageType.BASE_64 ||
        imageSettings.imageType === imageType.DEVICE_IMAGE
      "
      [paths]="paths"
      [requireIteration]="true"
      [label]="'slateEditor.image.valueLabel' | translate"
      [(ngModel)]="imageSettings.pathValue"
      name="value"
      [required]="true"
    ></data-selector-input>

    <!--image from file-->
    <rb-form-file
      *ngIf="imageSettings.imageType === imageType.FROM_FILE"
      [multiple]="false"
      [label]="'slateEditor.image.uploadFromFile' | translate"
      name="uploadFromFile"
      [(ngModel)]="uploadedFiles"
      accept="image/*"
    ></rb-form-file>

    <!--image size-->
    <div class="mt-3 mb-2">
      {{ 'slateEditor.image.sizeLabel' | translate }}
      <span
        class="rb-ic rb-ic-info ml-1"
        [rbTooltip]="'slateEditor.image.sizeTooltip' | translate"
      ></span>
    </div>
    <image-dimension-editor [dimension]="imageSettings"></image-dimension-editor>
  </form>

  <div class="d-flex ml-auto mt-3">
    <button type="button" class="rb-btn rb-secondary" (click)="close()">
      {{ 'slateEditor.action.cancel' | translate }}
    </button>
    <button
      type="button"
      class="rb-btn rb-primary ml-3"
      data-cy="imagePropertiesSave"
      [disabled]="!form?.valid"
      (click)="save()"
    >
      {{
        isNewImage
          ? ('slateEditor.action.add' | translate)
          : ('slateEditor.action.save' | translate)
      }}
    </button>
  </div>
</div>
