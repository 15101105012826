<div class="d-flex" [class.flex-column]="renderVertically" *ngIf="dimension">
  <rb-form-input
    [ngClass]="{
      'CustomColumnDefinition_column CustomColumnDefinition_rightMargin': !renderVertically
    }"
    type="number"
    [label]="'widgets.table.maxHeightLabel' | translate"
    [(ngModel)]="dimension.maxHeight"
    [required]="true"
    name="maxHeight"
    data-cy="imageMaxHeight"
  ></rb-form-input>
  <rb-form-input
    [ngClass]="{
      'CustomColumnDefinition_column CustomColumnDefinition_leftMargin': !renderVertically
    }"
    type="number"
    [label]="'widgets.table.maxWidthLabel' | translate"
    [(ngModel)]="dimension.maxWidth"
    [required]="true"
    name="maxWidth"
    data-cy="imageMaxWidth"
  ></rb-form-input>
</div>
<span class="rb-ic rb-ic-info mr-2"></span>
<small class="text-muted text-break">
  {{ 'slateEditor.image.dimensionHint' | translate }}
</small>
