import { Subscription } from 'rxjs';
import { UserAuthService } from '../../core/services/user-auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ALL_AUTH_PROVIDERS, AuthProvider } from '../../core/services/models/auth-provider';

@Component({
  selector: 'project-incorrect-provider',
  templateUrl: './project-incorrect-provider.component.html',
  styleUrls: ['./project-incorrect-provider.component.scss']
})
export class ProjectIncorrectProviderComponent implements OnInit, OnDestroy {
  identityProvider: string;
  subscription: Subscription = new Subscription();

  constructor(private authService: UserAuthService) {}

  ngOnInit(): void {
    this.getIdentityProvider();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  logout() {
    this.authService.logout();
  }

  getIdentityProvider() {
    this.subscription.add(
      this.authService.getUiConfig().subscribe((uiConfig) => {
        this.identityProvider = uiConfig.userIdentityProvider;
        this.mapUserIdentityProvider();
      })
    );
  }

  mapUserIdentityProvider() {
    const authProvider = Object.values(ALL_AUTH_PROVIDERS).find((provider: AuthProvider) =>
      this.identityProvider.includes(provider.providerPrefix)
    );

    this.identityProvider = authProvider?.label;
  }
}
