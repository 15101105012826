import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OptionsConfigurationFormComponent } from './options-configuration-form/options-configuration-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { ColorPickerModule } from '../color-picker/color-picker.module';
import { ColorPickerInputComponent } from './color-picker-input/color-picker-input.component';
import { DataSelectionModule } from '../../shared-modules/data-selection/data-selection.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    ColorPickerModule,
    DataSelectionModule
  ],
  declarations: [OptionsConfigurationFormComponent, ColorPickerInputComponent],
  exports: [OptionsConfigurationFormComponent, ColorPickerInputComponent]
})
export class OptionsConfigurationModule {}
