import { ConditionChip } from './condition-chip.model';

export type NodeType =
  | 'SyntaxRootNode'
  | 'LogicalOperator'
  | 'ConditionChip'
  | 'Group'
  | 'GroupMarkerOpen'
  | 'GroupMarkerClose';
// Number Type: Necessary, when User enters an invalid Chip Index
export type NodeBodyType = LogicalOperator | ConditionChip | Group | number;

export const badgeTypes: Record<LogicalOperatorType, LogicalOperatorType> = {
  and: 'and',
  or: 'or'
};

export const regex = {
  isANumber: /[0-9]/,
  isANDorOR: /(AND|OR)/i,
  isAND: /AND/i,
  isBracket: /[()]/i,
  isTechnicalANDorOR: /^(\$and|\$or)$/i,
  isTechnicalLogicalOperator: /^(|\$exists|\$eq|\$ne|\$gt|\$lt|\$gte|\$lte)$/i,
  containsANDOR: /[ANDOR]/i,
  containsOnlyNumbers: /^\d+$/g
};

export class SyntaxNode {
  childNodes?: SyntaxNode[] = [];
  positionInDom: number;
  body: NodeBodyType;
  type: NodeType;

  constructor(type: NodeType, body?: NodeBodyType, positionInDom?: number) {
    this.type = type;
    this.body = body;
    this.positionInDom = positionInDom;
  }
}

export class LogicalOperator {
  type: string;

  constructor(type: string) {
    this.type = type;
  }
}

export type LogicalOperatorType = 'and' | 'or';

export class Group extends SyntaxNode {}
