import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AppLayoutFooterModel, AppMinimalFooterLink } from '@inst-iot/bosch-angular-ui-components';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../../constants';
import { FooterNote } from '../../../project-admin/white-labeling/footer-settings/footer-note';
import { ProjectUrlPipe } from '../../../shared-projects/pipes/project-url.pipe';
import { ProjectsService } from '../../../shared-projects/services/projects.service';
import { validateUnsafeUrl } from '../../../shared/directives/unsafe-url-handler.directive';
import { TranslatedPipe } from '../../../shared/pipes/translated.pipe';
import { UnsafeUrlDialogsService } from '../../../shared/services/unsafe-url-dialogs.service';
import { AppThemingService } from '../../services/app-theming.service';
import { ProjectViewConfig } from '../../../shared-projects/models/project.model';
import { UserAuthService } from '../../services/user-auth.service';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit, OnDestroy {
  @ViewChild('footerLinksError', { static: true })
  footerLinksErrorComponent: TemplateRef<any>;

  footer: AppLayoutFooterModel;

  readonly routing = Constants.routing;

  private destroy = new Subject<null>();

  constructor(
    private projectsService: ProjectsService,
    private projectUrlPipe: ProjectUrlPipe,
    private translateService: TranslateService,
    private translatedPipe: TranslatedPipe,
    private unsafeUrlDialogsService: UnsafeUrlDialogsService,
    private appThemingService: AppThemingService,
    private authService: UserAuthService
  ) {}

  ngOnInit(): void {
    this.footer = this.getDefaultFooter();
    this.setupFooterLinks();
  }

  ngOnDestroy(): void {
    this.destroy.next(null);
  }

  getLicencesLink(): AppMinimalFooterLink {
    const routerLink = this.projectUrlPipe.transform(
      this.routing.licenses,
      this.projectsService.projectName
    );
    return {
      title: this.translateService.instant('nav.licenses'),
      routerLink
    };
  }

  setupFooterLinks(): void {
    this.projectsService.projectConfigEvents
      .pipe(takeUntil(this.destroy))
      .subscribe((projectEvent) => {
        if (projectEvent) {
          this.footer.links = this.updateProjectFooterLinks(projectEvent.config?.footerLinks);
          this.footer.note = new FooterNote(projectEvent?.config).label;
          this.footer.copyright = undefined;
        } else {
          this.footer.links = this.setFooterLinksWithLicenses(this.getDefaultFooter().links);
          this.footer.note = null;
          this.footer.centralTemplateRef = null;
          this.footer.copyright = this.getDefaultFooter().copyright;
        }
      });
  }

  updateProjectFooterLinks(links: ProjectViewConfig[]): AppMinimalFooterLink[] {
    const convertedLinks = this.convertFooterLinks(links);

    if (!convertedLinks.length) {
      this.footer.centralTemplateRef = this.footerLinksErrorComponent;
    }

    return this.setFooterLinksWithLicenses(convertedLinks);
  }

  setFooterLinksWithLicenses(links: AppMinimalFooterLink[]): AppMinimalFooterLink[] {
    return [this.getLicencesLink()].concat(links);
  }

  convertFooterLinks(links: ProjectViewConfig[]): AppMinimalFooterLink[] {
    return (links ?? []).map((link: ProjectViewConfig) => {
      const footerLink: AppMinimalFooterLink = {
        title: this.translatedPipe.transform(link.label),
        target: '_blank',
        rel: 'nofollow'
      };

      if (link.type === 'dashboard') {
        footerLink.routerLink = `project/${this.projectsService.projectName}/${Constants.routing.views}/dashboards/${link.url}`;
      }

      if (link.type === 'link') {
        footerLink.url = link.url;
        footerLink.actionPerformed = ($event) => {
          if ($event.target instanceof HTMLElement) {
            const href = $event.target.attributes['href']?.value;
            if (validateUnsafeUrl(this.projectsService, href, this.unsafeUrlDialogsService)) {
              window.open(href, '_blank').focus();
            }
          }
        };
      }

      if (link.type === 'rich-text') {
        footerLink.routerLink =
          link.isPublic && this.isCustomDomain() && this.isUserUnauthenticated()
            ? `pages/footer/${link.name}`
            : `project/${this.projectsService.projectName}/pages/footer/${link.name}`;
      }

      return footerLink;
    });
  }

  getDefaultFooter(): AppLayoutFooterModel {
    const defaultLinks = [
      {
        title: this.translateService.instant('footer.imprint'),
        target: '_blank',
        url: 'https://www.bosch.io/lp/imprint.html'
      },
      {
        title: this.translateService.instant('footer.legalNote'),
        target: '_blank',
        url: 'https://bosch-iot-suite.com/legal/'
      },
      {
        title: this.translateService.instant('footer.privacy'),
        target: '_blank',
        url: 'https://www.bosch.io/lp/privacy-statement.html'
      },
      {
        title: this.translateService.instant('footer.privacyLeaflet'),
        target: '_blank',
        url: '/static-contents/docu/html/Privacy.html'
      }
    ];
    if (this.appThemingService.isStrictInsightsHostName() || this.appThemingService.isLocalhost) {
      defaultLinks.push({
        title: this.translateService.instant('footer.servicePlan'),
        target: '_blank',
        url: '/static-contents/docu/html/Introduction.html#src-1002927260_Introduction-ServicePlans'
      });
    }
    return {
      copyright: this.translateService.instant('footer.copyright'),
      links: defaultLinks
    };
  }

  private isUserUnauthenticated() {
    return !this.authService.lastUiConfig?.loggedIn;
  }

  private isCustomDomain() {
    return this.authService.lastUiConfig?.currentProject !== '_DEFAULT_';
  }
}
