import { ChangeDetectorRef, NgZone, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { interval, Subscription } from 'rxjs';

const eachSecond = interval(1000);

@Pipe({
  name: 'timeAgo',
  pure: false
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {
  timer: Subscription = null;

  text = '';

  date = null;

  constructor(private zone: NgZone) {}

  transform(date: any): string {
    if (date !== this.date) {
      this.date = date;
      this.startTimer();
      this.updateText();
    }
    return this.text;
  }

  updateText() {
    const newText = this.date ? moment(this.date).fromNow() : '';
    if (newText !== this.text) {
      this.text = newText;
    }
  }

  ngOnDestroy(): void {
    if (this.timer) {
      this.timer.unsubscribe();
    }
  }

  startTimer() {
    if (this.timer) {
      this.timer.unsubscribe();
    }
    this.zone.runOutsideAngular(() => {
      this.timer = eachSecond.subscribe(() => {
        this.updateText();
      });
    });
  }
}
