import { Directive } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  ValidatorFn
} from '@angular/forms';
import { ProjectsService } from '../../shared-projects/services/projects.service';

@Directive({
  selector: '[urlSchemeValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: UrlSchemeValidatorDirective, multi: true }]
})
export class UrlSchemeValidatorDirective implements Validator {
  constructor(private projectsService: ProjectsService) {}

  validate(c: AbstractControl): { [key: string]: string[] } | null {
    const urlSchemes = this.projectsService.allowedUrlSchemes;

    if (!isValidUrlScheme(c.value, urlSchemes) && !c.value.startsWith('${')) {
      return { schemeMatchError: urlSchemes };
    }
    return null;
  }
}

export function isValidUrlScheme(url: string, allowedSchemes: string[]) {
  if (!url || url.startsWith('/')) {
    return true;
  }
  return allowedSchemes.some((scheme) => url.toLowerCase().startsWith(`${scheme.toLowerCase()}:`));
}

export function urlSchemeValidatorReactive(allowedSchemes: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!isValidUrlScheme(control.value, allowedSchemes)) {
      return { schemeMatchError: allowedSchemes };
    }
    return null;
  };
}
