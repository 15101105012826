<div class="main-bg">
  <div class="container text-center">
    <h1 class="mt-3 componentHeader u-TextColor--fuchsia">
      {{ 'projectConfig.noAccess' | translate }}
    </h1>

    <p>
      {{ 'projectConfig.noDomainAccessLogoutDescription' | translate }}
    </p>

    <p class="mt-5">
      <button class="rb-btn rb-btn-primary" (click)="logout()">
        {{ 'nav.user.logout' | translate }}
      </button>
    </p>
  </div>
</div>
