import { regex } from '../models/syntax-node.model';
import { isArray, isBoolean, isNil, isNumber, isString } from 'lodash-es';
import { Operator, OperatorType } from '../models/operator.model';
import { ConditionChip, SupportedPathType } from '../models/condition-chip.model';
import { JsonObject } from '@angular-devkit/core';

/*
 * Maps a Query Condition Statement to a string
 * e.g. {"foo": {"$gt": "bar"}}
 * will be converted to "foo > bar"
 * */
function mapConditionToDisplayText(condition: Record<string, any>, key: string) {
  // Condition Object always has a key length of 1
  if (isDateCondition(condition[key])) {
    return `${key} : ${mapObjectToValueIfAvailable(condition[key], 'date')}`;
  } else if (isDateRangeCondition(condition[key])) {
    return `${key} : ${mapDateRangeConditionToDisplayText(condition[key])}`;
  } else if (isString(condition[key]) || isNumber(condition[key]) || isBoolean(condition[key])) {
    return `${key} : ${condition[key]}`;
  } else {
    const displayText = Object.keys(condition[key])
      .filter((operatorId: string) => operatorId !== '$options')
      .map((operatorId: string) => identifyMongoDBOperators(condition[key], operatorId));

    return `${key} ${displayText.join()}`;
  }
}

function isDateRangeCondition(value = {}) {
  const containsRangeOperators = ['$gte', '$lte'].every((key) => !isNil(value[key]));

  if (containsRangeOperators) {
    const range = { from: value['$gte'], to: value['$lte'] };
    return !!range.from['$date'] && !!range.to['$date'];
  }

  return false;
}

function mapDateRangeConditionToDisplayText(value: JsonObject) {
  return `${value['$gte']['$date']} - ${value['$lte']['$date']}`;
}

function mapDateRangeConditionToValue(value: JsonObject) {
  return [value['$gte']['$date'], value['$lte']['$date']];
}

function mapObjectToValueIfAvailable(condition: string | number | JsonObject, operator = 'oid') {
  if (isDateCondition(condition as JsonObject)) {
    return mapDateConditionToValue(condition as JsonObject);
  } else if (isDateRangeCondition(condition as JsonObject)) {
    return mapDateRangeConditionToValue(condition as JsonObject);
  } else if (!isNil(condition['$' + operator])) {
    return condition['$' + operator];
  } else {
    return condition;
  }
}

function identifyMongoDBOperators(condition: string | number | JsonObject, operatorId: string) {
  const operator = Operator.byTechnicalId(operatorId.substring(1) as OperatorType);

  if (isDateCondition(condition)) {
    return `${operator.visualID} ${mapDateConditionToValue(condition as JsonObject)}`;
  } else if (isDateRangeCondition(condition as JsonObject)) {
    return `: ${mapDateRangeConditionToDisplayText(condition as JsonObject)}`;
  } else if (['exists', 'regex'].includes(operator?.technicalID)) {
    return `${operator.visualID} ${mapObjectToValueIfAvailable(condition, operator?.technicalID)}`;
  } else if (operator) {
    return `${operator.visualID} ${mapObjectToValueIfAvailable(condition[operatorId])}`;
  } else {
    return `: ${mapObjectToValueIfAvailable(condition)}`;
  }
}

/*
 * Maps a Mongo DB Aggregation Query to a string expressing the Logic
 * e.g. {"$or": [{"$and": [{1},{2}]}, {3}]}
 * will be converted to "1 AND 2 OR 3" - AND has higher priority
 * */
export function mapQueryToDisplayText(query = {}): string {
  let logicDisplayText = '';

  if (typeof query === 'string') {
    return logicDisplayText;
  } else {
    Object.keys(query || {}).forEach((key) => {
      if (regex.isTechnicalANDorOR.test(key) && isArray(query[key])) {
        logicDisplayText += query[key]
          .map((condition) => mapQueryToDisplayText(condition))
          .join(` <b>${key.substring(1).toUpperCase()}</b> `);
      } else {
        logicDisplayText += mapConditionToDisplayText(query, key);
      }
    });
    return logicDisplayText;
  }
}

export function loadChipsConfigFromQuerySyntax(
  query = {},
  counter = 0
): { chips: ConditionChip[]; simpleLogic: string } {
  let simpleLogic = '';
  const chips: ConditionChip[] = [];

  Object.keys(query || {}).forEach((key) => {
    if (regex.isTechnicalANDorOR.test(key) && isArray(query[key])) {
      query[key].map((condition, i, conditions) => {
        const chipsConfig = loadChipsConfigFromQuerySyntax(condition, counter);
        chips.push(...chipsConfig.chips);
        simpleLogic = simpleLogic.concat(chipsConfig.simpleLogic);
        counter += chipsConfig.chips.length;

        if (i < conditions.length - 1) {
          simpleLogic = simpleLogic.concat(key.substring(1));
        }
      });
    } else {
      chips.push(createChipFromCondition(query[key], key, counter));
      simpleLogic = simpleLogic.concat(String(++counter));
    }
  });

  return { chips, simpleLogic };
}

function getConditionValue(value = {}, key: string): Partial<ConditionChip> {
  if (isString(value) || isNumber(value) || isBoolean(value)) {
    return { type: typeof value as SupportedPathType, operatorID: 'eq', value: value };
  } else if (value['$regex']) {
    return {
      type: 'string',
      operatorID: 'regex',
      value: mapObjectToValueIfAvailable(value, 'regex')
    };
  } else if (isDateCondition(value) || isDateRangeCondition(value)) {
    return {
      type: isDateCondition(value) ? 'date' : 'datetimerange',
      operatorID: 'eq',
      value: isDateCondition(value)
        ? mapDateConditionToValue(value)
        : mapDateRangeConditionToValue(value)
    };
  } else {
    // Condition contains an inner object, most likely a technical operator
    return parseInnerObjectToPartialChip(value, key);
  }
}

function isDateCondition(value = {}) {
  return Object.keys(value).includes('$date');
}

function mapDateConditionToValue(value: Record<string, any>) {
  return value['$date'] || value;
}

function parseInnerObjectToPartialChip(value = {}, key: string): Partial<ConditionChip> {
  const conditionValue: Partial<ConditionChip>[] = Object.keys(value).map((operatorId) => {
    const operator = Operator.byTechnicalId(operatorId.substring(1) as OperatorType);
    if (
      operator &&
      !isDateRangeCondition(value[operatorId]) &&
      !isDateCondition(value[operatorId])
    ) {
      return {
        type: typeof value[operatorId] as SupportedPathType,
        operatorID: operator.technicalID,
        value: mapObjectToValueIfAvailable(value[operatorId])
      };
    } else if (isDateRangeCondition(value[operatorId])) {
      // Contains two nested $date objects
      return {
        type: 'datetimerange',
        operatorID: operator.technicalID,
        value: mapDateRangeConditionToValue(value[operatorId])
      };
    } else if (isDateCondition(value[operatorId])) {
      // Contains a $date object
      return {
        type: 'date',
        operatorID: operator.technicalID,
        value: mapDateConditionToValue(value[operatorId])
      };
    } else if (value['$oid']) {
      return {
        type: 'objectId',
        operatorID: 'eq',
        value: mapObjectToValueIfAvailable(value[operatorId])
      };
    } else {
      return {
        type: 'string',
        operatorID: 'eq',
        value: mapObjectToValueIfAvailable(value[operatorId])
      };
    }
  });
  return conditionValue?.length ? conditionValue[0] : value[key];
}

function createChipFromCondition(
  value: Record<string, string | string[]>[],
  path: string,
  index = 0
): ConditionChip {
  return new ConditionChip(index, {
    input: path,
    searchType: 'path',
    ...getConditionValue(value, path)
  });
}
