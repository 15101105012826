import { FeatureCollection } from '@turf/helpers';
import { getType } from '@turf/invariant';
import { random, uniq } from 'lodash-es';
import { FieldDefinition } from '../dynamic-filter/dynamic-filter.model';

export type SearchType = 'path' | 'filter';

export enum PropertyPathType {
  PREDEFINED_PATH = 'predefinedPath',
  MANUAL_PATH = 'manualPath'
}

export interface SearchInput {
  paths: PathInfo[];
  filters: FieldDefinition[];
  type: SearchType;
  propertyPathType?: PropertyPathType;
}

export type PathItemType = 'string' | 'boolean' | 'number' | 'map' | 'null' | 'array' | 'bigint';

export class PathInfo {
  types: PathItemType[] = [];
  values: any[] = [];
  count = 0;

  constructor(public path: string) {}

  isAtomic(): boolean {
    return this.types.every(isAtomicType);
  }

  getDepth() {
    return this.getPath().length - 1;
  }

  getPath(): string[] {
    return this.path.split(/\./);
  }

  getTopValues(limit = 5) {
    const map = {};
    this.values.forEach((value) => {
      const valueStr = String(value);
      if (!map[valueStr]) {
        map[valueStr] = 0;
      }
      map[valueStr]++;
    });
    const values = Object.keys(map).map((valueStr) => ({
      value: valueStr,
      count: map[valueStr]
    }));
    values.sort((a, b) => b.count - a.count);
    return values.slice(0, limit);
  }
}

export function getPathIds(sampleSize: number, pathForEachIndex: boolean): string[] {
  if (!pathForEachIndex) {
    return ['[i]'];
  }
  const pathIds: string[] = [];
  for (let i = 0; i < sampleSize; i++) {
    pathIds.push(`[${i}]`);
  }
  return pathIds;
}

export function identifyPathsInArray(data: any[], pathForEachIndex = false): PathInfo[] {
  if (!data) {
    return [];
  }

  const paths: PathInfo[] = [];

  // if the data is larger then 100 we take 10% of the data size as our sample size
  const sampleSize = data.length <= 100 ? data.length : Math.floor(data.length * 0.1);
  const pathIds = getPathIds(sampleSize, pathForEachIndex);
  // data size is small we just scan all of them
  if (sampleSize === data.length) {
    data.forEach((entry) =>
      pathIds.forEach((pathId) => {
        identifyPaths(entry, [pathId], paths, true);
      })
    );
  } else {
    // creates an array of array index numbers to randomly select data samples
    const createIndices = (randomSize): number[] => {
      const numberArray: number[] = [];
      for (let i = 0; i < randomSize; i++) {
        numberArray.push(random(1, data.length));
      }
      return uniq(numberArray);
    };
    const samples = createIndices(sampleSize);
    // data size is larger then 100 we scan the random samples
    samples.forEach((indexNumber) => identifyPaths(data[indexNumber], ['[i]'], paths, true));
  }
  return paths;
}

export function identifyPaths(
  o: any,
  path: string[] = [],
  paths: PathInfo[] = [],
  mergeArr = true,
  keepArrIndicator = true
): PathInfo[] {
  const maxPathElements = 1000;
  if (paths.length >= maxPathElements) {
    return paths;
  }
  const type = typeof o;
  const pathId = path.join('.');
  let infoType: PathItemType;
  if (type === 'object' && o === null) {
    infoType = 'null';
  } else if (type === 'object' && Array.isArray(o)) {
    infoType = 'array';
  } else if (type === 'object') {
    infoType = 'map';
  } else if (type === 'undefined' || type === 'symbol' || type === 'function') {
    infoType = null;
  } else {
    infoType = type;
  }

  let info: PathInfo = paths.find((p) => p.path === pathId);
  if (!info) {
    info = new PathInfo(path.join('.'));
    paths.push(info);
  }
  info.count++;

  if (!info.types.includes(infoType)) {
    info.types.push(infoType);
  }

  if (isAtomicType(infoType)) {
    info.values.push(o);
  }

  if (infoType === 'array') {
    if (mergeArr && !keepArrIndicator) {
      o.forEach((item) => {
        identifyPaths(item, [...path], paths, mergeArr, keepArrIndicator);
      });
    } else {
      o.forEach((item, idx) => {
        identifyPaths(item, [...path, mergeArr ? '[]' : idx], paths, mergeArr, keepArrIndicator);
      });
    }
  }
  if (infoType === 'map') {
    Object.keys(o).forEach((key) => {
      identifyPaths(o[key], [...path, key], paths, mergeArr, keepArrIndicator);
    });
  }

  return paths;
}

export function identifyDeepPaths(object: any): PathInfo[] {
  const pathArray: PathInfo[] = [];
  identifyPaths(object, [], pathArray);

  return pathArray.filter((path) => {
    return path.path.length && pathArray.some((innerPath) => !innerPath.path.includes(path.path));
  });
}

export function getPathWithIndices(
  path: string | string[],
  iterators: Record<string, number | string> = {}
): string[] {
  return getPathParts(path).map((part) => {
    if (part.isArray) {
      if (part.index in iterators) {
        return iterators[part.index].toString();
      }
      if (!part.isIterator && part.index !== '') {
        return part.index;
      }
      return '0';
    } else {
      return part.part;
    }
  });
}

/**
 * Returns a iterator for the data, where the index defines the name of the iterator
 */
export function getDataPathIterator<T = any>(
  data: any,
  path: string | string[],
  index = 'i'
): IterableIterator<T> {
  const { basePath, valuePath } = getIteratorPathParts(path, index, false);
  const basePathFinal = getPathWithIndices(basePath);
  const baseArray = getValueByPath(data, basePathFinal) || [];
  const valuePathFinal = getPathWithIndices(valuePath);
  let iteratorIndex = 0;
  return {
    next(): IteratorResult<T> {
      if (iteratorIndex < baseArray.length) {
        const value = getValueByPath(baseArray[iteratorIndex], valuePathFinal);
        iteratorIndex++;
        return { value: value, done: false };
      } else {
        return { value: undefined, done: true };
      }
    },
    [Symbol.iterator]: function () {
      return this;
    }
  };
}

export function getBaseArrayForIterator<T = any>(
  data: any,
  path: string | string[],
  index = 'i'
): T[] {
  const { basePath } = getIteratorPathParts(path, index);
  const basePathFinal = getPathWithIndices(basePath);
  const baseArray = (basePath && getValueByPath(data, basePathFinal)) || [];
  return Array.isArray(baseArray) ? baseArray : [];
}

export function getIteratorPathParts(
  path: string | string[],
  index: string,
  nullForNotFound = true
) {
  const parts = getPathParts(path);
  const result = {
    basePath: [],
    valuePath: []
  };
  let inValuePath = false;
  for (const part of parts) {
    if (inValuePath) {
      result.valuePath.push(part.part);
    }
    if (part.isIterator && part.index === index) {
      inValuePath = true;
    } else if (!inValuePath) {
      result.basePath.push(part.part);
    }
  }
  if (!inValuePath && nullForNotFound) {
    result.valuePath = result.basePath;
    result.basePath = null;
  } else if (!inValuePath) {
    result.valuePath = result.basePath;
    result.basePath = [];
  }
  return result;
}

export function getValueByPath(o: any, path: string[]) {
  if (!path.length || path[0] === '') {
    return o;
  }
  const key = path[0];
  if (o && typeof o === 'object' && key in o) {
    return getValueByPath(o[key], path.slice(1));
  }
  return undefined;
}

export function isGeojson(obj: any): boolean {
  if (typeof obj === 'object' && 'type' in obj) {
    return !!getType(obj as FeatureCollection);
  }
  return false;
}

function isAtomicType(type: PathItemType) {
  return type === 'string' || type === 'number' || type === 'boolean' || type === 'null';
}

export interface PathParts {
  part: string;
  isArray: boolean;
  isIterator?: boolean;
  index?: string;
}

export function getPathParts(path: string | string[]): PathParts[] {
  let pathArray = [];
  if (typeof path === 'string') {
    pathArray = path.split('.');
  } else if (Array.isArray(path)) {
    pathArray = path;
  }
  return pathArray.map((part) => {
    const partInfo: PathParts = {
      part: part,
      isArray: false
    };
    if (part.match(/^\[(\w*)]$/)) {
      partInfo.isArray = true;
      partInfo.index = RegExp.$1;
      partInfo.isIterator = !!partInfo.index.match(/^[a-zA-Z]+$/);
    }
    return partInfo;
  });
}

export function toBasePath(path: string): string {
  return getPathParts(path)
    .map((pp) => (pp.isArray ? '[]' : pp.part))
    .join('.');
}
