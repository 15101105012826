<div *ngIf="showSingleKeyId" class="skid-info">
  <div class="loggedin-text-container">
    <span class="loggedin-text mr-auto">{{ 'nav.loggedInWith' | translate }}</span>
    <a href="https://singlekey-id.com/myprofile/" target="_blank">
      {{ 'nav.editProfile' | translate }}
    </a>
  </div>
  <div>
    <a href="https://singlekey-id.com/myprofile/" target="_blank">
      <img src="assets/img/skid_logo_in_row.svg" alt="SingleKey ID" width="250" height="63" />
    </a>
  </div>
</div>
<div class="spacing a-menu-item__link--disabled">
  {{ userAuthService.userName }}
</div>
