import { inject, Injectable } from '@angular/core';
import { ProjectsService } from '../../../../shared-projects/services/projects.service';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  DeviceFilterParameterConfig,
  FilterParameterConfig,
  FilterWidgetConfig,
  GlobalFilterParameters
} from '../filter-widget.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ParameterInfo } from './filter-widget-edit.model';
import { filter, map, tap } from 'rxjs/operators';
import { isEmpty } from 'lodash-es';
import { LocalStorageService } from 'ngx-localstorage';
import { globalParamPrefixRegex } from './filter-widget-edit.util';
import { getObjectDifferences } from '../../../../shared/functional-utils';

@Injectable({ providedIn: 'root' })
export class FilterWidgetGlobalParametersService {
  private projectsService = inject(ProjectsService);
  private localStorage = inject(LocalStorageService);

  private globalParametersSubject = new BehaviorSubject<GlobalFilterParameters>(null);
  private globalParametersIdsSubject = new BehaviorSubject<string[]>([]);

  filteredGlobalParameters$ = this.globalParametersIdsSubject.pipe(
    filter(() => Boolean(this.globalParameters)),
    map((ids) => {
      if (!ids.length) {
        return Object.values(this.globalParameters);
      }

      return Object.values(this.globalParameters).filter((config) => !ids.includes(config.name));
    })
  );

  storageChange$ = this.localStorage.pipe(
    filter((event) => event?.key?.includes(this.localStorageKey)),
    map((event) => getObjectDifferences(JSON.parse(event.oldValue), JSON.parse(event.newValue))),
    filter((diff) => !isEmpty(diff))
  );

  get localStorageKey() {
    return `globalFilters_${this.projectsService.projectName}`;
  }

  get storage() {
    return this.localStorage.get(this.localStorageKey) ?? {};
  }

  get globalParameters() {
    const globalParameters = this.globalParametersSubject.getValue();

    if (!globalParameters) {
      return globalParameters;
    }

    return { ...globalParameters };
  }

  constructor() {
    this.projectsService.globalFilterParameters$
      .pipe(takeUntilDestroyed())
      .subscribe((globalParameters) => {
        this.globalParametersSubject.next(globalParameters);
      });
  }

  hasParameter(parameterName: string) {
    if (!this.globalParameters) {
      return false;
    }

    return parameterName in this.globalParameters;
  }

  saveGlobalParameters(
    globalParameters: GlobalFilterParameters
  ): Observable<GlobalFilterParameters> {
    return this.projectsService
      .patchProjectConfigInfo(this.projectsService.projectName, {
        properties: { globalParameters }
      })
      .pipe(
        map((config) => config.properties?.['globalParameters']),
        tap((globalParameters) => this.projectsService.updateGlobalParameters(globalParameters))
      );
  }

  updateGlobalParameterIds(ids: string[]) {
    this.globalParametersIdsSubject.next(ids);
  }

  removeGlobalParameterFromLocalStorage(id: string) {
    const localStorageGlobalParams = this.localStorage.get<Record<string, unknown>>(
      this.localStorageKey
    );

    if (localStorageGlobalParams) {
      delete localStorageGlobalParams[id];
    }

    if (isEmpty(localStorageGlobalParams)) {
      this.localStorage.remove(this.localStorageKey);
    } else {
      this.localStorage.set(this.localStorageKey, localStorageGlobalParams);
    }
  }

  syncGlobalParametersToLocalStorage(globalParametersIds: string[], dashboardName: string) {
    const localStorageFilterWidgetKey = `dashboardState_${this.projectsService.projectName}_${dashboardName}`;

    const localStorageGlobalParams =
      this.localStorage.get<Record<string, unknown>>(this.localStorageKey) ?? {};
    const localStorageFilterWidget =
      this.localStorage.get<Record<string, unknown>>(localStorageFilterWidgetKey) ?? {};

    const globalParamsNotSavedInLocalStorage: Record<string, unknown> = globalParametersIds
      .filter((id) => !(id in localStorageGlobalParams))
      .reduce((acc, paramId) => {
        const withoutGlobalPrefix = 'filter.' + paramId.replace(globalParamPrefixRegex, '');

        if (withoutGlobalPrefix in localStorageFilterWidget) {
          return { ...acc, [paramId]: localStorageFilterWidget[withoutGlobalPrefix] };
        }

        return { ...acc, [paramId]: '' };
      }, {});

    this.localStorage.set(this.localStorageKey, {
      ...localStorageGlobalParams,
      ...globalParamsNotSavedInLocalStorage
    });
  }

  syncFilterWidgetGlobalParameters(filterConfig: FilterWidgetConfig): FilterWidgetConfig {
    const filterWidgetGlobalParams =
      filterConfig.globalParameters && !isEmpty(this.globalParameters)
        ? filterConfig.globalParameters.filter((paramId) => this.globalParameters[paramId])
        : [];

    return {
      ...filterConfig,
      globalParameters: filterWidgetGlobalParams
    };
  }

  getGlobalParameters(
    filterConfig: FilterWidgetConfig,
    parameterType: 'device' | 'general' | 'all' = 'all'
  ): FilterParameterConfig[] {
    if (!filterConfig?.globalParameters?.length || isEmpty(this.globalParameters)) {
      return [];
    }

    const filterWidgetGlobalParameters = filterConfig.globalParameters
      .filter((paramId) => Boolean(this.globalParameters[paramId]))
      .map((paramId) => ({ ...this.globalParameters[paramId] }));

    if (parameterType === 'all') {
      return filterWidgetGlobalParameters;
    }

    return filterWidgetGlobalParameters.filter((param) =>
      parameterType === 'device' ? param['propertyPath'] : !param['propertyPath']
    );
  }

  filterOutGlobalParameters(filterConfig: FilterWidgetConfig) {
    filterConfig.parameters = filterConfig.parameters.filter((param) => !param.global);
    filterConfig.deviceParameters = filterConfig.deviceParameters.filter(
      (param) => !param.global
    ) as unknown as DeviceFilterParameterConfig[];
  }

  extractGlobalFilterParameters(parameters: {
    general: ParameterInfo[];
    device: ParameterInfo[];
  }): GlobalFilterParameters {
    const globalParams = [...parameters.general, ...parameters.device].filter(
      (param) => param.config.global
    );

    if (!globalParams.length) {
      return null;
    }

    return globalParams.reduce((acc, param) => {
      acc[param.config.name] = param.config;
      return acc;
    }, {} as GlobalFilterParameters);
  }
}
