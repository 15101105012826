import { Component, Input } from '@angular/core';

@Component({
  selector: 'error-dialog',
  templateUrl: './error-dialog.component.html'
})
export class ErrorDialogComponent {
  @Input() alertTitle: string;
  @Input() buttonLabel = 'Ok';

  @Input() error: string;
  @Input() errorHeadline: string;
}
