import { Component, EventEmitter, Input, Output } from '@angular/core';
import { QueryTemplateParameter } from '../../../shared/api-model';
import { createDefaultValue } from '../models/query-parameter-converter';

/**
 * Displays the value input for a parameter.
 * It decides based on the parameterType, what to display
 */
@Component({
  selector: 'parameter-value',
  templateUrl: './parameter-value.component.html'
})
export class ParameterValueComponent {
  @Input() allowSet = false;

  @Input() showChoices = true;

  @Input() isSet = false;

  @Output() isSetChange = new EventEmitter<boolean>();

  @Input() parameter: QueryTemplateParameter;

  @Output() valueChange = new EventEmitter<any>();

  @Input() value: any;

  @Input() readonly = false;

  updateValue(v: any) {
    this.value = v;
    this.valueChange.next(v);
  }

  get hasChoices() {
    return !!this.parameter.valueChoices;
  }

  get parameterName() {
    return this.parameter.label?.length ? this.parameter.label : this.parameter.name;
  }

  get isNull() {
    return this.value === null && this.parameter.nullable;
  }

  set isNull(isNull: boolean) {
    if (isNull) {
      this.updateValue(null);
    } else {
      this.updateValue(createDefaultValue(this.parameter.dataType, this.parameter.parameterType));
    }
  }

  get internalIsSet() {
    return this.isSet;
  }

  set internalIsSet(set: boolean) {
    this.isSet = set;
    this.isSetChange.next(set);
  }
}
