import { isNil } from 'lodash-es';
import { getValueByPath } from '../../../data-selection/data-info-util';
import { DsParameter, DsParameterValue } from './data-sources';
import { WidgetContext } from './widget-context';
import { replacePlaceholders } from './placeholder-utils';
import { search } from 'jmespath';

export function isDsParameterValue(parameter: DsParameter): parameter is DsParameterValue {
  return 'value' in parameter;
}

export function resolveParameter(parameter: DsParameter, ctx: WidgetContext): any {
  const value = resolveParameterValue(parameter, ctx);
  const dateTimeRangeParam = resolveDateTimeRange(parameter, value, ctx);
  if (!isNil(dateTimeRangeParam)) {
    return dateTimeRangeParam;
  }
  const dateRangeParam = resolveDateRange(parameter, value, ctx);
  if (!isNil(dateRangeParam)) {
    return dateRangeParam;
  }
  if (value !== undefined) {
    const floatParam = resolveFloat(parameter, value);
    if (!isNil(floatParam)) {
      return floatParam;
    }
    const intParam = resolveInt(parameter, value);
    if (!isNil(intParam)) {
      return intParam;
    }
    const boolParam = resolveBoolean(parameter, value);
    if (!isNil(boolParam)) {
      return boolParam;
    }
    const expressionParam = resolveExpression(parameter, value, ctx);
    if (!isNil(expressionParam)) {
      return expressionParam;
    }
  }
  return value;
}

function resolveFloat(parameter: DsParameter, value): number | number[] | undefined {
  if (parameter.type === 'float' && typeof value !== 'number') {
    if (Array.isArray(value)) {
      const parsed = value.map((val) => parseFloat(val));
      if (parsed.some((val) => val === undefined)) {
        return undefined;
      }
      return parsed;
    }
    return parseFloat(value);
  }
  return undefined;
}

function resolveInt(parameter: DsParameter, value): number | number[] | undefined {
  if (parameter.type === 'int' && typeof value !== 'number') {
    if (Array.isArray(value)) {
      const parsed = value.map((val) => parseInt(val, 10));
      if (parsed.some((val) => val === undefined)) {
        return undefined;
      }
      return parsed;
    }
    return parseInt(value, 10);
  }
  return undefined;
}

function resolveDateTimeRange(
  parameter: DsParameter,
  value,
  ctx: WidgetContext
): { from: string; to: string } | undefined {
  if (
    (parameter.type === 'datetimerange' || parameter.type === 'daterange') &&
    value &&
    value.indexOf(',') !== -1
  ) {
    const parts = value.split(',');
    return {
      from: parts[0],
      to: parts[1]
    };
  }
  if (parameter.type === 'datetimerange' && !value && 'ref' in parameter) {
    const paramFrom = resolveParameterValue({ ref: parameter.ref + 'From', type: 'datetime' }, ctx);
    const paramTo = resolveParameterValue({ ref: parameter.ref + 'To', type: 'datetime' }, ctx);
    if (paramFrom && paramTo) {
      return {
        from: paramFrom,
        to: paramTo
      };
    }
  }
  return undefined;
}

function resolveDateRange(
  parameter: DsParameter,
  value,
  ctx: WidgetContext
): { from: string; to: string } | undefined {
  if (parameter.type === 'daterange' && !value && 'ref' in parameter) {
    const paramFrom = resolveParameterValue({ ref: parameter.ref + 'From', type: 'date' }, ctx);
    const paramTo = resolveParameterValue({ ref: parameter.ref + 'To', type: 'date' }, ctx);
    if (paramFrom && paramTo) {
      return {
        from: paramFrom,
        to: paramTo
      };
    }
  }
  return undefined;
}

function resolveBoolean(parameter: DsParameter, value: any): boolean | boolean[] | undefined {
  if (parameter.type === 'boolean') {
    if (Array.isArray(value)) {
      const parsed = value.map((val) => val === 'true' || val === true);
      if (parsed.some((val) => val === undefined)) {
        return undefined;
      }
      return parsed;
    }
    return value === 'true' || value === true;
  }
  return undefined;
}

function resolveExpression(
  parameter: DsParameter,
  value: any,
  ctx: WidgetContext
): string | undefined {
  if (parameter.type === 'expr') {
    return replacePlaceholders(value, ctx);
  }
  return undefined;
}

export function resolveParameterValue(parameter: DsParameter, ctx: WidgetContext): any {
  if (parameter) {
    if ('value' in parameter) {
      return parameter.value;
    }
    if ('ref' in parameter) {
      const value = getValueByPath(ctx, parameter.ref.split('.'));
      // if is in edit mode use default value for widget preview
      if (!value && !ctx.filterParams) {
        return parameter.default ? parameter.default : undefined;
      }
      return value;
    }
  }
  return undefined;
}

export function hasRequiredValue(parameter: DsParameter) {
  if (!parameter) {
    return false;
  }
  if (
    'value' in parameter &&
    parameter.value !== null &&
    parameter.value !== '' &&
    parameter.value !== undefined
  ) {
    return true;
  }
  return !!(
    'ref' in parameter &&
    parameter.default !== null &&
    parameter.default !== '' &&
    parameter.default !== undefined &&
    parameter.ref
  );
}

export function hasData(data: any): boolean {
  if (Array.isArray(data)) {
    return data.length > 0;
  }
  return data !== null && data !== undefined;
}

export function isJmesExpressionValid(expression: string): boolean {
  try {
    search({}, expression);
    return true;
  } catch (e) {
    return expression === '';
  }
}
