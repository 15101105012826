import { AbsolutePickerValue, RelativePickerValue } from '@inst-iot/bosch-angular-ui-components';
import { identity } from 'rxjs';

export function getAbsoluteFromRelative(
  value: RelativePickerValue,
  removeSeconds = true,
  transformDate: (date: Date) => Date = identity
): AbsolutePickerValue {
  const now = new Date().getTime();
  const hf = removeSeconds ? 1000 * 60 : 1;
  const start = toISOString(Math.floor((now + value[0]) / hf) * hf, transformDate);
  const end = toISOString(Math.floor((now + value[1]) / hf) * hf, transformDate);
  return [start, end];
}

export function toISOString(
  data: string | number | Date,
  transformDate: (date: Date) => Date = identity
) {
  let date = data;
  if (typeof data === 'string' || typeof data === 'number') {
    date = new Date(data);
  }
  if (date instanceof Date) {
    return transformDate(date).toISOString();
  }
  return '';
}
