import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { DashboardWidgetComponent } from '../dashboard-widget/dashboard-widget.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [DashboardWidgetComponent],
  exports: [DashboardWidgetComponent]
})
export class DashboardWidgetModule {}
