import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { ResizeObserver } from 'resize-observer';

@Component({
  selector: 'ellipsis-text',
  templateUrl: './ellipsis-text.component.html',
  styleUrls: ['./ellipsis-text.component.scss']
})
export class EllipsisTextComponent implements OnInit, AfterViewInit {
  /**
   * Use this if and only if the child content of the hint has several blocks!
   * If the content is only inline text and tags, do *not* specify this attribute.
   */
  @Input() inlineContent: string;
  @Input() hint?: boolean = true;
  @Input() ellipsedByDefault?: boolean = true;
  @Input() class?: string;

  @ViewChild('mainContent') mainContent: ElementRef<HTMLDivElement>;

  ellipsisEnabled = true;
  displayMoreOrLess = false;

  private _lineHeight: number = undefined;
  private _resizeObserver: ResizeObserver;

  ngOnInit() {
    this.calculateDisplayMoreOrLess = this.calculateDisplayMoreOrLess.bind(this);
    this._resizeObserver = new ResizeObserver(this.calculateDisplayMoreOrLess);
  }

  ngAfterViewInit() {
    this._resizeObserver.observe(this.mainContent.nativeElement);

    setTimeout(() => {
      this.calculateDisplayMoreOrLess();
      this.ellipsisEnabled = this.ellipsedByDefault;
    }, 0);
  }

  toggleEllipse(): void {
    this.ellipsisEnabled = !this.ellipsisEnabled;
    this.calculateDisplayMoreOrLess();
  }

  isEllipsedOrWrapped(): boolean {
    const nativeElement = this.mainContent.nativeElement;

    if (
      nativeElement.offsetHeight > 0 &&
      (!this._lineHeight || nativeElement.offsetHeight < this._lineHeight)
    ) {
      this._lineHeight = nativeElement.offsetHeight;
    }

    return (
      nativeElement.offsetWidth < nativeElement.scrollWidth ||
      nativeElement.offsetHeight > this._lineHeight
    );
  }

  calculateDisplayMoreOrLess() {
    this.displayMoreOrLess = this.inlineContent !== undefined || this.isEllipsedOrWrapped();
  }
}
