<div [ngClass]="customCSS">
  <div *ngIf="label" class="label label-small pb-1">
    <rb-render-tpl [tpl]="label"></rb-render-tpl>
  </div>
  <div class="row" *ngFor="let chip of chips; let i = index">
    <div class="col-5 col-xl-4 pr-0 d-flex">
      <rb-form-input
        class="pr-1 flex-grow-1"
        spellcheck="false"
        [attr.data-cy]="'queryConditionInputChipPath-' + i"
        [label]="'simpleSearch.path' | translate"
        [name]="'chipPath_' + i"
        [(ngModel)]="chip.input"
        (ngModelChange)="composeQuery()"
        (focusin)="focusedChip = chip"
        (focusout)="focusedChip = undefined; trimChipValues(chip)"
      ></rb-form-input>
    </div>

    <div class="col-auto col-xl-1 px-1">
      <operator-selector
        [unsupportedOperators]="getIncompatibleOperators(chip.type)"
        [attr.data-cy]="'queryConditionOperatorSelector-' + i"
        [operatorsList]="mongoOperators"
        [name]="'conditionOperator' + i"
        [ngModel]="chip.operator"
        (ngModelChange)="setChipOperator($event, chip); composeQuery()"
      ></operator-selector>
    </div>

    <div class="col-4 col-xl-3 px-0 d-flex">
      <rb-form-custom-select
        class="pl-1 pr-1 flex-grow-1"
        [attr.data-cy]="'queryConditionInputChipDataType-' + i"
        [disabled]="checkOperatorTypeExists(chip.operatorID)"
        [name]="'dataType' + i"
        [label]="'simpleSearch.dataTypeSelection' | translate"
        [(ngModel)]="chip.type"
        (ngModelChange)="switchDataType(chip); composeQuery()"
        (focusin)="focusedChip = chip"
        (focusout)="focusedChip = undefined"
      >
        <rb-form-select-option [value]="'string'" selected>String</rb-form-select-option>
        <rb-form-select-option [value]="'number'">Number</rb-form-select-option>
        <rb-form-select-option [value]="'objectId'">ObjectId</rb-form-select-option>
        <rb-form-select-option [value]="'boolean'">Boolean</rb-form-select-option>
        <rb-form-select-option [value]="'date'">Date</rb-form-select-option>
        <rb-form-select-option [value]="'datetimerange'">Date Range</rb-form-select-option>
      </rb-form-custom-select>
    </div>

    <div class="col-12 col-xl-4 pl-xl-0 d-flex">
      <div [ngSwitch]="chip.type">
        <rb-form-date-input
          [name]="'chipValue_' + i"
          *ngSwitchCase="'date'"
          class="required"
          [label]="'simpleSearch.value' | translate"
          [(ngModel)]="chip.value"
          [attr.data-cy]="'queryConditionInputChipValue-' + i"
          [disabled]="checkOperatorTypeExists(chip.operatorID)"
          (focusin)="focusedChip = chip"
          (focusout)="focusedChip = undefined; trimChipValues(chip)"
          (ngModelChange)="updateDate(chip, $event); composeQuery()"
          [options]="{
            enableTime: true,
            allowInput: true,
            static: true
          }"
        ></rb-form-date-input>

        <date-time-range-popover
          *ngSwitchCase="'datetimerange'"
          [name]="'chipValue_' + i"
          [label]="'simpleSearch.value' | translate"
          [ngModel]="chip.value"
          [disabled]="checkOperatorTypeExists(chip.operatorID)"
          [attr.data-cy]="'queryConditionInputChipValue-' + i"
          (ngModelChange)="updateRange(chip, $event); composeQuery()"
          (focusin)="focusedChip = chip"
          (focusout)="focusedChip = undefined; trimChipValues(chip)"
          [options]="{ enableTime: true, allowInput: true }"
        ></date-time-range-popover>

        <rb-form-input
          [name]="'chipValue_' + i"
          *ngSwitchCase="'boolean'"
          class="required"
          readonly="true"
          [rbDropdown]="dropDown"
          [(ngModel)]="chip.value"
          [required]="true"
          (focusin)="focusedChip = chip"
          [attr.data-cy]="'queryConditionInputChipValue-' + i"
          (focusout)="focusedChip = undefined; trimChipValues(chip)"
          [label]="'simpleSearch.value' | translate"
        ></rb-form-input>

        <ng-template #dropDown>
          <a class="rb-dropdown-item" (click)="setBooleanValue(chip, true); composeQuery()">true</a>
          <a class="rb-dropdown-item" (click)="setBooleanValue(chip, false); composeQuery()">
            false
          </a>
        </ng-template>

        <rb-form-input
          spellcheck="false"
          [attr.data-cy]="'queryConditionInputChipValue-' + i"
          [label]="'simpleSearch.value' | translate"
          [name]="'chipValue_' + i"
          [disabled]="checkOperatorTypeExists(chip.operatorID)"
          *ngSwitchDefault
          [(ngModel)]="chip.value"
          (ngModelChange)="setStringValue(chip); composeQuery()"
          (focusin)="focusedChip = chip"
          (focusout)="focusedChip = undefined; trimChipValues(chip)"
        ></rb-form-input>
      </div>
    </div>
  </div>
  <div class="text-right">
    <button class="rb-btn rb-link" type="button" [rbPopover]="queryAsText">
      <span class="rb-ic rb-ic-info-i"></span>
      {{ 'simpleSearch.showQuery' | translate }}
    </button>
    <button
      class="rb-btn rb-link pr-0"
      type="button"
      [attr.data-cy]="'queryConditionInputAddCondition'"
      (click)="createChip(); composeQuery()"
    >
      <span class="rb-ic rb-ic-add"></span>
      {{ 'simpleSearch.addCondition' | translate }}
    </button>
  </div>

  <div class="mt-3">
    <ng-container *ngFor="let chip of chips; let i = index">
      <filter-chip
        [chip]="chip"
        [isSelectable]="false"
        [focused]="chip === focusedChip"
        (chipRemoved)="removeChip(chip)"
      ></filter-chip>

      <logical-operator-display
        *ngIf="logicalOperators[i]"
        [logic]="logicalOperators[i]"
        (logicalOperatorChange)="switchLogicalOperator(i); composeQuery()"
      ></logical-operator-display>
    </ng-container>
  </div>
</div>
