import { ElementRef, Injectable, QueryList } from '@angular/core';
import { GridsterComponent, GridsterItem, GridsterPush } from 'angular-gridster2';
import { GridsterItemComponentInterface } from 'angular-gridster2/lib/gridsterItem.interface';
import { ReplaySubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { outerHeight } from '../../shared/style-utils';
import { DashboardWidgetComponent } from '../dashboard-widget/dashboard-widget.component';
import { DashboardWidgetConfig, LayoutBehavior } from '../models/DashboardWidgetConfig';
import { GridLayoutConfig, WidgetTypeDefinition } from '../models/widget-registry.model';
import { DashboardGridSingletonService } from './dashboard-grid-singleton.service';
import { WidgetInstanceService } from './widget-instance.service';
import { WidgetsRegistryService } from './widgets-registry.service';

export const DEFAULT_ROWS_COUNT = 6;

export interface WidgetWidth {
  id: string;
  width: number;
}

@Injectable()
export class DashboardGridService {
  private widgetWidthChange = new ReplaySubject<WidgetWidth>(Number.MAX_SAFE_INTEGER);
  private widgetWidthChangeObs$ = this.widgetWidthChange.asObservable();

  widgets: DashboardWidgetConfig[];
  items: GridsterItem[];
  activateDragAndResize: boolean;
  gridster: GridsterComponent;
  widgetComponents: QueryList<DashboardWidgetComponent>;
  tabIdentifier?: string;
  private resizeObservers = {};
  private richTextsRowsChangedMap = new Map<string, boolean>();

  isVisible(widget: DashboardWidgetConfig) {
    return this.dashboardGridSingletonService.dashboardDataService.isWidgetVisible(widget);
  }

  constructor(
    private widgetRegistry: WidgetsRegistryService,
    private dashboardGridSingletonService: DashboardGridSingletonService
  ) {}

  widgetWidthChangeById$(widgetId: string) {
    return this.widgetWidthChangeObs$.pipe(filter((change) => change.id === widgetId));
  }

  isFixedWidgetLayout(widgetId: string) {
    return this.widgets?.find((widget) => widget.id === widgetId).layoutBehavior === 'fixed';
  }

  trackWidthOfWidget(widget: WidgetWidth) {
    this.widgetWidthChange.next(widget);
  }

  itemResize(
    item: GridsterItem,
    itemComponent: GridsterItemComponentInterface,
    skipWidgetHeightVerification = false,
    forceWidgetResize = false
  ) {
    const gridLayoutConfig = this.widgetRegistry.getTypeDefinition(
      item.widget.type
    )?.gridLayoutConfig;

    if (
      (!this.skipAutoHeightCalculation(item.widget, gridLayoutConfig) || forceWidgetResize) &&
      this.isVisible(item.widget)
    ) {
      // for some reason when we have a tab widget, the widget components are delayed
      if (!this.widgetComponents) {
        setTimeout(() => {
          this.changeItemSize(item, itemComponent, skipWidgetHeightVerification, gridLayoutConfig);
        });
      } else {
        this.changeItemSize(item, itemComponent, skipWidgetHeightVerification, gridLayoutConfig);
      }
    }
  }

  convertWidgetsToItems(isInsideTabWidget = false): void {
    if (!this.widgets) {
      this.items = [];
    } else {
      this.items = this.widgets
        // filter only first level widgets, gridster is not working inside tab widget
        .filter((widget: DashboardWidgetConfig) => !widget.insideTab || isInsideTabWidget)
        .map((w: DashboardWidgetConfig) => {
          const gridLayoutConfig = this.widgetRegistry.getTypeDefinition(w.type)?.gridLayoutConfig;

          return {
            cols: w.columns,
            rows: this.isVisible(w) ? w.rows : 2,
            x: w.positionX,
            y: w.positionY,
            widget: w,
            minItemRows: gridLayoutConfig?.minRowsCount,
            minItemCols: gridLayoutConfig?.minColsCount || 1
          };
        });
    }
  }

  removeItemByWidgetId(widgetId: string) {
    const item = this.items.find((item) => item.widget.id === widgetId);
    if (item) {
      this.items.splice(this.items.indexOf(item), 1);
    }
  }

  convertItemsToWidgets(item: GridsterItem) {
    this.widgets = this.widgets.map((widget) => {
      if (item.widget.id === widget.id) {
        return {
          ...widget,
          columns: item.cols,
          rows: item.rows,
          positionX: item.x,
          positionY: item.y
        };
      }
      return widget;
    });
  }

  resizeWidgetById(widgetId: string, forceWidgetResize = false) {
    if (this.gridster?.grid && this.items) {
      const itemComponent = this.gridster.grid.find((el) => el.item.widget.id === widgetId);
      const item = this.items.find((item) => item.widget.id === widgetId);
      if (itemComponent && item) {
        if (this.resizeObservers[widgetId]) {
          this.resizeObservers[widgetId].observer.unobserve(this.resizeObservers[widgetId].content);
          delete this.resizeObservers[widgetId];
        }
        this.itemResize(item, itemComponent, false, forceWidgetResize);
      }
    }
  }

  setItemSize(widgetId: string, rows: number) {
    const itemComponent = this.gridster.grid.find((el) => el.item.widget.id === widgetId);
    const item = this.items.find((item) => item.widget.id === widgetId);
    item.rows = rows;
    this.dashboardGridSingletonService.mainLayoutInstance.widgets.find(
      (w) => w.id === widgetId
    ).rows = rows;
    this.pushItem(itemComponent, rows, item.minItemRows);

    this.gridster.options?.api?.optionsChanged();
    this.gridster.options?.api?.resize();
  }

  private changeItemSize(
    item: GridsterItem,
    itemComponent: GridsterItemComponentInterface,
    skipWidgetHeightVerification = false,
    gridLayoutConfig: GridLayoutConfig
  ) {
    const hasNoRowsConfigured = !item.rows;

    if (!this.widgetComponents) {
      return;
    }

    const widgetComponent = this.widgetComponents
      .toArray()
      .find((component) => component.widget.id === item.widget.id);
    if (!widgetComponent) {
      return;
    }

    const { widgetInstance, widgetContent, widget } = widgetComponent;
    const isRendered = new Subject<null>();

    widgetInstance?.rendered?.pipe(takeUntil(isRendered)).subscribe((rendered) => {
      if (rendered) {
        isRendered.next(null);
      }
      const widgetRows = DashboardGridService.calculateWidgetRows(
        widgetContent,
        this.gridster.curRowHeight,
        this.gridster.$options.margin,
        gridLayoutConfig,
        widget.type === 'tabwidget'
      );

      if (!skipWidgetHeightVerification && rendered) {
        this.verifyWidgetHeight(
          widgetContent,
          item,
          itemComponent,
          gridLayoutConfig,
          widget.type === 'tabwidget'
        );
      }

      // During init the main layout instance might not yet be available
      if (this.dashboardGridSingletonService.mainLayoutInstance) {
        const currentWidget = this.getCurrentWidget(widget.id);

        if (currentWidget) {
          const isRichTextOnAdaptMode = this.isRichTextOnAdaptMode(currentWidget);
          this.initializeRichTextRowsChangedMap(currentWidget, isRichTextOnAdaptMode);

          const rowsChanged = this.haveRowsChanged(currentWidget, widgetRows);
          const skipRowsChange = this.shouldSkipRowsChange(
            item,
            gridLayoutConfig,
            widgetRows,
            currentWidget
          );

          if (
            this.shouldUpdateRows(
              rowsChanged,
              item,
              rendered,
              skipRowsChange,
              isRichTextOnAdaptMode,
              currentWidget
            )
          ) {
            this.updateRows(itemComponent, widgetRows, gridLayoutConfig, currentWidget, widget.id);
            this.handlePostUpdateActions(
              rendered,
              hasNoRowsConfigured,
              isRichTextOnAdaptMode,
              currentWidget
            );
          }
        }
      }
    });
  }

  private getCurrentWidget(widgetId: string) {
    return this.dashboardGridSingletonService.mainLayoutInstance.widgets.find(
      (w) => w.id === widgetId
    );
  }

  private initializeRichTextRowsChangedMap(currentWidget: any, isRichTextOnAdaptMode: boolean) {
    if (isRichTextOnAdaptMode && !this.richTextsRowsChangedMap.get(currentWidget.id)) {
      this.richTextsRowsChangedMap.set(currentWidget.id, false);
    }
  }

  private haveRowsChanged(currentWidget: any, widgetRows: number) {
    return currentWidget.rows !== widgetRows;
  }

  private shouldSkipRowsChange(
    item: GridsterItem,
    gridLayoutConfig: GridLayoutConfig,
    widgetRows: number,
    currentWidget: any
  ) {
    return (
      this.isRowChangeNotAllowed(item, gridLayoutConfig) &&
      !this.isOnShrinkDragAndResizeRowChangeAllowed(widgetRows, currentWidget.rows)
    );
  }

  private shouldUpdateRows(
    rowsChanged: boolean,
    item: GridsterItem,
    rendered: boolean,
    skipRowsChange: boolean,
    isRichTextOnAdaptMode: boolean,
    currentWidget: any
  ) {
    const shouldChangeRowsOnceOnAdaptRichText =
      isRichTextOnAdaptMode && !this.richTextsRowsChangedMap.get(currentWidget.id);
    return (
      rowsChanged &&
      (!item.rows || rendered) &&
      (!skipRowsChange || shouldChangeRowsOnceOnAdaptRichText)
    );
  }

  private updateRows(
    itemComponent: GridsterItemComponentInterface,
    widgetRows: number,
    gridLayoutConfig: GridLayoutConfig,
    currentWidget: any,
    widgetId: string
  ) {
    this.pushItem(itemComponent, widgetRows, gridLayoutConfig.minRowsCount);
    this.widgets.find((w) => w.id === widgetId).rows = widgetRows;
    currentWidget.rows = widgetRows;
    this.gridster.options?.api?.optionsChanged();
    this.gridster.options?.api?.resize();
  }

  private handlePostUpdateActions(
    rendered: boolean,
    hasNoRowsConfigured: boolean,
    isRichTextOnAdaptMode: boolean,
    currentWidget: any
  ) {
    if (rendered && !this.activateDragAndResize && hasNoRowsConfigured) {
      this.dashboardGridSingletonService.saveRelayout(this.tabIdentifier ? this : null);
    }

    if (isRichTextOnAdaptMode) {
      this.richTextsRowsChangedMap.set(currentWidget.id, true);
    }
  }

  private isRichTextOnAdaptMode(config: DashboardWidgetConfig) {
    return config.type === 'rich-text' && config.layoutBehavior === LayoutBehavior.Adapt;
  }

  private isOnShrinkDragAndResizeRowChangeAllowed(
    widgetRowsNeeded: number,
    currentWidgetRows: number
  ) {
    return this.activateDragAndResize && widgetRowsNeeded > currentWidgetRows;
  }

  private isRowChangeNotAllowed(item: GridsterItem, gridLayoutConfig: GridLayoutConfig) {
    const isNewWidget = !item.widget.rows;
    return !isNewWidget && gridLayoutConfig.disableFixedHeight;
  }

  private pushItem(
    itemToPush: GridsterItemComponentInterface,
    widgetRows: number,
    minRowsCount = widgetRows
  ): void {
    if (!itemToPush.gridster) {
      itemToPush.gridster = this.gridster;
    }
    const push = new GridsterPush(itemToPush);
    itemToPush.$item.rows = widgetRows;
    itemToPush.item.rows = widgetRows;
    itemToPush.$item.minItemRows = minRowsCount;
    itemToPush.item.minItemRows = minRowsCount;

    // set it for true as the pushItems function expect the grid to be drggable in order to push the items
    this.gridster.$options.draggable.enabled = true;

    // set minItemRows for each widget to 1 during execution of pushItems as
    // sometimes the push fails due to check against min items rows
    const minItemRows = [];
    this.gridster.grid.forEach((component) => {
      minItemRows.push({
        id: component.item.widget.id,
        minItemRows: component.item.minItemRows
      });
      component.item.minItemRows = 1;
    });
    if (this.gridster && push.pushItems(push.fromNorth)) {
      this.gridster.$options.draggable.enabled = false;
      push.checkPushBack();
      push.setPushedItems();
      itemToPush.setSize();
      itemToPush.checkItemChanges(itemToPush.$item, itemToPush.item);
    } else {
      push.restoreItems();
    }
    // set again the min rows
    this.gridster.grid.forEach((component) => {
      component.item.minItemRows = minItemRows.find(
        (row) => row.id === component.item.widget.id
      )?.minItemRows;
    });

    push.destroy();
  }

  private static calculateWidgetRows(
    widgetContent: ElementRef,
    currRowHeight: number,
    margin: number,
    gridLayoutConfig: GridLayoutConfig,
    isTabWidget: boolean
  ) {
    // this is workaround, without this border, the offsetHeight is incorrect for some widgets
    // see more about https://www.w3.org/TR/CSS2/box.html#collapsing-margins
    // remove 2 pixels for added border
    const widgetHeight = widgetContent.nativeElement.offsetHeight - 2;
    widgetContent.nativeElement.style.border = '1px solid black';
    widgetContent.nativeElement.style.border = 'none';

    if (gridLayoutConfig && gridLayoutConfig.ignoreGridsterItemMargin) {
      if (isTabWidget) {
        return Math.ceil((widgetHeight - margin) / currRowHeight) || 1;
      } else {
        return Math.ceil(widgetHeight / currRowHeight) || 1;
      }
    }
    return Math.ceil((widgetHeight + margin) / currRowHeight);
  }

  /**
   * Create a resize observer watching for component element reference height changes.
   * Works only for a second and then subscription is destroyed.
   */
  private verifyWidgetHeight(
    widgetContent: ElementRef,
    item: GridsterItem,
    itemComponent: GridsterItemComponentInterface,
    gridLayoutConfig: GridLayoutConfig,
    isTabWidget: boolean
  ) {
    if (!this.resizeObservers[item.widget.id]) {
      this.resizeObservers[item.widget.id] = {};
      this.resizeObservers[item.widget.id].observer = new ResizeObserver(() => {
        const actualRowsNeeded = DashboardGridService.calculateWidgetRows(
          widgetContent,
          this.gridster.curRowHeight,
          this.gridster.$options.margin,
          gridLayoutConfig,
          isTabWidget
        );

        const currentRows = item.rows;

        if (
          !this.getWidgetInstance(item.widget.id)?.loading.value &&
          actualRowsNeeded !== currentRows
        ) {
          this.itemResize(item, itemComponent, true);
        }
      });
      this.resizeObservers[item.widget.id].content = widgetContent.nativeElement;
      this.resizeObservers[item.widget.id].observer.observe(widgetContent.nativeElement);
    }
  }

  getWidgetContainerHeight(
    widgetDefinition: WidgetTypeDefinition,
    widgetContent: ElementRef,
    widget: DashboardWidgetConfig
  ) {
    const gridLayoutConfig = this.widgetRegistry.getTypeDefinition(
      widgetDefinition?.type
    )?.gridLayoutConfig;

    if (this.skipAutoHeightCalculation(widget, gridLayoutConfig) && widgetContent) {
      const widgetHeaderElement = widgetContent.nativeElement.querySelector('widget-header');

      const headerHeight = widgetHeaderElement ? outerHeight(widgetHeaderElement) : 0;
      return 'calc(100% - ' + headerHeight + 'px)';
    } else {
      return 'unset';
    }
  }

  destroy() {
    Object.keys(this.resizeObservers).forEach((key) => {
      this.resizeObservers[key].observer.unobserve(this.resizeObservers[key].content);
      delete this.resizeObservers[key];
    });
  }

  private getWidgetInstance(widgetId: string): WidgetInstanceService {
    return this.widgetComponents.toArray().find((component) => component.widget.id === widgetId)
      ?.widgetInstance;
  }

  private skipAutoHeightCalculation(
    widget: DashboardWidgetConfig,
    gridLayoutConfig: GridLayoutConfig
  ) {
    // skip auto resize when the layout behavior is set to Fixed AND rows are null
    // we want to use the auto calculation on fixed layout when there is a new widget only
    if (widget.layoutBehavior === LayoutBehavior.Fixed && !!widget.rows) {
      return true;
    }
    return !!gridLayoutConfig?.disableWidgetHeightBasedCalculation;
  }
}
