<rb-dialog
  [dialogTitle]="'fileLinkCopy.title' | translate"
  [alert]="{ type: 'warning', errorCode: '' }"
  [confirmationButtonLabel]="'copy' | translate | titlecase"
  [cancelButtonLabel]="'cancel' | translate | titlecase"
>
  <p>{{ 'fileLinkCopy.info.securityReasons' | translate }}</p>
  <p>{{ 'fileLinkCopy.info.copyUsage' | translate }}</p>

  <rb-form-input
    data-cy="fileLinkCopyDialogUrl"
    [label]="'fileLinkCopy.label' | translate"
    [ngModel]="fileUrl"
    readonly
  ></rb-form-input>
</rb-dialog>
