import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ProjectsService } from '../../shared-projects/services/projects.service';
import { DomainStatus } from './domain.status';
import { filter, map, tap } from 'rxjs/operators';
import {
  ImageWithUploadStatus,
  trackImageUploadProgress
} from '../../shared-modules/image-uploader/image-uploader.util';

@Injectable()
export class AdminDomainService {
  constructor(private http: HttpClient, private projectsService: ProjectsService) {}

  private whiteLabelingAdminBaseUrl = `/project-management-service/v1/domains`;

  private get backgroundImageBaseUrl(): string {
    return `/ui/api/background/${this.projectsService.projectName}`;
  }

  private get whiteLabelingAdminProjectUrl(): string {
    return `${this.whiteLabelingAdminBaseUrl}/${this.projectsService.projectName}`;
  }

  isWhiteLabelingEnabled(): boolean {
    return environment.whiteLabeling;
  }

  isCustomHomepageEnabled() {
    return !this.projectsService.isFreePlan;
  }

  confirmDomainDeletion(): Observable<DomainStatus> {
    return this.http.delete<DomainStatus>(this.whiteLabelingAdminProjectUrl);
  }

  setUpTrendsGuid(guid: string): Observable<DomainStatus> {
    const url = `${this.whiteLabelingAdminProjectUrl}/uptrends`;
    return this.http.put<DomainStatus>(url, { uptrendsGuid: guid });
  }

  confirmDomain(): Observable<DomainStatus> {
    const url = `${this.whiteLabelingAdminProjectUrl}/confirm`;
    return this.http.post<DomainStatus>(url, null);
  }

  getCustomDomainsOrderedByDomainName(): Observable<DomainStatus[]> {
    return this.http.get<DomainStatus[]>(this.whiteLabelingAdminBaseUrl).pipe(
      map((domainStatusArray: DomainStatus[]) => {
        return domainStatusArray.sort((a, b) => a.domain.localeCompare(b.domain));
      })
    );
  }

  uploadHomepageBackgroundImage(image: ImageWithUploadStatus): Observable<any> {
    // Returns the ID in an object on successful upload
    const data = new FormData();
    data.append('attachment', image.file);

    return this.http
      .post(this.backgroundImageBaseUrl, data, {
        observe: 'events',
        reportProgress: true
      })
      .pipe(
        filter((ev) => trackImageUploadProgress(image, ev)),
        map((event: HttpResponse<any>) => event.body.url)
      );
  }

  removeHomepageBackgroundImage(): Observable<any> {
    return this.http.delete(this.backgroundImageBaseUrl);
  }
}
