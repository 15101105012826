import {
  ProjectConfig,
  ProjectConfigProperties,
  ProjectViewConfig,
  TranslatedString
} from '../../../shared-projects/models/project.model';
import {
  ALL_AUTH_PROVIDERS,
  AuthProvider,
  AuthProviderPrefix
} from '../../../core/services/models/auth-provider';
import {
  CUSTOM_DOMAIN_THEME,
  DEFAULT_BACKGROUND,
  Theme
} from '../../../core/services/models/app-theme';
import { ProjectConfigEvent } from '../../../shared-projects/services/projects.service';

export class ProjectUiConfigPublic implements Omit<ProjectConfig, 'logo' | 'views' | 'options'> {
  name: string; // technical project name
  label: TranslatedString;
  logo: {
    sourceUrl: string;
    logoUrl: string;
  };
  footerLinks: ProjectViewConfig[];
  contactLink?: string;
  allowedIDPs: AuthProviderPrefix[];
  pageContent: CustomHomepageContent;
  properties: ProjectConfigProperties;

  get backgroundImage(): string {
    return `url(${this.pageContent?.backgroundImageUrl || DEFAULT_BACKGROUND['INSIGHTS']})`;
  }

  get themeLogoUrl(): string {
    return this.logo.logoUrl || '';
  }

  get themeLogo(): string {
    return this.logo.sourceUrl;
  }

  get supportLink(): string {
    return this.contactLink;
  }

  constructor(config: Partial<ProjectUiConfigPublic>) {
    Object.assign(this, config);
    return this;
  }

  mapToAppTheme(): Theme {
    const theme = CUSTOM_DOMAIN_THEME;
    theme.backgroundImage = this.backgroundImage;
    theme.authenticationProviders = this.getValidAuthenticationProviders(theme);
    theme.supportLink = this.supportLink ?? theme.supportLink;
    theme.themeLogo = this.themeLogo || theme.themeLogo;
    theme.themeLogoUrl = this.themeLogoUrl;
    theme.newsLink = this.properties.newsLink ?? theme.newsLink;
    theme.helpLink = this.properties.helpLink ?? theme.helpLink;
    theme.apiDocsLink = this.properties.apiDocsLink ?? theme.apiDocsLink;
    return theme;
  }

  mapToProjectConfigEvent(): ProjectConfigEvent {
    return new ProjectConfigEvent(this.mapToProjectConfig());
  }

  private mapToProjectConfig(): ProjectConfig {
    const projectConfig = {} as ProjectConfig;
    Object.assign(projectConfig, this);
    projectConfig.logo = this.logo?.sourceUrl || '';
    projectConfig.logoUrl = this.logo?.logoUrl || '';
    projectConfig.options = {};
    projectConfig.views = [];
    return projectConfig;
  }

  private getValidAuthenticationProviders(theme: Theme): AuthProvider[] {
    if (this.allowedIDPs?.length) {
      return this.allowedIDPs
        .filter((idp) => ALL_AUTH_PROVIDERS[idp])
        .map((idp) => ALL_AUTH_PROVIDERS[idp]);
    } else {
      return theme.authenticationProviders;
    }
  }
}

export class CustomHomepageContent {
  headerText: string;
  descriptionText: string;
  backgroundImageUrl: string;

  constructor() {
    this.headerText = '';
    this.descriptionText = '';
    this.backgroundImageUrl = '';
  }
}
