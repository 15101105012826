import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GaugeChartComponent } from './gauge-chart/gauge-chart.component';
import { ThermometerComponent } from './thermometer/thermometer.component';

@NgModule({
  imports: [CommonModule],
  declarations: [GaugeChartComponent, ThermometerComponent],
  exports: [GaugeChartComponent, ThermometerComponent]
})
export class CustomChartsModule {}
