import { HttpErrorResponse } from '@angular/common/http';
import { GlobalAlertService } from '../../../core/services/global-alert.service';
import { UserAuthService } from '../../../core/services/user-auth.service';
import { readBlob } from '../../action-buttons/action-config-util';
import { AZURE_ERROR_CODE } from '../models/rest-request.model';
import { translate } from '../../../shared/translation-util';
import { DialogCloseEventWithResult } from '@inst-iot/bosch-angular-ui-components';

export function handleAzureSessionError(
  response: HttpErrorResponse,
  alertService: GlobalAlertService,
  userAuthService: UserAuthService
) {
  if (response.status === 440) {
    readBlob(response.error)
      .then((message) => {
        if (message?.includes(AZURE_ERROR_CODE)) {
          alertService
            .showWarning(
              translate('widget.azureSessionExpiredMessage'),
              translate('refreshBrowserWarning.refreshSessionButton'),
              translate('error.reload'),
              translate('confirmation.abort')
            )
            .then((isReloadClicked: DialogCloseEventWithResult) => {
              if (isReloadClicked.event === 'confirmed') {
                userAuthService.performLoginAndRetainLocation();
              }
            });
        }
      })
      .catch((error: any) => {
        console.error('Error while reading blob', error);
      });
  }
}
