import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProjectsService } from '../../../shared-projects/services/projects.service';
import { Role } from '../models/role.model';
import { User } from '../../../shared/api-model';
import { getPagableParams } from '../../../shared/http-utils';
import { Sort } from '@inst-iot/bosch-angular-ui-components';

@Injectable()
export class RoleApiService {
  get baseUrl(): string {
    return `/project-management-service/v1/${this.projectsService.projectName}`;
  }

  get rolesUrl(): string {
    return `${this.baseUrl}/roles`;
  }

  constructor(private http: HttpClient, private projectsService: ProjectsService) {}

  /* Role API */

  getRoleList(
    page = 0,
    pageSize = 100,
    searchTerm?: string,
    sort?: Sort
  ): Observable<HttpResponse<Role[]>> {
    let params = getPagableParams({ page, pageSize });
    if (searchTerm) {
      params = params.set('search', searchTerm);
    }
    if (sort) {
      let sortId;
      switch (sort.id) {
        case 'roleDisplayName':
          sortId = 'name';
          break;
        case 'createTs':
          sortId = 'createTimestamp';
          break;
        default:
          sortId = sort.id;
      }
      params = params.set('sort', `${sortId},${sort.direction}`);
    }

    return this.http.get<Role[]>(`${this.rolesUrl}`, { params, observe: 'response' }).pipe(
      map((response: HttpResponse<Role[]>) => {
        const roles = response.body.map((role) => new Role(role));
        return new HttpResponse<Role[]>({ ...response, body: roles });
      })
    );
  }

  getRoleWithUsers(roleName: string): Observable<{ role: Role; users: User[] }> {
    return combineLatest([this.getRoleList(0, 100, roleName), this.getUsersOfRole(roleName)]).pipe(
      map(([rolesResponse, users]) => {
        const role = rolesResponse.body.find((r) => r.roleName === roleName);
        return role ? { role: role, users: users } : undefined;
      })
    );
  }

  getUsersOfRole(roleName: string): Observable<User[]> {
    return this.http.get<User[]>(`${this.rolesUrl}/${roleName}/users`);
  }

  createRole(role: Role): Observable<Role> {
    return this.http.post<Role>(`${this.rolesUrl}`, role).pipe(
      map((newRole: Role) => {
        return new Role(newRole);
      })
    );
  }

  updateRole(role: Role): Observable<Role> {
    return this.http.patch<Role>(`${this.rolesUrl}/${role.roleName}`, role).pipe(
      map((updatedRole: Role) => {
        return new Role(updatedRole);
      })
    );
  }

  updateRoleMappings(role: Role, usersIds: string[]) {
    return this.http
      .put<any>(`${this.rolesUrl}/${role.roleName}/role-mappings`, { userIds: usersIds })
      .pipe(
        map((updatedRole: Role) => {
          return new Role(updatedRole);
        })
      );
  }

  overwriteSessionRole(sessionRoles: string[]): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/session-role-overwrite`, sessionRoles);
  }

  removeSessionRoleOverwrite(): Observable<any> {
    return this.http.delete(`${this.baseUrl}/session-role-overwrite`);
  }

  deleteRole(roleName: string): Observable<null> {
    return this.http.delete<null>(`${this.rolesUrl}/${roleName}`);
  }
}
