import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FILE_LINK_REGEX } from '../../shared-modules/data-widgets/data-widgets-common/lib/hyperlink.service';
import { ProjectsService } from '../../shared-projects/services/projects.service';
import { UnsafeUrlDialogsService } from '../services/unsafe-url-dialogs.service';
import { isValidUrlScheme } from '../validators/url-scheme-validator.directive';

@Directive({
  selector: '[unsafeUrlHandler]'
})
export class UnsafeUrlHandlerDirective {
  @Input() unsafeUrlHandler?: string;

  constructor(
    private projectsService: ProjectsService,
    private elementRef: ElementRef,
    private unsafeUrlDialogsService: UnsafeUrlDialogsService
  ) {}

  @HostListener('click', ['$event']) validate(event: MouseEvent) {
    const url = this.unsafeUrlHandler || this.elementRef.nativeElement.href;
    if (!validateUnsafeUrl(this.projectsService, url, this.unsafeUrlDialogsService)) {
      event.preventDefault();
    }
  }
}

export function validateUnsafeUrl(
  projectsService: ProjectsService,
  href: string,
  unsafeUrlDialogsService: UnsafeUrlDialogsService
) {
  const isFileLink = FILE_LINK_REGEX.test(href);
  const isUnsafe =
    href.startsWith('unsafe:') || !isValidUrlScheme(href, projectsService.allowedUrlSchemes);

  if (isUnsafe) {
    unsafeUrlDialogsService.showErrorDialog();
    return false;
  }

  // Handled in here because file links are considered a security risk by browsers and therefore are unsafe
  if (isFileLink) {
    unsafeUrlDialogsService.showFileLinkCopyDialog(href);
    return false;
  }

  return true;
}
