import { Injector } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { DevicesService } from '../../../../../devices/services/devices.service';
import { translate } from '../../../../../shared/translation-util';
import { DataLoaderRegistryService } from '../../services/data-loader-registry.service';
import { resolveParameter } from '../data-source-utils';
import { assignWithDefaults } from '../model-utils';
import { WidgetContext } from '../widget-context';
import { DataSource, dataSourceTypes } from './data-source-base';
import { DataSourceType, DsParameter } from './models';
import { isEmpty } from 'lodash-es';
import { CalendarWidgetService } from '../../../../../dashboards/widgets/calendar-widget/services/calendar-widget.service';

export class ThingDataSource extends DataSource {
  type: DataSourceType = 'thing';
  label = 'Single device';
  thingId: DsParameter = null;
  deviceType: string = null;

  constructor(props?: Partial<ThingDataSource>) {
    super(props);
    assignWithDefaults(this, props);
  }

  static initDevicesService(ctx: WidgetContext): DevicesService {
    let devicesService = ctx.injector.get(DevicesService, null);
    if (!devicesService) {
      const injector = Injector.create({
        parent: ctx.injector,
        providers: [{ provide: DevicesService }]
      });
      devicesService = injector.get(DevicesService);
    }

    return devicesService;
  }

  static initCalendarWidgetService(ctx: WidgetContext): CalendarWidgetService {
    let calendarWidgetService = ctx.injector.get(CalendarWidgetService, null);
    if (!calendarWidgetService) {
      const injector = Injector.create({
        parent: ctx.injector,
        providers: [{ provide: CalendarWidgetService }]
      });
      calendarWidgetService = injector.get(CalendarWidgetService);
    }
    return calendarWidgetService;
  }

  static isThingDataSource(ds: DataSource): ds is ThingDataSource {
    return ds.type === 'thing';
  }

  validate(deviceTypeMandatory = false): ValidationErrors | null {
    const results = {};
    if (!this.validateThingId()) {
      results['missingThingId'] = true;
    }
    if (deviceTypeMandatory && isEmpty(this.deviceType)) {
      results['missingThingDevice'] = true;
    }
    return Object.keys(results).length > 0 ? results : null;
  }

  private validateThingId() {
    const thingId = this.thingId as any;
    if (thingId === null) {
      return false;
    }
    const isRef = thingId.ref !== undefined;
    return !isEmpty(isRef ? thingId.default : thingId.value);
  }

  loadData(ctx: WidgetContext): Observable<any[]> {
    const thingId = resolveParameter(this.thingId, ctx);
    if (!thingId) {
      return throwError(new Error('Could not resolve valid ThingId'));
    }

    const devicesService = ThingDataSource.initDevicesService(ctx);
    const source = devicesService.getDevice(thingId).pipe(map((thing) => [thing]));
    const registryService = ctx.injector.get(DataLoaderRegistryService, null);
    return registryService
      ? source.pipe(registryService.registerDataSource(thingId, ctx.priority))
      : source;
  }
}

dataSourceTypes.push({
  name: 'thing',
  i18nLabel: translate('dataSourceConfig.thing'),
  constructor: ThingDataSource
});
