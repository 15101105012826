import {
  createComponent,
  EnvironmentInjector,
  Inject,
  Injectable,
  Injector,
  Optional
} from '@angular/core';
import {
  CustomFeatureProperty,
  DeviceTypeDefinition,
  DeviceTypeFeature
} from '../models/device-types';
import { FEATURE_DEFINITION, FeatureDefinition } from '../models/feature-definition';
import { NewThing, Thing, ThingFeature } from '../models/thing';
import { FeatureWithConfig } from '../../dashboards/widgets/device-details-widget/device-details-widget.component';
import { TranslatedPipe } from '../../shared/pipes/translated.pipe';

@Injectable()
export class FeatureDefinitionsService {
  constructor(
    private translated: TranslatedPipe,
    private environmentInjector: EnvironmentInjector,
    @Optional() @Inject(FEATURE_DEFINITION) public featureDefinitions: Array<FeatureDefinition>
  ) {}

  getFeatureDefinition(fullDefinition: string): FeatureDefinition | null {
    if (!fullDefinition) {
      return null;
    }
    const definitionNameWithoutVersion = fullDefinition.split(':').slice(0, 2).join(':');
    return this.getFeatureDefinitionWithoutVersion(definitionNameWithoutVersion);
  }

  getFeatureDefinitionWithoutVersion(
    definitionNameWithoutVersion: string
  ): FeatureDefinition | null {
    return this.featureDefinitions.find((wt) => wt.definition === definitionNameWithoutVersion);
  }

  getFeatureDetailsComponentInstance(fullDefinition: string, properties: any, injector: Injector) {
    const featureDefinition = this.getFeatureDefinition(fullDefinition);
    if (!featureDefinition) {
      return null;
    }

    const compRef = createComponent(featureDefinition.detailsComponent, {
      environmentInjector: this.environmentInjector,
      elementInjector: injector
    });
    compRef.instance.properties = properties;
    compRef.instance.featureDefinition = featureDefinition;
    return compRef;
  }

  getFeatureDetailsViewComponentInstance(
    fullDefinition: string,
    thingFeature: ThingFeature,
    featureWithConfig: FeatureWithConfig,
    thingId,
    isEditable,
    injector: Injector
  ) {
    const featureDefinition = this.getFeatureDefinition(fullDefinition);
    if (!featureDefinition) {
      return null;
    }

    const compRef = createComponent(featureDefinition.detailsViewComponent, {
      environmentInjector: this.environmentInjector,
      elementInjector: injector
    });
    compRef.instance.thingFeature = thingFeature || { definition: [], properties: null };
    compRef.instance.featureWithConfig = featureWithConfig;
    compRef.instance.thingId = thingId;
    compRef.instance.isEditable = isEditable;
    return compRef;
  }

  getDefaultThing(definition: DeviceTypeDefinition): NewThing {
    const thing: NewThing = {
      attributes: {
        type: definition.type
      },
      features: {}
    };

    definition.features.forEach((fDef) => {
      if (fDef.mandatory && fDef.defaultName && !fDef.virtual) {
        thing.features[fDef.defaultName] = this.getDefaultFeature(fDef);
      }
    });

    return thing;
  }

  getDefaultFeature(fDef: DeviceTypeFeature): ThingFeature {
    const feature = {
      definition: [`${fDef.definition}:1`],
      properties: {}
    };

    const featureDef = this.getFeatureDefinition(fDef.definition);
    if (featureDef) {
      feature.definition[0] = `${featureDef.definition}:${featureDef.version}`;
    }
    if (featureDef && featureDef.defaultProperties) {
      feature.properties = featureDef.defaultProperties;
    }
    if (fDef.customProperties) {
      fDef.customProperties.forEach((prop) => {
        feature.properties[prop.name] = this.getDefaultValueOfProperty(prop);
      });
    }
    return feature;
  }

  getNewFeatureId(thing: Thing, fDef: DeviceTypeFeature): string {
    const featureIds = Object.keys(thing.features);
    if (!featureIds.includes(fDef.defaultName)) {
      return fDef.defaultName;
    }
    let maxNum = 0;
    featureIds
      .filter((id) => id.startsWith(fDef.defaultName))
      .forEach((id) => {
        if (id.match(/(\d+)$/) && parseInt(RegExp.$1, 10) > maxNum) {
          maxNum = parseInt(RegExp.$1, 10);
        }
      });
    return fDef.defaultName + maxNum;
  }

  getDefaultDeviceTypeFeature(fd: FeatureDefinition): DeviceTypeFeature {
    return {
      definition: fd.definition,
      defaultName: fd.defaultName,
      editable: true,
      mandatory: false,
      tracked: false,
      virtual: !!fd.virtual,
      icon: ''
    };
  }

  getDeviceTypeFeatureTitle(feature: DeviceTypeFeature) {
    if (!feature) {
      return '';
    }
    const featureDefinition = this.getFeatureDefinitionWithoutVersion(feature.definition);
    const translated = feature.properties?.label
      ? feature.properties.label
      : featureDefinition?.title;

    return this.translated.transform(translated);
  }

  private getDefaultValueOfProperty(prop: CustomFeatureProperty): string | string[] {
    if (prop.type === 'selection' && prop.multipleValuesAllowed === true) {
      return prop.defaultValue ? [prop.defaultValue] : [];
    } else {
      return prop.defaultValue;
    }
  }
}
