export enum BreakPoints {
  PORTRAIT_PHONE = 0,
  LANDSCAPE_PHONE = 576,
  TABLET = 768,
  DESKTOP = 922,
  LARGE = 1200
}

export const matchMediaQueries = {
  tabletOnly: `(min-width: ${BreakPoints.TABLET}px) and (max-width: ${BreakPoints.DESKTOP - 1}px)`,
  desktopOnly: `(min-width: ${BreakPoints.DESKTOP}px) and (max-width: ${BreakPoints.LARGE - 1}px)`,
  largeOnly: `(min-width: ${BreakPoints.LARGE}px)`,
  fromMobile: `(min-width: ${BreakPoints.PORTRAIT_PHONE}px)`,
  fromTablet: `(min-width: ${BreakPoints.TABLET}px)`,
  fromDesktop: `(min-width: ${BreakPoints.DESKTOP}px)`,
  fromLarge: `(min-width: ${BreakPoints.LARGE}px)`,
  untilMobile: `(max-width: ${BreakPoints.LANDSCAPE_PHONE - 1}px)`,
  untilTablet: `(max-width: ${BreakPoints.TABLET - 1}px)`,
  untilDesktop: `(max-width: ${BreakPoints.DESKTOP - 1}px)`,
  untilLarge: `(max-width: ${BreakPoints.LARGE - 1}px)`
};
