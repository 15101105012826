import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { ColorPickerModule } from '../color-picker/color-picker.module';
import { OptionsConfigurationModule } from '../options-configuration/options-configuration.module';
import { DataConditionalFormattingPipe } from './data-conditional-formatting.pipe';
import { DataConditionalFormattingComponent } from './data-conditional-formatting.component';
import { DataSelectionModule } from '../data-selection/data-selection.module';
import { ColorRangeComponent } from './color-range/color-range.component';

@NgModule({
  declarations: [
    DataConditionalFormattingPipe,
    DataConditionalFormattingComponent,
    ColorRangeComponent
  ],
  exports: [DataConditionalFormattingPipe, DataConditionalFormattingComponent, ColorRangeComponent],
  imports: [
    CommonModule,
    SharedModule,
    ColorPickerModule,
    OptionsConfigurationModule,
    DataSelectionModule
  ]
})
export class DataConditionalFormattingModule {}
