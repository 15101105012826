import { FilterParams, WidgetContext } from './widget-context';
import { isArray, isEmpty } from 'lodash-es';
import { FieldType } from '../../../static-filter/static-filter.model';
import {
  DeviceFilterParameterConfig,
  getCustomProperty
} from '../../../../dashboards/widgets/filter-widget/filter-widget.model';
import { DeviceTypeDefinition } from '../../../../devices/models/device-types';
import { map } from 'rxjs/operators';
import { Observable, OperatorFunction } from 'rxjs';
import { search } from 'jmespath';

const dateTypes: FieldType[] = [
  'singledatetime',
  'datetimerange',
  'date',
  'datetimerange2',
  'datetime'
];

export function buildThingsFilter(
  properties,
  ctx: WidgetContext,
  preFilterThings?: string
): string {
  const filters = [];
  if (properties?.filterConfig?.deviceParameters !== undefined) {
    const filterConfig = properties.filterConfig;
    if (filterConfig.deviceType) {
      filters.push(`eq(attributes/type,"${filterConfig.deviceType}")`);
    }
    if (ctx?.filterParams) {
      const resolvedThingsFilter = resolveThingsQueryUsingFilterParams(
        filterConfig,
        ctx.filterParams,
        properties?.deviceType
      );
      if (!isEmpty(resolvedThingsFilter)) {
        filters.push(resolvedThingsFilter);
      }
    }
  }
  if (preFilterThings) {
    filters.push(preFilterThings);
  }
  return filters.length > 0 ? `and(${filters.join(',')})` : undefined;
}

export function filterByJmesExpression<T>(expression: string): OperatorFunction<T, T> {
  return (source: Observable<T>): Observable<T> =>
    source.pipe(
      map((data: T) => {
        if (expression) {
          try {
            return search(data, expression) || [];
          } catch (e) {
            return [];
          }
        }
        return data;
      })
    );
}

/**
 * only available in view mode, not in edit mode
 * @returns array of parameter filter
 **/
function resolveThingsQueryUsingFilterParams(
  filterConfig,
  filterParams: FilterParams,
  deviceType: DeviceTypeDefinition
): string[] {
  const parameterFilters = [];
  filterConfig.deviceParameters.forEach((deviceParameter) => {
    const value = filterParams[deviceParameter.name];
    if (filterParams && value !== undefined && value.length !== 0) {
      if (isArray(value) && deviceParameter.type !== 'selection') {
        const values = value.map((valueElem) =>
          resolveThingFilterForParameter(deviceParameter, valueElem, deviceType)
        );
        parameterFilters.push(values?.length > 1 ? `or(${values.join(',')})` : values[0]);
      } else {
        parameterFilters.push(resolveThingFilterForParameter(deviceParameter, value, deviceType));
      }
    }
  });
  return parameterFilters;
}

function resolveThingFilterForParameter(
  deviceParam: DeviceFilterParameterConfig,
  value: any,
  deviceType: DeviceTypeDefinition
) {
  switch (deviceParam.type) {
    case 'checkbox':
      return [`eq(${deviceParam.propertyPath},${value})`];
    case 'device':
      return [`eq(${deviceParam.propertyPath},"${value.thingId || value}")`];
    case 'number':
      return [
        `ge(${deviceParam.propertyPath},${value.from})`,
        `le(${deviceParam.propertyPath},${value.to})`
      ];
    case 'selection':
      if (getCustomProperty(deviceParam.propertyPath, deviceType)?.multipleValuesAllowed) {
        return [`in(${deviceParam.propertyPath},"${joinIfArray(value, '","')}")`];
      } else {
        return [`eq(${deviceParam.propertyPath},"${value}")`];
      }
    default:
      if (dateTypes.indexOf(deviceParam.type) >= 0) {
        return `and(ge(${deviceParam.propertyPath},"${resolveDate(value.from)}"),le(${
          deviceParam.propertyPath
        },"${resolveDate(value.to)}"))`;
      } else {
        return [`ilike(${deviceParam.propertyPath},"*${value}*")`];
      }
  }
}

export function joinIfArray(value: any, joiner = '') {
  if (isArray(value)) {
    return value.join(joiner);
  }
  return value;
}

function resolveDate(date: string) {
  const parsedNumber = Number(date);
  if (!isNaN(parsedNumber)) {
    return new Date(new Date().getTime() + parsedNumber).toISOString();
  }
  return date;
}
