import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { get, isEmpty, isNil } from 'lodash-es';
import { Observable, of, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeviceHistory } from '../models/device-history';
import { DeviceHistoryRestService } from './device-history-rest.service';
import { DeviceTypesService } from './device-types.service';
import { QueryConfig } from '../../shared-query/models/query-config';
import { QueryEndPointTypes } from '../../shared-query/services/query.service';
import { ProjectsService } from '../../shared-projects/services/projects.service';
import { CollectionsService } from '../../shared-projects/services/collections.service';

@Injectable({ providedIn: 'root' })
export class DeviceHistoryService {
  get historyEntry(): DeviceHistory {
    return this._historyEntry;
  }

  private _lastDateChunk = '';
  private _historyEntry: DeviceHistory;

  constructor(
    private deviceTypeService: DeviceTypesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private restService: DeviceHistoryRestService,
    private projectsService: ProjectsService
  ) {}

  /**
   * Uses the REST Service to get a specific DeviceHistory document by ID
   *
   * @param id {string} - The mongodb id of the DeviceHistory document
   * @returns An Observable of a DeviceHistory document
   */
  loadHistoryEntry(id: string): Observable<DeviceHistory> {
    const queryConfig = this.getDeviceHistoryQueryConfig(
      { _id: { $oid: id } },
      this.projectsService.projectName,
      null,
      0,
      1
    );
    return this.restService.getDeviceHistoryDocuments(queryConfig).pipe(map((result) => result[0]));
  }

  loadHistoryEntryByRevision(thingId: string, revision: string | number) {
    const query = { thingId: thingId, revision: revision };
    const queryConfig = this.getDeviceHistoryQueryConfig(
      query,
      this.projectsService.projectName,
      null,
      0,
      1
    );
    return this.restService.getDeviceHistoryDocuments(queryConfig).pipe(map((result) => result[0]));
  }

  getValidEntryOfDevice(thingId: string, at: string): Observable<DeviceHistory> {
    const query = {
      thingId: thingId,
      validityBegin: { $lte: { $date: at } }
    };
    const queryConfig = this.getDeviceHistoryQueryConfig(
      query,
      this.projectsService.projectName,
      null,
      0,
      1
    );
    return this.restService.getDeviceHistoryDocuments(queryConfig).pipe(map((result) => result[0]));
  }

  /**
   * Returns the newest history entry that includes a snapshot with the given ThingId.
   * So either the device was changed or a device that was linked to it.
   *
   * @param thingId
   */
  getLastEntryWithDeviceId(thingId: string): Observable<DeviceHistory> {
    const query = { thingId: thingId };
    const queryConfig = this.getDeviceHistoryQueryConfig(
      query,
      this.projectsService.projectName,
      null,
      0,
      1
    );
    return this.restService.getDeviceHistoryDocuments(queryConfig).pipe(map((result) => result[0]));
  }

  /**
   * Loads an array of history entries
   * @param query - query that filters the entries
   * @param page
   * @param limit
   * @param collectionsService - if given a request is made to get the current thing history collection size for the total elements count
   *
   */
  loadHistoryList(
    query: Record<string, any>,
    page: number,
    limit: number,
    collectionsService?: CollectionsService
  ): Observable<{ totalCount: number; historyList: DeviceHistory[] }> {
    const deviceTypeObservable = isNil(this.deviceTypeService.deviceTypes)
      ? this.deviceTypeService.getDeviceTypes()
      : this.deviceTypeService.deviceTypes$;
    const queryConfig = this.getDeviceHistoryQueryConfig(
      query,
      this.projectsService.projectName,
      { createdAt: -1 },
      page,
      limit
    );
    return zip(
      this.restService.getDeviceHistoryDocuments(queryConfig),
      deviceTypeObservable,
      this.getTotalCount(queryConfig, collectionsService)
    ).pipe(
      map(([response, deviceTypes, totalCount]) => {
        const historyList: DeviceHistory[] = response.map((entry) => {
          const e = new DeviceHistory(entry);
          const deviceType = this.deviceTypeService.findDeviceType(
            get(e.getRoot(), 'attributes.type'),
            deviceTypes
          );
          if (deviceType && 'icon' in deviceType) {
            e.setIcon(deviceType.icon);
          }
          return e;
        });
        if (response.length < limit || (!response.length && page > 0)) {
          totalCount = page * limit + response.length;
        }
        return { totalCount, historyList };
      })
    );
  }

  private getTotalCount(
    queryConfig: QueryConfig,
    collectionsService?: CollectionsService
  ): Observable<number> {
    if (!isEmpty(queryConfig.query) || !collectionsService) {
      return of(Infinity);
    }
    return collectionsService.getCollectionCount(queryConfig.collection);
  }

  /**
   * Checks if there is a history entry of the device with a newer timestamp than the parameter
   * Returns true if no newer timestamp is found
   *
   * @param deviceId - The thingId of the device
   * @param backdateTimestamp - the timestamp that should be compared against
   */
  isBackdateTimestampValid(deviceId: string, backdateTimestamp: Date): Observable<boolean> {
    return this.getLastEntryWithDeviceId(deviceId).pipe(
      map((entry) => {
        if (!entry?.validityBegin) {
          // Make timestamp valid for a successful Device Creation
          return true;
        } else {
          const entryTimestamp = new Date(entry.validityBegin);
          return entryTimestamp < backdateTimestamp;
        }
      })
    );
  }

  sortIntoDateChunks(index: number, lastModified: string): boolean {
    if (index === 0 || this._lastDateChunk !== lastModified) {
      this._lastDateChunk = lastModified;
      return true;
    }
    return false;
  }

  getChildrenOfDevice(thingId: string, modified: string): Observable<DeviceHistory[]> {
    return this.restService
      .getDeviceChildren(thingId, modified)
      .pipe(map((r) => r.map((e) => new DeviceHistory(e))));
  }

  private getDeviceHistoryQueryConfig(
    query: Record<string, any>,
    project,
    sort: any,
    pageNumber: number,
    limit: number
  ) {
    return new QueryConfig({
      project: this.projectsService.projectName,
      query: query,
      collection: project + '_thing_history',
      tag: 'device-history',
      queryUpdatedByPagination: true,
      type: QueryEndPointTypes.Find,
      sort: sort,
      skip: pageNumber * limit,
      limit: limit,
      queuingTimeout: 600
    });
  }
}
