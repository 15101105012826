import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '../../shared/pipes/date.pipe';
import { LanguagesService } from '../../core/services/languages.service';
import { TimeAgoPipe } from '../../shared/pipes/time-ago.pipe';
import { TruncatePipe } from '../../shared/pipes/truncate.pipe';
import { isNil, isNumber } from 'lodash-es';
import {
  ArrayValueSeparatorType,
  DataFormattingInputType,
  ValueFormattingBooleanConfig,
  ValueFormattingDatetimeConfig,
  ValueFormattingJsonConfig,
  ValueFormattingNumberConfig,
  ValueFormattingResolvedConfig
} from './data-conditional-formatting.model';
import { ArrayValueSeparator } from './data-conditional-formatting-utils';

@Pipe({
  name: 'dataConditionalFormattingPipe'
})
export class DataConditionalFormattingPipe implements PipeTransform {
  constructor(
    private dataFormatPipe: DatePipe,
    private languagesService: LanguagesService,
    private timeAgoPipe: TimeAgoPipe,
    private truncatePipe: TruncatePipe
  ) {}

  transform(
    value: string | number | boolean,
    valueFormattingConfig: ValueFormattingResolvedConfig
  ): string {
    if (!valueFormattingConfig) {
      return value as string;
    }

    if (valueFormattingConfig.hidden) {
      return '';
    }

    switch (valueFormattingConfig.dataType) {
      case DataFormattingInputType.BOOLEAN:
        value = this.formatBoolean(
          String(value),
          valueFormattingConfig as ValueFormattingBooleanConfig<string>
        );
        break;
      case DataFormattingInputType.DATETIME:
        value = this.prepareFormatDateTime(String(value), valueFormattingConfig);
        break;
      case DataFormattingInputType.NUMBER:
        value = this.prepareFormatNumber(String(value), valueFormattingConfig);
        break;
      case DataFormattingInputType.JSON:
        value = this.formatJson(
          String(value),
          valueFormattingConfig as ValueFormattingJsonConfig<string>
        );
        break;
      case DataFormattingInputType.STRING:
        value = value as string;
        break;
    }

    const arraySeparator: ArrayValueSeparatorType = valueFormattingConfig?.arrayValueSeparator;
    if (arraySeparator && valueFormattingConfig.multipleValues) {
      value = ArrayValueSeparator[arraySeparator](value);
    }

    if (valueFormattingConfig.prefix) {
      value = valueFormattingConfig.prefix + value;
    }
    if (valueFormattingConfig.suffix) {
      value = value + valueFormattingConfig.suffix;
    }

    return value;
  }

  private formatBoolean(
    value: string,
    valueFormattingConfig: ValueFormattingBooleanConfig<string>
  ): string {
    return value
      .split('\n')
      .map((val) => {
        if (val === 'true') {
          return valueFormattingConfig.trueText || 'true';
        } else if (val === 'false') {
          return valueFormattingConfig.falseText || 'false';
        } else {
          return '';
        }
      })
      .join('\n');
  }

  private prepareFormatNumber(
    value: string,
    valueFormattingConfig: ValueFormattingResolvedConfig
  ): string {
    value = value
      .split('\n')
      .map((number: string) =>
        this.formatNumber(number, valueFormattingConfig as ValueFormattingNumberConfig<string>)
      )
      .join('\n');
    return value;
  }

  private formatNumber(
    value: string | number,
    valueFormattingConfig: ValueFormattingNumberConfig<string>
  ) {
    let formatted = value;

    if (typeof value === 'string') {
      formatted = parseFloat(value);
      if (isNaN(formatted)) {
        return 'Invalid Number';
      }
    }

    if (isNil(value)) {
      return '';
    }

    formatted = formatted.toLocaleString(this.languagesService.currentLanguage, {
      useGrouping: valueFormattingConfig.thousandsSeparator,
      ...(isNumber(valueFormattingConfig.decimalPoints)
        ? {
            minimumFractionDigits: valueFormattingConfig.decimalPoints,
            maximumFractionDigits: valueFormattingConfig.decimalPoints
          }
        : {})
    });

    return formatted;
  }

  private prepareFormatDateTime(
    value: string,
    valueFormattingConfig: ValueFormattingResolvedConfig
  ): string {
    value = value
      .split('\n')
      .map((date: string) =>
        this.formatDatetime(date, valueFormattingConfig as ValueFormattingDatetimeConfig<string>)
      )
      .join('\n');
    return value;
  }

  private formatDatetime(
    value: string,
    valueFormattingConfig: ValueFormattingDatetimeConfig<string>
  ): string {
    let formatted = value;

    if (valueFormattingConfig.dateFormat === 'relative') {
      formatted = this.timeAgoPipe.transform(value);
    } else if (valueFormattingConfig.dateFormat !== 'none') {
      formatted = this.dataFormatPipe.transform(
        value,
        valueFormattingConfig.dateFormat as string,
        valueFormattingConfig.convertTimezone
      );
    }

    return formatted ?? 'Invalid Date';
  }

  private formatJson(value: any, valueFormattingConfig: ValueFormattingJsonConfig<string>): string {
    let formatted = value;
    if (valueFormattingConfig.showInPopover) {
      formatted = this.truncatePipe.transform(value, 50);
    }

    return formatted ?? 'Invalid JSON';
  }
}
