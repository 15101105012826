<ng-container *ngIf="dataType === 'STRING' || dataType === 'CONDITION'">
  <rb-form-input
    [label]="label"
    [ngModel]="value"
    (ngModelChange)="updateValue($event)"
    [required]="required"
    [valueValidator]="validationRegExp"
    [dataType]="dataType"
    [readonly]="readonly"
  >
    <ng-template rbFormValidationMessage="required">
      {{ 'queryTemplate.required' | translate }}
    </ng-template>
    <ng-template rbFormValidationMessage="invalidCustomValue">
      {{ 'queryTemplate.valueNotMatchPattern' | translate: { pattern: validationRegExp } }}
    </ng-template>
  </rb-form-input>
</ng-container>
<ng-container *ngIf="dataType === 'INT'">
  <rb-form-input
    [label]="label"
    [ngModel]="value"
    (ngModelChange)="updateValue($event)"
    [required]="required"
    [valueValidator]="validationRegExp"
    [dataType]="dataType"
  >
    <ng-template rbFormValidationMessage="required">
      {{ 'queryTemplate.required' | translate }}
    </ng-template>
    <ng-template rbFormValidationMessage="invalidCustomValue">
      {{ 'queryTemplate.valueNotMatchPattern' | translate: { pattern: validationRegExp } }}
    </ng-template>
    <ng-template rbFormValidationMessage="invalidValue">
      {{ 'queryTemplate.invalidInteger' | translate }}
    </ng-template>
  </rb-form-input>
</ng-container>
<ng-container *ngIf="dataType === 'FLOAT'">
  <rb-form-input
    [label]="label"
    [ngModel]="value"
    (ngModelChange)="updateValue($event)"
    [required]="required"
    [valueValidator]="validationRegExp"
    [dataType]="dataType"
  >
    <ng-template rbFormValidationMessage="required">
      {{ 'queryTemplate.required' | translate }}
    </ng-template>
    <ng-template rbFormValidationMessage="invalidCustomValue">
      {{ 'queryTemplate.valueNotMatchPattern' | translate: { pattern: validationRegExp } }}
    </ng-template>
    <ng-template rbFormValidationMessage="invalidValue">
      {{ 'queryTemplate.invalidDecimal' | translate }}
    </ng-template>
  </rb-form-input>
</ng-container>
<ng-container *ngIf="dataType === 'BOOLEAN'">
  <rb-form-checkbox
    class="indented"
    [label]="label"
    [ngModel]="value"
    (ngModelChange)="updateValue($event)"
  ></rb-form-checkbox>
</ng-container>

<ng-container *ngIf="dataType === 'TIMESTAMP_RANGE'">
  <date-time-range-popover
    [label]="label"
    [ngModel]="defaultValue"
    (ngModelChange)="updateValue($event)"
    [allowRelative]="true"
  ></date-time-range-popover>
</ng-container>

<ng-container *ngIf="dataType === 'TIMESTAMP'">
  <rb-form-date-input
    [name]="'defaultvalue'"
    [ngModel]="value"
    (ngModelChange)="updateValue($event)"
    [options]="{ enableTime: true }"
    class="white"
    [readonly]="'true'"
    [label]="label"
  ></rb-form-date-input>
</ng-container>

<ng-container *ngIf="dataType === 'DEVICE'">
  <device-search
    [label]="label"
    [ngModel]="value"
    [required]="required"
    (ngModelChange)="updateValue($event)"
    [valueValidator]="validationRegExp"
  >
    <ng-template rbFormValidationMessage="required">
      {{ 'queryTemplate.required' | translate }}
    </ng-template>
    <ng-template rbFormValidationMessage="invalidCustomValue">
      {{ 'queryTemplate.valueNotMatchPattern' | translate: { pattern: validationRegExp } }}
    </ng-template>
  </device-search>
</ng-container>
