import {
  AppSideNavigationMenuItem,
  AppSideNavigationSubMenuItem
} from '@inst-iot/bosch-angular-ui-components';
import {
  CustomMenuItemApiModel,
  MenuItemType
} from '../../../project-admin/custom-menu/models/custom-menu-item.model';
import { Constants } from '../../../../constants';
import { TranslateService } from '@ngx-translate/core';
import { ProjectUrlPipe } from '../../../shared-projects/pipes/project-url.pipe';

export interface NavigationItem extends AppSideNavigationMenuItem {
  roles?: string[];
  systemRoles?: string[];
  options?: string[];
  subItems?: NavigationSubItem[];
}

export interface NavigationSubItem extends AppSideNavigationSubMenuItem {
  roles?: string[];
  systemRoles?: string[];
  options?: string[];
  subItems?: NavigationTabItem[];
}

export interface NavigationTabItem extends AppSideNavigationSubMenuItem {
  roles?: string[];
  systemRoles?: string[];
  options?: string[];
}

const routing = Constants.routing;
const routesWithoutRouterActiveExactMatch = [
  routing.exploreExplorer,
  routing.servicesDecoder,
  routing.servicesNotifications,
  routing.deviceType,
  routing.devicesAll,
  routing.processingPipelines
];

export function createNavigation(
  apiMenu: CustomMenuItemApiModel[],
  translateService: TranslateService,
  projectUrlPipe: ProjectUrlPipe
): NavigationItem[] {
  const navigation = [];

  apiMenu
    .filter((i) => !i.hidden)
    .forEach((item) => {
      const nav = createNavigationItem(item, translateService, projectUrlPipe);

      (item.subItems || [])
        .filter((i) => !i.hidden)
        .forEach((subItem) => {
          const subNavItem = createNavigationItem(subItem, translateService, projectUrlPipe);

          (subItem.subItems || [])
            .filter((i) => !i.hidden)
            .forEach((tabItem) => {
              const tab = createNavigationItem(tabItem, translateService, projectUrlPipe);
              subNavItem.subItems.push(tab);
            });

          nav.subItems.push(subNavItem);
        });

      navigation.push(nav);
    });

  return navigation;
}

function createNavigationItem(
  apiItem: CustomMenuItemApiModel,
  translateService: TranslateService,
  projectUrlPipe: ProjectUrlPipe
): NavigationItem | NavigationSubItem {
  const item: NavigationItem | NavigationSubItem = {
    id: apiItem.name,
    title: apiItem.label[translateService.currentLang] ?? apiItem.label['en'],
    roles: apiItem.roles,
    subItems: []
  };

  if (apiItem.icon) {
    (item as NavigationItem).icon = `rb-ic ${apiItem.icon}`;
  }

  if (apiItem.type === MenuItemType.LINK) {
    item.url = apiItem.url;
    item.target = '_blank';
  } else if (
    apiItem.type === MenuItemType.DASHBOARD ||
    apiItem.type === MenuItemType.IFRAME ||
    apiItem.type === MenuItemType.INTERNAL
  ) {
    switch (apiItem.type) {
      case MenuItemType.DASHBOARD:
        if (apiItem.url === 'home') {
          item.routerLink = projectUrlPipe.transform('/home');
        } else {
          item.routerLink = projectUrlPipe.transform(
            `${Constants.routing.views}/dashboards/${apiItem.url}`
          );
        }

        break;
      case MenuItemType.IFRAME:
        item.routerLink = projectUrlPipe.transform(`${Constants.routing.views}/${apiItem.name}`);
        break;
      default:
        item.routerLink = projectUrlPipe.transform(apiItem.url);
        break;
    }
    item.routerActiveExactMatch = getRouterActiveExactMatch(apiItem.url, apiItem.type);
  }

  return item;
}

function getRouterActiveExactMatch(url: string, type: MenuItemType): boolean {
  if (type === MenuItemType.DASHBOARD) {
    return false;
  }

  return !Object.values(routesWithoutRouterActiveExactMatch).includes(url);
}
