import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { LoadingEntity } from '@inst-iot/bosch-angular-ui-components';
import { isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../../constants';
import { NotificationBanner, ProjectConfig } from '../../../shared-projects/models/project.model';
import { ProjectsService } from '../../../shared-projects/services/projects.service';

@Component({
  selector: 'notification-banner',
  templateUrl: './notification-display.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationDisplayComponent implements OnInit, OnDestroy {
  isFreeplanProlongated = false;

  isFreeplanExpired = false;

  prolongationLoader = new LoadingEntity<ProjectConfig>();

  hasNotificationBannerEnabled = false;

  notification: NotificationBanner;

  @ViewChild('freeplanNotification', { static: true })
  freeplanNotification: TemplateRef<any>;

  @ViewChild('projectNotification', { static: true })
  projectNotification: TemplateRef<any>;

  readonly roles = Constants.roles;

  private readonly notificationStorageKey = () =>
    `${this.projectsService.projectName}_${Constants.notificationBanner.storageKey}`;

  private destroy = new Subject<null>();

  constructor(public projectsService: ProjectsService, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.checkNotificationBannerState();
    this.isFreeplanExpired = !!this.projectsService.projectConfig?.prolongationNeededUntil;
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy.next(null);
  }

  prolongateFreeplan(): void {
    this.prolongationLoader
      .run(this.projectsService.prolongateFreeplan())
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
        this.isFreeplanProlongated = true;
      });
  }

  checkNotificationBannerState(): void {
    this.projectsService.notificationBanner
      .pipe(takeUntil(this.destroy))
      .subscribe((notification: NotificationBanner) => {
        this.notification = notification;

        if (notification) {
          this.convertNotificationType(notification);
          this.hasNotificationBannerEnabled =
            !isEmpty(notification?.message) &&
            notification.isBannerEnabled &&
            !this.isBannerDismissed();
        }

        this.cd.detectChanges();
      });
  }

  convertNotificationType(notificationBanner: NotificationBanner): 'info' | 'warning' {
    if (notificationBanner.type === 'neutral') {
      return 'info';
    } else {
      return 'warning';
    }
  }

  isBannerDismissed(): boolean {
    return !!sessionStorage.getItem(this.notificationStorageKey());
  }

  dismissNotificationBanner(): void {
    sessionStorage.setItem(this.notificationStorageKey(), 'true');
    this.cd.detectChanges();
  }
}
