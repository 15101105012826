import { Component } from '@angular/core';
import { Constants } from '../../../constants';

@Component({
  selector: 'project-no-access',
  templateUrl: './project-no-access.component.html'
})
export class ProjectNoAccessComponent {
  routing = Constants.routing;
}
