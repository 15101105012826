import { AfterViewInit, Directive, ElementRef, inject, Input, OnDestroy } from '@angular/core';
import { fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { LoadingEntity } from '@inst-iot/bosch-angular-ui-components';

@Directive({
  selector: '[searchItems]'
})
export class SearchItemsDirective implements AfterViewInit, OnDestroy {
  @Input('searchItems') searchFn: (value: string) => Observable<any>;
  @Input() loader: LoadingEntity<any>;

  private host = inject(ElementRef);
  private destroy = new Subject<void>();

  ngAfterViewInit() {
    fromEvent(this.host.nativeElement, 'input')
      .pipe(
        debounceTime(500),
        map((event: InputEvent) => (event.target as HTMLInputElement).value),
        distinctUntilChanged(),
        switchMap((value) =>
          this.loader ? this.loader.run(this.searchFn(value)) : this.searchFn(value)
        ),
        takeUntil(this.destroy)
      )
      .subscribe();
  }

  ngOnDestroy() {
    if (this.loader) {
      this.loader.complete();
    }

    this.destroy.next();
    this.destroy.complete();
  }
}
