<rb-form-input
  #valueControl
  [label]="label"
  [icon]="'rb-ic-calendar'"
  [ngModel]="displayValue"
  [disabled]="disabled"
  [rbDropdown]="dropdown"
  [autoClose]="false"
></rb-form-input>

<ng-template #dropdown let-close="close">
  <div>
    <rb-date-range-picker
      #rangePicker
      [options]="options"
      [(ngModel)]="tempRange"
      [disabled]="disabled"
      (ngModelChange)="tempRangeChanged($event)"
      [allowRelative]="allowRelative"
      [absoluteLabel]="'timeRangePicker.absolute' | translate"
      [relativeLabel]="'timeRangePicker.relative' | translate"
      [presetsLabel]="'timeRangePicker.presets' | translate"
      [presets]="presets"
      [units]="timeInputOptions.units"
      [startLabel]="'relTimeInput.start' | translate"
      [endLabel]="'relTimeInput.end' | translate"
      [enableTimeSlider]="enableTimeSlider && options.enableTime"
    ></rb-date-range-picker>
    <div class="d-flex justify-content-between align-items-center my-3">
      <span class="ml-3">
        <ng-container *ngIf="rangePicker && rangePicker.tab === 'absolute'" class="mt-3">
          {{ 'dateTimePicker.duration' | translate }}: {{ duration | duration: 'dhm' }}
        </ng-container>
      </span>
      <span class="mr-3">
        <button type="button" class="rb-btn mr-3" (click)="close()">
          {{ 'dateTimePicker.cancel' | translate }}
        </button>
        <button
          type="button"
          class="rb-btn rb-primary"
          data-cy="selectTimeSeriesTimeRange"
          [disabled]="disableApplyButton"
          (click)="close(); updateModel(); $event.stopPropagation()"
        >
          {{ 'dateTimePicker.apply' | translate }}
        </button>
      </span>
    </div>
  </div>
</ng-template>
