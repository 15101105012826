import { ObjectAclReq, QueryTemplateParameters } from '../../shared/api-model';

export function createTemplateForTimeseries(
  collection: string,
  name: string,
  timeField: string,
  objectAcl: ObjectAclReq
): QueryTemplateParameters {
  return {
    template: `[
  {
    "$match": {
      "$and": [
        {"${timeField}": { "$gt": { "$date": "$\{timerange.from}" }, "$lt": { "$date": "$\{timerange.to}" } }}
        <#if meta_match_clause??>, $\{meta_match_clause} </#if>
      ]
    }
  }
  <#if limit??>
  , { "$limit": $\{limit} }
  </#if>
]`,
    queryType: 'AGGREGATE',
    description:
      'This template was auto generated by the service as base for the timeseries collection.',
    name: name,
    objectAcl: objectAcl,
    collection: collection,
    queuingTimeout: 60,
    parameters: [
      {
        name: 'timerange',
        dataType: 'TIMESTAMP',
        parameterType: 'RANGE',
        optional: false,
        defaultValue: null
      },
      {
        label: 'meta match clause',
        name: 'meta_match_clause',
        dataType: 'STRING',
        parameterType: 'SCALAR',
        optional: true,
        defaultValue: null
      },
      {
        label: 'amount',
        name: 'limit',
        dataType: 'INT',
        parameterType: 'SCALAR',
        optional: true,
        defaultValue: 10000
      }
    ]
  };
}
