import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProjectsService } from '../../shared-projects/services/projects.service';
import { DeviceHistory, DeviceHistoryDto } from '../models/device-history';
import { DeviceHistoryComment } from '../models/device-history-comment';
import { QueryService } from '../../shared-query/services/query.service';
import { map, skipWhile } from 'rxjs/operators';
import { QueryConfig } from '../../shared-query/models/query-config';

@Injectable({ providedIn: 'root' })
export class DeviceHistoryRestService {
  private readonly projectServiceUrl = '/project-management-service/v1/';
  private readonly endpoint = '/thing-history';
  private readonly collection = '_thing_history';

  get projectName(): string {
    return this.projectsService.projectConfigEvents.getValue().config.name;
  }

  /**
   * Because Angular services are singletons across projects,
   * we use a getter here so we get to know if the user switches the project
   */
  get finalUrl(): string {
    return this.projectServiceUrl + this.projectName + this.endpoint;
  }

  constructor(
    private http: HttpClient,
    private projectsService: ProjectsService,
    private queryService: QueryService
  ) {}

  /**
   * Queries the MongoQueryService for History Documents
   * @param queryConfig - The Query Configuration which can contain a filter query
   */
  getDeviceHistoryDocuments(queryConfig: QueryConfig): Observable<DeviceHistory[]> {
    return this.queryService.runQueryConfig(queryConfig).pipe(
      skipWhile((status) => status.result === undefined),
      map((response) => response.result?.map((entry) => new DeviceHistory(entry)))
    );
  }

  /**
   * Gets the position of an entry, sorted by the validityBegin date and filtered by the thingId
   * @param history
   */
  getDeviceHistoryEntryPosition(history: DeviceHistory): Observable<number> {
    const count = {
      collection: this.projectName + this.collection,
      query: {
        thingId: history.thingId,
        validityBegin: { $gte: { $date: new Date(history.validityBegin) } }
      }
    };

    return this.queryService.executeCountQuery(count).pipe(map((p) => p - 1));
  }

  getDeviceChildren(parentId: string, at: string): Observable<DeviceHistoryDto[]> {
    const pipeline =
      /* Historical child search

     Requires three steps:
     1. search for all entries that ever have refered to the parent
     2. filter out all final DELETED entries
     3. check if the entries above are really² the last ones
    */
      [
        /* 1. Step: Search for all¹ entries that refer to the parent

      ¹ all means, that at this stage, it's only possible to search entries, that contain the parentId
        and are before the date in question. In words: All devices, that have been connected once.

        Implementors note: Mongo combines the $match + $sort + $project into very efficient index scan,
                           which is the reason to do this, and fetch the whole history entry later, after
                           the grouping
    */
        {
          $match: {
            'snapshots.0.features.deviceLinks.properties.parentId': parentId,
            validityBegin: { $lte: { $date: at } }
          }
        },
        {
          $sort: { validityBegin: -1 }
        },
        {
          $project: {
            _id: 0,
            validityBegin: 1,
            thingId: 1
          }
        },
        {
          $group: {
            _id: '$thingId',
            validitySince: { $first: '$validityBegin' }
          }
        },
        {
          $lookup: {
            from: this.projectName + this.collection,
            let: { thingId: '$_id', validityBegin: '$validitySince' },
            pipeline: [
              {
                $match: {
                  $expr: {
                    $and: [
                      { $eq: ['$validityBegin', '$$validityBegin'] },
                      { $eq: ['$thingId', '$$thingId'] }
                    ]
                  }
                }
              }
            ],
            as: 'fullEntry'
          }
        },
        {
          $replaceRoot: { newRoot: { $arrayElemAt: ['$fullEntry', 0] } }
        },
        /* 2. Step: filter out all final DELETED entries

       This requires two steps:
       2a. extract the thingId of the change
       2b. filter all entries, that say the DEVICE was deleted
    */
        {
          $addFields: {
            change: {
              $map: {
                input: '$change',
                in: {
                  $let: {
                    vars: { topicSplitted: { $split: ['$$this.topic', '/'] } },
                    in: {
                      thingId: {
                        $concat: [
                          { $arrayElemAt: ['$$topicSplitted', 0] },
                          ':',
                          { $arrayElemAt: ['$$topicSplitted', 1] }
                        ]
                      },
                      type: '$$this.actionType'
                    }
                  }
                }
              }
            }
          }
        },
        {
          $match: {
            $expr: {
              $eq: [
                0,
                {
                  $size: {
                    $filter: {
                      input: { $ifNull: ['$change', []] },
                      cond: {
                        $and: [
                          { $eq: ['$$this.type', 'THING_DELETED'] },
                          { $eq: ['$$this.thingId', '$_id'] }
                        ]
                      }
                    }
                  }
                }
              ]
            }
          }
        },
        /* 3. Step: Check if the entries above are really² the last ones

       ² the first $match stage returned all ever connected child entries.
         This step now has to check, if the parent hasn't change since.

       This requires two steps:
       2a. search for each thingId, for other entries in between
       2b. remove all entries, where such any³ are found.

      ³ any entry in this period that is found actually leads to exclusion
        of that potential child, because that such entry apprears here,
        but not in the original first step (match + group), means, it has
        another or no parentId.
    */
        {
          $lookup: {
            from: this.projectName + this.collection,
            let: { validityBegin: '$validityBegin', thingId: '$thingId' },
            pipeline: [
              {
                $match: {
                  $and: [
                    { validityBegin: { $lte: { $date: at } } },
                    {
                      $expr: {
                        $and: [
                          { $eq: ['$thingId', '$$thingId'] },
                          { $gt: ['$validityBegin', '$$validityBegin'] }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                $limit: 1
              }
            ],
            as: 'intermediate'
          }
        },
        {
          $match: {
            intermediate: { $size: 0 }
          }
        }
      ];
    return this.queryService
      .runQueryWithResult(
        pipeline,
        this.projectName + this.collection,
        'device-history load-children'
      )
      .pipe(map((e) => e as DeviceHistoryDto[]));
  }

  /**
   * Creates or update a given "comment" into the DeviceHistory document specified by the "historyId".
   * Comment gets created if not existing yet.
   *
   * @param historyId - the unique deviceHistory documentId
   * @param comment - the comment that you want to update
   */
  updateComment(
    historyId: string,
    comment: DeviceHistoryComment
  ): Observable<DeviceHistoryComment> {
    return this.http.put<DeviceHistoryComment>(
      `${this.finalUrl}/entries/${historyId}/comment?comment=${encodeURIComponent(comment.text)}`,
      {}
    );
  }

  /**
   * Creates or updates the "labels" in the DeviceHistory document specified by the "historyId"
   * Labels get created if not existing yet.
   *
   * @param historyId - the unique deviceHistory documentId
   * @param labels - the labels that you want to update
   */
  updateLabels(historyId: string, labels: string[]): Observable<string[]> {
    return this.http.put<string[]>(`${this.finalUrl}/entries/${historyId}/labels`, labels);
  }

  deleteLabel(historyId: string, label: any): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(
      `${this.finalUrl}/entries/${historyId}/labels/${label}`
    );
  }
}
