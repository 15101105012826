import {
  LogicalOperator,
  LogicalOperatorType,
  SyntaxNode
} from '../../query-condition-input/models/syntax-node.model';
import {
  DisableCondition,
  DisableConditionPathTypes,
  DisableConditionPayload,
  DisableConditionType
} from '../models/disable-condition.model';
import { ConditionChip } from '../../query-condition-input/models/condition-chip.model';

export function assembleDisableConditions(node: SyntaxNode): DisableCondition[] {
  return node.childNodes.map(buildDisableConditionObject);
}

function buildDisableConditionObject(node: SyntaxNode, index: number): DisableCondition {
  let nodeBody;

  if (index % 2 === 0) {
    nodeBody = node.body as ConditionChip;
    return createDefaultDisableCondition(nodeBody);
  }

  nodeBody = node.body as LogicalOperator;
  return createLogicalOperatorDisableCondition(nodeBody);
}

function createDefaultDisableCondition(data: ConditionChip): DisableCondition {
  const payload: DisableConditionPayload = {
    propertyPath: data.input,
    operator: data.operatorID,
    pathType: data.type as DisableConditionPathTypes,
    value: data.value
  };

  return { type: DisableConditionType.Condition, payload };
}

function createLogicalOperatorDisableCondition(data: LogicalOperator): DisableCondition {
  return { type: DisableConditionType.LogicalOperator, payload: data.type as LogicalOperatorType };
}
