<rb-alert
  [alertTitle]="alertInfo?.title | translate"
  [type]="alertInfo?.type"
  [okBtnLabel]="alertInfo?.okBtnLabel | translate"
  [cancelBtnLabel]="alertInfo?.cancelBtnLabel | translate"
>
  <p>{{ alertInfo?.description | translate }}</p>
  <ng-container *ngIf="alertInfo?.details">
    <a href="#" class="rb-details-toggle" rbDetailsToggle #toggleState="rbDetailsToggle" translate>
      alert.showDetails
    </a>
    <p [hidden]="!toggleState.open" class="small">{{ alertInfo.details }}</p>
  </ng-container>
</rb-alert>
