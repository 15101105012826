import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { translate } from '../../../../shared/translation-util';
import {
  FieldDefinition,
  SupportedFieldDefinitionTypes
} from '../../../dynamic-filter/dynamic-filter.model';
import { ConditionChip } from '../../../query-condition-input/models/condition-chip.model';
import { Operator } from '../../../query-condition-input/models/operator.model';
import { InputObject } from '../../../query-condition-input/models/util-objects.model';
import { SimpleSearchInputService } from './simple-search-input.service';

const showOperatorFields = ['metaData', 'size', 'executions'];
const defaultFilterDataType = 'string';
const supportedMetadataFieldDataTypes = ['date', 'string', 'number'];

@Injectable()
export class SimpleSearchInputFilterService extends SimpleSearchInputService<FieldDefinition> {
  unfilteredFilters: FieldDefinition[];

  constructor(private translateService: TranslateService) {
    super();
    this.searchType = 'filter';
    this.dataTypeInput = defaultFilterDataType;
    this.operatorInput = Operator.byTechnicalId('eq');
    this.defaultDataType = defaultFilterDataType;
    this.searchInputLabel = translate('simpleSearch.filterLabel');
  }

  setInputs(values: FieldDefinition[]) {
    this.unfilteredFilters = values;
    this.setAvailableFilters();
  }

  setChips(chipsList: ConditionChip[]) {
    this._chips = chipsList;
    this.setAvailableFilters();
  }

  setShowDuplicateButton() {
    this.showDuplicateButton = this.inputs.some((input) => input.name === this.activeChip?.input);
  }

  search(text: string): Observable<string[]> {
    // Regex for matching case insensitive entries
    const rgx = new RegExp(`^.*${text}.*`, 'i');

    return of(
      this.inputs
        .map((filter) => this.findFinalFilterLabel(filter))
        .filter((label) => (label ? label.match(rgx) : false))
    ).pipe(
      delay(50),
      map((entries: string[]) => {
        this.displayLabels = entries;
        return entries;
      })
    );
  }

  detectDataType(field: string) {
    if (this.activeChip) {
      // Deactivate Detection while editing a Chip
      return;
    }

    this.resetDataDetection();
    this.inputValue = '';

    const foundFilter: FieldDefinition = this.inputs.find(
      (filter) => this.findFinalFilterLabel(filter) === field
    );

    if (foundFilter) {
      this.currentInput = foundFilter;
      if (foundFilter.type === 'static') {
        this.operatorInput = Operator.byTechnicalId('exists');
      }
      this.setValueInputForType(foundFilter);
      this.showFieldName = foundFilter.type === 'nested';
      this.showOperator = showOperatorFields.includes(foundFilter.name);
      this.dataTypeInput = SupportedFieldDefinitionTypes.includes(foundFilter.type)
        ? foundFilter.type
        : defaultFilterDataType;
      this.input = foundFilter.name;
      this.inputLabel = {
        displayLabel: this.translateService.instant(foundFilter.i18nLabel),
        i18nLabel: foundFilter.i18nLabel
      };
    } else {
      this.dataTypeInput = defaultFilterDataType;
    }
  }

  findFinalFilterLabel(filter: FieldDefinition): string {
    return filter.i18nLabel ? this.translateService.instant(filter.i18nLabel) : filter.label;
  }

  getInputsAsObject(): InputObject {
    return {
      type: this.dataTypeInput,
      value: this.validateValue(),
      fieldName: this.fieldName,
      searchType: this.searchType,
      input: this.input,
      i18nLabel: this.inputLabel.i18nLabel,
      operatorID: this.operatorInput.technicalID,
      customValuePath: this.customValuePath,
      valueLabel: this.valueLabel
    };
  }

  private setValueInputForType(foundFilter: FieldDefinition) {
    if (foundFilter.type === 'range') {
      this.inputValue = { from: '1', to: '1' };
    } else if (foundFilter.type === 'datasetRequiredId' || foundFilter.type === 'dataset') {
      this.inputValue = { collection: foundFilter?.selectOptions[0], id: '' };
    } else if (foundFilter.type === 'datetimerange') {
      this.inputValue = null;
    } else if (foundFilter.type !== 'text') {
      this.inputValue = '';
    }
  }

  loadInputsFromChip(chip: ConditionChip) {
    super.loadInputsFromChip(chip);
    this.inputLabel = {
      displayLabel: this.activeChip.i18nLabel
        ? this.translateService.instant(this.activeChip.i18nLabel)
        : this.activeChip.input,
      i18nLabel: this.activeChip.i18nLabel
    };
    this.currentInput = this.unfilteredFilters.find(
      (filter: FieldDefinition) => filter.name === this.activeChip.input
    );
    this.showOperator = showOperatorFields.includes(this.activeChip.input);
  }

  // only metadata type can be selected, all the others are predefined
  setSupportedDataTypes(dataTypes = supportedMetadataFieldDataTypes) {
    this.supportedDataTypes = dataTypes;
  }

  private setDisplayLabels() {
    this.displayLabels = this.inputs.map((filter: FieldDefinition) =>
      this.findFinalFilterLabel(filter)
    );
  }

  private setAvailableFilters() {
    this.inputs = this.unfilteredFilters;
    this._chips.forEach((chip) => {
      const filter = this.unfilteredFilters.find((v) => v.name === chip.input);
      if (filter?.incompatible) {
        this.inputs = this.unfilteredFilters.filter((f) => !filter.incompatible.includes(f.name));
      }
      if (filter?.multipleValuesAllowed === false) {
        this.inputs = this.inputs.filter((f) => filter.name !== f.name);
      }
    });

    this.setDisplayLabels();
  }

  private resetDataDetection() {
    this.showFieldName = false;
    this.operatorInput = Operator.byTechnicalId('eq');
    this.showOperator = false;
    this.currentInput = null;
  }
}
