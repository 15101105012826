import { createComponent, EnvironmentInjector, inject, Injectable, Injector } from '@angular/core';
import { ModalService } from '@inst-iot/bosch-angular-ui-components';
import { ClipboardService } from './clipboard.service';
import { TranslateService } from '@ngx-translate/core';
import { FileLinkCopyDialogComponent } from '../file-link-copy-dialog/file-link-copy-dialog.component';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class UnsafeUrlDialogsService {
  private environmentInjector = inject(EnvironmentInjector);
  private injector = inject(Injector);
  private modalService = inject(ModalService);
  private clipboardService = inject(ClipboardService);

  constructor(private translateService: TranslateService) {}
  showFileLinkCopyDialog(fileUrl: string) {
    const componentRef = createComponent(FileLinkCopyDialogComponent, {
      environmentInjector: this.environmentInjector,
      elementInjector: this.injector
    });

    componentRef.instance.fileUrl = fileUrl;

    this.modalService
      .open(componentRef, { stacked: true, maxWidth: '700px' })
      .then((copyClicked) => {
        if (copyClicked) {
          this.clipboardService.copyToClipboard(fileUrl);
        }
      });
  }

  showErrorDialog() {
    const componentRef = createComponent(ErrorDialogComponent, {
      environmentInjector: this.environmentInjector,
      elementInjector: this.injector
    });
    componentRef.instance.alertTitle = this.translateService.instant(
      'validation.urlSchemeValidation.projectSecurityAlert'
    );
    componentRef.instance.buttonLabel = this.translateService.instant('confirmation.confirm');
    componentRef.instance.error = this.translateService.instant(
      'validation.urlSchemeValidation.disabledLinkError'
    );
    componentRef.instance.errorHeadline = this.translateService.instant(
      'validation.urlSchemeValidation.disabledLinkHeadline'
    );
    this.modalService.open(componentRef, { maxWidth: '500px' });
  }
}
