<h5 class="my-2" *ngIf="showTitle">
  {{ 'users.roleSelector.title' | translate }}
</h5>

<div class="user-role-selector">
  <role-search
    [ngModel]="roleSearchTerm"
    [label]="'users.roleSelector.label' | translate"
    [selectedRoles]="selectedRoles"
    (roleSelected)="selectRole($event)"
    dataCyInput="userSearchRole"
    dataCyRoleEntry="userRoleSelector-"
  ></role-search>
</div>

<div class="assigned-roles" *rbLoading="rolesLoader">
  <div *ngFor="let role of selectedRoles" class="role d-flex align-items-center py-2">
    <span class="flex-grow-1">
      {{ role.roleName | displayRoles | async }}
      <span
        class="rb-ic rb-ic-question-frame pl-2"
        *ngIf="role?.description"
        [rbTooltip]="role.description"
      ></span>
    </span>
    <button
      type="button"
      [disabled]="!role.assignable"
      (click)="removeRole(role)"
      class="rb-btn rb-link delete-trigger"
    >
      <span class="rb-ic rb-ic-delete"></span>
    </button>
  </div>
</div>
