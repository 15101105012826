import {
  AggregationParametersRequest,
  CountParametersRequest,
  DistinctParametersRequest,
  FindParametersRequest,
  QueryTemplateExecutionParameters
} from '../../shared/api-model';
import { QueryEndPointTypes } from '../services/query.service';

export type QueryRequestParameter =
  | FindParametersRequest
  | CountParametersRequest
  | DistinctParametersRequest
  | AggregationParametersRequest;

export class QueryConfig {
  project: string;

  /**
   * The MongoDB aggregation framework query array
   */
  query?: any;

  /**
   * In case a template should be used instead of query and collection
   */
  templateId?: string;

  /**
   * In conjunction with a templateId
   */
  templateParameters?: any;

  /**
   * The collection on which the aggregation should run
   */
  collection?: string;

  /**
   * The tag under which the query should be searchable later in the log
   */
  tag: string;

  /**
   * If target collection is set, the query will be executed on the targetCollection
   */
  targetCollection?: string;

  /**
   * Whether the pagination sets $limit and $skip
   */
  queryUpdatedByPagination?: boolean;

  /**
   * Whether the query should be explained
   */
  explain?: boolean;

  /**
   * Download the result as json or csv with parameters
   */
  download?: string;

  /**
   * Whether the data should be plotted as chart.
   * Can't be used with download.
   */
  plot?: boolean;

  type?: QueryEndPointTypes;

  field?: string;

  limit?: number;

  projection?: any;

  skip?: number;

  sort?: any;

  hint?: any;

  cacheTime?: number;

  /**
   * Queuing Timeout to wait for a ticket, in case the request could not get one right away. Default 2sec in case of Synch or 3600sec in case of Async
   */
  queuingTimeout?: number;

  historyId?: string;

  constructor(config: Partial<QueryConfig>) {
    Object.assign(this, config);
  }

  getQueryTemplateParameter(): QueryTemplateExecutionParameters {
    return {
      parameterValues: this.templateParameters,
      targetCollection: this.targetCollection,
      tag: this.tag
    };
  }

  getQueryParameters(): QueryRequestParameter {
    const type2Parameter: Record<QueryEndPointTypes, () => QueryRequestParameter> = {
      [QueryEndPointTypes.Find]: () => this.getFindParametersRequestData(),
      [QueryEndPointTypes.Count]: () => this.getCountParametersRequestData(),
      [QueryEndPointTypes.Distinct]: () => this.getDistinctParametersRequestData(),
      [QueryEndPointTypes.Aggregation]: () => this.getAggregationParametersRequestData()
    };
    return type2Parameter[this.type]() ?? null;
  }

  getFindParametersRequestData(): FindParametersRequest {
    return {
      query: this.query,
      collection: this.collection,
      tag: this.tag,
      limit: this.limit,
      projection: this.projection,
      skip: this.skip,
      sort: this.sort,
      hint: this.hint,
      queuingTimeout: this.queuingTimeout
    } as FindParametersRequest;
  }

  getCountParametersRequestData(): CountParametersRequest {
    return {
      query: this.query,
      collection: this.collection,
      tag: this.tag,
      hint: this.hint,
      queuingTimeout: this.queuingTimeout
    } as CountParametersRequest;
  }

  getDistinctParametersRequestData(): DistinctParametersRequest {
    return {
      query: this.query,
      collection: this.collection,
      tag: this.tag,
      field: this.field,
      hint: this.hint,
      queuingTimeout: this.queuingTimeout
    } as DistinctParametersRequest;
  }

  getAggregationParametersRequestData(): AggregationParametersRequest {
    return {
      query: this.query,
      collection: this.collection,
      tag: this.tag,
      sample: false,
      hint: this.hint,
      queuingTimeout: this.queuingTimeout
    } as AggregationParametersRequest;
  }
}
