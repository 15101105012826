import { Component, OnDestroy, OnInit } from '@angular/core';
import { MergeCandidate, MergeUserService } from '../../core/services/merge-user.service';
import { LoadingEntity } from '@inst-iot/bosch-angular-ui-components';
import { noop } from 'rxjs';
import { Constants } from '../../../constants';

@Component({
  selector: 'migrate-adfs',
  templateUrl: './migrate-adfs.component.html'
})
export class MigrateAdfsComponent implements OnInit, OnDestroy {
  mergeCandidate: MergeCandidate;
  candidateLoader = new LoadingEntity<any>();
  migrationFailed = false;
  routing = Constants.routing;

  constructor(private mergeUserService: MergeUserService) {}

  ngOnInit(): void {
    this.candidateLoader
      .run(this.mergeUserService.loadMergeCandidates())
      .subscribe((mergeCandidate) => (this.mergeCandidate = mergeCandidate));
  }

  ngOnDestroy() {
    this.candidateLoader.complete();
  }

  migrate() {
    this.migrationFailed = false;
    this.mergeUserService.migrateUser().subscribe({
      next: noop,
      error: () => {
        this.migrationFailed = true;
      }
    });
  }
}
