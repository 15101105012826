import { cloneDeep, isNil, uniq } from 'lodash-es';
import { KeyValue } from '../../../shared-modules/key-value-editor/key-value';
import {
  CheckBoxField,
  DateTimeRangeField2,
  DeviceField,
  FieldDefinition,
  FieldType,
  NumberRangeField,
  QueryConditionField,
  SingleDateTimeField,
  SingleSelectField,
  TextField
} from '../../../shared-modules/static-filter/static-filter.model';
import { TranslatedString } from '../../../shared-projects/models/project.model';
import { DeviceTypeDefinition } from '../../../shared/api-model';
import { FieldSelectOption } from '../../../shared/filter-model/filter.model';
import { translate } from '../../../shared/translation-util';
import { DashboardParameter } from '../../models/dashboard-parameter';
import { DeviceParameterValue } from '../../models/device-dashboard-parameter';

export interface FilterParameterConfig {
  /**
   * Name of the parameter
   */
  name: string;

  /**
   * Label that is displayed for the user
   */
  label?: TranslatedString;

  /**
   * Type of the field
   */
  type: FieldType;

  /**
   * Whether a value is required, default: false
   */
  required?: boolean;

  /**
   * Whether setting multiple values is allowed, default: false
   */
  multipleValues?: boolean;

  /**
   * Whether it should be visible on the UI. default: true
   */
  visible?: boolean;

  /**
   * Whether it can be used across different filter widgets and dashboards. default: false
   */
  global?: boolean;

  defaultValue: any;

  /**
   * custom properties depending on the type
   */
  [property: string]: any;
}

export interface DeviceFilterParameterConfig extends FilterParameterConfig {
  propertyPath: string;
}

export interface GlobalFilterParameters {
  [id: string]: FilterParameterConfig;
}

export interface FilterWidgetConfig {
  storageKey: string;

  /**
   * Configured general parameters
   */
  parameters: FilterParameterConfig[];

  /**
   * selected device type for device filters section
   */
  deviceType: string;

  /**
   * Configured device parameters
   */
  deviceParameters: DeviceFilterParameterConfig[];

  globalParameters: string[];
}

export class FilterValue {
  field: FieldDefinition;
  dashboardParam: DashboardParameter;
  value: any;
  enabled: boolean;
  errors: Map<string, boolean> = new Map();

  constructor(filterValue: Partial<FilterValue> = {}) {
    Object.assign(this, filterValue);
    return this;
  }

  get resolvedValue(): DeviceParameterValue | DeviceParameterValue[] {
    return this.dashboardParam?.resolvedValue;
  }

  get fieldName(): string {
    return this.field.name;
  }

  get valueAsArray(): any[] {
    return this.getValueAsArray(this.value);
  }

  get isMultipleValueField(): boolean {
    return this.field.multipleValues;
  }

  hasErrors(): boolean {
    return [...this.errors.values()].some((error: boolean) => error);
  }

  hasErrorForIndex(index: number): boolean {
    return this.errors.has(`${this.fieldName}_${index}`);
  }

  setErrorForIndex(index: number): FilterValue {
    this.errors.set(`${this.fieldName}_${index}`, true);
    return this;
  }

  unsetErrorForIndex(index: number): FilterValue {
    if (this.errors.has(`${this.fieldName}_${index}`)) {
      this.errors.delete(`${this.fieldName}_${index}`);
    }
    return this;
  }

  resetErrors(): FilterValue {
    if (!this.field.required) {
      this.errors = new Map();
    }
    return this;
  }

  isLastValue(): boolean {
    return this.isMultipleValueField ? this.value?.length === 1 : true;
  }

  isMultiSelectionType(): boolean {
    return this.field.type === 'device' || this.field.type === 'selection';
  }

  getDefaultValue(): any {
    if (this.isMultipleValueField) {
      return [this.field?.defaultValue];
    } else {
      return this.field.defaultValue;
    }
  }

  addNewValue(): FilterValue {
    if (this.isMultipleValueField) {
      this.value =
        this.field.type === 'datetimerange2'
          ? [...this.valueAsArray, ...this.getDefaultValue()]
          : uniq([...this.valueAsArray, ...this.getDefaultValue()]);
    } else {
      this.value = this.field.global && this.value ? this.value : this.getDefaultValue();
    }

    return this;
  }

  setValue(value: any | any[], index?: number): FilterValue {
    if (this.isMultipleValueField && Number.isFinite(index) && index >= 0) {
      if (!Array.isArray(this.value)) {
        this.value = [];
      }
      this.value[index] = value;
    } else {
      this.value = value;
    }
    return this;
  }

  resetValue(): FilterValue {
    this.enabled = !!this.field.required;
    this.value = this.isMultipleValueField
      ? this.getValueAsArray(this.field.defaultValue)
      : this.field.defaultValue;

    this.resetErrors();
    return this;
  }

  removeValue(valueIndex?: number): FilterValue {
    if (this.isLastValue() && this.field.required) {
      this.setValue(this.getDefaultValue());
    } else if (this.isMultipleValueField) {
      this.value.splice(valueIndex, 1);
    } else {
      this.value = undefined;
    }
    // the last value got removed, we disable the filter to not show up anymore
    if (this.valueAsArray.length === 0 && !this.field.required) {
      this.setDisabled();
    }
    this.unsetErrorForIndex(valueIndex);
    return this;
  }

  getValueAsArray(value: any): any[] {
    if (isNil(value)) {
      return [];
    }
    return Array.isArray(value) ? value : [value];
  }

  getValueAsPlain(value: any | any[], index?: number): any {
    return this.isMultipleValueField ? value[index] : value;
  }

  setDisabled(): FilterValue {
    this.enabled = false;
    return this;
  }

  setEnabled(): FilterValue {
    this.enabled = true;
    return this;
  }

  cloneValue(): FilterValue {
    return new FilterValue({ ...this, value: cloneDeep(this.value) });
  }

  getAllSelectOptionValues(): string[] {
    return this.field.selectOptions.map((option) => option.value);
  }

  getPickedSelectOptions(): FieldSelectOption[] {
    return this.field.selectOptions?.filter((option: FieldSelectOption) =>
      this.valueAsArray.find((value: string) => value === option.value)
    );
  }

  getUnpickedSelectOptions(): FieldSelectOption[] {
    return this.field.selectOptions?.filter(
      (option: FieldSelectOption) =>
        !this.valueAsArray.find((value: string) => value === option.value)
    );
  }
}

export interface ParameterType {
  type: FieldType;
  label: string;
  description: string;
  defaultProperties: Record<string, any>;
  visible: boolean;
}

export interface FilterData {
  filterValue: FilterValue;
  filterValueIndex: number;
}

export const parameterTypes: ParameterType[] = [
  {
    type: 'text',
    label: translate('filterWidget.types.text'),
    description: translate('filterWidget.typesDescription.text'),
    defaultProperties: {},
    visible: true
  },
  {
    type: 'number',
    label: translate('filterWidget.types.number'),
    description: translate('filterWidget.typesDescription.number'),
    defaultProperties: {},
    visible: true
  },
  {
    type: 'datetimerange2',
    label: translate('filterWidget.types.dateTimeRange'),
    description: translate('filterWidget.typesDescription.dateTimeRange'),
    defaultProperties: {
      allowRelative: true,
      enableTime: true,
      defaultValue: {
        from: '-86400000',
        to: '-0'
      }
    },
    visible: true
  },
  {
    type: 'date',
    label: translate('filterWidget.types.date'),
    description: translate('filterWidget.typesDescription.date'),
    defaultProperties: {
      allowRelative: true,
      enableTime: false,
      defaultValue: {
        from: '-86400000',
        to: '-0'
      }
    },
    visible: false
  },
  {
    type: 'checkbox',
    label: translate('filterWidget.types.checkbox'),
    description: translate('filterWidget.typesDescription.checkbox'),
    defaultProperties: {
      defaultValue: false,
      required: false
    },
    visible: true
  },
  {
    type: 'selection',
    label: translate('filterWidget.types.selection'),
    description: translate('filterWidget.typesDescription.selection'),
    defaultProperties: {
      selectOptions: [],
      defaultValue: ''
    },
    visible: true
  },
  {
    type: 'device',
    label: translate('filterWidget.types.device'),
    description: translate('filterWidget.typesDescription.device'),
    defaultProperties: {
      deviceTypes: []
    },
    visible: true
  },
  {
    type: 'queryCondition',
    label: translate('filterWidget.types.queryCondition'),
    description: translate('filterWidget.typesDescription.queryCondition'),
    defaultProperties: {},
    visible: true
  }
];

export function filterParameterToFieldDefinition(
  param: FilterParameterConfig,
  deviceType?: DeviceTypeDefinition
): FieldDefinition {
  const typeToFieldDef = {
    text() {
      return new TextField(param.name);
    },
    number() {
      return new NumberRangeField(param.name);
    },
    device() {
      const field = new DeviceField(param.name);
      field.deviceTypes = param.deviceTypes;
      field.valuePath = param.valuePath;
      field.filter = param.filter;
      field.propertyPath = param.propertyPath;
      return field;
    },
    datetimerange2() {
      const field = new DateTimeRangeField2(param.name);
      field.allowRelative = param.allowRelative;
      field.options.enableTime = param.enableTime;
      return field;
    },
    date() {
      const field = new DateTimeRangeField2(param.name);
      field.allowRelative = param.allowRelative;
      field.options.enableTime = false;
      return field;
    },
    checkbox() {
      return new CheckBoxField(param.name);
    },
    selection() {
      const field = new SingleSelectField(param.name);
      field.selectOptions =
        getDeviceSelectOptions(param, deviceType) ||
        (param.selectOptions as KeyValue[]).map((kv) => ({ value: kv.value, label: kv.key }));
      return field;
    },
    singledatetime() {
      return new SingleDateTimeField(param.name);
    },
    queryCondition() {
      return new QueryConditionField(param.name);
    }
  };

  const field: FieldDefinition = typeToFieldDef[param.type]();
  field.translatedLabel = param.label;
  field.required = param.required ?? !!param.required;
  field.defaultValue = param.defaultValue;
  field.multipleValues = param.multipleValues ?? !!param.multipleValues;
  field.global = !!param.global;

  return field;
}

function getDeviceSelectOptions(
  param: FilterParameterConfig,
  deviceType: DeviceTypeDefinition
): FieldSelectOption[] | undefined {
  if (!deviceType) {
    return undefined;
  }
  return getCustomProperty(param.propertyPath, deviceType)?.selectOptions as FieldSelectOption[];
}

export function getDefaultValues(params: FilterParameterConfig[]): Record<string, string> {
  const data = {};
  for (const p of params) {
    data[p.name] = p.defaultValue;
  }
  return data;
}

export function getDeviceTypeFeatureNameFromPath(propertyPath: string): string {
  return propertyPath.includes('/') ? propertyPath.split('/')[1] : 'general';
}

export function getCustomPropertyNameFromPath(propertyPath: string): string {
  return propertyPath.includes('/') ? propertyPath.split('/')[3] : propertyPath;
}

export function getCustomProperty(
  propertyPath: string,
  deviceTypeDefinition: DeviceTypeDefinition
) {
  const featureName = getDeviceTypeFeatureNameFromPath(propertyPath);
  const parameterName = getCustomPropertyNameFromPath(propertyPath);
  return deviceTypeDefinition?.features
    .find((f) => f.defaultName === featureName)
    ?.customProperties?.find((property) => property.name === parameterName);
}
