import {
  EventEmitter,
  Inject,
  Injectable,
  InjectionToken,
  Optional,
  Provider
} from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';
import { PageData } from '@inst-iot/bosch-angular-ui-components';

export interface PaginationColumn {
  name: string;
  label?: string;
  i18nLabel?: string;
  active: boolean;
}

export interface PaginationSettings {
  storageKey?: string;
  limit?: number;
  limits?: number[];
  simpleMode?: boolean;
  columns?: PaginationColumn[];
}

export interface PaginationSettingsChange {
  field: string;
  previousValue: any;
  currentValue: any;
}

export const STORAGE_KEY = new InjectionToken('paginationStorageKey');

@Injectable()
export class PaginationSettingsService implements PaginationSettings {
  get loaded(): boolean {
    return this._loaded;
  }

  limit = 20;
  limits = [10, 20, 50, 100];
  simpleMode = false;
  columns: PaginationColumn[] = null;

  pageData = new PageData({
    number: 0,
    totalElements: 0,
    size: this.limit,
    numberOfElements: 0
  });

  settingsChange = new EventEmitter<PaginationSettingsChange>();

  private _loaded = false;

  constructor(
    private localStorage: LocalStorageService,
    @Optional() @Inject(STORAGE_KEY) public storageKey: string
  ) {
    this.loadStoredData();
    // makes pageData immutable to force usage of the "new" operator to update properties correctly
    Object.freeze(this.pageData);
  }

  static withKey(storageKey): Provider[] {
    return [
      { provide: STORAGE_KEY, useValue: storageKey },
      { provide: PaginationSettingsService, useClass: PaginationSettingsService }
    ];
  }

  getActiveColumns(): PaginationColumn[] {
    if (!this.columns) {
      return [];
    }
    return this.columns.filter((column) => column.active);
  }

  loadStoredData() {
    if (this.storageKey && !this._loaded) {
      const storedData = this.localStorage.get('paginationSettings_' + this.storageKey);
      if (storedData) {
        Object.assign(this, storedData);
        this._loaded = true;
      }
    }
  }

  storeSettings() {
    if (this.storageKey) {
      this.localStorage.set('paginationSettings_' + this.storageKey, {
        limit: this.limit,
        limits: this.limits,
        columns: this.columns,
        simpleMode: this.simpleMode
      });
    }
  }

  /**
   * Called when change by user interaction
   */
  updateLimit(limit) {
    const prev = this.limit;
    this.limit = limit;
    this.settingsChange.next({ field: 'limit', previousValue: prev, currentValue: limit });
    this.storeSettings();
  }

  /**
   * Called when change by user interaction
   */
  updateLimits(limits) {
    const prev = this.limits;
    this.limits = limits;
    this.settingsChange.next({ field: 'limits', previousValue: prev, currentValue: limits });
    this.storeSettings();
  }

  /**
   * Called when change by user interaction
   */
  updateColumns(columns: PaginationColumn[]) {
    const prev = this.columns;
    this.columns = columns;
    this.settingsChange.next({ field: 'columns', previousValue: prev, currentValue: columns });
    this.storeSettings();
  }

  /**
   * Called when change by user interaction
   */
  updateSimpleMode(mode) {
    const prev = this.simpleMode;
    this.simpleMode = mode;
    this.settingsChange.next({ field: 'simpleMode', previousValue: prev, currentValue: mode });
    this.storeSettings();
  }

  /**
   * Called when change by user interaction
   */
  updateColumnActive(column: string, state: boolean) {
    const item = this.columns.find((col) => col.name === column);
    if (!item) {
      return;
    }
    const prev = item.active;
    if (state !== prev) {
      item.active = state;
      this.settingsChange.next({
        field: 'columns.' + column,
        previousValue: prev,
        currentValue: state
      });
      this.storeSettings();
    }
  }
}
