import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRoutingModule } from './user-routing.module';
import { FormsModule } from '@angular/forms';
import { InfoComponent } from './info/info.component';
import { SettingsComponent } from './settings/settings.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { AcceptInviteComponent } from './accept-invite/accept-invite.component';
import { LogoutComponent } from './logout/logout.component';
import { LoginCompleteComponent } from './login-complete/login-complete.component';
import { LoginComponent } from './login/login.component';
import { MigrateAdfsComponent } from './migrate-adfs/migrate-adfs.component';
import { ProjectIncorrectProviderComponent } from './project-incorrect-provider/project-incorrect-provider.component';
import { ProjectNoAccessComponent } from './project-no-access/project-no-access.component';
import { DomainProjectNoAccessComponent } from './domain-project-no-access/domain-project-no-access.component';

@NgModule({
  imports: [CommonModule, SharedModule, UserRoutingModule, FormsModule, TranslateModule],
  declarations: [
    InfoComponent,
    SettingsComponent,
    AcceptInviteComponent,
    LogoutComponent,
    LoginCompleteComponent,
    LoginComponent,
    MigrateAdfsComponent,
    ProjectIncorrectProviderComponent,
    ProjectNoAccessComponent,
    DomainProjectNoAccessComponent
  ],
  exports: []
})
export class UserModule {}
