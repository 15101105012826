<rb-form-input
  [formControl]="control"
  [attr.data-cy]="dataCyInput"
  [label]="label"
  [class.white]="true"
  [rbInitialOpen]="true"
  (keydown)="onEnterPressed($event)"
  [rbFormInputAutocomplete]="searchFunc"
  [rbAutocompleteList]="dropDownContent"
></rb-form-input>
<ng-container *ngIf="loader.error">
  <div *rbLoading="loader"></div>
</ng-container>
<ng-template #dropDownContent let-active="active" let-focus="focus" let-select="select">
  <div class="rb-dropdown">
    <div *ngIf="(!roles || !roles.length) && !loader.loading" class="p-3" translate>
      users.roleSelector.noRolesFound
    </div>
    <a
      href="#"
      class="rb-dropdown-item"
      *ngFor="let role of filteredRoles"
      (click)="select(role.roleName); selectRole(role)"
      [attr.data-cy]="dataCyRoleEntry + role.roleName"
      [class.active]="active === role.roleName"
      [class.focus]="focus === role.roleName"
    >
      {{ role.roleDisplayName }}
      <span
        class="rb-ic rb-ic-question-frame pl-2"
        *ngIf="role?.description"
        [rbTooltip]="role.description"
      ></span>
    </a>

    <a
      href="#"
      *ngIf="hasMore"
      class="rb-forward rb-link rb-dropdown-item"
      (click)="$event.stopPropagation(); loadMore()"
    >
      {{ 'widget.dataSource.loadMore' | translate }}
      <span class="rb-ic rb-ic-refresh rb-ic-spin" *ngIf="loader.loading"></span>
    </a>
  </div>
</ng-template>
