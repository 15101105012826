import { Injectable } from '@angular/core';
import { Device } from '../models/device';
import { Observable, of } from 'rxjs';
import { DeviceType } from '../models/device-types';

@Injectable({ providedIn: 'root' })
export class DeviceCacheService {
  public devicesCache: { [thingId: string]: Device } = {};
  public deviceTypeCache: { [type: string]: Observable<DeviceType> } = {};

  /**
   * Finds the devices and reduces the thingIds list
   */
  findDevicesByIdsInCache(thingIds: string[]): Device[] {
    const devices = [];
    for (let i = 0; i < thingIds.length; i++) {
      const thingId = thingIds[i];
      if (this.devicesCache[thingId]) {
        devices.push(this.devicesCache[thingId]);
        thingIds.splice(i, 1);
        i--;
      }
    }
    return devices;
  }

  cacheDeviceType(deviceType: DeviceType) {
    this.deviceTypeCache[deviceType.type] = of(deviceType);
  }

  resetCache() {
    this.devicesCache = {};
    this.deviceTypeCache = {};
  }
}
