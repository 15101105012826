import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap, publishReplay, refCount, skipWhile } from 'rxjs/operators';
import { ProjectsService } from '../../shared-projects/services/projects.service';
import {
  AggregatedCollectionDbStats,
  CollectionDbStats,
  CollectionHistory,
  CollectionOverview,
  InputData,
  MongoDBRef,
  MonitoringFailure,
  ProcessedData,
  ProcessingExecution,
  ProcessingHistory
} from '../../shared/api-model';
import { DeletionMode } from '../../processor/input-data-view/input-data.service';
import { QueryConfig } from '../models/query-config';
import { QueryEndPointTypes, QueryService } from './query.service';
import { InputDataDocument } from '../../processor/models/input-data-document.model';

export interface PagedFromToParams {
  page: number;
  pageSize: number;
  sort?: string;
  from?: string;
  to?: string;
  filterQuery?: any;
}

export interface DocumentsQueryParams extends PagedFromToParams {
  userName?: string;
  userId?: string;
  senderIp?: string;
  filterQuery?: any;
}

export interface ProcessingLogQueryParams extends PagedFromToParams {
  success?: string;
  inputDataId?: string;
  inputDataCollection?: string;
  processedDataId?: string;
  processedDataCollection?: string;
  answerMaxLimit?: number;
  statusFilterQuery?: any;
}

function encodeDocumentId(documentId: string | object) {
  if (typeof documentId === 'string') {
    return encodeURIComponent(documentId);
  } else {
    return encodeURIComponent(JSON.stringify(documentId));
  }
}

@Injectable()
export class DataPipelineService {
  baseUrl = '/project-management-service';

  inputCollectionsByProject: { [project: string]: Observable<CollectionOverview[]> } = {};
  processedCollectionsByProject: { [project: string]: Observable<CollectionOverview[]> } = {};

  constructor(
    private http: HttpClient,
    private projectsService: ProjectsService,
    private queryService: QueryService
  ) {}

  getInputDataCollections(projectId: string): Observable<CollectionOverview[]> {
    if (this.inputCollectionsByProject[projectId]) {
      return this.inputCollectionsByProject[projectId];
    }
    this.inputCollectionsByProject[projectId] = this.http
      .get<CollectionOverview[]>(this.getInputDataUrl(projectId) + '/collections')
      .pipe(publishReplay(1), refCount());
    return this.inputCollectionsByProject[projectId];
  }

  getProcessedDataCollections(projectId: string): Observable<CollectionOverview[]> {
    if (this.processedCollectionsByProject[projectId]) {
      return this.processedCollectionsByProject[projectId];
    }
    this.processedCollectionsByProject[projectId] = this.http
      .get<CollectionOverview[]>(this.getProcessedDataUrl(projectId) + '/collections')
      .pipe(publishReplay(1), refCount());
    return this.processedCollectionsByProject[projectId];
  }

  getProcessedDataInputData(
    projectId: string,
    collection: string,
    documentId: string
  ): Observable<MongoDBRef[]> {
    return this.http.get<MongoDBRef[]>(
      this.getProcessedDataUrl(projectId, collection) +
        '/documents/' +
        encodeDocumentId(documentId) +
        '/input-data'
    );
  }

  getProcessedDataSets(
    projectId: string,
    collection: string,
    page = 0,
    size = 0,
    sort: string = null,
    query: any = null
  ): Observable<HttpResponse<ProcessedData[]>> {
    let params = new HttpParams();
    if (page) {
      params = params.set('page', page.toString());
    }
    if (size) {
      params = params.set('pageSize', size.toString());
    }
    if (sort) {
      params = params.set('sort', sort);
    }
    if (query) {
      params = params.set('filterQuery', JSON.stringify(query));
    }
    return this.http.get<ProcessedData[]>(
      this.getProcessedDataUrl(projectId, collection) + '/documents',
      {
        params: params,
        observe: 'response'
      }
    );
  }

  getProcessedData(
    projectId: string,
    collection: string,
    documentId: string | object,
    page?: number,
    size?: number,
    sort?: string
  ): Observable<ProcessedData> {
    let params = new HttpParams();
    if (page) {
      params = params.set('page', page.toString());
    }
    if (size) {
      params = params.set('pageSize', size.toString());
    }
    if (sort) {
      params = params.set('sort', sort);
    }
    return this.http.get<ProcessedData>(
      this.getProcessedDataUrl(projectId, collection) +
        '/documents/' +
        encodeDocumentId(documentId),
      { params: params }
    );
  }

  getInputDataStats(projectId: string): Observable<AggregatedCollectionDbStats> {
    return this.http.get<AggregatedCollectionDbStats>(
      this.getInputDataUrl(projectId) + '/db-stats'
    );
  }

  getInputDataCollectionStats(
    projectId: string,
    collection: string
  ): Observable<CollectionDbStats[]> {
    return this.http.get<CollectionDbStats[]>(
      this.getInputDataUrl(projectId, collection) + '/db-stats'
    );
  }

  getInputDataCollectionHistory(
    projectId: string,
    collection: string,
    from: string,
    to: string,
    resolution: 'DAYS' | 'HOURS' = 'DAYS'
  ): Observable<CollectionHistory> {
    let params = new HttpParams();
    params = params.set('from', from);
    params = params.set('to', to);
    params = params.set('resolution', resolution);
    return this.http.get<CollectionHistory>(
      this.getInputDataUrl(projectId, collection) + '/history',
      { params: params }
    );
  }

  getInputDataDocuments(queryConfig: QueryConfig): Observable<InputDataDocument[]> {
    return this.queryService.runQueryConfig(queryConfig).pipe(
      skipWhile((status) => status.result === undefined),
      map((response) => {
        return response.result?.map((entry) => {
          const { _id: id, ...properties } = entry;
          const inputData = { id, ...properties };
          return new InputDataDocument(queryConfig.collection, inputData);
        });
      })
    );
  }

  countInputDataDocuments(query: any, collection: string, tag: string): Observable<number> {
    const queryConfig = new QueryConfig({
      project: this.projectsService.projectName,
      query: query,
      collection: collection,
      tag: tag,
      type: QueryEndPointTypes.Count,
      queuingTimeout: 600
    });

    return this.queryService
      .runQueryConfig(queryConfig)
      .pipe(map((response) => response.result[0]));
  }

  getInputDataDocumentsByProcessedData(
    projectId: string,
    collection: string,
    processedId: string
  ): Observable<InputDataDocument[]> {
    return this.getProcessedDataInputData(projectId, collection, processedId).pipe(
      mergeMap((refs) => {
        if (!refs || !refs.length) {
          return of([]);
        }
        return forkJoin(
          refs.map((ref) =>
            this.getInputDataDocument(projectId, ref.collection, ref.id).pipe(
              map((entry) => {
                return {
                  collection: ref.collection,
                  inputData: entry
                };
              })
            )
          )
        );
      })
    );
  }

  getInputDataPreviewText(inputDataId: string): Observable<string> {
    const collection = `${this.projectsService.projectName}_input_data`;
    return this.http.get(
      `${this.getInputDataUrl(
        this.projectsService.projectName,
        collection
      )}/documents/${inputDataId}/preview`,
      { responseType: 'text' }
    );
  }

  getInputDataPreviewImage(
    inputDataId: string,
    maxWidth?: number,
    maxHeight?: number
  ): Observable<Blob> {
    const collection = `${this.projectsService.projectName}_input_data`;

    let params = new HttpParams();
    if (maxWidth && maxHeight) {
      params = params.set('maxWidth', `${maxWidth}`);
      params = params.set('maxHeight', `${maxHeight}`);
    }

    return this.http.get(
      `${this.getInputDataUrl(
        this.projectsService.projectName,
        collection
      )}/documents/${inputDataId}/preview`,
      { params, responseType: 'blob' }
    );
  }

  getInputDataDocument(
    projectId: string,
    collection: string,
    documentId: string | object
  ): Observable<InputData> {
    return this.http.get<InputData>(
      this.getInputDataUrl(projectId, collection) + '/documents/' + documentId
    );
  }

  restoreInputDataDocument(projectId: string, collection: string, documentId: string) {
    return this.http.patch<any>(
      this.getInputDataUrl(projectId, collection) + '/documents/' + documentId + '/restore',
      {}
    );
  }

  deleteInputDataDocument(
    projectId: string,
    collection: string,
    documentId: string | object,
    deletionMode: DeletionMode = 'inputDataOnly'
  ): Observable<HttpResponse<string>> {
    return this.http.delete(
      this.getInputDataUrl(projectId, collection) + '/documents/' + documentId,
      {
        params: new HttpParams().set('deletionMode', deletionMode),
        responseType: 'text',
        observe: 'response'
      }
    );
  }

  deleteProcessedDataDocument(
    projectId: string,
    collection: string,
    documentId: string | object,
    purge = false
  ): Observable<string> {
    return this.http.delete(
      this.getProcessedDataUrl(projectId, collection) +
        '/documents/' +
        encodeDocumentId(documentId),
      { params: new HttpParams().set('purge', String(purge)), responseType: 'text' }
    );
  }

  getInputDataDocumentDownloadUrl(
    projectId: string,
    collection: string,
    documentId: string | object
  ): string {
    return this.getInputDataUrl(projectId, collection) + '/documents/' + documentId + '/download';
  }

  getInputDataDownloadAllUrl(
    projectId: string,
    collection: string,
    filters: DocumentsQueryParams
  ): string {
    let params = new HttpParams();
    Object.keys(filters).forEach((key) => (params = params.set(key, filters[key])));
    return this.getInputDataUrl(projectId, collection) + '/documents/downloadfiltered?' + params;
  }

  getProcessingInfoDocuments(
    projectId: string,
    query: ProcessingLogQueryParams
  ): Observable<HttpResponse<ProcessingExecution[]>> {
    let params = new HttpParams();
    Object.keys(query).forEach((key) => (params = params.set(key, query[key])));
    return this.http.get<ProcessingExecution[]>(
      this.getProcessingInfoUrl(projectId) + '/executions',
      {
        observe: 'response',
        params: params
      }
    );
  }

  getIngestionDocuments(
    projectId: string,
    query: ProcessingLogQueryParams
  ): Observable<HttpResponse<MonitoringFailure[]>> {
    let params = new HttpParams();
    Object.keys(query).forEach((key) => (params = params.set(key, query[key])));
    return this.http.get<MonitoringFailure[]>(this.getIngestionDataUrl(projectId), {
      observe: 'response',
      params: params
    });
  }

  getIngestionDocumentHistory(
    projectId: string,
    from: string,
    to: string,
    resolution: 'DAYS' | 'HOURS' = 'DAYS'
  ): Observable<MonitoringFailure> {
    let params = new HttpParams();
    params = params.set(
      'filterQuery',
      JSON.stringify({ timestamp: { $gte: { $date: from }, $lte: { $date: to } } })
    );
    params = params.set('resolution', resolution);

    return this.http.get<MonitoringFailure>(this.getIngestionDataUrl(projectId), {
      params: params
    });
  }

  getProcessingInfoHistory(
    projectId: string,
    from: string,
    to: string,
    resolution: 'DAYS' | 'HOURS' = 'DAYS'
  ): Observable<ProcessingHistory> {
    let params = new HttpParams();
    params = params.set('from', from);
    params = params.set('to', to);
    params = params.set('resolution', resolution);
    return this.http.get<ProcessingHistory>(this.getProcessingInfoUrl(projectId) + '/history', {
      params: params
    });
  }

  private getBaseUrl(projectId: string) {
    return this.baseUrl + '/v1/' + projectId;
  }

  private getInputDataUrl(projectId: string, collectionName: string = null) {
    if (collectionName) {
      return this.getBaseUrl(projectId) + '/input-data/collections/' + collectionName;
    } else {
      return this.getBaseUrl(projectId) + '/input-data';
    }
  }

  private getProcessedDataUrl(projectId: string, collectionName: string = null) {
    if (collectionName) {
      return this.getBaseUrl(projectId) + '/processed-data/collections/' + collectionName;
    } else {
      return this.getBaseUrl(projectId) + '/processed-data';
    }
  }

  private getProcessingInfoUrl(projectId: string) {
    return this.getBaseUrl(projectId) + '/processing';
  }

  private getIngestionDataUrl(projectId: string) {
    return `/ingestion-monitoring-service/v1/${projectId}/monitoring/ingestion/errors`;
  }

  getInputDataCollectionHistorySize(
    projectId: string,
    collection: string,
    from: string,
    to: string,
    resolution: 'DAYS' | 'HOURS' = 'DAYS'
  ): Observable<CollectionHistory> {
    let params = new HttpParams();
    params = params.set('from', from);
    params = params.set('to', to);
    params = params.set('resolution', resolution);
    return this.http.get<CollectionHistory>(
      this.getInputDataUrl(projectId, collection) + '/history-size',
      { params: params }
    );
  }

  getImageUrl(projectId: string, collectionName: string, documentId: string, contentType: string) {
    return (
      this.getBaseUrl(projectId) +
      '/processed-data/collections/' +
      collectionName +
      '/images/' +
      documentId +
      '/display?contentType=' +
      encodeURIComponent(contentType)
    );
  }

  getDuplicatesCount(projectId: string, from?: string, to?: string): Observable<number> {
    const find = {
      collection: projectId + '_input_data',
      query: this.getCountDuplicatesQuery(from, to)
    };

    return this.queryService.executeCountQuery(find);
  }

  getCountDuplicatesQuery(from?: string, to?: string) {
    const andArray = [];
    if (from) {
      const receivedFrom = {};
      receivedFrom['receivedAt'] = {
        $gte: { $date: from }
      };
      andArray.push(receivedFrom);
    }
    if (to) {
      const receivedTo = {};
      receivedTo['receivedAt'] = {
        $lte: { $date: to }
      };
      andArray.push(receivedTo);
    }
    const duplicate = {};
    duplicate['duplicate'] = true;
    andArray.push(duplicate);
    return { $and: andArray };
  }
}
