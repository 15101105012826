import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConditionInputModule } from '../condition-input/condition-input.module';
import { ColorPickerComponent } from './color-picker.component';
import { TranslateModule } from '@ngx-translate/core';
import { ColorSelectorModule } from './color-selector/color-selector.module';

@NgModule({
  declarations: [ColorPickerComponent],
  imports: [CommonModule, ConditionInputModule, ColorSelectorModule, TranslateModule],
  exports: [ColorPickerComponent]
})
export class ColorPickerModule {}
