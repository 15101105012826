import { Component, OnInit } from '@angular/core';
import { UserAuthService } from '../../core/services/user-auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingEntity } from '@inst-iot/bosch-angular-ui-components';
import { HttpClient } from '@angular/common/http';
import { isIncorrectProviderError } from '../../shared/http-utils';
import { Constants } from '../../../constants';

interface ActivateUserResponse {
  validRequest: boolean;
  emailMismatch: boolean;
  identityProviderMismatch: boolean;
}

@Component({
  selector: 'accept-invite',
  templateUrl: './accept-invite.component.html'
})
export class AcceptInviteComponent implements OnInit {
  username: string;

  infoLoader = new LoadingEntity<ActivateUserResponse>();

  acceptLoader = new LoadingEntity<ActivateUserResponse>();

  userId: string;

  activationCode: string;

  project: string;

  constructor(
    private authService: UserAuthService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.username = this.authService.userName;
    this.route.queryParams.subscribe((param) => {
      this.userId = param.userId;
      this.activationCode = param.activationCode;
      this.project = param.project;
      this.loadStatus();
    });
  }

  loadStatus() {
    if (this.userId && this.activationCode) {
      const url = `/activate-user/ciam?userId=${this.userId}&activationCode=${this.activationCode}`;
      this.infoLoader.run(this.http.get<ActivateUserResponse>(url)).subscribe({
        error: (err) => {
          if (isIncorrectProviderError(err)) {
            this.router.navigate([Constants.routing.userIncorrectIdentityProvider]);
          }
        }
      });
    }
  }

  acceptInvite() {
    const data = {
      userId: this.userId,
      activationCode: this.activationCode,
      username: this.username
    };

    this.acceptLoader
      .run(this.http.post<ActivateUserResponse>('/activate-user/ciam', data))
      .subscribe((res) => {
        if (!res.emailMismatch) {
          const redirectPath = this.project ? `/ui/project/${this.project}` : '/ui/projects';
          this.authService.performLogin(redirectPath);
        }
      });
  }
}
