import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { BreadcrumbsService, LoadingEntity } from '@inst-iot/bosch-angular-ui-components';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppThemingService } from './core/services/app-theming.service';
import { CookieCheckService } from './core/services/cookie-check.service';
import { MergeUserService } from './core/services/merge-user.service';
import { UserAuthService } from './core/services/user-auth.service';
import { ProjectsService } from './shared-projects/services/projects.service';
import { generateStylesheet, removeStylesheet } from './shared-projects/utils/project-styling.util';
import { UiConfig } from './shared/api-model';
import { isHandledError } from './shared/http-utils';
import { TranslatedPipe } from './shared/pipes/translated.pipe';
import { getProjectFromPath, isProjectRoute } from './shared/project-utils';
import { LanguagesService } from './core/services/languages.service';

@Component({
  selector: 'app-root',
  styles: [
    `
      :host {
        height: inherit;
      }
    `
  ],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  destroy = new Subject<null>();

  loading = false;
  isFirstNavigation = true;
  error = null;
  uiConfigLoader = new LoadingEntity<UiConfig>();
  isProjectRoute = false;

  constructor(
    private authService: UserAuthService,
    private projects: ProjectsService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private mergeUserService: MergeUserService,
    private cookieCheckService: CookieCheckService,
    private breadcrumbsService: BreadcrumbsService,
    public appTheming: AppThemingService,
    private translatedPipe: TranslatedPipe,
    private languagesService: LanguagesService
  ) {}

  get projectName() {
    return this.projects.projectName;
  }

  get projectLabel() {
    return this.projects.projectLabel;
  }

  @HostListener('window:focus')
  onFocus() {
    this.authService.checkAutoLogout();
  }

  ngOnInit() {
    this.subscribeToRouter();
    this.subscribeToProjectConfig();
    this.subscribeToBreadcrumbs();

    this.uiConfigLoader.run(this.authService.getUiConfig()).subscribe((uiConfig) => {
      if (uiConfig.loggedIn) {
        this.mergeUserService.checkUserMerge(uiConfig).subscribe();
      }
    });
    this.cookieCheckService.checkCookieEnabled();
  }

  isLoading() {
    return (
      (this.uiConfigLoader.loading ||
        this.loading ||
        this.projects.projectConfigLoader.loading ||
        !this.languagesService.translationsLoaded) &&
      !this.error
    );
  }

  private subscribeToRouter() {
    this.router.events.pipe(takeUntil(this.destroy)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.isProjectRoute = isProjectRoute(event.url);
        this.authService.isLoggedIn().subscribe((isLoggedIn) => {
          if (isLoggedIn) {
            this.initProject(getProjectFromPath(event.url));
          }
        });

        if (this.isFirstNavigation) {
          this.loading = true;
          this.isFirstNavigation = false;
        }
      }
      if (event instanceof NavigationEnd) {
        this.loading = false;
        if (getProjectFromPath(event.url)) {
          this.renderer.addClass(this.document.body, 'projectSpace');
        } else {
          this.renderer.removeClass(this.document.body, 'projectSpace');
        }
      }
      this.error = null;
      if (event instanceof NavigationError && this.loading) {
        this.loading = false;
        this.error = isHandledError(event.error) ? null : event.error;
      }
    });
  }

  private subscribeToProjectConfig() {
    this.projects.projectConfigEvents.pipe(takeUntil(this.destroy)).subscribe((conf) => {
      if (conf?.config) {
        generateStylesheet(conf.config);
      } else {
        removeStylesheet();
      }
    });
  }

  private subscribeToBreadcrumbs() {
    this.breadcrumbsService.getBreadcrumbs().subscribe((breadcrumbs) => {
      if (breadcrumbs && breadcrumbs.length) {
        const last = breadcrumbs[breadcrumbs.length - 1];
        this.updateTitle(last.title);
      } else {
        this.updateTitle();
      }
    });
  }

  private initProject(projectName: string) {
    if (!projectName) {
      this.projects.clearProject();
    } else if (this.projectName !== projectName) {
      this.loading = true;
      this.projects.initProject(projectName, this.authService.getUserId());
    }
  }

  /**
   * Updates the pages title tag
   */
  private updateTitle(pageTitle?: string) {
    let title = this.appTheming.appTheme.productName || '';

    if (this.projects.projectConfig) {
      title = this.translatedPipe.transform(this.projects.projectConfig.label);
      if (pageTitle) {
        title += ' - ' + pageTitle + ' | ' + this.appTheming.appTheme.productName;
      }
    } else if (pageTitle) {
      title = pageTitle + ' - ' + title;
    }

    const element = document.head.querySelector('title');
    if (element && element.textContent) {
      element.textContent = title;
    }
  }
}
