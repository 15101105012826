import { Component, OnInit } from '@angular/core';
import { LanguageItem } from '@inst-iot/bosch-angular-ui-components/molecules/language-selector/language-selector.component';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { LocalStorageService } from 'ngx-localstorage';
import { LanguagesService } from '../../core/services/languages.service';
import { SettingsService } from '../../core/services/settings.service';
import { translate } from '../../shared/translation-util';

@Component({
  selector: 'settings',
  templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit {
  languages: LanguageItem[] = [];

  timezones: { name: string; info: string }[] = [];

  timeFormats = [
    { value: '24', name: translate('settings.format24h') },
    { value: '12', name: translate('settings.format12h') }
  ];

  constructor(
    public settings: SettingsService,
    private languagesService: LanguagesService,
    private translate: TranslateService,
    private localStorage: LocalStorageService
  ) {}

  ngOnInit() {
    this.languages = this.languagesService.mapLanguages();
    this.timezones = (moment as any).tz.names().map((tzName) => {
      return { name: tzName, info: (moment as any).tz(tzName).format('z Z') };
    });
  }

  set language(lang: string) {
    this.settings.language = lang === 'null' ? null : lang;
    if (this.settings.language) {
      this.translate.use(this.settings.language);
    } else {
      this.translate.use(this.languagesService.getAutoConfiguredLanguage());
    }
  }

  get language() {
    if (this.settings.language === null) {
      return 'null';
    }
    return this.settings.language;
  }

  get timeFormat(): string {
    return this.settings.timeFormat;
  }

  set timeFormat(tf: string) {
    this.settings.timeFormat = tf;
    this.settings.persist();
    this.languagesService.updateTimeFormat();
  }

  get timezone() {
    if (this.settings.customTimezone === null) {
      return 'null';
    }
    return this.settings.customTimezone;
  }

  set timezone(tz: string) {
    this.languagesService.updateTimezone(tz === 'null' ? null : tz);
  }

  resetStorage() {
    this.localStorage.clear();
    const defaultSettings = new SettingsService(this.localStorage);
    Object.assign(this.settings, defaultSettings);
  }
}
