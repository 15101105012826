<div class="d-flex justify-content-start">
  <div *ngIf="allowSet">
    <rb-form-checkbox [(ngModel)]="internalIsSet">Set</rb-form-checkbox>
  </div>
  <ng-container *ngIf="!allowSet || isSet">
    <ng-container *ngIf="!isNull && !(showChoices && hasChoices)">
      <div *ngIf="parameter.parameterType === 'SCALAR'" style="flex-grow: 1">
        <value-input
          [dataType]="parameter.dataType"
          [label]="labelTpl"
          [value]="value"
          (valueChange)="updateValue($event)"
          [required]="!parameter.optional"
          [validationRegExp]="parameter.valueRegex"
          [readonly]="readonly"
        ></value-input>
      </div>
      <div
        *ngIf="parameter.parameterType === 'RANGE' && parameter.dataType !== 'TIMESTAMP'"
        style="flex-grow: 1"
      >
        <range-value
          [label]="labelTpl"
          [parameter]="parameter"
          [value]="value"
          (valueChange)="updateValue($event)"
        ></range-value>
      </div>
      <div
        *ngIf="parameter.parameterType === 'RANGE' && parameter.dataType === 'TIMESTAMP'"
        style="flex-grow: 1"
      >
        <value-input
          [dataType]="'TIMESTAMP_RANGE'"
          [label]="labelTpl"
          [value]="value"
          [attr.data-cy]="'parameterValue-' + parameter.name"
          (valueChange)="updateValue($event)"
          [required]="!parameter.optional"
        ></value-input>
      </div>
      <div *ngIf="parameter.parameterType === 'LIST'" style="flex-grow: 1">
        <list-value
          [label]="labelTpl"
          [parameter]="parameter"
          [value]="value"
          (valueChange)="updateValue($event)"
        ></list-value>
      </div>
      <div *ngIf="parameter.parameterType === 'MAP'" style="flex-grow: 1">
        <map-value
          [label]="labelTpl"
          [parameter]="parameter"
          [value]="value"
          (valueChange)="updateValue($event)"
        ></map-value>
      </div>
      <div *ngIf="parameter.parameterType === 'QUERY'" style="flex-grow: 1">
        <query-condition-input
          [label]="labelTpl"
          [customCSS]="'show-container'"
          [query]="value"
          (queryChanged)="updateValue($event.query)"
        ></query-condition-input>
      </div>
    </ng-container>
    <ng-container *ngIf="!isNull && showChoices && hasChoices">
      <div style="flex-grow: 1">
        <choice-value
          [label]="labelTpl"
          [parameter]="parameter"
          [value]="value"
          (valueChange)="updateValue($event)"
        ></choice-value>
      </div>
    </ng-container>

    <div *ngIf="parameter.nullable">
      <rb-form-checkbox [(ngModel)]="isNull">Null</rb-form-checkbox>
    </div>
  </ng-container>
</div>
<ng-template #labelTpl>
  {{ parameterName || ('queryTemplate.defaultParamValueLabel' | translate) }}
  <span
    class="text-danger"
    *ngIf="!parameter.optional"
    [rbTooltip]="'queryTemplate.required' | translate"
  >
    *
  </span>
  <span class="text-info" *ngIf="isSet" [rbTooltip]="'queryTemplate.isSet' | translate">+</span>
</ng-template>
