import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedProjectsModule } from '../../../shared-projects/shared-projects.module';
import { SharedModule } from '../../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { BackdateChangeComponent } from './backdate-change.component';

@NgModule({
  imports: [SharedModule, SharedProjectsModule, RouterModule, CommonModule],
  declarations: [BackdateChangeComponent],
  exports: [BackdateChangeComponent],
  providers: []
})
export class BackdateChangeModule {}
