import { HttpEvent, HttpEventType } from '@angular/common/http';

export interface ImageWithUploadStatus {
  file: File;
  uploading: boolean;
  uploaded: number;
  total: number;
  body: any;
  src: string;
}

export function mapFileToImageWithStatus(file: File): ImageWithUploadStatus {
  return {
    file: file,
    uploading: false,
    uploaded: 0,
    total: 0,
    body: null,
    src: ''
  };
}

export function trackImageUploadProgress(image: ImageWithUploadStatus, event: HttpEvent<any>) {
  if (event.type === HttpEventType.Sent) {
    image.uploading = true;
  }
  if (event.type === HttpEventType.UploadProgress) {
    image.uploaded = event.loaded;
    image.total = event.total;
  }
  if (event.type === HttpEventType.Response) {
    image.body = event.body;
  }
  return event.type === HttpEventType.Response;
}
