import { ServicePlanType } from '../../shared-projects/models/service-plan.model';

export enum SubscriptionStatus {
  created = 'created',
  active = 'active',
  standby = 'standby',
  deleted = 'deleted',
  failed = 'failed',
  terminated = 'terminated'
}

export class SubscriptionDetails {
  status: SubscriptionStatus;
  plan: ServicePlanType;
  marketplaceId: string;
  createdTs?: string;
  email?: string; // Owner email
  ownerOrg?: string; // Owner organization, used for determining discount for pay-as-you-go plans
  instanceId?: string; // ITSP / Bosch IoT Suite subscription ID
  sub?: string; // Technical user id
  terminationTs?: string; // only set if marked for deletion
  costCenter?: string;

  constructor(details: Partial<SubscriptionDetails> = {}) {
    this.status = details?.status;
    this.plan = details?.plan;
    this.marketplaceId = details?.marketplaceId;
    this.createdTs = details?.createdTs;
    this.email = details?.email;
    this.ownerOrg = details?.ownerOrg;
    this.instanceId = details?.instanceId;
    this.sub = details?.sub;
    this.terminationTs = details?.terminationTs;
    this.costCenter = details?.costCenter;

    return this;
  }

  toUpdateRequest(): UpdateSubscriptionDetailsRequest {
    return {
      marketplaceId: this.marketplaceId,
      createdTs: this.createdTs,
      email: this.email,
      ownerOrg: this.ownerOrg,
      instanceId: this.instanceId,
      sub: this.sub,
      costCenter: this.costCenter
    };
  }
}

export interface UpdateSubscriptionDetailsRequest {
  marketplaceId: string;
  createdTs?: string;
  email?: string;
  ownerOrg?: string;
  instanceId?: string;
  sub?: string;
  costCenter?: string;
}
