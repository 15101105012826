import { ConditionChip } from '../models/condition-chip.model';
import { checkSyntax } from './syntax-error-handler.util';
import { LogicalOperator, NodeType, regex, SyntaxNode } from '../models/syntax-node.model';

function createNode(type: NodeType) {
  return new SyntaxNode(type);
}

function classifyChars(node: SyntaxNode, input: string) {
  let skipIterations = 0;

  for (let i = 0; i < input.length; i++) {
    if (skipIterations) {
      skipIterations--;
      continue;
    }

    if (regex.isANumber.exec(input[i])) {
      skipIterations = identifyChip(input.substring(i), node);
    } else if (regex.containsANDOR.exec(input[i])) {
      skipIterations = identifyLogicalOperators(input.substring(i), node);
    } else if (input[i] === '(') {
      setGroupMarker('GroupMarkerOpen', node);
    } else if (input[i] === ')') {
      setGroupMarker('GroupMarkerClose', node);
    }
  }

  identifyGroups(node);
}

function identifyGroups(node: SyntaxNode, groupOffset = 0) {
  let group: SyntaxNode;
  let startPosition = -1;
  let idx = groupOffset;

  while (idx < node.childNodes.length) {
    const child = node.childNodes[idx];

    switch (child.type) {
      case 'GroupMarkerClose':
        startPosition !== -1 ? insertGroup() : exitLoop();
        break;
      case 'GroupMarkerOpen':
        startPosition !== -1 ? createSubGroup() : createGroup();
        break;
      default:
        if (startPosition !== -1) {
          group.childNodes.push(child);
        }
        break;
    }
    idx++;
  }

  function createSubGroup() {
    identifyGroups(node, idx);
    // Jump back to replaced GroupOpenMarker, when Subgroup has been resolved
    // Necessary to achieve a correct Splicing
    if (node.childNodes[idx].type === 'Group') {
      idx--;
    }
  }

  function insertGroup() {
    node.childNodes.splice(startPosition, group.childNodes.length + 2, group);
    idx = startPosition;
    startPosition = -1;
  }

  function createGroup() {
    startPosition = idx;
    group = createNode('Group');
  }

  function exitLoop() {
    idx = node.childNodes.length;
  }
}

function setGroupMarker(markerType: NodeType, node: SyntaxNode) {
  node.childNodes.push(createNode(markerType));
}

function identifyLogicalOperators(substring: string, node: SyntaxNode) {
  let logicalOperator = '';

  for (const char of substring) {
    if (regex.isANumber.exec(char) || regex.isBracket.exec(char)) {
      break;
    }
    logicalOperator += char;
    if (regex.isANDorOR.exec(logicalOperator) || logicalOperator.length > 2) {
      break;
    }
  }

  node.childNodes.push(
    new SyntaxNode(
      'LogicalOperator',
      new LogicalOperator(logicalOperator ? logicalOperator : substring.slice(0, 3)),
      node.childNodes.length
    )
  );

  return logicalOperator.length - 1;
}

function identifyChip(substring: string, node: SyntaxNode) {
  let idx = 0;
  let chipID = '';
  while (idx >= 0) {
    if (!regex.isANumber.exec(substring[idx])) {
      break;
    }
    chipID += substring[idx];
    idx++;
  }

  node.childNodes.push(new SyntaxNode('ConditionChip', +chipID, node.childNodes.length));

  return chipID.length - 1;
}

export function typifySyntaxNodes(node: SyntaxNode) {
  node.childNodes.forEach((child: any) => {
    if (child.type === 'ConditionChip') {
      if (typeof child.body.index === 'number') {
        child.body = new ConditionChip(child.body.index, {
          value: child.body.value,
          type: child.body.type,
          searchType: child.body.searchType,
          input: child.body.input,
          i18nLabel: child.body.i18nLabel,
          operatorID: child.body.operatorID,
          fieldName: child.body.fieldName,
          customValuePath: child.body.customValuePath,
          valueLabel: child.body.valueLabel
        });
      }
    } else if (child.type === 'LogicalOperator') {
      child.body = new LogicalOperator(child.body.type);
    } else if (child.type === 'Group') {
      typifySyntaxNodes(child);
    }
  });
}

export function parseInput(
  chips: ConditionChip[],
  errors: SyntaxError[],
  input: string
): SyntaxNode {
  const rootNode = createNode('SyntaxRootNode');
  classifyChars(rootNode, input);
  checkSyntax(rootNode, chips, errors);
  return rootNode;
}
