import { Injectable } from '@angular/core';
import { BreadcrumbsService } from '@inst-iot/bosch-angular-ui-components';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Breadcrumb } from '@inst-iot/bosch-angular-ui-components/molecules/breadcrumbs/breadcrumbs.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslatedBreadcrumbsService extends BreadcrumbsService {
  constructor(router: Router, activeRoute: ActivatedRoute, private translate: TranslateService) {
    super(router, activeRoute);
  }

  protected getBreadCrumbElement(route: ActivatedRouteSnapshot, path: string[]): Breadcrumb {
    const element = {
      urlSegments: path.slice(0),
      title: '...'
    };
    if (route.outlet === 'primary' && route.data && route.data['i18nTitle'] && this.translate) {
      const key = route.data['i18nTitle'];
      this.translate.get(key).subscribe((title) => {
        element.title = title;
      });
      return element;
    }
    if (route.outlet === 'primary' && route.data && route.data['title']) {
      element.title =
        typeof route.data['title'] === 'function'
          ? route.data['title'](route)
          : route.data['title'];
      return element;
    }
    return null;
  }
}
