import { Inject, Injectable, InjectionToken, Optional, SecurityContext } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { Params, Router } from '@angular/router';
import {
  encodeUriWithModifiers,
  resolveMultipleIteratingPlaceholders,
  resolvePlaceholders,
  resolveStringWithModifiers
} from './placeholder-utils';
import { Device } from '../../../../devices/models/device';
import { Constants } from '../../../../../constants';
import { ProjectUrlPipe } from '../../../../shared-projects/pipes/project-url.pipe';
import { getProjectFromPath } from '../../../../shared/project-utils';
import { DomSanitizer } from '@angular/platform-browser';
import { getDashboardNameFromPath, getUrlParamsFromPath } from '../../../../shared/dashboard-utils';

/*
 * Embedded Insights Frames can optionally receive a prefixUrl parameter
 * This affects internal dashboard Links to be re-composed to: "prefixUrl" + "dashboardName".
 * Except for trigger Urls, as they would otherwise lead to a duplicate embedding
 * */
export const INITIAL_PREFIX_URL = new InjectionToken<string>('INITIAL_PREFIX_URL');

export const FILE_LINK_REGEX = /^file:/;

export enum HyperlinkTarget {
  BLANK = 'blank',
  SELF = 'self',
  CLIPBOARD = 'clipboard'
}

export type LinkProperties = {
  isInternalUrl: boolean;
  isTargetSelf: boolean;
  isTargetClipboard: boolean;
};

export type ParsedUrlResult = Pick<
  LinkDefinition,
  'queryParams' | 'hasQueryParams' | 'urlWithoutQueryParams'
>;

export type LinkDefinitionTarget = '_blank' | '_self';

export interface LinkDefinition {
  isCopyToClipboard?: boolean;
  isFileLink?: boolean;
  url?: string;
  hasQueryParams: boolean;
  urlWithoutQueryParams: string;
  queryParams: Params;
  label: string;
  target: LinkDefinitionTarget;
  isInternal: boolean;
  fullUrl: string;
  internalUrl?: string;
  targetUrl?: string;
  callback?: (e: Event) => void;
}

@Injectable({
  providedIn: 'root'
})
export class HyperlinkService {
  constructor(
    @Optional() @Inject(INITIAL_PREFIX_URL) private readonly initialPrefixUrl: string,
    @Inject(DOCUMENT) private document: Document,
    private location: Location,
    private window: Window,
    private router: Router,
    private projectUrlPipe: ProjectUrlPipe,
    private sanitizer: DomSanitizer
  ) {}

  resolveLinkArray(
    definition: { label: string; path: string; target: string },
    ...dataSource: any[]
  ): LinkDefinition[] {
    const links = [];

    const resolvedValues = resolveMultipleIteratingPlaceholders(
      [definition.label, definition.path],
      [resolveStringWithModifiers, encodeUriWithModifiers],
      ...dataSource
    );

    for (const [label, resolvedUrl] of resolvedValues) {
      links.push(this.createLinkDefinition(resolvedUrl, label, definition.target));
    }

    return links;
  }

  resolveLink(
    definition: { label?: string; path: string; target: string; isTriggerUrl?: boolean },
    ...dataSource: any[]
  ): LinkDefinition {
    const resolvedUrl = resolvePlaceholders(
      definition.path,
      0,
      encodeUriWithModifiers,
      ...dataSource
    );
    const resolvedLabel = resolvePlaceholders(
      definition.label,
      0,
      resolveStringWithModifiers,
      ...dataSource
    );

    return this.createLinkDefinition(
      resolvedUrl,
      resolvedLabel,
      definition.target,
      definition?.isTriggerUrl
    );
  }

  createLinkDefinition(
    resolvedUrl: string,
    label: string,
    target = '',
    isTriggerUrl = false
  ): LinkDefinition {
    const { isInternalUrl, isTargetSelf, isTargetClipboard } = this.determineLinkProperties(
      resolvedUrl,
      target
    );
    const considerLinkInternal = this.shouldConsiderLinkInternal(
      isInternalUrl,
      isTargetSelf,
      isTriggerUrl
    );
    const internalUrl = this.getInternalUrl(isTargetSelf, isInternalUrl, resolvedUrl);

    resolvedUrl =
      this.initialPrefixUrl && !isTriggerUrl ? this.applyInitialPrefix(resolvedUrl) : resolvedUrl;

    const url = considerLinkInternal ? internalUrl || '' : resolvedUrl;
    const { queryParams, hasQueryParams, urlWithoutQueryParams } =
      this.parseUrlWithQueryParams(url);

    const callback = this.getLinkDefinitionCallback(
      isTargetClipboard,
      resolvedUrl,
      isTargetSelf,
      internalUrl
    );

    return {
      isCopyToClipboard: isTargetClipboard,
      isFileLink: this.isFileLink(resolvedUrl),
      url,
      urlWithoutQueryParams,
      queryParams,
      hasQueryParams,
      label: label || resolvedUrl,
      target: isTargetSelf ? '_self' : '_blank',
      isInternal: considerLinkInternal,
      fullUrl: resolvedUrl,
      internalUrl,
      targetUrl: resolvedUrl,
      callback
    };
  }

  isFileLink(url: string) {
    return FILE_LINK_REGEX.test(url);
  }

  openLink(linkDefinition: LinkDefinition) {
    if (linkDefinition.isInternal && linkDefinition.hasQueryParams) {
      this.router.navigate([linkDefinition.urlWithoutQueryParams], {
        queryParams: linkDefinition.queryParams
      });
    } else if (linkDefinition.isInternal && !linkDefinition.hasQueryParams) {
      this.router.navigate([linkDefinition.url]);
    } else if (!linkDefinition.isInternal && linkDefinition.target === '_self') {
      this.window.location.href = linkDefinition.url;
    } else {
      this.window.open(linkDefinition.url, linkDefinition.target);
    }
  }

  createDeviceLink(device: Device) {
    const projectBaseUrl =
      window.location.origin + this.location.prepareExternalUrl(this.projectUrlPipe.transform(''));
    const deviceLink = `${Constants.routing.deviceType}/${device.getType()}/${device.thingId}`;
    return `${projectBaseUrl}${deviceLink}`;
  }

  private determineLinkProperties(resolvedUrl: string, target: string): LinkProperties {
    return {
      isInternalUrl: this.checkIfInternalUrl(resolvedUrl),
      isTargetSelf: this.isHyperlinkTargetSelf(target.toLowerCase()),
      isTargetClipboard: this.isHyperlinkTargetClipboard(target.toLowerCase())
    };
  }

  /**
   * Checks if the provided URL is internal, i.e., from the same origin as the current location.
   *
   * @param url - The URL to be checked for being internal.
   *
   * @example
   * Returns true:
   *   - Current location: "https://dev0.bosch-iot-insights-azure.com"
   *   - Resolved URL: "https://dev0.bosch-iot-insights-azure.com/ui/project/some-project"
   *
   * Returns false:
   *   - Current location: "https://dev0.bosch-iot-insights-azure.com"
   *   - Resolved URL: "https://bosch-iot-insights-azure.com/ui/project/some-project"
   *
   * @returns {boolean} - True if the URL is internal, false otherwise.
   */
  private checkIfInternalUrl(url: string): boolean {
    const uiPrefix = this.location.prepareExternalUrl('/');
    const hostUrl = this.document.location.host + uiPrefix;

    return url && (url.indexOf(hostUrl) >= 0 || url.indexOf(uiPrefix) === 0);
  }

  /**
   * Determines whether the link should open in the same tab ("self") or a new tab ("blank").
   *
   * @param target - The target attribute value for the link.
   *
   * @example
   * Returns true:
   *   - Target: "_self" or "self"
   *
   * Returns false:
   *   - Target: "_blank" or "blank"
   *
   * @returns {boolean} - True if the target is set to open in the same tab, false for a new tab.
   */
  private isHyperlinkTargetSelf(target: string): boolean {
    return (
      target.toLowerCase() === HyperlinkTarget.SELF ||
      target.toLowerCase() === `_${HyperlinkTarget.SELF}`
    );
  }

  /**
   * Determines whether to use Angular's internal routing for the link.
   *
   * @returns {boolean} - True if Angular's routing should be used, false otherwise.
   */
  private shouldConsiderLinkInternal(
    isInternalUrl: boolean,
    isTargetSelf: boolean,
    isTriggerUrl: boolean
  ): boolean {
    return isTargetSelf && isInternalUrl && (!this.initialPrefixUrl || isTriggerUrl);
  }

  private isHyperlinkTargetClipboard(target: string): boolean {
    return target.toLowerCase() === HyperlinkTarget.CLIPBOARD;
  }

  private getInternalUrl(
    isTargetSelf: boolean,
    isInternalUrl: boolean,
    resolvedUrl: string
  ): string | null {
    const uiPrefix = this.location.prepareExternalUrl('/');
    return isTargetSelf && isInternalUrl
      ? resolvedUrl.substring(resolvedUrl.indexOf(uiPrefix) + uiPrefix.length - 1)
      : null;
  }

  private parseUrlWithQueryParams(url: string): ParsedUrlResult {
    const parsedUrl = this.router.parseUrl(url);
    const queryParams = parsedUrl.queryParams;
    const hasQueryParams = Object.keys(queryParams).length !== 0;
    const urlWithoutQueryParams = hasQueryParams ? url.split('?')[0] : undefined;

    return { queryParams, hasQueryParams, urlWithoutQueryParams };
  }

  private getLinkDefinitionCallback(
    isCopyToClipboard: boolean,
    clipboardValue: string,
    isTargetSelf: boolean,
    internalUrl: string
  ): (e: Event) => void {
    if (isCopyToClipboard) {
      return async (e: Event) => {
        e.preventDefault();

        await navigator.clipboard.writeText(clipboardValue);
      };
    }
    return (e: Event) => {
      if (this.initialPrefixUrl && isTargetSelf && internalUrl) {
        e.preventDefault();
        this.router.navigateByUrl(internalUrl);
      }
    };
  }

  private applyInitialPrefix(resolvedUrl: string): string {
    const dashboardName = getDashboardNameFromPath(resolvedUrl);
    const queryParams = getUrlParamsFromPath(resolvedUrl);
    const isSameProjectUrl =
      getProjectFromPath(this.document.location.href) === getProjectFromPath(resolvedUrl);
    if (isSameProjectUrl && dashboardName) {
      const url = this.initialPrefixUrl + dashboardName + queryParams;
      return this.sanitizer.sanitize(
        SecurityContext.RESOURCE_URL,
        this.sanitizer.bypassSecurityTrustResourceUrl(url)
      );
    }
    return resolvedUrl;
  }
}
