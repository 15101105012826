import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataSelectorInputComponent } from './data-selector-input/data-selector-input.component';
import { FormsModule } from '@angular/forms';
import { FormFieldsModule } from '@inst-iot/bosch-angular-ui-components';
import { DataStructureSelectorComponent } from './data-structure-selector/data-structure-selector.component';
import { SharedModule } from '../../shared/shared.module';
import { DataTooltipSelectorComponent } from './data-tooltip-selector/data-tooltip-selector.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ValueOrReferenceInputComponent } from './value-or-reference-input/value-or-reference-input.component';
import { ColorSelectorModule } from '../color-picker/color-selector/color-selector.module';
import { StyleSelectionComponent } from './style-selection/style-selection.component';

@NgModule({
  declarations: [
    DataSelectorInputComponent,
    DataStructureSelectorComponent,
    DataTooltipSelectorComponent,
    ValueOrReferenceInputComponent,
    StyleSelectionComponent
  ],
  imports: [
    CommonModule,
    ColorSelectorModule,
    FormsModule,
    FormFieldsModule,
    SharedModule,
    DragDropModule
  ],
  exports: [
    DataSelectorInputComponent,
    DataStructureSelectorComponent,
    DataTooltipSelectorComponent,
    ValueOrReferenceInputComponent,
    StyleSelectionComponent
  ]
})
export class DataSelectionModule {}
