import { InputData } from '../../shared/api-model';
import { Constants } from '../../../constants';

export class InputDataDocument {
  collection: string;
  inputData: InputData;
  downloadable?: boolean;

  constructor(collection = '', inputData: InputData = {}) {
    this.collection = collection;
    this.inputData = inputData;
  }

  isInObjectStorage(): boolean {
    return !!this.inputData.payloadRef;
  }

  isArchived(): boolean {
    return !!this.inputData.archivedAt;
  }

  isDeleted(): boolean {
    return !!this.inputData.deletedAt;
  }

  isArchivable(): boolean {
    return this.isInObjectStorage() && !this.isArchived() && this.downloadable && !this.isDeleted();
  }

  isReinstatable(ignoreDelay: boolean): boolean {
    return (
      this.isInObjectStorage() &&
      this.isArchived() &&
      (ignoreDelay || this.getDaysLeftBeforeReinstatability() <= 0) &&
      !this.isDeleted()
    );
  }

  getDaysLeftBeforeReinstatability(): number {
    if (!this.inputData.archivedAt) {
      return 0;
    }
    const reinstatabilityDate: Date = this.getReinstatabilityDate();
    const now = new Date();

    const msLeft = reinstatabilityDate.getTime() - now.getTime();

    if (msLeft < 0) {
      return 0;
    }

    // Ceil days result to 1 decimal place
    return Math.ceil(msLeft * Constants.msToDays * 10) * 0.1;
  }

  getReinstatabilityDate(): Date {
    if (!this.inputData.archivedAt) {
      return null;
    }
    const archivedAt: Date = new Date(Date.parse(this.inputData.archivedAt));
    return new Date(archivedAt.getTime() + Constants.archiving.minArchivingDurationMs);
  }
}
