import { DatePipe } from '@angular/common';
import {
  DateRangePickerPreset,
  getRelativeInfo,
  isAbsolute,
  isRelative,
  PickerValue,
  StateValues
} from '@inst-iot/bosch-angular-ui-components';
import * as moment from 'moment';
import { DateTimeRangePickerOptions } from '../../shared/date-time-range-picker/date-time-range-picker.component';
import { DateTimeRange } from '../../shared/date-time-range-picker/date-time-range-picker.model';
import { BaseFieldDefinition, FieldSelectOption } from '../../shared/filter-model/filter.model';
import {
  commonParamsConverter,
  defaultParamsConverter
} from '../../shared/filter-model/filter.utils';
import { parseIfJSON } from '../query-condition-input/utils/query-assembler.util';
import { mapQueryToDisplayText } from '../query-condition-input/utils/query-condition.util';
import { translate } from '../../shared/translation-util';

export type FieldType =
  | 'checkbox'
  | 'custom'
  | 'date'
  | 'datetime'
  | 'datetimerange'
  | 'datetimerange2'
  | 'device'
  | 'multi-selection'
  | 'multi-selection-opt'
  | 'number'
  | 'queryCondition'
  | 'selection'
  | 'singledatetime'
  | 'text';

export const DATE_RANGE_PICKER_PRESETS: DateRangePickerPreset[] = [
  {
    label: `${translate('timeRangePicker.absoluteLast24h')}`,
    absoluteRange: [lastXISoString(-86400000), lastXISoString(0)]
  },
  {
    label: `${translate('timeRangePicker.absoluteLast48h')}`,
    absoluteRange: [lastXISoString(-86400000 * 2), lastXISoString(0)]
  },
  {
    label: `${translate('timeRangePicker.absoluteLast7Days')}`,
    absoluteRange: [lastXISoString(-86400000 * 7), lastXISoString(0)]
  },
  {
    label: translate('timeRangePicker.last1h'),
    relativeRange: [-3600000, 0]
  },
  {
    label: translate('timeRangePicker.last12h'),
    relativeRange: [-3600000 * 12, 0]
  },
  {
    label: translate('timeRangePicker.last24h'),
    relativeRange: [-86400000, 0]
  },
  {
    label: translate('timeRangePicker.last48h'),
    relativeRange: [-86400000 * 2, 0]
  },
  {
    label: translate('timeRangePicker.last7Days'),
    relativeRange: [-86400000 * 7, 0]
  },
  {
    label: translate('timeRangePicker.last14Days'),
    relativeRange: [-86400000 * 14, 0]
  }
];

export interface FieldDefinition extends BaseFieldDefinition {
  /**
   * Type of the field
   */
  type: FieldType;

  /**
   * Whether the filter can be removed
   */
  required?: boolean;

  /**
   * Whether setting multiple values is allowed, default: false
   */
  multipleValues?: boolean;

  /**
   * Whether it can be used across different filter widgets and dashboards. default: false
   */
  global?: boolean;

  /**
   * Convert the outside value to the inside static filter value
   */
  modelToView?: (model: any) => any;
  /**
   * Convert the value from the static filter to the outside value
   */
  viewToModel?: (value: any) => any;
  /**
   * Convert date picker value to specific user-friendly format
   */
  display?: (...args) => string;

  datePickerConfig?: DateTimeRangePickerOptions;

  [key: string]: any;

  /**
   * Whether the filter should be disabled
   */
  disabled?: boolean;
}

export interface StringDateRange {
  from: string;
  to: string;
}

export interface NumberRange {
  from: number;
  to: number;
}

export class DateTimeRangeField implements FieldDefinition {
  type: FieldType = 'datetimerange';

  datePickerConfig = new DateTimeRangePickerOptions({
    ranges: {
      'Last 24h': [moment().subtract(24, 'hours'), moment()],
      'Last 48h': [moment().subtract(48, 'hours'), moment()],
      'Last 7 days': [moment().subtract(7, 'days'), moment()],
      'Last 30 days': [moment().subtract(30, 'days'), moment()]
    }
  });

  fromParams = commonParamsConverter.range.fromParams;
  toParams = commonParamsConverter.range.toParams;

  constructor(public name: string, public label?: string, public required = false) {}

  modelToView(model: StringDateRange): DateTimeRange {
    return {
      start: moment(model.from),
      end: moment(model.to)
    };
  }

  viewToModel(value: DateTimeRange): StringDateRange {
    return {
      from: value.start.toISOString(),
      to: value.end.toISOString()
    };
  }

  update(range: DateTimeRange, limit: DateTimeRange) {
    const datePickerConfig = new DateTimeRangePickerOptions(this.datePickerConfig);
    datePickerConfig.startDate = range.start;
    datePickerConfig.endDate = range.end;
    if (limit) {
      datePickerConfig.minDate = limit.start;
      datePickerConfig.maxDate = limit.end;
      datePickerConfig.updateStartEndByMinMax();
    } else {
      datePickerConfig.minDate = null;
      datePickerConfig.maxDate = null;
    }
    this.datePickerConfig = datePickerConfig;
  }

  updateMinMaxByRanges(ranges: DateTimeRange[]): DateTimeRange {
    const datePickerConfig = new DateTimeRangePickerOptions(this.datePickerConfig);
    ranges.forEach((range) => {
      if (!datePickerConfig.minDate || range.start.isBefore(datePickerConfig.minDate)) {
        datePickerConfig.minDate = range.start;
      }
      if (!datePickerConfig.maxDate || range.end.isAfter(datePickerConfig.maxDate)) {
        datePickerConfig.maxDate = range.end;
      }
    });
    datePickerConfig.updateStartEndByMinMax();
    this.datePickerConfig = datePickerConfig;
    return {
      start: datePickerConfig.minDate,
      end: datePickerConfig.maxDate
    };
  }
}

export class DateTimeRangeField2 implements FieldDefinition {
  type: FieldType = 'datetimerange2';

  allowRelative = false;

  options = {
    enableTime: false
  };

  units = [
    { value: 1, unit: 'ms', label: translate('relTimeInput.milliseconds') },
    { value: 1000, unit: 's', label: translate('relTimeInput.seconds') },
    { value: 1000 * 60, unit: 'm', label: translate('relTimeInput.minutes') },
    { value: 1000 * 60 * 60, unit: 'h', label: translate('relTimeInput.hours') },
    { value: 1000 * 60 * 60 * 24, unit: 'd', label: translate('relTimeInput.days') },
    { value: 1000 * 60 * 60 * 24 * 7, unit: 'w', label: translate('relTimeInput.weeks') }
  ];

  presets: DateRangePickerPreset[] = DATE_RANGE_PICKER_PRESETS;

  fromParams = defaultParamsConverter.datetimerange.fromParams;
  toParams = defaultParamsConverter.datetimerange.toParams;

  constructor(public name: string, public label?: string, public required = true) {}

  modelToView(model: StringDateRange): PickerValue {
    if (model?.from && typeof model.from === 'string' && model.from.match(/^[+-]?\d{1,20}$/)) {
      return [parseInt(model.from, 10), parseInt(model.to, 10)];
    }
    return [model.from, model.to];
  }

  viewToModel(value: PickerValue): StringDateRange {
    if (isNullOrUndefined(value)) {
      return { from: undefined, to: undefined };
    }
    if (isRelative(value)) {
      return {
        from: value[0] >= 0 ? '+' + value[0] : String(value[0]),
        to: value[1] >= 0 ? '+' + value[1] : String(value[1])
      };
    }
    return {
      from: value[0] as string,
      to: value[1] as string
    };
  }

  display(value: PickerValue | StringDateRange, format: string, datePipe: DatePipe): string {
    value = Array.isArray(value) ? value : this.modelToView(value);
    if (isRelative(value)) {
      const info1 = getRelativeInfo(value[0], this.units);
      const info2 = getRelativeInfo(value[1], this.units);

      return info1.displayText + (info2.count ? ' - ' + info2.displayText : '');
    }
    if (isAbsolute(value)) {
      return datePipe.transform(value[0], format) + ' - ' + datePipe.transform(value[1], format);
    }

    return '';
  }
}

export class MultiSelectArrayField implements FieldDefinition {
  type: FieldType = 'multi-selection';

  selectOptions: FieldSelectOption[] = [];

  fromParams = commonParamsConverter.json.fromParams;
  toParams = commonParamsConverter.json.toParams;

  constructor(public name: string, public label?: string, public required = false) {}

  modelToView(model: string[]): StateValues {
    const stateValues = {};
    model.forEach((key) => {
      stateValues[key] = true;
    });
    return stateValues;
  }

  viewToModel(value: StateValues): string[] {
    if (!value) {
      return null;
    }
    return Object.keys(value).filter((key) => !!value[key]);
  }
}

export class SingleSelectField implements FieldDefinition {
  type: FieldType = 'selection';

  selectOptions: FieldSelectOption[] = [];

  constructor(public name: string, public label?: string, public required = false) {}

  display(value: string, selectOptions: FieldSelectOption[]): string {
    return selectOptions.find((option: FieldSelectOption) => option.value === value)?.label;
  }
}

export class CheckBoxField implements FieldDefinition {
  type: FieldType = 'checkbox';

  fromParams = commonParamsConverter.json.fromParams;

  required = false;

  constructor(public name: string, public label?: string) {}

  modelToView(model: any): boolean {
    return !!model;
  }

  viewToModel(value: any): boolean {
    return !!value;
  }
}

export class NumberRangeField implements FieldDefinition {
  type: FieldType = 'number';

  constructor(public name: string) {}

  modelToView(model: NumberRange): number[] {
    return [model.from, model.to];
  }

  viewToModel(value: number[]): NumberRange {
    if (isNullOrUndefined(value)) {
      return { from: undefined, to: undefined };
    }
    return {
      from: value[0],
      to: value[1]
    };
  }

  display(value: string[]): string {
    if (!value) {
      return '';
    }
    const valMin = parseFloat(value[0]),
      valMax = parseFloat(value[1]),
      minNumeric = !isNaN(valMin),
      maxNumeric = !isNaN(valMax);
    if (minNumeric && !maxNumeric) {
      return '> ' + valMin;
    } else if (!minNumeric && maxNumeric) {
      return '< ' + valMax;
    } else if (minNumeric && maxNumeric) {
      return `${valMin} - ${valMax}`;
    }
    return '';
  }
}

export class TextField implements FieldDefinition {
  type: FieldType = 'text';

  constructor(public name: string) {}

  display(value: any) {
    return value;
  }
}

export class QueryConditionField implements FieldDefinition {
  type: FieldType = 'queryCondition';

  constructor(public name: string) {}

  display(value: any) {
    const query = parseIfJSON(value);
    return mapQueryToDisplayText(query);
  }

  viewToModel(query: Record<string, string>): string {
    return JSON.stringify(query, null, 0);
  }

  modelToView(queryAsString: string): string | object {
    const parsedQuery = parseIfJSON(queryAsString);
    if (typeof parsedQuery === 'string') {
      return {};
    } else {
      return parsedQuery;
    }
  }
}

export class DeviceField implements FieldDefinition {
  type: FieldType = 'device';
  deviceTypes: string[];
  valuePath: string;
  filter: string;
  propertyPath: string;

  constructor(public name: string) {}
}

export class SingleDateTimeField implements FieldDefinition {
  type: FieldType = 'singledatetime';

  datePickerConfig = new DateTimeRangePickerOptions({
    singleDatePicker: true
  });

  constructor(public name: string, public label?: string, public required = false) {}

  modelToView(model: StringDateRange): DateTimeRange {
    return {
      start: moment(model.from),
      end: null
    };
  }

  viewToModel(value: DateTimeRange): StringDateRange {
    if (isNullOrUndefined(value)) {
      return { from: undefined, to: undefined };
    }
    const newDate = moment(value.start);
    return {
      from: value.start.toISOString(),
      to: newDate.add(1, 'hour').toISOString()
    };
  }

  update(range: DateTimeRange, limit: DateTimeRange) {
    return DateTimeRangeField.prototype.update.call(this, range, limit);
  }

  fromParams(params: Record<string, string>): StringDateRange {
    if (params[this.name]) {
      return {
        from: params[this.name],
        to: moment(params[this.name]).add(1, 'hour').toISOString()
      };
    }
    return undefined;
  }

  toParams(value: StringDateRange): Record<string, string> {
    const params = {};
    params[this.name] = value.from;
    return params;
  }
}

function lastXISoString(addMs: number) {
  const currentMinute = Math.ceil(new Date().getTime() / 60000) * 60000;
  return new Date(currentMinute + addMs).toISOString();
}

function isNullOrUndefined(value) {
  return value === null || value === undefined;
}
