import { ALL_AUTH_PROVIDERS, AuthProvider } from './auth-provider';
import { Constants } from '../../../../constants';

const INSIGHTS_IMAGE = 'assets/img/insights-background.jpg';
export const DEFAULT_BACKGROUND: Record<AppThemeName, string> = {
  CUSTOM: INSIGHTS_IMAGE,
  INSIGHTS: INSIGHTS_IMAGE,
  ETAS: 'linear-gradient(to right, #164293, #89037a)'
};

export type AppThemeName = 'INSIGHTS' | 'ETAS' | 'CUSTOM';

export interface AppTheme {
  theme: AppThemeName;
  themeLogo: string;
  themeLogoUrl: string;
  backgroundImage: string;
  productName: string;
  logoutUrl: string;
  supportLink: string;
  companyName: string;
  companyUrl: string;
  providerSuffix: string;
  authenticationProviders: AuthProvider[];
  newsLink: string;
  helpLink: string;
  apiDocsLink: string;
}

export class Theme implements AppTheme {
  theme: AppThemeName;
  themeLogo: string;
  themeLogoUrl: string;
  backgroundImage: string;
  productName: string;
  logoutUrl: string;
  supportLink: string;
  companyName: string;
  companyUrl: string;
  providerSuffix: string;
  authenticationProviders: AuthProvider[];
  newsLink: string;
  helpLink: string;
  apiDocsLink: string;

  constructor(_theme: Partial<AppTheme> = {}) {
    Object.assign(this, _theme);
    return this;
  }

  isEtasTheme(): boolean {
    return this.theme === 'ETAS';
  }
}

export const INSIGHTS_THEME: Theme = new Theme({
  theme: 'INSIGHTS',
  themeLogo: '',
  themeLogoUrl: '',
  backgroundImage: `url(${DEFAULT_BACKGROUND['INSIGHTS']})`,
  productName: 'Bosch IoT Insights',
  logoutUrl: '/auth/start/endsession',
  supportLink: 'https://bosch-iot-insights.atlassian.net/servicedesk/customer/portal/2',
  companyName: 'Bosch.IO',
  companyUrl: 'https://www.bosch.io',
  providerSuffix: 'insights',
  authenticationProviders: Object.values(ALL_AUTH_PROVIDERS),
  newsLink: 'https://www.bosch-iot-suite.com/news/',
  helpLink: Constants.routing.help
});

export const ETAS_THEME: Theme = new Theme({
  theme: 'ETAS',
  themeLogo: 'assets/img/etas-logo.svg',
  themeLogoUrl: 'https://www.etas.com',
  backgroundImage: DEFAULT_BACKGROUND['ETAS'],
  productName: 'Data Insights',
  logoutUrl: '/auth/start/endsession',
  supportLink: 'https://documentation.pantaris.io/support/contact',
  companyName: 'ETAS',
  companyUrl: 'https://www.etas.com',
  providerSuffix: 'etas',
  authenticationProviders: Object.values(ALL_AUTH_PROVIDERS),
  newsLink: 'https://www.bosch-iot-suite.com/news/',
  helpLink: Constants.routing.help
});

export const CUSTOM_DOMAIN_THEME: Theme = new Theme({
  theme: 'CUSTOM',
  providerSuffix: 'insights',
  logoutUrl: '/auth/start/endsession',
  authenticationProviders: Object.values(ALL_AUTH_PROVIDERS),
  newsLink: INSIGHTS_THEME.newsLink,
  supportLink: INSIGHTS_THEME.supportLink,
  helpLink: INSIGHTS_THEME.helpLink
});

// Keep this one even if it seems unused - it is needed to test the ETAS theme locally
// We might implement a flag or so to switch, but for now it does the job
export const LOCAL_ETAS_THEME: Theme = new Theme({
  ...ETAS_THEME,
  providerSuffix: INSIGHTS_THEME.providerSuffix
});
