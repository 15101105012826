import { Component } from '@angular/core';
import { UserAuthService } from '../../core/services/user-auth.service';

@Component({
  selector: 'domain-project-no-access',
  templateUrl: './domain-project-no-access.component.html'
})
export class DomainProjectNoAccessComponent {
  constructor(private userAuthService: UserAuthService) {}

  logout() {
    this.userAuthService.logout();
  }
}
