import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Params } from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  merge,
  MonoTypeOperatorFunction,
  Observable,
  of,
  ReplaySubject,
  Subject,
  Subscriber,
  Subscription
} from 'rxjs';
import { delay, filter, map, shareReplay, startWith, takeUntil } from 'rxjs/operators';
import {
  DataSource,
  FilterParams,
  InputParams,
  QueryTemplateDataSource
} from '../../shared-modules/data-widgets/data-widgets-common';
import { RequestStatusComposite } from '../../shared/api-model';
import { WidgetTypeDefinition } from '../models/widget-registry.model';
import { LayoutBehavior } from '../models/DashboardWidgetConfig';

/**
 * Provided by the DashboardWidgetComponent for every widget instance.
 * This Service is used as communication line between a widget and its outside world
 */
@Injectable()
export class WidgetInstanceService {
  /**
   * ID of the widget
   */
  id: string;

  dashboardName: string;

  /**
   * Properties of the widget
   */
  properties: any;

  /**
   * Title of the widget after placeholder resolvement
   */
  resolvedTitle: string;

  /**
   * The widget type definition
   */
  widgetType: WidgetTypeDefinition;

  /**
   *
   */
  editing = new Subject<boolean>();

  /**
   *
   */
  sorting = new Subject<boolean>();

  /**
   * Emitted by the widget control when a user clicks the maximize/minimize button
   */
  maximize = new Subject<boolean>();

  /**
   * Emitted by the control widget when a user clicks the reload button
   */
  reload = new Subject<null>();

  /**
   * Emitted by the widget if the loading state has changed
   */
  loading = new BehaviorSubject<boolean>(false);

  /**
   * The auto refreshing state shows if the auto refresh timer is active
   * Emitted by the widget or the control widget if the user wishes to stop or continue.
   */
  autoRefreshing = new BehaviorSubject<boolean>(false);

  /**
   * When an error appears during loading, that is caught
   */
  loadingError = new BehaviorSubject<HttpErrorResponse>(null);

  /**
   * When an error appears during loading, that is caught
   * The loadingError BehaviorSubject is updated only when observable complete
   * This one is updated also on every 'next' call
   */
  activeLoadingErrorSubject = new BehaviorSubject<HttpErrorResponse>(null);

  /**
   * Emits when a new loading context is initiated
   */
  newContext = new Subject<null>();

  /**
   * Will change to true, when the widget is rendered (e.g. on tab change)
   * Never switches back from true to false.
   */
  visible: Observable<boolean>;

  /**
   * Whether auto refreshing is available
   */
  hasAutoRefreshing = false;

  /**
   * When the last loading of data was requested
   */
  lastLoading: Date = null;

  /**
   * When the last data has been received
   */
  lastData: Date = null;

  /**
   * When the last data, that was received has been generated
   */
  lastDataGenerated: Date = null;

  queryParams: Observable<Params>;

  /**
   * Parameters supplied by a singleton filter widget
   */
  filterParams: Observable<FilterParams>;

  /**
   * Parameters supplied by a singleton input widget
   */
  inputFields: Observable<InputParams>;

  /**
   * If the widget is inside of a tab-widget this is the corresponding tabId. Can be null.
   */
  tabId: string;

  /**
   * Data loading priority of the widget
   */
  priority: number;

  /**
   * If the widget is inside of a tab-widget this is the current selected tabId. Can be null.
   * The Subject instance has to be shared by the tab-widget.component to its underlying components.
   */
  currentTabId$: Observable<string>;

  /**
   * Value is update when polling for query-template DataSource status
   */
  queryStatus: RequestStatusComposite;

  /**
   * Used in case of dashboard grid layout. Mainly to resize maps/charts when the widget grid dimensions are changed.
   */
  containerResized = new Subject<string>();

  /**
   * Used to track the data loaded status
   */
  dataLoaded = new BehaviorSubject<boolean>(false);

  /**
   * Used to track the rendered state for a widget. Even when the data is loaded,
   * some widgets require additional async operations till they are rendered(check tree widget)
   * The rendered observable here combines dataLoaded and additional observable - passed to the trackLoading fn when widget instance is initialized
   * If both are true, the widget is rendered. In case no additional observable is passed, only dataLoaded is considered.
   */
  rendered = new ReplaySubject<boolean>(1);

  /**
   * Available for grid layouts only. Can be missing if the widget was created before introducing the layoutBehavior option.
   */
  layoutBehavior: LayoutBehavior;

  parentWidgetInstance?: WidgetInstanceService;

  constructor(@Optional() @SkipSelf() parentWidgetInstance?: WidgetInstanceService) {
    if (parentWidgetInstance?.currentTabId$) {
      this.parentWidgetInstance = parentWidgetInstance;
      this.currentTabId$ = parentWidgetInstance.currentTabId$;
      this.visible = this.currentTabId$.pipe(
        map((tabId) => tabId === this.tabId),
        startWith(false),
        shareReplay(1)
      );
    } else {
      this.visible = new BehaviorSubject(true);
    }
  }

  get hasDataSource() {
    return Boolean(this.properties.dataSourceConfig?.sources?.length);
  }

  initAutoRefreshing() {
    this.hasAutoRefreshing = true;
    this.autoRefreshing.next(true);
  }

  trackLoadingStart<T>(): MonoTypeOperatorFunction<T> {
    return (source: Observable<T>) =>
      new Observable((observer: Subscriber<T>) => {
        this.loading.next(true);
        return source.subscribe(observer);
      });
  }

  trackQueryStatus<T>(dataSources: DataSource[]): MonoTypeOperatorFunction<T> {
    const queryTemplateSources = dataSources
      .filter((source: DataSource) => source instanceof QueryTemplateDataSource)
      .map((source: QueryTemplateDataSource) => source.queryStatus);

    return (source: Observable<T>) =>
      new Observable((observer: Subscriber<T>) => {
        const sub = merge(...queryTemplateSources).subscribe((source: RequestStatusComposite) => {
          this.queryStatus = source;
        });
        return source.subscribe(observer).add(sub);
      });
  }

  trackRendering(widgetRenderedState: Observable<boolean> = of(true)) {
    combineLatest([this.dataLoaded, widgetRenderedState])
      .pipe(
        delay(0),
        map(([dataLoaded, widgetRenderedState]) => {
          if (this.hasDataSource) {
            return dataLoaded && widgetRenderedState;
          }

          return widgetRenderedState;
        }),
        filter((rendered) => rendered),
        takeUntil(this.rendered)
      )
      .subscribe((rendered) => {
        this.rendered.next(rendered);
      });
  }

  trackLoading<T>(): MonoTypeOperatorFunction<T> {
    this.lastData = null;
    this.lastDataGenerated = null;
    this.newContext.next(null);
    return (source: Observable<T>) =>
      new Observable((observer: Subscriber<T>) => {
        this.loading.next(true);
        this.lastLoading = new Date();
        let done = false;
        return source.subscribe({
          next: (value) => {
            if (!done) {
              this.loading.next(false);
              this.dataLoaded.next(true);
              done = true;
            }
            this.lastData = new Date();
            observer.next(value);
          },
          error: (err) => {
            this.loading.next(false);
            this.dataLoaded.next(true);
            observer.error(err);
          },
          complete: () => {
            this.loading.next(false);
            this.dataLoaded.next(true);
            observer.complete();
          }
        });
      });
  }

  /**
   * Operator to run the auto refreshing mechanism on a Observable
   * @param interval in seconds
   * @param catchError if you catch errors, subscribe to the loadingError subject to get notified
   */
  runRefreshing<T>(interval?: number, catchError = true): MonoTypeOperatorFunction<T> {
    const loadingErrorSubject = this.loadingError;
    const activeLoadingErrorSubject = this.activeLoadingErrorSubject;

    // eslint-disable-next-line sonarjs/cognitive-complexity
    return (source: Observable<T>) =>
      new Observable((observer: Subscriber<T>) => {
        let loadSub: Subscription = null;
        let autoRefreshing = false;
        let timeout = null;
        let initial = true;

        // Triggered when User clicks on Widget Control Refresh Button
        const reloadSub = this.reload.subscribe(() => {
          runLoad();
        });

        const autoRefreshingSub = this.autoRefreshing.subscribe((state) => {
          if (autoRefreshing && !state) {
            stopLoad();
          } else if (state || initial) {
            runLoad();
            initial = false;
          }
          autoRefreshing = state;
        });

        function stopLoad() {
          if (loadSub) {
            loadSub.unsubscribe();
          }
          if (timeout) {
            clearTimeout(timeout);
          }
        }

        function runLoad() {
          stopLoad();

          const start = new Date().getTime();
          loadSub = source.subscribe({
            next: (value) => {
              if (catchError && activeLoadingErrorSubject.value) {
                activeLoadingErrorSubject.next(null);
              }
              observer.next(value);
            },
            error: (err) => {
              loadingErrorSubject.next(err);
              activeLoadingErrorSubject.next(err);
              if (catchError) {
                scheduleNextRun(start);
              } else {
                observer.error(err);
              }
            },
            complete: () => {
              if (catchError && loadingErrorSubject.value) {
                loadingErrorSubject.next(null);
              }
              scheduleNextRun(start);
            }
          });
        }

        function scheduleNextRun(start: number) {
          const timePassed = new Date().getTime() - start;
          const scheduledTimeout = interval * 1000 - timePassed;
          if (autoRefreshing) {
            const time = Math.max(1000, scheduledTimeout);
            timeout = setTimeout(() => {
              runLoad();
            }, time);
          }
        }

        return () => {
          reloadSub.unsubscribe();
          autoRefreshingSub.unsubscribe();
          stopLoad();
        };
      });
  }
}
