import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ConditionChip } from '../models/condition-chip.model';
import { TranslateService } from '@ngx-translate/core';
import { DateFormatType } from '../../data-conditional-formatting/data-conditional-formatting.model';
import { DatePipe } from '../../../shared/pipes/date.pipe';

@Component({
  selector: 'filter-chip',
  templateUrl: './filter-chip.component.html',
  styleUrls: ['./filter-chip.component.scss']
})
export class FilterChipComponent implements OnChanges {
  isChipPristine = true;

  @Input() chip: ConditionChip;
  @Input() showLabels: boolean;
  @Input() focused = false;
  @Input() isSelectable = true;
  @Input() isChipSelected: boolean;
  @Input() isChipDeletable = true;

  @Output() chipSelected = new EventEmitter();
  @Output() chipRemoved = new EventEmitter();

  constructor(private translateService: TranslateService, private datePipe: DatePipe) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.focused?.firstChange && !this.focused) {
      this.isChipPristine = false;
    }
  }

  selectChip() {
    this.chipSelected.emit();
  }

  removeChip() {
    this.chipRemoved.emit();
  }

  toString(chip: ConditionChip): string {
    let inputLabel = chip.i18nLabel ? this.translateService.instant(chip.i18nLabel) : chip.input;
    inputLabel += chip.fieldName ? ` (${chip.fieldName})` : '';

    return inputLabel + ' ' + chip.operator.visualID + ' ' + this.getValueAsString(chip);
  }

  private getValueAsString(chip: ConditionChip) {
    if (chip.type === 'range') {
      return `${chip.value?.from} - ${chip.value?.to}`;
    }
    if (chip.type === 'datasetRequiredId' || chip.type === 'dataset') {
      const valueId = chip.value?.id ? ` : ${chip.value?.id}` : '';
      return `${chip.value?.collection}` + valueId;
    }
    if (chip.type === 'date') {
      return chip.value ? this.datePipe.transform(chip.value, DateFormatType.MEDIUM) : '';
    }
    if (chip.type === 'objectId') {
      return typeof chip.value === 'string' || typeof chip.value === 'number'
        ? chip.value
        : JSON.stringify(chip);
    }
    if (chip.type === 'json') {
      return typeof chip.value === 'string' ? chip.value : JSON.stringify(chip.value);
    }
    if (chip.type === 'datetimerange') {
      const dateFormat =
        chip.input === 'lastSeenAt' && chip.searchType === 'filter'
          ? DateFormatType.MEDIUM_DATE
          : DateFormatType.MEDIUM;
      return `${this.datePipe.transform(chip.value[0], dateFormat)} - ${this.datePipe.transform(
        chip.value[1],
        dateFormat
      )}`;
    }
    if (chip.type === 'selection' || chip.type === 'boolean-selection-with-exists') {
      return chip.valueLabel ? this.translateService.instant(chip.valueLabel) : chip.value;
    }
    return chip.value;
  }
}
