<div class="rb-custom-input not-empty">
  <div class="input-wrapper pt-3">
    <div class="label">
      <rb-render-tpl [tpl]="label"></rb-render-tpl>
    </div>
  </div>
</div>
<div class="mb-3 pl-3 left-border">
  <div
    *ngFor="let entry of list; let i = index"
    class="d-flex justify-content-start align-items-center"
  >
    <value-input
      [dataType]="parameter.dataType"
      [label]="'queryTemplate.entryNum' | translate: { num: i }"
      [validationRegExp]="parameter.valueRegex"
      [required]="!parameter.optional"
      [(value)]="entry.value"
      style="flex-grow: 1"
    ></value-input>
    <button type="button" class="rb-btn rb-tiny" (click)="remove(i)">
      <span class="rb-ic rb-ic-close"></span>
    </button>
  </div>
  <button type="button" class="rb-btn rb-tiny" (click)="add()">
    <span class="rb-ic rb-ic-add"></span>
    <span class="vlign-icons">{{ 'queryTemplate.addNewEntry' | translate }}</span>
  </button>
</div>
