import { Component, ElementRef, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FullscreenService } from '../services/fullscreen.service';

@Component({ template: '' })
export abstract class FullscreenBaseComponent implements OnDestroy {
  destroy = new Subject<null>();

  constructor(private fullscreen: FullscreenService, private elementRef: ElementRef) {
    this.fullscreen.isActive.pipe(takeUntil(this.destroy)).subscribe((isFullscreen: boolean) => {
      if (isFullscreen) {
        this.elementRef.nativeElement.classList.add('fullscreen');
      } else {
        this.elementRef.nativeElement.classList.remove('fullscreen');
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy.next(null);
  }
}
