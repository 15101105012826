import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { ProjectUrlPipe } from '../../shared-projects/pipes/project-url.pipe';
import { DashboardWidgetConfig, LayoutBehavior } from '../models/DashboardWidgetConfig';
import { WidgetTypeDefinition } from '../models/widget-registry.model';
import { DashboardDataService } from '../services/dashboard-data.service';
import { DashboardGridService } from '../services/dashboard-grid.service';
import { WidgetInstanceService } from '../services/widget-instance.service';
import { WidgetVisibilityService } from '../services/widget-visibility.service';
import { WidgetsRegistryService } from '../services/widgets-registry.service';

@Component({
  selector: 'dashboard-widget',
  templateUrl: './dashboard-widget.component.html',
  styleUrls: ['./dashboard-widget.component.scss'],
  providers: [WidgetInstanceService, WidgetVisibilityService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardWidgetComponent implements OnInit, OnDestroy {
  @Input() set tabId(tabId: string) {
    this.widgetInstance.tabId = tabId;
  }

  @Input() set canEdit(editing: boolean) {
    this.editing = editing;
    this.widgetInstance.editing.next(editing);
  }

  @Input() set isSorting(sorting: boolean) {
    this.sorting = sorting;
    this.widgetInstance.sorting.next(sorting);
  }

  @Input() widget: DashboardWidgetConfig;

  @Input()
  set widgetResized(obs: Subject<string>) {
    if (!obs || this.widgetResizedSub) {
      return;
    }
    this.widgetResizedSub = obs
      .pipe(
        filter((widgetId) => widgetId === this.widget.id),
        takeUntil(this.destroy)
      )
      .subscribe({ next: () => this.widgetInstance.containerResized.next(this.widget.id) });
  }

  @Output() maximize = new EventEmitter<boolean>();

  @ViewChild('widgetContent', { static: true }) widgetContent: ElementRef;

  editing = false;

  sorting = false;

  componentRef: ComponentRef<any>;

  widgetType: WidgetTypeDefinition;

  destroy = new Subject<null>();

  widgetVisible = new Subject<null>();

  error = null;

  mouseIn = false;

  isGridLayout: boolean;

  private widgetResizedSub: Subscription;

  constructor(
    public dashboardsService: DashboardDataService,
    public widgetInstance: WidgetInstanceService,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private widgetRegistry: WidgetsRegistryService,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private projectUrl: ProjectUrlPipe,
    private dashboardGridService: DashboardGridService,
    private widgetVisibilityService: WidgetVisibilityService
  ) {}

  @HostListener('mouseenter', ['$event']) onMouseEnter(event: any) {
    event.stopPropagation();
    this.mouseIn = true;
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.mouseIn = false;
  }

  ngOnInit() {
    this.setWidgetType();
    this.isGridLayout = this.dashboardsService.isGridLayout();
    this.initWidgetInstance();
  }

  ngOnDestroy(): void {
    this.destroy.next(null);
    this.widgetResizedSub?.unsubscribe();
    if (this.componentRef) {
      const index = this.viewContainerRef.indexOf(this.componentRef.hostView);
      if (index !== -1) {
        this.viewContainerRef.detach(index);
      }
    }
  }

  setWidgetType(): void {
    this.widgetType = this.widgetRegistry.getTypeDefinition(this.widget.type);
    if (this.dashboardsService.isWidgetVisible(this.widget) && !this.widgetType) {
      this.error = this.widget.type;
    }
  }

  initWidgetInstance(): void {
    this.widgetInstance.filterParams = this.dashboardsService.filterParams;
    this.widgetInstance.inputFields = this.dashboardsService.inputFields;
    this.widgetInstance.queryParams = this.route.queryParams;

    this.widgetInstance.maximize.pipe(takeUntil(this.destroy)).subscribe((maximize) => {
      this.maximize.emit(maximize);
    });

    if (this.dashboardsService.isWidgetVisible(this.widget) && !this.error) {
      this.widgetInstance.id = this.widget.id;
      this.widgetInstance.dashboardName = this.dashboardsService.currentConfig
        ? this.dashboardsService.currentConfig.name
        : '';
      this.widgetInstance.widgetType = this.widgetType;
      this.widgetInstance.layoutBehavior = this.widget.layoutBehavior || LayoutBehavior.Adapt;
      this.widgetInstance.properties = this.widget.properties;
      this.widgetInstance.priority = this.dashboardsService.calculateWidgetPriority(this.widget);

      this.loadWidget(this.widgetType);
    }
  }

  openEditWidget() {
    this.projectUrl.navigate(this.dashboardsService.getEditWidgetDashboardUrl(this.widget.id));
  }

  getWidgetContainerHeight() {
    if (this.dashboardsService.isSpecificWidgetRoute) {
      return '100%';
    }
    return this.isGridLayout && this.dashboardsService.isWidgetVisible(this.widget)
      ? this.dashboardGridService.getWidgetContainerHeight(
          this.widgetInstance.widgetType,
          this.widgetContent,
          this.widget
        )
      : 'unset';
  }

  loadWidget(widgetType: WidgetTypeDefinition) {
    if (this.widget.properties.lazyLoading) {
      this.lazyLoadWidget(widgetType);
    } else {
      this.createWidget(widgetType);
    }
    this.cd.markForCheck();
  }

  lazyLoadWidget(widgetType: WidgetTypeDefinition) {
    this.widgetVisibilityService
      .elementInSight(this.widgetContent.nativeElement)
      .pipe(
        takeUntil(this.widgetVisible),
        filter((elementVisible) => elementVisible),
        tap(() => {
          this.createWidget(widgetType);
          this.widgetVisible.next(null);
        })
      )
      .subscribe(() => this.cd.markForCheck());
  }

  private createWidget(widgetType: WidgetTypeDefinition) {
    this.componentRef = this.viewContainerRef.createComponent(widgetType.component, {
      injector: this.injector
    });
    this.widgetInstance.trackRendering(this.componentRef.instance.widgetRenderedState);
    this.widgetContent.nativeElement.appendChild(this.componentRef.location.nativeElement);
  }
}
