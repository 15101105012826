import { InjectionToken, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslatedString } from '../../shared-projects/models/project.model';
import { Device } from './device';
import { DeviceHistoryChange } from './device-history-change';
import {
  CustomFeatureProperty,
  CustomFeaturePropertyType,
  DeviceTypeDefinition,
  DeviceTypeFeature
} from './device-types';
import { ThingFeature, ThingPropertiesUpdate } from './thing';
import { FeatureWithConfig } from '../../dashboards/widgets/device-details-widget/device-details-widget.component';

/**
 * Implemented by components that display the change of a feature
 */
export interface FeatureChangeComponentBase {
  /**
   * The properties of the feature that changed
   */
  change: DeviceHistoryChange;

  /**
   * Name of the changed device, set in case device is linked
   */
  deviceName: string | undefined;

  /**
   * True if change is displayed in the history details view (instead of overview)
   */
  detailsView: boolean;

  deviceType: DeviceTypeDefinition;

  /**
   * The feature definition
   */
  featureDefinition: FeatureDefinition;
}

/**
 * Implemented by components that display a feature
 */
export interface FeatureComponentBase {
  /**
   * The properties the feature is initialized with
   */
  properties: any;

  /**
   * The feature definition
   */
  featureDefinition: FeatureDefinition;

  /**
   * Emits when the properties have been edited by the user.
   * If null is emitted, the feature should be removed.
   */
  propertiesChange?: Observable<ThingPropertiesUpdate>;
  attributesChange?: Observable<any>;

  deviceTypeFeature?: DeviceTypeFeature;

  device?: Device;

  // Used in device history to highlight the changed properties
  historyChangedProperties?: string[];
}

/**
 * Implemented by components that display a feature
 */
export interface AdminFeatureComponentBase {
  /**
   * The feature definition
   */
  featureDefinition: FeatureDefinition;

  /**
   * Optional List of possible properties and their description and default value
   */
  customProperties?: CustomFeatureProperty[];

  /**
   * Custom properties for UI configuration
   */
  properties?: any;

  /**
   * DeviceTypeFeature to check tracking of feature
   */
  deviceTypeFeature: DeviceTypeFeature;

  /**
   * Emits when the properties have been edited by the user.
   * No meaningful value is emitted. The values can be read from the properties.
   */
  propertiesChange?: Observable<any>;
}

export interface DeviceDetailsComponentBase {
  /**
   * The thing feature
   */
  thingFeature: ThingFeature;
  /**
   * The selected thing identifier
   */
  thingId: string;
  /**
   * The feature configuration of the device details widget
   */
  featureWithConfig: FeatureWithConfig;
  /**
   * Flag if editing should be enabled
   */
  isEditable: boolean;
}

export interface FeatureAdminEditorConfig {
  disableConfiguration?: boolean;
  disableMandatoryEditor?: boolean;
  disableUserEditor?: boolean;
  fixedProperties?: {
    name: string;
    type: CustomFeaturePropertyType;
    defaultValue: any;
  }[];
}

/**
 * Provided by a module for the feature
 */
export interface FeatureDefinition {
  /**
   * Identifier of the definition namespace:identifier
   */
  definition: string;

  /**
   * The version that is used for newly created devices
   */
  version: string;

  /**
   * Title to be displayed on the UI for the feature
   */
  title: TranslatedString;
  /**
   * Description that describes the feature
   */
  description: TranslatedString;
  /**
   * Order in which it should be displayed 1(top) - 100(bottom).
   */
  order: number;
  /**
   * The component to be displayed on the details page
   */
  detailsComponent?: Type<FeatureComponentBase>;

  /**
   * Renders the change of a feature. Used in the deviceHistory component.
   */
  changeComponent?: Type<FeatureChangeComponentBase>;

  /**
   * The component to be displayed in the Device Type Feature Definition page
   */
  adminComponent?: Type<AdminFeatureComponentBase>;

  /**
   * The component to be displayed in the Device Details View page.
   */
  detailsViewComponent?: Type<DeviceDetailsComponentBase>;

  /**
   * Default properties when a new feature is added.
   * Can be overwritten by default Properties from DeviceType
   */
  defaultProperties?: { [key: string]: any };

  /**
   * A default name, that is used when the feature is added to a device type definition
   */
  defaultName: string;

  /**
   * Whether this is a virtual feature. Virtual features are not saved in Things and are only available within the UI.
   */
  virtual?: boolean;

  /**
   * Whether it can be added multiple times to a device type
   */
  multiple?: boolean;

  editorConfig?: FeatureAdminEditorConfig;
}

export const FEATURE_DEFINITION = new InjectionToken<Array<FeatureDefinition>>(
  'FeatureDefinitions'
);
