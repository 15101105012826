import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Constants } from '../../../constants';

@Component({
  selector: 'error',
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit {
  error = 'resourceNotFound';
  errorDetail = 'Unknown error';
  timeStamp = '';

  routing = Constants.routing;

  showLogin = false;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      if (params['error']) {
        this.error = params['error'];
        this.updateShowLogin();
      }
      if (Constants.errors[this.error]) {
        this.errorDetail = Constants.errors[this.error];
      }
    });
    const now = new Date();
    this.timeStamp = now.toLocaleString();
  }

  updateShowLogin() {
    this.showLogin = ['loginCheckFailed', 'sessionTimeout'].indexOf(this.error) !== -1;
  }
}
