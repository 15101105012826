import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardLabelService {
  private currentLabel = new BehaviorSubject<string>('');
  currentLabel$ = this.currentLabel.asObservable();

  setCurrentLabel(label: string) {
    this.currentLabel.next(label);
  }
}
