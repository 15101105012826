import { LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FLATPICKR_DEFAULT_OPTIONS } from '@inst-iot/bosch-angular-ui-components';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LocalStorageDirective, NGX_LOCAL_STORAGE_CONFIG } from 'ngx-localstorage';
import { SortablejsModule } from 'ngx-sortablejs-v16';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { FlatpickrOptionsService } from './core/services/flatpickr-options.service';
import { GlobalErrorHandler } from './core/services/global-error.handler';
import { HttpErrorInterceptor } from './core/services/http-error.interceptor';
import { SharedProjectsModule } from './shared-projects/shared-projects.module';
import { EmbeddedLocationStrategy, isInFrame } from './shared/embed-utils';
import { SharedModule } from './shared/shared.module';

export function translateHttpLoaderFactory(http: HttpClient) {
  const metaBuildTime: HTMLMetaElement = document.querySelector('meta[name=build_time]');
  const buildTime = metaBuildTime?.content;
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + buildTime);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    SharedModule,
    SharedProjectsModule,
    SortablejsModule.forRoot({}),
    CoreModule,
    LocalStorageDirective,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: FLATPICKR_DEFAULT_OPTIONS,
      useClass: FlatpickrOptionsService
    },
    {
      provide: NGX_LOCAL_STORAGE_CONFIG,
      useValue: { prefix: 'sfdeUi', storageType: 'localStorage', delimiter: '.' }
    },
    ...(isInFrame() ? [{ provide: LocationStrategy, useClass: EmbeddedLocationStrategy }] : [])
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
