import { Component, OnInit } from '@angular/core';
import { UserAuthService } from '../../core/services/user-auth.service';
import { SystemRole } from '../../core/services/models/system-role';
import { ALL_AUTH_PROVIDERS } from '../../core/services/models/auth-provider';

interface ProjectRoleInfo {
  project: string;
  roles: string[];
}

@Component({
  selector: 'info',
  templateUrl: './info.component.html'
})
export class InfoComponent implements OnInit {
  projects: ProjectRoleInfo[] = [];
  systemRoles: string[] = [];

  systemRole = SystemRole;

  constructor(public authService: UserAuthService) {}

  ngOnInit() {
    this.normalizeData();
  }

  get userEmail(): string {
    return this.authService.userEmail;
  }

  get userName(): string {
    return this.authService.userName;
  }

  get userId(): string {
    return this.authService.userId;
  }

  // the login provider (identity provider for a domain)
  get provider(): string {
    return this.authService.provider;
  }

  // the identity provider of the keycloak user
  get identityProvider(): string {
    return this.authService.identityProvider;
  }

  get identityProviderLabel(): string {
    return ALL_AUTH_PROVIDERS[this.identityProvider]?.label ?? this.identityProvider;
  }

  normalizeData() {
    this.projects = Object.entries(this.authService.projectRoles).map(([project, roles]) => ({
      project,
      roles
    }));
    this.systemRoles = this.authService.systemRoles;
    this.projects.sort((a, b) => {
      if (a.project < b.project) {
        return -1;
      }
      if (a.project > b.project) {
        return 1;
      }
      return 0;
    });
    this.projects.forEach((info) => {
      info.roles.sort();
    });
  }

  login() {
    this.authService.performLogin('/ui/user-internal/info');
  }
}
