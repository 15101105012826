<div class="main-bg p-4">
  <div class="container text-center">
    <h2
      class="mt-3 componentHeader u-TextColor--fuchsia"
      data-cy="projectIncorrectIdentityProviderHeading"
    >
      <span class="rb-ic rb-ic-user-hand-stop d-block no-access-icon"></span>
      {{ 'projectConfig.incorrectIdentityProvider' | translate }}
    </h2>
    <div class="row justify-content-center">
      <div class="col-md-4 col-12">
        <p
          class="mb-4"
          [innerHTML]="
            'projectConfig.identityProviderDescription'
              | translate: { identityProvider: identityProvider | translate }
          "
        ></p>

        <div class="login-provider">
          <a href="#" class="rb-btn rb-primary" (click)="logout()">
            <span class="rb-ic rb-ic-logout mr-2"></span>
            {{ 'nav.user.logout' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
