<span
  (click)="selectChip()"
  [ngClass]="{
    '-selected': isChipSelected,
    'a-chip__error': !chip.isValid && !isChipPristine,
    '-static-content': !isSelectable,
    '-focused': focused
  }"
  class="a-chip -btnClose mw-100 mr-2 my-1"
  role="button"
>
  <ng-container *ngIf="chip.isValid; else invalidChip">
    <span *ngIf="showLabels" class="a-chip__image chipID-{{ chip.index % 8 }}">
      {{ chip.visualIndex }}
    </span>
    <span class="a-chip__label pr-1">
      {{ toString(chip) }}
    </span>
  </ng-container>

  <span *ngIf="isChipDeletable" (click)="removeChip()" class="rb-ic rb-ic-close p-1 mr-2"></span>

  <ng-template #invalidChip>
    <span class="a-chip__label pr-1">Chip {{ chip.visualIndex }}</span>
  </ng-template>
</span>
