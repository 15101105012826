import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { LoadingEntity } from '@inst-iot/bosch-angular-ui-components';
import { User } from '../../../../project-admin/user/models/user.model';
import { UserApiService } from '../../../../project-admin/user/services/user-api.service';
import { PaginationSettingsService } from '../../../../shared/pagination-bar/pagination-settings.service';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { UserAuthService } from '../../../../core/services/user-auth.service';
import { toNumber } from 'lodash-es';
import { Observable, of } from 'rxjs';

interface MinimalUser {
  userId: string;
  email: string;
}

@Component({
  selector: 'user-selector',
  templateUrl: './user-selector.component.html'
})
export class UserSelectorComponent implements OnDestroy, OnChanges {
  @Input() label: string;
  @Input() white = false;
  @Input() prefillCurrentUser = false;
  @Input()
  userListLoader = new LoadingEntity<string[]>();

  @Input() set selectedUser(bookingOwner: MinimalUser) {
    this._user = bookingOwner;
  }

  @Output() userSelected = new EventEmitter();

  userList: MinimalUser[] = [];
  emails: string[] = [];
  userMail = '';
  searchTerm = undefined;
  hasMore = false;
  page = 0;

  private _user: MinimalUser;

  constructor(
    private userApiService: UserApiService,
    private paginationSettings: PaginationSettingsService,
    private userAuthService: UserAuthService
  ) {
    this.paginationSettings.limit = 10;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedUser?.currentValue?.email) {
      this.userMail = changes.selectedUser.currentValue.email;
    }
  }

  ngOnDestroy() {
    this.userListLoader.complete();
  }

  getSearchFunc() {
    return this.search.bind(this);
  }

  search(email: string): Observable<string[]> {
    email = this.prefillCurrentUser ? this.userAuthService.userEmail : email;

    if (email === this.searchTerm) {
      return null;
    }
    this.page = 0;
    this.searchTerm = email;

    return this.userListLoader.run(this.getUsersList(this.page, email));
  }

  getUsersList(pageNumber = 0, searchTerm?: string): Observable<string[]> {
    return this.userApiService
      .getUserList(pageNumber, this.paginationSettings.limit, { nameOrEmail: searchTerm })
      .pipe(
        debounceTime(500),
        map((response: HttpResponse<User[]>) => {
          if (pageNumber === 0) {
            this.userList = [];
          }
          const loadedUserList = response.body.filter((user: User) => !user.technical);
          this.userList.push(...loadedUserList);

          const totalUsers = toNumber(response.headers.get('x-total-count'));
          this.hasMore = (pageNumber + 1) * this.paginationSettings.limit < totalUsers;
          if (this.hasMore && loadedUserList.length === 0) {
            this.loadMore();
          }
          this.emails = this.userList.map((user) => user.email);
          return this.emails;
        }),
        switchMap(() => {
          if (this.prefillCurrentUser) {
            this.prefillCurrentUser = false;

            if (this.isUserAvailable()) {
              this.updateValue(this.searchTerm, true);
              return of(this.emails);
            } else {
              return this.getUsersList(pageNumber, '');
            }
          }

          return of(this.emails);
        })
      );
  }

  isUserAvailable(): boolean {
    return this.emails.length && !!this.emails.find((email) => email === this.searchTerm);
  }

  updateValue(email: string, prefillCurrentUser = false) {
    this.userMail = email;
    // you can have users using the same email(SingleKeyId for example) and this will prevent selecting the wrong one
    if (prefillCurrentUser) {
      this._user = this.userList.find(
        (user) => user.email === email && user.userId === this.userAuthService.userId
      );
    } else {
      this._user = this.userList.find((user) => user.email === email);
    }
    if (this._user) {
      this.userSelected.next(this._user);
    } else {
      this.userSelected.next(null);
    }
  }

  loadMore() {
    this.page++;
    this.userListLoader.run(this.getUsersList(this.page, this.searchTerm)).subscribe();
  }
}
