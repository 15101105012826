import { Location } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingEntity } from '@inst-iot/bosch-angular-ui-components';
import { isEmpty } from 'lodash-es';
import { LocalStorageService } from 'ngx-localstorage';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, first, map, shareReplay, switchMap } from 'rxjs/operators';
import { Constants } from '../../../constants';
import {
  excludeAccessAndCustomRoles,
  ProjectRole,
  RoleName
} from '../../core/services/models/project-role';
import { UserAuthService } from '../../core/services/user-auth.service';
import { GlobalFilterParameters } from '../../dashboards/widgets/filter-widget/filter-widget.model';
import { CustomMenuItemApiModel } from '../../project-admin/custom-menu/models/custom-menu-item.model';
import { UrlSchemeDefinition } from '../../project-admin/project-config/project-security-settings/project-security-settings.component';
import { CustomHomepageContent } from '../../project-admin/white-labeling/custom-homepage-config/custom-homepage-config.model';
import { ObjectAcl } from '../../shared-modules/access-rights/object-acl.model';
import {
  NotificationSettings,
  ObjectAclRes,
  UserRetention,
  UserRetentionResult
} from '../../shared/api-model';
import { defaultPredefinedEventColors, EventColor } from '../../shared/color-utils';
import {
  PayAsYouGoEnableFeatureResult,
  PayAsYouGoFeatureType
} from '../models/pay-as-you-go-features.model';
import {
  InputDataArchivingType,
  NotificationBanner,
  ProjectConfig,
  ProjectUiConfigInfo,
  ProjectViewConfig,
  TranslatedString
} from '../models/project.model';
import { ServicePlanType } from '../models/service-plan.model';
import { transformProjectUrl } from '../../shared/project-url-pipe-util';
import { SubscriptionStatus } from '../../project-admin/subscription-details/subscription-details.model';
import { SystemRole } from '../../core/services/models/system-role';
import { UrlSearchParamMap } from '../../dashboards/models/url-search-param-map';

export interface LatestProjectItem {
  name: string;
  label: TranslatedString;
}

export class LastVisitedPageItem {
  path: string;
  queryParams: string;
  name: string;

  get params() {
    return this.getRouteParams();
  }

  static fromURLString(urlString: string, pageTitle: string): LastVisitedPageItem {
    const url = new URL(urlString, document.location.href);
    return new LastVisitedPageItem({
      path: url.pathname,
      queryParams: url.search,
      name: pageTitle
    });
  }

  static fromStorageEntries(entries: Partial<LastVisitedPageItem>[] = []): LastVisitedPageItem[] {
    return entries.map((entry) => new LastVisitedPageItem(entry));
  }

  constructor(pageItem: Partial<LastVisitedPageItem>) {
    Object.assign(this, pageItem);
  }

  private getRouteParams() {
    const urlSearchParams = new URLSearchParams(this.queryParams);
    return new UrlSearchParamMap(urlSearchParams).toParams();
  }
}

export interface IdentityProvider {
  displayName: string;
  id: string;
  allowed: boolean;
}

export class ProjectConfigEvent {
  constructor(public config: ProjectConfig, public error?: HttpErrorResponse) {}
}

/**
 * Provides access to the currently selected project and the last used projects.
 */
@Injectable({ providedIn: 'root' })
export class ProjectsService {
  get projectConfig(): ProjectConfig | null {
    return this.projectConfigEvents.getValue()?.config ?? null;
  }

  get allowedUrlSchemes() {
    return [
      ...this.defaultUrlSchemes,
      ...(this.projectConfig?.options?.allowedUrlSchemes?.map((s) => s.name) ?? [])
    ];
  }

  get hasCustomDomain(): boolean {
    return !!this.projectConfig?.options?.customDomain;
  }

  get defaultUrlSchemes() {
    return ['http', 'https', 'mailto', 'file'];
  }

  get isReprocessingAllowed(): boolean {
    return !!this.projectConfig?.reprocessingAllowed;
  }

  get isPipelineProject(): boolean {
    return !!this.projectConfig?.options?.enablePipelines;
  }

  get hasObjectStore(): boolean {
    return !!this.projectConfig?.options?.enableObjectStore;
  }

  get isPayAsYouGoPlan(): boolean {
    return this.projectConfig?.plan === ServicePlanType.payAsYouGo;
  }

  get isFreePlan(): boolean {
    return this.projectConfig?.plan === ServicePlanType.free;
  }

  get useDeviceProvisioning(): boolean {
    return !!this.projectConfig?.options?.enableDeviceProvisioning;
  }

  get isUserInviteMailEnabled(): boolean {
    return !!this.projectConfig?.options?.enableUserInviteEmail;
  }

  get isArchivingEnabled(): boolean {
    const archiving: InputDataArchivingType = this.projectConfig?.options?.inputDataArchiving;
    return !!archiving && archiving !== 'disabled';
  }

  get allProjects(): ProjectUiConfigInfo[] {
    return this._allProjects.getValue();
  }

  get notificationBannerMessage(): Observable<NotificationBanner> {
    return this.notificationBanner.asObservable();
  }

  get predefinedEventColors(): Observable<EventColor[]> {
    return this.projectEventColors.asObservable();
  }

  get globalFilterParameters$(): Observable<GlobalFilterParameters> {
    return this.globalParameters.asObservable();
  }

  get customDomainContentConfig(): CustomHomepageContent {
    return this.projectConfig?.pageContent;
  }

  getCustomMenuObs(): Observable<CustomMenuItemApiModel[]> {
    return this.customMenu.asObservable();
  }

  projectName: string;

  projectLabel: TranslatedString;

  projectConfigLoader = new LoadingEntity<ProjectConfig>();

  /**
   * Hot observable of the project config change events.
   * Should only be subscribed by components, that exist over project changes.
   * It can emit null and ProjectConfigEvent
   */
  projectConfigEvents = new BehaviorSubject<ProjectConfigEvent>(null);

  /**
   * Stores the event booking colors into observable to prevent reload of the project.
   */
  projectEventColors = new BehaviorSubject<EventColor[]>(defaultPredefinedEventColors);

  /**
   * Stores project notification for all users
   */
  notificationBanner = new BehaviorSubject<NotificationBanner>(null);

  globalParameters = new BehaviorSubject<GlobalFilterParameters>(null);

  customMenu = new BehaviorSubject<CustomMenuItemApiModel[]>(null);

  /**
   * Custom Domain Feature
   * Disallow navigation to projects not bound to the custom domain config
   * **/
  restrictedSingleProjectNavigation: string;
  projectRedirectPath: string;

  private _allProjects: BehaviorSubject<ProjectUiConfigInfo[]> = new BehaviorSubject<
    ProjectUiConfigInfo[]
  >(null);

  private _allProjectsLoadingObservable = null;

  private latestProjectsLimit = 5;
  private lastVisitedPagesLimit = 8; // Visible limit is 7, but currently active page is not considered

  private readonly serviceUrl = '/project-management-service/v1/projects';

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private authService: UserAuthService,
    private location: Location,
    private router: Router
  ) {}

  initProject(projectName: string, userId: string, force = false) {
    if (projectName && (this.projectName !== projectName || force)) {
      this.projectName = projectName;
      this.projectLabel = this.getPersistedProjectLabel(projectName);
      this.projectConfigEvents.next(null);
      this.projectEventColors.next(defaultPredefinedEventColors);
      this.loadProjectConfig(this.projectName, userId);
    }
  }

  clearProject() {
    this.projectName = null;
    this.projectConfigEvents.next(null);
    this.projectEventColors.next(defaultPredefinedEventColors);
  }

  inProject(): boolean {
    return !!this.projectName;
  }

  /**
   * Observable that emits the latest project config once, or the error, that was received
   * for the project.
   * Should be used in components, that need to know the project config
   */
  getCurrentProject(): Observable<ProjectConfig> {
    return this.projectConfigEvents.pipe(
      filter((event) => event !== null),
      first(),
      map((event) => {
        if (event.config) {
          return event.config;
        }
        throw event.error;
      })
    );
  }

  getAllProjects(): Observable<ProjectUiConfigInfo[]> {
    if (this._allProjects.value) {
      return this._allProjects;
    } else if (this._allProjectsLoadingObservable) {
      return this._allProjectsLoadingObservable;
    }

    this._allProjectsLoadingObservable = this.http
      .get<ProjectUiConfigInfo[]>('/ui/api/ui/config/ui-info')
      .pipe(
        map((res) => {
          this._allProjectsLoadingObservable = null;

          for (const project of res) {
            this.addMissingLabels(project);
          }
          this._allProjects.next(res);

          return res;
        }),
        shareReplay(1)
      );
    return this._allProjectsLoadingObservable;
  }

  enablePayAsYouGoFeatures(
    features: PayAsYouGoFeatureType[]
  ): Observable<PayAsYouGoEnableFeatureResult> {
    return this.http.post<PayAsYouGoEnableFeatureResult>(
      `${this.serviceUrl}/${this.projectName}/payAsYouGo/enableFeatures`,
      { activate: features }
    );
  }

  getProjectConfig(name: string): Observable<ProjectConfig> {
    return this.http.get<ProjectConfig>('/ui/api/ui/config/' + name);
  }

  // Currently only supports & used for global filters
  patchProjectConfigInfo(name: string, config: Partial<ProjectConfig>) {
    return this.http.patch<ProjectConfig>('/ui/api/ui/config/' + name, config);
  }

  getInputDataArchivingType() {
    return this.http.get<any>(`${this.serviceUrl}/${this.projectName}/input-data-archiving`);
  }

  setInputDataArchivingType(type: InputDataArchivingType) {
    const body = { inputDataArchiving: type };
    return this.http.put(`${this.serviceUrl}/${this.projectName}/input-data-archiving`, body);
  }

  updateProjectConfigInfo(name: string, config: ProjectConfig) {
    this._allProjectsLoadingObservable = null;
    this._allProjects.next(null);
    this.updateEventColors(config);
    // do not send the 'deprecated' views, because the BE is trying to migrate them again
    if (config.views) {
      config.views = [];
    }
    return this.http.put<ProjectConfig>('/ui/api/ui/config/' + name, config);
  }

  updateAllowedUrlSchemes(projectName: string, urlSchemes: UrlSchemeDefinition[]) {
    return this.http.put<UrlSchemeDefinition[]>(
      `${this.serviceUrl}/${projectName}/allowedUrlSchemes`,
      urlSchemes
    );
  }

  getAllowedUrlSchemes(projectName: string): Observable<UrlSchemeDefinition[]> {
    return this.http.get<UrlSchemeDefinition[]>(
      `${this.serviceUrl}/${projectName}/allowedUrlSchemes`
    );
  }

  getNotificationSettings(projectName: string): Observable<NotificationSettings> {
    return this.http.get<NotificationSettings>(
      `${this.serviceUrl}/${projectName}/notificationSettings`
    );
  }

  updateNotificationSettings(
    projectName: string,
    notificationSettings: NotificationSettings
  ): Observable<NotificationSettings> {
    return this.http.put<NotificationSettings>(
      `${this.serviceUrl}/${projectName}/notificationSettings`,
      notificationSettings
    );
  }

  updateUserRetention(projectName: string, userRetention: UserRetention) {
    return this.http.put<UserRetention>(
      `${this.serviceUrl}/${projectName}/user-retentions`,
      userRetention
    );
  }

  getUserRetentions(projectName: string): Observable<UserRetentionResult[]> {
    return this.http.get<UserRetentionResult[]>(
      `${this.serviceUrl}/${projectName}/user-retentions`
    );
  }

  private updateLatestProjects(config: ProjectConfig, userId: string) {
    const items = this.getLastUsedProjects(userId);
    items.unshift({ name: config.name, label: config.label });
    for (let i = 1; i < items.length; i++) {
      const item = items[i];
      if (item.name === config.name || i >= this.latestProjectsLimit) {
        items.splice(i, 1);
        i--;
      }
    }
    this.localStorage.set('lastUsedProjects-' + userId, items);
  }

  updateLastVisitedPages(visitedPage: LastVisitedPageItem, userId: string) {
    if (!visitedPage?.name) {
      return;
    }

    const items = this.getLastVisitedPagesForProject(userId, this.projectName);
    this.assertUniqueEntriesBeforeInsertingNewPageEntry(items, visitedPage);

    for (let i = 1; i < items.length; i++) {
      const item = items[i];
      if (item.path === visitedPage.path || i >= this.lastVisitedPagesLimit) {
        items.splice(i, 1);
        i--;
      }
    }

    const allItems = this.getLastVisitedPages(userId);
    allItems[this.projectName] = items;

    this.localStorage.set('lastVisitedPages-' + userId, allItems);
  }

  getLastUsedProjects(userId: string): LatestProjectItem[] {
    return this.localStorage.get('lastUsedProjects-' + userId) || [];
  }

  private assertUniqueEntriesBeforeInsertingNewPageEntry(
    items: LastVisitedPageItem[],
    visitedPage: LastVisitedPageItem
  ) {
    const itemNames = new Set(items.map((item) => item.name));
    if (itemNames.has(visitedPage.name)) {
      const duplicateIndex = items.findIndex((item) => item.name === visitedPage.name);
      const parentPage = this.getParentPageOrLastAmongSubPages(visitedPage, items[duplicateIndex]);
      items.splice(duplicateIndex, 1);
      items.unshift(parentPage);
    } else {
      items.unshift(visitedPage);
    }
  }

  /*
   * For Sub-Pages, i.e. in the ALl Devices Section, navigating among device types or devices can cause multiple entries with the same page title
   * This method avoids the insertion of duplicate titles within the recent page list by prioritizing the parent page (if exists).
   * If no parent page was found, the last visited page is prioritized
   * */
  private getParentPageOrLastAmongSubPages(
    visitedPage: LastVisitedPageItem,
    duplicate: LastVisitedPageItem
  ): LastVisitedPageItem {
    const visitedPageHierarchies = visitedPage.path.split('/').length;
    const duplicatedPageHierarchies = duplicate.path.split('/').length;

    if (visitedPageHierarchies > duplicatedPageHierarchies) {
      return duplicate;
    }

    return visitedPage;
  }

  private getLastVisitedPages(userId: string): { [projectName: string]: LastVisitedPageItem[] } {
    const pages: any = this.localStorage.get('lastVisitedPages-' + userId) || {};
    Object.keys(pages).forEach((projectName) => {
      pages[projectName] = LastVisitedPageItem.fromStorageEntries(pages[projectName]);
    });

    return pages;
  }

  getLastVisitedPagesForProject(userId: string, projectName: string): LastVisitedPageItem[] {
    const items = this.getLastVisitedPages(userId);
    return items[projectName] || [];
  }

  private getPersistedProjectLabel(projectName: string): TranslatedString {
    let found = this.getLastUsedProjects(this.authService.getUserId()).find(
      (project) => project.name === projectName
    )?.label;
    if (found) {
      return found;
    }
    if (this._allProjects.value) {
      found = this._allProjects.value.find((project) => project.name === projectName)?.label;
    }

    return found || null;
  }

  private loadProjectConfig(projectName: string, userId: string) {
    this.projectConfigLoader
      .run(
        this.authService.isLoggedIn().pipe(
          switchMap((isLoggedIn) => {
            return isLoggedIn ? this.getProjectInfo(projectName) : of(null);
          })
        )
      )
      .subscribe({
        next: (config) => {
          if (config) {
            this.updateLatestProjects(config, userId);
            this.updateEventColors(config);
            this.updateNotificationBanner(config.properties?.projectNotification);
            this.updateGlobalParameters(config.properties?.globalParameters);
            this.updateCustomMenu(config.menu?.subItems);
            this.projectConfigEvents.next(new ProjectConfigEvent(config));
            this.projectLabel = config.label;
            this.handleStandbyStatus(config.status);
          }
        },
        error: (error) => {
          this.projectConfigEvents.next(new ProjectConfigEvent(null, error));
        }
      });
  }

  private getProjectInfo(name: string): Observable<ProjectConfig> {
    return this.http
      .get<ProjectConfig>('/ui/api/ui/config/' + name + '/info', { observe: 'response' })
      .pipe(
        map((res) => {
          if (res.status === 205) {
            const redirectPath = this.location.prepareExternalUrl(this.location.path());
            this.authService.performLogin(redirectPath);
            throw new Error('Project ' + name + ' access granted, but session reload is needed');
          } else {
            return this.addMissingLabels(res.body);
          }
        }),
        catchError((err) => {
          console.log('getProjectConfig: err = ', err);
          if (err && err['status'] === 404) {
            this.getAllProjects().pipe(
              switchMap((projects) => {
                const project = projects.find((p) => p.name === name);
                if (project) {
                  return of(this.mockProjectConfig(project));
                } else {
                  throw new Error('Project ' + name + ' not found');
                }
              })
            );
          }
          if (err && err['status'] === 403 && this.restrictedSingleProjectNavigation) {
            console.log('Forbidden User Access within Custom Domain');
            this.router.navigate([Constants.routing.userNoDomainAccess]);
          }
          return throwError(err);
        })
      );
  }

  private mockProjectConfig(project: ProjectUiConfigInfo): ProjectConfig {
    return {
      name: project.name,
      label: project.label,
      views: [],
      properties: {},
      options: {}
    };
  }

  private addMissingLabels(proj) {
    for (const key in proj.label) {
      if (proj.label[key] === '') {
        proj.label[key] = proj.name;
      }
    }
    return proj;
  }

  private handleStandbyStatus(status: SubscriptionStatus): void {
    if (
      status === SubscriptionStatus.standby &&
      !this.authService.hasSystemRole(SystemRole.admin)
    ) {
      const redirectPath = this.isProjectOwner()
        ? Constants.routing.adminSubscriptionDetails
        : Constants.routing.subscriptionStandby;
      this.router.navigate([transformProjectUrl(redirectPath, this.projectName)]);
    }
  }

  hasRoleOfProject(projectName: string, roleName: RoleName): boolean {
    return this.authService.hasProjectRole(new ProjectRole(projectName, roleName));
  }

  hasRoleOfCurrentProject(roleName: RoleName): boolean {
    if (!this.projectName) {
      return false;
    }
    return this.authService.hasProjectRole(new ProjectRole(this.projectName, roleName));
  }

  /**
   * Checks if the user has any of the supplied roles in the current project.
   * Is true if no roles are supplied
   * @param roles role name without prefix e.g. user
   */
  hasAnyRole(roles: RoleName[]) {
    if (roles && roles.length) {
      return roles.some((role) => this.hasRoleOfCurrentProject(role));
    } else {
      return true;
    }
  }

  /**
   * Checks if the user has any of the supplied roles in the current project or the according system role
   * Does not consider role hierarchy
   * @param roles
   */
  hasAnySpecificRole(roles: RoleName[]): boolean {
    const hasSystemRole =
      this.authService.hasAnySystemRole(roles) ||
      this.authService.hasAnySystemRole(roles.map((role) => `sfde_${role}`));
    const hasProjectRole =
      this.authService.projectRoles[this.projectName]?.some((role) => roles.includes(role)) ||
      false;
    return hasSystemRole || hasProjectRole;
  }

  isAccessOnlyUser(): boolean {
    return !this.hasAnyRole(excludeAccessAndCustomRoles);
  }

  isProjectOwner() {
    return this.hasRoleOfCurrentProject(Constants.roles.owner);
  }

  isAdmin(): boolean {
    return this.hasRoleOfCurrentProject(Constants.roles.admin);
  }

  isPowerUser(): boolean {
    return this.hasRoleOfCurrentProject(Constants.roles.powerUser);
  }

  hasQueryTemplatesAccess(): boolean {
    if (this.isAccessOnlyUser()) {
      return this.projectConfig && this.projectConfig.queryTemplateCount > 0;
    }
    return true;
  }

  getVisibleViews(projectConfig: ProjectConfig): ProjectViewConfig[] {
    if (!projectConfig || !projectConfig.views) {
      return [];
    }
    return projectConfig.views.filter(
      (v) => !v.requiredRoles || this.hasAccessToViews(v.requiredRoles)
    );
  }

  updateGlobalParameters(parameters: GlobalFilterParameters) {
    this.globalParameters.next(parameters);
  }

  private updateCustomMenu(menu: CustomMenuItemApiModel[]) {
    this.customMenu.next(menu);
  }

  private updateNotificationBanner(banner: NotificationBanner) {
    this.notificationBanner.next(banner);
  }

  private updateEventColors(config: ProjectConfig) {
    if (!isEmpty(config?.properties?.predefinedEventColors)) {
      this.projectEventColors.next(config?.properties?.predefinedEventColors);
    }
  }

  private hasAccessToViews(requiredRoles: string[]): boolean {
    // Admin should be able to see every dashboard/view
    return this.hasAnyRole(requiredRoles) || this.hasRoleOfCurrentProject('admin');
  }

  hasReadAccessRight(acl: ObjectAclRes): boolean {
    return this.hasAccessRight(acl?.readers);
  }

  hasWriteAccessRight(acl: ObjectAclRes): boolean {
    return this.hasAccessRight(acl?.writers);
  }

  private hasAccessRight(roles: string[]): boolean {
    if (this.hasRoleOfCurrentProject('admin')) {
      return true;
    }

    if (roles?.length > 0) {
      const rolesForCurrentProject = roles.filter((role: string) => {
        return role.startsWith(`roleName:${this.projectName}`);
      });
      const projectRoles = ObjectAcl.extractRoleNamesFromAclString(rolesForCurrentProject);
      return this.hasAnyRole(projectRoles);
    }
    return false;
  }

  updateAllowedIdentityProviders(identityProviders: string[]): Observable<string[]> {
    return this.http.put<string[]>(
      `${this.serviceUrl}/${this.projectName}/identity-providers`,
      identityProviders
    );
  }

  getIdentityProviders(): Observable<IdentityProvider[]> {
    return this.http.get<IdentityProvider[]>(
      `${this.serviceUrl}/${this.projectName}/identity-providers`
    );
  }

  prolongateFreeplan(): Observable<ProjectConfig> {
    return this.http.post<ProjectConfig>(
      `${this.serviceUrl}/${this.projectName}/freePlan/confirmProlongation`,
      {}
    );
  }
}
