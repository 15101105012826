import { translate } from '../../../shared/translation-util';

export type AuthProviderPrefix = 'adfs' | 'ciam' | 'skid' | 'aad' | 'ext' | 'skidstage';

export class AuthProvider {
  static SKID = new AuthProvider('skid', translate('nav.loginSingleKeyId'));
  static AAD = new AuthProvider('aad', translate('nav.loginBoschEmployee'));

  private constructor(public providerPrefix: AuthProviderPrefix, public label: string) {}
}

export const ALL_AUTH_PROVIDERS: { [name: string]: AuthProvider } = {
  skid: AuthProvider.SKID,
  aad: AuthProvider.AAD
};
