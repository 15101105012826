import { Component, forwardRef, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { getPathParts, PathInfo, PathItemType, PathParts, toBasePath } from '../data-info-util';

@Component({
  selector: 'data-selector-input',
  templateUrl: './data-selector-input.component.html',
  styleUrls: ['./data-selector-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DataSelectorInputComponent),
      multi: true
    }
  ]
})
export class DataSelectorInputComponent implements OnChanges, ControlValueAccessor {
  @Input() name: string;

  @Input() label: string | TemplateRef<any> = null;

  @Input() paths: PathInfo[];

  @Input() customClass = '';

  @Input() required = true;

  @Input() showValidationMessage;

  @Input() requireIteration = false;

  /**
   * Allowed types, e.g. number, string, map, array, boolean
   * null = any type allowed
   */
  @Input() allowedTypes: PathItemType[] = null;

  id = 'dataSelector.' + Math.random();

  disabled: boolean;

  onChange;
  onTouched;

  _path: string;

  pathParts: PathParts[] = [];

  isInSelection = false;

  isMatchingType = true;

  hasIterator = false;

  showErrors = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.paths) {
      this.checkPath();
    }
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this._path = obj;
    this.checkPath();
  }

  selectPath(path: string) {
    this._path = path;
    this.onChange(path);
    this.checkPath();
  }

  checkPath() {
    this.showErrors = !!(typeof this._path === 'string' && this._path.length);
    if (this.paths && this._path) {
      const path = toBasePath(this._path);
      const pathInfo = this.paths.find((p) => toBasePath(p.path) === path);
      this.isInSelection = !!pathInfo;
      if (this.allowedTypes && pathInfo) {
        this.isMatchingType = this.allowedTypes.some((type) => pathInfo.types.includes(type));
      }
    } else if (this._path === '' && !this.required) {
      this.isInSelection = true;
    }
    const pathParts = getPathParts(this._path);
    this.hasIterator = pathParts.some((pp) => pp.isIterator);
    if (this.pathParts.length === pathParts.length) {
      for (let i = 0; i < pathParts.length; i++) {
        Object.assign(this.pathParts[i], pathParts[i]);
      }
    } else {
      this.pathParts = pathParts;
    }
  }

  setIterate(index: number) {
    for (let i = 0; i < this.pathParts.length; i++) {
      const pathPart = this.pathParts[i];
      if (i === index) {
        pathPart.part = '[i]';
        pathPart.isIterator = true;
        pathPart.index = 'i';
      } else if (pathPart.isIterator) {
        pathPart.part = '[]';
        pathPart.isIterator = false;
        pathPart.index = '';
      }
    }
    this.selectPath(this.pathParts.map((pp) => pp.part).join('.'));
  }

  setStaticValue(pp: PathParts, value: string) {
    pp.isIterator = false;
    pp.index = value;
    pp.part = '[' + value + ']';
    this.selectPath(this.pathParts.map((p) => p.part).join('.'));
  }

  handleArrayPathPartClick($event: MouseEvent) {
    // "Jump" over all the ancestors to avoid surrounding dropdown to trigger,
    // but trigger all the other events related to popovers/dropdowns (based on window.click)
    $event.stopPropagation();
    window.dispatchEvent(new Event('click'));
  }
}
