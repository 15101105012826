import { HttpHeaders } from '@angular/common/http';
import { ThingPolicy } from './thing-policy';

export const allThingFields: string = [
  'thingId',
  'policyId',
  'attributes',
  'features',
  '_revision',
  '_modified',
  '_policy',
  '_created'
].join(',');

export interface ThingProperties {
  [key: string]: any;
}

export interface ThingFeature {
  definition: string[];
  properties: ThingProperties;
}

export interface NewThing {
  policyId?: string;
  _policy?: ThingPolicy;
  _copyPolicyFrom?: string;
  attributes: ThingProperties;
  features: { [name: string]: ThingFeature };
}

export interface Thing {
  thingId?: string;
  policyId: string;
  attributes: ThingProperties;
  features: { [name: string]: ThingFeature };
  _revision?: number;
  _modified?: string;
  _policy?: ThingPolicy;
  _created?: string;
}

export interface ThingPropertiesUpdate {
  properties: ThingProperties;
  updateInfo?: HttpHeaders;
}

export interface DeviceProvisioning {
  id: string;
  hub: Record<string, any>;
  things: { thing: NewThing };
}

export interface DeviceProvisioningResponse {
  id: string;
  hub: Record<string, any>;
  things: { thing: Thing };
}
