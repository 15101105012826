import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { WidgetControlComponent } from './widget-control/widget-control.component';
import { WidgetSettingsItemComponent } from './widget-settings-item/widget-settings-item.component';
import { WidgetLoadingContentComponent } from './widget-loading-content/widget-loading-content.component';
import { WidgetLoadingDirective } from './widget-loading-content/widget-loading.directive';
import { SharedQueryModule } from '../../shared-query/shared-query.module';
import { WidgetTitleComponent } from './widget-title/widget-title.component';
import { WidgetContainerComponent } from './widget-container/widget-container.component';
import { ContextMenuModule } from '@inst-iot/bosch-angular-ui-components';
import { WidgetLoadingStatusComponent } from './widget-loading-status/widget-loading-status.component';
import { DashboardGridComponent } from './dashboard-grid/dashboard-grid.component';
import { GridsterModule } from 'angular-gridster2';
import { DashboardWidgetModule } from '../dashboard-widget/dashboard-widget.module';
import { ActivateLoadingStatusTooltipDirective } from './widget-container/directives/activate-loading-status-tooltip.directive';
import { WidgetExportInfoComponent } from './widget-container/widget-export-info/widget-export-info.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SharedQueryModule,
    ContextMenuModule,
    GridsterModule,
    DashboardWidgetModule
  ],
  declarations: [
    ActivateLoadingStatusTooltipDirective,
    WidgetControlComponent,
    WidgetSettingsItemComponent,
    WidgetLoadingContentComponent,
    WidgetLoadingDirective,
    WidgetTitleComponent,
    WidgetExportInfoComponent,
    WidgetContainerComponent,
    WidgetLoadingStatusComponent,
    DashboardGridComponent
  ],
  exports: [
    WidgetControlComponent,
    WidgetSettingsItemComponent,
    WidgetLoadingContentComponent,
    WidgetLoadingDirective,
    WidgetTitleComponent,
    WidgetExportInfoComponent,
    WidgetContainerComponent,
    DashboardGridComponent
  ]
})
export class WidgetCommonsModule {}
