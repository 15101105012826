import { isNil } from 'lodash-es';
import { UserPending } from './user-pending.model';
import { AuthProviderPrefix } from '../../../core/services/models/auth-provider';
import { OperatorType } from '../../../shared-modules/query-condition-input/models/operator.model';

export type UserLike = User | UserPending;

export type UserRoleUpdate = Pick<User, 'userId' | 'projectRoles'>;

export type UserSetting = User[] | UserRoleUpdate[] | UserLike | UserRoleUpdate;

export class User {
  userId: string;
  projectRoles: string[];
  email: string;
  name: string;
  createdAt: string;
  lastSeenAt: string;
  technical: boolean;
  removable: boolean;
  identityProvider: AuthProviderPrefix;
  internalName: string;
  consent: {
    revision: number;
    acceptanceDate: string;
  };
  // only technical users have a password
  password?: string;

  get displayName(): string {
    return this.technical ? this.email : this.name + ` (${this.email})`;
  }

  get isTechnical(): boolean {
    return this.technical;
  }

  get isRemovable(): boolean {
    return this.removable;
  }

  get id(): string {
    return this.userId;
  }

  get isBoschIdUser() {
    return this.identityProvider?.startsWith('ciam');
  }

  get isBoschEmployee() {
    return this.identityProvider === 'adfs' || this.identityProvider === 'aad';
  }

  get isSingleKeyId() {
    return this.identityProvider === 'skid';
  }

  get isSkidStage() {
    return this.identityProvider === 'skidstage';
  }

  get consentRevision() {
    return this.consent?.revision;
  }

  constructor(user: Partial<User> = {}) {
    const {
      userId = '',
      name = '',
      email = '',
      projectRoles = [],
      createdAt,
      lastSeenAt,
      technical = false,
      internalName = '',
      removable = true,
      identityProvider = null,
      consent
    } = user;

    this.userId = userId;
    this.name = name;
    this.internalName = internalName;
    this.createdAt = createdAt;
    this.lastSeenAt = lastSeenAt;
    this.email = email;
    this.projectRoles = projectRoles;
    this.technical = technical;
    this.removable = removable;
    this.identityProvider = identityProvider;
    this.consent = consent;
    if ('password' in user) {
      this.password = user.password;
    }
    this.sortRoleByName();
    return this;
  }

  static findUserById(id: string, users: UserLike[]): UserLike | undefined {
    return users.find((user) => user.id === id);
  }

  static findUserIndexById(id: string, users: UserLike[]): number {
    return users.findIndex((user) => user.id === id);
  }

  static deleteUserById(id: string, users: UserLike[]): UserLike[] {
    const foundIndex = User.findUserIndexById(id, users);
    if (isNil(foundIndex) || foundIndex === -1) {
      return users;
    }
    users.splice(foundIndex, 1);
    return users;
  }

  static updateUserInList(editedUser: UserLike, users: UserLike[]): UserLike[] {
    const foundIndex = User.findUserIndexById(editedUser.id, users);
    if (isNil(foundIndex) || foundIndex === -1) {
      return users;
    }
    users[foundIndex] = editedUser;
    return users;
  }

  static hasRole(user: UserLike, role: string): boolean {
    return user.projectRoles.includes(role);
  }

  static roleSortCompare(roleA: string, roleB: string) {
    if (roleA < roleB) {
      return -1;
    }
    if (roleA > roleB) {
      return 1;
    }
    return 0;
  }

  sortRoleByName(): this {
    this.projectRoles.sort((a, b) => User.roleSortCompare(a, b));
    return this;
  }

  static getUserSearchLink(userName: string) {
    return {
      1: JSON.stringify({
        name: userName,
        operator: 'eq' as OperatorType
      }),
      simpleLogic: 1
    };
  }
}
