import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageData } from '@inst-iot/bosch-angular-ui-components';
import { TranslateService } from '@ngx-translate/core';
import { flatten, get, isEmpty, uniq } from 'lodash-es';
import { Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AppThemingService } from '../../core/services/app-theming.service';
import { FilterParams, InputParams } from '../../shared-modules/data-widgets/data-widgets-common';
import { DataPriorityService } from '../../shared-modules/data-widgets/data-widgets-common/services/data-priority.service';
import { ProjectsService } from '../../shared-projects/services/projects.service';
import { QueryService } from '../../shared-query/services/query.service';
import { DashboardConfig, DashboardLayout } from '../models/DashboardConfig';
import { DashboardWidgetConfig } from '../models/DashboardWidgetConfig';
import {
  DefaultDashboardParams,
  defaultDashboards,
  generateDefaultDashboard,
  getDefaultDashboardsList
} from '../models/default-dashboards';
import { FilterWidgetGlobalParametersService } from '../widgets/filter-widget/filter-widget-edit/filter-widget-global-parameters.service';
import { FilterParameterConfig } from '../widgets/filter-widget/filter-widget.model';
import { FieldConfig } from '../widgets/input-widget/models/input.model';
import { TabConfig } from '../widgets/tab-widget/tab-widget.model';

@Injectable()
export class DashboardDataService {
  widgetRemoved$ = new Subject<DashboardWidgetConfig>();
  configChange = new Subject<DashboardConfig>();

  /**
   * Filter params are set by the dashboard-state.service.ts
   */
  filterParams = new ReplaySubject<FilterParams>(1);

  inputFields = new ReplaySubject<InputParams>(1);

  isSpecificWidgetRoute = false;

  private _currentConfig: DashboardConfig = null;

  constructor(
    private http: HttpClient,
    private projectsService: ProjectsService,
    private appThemingService: AppThemingService,
    private languageService: TranslateService,
    private globalParametersService: FilterWidgetGlobalParametersService,
    private queryService: QueryService
  ) {}

  get currentConfig(): DashboardConfig {
    return this._currentConfig;
  }

  get currentLayout(): DashboardLayout {
    return this._currentConfig?.layout || DashboardLayout.ColumnBased;
  }

  get baseUrl() {
    return '/ui/api/ui/dashboards/' + this.projectsService.projectName;
  }

  get baseDashboardUrl() {
    return (
      '/ui/api/ui/dashboards/' +
      this.projectsService.projectName +
      '/dashboard/' +
      this.currentConfig.name
    );
  }

  get dashboardsCollectionName() {
    return `${this.projectsService.projectName}_dashboard_configs`;
  }

  isGridLayout(dashboardLayout: DashboardLayout = this.currentLayout) {
    return (
      dashboardLayout === DashboardLayout.GRID ||
      dashboardLayout === DashboardLayout.GridFreeFloating
    );
  }

  updateConfig(config: DashboardConfig, dashboardChange = false) {
    this._currentConfig = config;

    /**
     * When dashboard is changed(only in this case), we 'reset' the filterParams/inputFields to
     * avoid double calls or calls with wrong(previous) parameters
     * Fix introduced for the following bugs:
     * INS-2531 - UI is triggering queries twice without ever retrieving the results
     * INS-7113 - Input & Filter parameters not working properly when new widgets are added
     * INS-7275 - Dashboard/Filter Widget - Error passing filter parameters to widgets
     */
    if (dashboardChange) {
      this.filterParams.complete();
      this.filterParams = new ReplaySubject<FilterParams>(1);
      this.inputFields.complete();
      this.inputFields = new ReplaySubject<InputParams>(1);
    }

    this.configChange.next(config);
  }

  getDashboardUrlParts(): string[] {
    if (!this.currentConfig) {
      throw new Error('current dashboard config not available');
    }
    if (this.currentConfig.name === 'home') {
      return ['home'];
    } else {
      return ['views', 'dashboards', this.currentConfig.name];
    }
  }

  getEditWidgetDashboardUrl(widgetId: string): string[] {
    if (!this.currentConfig) {
      throw new Error('current dashboard config not available');
    }
    if (this.currentConfig.name === 'home') {
      return ['home', 'home', 'edit', widgetId];
    } else {
      return ['views', 'dashboards', this.currentConfig.name, 'edit', widgetId];
    }
  }

  /**
   * @deprecated
   * Use 'getDashboards()' or 'getDashboardsWithDefaults()'
   * via the MongoQueryService to not bypass the ReadTicketManagement
   */
  getDashboardConfig(name: string): Observable<DashboardConfig> {
    return this.http.get<DashboardConfig>(this.baseUrl + '/dashboard/' + name).pipe(
      switchMap((config: DashboardConfig) => {
        config.widgets =
          config.widgets?.map(
            (widget: DashboardWidgetConfig) => new DashboardWidgetConfig(widget)
          ) ?? [];
        return of(config);
      }),
      catchError((err) => {
        if (err?.status === 404 && defaultDashboards[name]) {
          console.log(this.projectsService?.projectConfig);
          const defaultDashboardParams: DefaultDashboardParams = {
            productName: this.projectsService.hasCustomDomain
              ? this.projectsService?.projectConfig?.label[this.languageService.currentLang] ||
                this.projectsService?.projectConfig?.label?.en
              : this.appThemingService.appTheme.productName
          };
          return of(
            JSON.parse(JSON.stringify(generateDefaultDashboard(defaultDashboardParams, name)))
          );
        }
        return throwError(err);
      })
    );
  }

  getDistinctDashboardTags(limit = 100): Observable<string[]> {
    const aggregate = [
      { $match: { $and: [{ tags: { $ne: null } }, { tags: { $ne: [] } }] } },
      { $limit: limit }
    ];

    return this.queryService.runQueryWithResult(aggregate, this.dashboardsCollectionName).pipe(
      map((dashboards) => {
        const tags = (dashboards || []).map((dashboard) => dashboard.tags);
        return uniq(flatten(tags));
      })
    );
  }

  /*
   * If no dashboards exist, the default dashboards are returned.
   * */
  getDashboardsWithDefaults(
    query = {},
    pagination?: Partial<PageData>
  ): Observable<DashboardConfig[]> {
    return this.getDashboards(query, pagination).pipe(
      map((dashboards) => {
        if (!dashboards.length) {
          return getDefaultDashboardsList(this.projectsService.projectName);
        }
        return dashboards;
      })
    );
  }

  /*
   * Gets a list of filtered, database entries for dashboards
   * For retrieving the Dashboards we do not need to check the requiredRoles
   * Only for displaying/accessing the dashboard the roles need to be checked
   * */
  getDashboards(query = {}, pagination?: Partial<PageData>): Observable<DashboardConfig[]> {
    const aggregate = this.getDashboardsAggregate(query, pagination);

    return this.queryService.runQueryWithResult(aggregate, this.dashboardsCollectionName).pipe(
      map((dashboards) => {
        return (dashboards || []).map((dashboard) => ({
          ...dashboard,
          id: dashboard._id,
          widgets: dashboard.widgets
            ? dashboard.widgets.map((widget) => {
                widget = widget._id ? { id: widget._id, ...widget } : widget;
                return new DashboardWidgetConfig(widget);
              })
            : [],
          layout: dashboard.layout ?? DashboardLayout.ColumnBased
        }));
      })
    );
  }

  /*
   * Used for pagination
   * @param query (optional): The query to filter the dashboards
   */
  getDashboardsCount(query = {}): Observable<number> {
    const aggregate = [{ $match: query }, { $count: 'total' }];

    return this.queryService
      .runQueryWithResult(aggregate, this.dashboardsCollectionName)
      .pipe(map((result) => (result[0] ? result[0].total : Infinity)));
  }

  verifyDashboard(): Observable<DashboardConfig> {
    return of(null).pipe(
      switchMap(() => {
        if (this.currentConfig.isLocalDashboard === true) {
          return this.createDashboard(this.currentConfig).pipe(
            tap((config) => Object.assign(this.currentConfig, config))
          );
        } else {
          return of(this.currentConfig);
        }
      })
    );
  }

  createDashboard(config: DashboardConfig): Observable<DashboardConfig> {
    return this.http.post<DashboardConfig>(this.baseUrl + '/dashboard', config);
  }

  updateDashboard(
    name: string,
    config: DashboardConfig,
    updateWidgets = false
  ): Observable<DashboardConfig> {
    let params = new HttpParams();
    if (updateWidgets) {
      params = params.set('updateWidgets', updateWidgets);
    }
    return this.http.put<DashboardConfig>(this.baseUrl + '/dashboard' + '/' + name, config, {
      params
    });
  }

  deleteDashboard(name: string): Observable<any> {
    return this.http.delete<any>(this.baseUrl + '/dashboard' + '/' + name);
  }

  addWidget(widget: DashboardWidgetConfig): Observable<DashboardWidgetConfig> {
    if (!this.currentConfig) {
      throw new Error('current dashboard config not available');
    }
    if (!widget.id) {
      widget.id = this.getNewWidgetId();
    }

    return this.verifyDashboard().pipe(
      switchMap(() =>
        this.http.post<DashboardWidgetConfig>(this.baseDashboardUrl + '/widget', widget)
      ),
      tap((savedWidget) => {
        this.currentConfig.widgets.push(savedWidget);
        this.configChange.next(this.currentConfig);
      })
    );
  }

  saveWidget(widget: DashboardWidgetConfig, fromDashboard = true) {
    if (!this.currentConfig) {
      throw new Error('current dashboard config not available');
    }
    if (!widget.id) {
      throw new Error('Missing widget id');
    }
    return this.verifyDashboard().pipe(
      switchMap(() =>
        this.http.put<DashboardWidgetConfig>(this.baseDashboardUrl + '/widget/' + widget.id, widget)
      ),
      tap((savedWidget) => {
        const index = this.currentConfig.widgets.findIndex((w) => w.id === savedWidget.id);
        if (index !== -1) {
          this.currentConfig.widgets.splice(index, 1, savedWidget);
          if (fromDashboard) {
            this.configChange.next(this.currentConfig);
          }
        }
      })
    );
  }

  removeWidget(widgetId: string) {
    if (!this.currentConfig) {
      throw new Error('current dashboard config not available');
    }
    return this.verifyDashboard().pipe(
      switchMap(() => this.http.delete<any>(this.baseDashboardUrl + '/widget/' + widgetId)),
      tap(() => {
        const index = this.currentConfig.widgets.findIndex((w) => w.id === widgetId);
        if (index !== -1) {
          const removedWidget = this.currentConfig.widgets[index];
          this.currentConfig.widgets.splice(index, 1);
          this.configChange.next(this.currentConfig);
          this.widgetRemoved$.next(removedWidget);
        }
      })
    );
  }

  saveWidgetOrder() {
    if (!this.currentConfig) {
      throw new Error('current dashboard config not available');
    }
    const ids = this.currentConfig.widgets.map((w) => w.id);
    return this.verifyDashboard().pipe(
      switchMap(() => this.http.put<any>(this.baseDashboardUrl + '/widget/widget-order', ids)),
      tap(() => {
        this.configChange.next(this.currentConfig);
      })
    );
  }

  updateWidgetOrderFromTab(list) {
    const ids = list.map((w) => w.id);
    return this.verifyDashboard().pipe(
      switchMap(() => this.http.put<any>(this.baseDashboardUrl + '/widget/widget-order', ids))
    );
  }

  getWidget(id: string) {
    if (!this.currentConfig) {
      return null;
    }
    return this.currentConfig.widgets.find((w) => w.id === id);
  }

  getInputWidgetDefinitions(): FieldConfig[] | null {
    if (!this.currentConfig) {
      return null;
    }
    return flatten(
      this.currentConfig.widgets
        .filter((w) => w.type === 'input')
        .map((dashboard: DashboardWidgetConfig) => dashboard?.properties['fieldDefinitions'])
    );
  }

  calculateWidgetPriority(widget: DashboardWidgetConfig): number {
    if (!this.currentConfig) {
      return DataPriorityService.defaultPriority;
    }

    // iterates and counts widgets as they are displayed on the dashboard and respecting tab widgets
    let pos = 0;
    for (const w of this.getDirectlyVisibleWidgets()) {
      pos++;

      if (w.id === widget.id) {
        return pos;
      }

      if (w.type !== 'tabwidget' || !get(w, 'properties.tabConfig[0].widgets')) {
        continue;
      }

      // count each widget of the first tab until desired widget is found
      for (const wInTab of this.getActiveWidgetsOfTab(w.properties['tabConfig'][0])) {
        pos++;
        if (wInTab.id === widget.id) {
          return pos;
        }
      }
    }

    return DataPriorityService.defaultPriority;
  }

  isWidgetVisible(widget: DashboardWidgetConfig): boolean {
    return DashboardWidgetConfig.isVisible(
      widget.visibility,
      widget.selectedRoles,
      this.projectsService
    );
  }

  // $skip should come before $limit
  private getDashboardsAggregate(query: any, pagination?: Partial<PageData>) {
    let aggregate: any;

    if (!isEmpty(pagination)) {
      aggregate = [
        { $match: query },
        { $skip: pagination.number * pagination.size },
        { $limit: pagination.size }
      ];
    } else {
      aggregate = [{ $match: query }];
    }

    return aggregate;
  }

  private getDirectlyVisibleWidgets() {
    return this.currentConfig.widgets.filter((w) => !w.insideTab && this.isWidgetVisible(w));
  }

  private getActiveWidgetsOfTab(tabConfig: TabConfig): DashboardWidgetConfig[] {
    const widgets: DashboardWidgetConfig[] = [];
    for (const widgetId of tabConfig.widgets) {
      const widgetInTab = this.getWidget(widgetId);
      if (!widgetInTab || !this.isWidgetVisible(widgetInTab)) {
        continue;
      }
      widgets.push(widgetInTab);
    }
    return widgets;
  }

  private getNewWidgetId() {
    let id = 0;
    while (this.currentConfig.widgets.find((w) => w.id === 'w' + id)) {
      id++;
    }
    return 'w' + id;
  }

  getFilterParameter(): FilterParameterConfig[] {
    const filterWidget = this.getWidget('filter');

    if (!filterWidget) {
      return [];
    }

    const filterConfig = filterWidget.properties['filterConfig'];

    if (!filterConfig?.parameters && !filterConfig?.globalParameters) {
      return undefined;
    }

    const parameters = filterConfig?.parameters ?? [];
    const globalParameters: string[] = filterConfig?.globalParameters ?? [];
    filterConfig.globalParameters = globalParameters.filter((param) =>
      this.globalParametersService.hasParameter(param)
    );

    return [
      ...parameters,
      ...this.globalParametersService.getGlobalParameters(filterConfig, 'general')
    ];
  }
}
