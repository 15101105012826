import { translate } from '../../../shared/translation-util';

/*
 * OperatorType reflects a technical MongoDB Operator without the $ prefix.
 * */
export type OperatorType = 'exists' | 'regex' | 'eq' | 'ne' | 'gt' | 'lt' | 'gte' | 'lte';

export class Operator {
  static operators: Operator[] = [
    new Operator('exists', '∃', translate('simpleSearch.operators.exists')),
    new Operator('regex', '⊂', translate('simpleSearch.operators.contains')),
    new Operator('eq', '=', translate('simpleSearch.operators.eq')),
    new Operator('ne', '≠', translate('simpleSearch.operators.ne')),
    new Operator('gt', '>', translate('simpleSearch.operators.gt')),
    new Operator('lt', '<', translate('simpleSearch.operators.lt')),
    new Operator('gte', '≥', translate('simpleSearch.operators.gte')),
    new Operator('lte', '≤', translate('simpleSearch.operators.lte'))
  ];

  technicalID: OperatorType;
  visualID: string;
  translationPath: string;

  constructor(technicalID: OperatorType, visualID: string, translationPath: string) {
    this.technicalID = technicalID;
    this.visualID = visualID;
    this.translationPath = translationPath;
  }

  static byTechnicalId(id: OperatorType): Operator {
    return Operator.operators.find((operator: Operator) => operator.technicalID === id);
  }
}
