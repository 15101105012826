<ng-container *ngIf="layoutService.projectLabel">
  <a
    type="button"
    class="a-button a-button--integrated -without-label"
    data-cy="headerLastViewedPageSelector"
    [rbPopover]="pageSelection"
  >
    <i class="a-icon a-button__icon boschicon-bosch-ic-history" title="Page Selector"></i>
  </a>
</ng-container>

<ng-template #pageSelection>
  <div data-cy="headerLastViewedPageSelectorDropdown">
    <ul class="o-context-menu__menuItems">
      <li class="a-menu-item">
        <span class="a-menu-item__link a-menu-item__link--disabled">
          {{ 'nav.lastViewedPages' | translate }}
        </span>
      </li>
    </ul>

    <ul class="o-context-menu__menuItems">
      <ng-container *ngFor="let page of layoutService.lastUsedPages$ | async; let index = index">
        <li
          class="a-menu-item"
          [attr.data-cy]="'headerLastViewedPage_' + index"
          *ngIf="index !== 0"
        >
          <a
            class="a-menu-item__link d-block"
            [routerLink]="page.path"
            [queryParams]="page.params"
            (click)="triggerHeaderTitleChange(page.name)"
          >
            {{ page.name }}
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-template>
