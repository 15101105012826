import { Injector } from '@angular/core';
import * as moment from 'moment';
import { Observable, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { LanguagesService } from '../../../../core/services/languages.service';
import { UserAuthService } from '../../../../core/services/user-auth.service';
import { WidgetInstanceService } from '../../../../dashboards/services/widget-instance.service';
import { DataSourceConfig } from './data-sources';
import { WidgetContext, WidgetPlaceholderContext } from './widget-context';
import { v4 as uuidv4 } from 'uuid';

/**
 * Creates widget context with user related parameters by injector.
 */
export function createBasicWidgetContext(injector: Injector): WidgetContext {
  const authService = injector.get(UserAuthService);
  const languageService = injector.get(LanguagesService);

  return {
    injector: injector,
    insights: {
      user: {
        id: authService.userId,
        email: authService.userEmail,
        displayName: authService.lastUiConfig ? authService.lastUiConfig.userDisplayName : '',
        name: authService.userName,
        internalName: authService.lastUiConfig ? authService.lastUiConfig.userInternalName : ''
      },
      language: languageService.currentLanguage,
      timezone: languageService.timezone,
      timestamp: new Date().toISOString(),
      timezoneOffset: moment().format('Z')
    }
  };
}

/**
 * Creates a stream of widget contexts.
 * Every time query parameters or filters change, this emits a new widget context
 * which should lead to a refresh of a widget
 */

export function createWidgetContextStream(
  instance: WidgetInstanceService,
  injector: Injector
): Observable<WidgetContext> {
  return combineLatest([instance.filterParams, instance.inputFields]).pipe(
    map(([filterParams, inputFields]) => {
      return {
        dashboardName: instance.dashboardName,
        widgetId: instance.id,
        priority: instance.priority,
        filterParams,
        inputFields,
        ...createBasicWidgetContext(injector)
      };
    })
  );
}

export function createWidgetPlaceHolderContext(ctx: WidgetContext): WidgetPlaceholderContext {
  return {
    dashboardName: ctx.dashboardName,
    widgetId: ctx.widgetId,
    filterParams: ctx.filterParams,
    inputFields: ctx.inputFields,
    insights: ctx.insights
  };
}

export function createWidgetPlaceholderContextStream(
  instance: WidgetInstanceService,
  injector: Injector
): Observable<WidgetPlaceholderContext> {
  return createWidgetContextStream(instance, injector).pipe(map(createWidgetPlaceHolderContext));
}

export function createWidgetDataStream(
  instance: WidgetInstanceService,
  injector: Injector,
  catchError = true
): Observable<any[]> {
  const rawDataSourceConfig = instance.properties.dataSourceConfig;
  const dataSourceConfig =
    rawDataSourceConfig instanceof DataSourceConfig
      ? rawDataSourceConfig
      : new DataSourceConfig(rawDataSourceConfig);
  let refreshIntervalInSec = 0;

  if (instance.properties['refreshIntervalInSec']) {
    refreshIntervalInSec = instance.properties['refreshIntervalInSec'];
    if (!instance.hasAutoRefreshing) {
      instance.initAutoRefreshing();
    }
  }

  return createWidgetContextStream(instance, injector).pipe(
    instance.trackLoadingStart(),
    instance.trackQueryStatus(dataSourceConfig.sources),
    switchMap((widgetContext) => {
      return dataSourceConfig.loadData(widgetContext).pipe(
        instance.trackLoading(),
        instance.runRefreshing(refreshIntervalInSec, catchError),
        tap(() => (instance.lastDataGenerated = dataSourceConfig.getLastUpdate()))
      );
    })
  );
}

export function hasPlaybackAllDataSource(instance: WidgetInstanceService): boolean {
  const rawDataSourceConfig = instance.properties.dataSourceConfig;
  const dataSourceConfig =
    rawDataSourceConfig instanceof DataSourceConfig
      ? rawDataSourceConfig
      : new DataSourceConfig(rawDataSourceConfig);
  return !!dataSourceConfig.sources.find((ds) => ds.type === 'playbackAll');
}

export function createRandomIdContext() {
  const alphanumericCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const hexCharacters = 'abcdef0123456789';
  const uuid = uuidv4();
  return {
    insights: {
      randomUuid: uuid,
      randomUuidWithoutHyphen: uuid.replace(/-/g, ''),
      randomAlphanumeric16: getRandomCharSequence(alphanumericCharacters, 16),
      randomHex32: getRandomCharSequence(hexCharacters, 32),
      randomHex16: getRandomCharSequence(hexCharacters, 16)
    }
  };
}

function getRandomCharSequence(fromCharacters: string, targetLength: number) {
  let result = '';
  const randomArray = self.crypto.getRandomValues(new Uint8Array(targetLength));
  for (const randomNumber of randomArray) {
    result += fromCharacters.charAt(Math.round((randomNumber / 255) * (fromCharacters.length - 1)));
  }
  return result;
}
