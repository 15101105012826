import { Component, Input } from '@angular/core';

@Component({
  selector: 'url-scheme-error',
  templateUrl: './url-scheme-error.component.html',
  styleUrls: ['url-scheme-error.component.scss']
})
export class UrlSchemeErrorComponent {
  @Input() schemes: string[];
}
