<picklist
  *ngIf="usePickList"
  name="deviceSearch"
  [formControl]="control"
  [placeholder]="'deviceSearch.searchDevice' | translate"
  [loader]="loader"
  [availableItems]="pickListAvailableItems"
  [selectedItems]="selectedDevicesList.selectedItems$ | async"
  [resetOrRemoveAllClicked$]="resetOrRemoveAllClicked$"
  [availableItemsSearchFn]="searchFunc"
  [infiniteScrollFn]="rerunSearch.bind(this)"
  (removeSelectedItem)="removeSelectedDevice.emit($event)"
  (itemSearch)="resetSearchCursor()"
></picklist>

<rb-form-input
  *ngIf="!usePickList"
  class="static"
  maxlength="100"
  data-cy="deviceSearchDeviceInput"
  [formControl]="control"
  [label]="label"
  [placeholder]="'deviceSearch.searchDevice' | translate"
  [class.white]="white"
  [rbInitialOpen]="true"
  [rbFormInputAutocomplete]="searchFunc"
  [rbAutocompleteList]="dropDownContent"
  [required]="required"
  (ngModelChange)="resetSearchCursor()"
></rb-form-input>

<rb-form-errors [messages]="messages"></rb-form-errors>

<ng-container *ngIf="loader.error">
  <div *rbLoading="loader"></div>
</ng-container>

<ng-template #dropDownContent let-active="active" let-focus="focus" let-select="select">
  <ng-container *ngIf="!isSelectionMode">
    <ng-container *ngTemplateOutlet="list; context: { labels: deviceLabels }"></ng-container>
  </ng-container>
  <ng-container *ngIf="isSelectionMode">
    <ng-container
      *ngTemplateOutlet="
        list;
        context: { labels: selectedDevicesList.filteredItems$ | async | mapToLabel }
      "
    ></ng-container>
  </ng-container>

  <ng-template #list let-labels="labels">
    <div
      infiniteScroll
      (scrolled)="rerunSearch()"
      [scrollWindow]="false"
      [infiniteScrollDistance]="1"
    >
      <div *ngIf="(!labels || !labels.length) && (loader.loading$ | async) === false" class="p-3">
        {{ 'deviceSearch.noDevicesFound' | translate }}
      </div>
      <a
        href="#"
        class="rb-dropdown-item"
        *ngFor="let label of labels | alphabeticalSort"
        (click)="select(label)"
        [attr.data-cy]="'deviceOption-' + label"
        [class.active]="active === label"
        [class.focus]="focus === label"
      >
        <device-img
          *ngIf="showImages"
          [deviceTypes]="deviceTypes"
          [device]="getDevice(label)"
          [lazyLoadImg]="true"
          [resolution]="64"
        ></device-img>
        {{ label }}
      </a>
      <span class="rb-ic rb-ic-refresh rb-ic-spin px-3" *ngIf="loader.loading$ | async"></span>
    </div>
  </ng-template>
</ng-template>
