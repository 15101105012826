import { get } from 'lodash-es';
import { LinkDefinition } from '../../shared-modules/data-widgets/data-widgets-common/lib/hyperlink.service';
import { PropertyEntry } from '../features/device-general-feature/general-properties.model';
import { Thing, ThingFeature, ThingProperties } from './thing';
import { ThingPolicy } from './thing-policy';
import { AttachmentEntry } from '../services/device-attachment.service';

export const GENERAL_DEF = 'insights:general';
export const CONNECTION_STATUS_DEF = 'org.eclipse.ditto:ConnectionStatus';
export const DEVICE_TYPE_DEFAULT_NAME = 'default';

export interface DeviceRelation {
  relationId: string;
  source: string;
  target: string;
}

export interface DeviceIdParts {
  namespace: string;
  longId: string;
  shortId: string;
  typePrefix: string;
}

export interface DeviceWithStatus extends Device {
  bookingStatus?: string;
  incompatible?: boolean;
  inaccessible?: boolean;
  visibleProperties?: PropertyEntry[];
}

export interface DeviceViewDetails {
  thingId: string;
  linkDefinition: LinkDefinition;
  visiblePropertyEntries: PropertyEntry[];
}

export type resolutions = 64 | 126 | 256;

export class Device implements Thing {
  thingId = '';
  policyId = '';
  attributes: ThingProperties = {};
  features: { [name: string]: ThingFeature } = {};
  relations: DeviceRelation[] = [];
  _modified: string;
  _revision: number;
  _policy?: ThingPolicy;
  _created: string;

  private readonly _projectName: string;

  constructor(thing?: Thing, projectName?: string) {
    if (thing) {
      this.thingId = thing.thingId;
      this.policyId = thing.policyId;
      this.attributes = thing.attributes || {};
      this.features = thing.features || {};
      this._modified = thing._modified;
      this._revision = thing._revision;
      this._policy = new ThingPolicy(thing._policy, projectName);
      this._created = thing._created;
    }
    this._projectName = projectName;
  }

  static getDeviceTypeByThingId(thingId: string): string {
    return thingId.split(':')[1].split('_')[0];
  }

  static getDeviceLinksParentId(thing: Thing | Device): string | undefined {
    return get(thing, 'features.deviceLinks.properties.parentId');
  }

  getShortId() {
    const parts = this.getIdParts();
    return parts.shortId;
  }

  getIdParts(): DeviceIdParts {
    const parts = this.thingId.split(':', 2);
    const result = {
      namespace: parts[0],
      longId: parts[1],
      shortId: parts[1],
      typePrefix: ''
    };

    if (this.attributes?.type) {
      const typePrefix = this.attributes.type + '_';
      if (result.longId.indexOf(typePrefix) === 0) {
        result.typePrefix = typePrefix;
        result.shortId = result.longId.substring(typePrefix.length);
      }
    }
    return result;
  }

  getType(): string | undefined {
    return get(this.attributes, 'type');
  }

  getConnectionStatusFeature(): ThingFeature {
    const all = this.getFeaturesByDefinition(CONNECTION_STATUS_DEF);
    return all.length ? all[0] : null;
  }

  getFeaturesByDefinition(definition: string, ignoreVersion = true): ThingFeature[] {
    return this.getFeatureIdsByDefinition(definition, ignoreVersion).map(
      (name) => this.features[name]
    );
  }

  getFeatureIdsByDefinition(definition: string, ignoreVersion = true): string[] {
    return Object.keys(this.features).filter((featureId) => {
      const feature = this.features[featureId];
      if (ignoreVersion) {
        return compareDefinitionWithoutVersion(feature.definition, definition);
      } else {
        return compareDefinition(feature.definition, definition);
      }
    });
  }

  hasFeatureIdWithDefinition(featureId: string, definition: string, ignoreVersion = true): boolean {
    const feature = this.features[featureId];
    if (!feature) {
      return false;
    }
    if (ignoreVersion) {
      return compareDefinitionWithoutVersion(feature.definition, definition);
    } else {
      return compareDefinition(feature.definition, definition);
    }
  }

  hasFeature(definition: string): boolean {
    return this.getFeaturesByDefinition(definition).length > 0;
  }

  getFeatureProperties(definition: string): ThingProperties {
    const features = this.getFeaturesByDefinition(definition);
    if (features.length && features[0].properties) {
      return features[0].properties;
    }
    return {};
  }

  getFeaturePropertiesList(
    definition: string,
    ignore: string[] = []
  ): { key: string; value: any }[] {
    const properties = this.getFeatureProperties(definition);
    return Object.keys(properties)
      .filter((key) => !ignore.includes(key))
      .map((key) => ({ key: key, value: properties[key] }));
  }

  getName(): string {
    const props = this.getFeatureProperties(GENERAL_DEF);
    if (props.name) {
      return props.name;
    } else {
      return this.thingId;
    }
  }

  getImageUrl(attachmentEntry?: AttachmentEntry, resolution?: resolutions): string {
    const image = attachmentEntry || this.getPrimaryImage();

    const imageUrl = image ? attachmentUrlToImgResolution(image.url, resolution) : null;

    if (imageUrl) {
      return imageUrl;
    } else {
      return 'assets/img/vehicle_placeholder.jpg';
    }
  }

  getCreationDate(): string {
    return this._created;
  }

  setCreationDate(): void {
    this._created = new Date().toISOString();
  }

  hasDeviceImageProperty(): boolean {
    return this.features?.images?.properties?.images?.length > 0;
  }

  private getPrimaryImage() {
    const props = this.getFeatureProperties('insights:images');

    return props.images && props.images[0];
  }
}

/**
 * Used to convert device attachment url to images endpoint where resolution can be used
   From: /project-management-service/v1/${projectName}/attachments/devices/${thingId}/${label}
   To:  /project-management-service/v1/${projectName}/attachments/devices/${thingId}/images/${label}/?resolution={resolution}
 */
export function attachmentUrlToImgResolution(attachmentUrl: string, resolution: resolutions) {
  const fileName = attachmentUrl.split('/').pop();

  if (isThumbnailSupported(fileName)) {
    const parts = attachmentUrl.split('/');
    const lastIdx = parts.length - 1;
    parts.push(encodeURIComponent(parts[lastIdx]));
    parts[lastIdx] = 'images';
    parts.push(`?resolution=${resolution}`);

    return parts.join('/');
  }

  return attachmentUrl;
}

/**
 * Used to encode attachment label name in url
   From: /project-management-service/v1/${projectName}/attachments/devices/${thingId}/invalid#name.png
   To:  /project-management-service/v1/${projectName}/attachments/devices/${thingId}/invalid%23name.png
 */
export function encodeLabelInAttachmentUrl(attachmentUrl: string) {
  const parts = attachmentUrl.split('/');
  const lastIdx = parts.length - 1;
  parts[lastIdx] = encodeURIComponent(parts[lastIdx]);
  return parts.join('/');
}

function isThumbnailSupported(fileName = '') {
  const supportedFiles = ['bmp', 'png', 'gif', 'jpeg', 'jpg'];
  const fileExt = fileName.split('.').pop().toLowerCase();

  return fileExt && supportedFiles.includes(fileExt);
}

function stripVersion(definition) {
  return definition && definition.split(':').slice(0, 2).join(':');
}

function compareDefinitionWithoutVersion(definitions: string[], definition: string) {
  definition = stripVersion(definition);
  return definitions && definitions.some((def) => stripVersion(def) === definition);
}

function compareDefinition(definitions: string[], definition: string) {
  return definitions && definitions.some((def) => def === definition);
}
