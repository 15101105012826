import {
  FieldConfig,
  FieldType
} from '../../../../dashboards/widgets/input-widget/models/input.model';
import { Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import { DashboardDataService } from '../../../../dashboards/services/dashboard-data.service';
import { FilterParameterConfig } from '../../../../dashboards/widgets/filter-widget/filter-widget.model';
import { Device } from '../../../../devices/models/device';
import { QueryParameter } from '../../../query-template/models/query-parameter';
import { DsParameter, DsParameterType } from '../../data-widgets-common';
import { LinkDropdownOption } from '../link-dropdown-select/link-dropdown-select.component';
import { CollectionOverview, QueryTemplateParameter } from '../../../../shared/api-model';
import { TranslatedString } from '../../../../shared-projects/models/project.model';
import { TranslateService } from '@ngx-translate/core';
import { translate } from '../../../../shared/translation-util';

type ReferenceParameter = FieldConfig | FilterParameterConfig | UserContextParameter;

type ReferenceStrategy = (p: ReferenceParameter, ...args: any[]) => void;

type InputValueType = 'STRING' | 'TIMESTAMP' | 'TIMESTAMP_RANGE' | 'INT' | 'FLOAT' | 'BOOLEAN';

interface TypeOption extends LinkDropdownOption {
  value: DsParameterType | '';
  label: string;
  valueType: InputValueType; // input that is displayed to enter a value
  validation?: string;
}

export interface UserContextParameter {
  label: TranslatedString;
  value: string;
}

export const availableUserContextParameter: UserContextParameter[] = [
  { label: { en: 'User ID', de: 'Benutzer ID' }, value: 'insights.user.id' },
  { label: { en: 'User Name', de: 'Benutzer Name' }, value: 'insights.user.name' },
  {
    label: { en: 'User Display Name', de: 'Benutzer Display Name' },
    value: 'insights.user.displayName'
  },
  {
    label: { en: 'User Internal Name', de: 'Interner Benutzer Name' },
    value: 'insights.user.internalName'
  },
  { label: { en: 'User Email', de: 'Benutzer Email' }, value: 'insights.user.email' },
  { label: { en: 'Timestamp', de: 'Zeitstempel' }, value: 'insights.timestamp' },
  { label: { en: 'Language', de: 'Sprache' }, value: 'insights.language' },
  { label: { en: 'Time Zone', de: 'Zeitzone' }, value: 'insights.timezone' },
  {
    label: { en: 'Time Zone Offset', de: 'Zeitzonen-Offset' },
    value: 'insights.timezoneOffset'
  }
];

const availableTypeOptions: TypeOption[] = [
  { value: '', label: 'Unknown', valueType: 'STRING' },
  { value: 'string', label: 'String', valueType: 'STRING' },
  { value: 'date', label: 'Date', valueType: 'STRING', validation: '^[0-9]{4}-dd-dd$' },
  { value: 'datetime', label: 'Date time', valueType: 'TIMESTAMP' },
  { value: 'datetimerange', label: 'Date time range', valueType: 'TIMESTAMP_RANGE' },
  // {type: 'daterange', label: 'Date range'},
  { value: 'thingId', label: 'Thing Id', valueType: 'STRING' },
  { value: 'int', label: 'Integer', valueType: 'INT' },
  { value: 'float', label: 'Float', valueType: 'FLOAT' },
  { value: 'expr', label: 'Expression', valueType: 'STRING' },
  { value: 'boolean', label: 'Boolean', valueType: 'BOOLEAN' }
];

export enum InputType {
  QueryParameter = 'queryParameter',
  DeviceSearch = 'deviceSearch',
  QueryCollection = 'queryCollection',
  Default = 'default'
}

/**
 * Shows an input that can be switched between reference and value variant.
 * Reference variant: the parameter refers to a filter parameter or other variable
 * Value variant: the value is static
 */
@Component({
  selector: 'ds-parameter-input',
  templateUrl: './ds-parameter-input.component.html'
})
export class DsParameterInputComponent implements OnInit {
  // data types supported by value-input = 'STRING', 'TIMESTAMP', 'BOOLEAN', 'INT', 'FLOAT', 'TIMESTAMP_RANGE'
  typeOptions: TypeOption[] = availableTypeOptions;

  /**
   * Which types are allowed
   * null = any type is allowed
   */
  @Input() allowedTypes: (DsParameterType | '')[] | null = null;

  @Input() parameter: DsParameter;

  @Input() label: string;

  @Input() defaultLabel: string;

  @Input() inputType: InputType = InputType.Default;

  @Input() queryParameter: QueryParameter;

  @Input() deviceTypes: string[] = [];

  @Input() targetCollections: CollectionOverview[] = [];

  @Output() parameterChange = new EventEmitter<DsParameter>();

  parameterVariant: 'ref' | 'value' = 'value';

  dataType: DsParameterType = 'string';

  referenceText = '';

  value: any = '';

  readonly = false;

  typeOption: TypeOption;

  parameters: FilterParameterConfig[];
  inputParameters: FieldConfig[];

  userContextParameter = availableUserContextParameter;

  originalLabel = null;

  constructor(
    @Optional() private dashboardService: DashboardDataService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.parameters = this.getAvailableParameters();
    this.inputParameters = this.dashboardService.getInputWidgetDefinitions();

    if (!this.parameter) {
      this.parameter = { value: null };
    }

    if (this.queryParameter) {
      this.originalLabel = this.queryParameter.parameter.label;
    }

    if ('ref' in this.parameter) {
      this.parameterVariant = 'ref';
      this.referenceText = this.parameter.ref;
      this.dataType = this.parameter.type || 'string';
      this.value = this.parameter.default;
      this.readonly = this.parameter.ref.startsWith('insights.');
      if (this.queryParameter) {
        this.queryParameter.parameter.label =
          this.translateService.instant(translate('dataWidgets.parameterInput.preview')) +
          ' ' +
          (this.originalLabel || this.queryParameter.parameter.name);
      }
    }
    if ('value' in this.parameter) {
      this.parameterVariant = 'value';
      this.value = this.parameter.value;
      this.dataType = this.parameter.type || 'string';
    }

    this.filterTypes();
    this.updateTypeOption(this.dataType);
  }

  filterTypes() {
    if (this.allowedTypes) {
      this.typeOptions = availableTypeOptions.filter((t) => this.allowedTypes.includes(t.value));
    }
    if (this.parameterVariant === 'ref') {
      this.typeOptions = this.typeOptions.filter((t) => t.value !== 'expr');
    }
  }

  updateTypeOption(type: string) {
    const found = this.typeOptions.find((t) => t.value === type);
    if (found) {
      this.typeOption = found;
    } else {
      this.typeOption = this.typeOptions[0];
    }
  }

  updateQueryParameterValue(value: any) {
    this.queryParameter.updateValue(value);
    this.value = value;
    this.notifyChange();
  }

  updateValue(value: any) {
    this.value = value;
    this.notifyChange();
  }

  updateThingId(thing: Device) {
    this.value = thing.thingId;
    this.notifyChange();
  }

  updateParameterVariant(pt) {
    this.parameterVariant = pt;
    if (pt === 'value') {
      this.referenceText = '';
      if (this.queryParameter) {
        this.queryParameter.parameter.label =
          this.originalLabel || this.queryParameter.parameter.name;
      }
    }
    this.value = '';
    this.readonly = false;
    this.filterTypes();
    this.notifyChange();
  }

  setReference(referenceStrategy: ReferenceStrategy, p: ReferenceParameter, ...args: any[]) {
    const boundReferenceStrategy = referenceStrategy.bind(this, p, ...args);
    this.resetValue();
    boundReferenceStrategy();
    this.notifyChange();
  }

  setInputReference(p: FieldConfig) {
    this.referenceText = 'inputFields.' + p.technicalName;
    this.parameterVariant = 'ref';
    this.value = p.technicalName;

    this.readonly = false;
    this.filterTypes();
  }

  setFilterReference(p: FilterParameterConfig, name?: string) {
    this.referenceText = 'filterParams.' + p.name;
    if (name) {
      this.referenceText = 'filterParams.' + name;
    }
    this.parameterVariant = 'ref';
    if (this.queryParameter) {
      this.queryParameter.parameter.label =
        this.translateService.instant(translate('dataWidgets.parameterInput.preview')) +
        ' ' +
        (this.originalLabel || this.queryParameter.parameter.name);
      if (this.queryParameter.parameter.defaultValue) {
        this.value = this.queryParameter.parameter.defaultValue;
      }
    }
    if (p.defaultValue && !this.value) {
      this.value = p.multipleValues && this.isList() ? [p.defaultValue] : p.defaultValue;
    }
    this.readonly = false;
    this.filterTypes();
  }

  isParameterRefInputEnabled(): boolean {
    return (
      this.parameterVariant !== 'ref' &&
      !this.parameters?.length &&
      !this.inputParameters?.length &&
      !this.userContextParameter?.length
    );
  }

  setUserContextReference(pa: UserContextParameter) {
    this.referenceText = pa.value;
    this.parameterVariant = 'ref';
    this.value = pa.value;
    this.readonly = true;
  }

  private notifyChange() {
    if (this.parameterVariant === 'ref') {
      this.parameter = {
        ref: this.referenceText,
        type: this.dataType,
        default: this.value
      };
    }
    if (this.parameterVariant === 'value') {
      this.parameter = {
        value: this.value,
        type: this.dataType
      };
    }
    this.parameterChange.next(this.parameter);
  }

  private getAvailableParameters(): FilterParameterConfig[] {
    if (!this.dashboardService) {
      return [];
    }
    return this.dashboardService
      .getFilterParameter()
      .filter((p) => this.isFilterParameterAvailable(p));
  }

  private isInputFieldMultiSelectionType(p: FieldConfig) {
    return p.type === FieldType.MULTI_SELECTION;
  }

  private isFilterParameterAvailable(parameter: FilterParameterConfig) {
    const isList = this.isList();
    const isDateTimeRange = this.isDateTimeRange();
    if (
      (isDateTimeRange && parameter.type !== 'datetimerange2') ||
      (!isDateTimeRange && parameter.type === 'datetimerange2')
    ) {
      return false;
    }
    return (isList && parameter.multipleValues) || (!isList && !parameter.multipleValues);
  }

  private resetValue() {
    this.value = '';
  }

  private isList() {
    const tplParam: QueryTemplateParameter = this.queryParameter?.parameter;
    return tplParam?.parameterType === 'LIST';
  }

  private isDateTimeRange() {
    const tplParam: QueryTemplateParameter = this.queryParameter?.parameter;
    return tplParam?.parameterType === 'RANGE' && tplParam?.dataType === 'TIMESTAMP';
  }
}
