import { computed, effect, EffectRef, inject, Injectable, Injector, signal } from '@angular/core';
import { TranslatedPipe } from '../../../shared/pipes/translated.pipe';
import { TranslatedString } from '../../../shared-projects/models/project.model';

@Injectable({
  providedIn: 'root'
})
export class FooterRichtextTitleService {
  private translatedPipe = inject(TranslatedPipe);
  private injector = inject(Injector);
  private _title = signal<TranslatedString>(null);

  title = computed(() => {
    const title = this._title();
    return title ? this.translatedPipe.transform(title) : '';
  });

  setTitle(label: TranslatedString) {
    this._title.set(label);
  }

  createTitleEffect(onTitleChange: (title: string) => void): EffectRef {
    return effect(
      () => {
        const title = this.title();

        onTitleChange(title);
      },
      { injector: this.injector }
    );
  }
}
