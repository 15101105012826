import { Component } from '@angular/core';
import { LayoutService } from '../../layout.service';

@Component({
  selector: 'page-selector',
  templateUrl: './page-selector.component.html'
})
export class PageSelectorComponent {
  constructor(public layoutService: LayoutService) {}

  triggerHeaderTitleChange(title: string) {
    this.layoutService.currentHeaderTitle.next(title);
  }
}
