import { get } from 'lodash-es';
import { AccessRightChoice } from './device-access-right-choice';

export type ThingPolicyEntry = Partial<Record<string, Record<'subjects' | 'resources', any>>>;

export type ThingPolicyResource = 'policy:/.grant' | 'thing:/.grant';

export class ThingPolicy {
  get insightsScope(): string {
    return `${ThingPolicy.fullPrefix}${this._projectName}.`;
  }

  static readonly roleType = 'iot-insights-role';
  static readonly suitePrefix = 'iot-suite:/';
  static readonly scpPrefix = 'ext-iam.insights.';
  static readonly fullPrefix = `${ThingPolicy.suitePrefix}${ThingPolicy.scpPrefix}`;

  policyId: string;
  entries: ThingPolicyEntry;

  private readonly _projectName: string;

  constructor(policy: Partial<ThingPolicy> = {}, projectName: string) {
    const { policyId, entries = {} } = policy;

    this.policyId = policyId;
    this.entries = entries;
    this._projectName = projectName || '';
    return this;
  }

  static getResourceWriteAccessRoles(
    thingPolicy: ThingPolicy,
    resource: ThingPolicyResource,
    projectName: string
  ): string[] {
    const allPolicyKeys = Object.keys(thingPolicy.entries);
    const policyKeysWithAccess = [];

    allPolicyKeys.forEach((key: string) => {
      const resourceAccess: string[] = get(thingPolicy.entries[key].resources, resource);
      if (!ThingPolicy.grantContainsAccessRight(resourceAccess)) {
        return;
      }
      const subjectKeys = Object.keys(thingPolicy.entries[key].subjects);
      subjectKeys.forEach((subjectKey) => {
        if (subjectKey.includes(ThingPolicy.fullPrefix)) {
          policyKeysWithAccess.push(ThingPolicy.removeRolePrefixes(subjectKey, projectName));
        }
      });
    });
    return policyKeysWithAccess;
  }

  /**
   * @example
   * // output -> myCustomRole
   * ThingPolicy.removeRolePrefixes('iot-suite:/ext-iam.insights.myCustomRole');
   *
   * // output -> my_custom_role
   * ThingPolicy.removeRolePrefixes('iot-suite:/ext-iam.insights.${project}.my_custom_role');
   *
   * @param {string} prefixedRole
   * @param {string} projectName
   * @returns {string}
   */
  static removeRolePrefixes(prefixedRole: string, projectName?: string): string {
    const replacedPrefix = prefixedRole.replace(ThingPolicy.fullPrefix, '');
    if (projectName && replacedPrefix.includes(`${projectName}.`)) {
      return replacedPrefix.replace(`${projectName}.`, '');
    }
    return replacedPrefix;
  }

  /**
   * @example
   * // output -> true
   * ThingPolicy.grantContainsAccessRight(['READ', 'WRITE'])
   *
   * // output -> false
   * ThingPolicy.grantContainsAccessRight(['READ'])
   *
   * @param {string[]} grants
   * @returns {boolean}
   */
  static grantContainsAccessRight(grants: string[]): boolean {
    if (!grants) {
      return false;
    }
    return grants.includes('READ') && grants.includes('WRITE');
  }

  getAccessRightsRoles(): string[] {
    const filteredSubject = [
      ...(this.entries?.see?.subjects ? Object.keys(this.entries.see.subjects) : []),
      ...(this.entries?.manage?.subjects ? Object.keys(this.entries.manage.subjects) : [])
    ].filter((subject) => subject.startsWith(this.insightsScope));

    return [
      ...new Set(
        filteredSubject.map((policy) => ThingPolicy.removeRolePrefixes(policy, this._projectName))
      )
    ];
  }

  findRoleInPolicy(field: 'see' | 'manage' | string, role: string): string | undefined {
    if (!get(this.entries, field) || !get(this.entries[field], 'subjects')) {
      return undefined;
    }
    const policies = Object.keys(this.entries[field].subjects);
    return policies.find((key: string) => {
      return key === `${this.insightsScope}${role}`;
    });
  }

  /**
   *
   * @returns {boolean} true if it is not our Insights-Default-Policy else false
   */
  isEditablePolicy(): boolean {
    return !!this.entries['see'] && !!this.entries['manage'] && !this.policyId.endsWith(':default');
  }

  /**
   * @example
   * // output -> iot-suite:/ext-iam.insights.myCustomRole
   * ThingPolicy.getPrefixedRole('myCustomRole');
   *
   * @param {string} roleName
   * @returns {string}
   */
  getPrefixedRole(roleName: string): string {
    return `${this.insightsScope}${roleName}`;
  }

  updateThingPolicy(roleName: string, accessRight: AccessRightChoice) {
    switch (accessRight) {
      case AccessRightChoice.noAccess:
        this.removeAccess(roleName);
        break;
      case AccessRightChoice.read:
        this.removeReadWriteAccess(roleName);
        this.addReadAccess(roleName);
        break;
      case AccessRightChoice.readWrite:
        this.addReadWriteAccess(roleName);
        this.addReadAccess(roleName);
        break;
    }
  }

  removeAccess(roleName: string): void {
    this.removeReadAccess(roleName);
    this.removeReadWriteAccess(roleName);
  }

  addReadAccess(roleName: string): void {
    const findRole = this.entries['see'].subjects[this.getPrefixedRole(roleName)];
    // role access is already set as 'read' in policy
    if (findRole) {
      return;
    }
    this.entries['see'].subjects[this.getPrefixedRole(roleName)] = {
      type: ThingPolicy.roleType
    };
  }

  removeReadAccess(roleName: string): void {
    if (!this.entries['see'].subjects[this.getPrefixedRole(roleName)]) {
      return;
    }
    delete this.entries['see'].subjects[this.getPrefixedRole(roleName)];
  }

  addReadWriteAccess(roleName: string): void {
    const findRole = this.entries['manage'].subjects[this.getPrefixedRole(roleName)];
    // role access is already set as 'readWrite' in policy
    if (findRole) {
      return;
    }
    this.entries['manage'].subjects[this.getPrefixedRole(roleName)] = {
      type: ThingPolicy.roleType
    };
  }

  removeReadWriteAccess(roleName: string): void {
    if (!this.entries['manage'].subjects[this.getPrefixedRole(roleName)]) {
      return;
    }
    delete this.entries['manage'].subjects[this.getPrefixedRole(roleName)];
  }
}
