export function runBlobDownload(blob: Blob, fileName: string) {
  executeDownload(window.URL.createObjectURL(blob), fileName);
}

export function executeDownload(objectUrl: string, fileName?: string): void {
  const a: HTMLAnchorElement = document.createElement('a');
  a.href = objectUrl;
  if (fileName) {
    a.download = fileName;
  }

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function runJsonFileDownload(data: unknown, fileName: string, pretty = false) {
  let jsonString;
  if (pretty) {
    jsonString = JSON.stringify(data, null, 2);
  } else {
    jsonString = JSON.stringify(data);
  }
  const blob = new Blob([jsonString], { type: 'application/json' });
  runBlobDownload(blob, fileName);
}
