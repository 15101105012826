import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SearchFormModule } from '../../shared-modules/search-form/search-form.module';
import { SharedModule } from '../../shared/shared.module';
import { RoleApiService } from '../role/services/role-api.service';
import { RoleStoreService } from '../role/services/role-store.service';
import { UserAddService } from './services/user-add.service';
import { UserApiService } from './services/user-api.service';
import { UserAddComponent } from './user-add/user-add.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserPendingListComponent } from './user-pending-list/user-pending-list.component';
import { UserRoleInformationComponent } from './user-role-information/user-role-information.component';
import { UserRoleSelectorComponent } from './user-role-selector/user-role-selector.component';
import { UserViewComponent } from './user-view.component';
import { SharedProjectsModule } from '../../shared-projects/shared-projects.module';
import { RoleSearchModule } from '../../shared-modules/role-search/role-search.module';
import { UserCredentialsComponent } from './user-credentials/user-credentials.component';
import { NgStringPipesModule } from 'ngx-pipes';
import { SimpleSearchModule } from '../../shared-modules/simple-search/simple-search.module';
import { DataInspectionModule } from '../../shared-modules/data-inspection/data-inspection.module';
import { UserListExportComponent } from './user-list-export/user-list-export.component';
import { UserInviteComponent } from './user-invite/user-invite.component';

@NgModule({
  declarations: [
    UserViewComponent,
    UserPendingListComponent,
    UserListComponent,
    UserAddComponent,
    UserRoleInformationComponent,
    UserEditComponent,
    UserRoleSelectorComponent,
    UserCredentialsComponent,
    UserListExportComponent,
    UserInviteComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgStringPipesModule,
    SearchFormModule,
    SharedProjectsModule,
    RoleSearchModule,
    SimpleSearchModule,
    DataInspectionModule
  ],
  exports: [UserRoleSelectorComponent],
  providers: [UserApiService, UserAddService, RoleStoreService, RoleApiService]
})
export class UserModule {}
