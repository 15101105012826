import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { LanguageItem } from '@inst-iot/bosch-angular-ui-components/molecules/language-selector/language-selector.component';
import { TranslateService } from '@ngx-translate/core';
import flatpickr from 'flatpickr';
import { english } from 'flatpickr/dist/l10n/default';
import * as moment from 'moment';
import 'moment-timezone';
import { SettingsService } from './settings.service';

const flatPickrLocalize = {
  en: {
    ...english,
    dateFormat: 'Y-m-d'
  },
  de: {
    weekdays: {
      shorthand: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      longhand: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']
    },
    months: {
      shorthand: [
        'Jan',
        'Feb',
        'Mär',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dez'
      ],
      longhand: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember'
      ]
    },
    firstDayOfWeek: 1,
    weekAbbreviation: 'KW',
    rangeSeparator: ' bis ',
    scrollTitle: 'Zum Ändern scrollen',
    toggleTitle: 'Zum Umschalten klicken',
    dateFormat: 'd.m.Y'
  }
};

export const customMomentLocaleSettings = {
  en: {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: 'a few seconds',
      ss: '%d seconds',
      m: 'a minute',
      mm: '%d minutes',
      h: 'an hour',
      hh: '%d hours',
      d: 'a day',
      dd: '%d days',
      w: 'a week',
      ww: '%d weeks',
      M: 'a month',
      MM: '%d months',
      y: 'a year',
      yy: '%d years'
    }
  },
  de: {
    relativeTime: {
      future: 'in %s',
      past: 'vor %s',
      s: 'ein paar Sekunden',
      ss: '%d Sekunden',
      m: 'eine Minute',
      mm: '%d Minuten',
      h: 'eine Stunde',
      hh: '%d Stunden',
      d: 'ein Tag',
      dd: '%d Tage',
      w: 'eine Woche',
      ww: '%d Wochen',
      M: 'ein Monat',
      MM: '%d Monate',
      y: 'ein Jahr',
      yy: '%d Jahre'
    }
  }
};

export const timeFormat = {
  hour24: {
    en: {
      longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'YYYY-MM-DD',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'HH:mm:ss.SSS'
      }
    },
    de: {
      longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD.MM.YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'HH:mm:ss.SSS'
      }
    }
  },
  hour12: {
    en: {
      longDateFormat: {
        LT: 'h:mm A',
        LTS: 'h:mm:ss A',
        L: 'YYYY-MM-DD',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY hh:mm A',
        LLLL: 'h:mm:ss.SSS A'
      }
    },
    de: {
      longDateFormat: {
        LT: 'h:mm A',
        LTS: 'h:mm:ss A',
        L: 'DD.MM.YYYY',
        LL: 'D.MMMM YYYY',
        LLL: 'D MMMM YYYY hh:mm A',
        LLLL: 'h:mm:ss.SSS A'
      }
    }
  }
};

export const INITIAL_LANGUAGE = new InjectionToken<string>('INITIAL_LANGUAGE');

@Injectable({
  providedIn: 'root'
})
export class LanguagesService {
  languages = {
    en: 'English',
    de: 'German'
  };

  defaultLanguage = 'en';
  translationsLoaded = false;

  get currentLanguage() {
    return this.translateService.currentLang;
  }

  get hasCustomTimezone(): boolean {
    return !!this.settingsService.customTimezone;
  }

  /**
   *  Returns the user configured timezone name (e.g. Europe/Berlin)
   *  or if not configured the systems timezone as fallback
   */
  get timezone(): string {
    return this.settingsService.customTimezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  /**
   * Returns the timezone as a human-readable string
   */
  get timezoneFormatted(): string {
    return moment.tz(this.timezone).format('z Z');
  }

  constructor(
    private translateService: TranslateService,
    private settingsService: SettingsService,
    @Optional() @Inject(INITIAL_LANGUAGE) private initialLanguage: string
  ) {
    this.initLanguage();
    this.updateTimeFormat();
    this.updateTZ();
  }

  mapLanguages(): LanguageItem[] {
    return Object.keys(this.languages).map((lang) => {
      return { id: lang, name: this.languages[lang] };
    });
  }

  getAutoConfiguredLanguage() {
    const browserLang = this.translateService.getBrowserLang();
    if (browserLang in this.languages) {
      return browserLang;
    } else {
      return this.defaultLanguage;
    }
  }

  initLanguage() {
    this.translateService.use(this.getInitialLanguage());
    // set ss threshold to display time ago for seconds, i.e. 10 seconds ago
    moment.relativeTimeThreshold('ss', 10);

    this.translateService.onLangChange.subscribe((langEvent) => {
      if (customMomentLocaleSettings[langEvent.lang]) {
        moment.updateLocale(langEvent.lang, customMomentLocaleSettings[langEvent.lang]);
      }
      if (flatPickrLocalize[langEvent.lang]) {
        flatpickr.localize(flatPickrLocalize[langEvent.lang]);
        flatpickr.defaultConfig.dateFormat = flatPickrLocalize[langEvent.lang].dateFormat;
      }
      this.translationsLoaded = true;
    });
  }

  updateLanguage(language: string): void {
    if (language !== this.currentLanguage) {
      this.settingsService.language = language;
      this.translateService.use(language);
      location.reload();
    }
  }

  getInitialLanguage() {
    const userDefinedLanguage = this.settingsService.language;

    if (this.initialLanguage && Object.keys(this.languages).includes(this.initialLanguage)) {
      return this.initialLanguage;
    } else {
      return userDefinedLanguage || this.getAutoConfiguredLanguage();
    }
  }

  updateTimezone(timezone: string): void {
    this.settingsService.customTimezone = timezone;
    this.updateTZ();
  }

  updateTimeFormat() {
    const lang = this.currentLanguage;
    if (this.settingsService.timeFormat === '24') {
      moment.updateLocale(lang, timeFormat['hour24'][lang]);
    } else {
      moment.updateLocale(lang, timeFormat['hour12'][lang]);
    }
  }

  updateTZ() {
    moment.tz.setDefault(this.timezone);
  }
}
