import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { ProjectsService } from '../../../shared-projects/services/projects.service';
import { Role } from '../models/role.model';
import { RoleApiService } from './role-api.service';
import { first, map, switchMap, take, tap } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class RoleStoreService {
  roles$: ReplaySubject<Role[]>;

  constructor(private roleApiService: RoleApiService, private projectsService: ProjectsService) {
    this.initService();
    this.subscribeToProjectChange();
  }

  getRoleList(searchTerm?: string, page = 0, pageSize = 200): Observable<Role[]> {
    return this.roleApiService.getRoleList(page, pageSize, searchTerm).pipe(
      tap((rolesResponse: HttpResponse<Role[]>) => {
        if (!searchTerm) {
          this.roles$.next(rolesResponse.body);
        }
      }),
      map((rolesResponse: HttpResponse<Role[]>) => rolesResponse.body)
    );
  }

  getRolesByName(rolesName: string[], includeAdmin = false): Observable<Role[]> {
    return this.roles$.pipe(
      take(1),
      switchMap((roles) => {
        const rolesByName = [];
        const notFound = [];

        if (includeAdmin) {
          rolesName = [...new Set(['admin', ...rolesName])];
        }

        rolesName.forEach((roleName) => {
          const found = Role.findRoleByName(roleName, roles);
          if (found) {
            rolesByName.push(found);
          } else {
            notFound.push(roleName);
          }
        });

        if (!notFound.length) {
          return of(rolesByName);
        } else {
          return forkJoin(notFound.map((role) => this.getRoleList(role))).pipe(
            map((response) => {
              response.forEach((res, idx) => {
                const found = Role.findRoleByName(notFound[idx], res);
                if (found) {
                  rolesByName.push(found);
                }
              });

              return rolesByName;
            })
          );
        }
      })
    );
  }

  getRoleByRoleName(roleName: string): Observable<string | undefined> {
    if (!roleName) {
      return of(undefined);
    }
    return this.roles$.pipe(
      take(1),
      switchMap((roles) => {
        const found = Role.findRoleByName(roleName, roles);
        if (found) {
          return of(found[0]);
        }
        return this.getRoleList(roleName).pipe(
          map((roles) => {
            return Role.findRoleByName(roleName, roles)?.roleName;
          })
        );
      })
    );
  }

  private initService(): void {
    this.roles$ = new ReplaySubject<Role[]>();
    this.getRoleList().pipe(first()).subscribe();
  }

  private subscribeToProjectChange(): void {
    this.projectsService.projectConfigEvents.subscribe((event) => {
      // we receive null if the project is changed -> reinitialize the service
      if (event === null) {
        this.initService();
      }
    });
  }
}
