<div class="container-fluid">
  <h1 translate>settings.title</h1>

  <div>
    <rb-form-select
      [(ngModel)]="language"
      name="language"
      [label]="'settings.language' | translate"
    >
      <option value="null" translate>settings.browserDefault</option>
      <option *ngFor="let l of languages" value="{{ l.id }}">{{ l.name }}</option>
    </rb-form-select>
    <rb-form-select
      name="timezone"
      [(ngModel)]="timezone"
      [label]="'settings.timezone' | translate"
    >
      <option value="null" translate>settings.systemDefault</option>
      <option *ngFor="let tz of timezones" [value]="tz.name">{{ tz.name }} ({{ tz.info }})</option>
    </rb-form-select>
    <rb-form-select
      name="timeFormat"
      [(ngModel)]="timeFormat"
      [label]="'settings.timeformat' | translate"
    >
      <option *ngFor="let format of timeFormats" [value]="format.value">
        {{ format.name | translate }}
      </option>
    </rb-form-select>

    <p class="mt-3">
      <button
        class="rb-btn rb-danger"
        [confirmation]="'settings.resetAllConfirm' | translate"
        (confirmed)="resetStorage()"
        translate
      >
        settings.resetAll
      </button>
    </p>
  </div>
</div>
