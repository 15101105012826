import { Component } from '@angular/core';

@Component({
  selector: 'login-complete',
  templateUrl: './login-complete.component.html'
})
export class LoginCompleteComponent {
  closeWindow() {
    window.close();
  }
}
