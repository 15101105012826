import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ClipboardService {
  copyToClipboard(text: string): boolean {
    let input = document.createElement('textarea');
    input.innerHTML = text;
    input = this.placeOutSideOfViewport(input);
    const body = document.querySelector('body');
    body.appendChild(input);
    input.focus();
    input.select();
    try {
      if (!document.execCommand('copy')) {
        console.warn('Unable to copy path to clipboard.');
      }
    } catch (e) {
      console.error('Unable to copy path to clipboard', e);
    }
    body.removeChild(input);
    return true;
  }

  private placeOutSideOfViewport(input) {
    input.style.position = 'fixed';
    input.style.left = 0;
    input.style.top = 0;
    input.style.width = '2rem';
    input.style.height = '2rem';
    input.style.padding = 0;
    input.style.background = 'transparent';
    input.style.border = 'none';
    input.style.outline = 'none';
    input.style.boxShadow = 'none';
    return input;
  }
}
