import { Component, Input } from '@angular/core';
import { RichTextImageDimension } from '../image-properties-editor.component';

@Component({
  selector: 'image-dimension-editor',
  templateUrl: './image-dimension-editor.component.html'
})
export class ImageDimensionEditorComponent {
  @Input() dimension: RichTextImageDimension;
  @Input() renderVertically: boolean;
}
