import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HasProjectRoleDirective } from './directives/has-project-role.directive';
import { DisplayRolesPipe } from './pipes/display-roles.pipe';
import { HasReadAccessPipe } from './pipes/has-read-access.pipe';
import { HasWriteAccessPipe } from './pipes/has-write-access.pipe';
import { ProjectUrlPipe } from './pipes/project-url.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    HasProjectRoleDirective,
    DisplayRolesPipe,
    ProjectUrlPipe,
    HasReadAccessPipe,
    HasWriteAccessPipe
  ],
  exports: [
    HasProjectRoleDirective,
    DisplayRolesPipe,
    ProjectUrlPipe,
    HasReadAccessPipe,
    HasWriteAccessPipe
  ]
})
export class SharedProjectsModule {}
