export function transformNumber(value: any, language: string, args?: any): string {
  if (isNaN(value)) {
    return '';
  }
  if (typeof value !== 'number') {
    value = parseFloat(value);
  }

  if (args) {
    args = args.replace('-', '.').split('.');
    // tslint:disable-next-line:max-line-length
    return value.toLocaleString(language, {
      minimumIntegerDigits: args[0],
      minimumFractionDigits: args[1],
      maximumFractionDigits: args[2]
    });
  } else {
    return value.toLocaleString(language);
  }
}
