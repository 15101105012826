import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapToLabel'
})
export class DeviceSearchMapToLabelPipe implements PipeTransform {
  transform(value: { label: string; thingId: string }[]): string[] {
    return value.map((v) => v.label);
  }
}
