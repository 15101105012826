import { badgeTypes, SyntaxNode } from './models/syntax-node.model';
import { ChangeEvent, ConditionChip } from './models/condition-chip.model';
import { InputObject } from './models/util-objects.model';
import { Params } from '@angular/router';

export abstract class QueryConditionBase {
  chips: ConditionChip[] = [];

  activeChip: ConditionChip;

  syntaxTree: SyntaxNode;

  syntaxErrors: SyntaxError[] = [];

  simpleLogic: string;

  defaultJoiner: 'and' | 'or' = 'and';

  queryParams: Params;

  abstract createChip(inputs: InputObject, index?: number);

  updateChip(inputs: InputObject) {
    const idx = this.chips.indexOf(this.activeChip);
    this.chips[idx] = new ConditionChip(this.activeChip.index, inputs);
  }

  selectChip(chip?: ConditionChip) {
    if (!chip || chip instanceof ConditionChip) {
      this.activeChip = chip;
    }
  }

  removeChip(chip: ConditionChip) {
    this.chips.splice(this.chips.indexOf(chip), 1);
    this.chips = [...this.chips];
  }

  initSimpleLogic() {
    let simpleLogic = '';
    this.chips.forEach((chip: ConditionChip, idx: number) => {
      if (idx === 0) {
        simpleLogic += this.chips[0].visualIndex;
      } else {
        simpleLogic += badgeTypes[this.defaultJoiner] + this.chips[idx].visualIndex;
      }
    });
    this.simpleLogic = String(simpleLogic);
  }

  validateChangeEvent(changeEvent: ChangeEvent, chip: ConditionChip, logic = '') {
    switch (changeEvent) {
      case 'DELETE':
        logic = logic.replace(new RegExp('(and|or)' + chip.visualIndex + '(?!d)', 'ig'), '');
        logic = logic.replace(new RegExp('^' + chip.visualIndex + '(and|or)', 'ig'), '');
        break;
      case 'INSERT':
        logic += badgeTypes[this.defaultJoiner] + chip.visualIndex;
        break;
      case 'UPDATE':
        // Updates SyntaxTree with edited Chips, Left intentionally blank
        break;
    }

    this.simpleLogic = String(logic);
  }
}
