import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PathInfo } from '../../../../shared-modules/data-selection/data-info-util';

export enum RichTextImageType {
  FROM_URL = 'fromUrl',
  BASE_64 = 'base64',
  DEVICE_IMAGE = 'deviceImage',
  FROM_FILE = 'fromFile'
}

export interface SlateFileUpload {
  files: FileList;
  config?: RichTextImageDimension;
}

export interface RichTextImageDimension {
  maxHeight: number;
  maxWidth: number;
}

export interface RichTextImageSettings extends RichTextImageDimension {
  imageType: RichTextImageType;
  imageSrc?: string;
  pathValue?: string;
}

@Component({
  selector: 'image-properties-editor',
  templateUrl: './image-properties-editor.component.html',
  styleUrls: ['./image-properties-editor.component.scss']
})
export class ImagePropertiesEditorComponent implements OnInit {
  imageType = RichTextImageType;
  @Input() imageSettings: RichTextImageSettings;

  @Input() paths: PathInfo[];
  @Input() hideFilePicker: boolean;

  @Output() settingsChanged = new EventEmitter<RichTextImageSettings>();
  @Output() closePopover = new EventEmitter();
  @Output() uploadFromFile = new EventEmitter<SlateFileUpload>();
  uploadedFiles: FileList;
  isNewImage = false;
  defaultPathToShow = '';
  externalUrlPlaceholder = '';

  ngOnInit() {
    if (!this.imageSettings) {
      this.setDefaultValues();
    }
    const iteratorSupported = this.paths.find((path) => path.path.startsWith('[i].'));
    const index = iteratorSupported ? '[i]' : '[0]';
    this.defaultPathToShow = `${index}.features.images.properties.images`;
    this.externalUrlPlaceholder =
      'http://bosch-iot-insights.com/ui/project/datapath=${' + index + '.payload.datapath}';
  }

  private setDefaultValues() {
    this.imageSettings = {} as RichTextImageSettings;
    this.isNewImage = true;
    this.imageSettings.maxHeight = 50;
    this.imageSettings.maxWidth = 50;
    if (this.defaultPathToShow) {
      this.imageSettings.imageType = this.imageType.DEVICE_IMAGE;
      this.imageSettings.pathValue = this.defaultPathToShow;
    } else {
      this.imageSettings.imageType = this.imageType.FROM_URL;
    }
  }

  imageTypeChange(type: RichTextImageType) {
    if (type === RichTextImageType.DEVICE_IMAGE) {
      this.imageSettings.pathValue = this.defaultPathToShow;
    } else {
      this.imageSettings.pathValue = undefined;
    }
  }

  close() {
    this.closePopover.emit();
  }

  save() {
    if (this.imageSettings.imageType === RichTextImageType.FROM_FILE) {
      this.uploadFromFile.emit({
        files: this.uploadedFiles,
        config: {
          maxHeight: this.imageSettings.maxHeight,
          maxWidth: this.imageSettings.maxWidth
        }
      });
    } else {
      this.settingsChanged.emit(this.imageSettings);
    }

    // closing the popover will destroyed this component. Hence it must be called only after necessary events are thrown.
    this.close();
  }
}
