import { Pipe, PipeTransform } from '@angular/core';
import { capitalize } from 'lodash-es';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Role } from '../../project-admin/role/models/role.model';
import { RoleStoreService } from '../../project-admin/role/services/role-store.service';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'displayRoles'
})
export class DisplayRolesPipe implements PipeTransform {
  constructor(private roleService: RoleStoreService, private translateService: TranslateService) {}

  /**
   * The DisplayRolesPipe can be used to resolve roleNames by their roleDisplayName and render them as a comma separated list
   *
   * @example
   * // in your template
   * {{ myRoles | displayRoles | async }}
   */
  transform(roleNames: string | string[] | undefined): Observable<string> {
    return new Observable<string>((obs) => {
      if (!roleNames?.length) {
        obs.next(capitalize(this.translateService.instant('none')));
        obs.complete();
        return obs;
      }

      this.roleService.roles$.pipe(take(1)).subscribe((allRoles: Role[]) => {
        let roleNameMapping = '';

        if (Array.isArray(roleNames)) {
          roleNameMapping = roleNames
            .map(
              (roleName: string) =>
                Role.findRoleByName(roleName, allRoles)?.roleDisplayName ?? roleName
            )
            .join(', ');
        } else if (typeof roleNames === 'string') {
          roleNameMapping = Role.findRoleByName(roleNames, allRoles)?.roleDisplayName ?? roleNames;
        }

        roleNameMapping === ''
          ? obs.next(capitalize(this.translateService.instant('none')))
          : obs.next(roleNameMapping);
        obs.complete();
      });

      return obs;
    });
  }
}
