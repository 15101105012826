import { ErrorFormatter } from '@inst-iot/bosch-angular-ui-components';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { translate } from '../../shared/translation-util';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomErrorFormatter implements ErrorFormatter {
  constructor(private translateService: TranslateService) {}

  getDetails(error: any): string {
    if (!(error instanceof HttpErrorResponse)) {
      return null;
    }

    try {
      error.error.requestId = error['requestId'];
      const parsedError = JSON.parse(error.error);
      return JSON.stringify(parsedError, null, 2);
    } catch (e) {
      if (
        typeof error.error === 'object' &&
        error.error &&
        !(error.error instanceof ProgressEvent) &&
        !(error.error instanceof Blob)
      ) {
        return JSON.stringify(error.error, null, 2);
      }
      if (error.error === 'abort') {
        return `Request aborted. Request-Id: ${error['requestId']}`;
      }
      if (error.message) {
        return `${error.message}\nRequest-Id: ${error['requestId']}`;
      }
      return `Request-Id: ${error['requestId']}`;
    }
  }

  transform(error: any): string | null {
    if (!(error instanceof HttpErrorResponse)) {
      return null;
    }
    if (error.status === 404) {
      return this.translateService.instant(translate('error.notFound'));
    } else if (error.status === 500) {
      return this.translateService.instant(translate('error.internalServerError'));
    } else if (error.status === 0) {
      return this.translateService.instant(translate('error.unknownError'));
    } else if (typeof error.error === 'object' && error.error && error.error.message) {
      return error.error.message;
    } else if (error.error === 'abort') {
      return this.translateService.instant(translate('error.requestAborted'));
    } else {
      try {
        const parsedError = JSON.parse(error.error);
        if (parsedError.message) {
          return parsedError.message;
        }
      } catch (e) {
        return error.message;
      }
    }
    return null;
  }
}
