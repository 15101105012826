import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { SelectDatasetFilterComponent } from './select-dataset-filter.component';
import { CollectionSelectorModule } from '../collection-selector/collection-selector.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [SelectDatasetFilterComponent],
  imports: [CommonModule, SharedModule, CollectionSelectorModule],
  exports: [SelectDatasetFilterComponent]
})
export class SelectDatasetFilterModule {}
