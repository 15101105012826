import { Operator, OperatorType } from './operator.model';
import { FieldDefinitionType } from '../../dynamic-filter/dynamic-filter.model';
import { SearchType } from '../../data-selection/data-info-util';

export type SupportedPathType =
  | 'string'
  | 'boolean'
  | 'number'
  | 'null'
  | 'date'
  | 'objectId'
  | 'datetimerange'
  | 'json';

export type ChangeEvent = 'DELETE' | 'INSERT' | 'UPDATE' | 'INPUT';

export class ConditionChip {
  index: number;
  value: any;
  type: SupportedPathType | FieldDefinitionType;
  searchType: SearchType;
  input: string;
  i18nLabel: string;
  operatorID: OperatorType;
  fieldName: string;
  /**
   * Available for select options when we have a mixed filter with different value paths, e.g.
   * FIND, AGGREGATE options checked against object.type
   * EXTERNAL_REST_REQUEST checked against object.channel
   **/
  customValuePath: string;
  // used in select inputs to display the label of the option
  valueLabel: string;

  constructor(index: number, inputs: Partial<ConditionChip>) {
    this.index = index;
    Object.assign(this, inputs);
  }

  get operator(): Operator {
    return Operator.byTechnicalId(this.operatorID);
  }

  get visualIndex(): string {
    return String(this.index + 1);
  }

  get isValid(): boolean {
    return !!this.input;
  }

  get isPristine(): boolean {
    return !this.input && !this.value;
  }

  static getPrefilledDefaultChip(index = 0): ConditionChip {
    return new ConditionChip(index, {
      operatorID: 'eq',
      searchType: 'path',
      type: 'string',
      input: '',
      value: '',
      fieldName: undefined,
      i18nLabel: undefined
    });
  }
}
