import { Component, Input } from '@angular/core';
import { Operator, OperatorType } from '../models/operator.model';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'operator-selector',
  templateUrl: './operator-selector.component.html',
  styleUrls: ['./operator-selector.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: OperatorSelectorComponent, multi: true }]
})
export class OperatorSelectorComponent implements ControlValueAccessor {
  @Input()
  operatorsList = Operator.operators.filter(
    (operator: Operator) => operator.technicalID !== 'regex'
  );

  selectedOperator: Operator;

  onChange: (operator: Operator) => void;

  onTouched;

  @Input()
  disabled = false;

  @Input()
  unsupportedOperators: OperatorType[] = [];

  setOperator(selection: Operator) {
    this.writeValue(selection);
    this.onChange(selection);
  }

  registerOnChange(onChange: (operator: Operator) => void) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  writeValue(operator: Operator): void {
    this.selectedOperator = operator;
  }
}
