import { TranslatedString } from '../../shared-projects/models/project.model';

export type CustomFeaturePropertyType =
  | 'boolean'
  | 'status'
  | 'text'
  | 'number'
  | 'double'
  | 'textMultiline'
  | 'date'
  | 'datetime'
  | 'datetimeMediumSeconds'
  | 'hyperlink'
  | 'device'
  | 'selection';

export interface SelectionOption {
  value: any;
  label: string;
}

export interface CustomFeatureProperty {
  name: string;
  description?: TranslatedString;
  type: CustomFeaturePropertyType;
  defaultValue: any;
  regexPattern: string;
  selectOptions?: SelectionOption[];
  /**
   * if property is required it has to be set in the device creation dialog
   */
  required: boolean;
  tracked: boolean;
  allowedDeviceType?: string;
  customValuesAllowed?: boolean;
  multipleValuesAllowed?: boolean;
  icon?: string;
}

export enum HistoryTrackingState {
  none,
  some,
  all
}

export interface DeviceTypeFeature {
  /**
   * Identifier of the feature. eg. insights:details:1
   */
  definition: string;
  /**
   * Default name when creating the feature on a Thing e.g. details
   */
  defaultName: string;
  /**
   * Optional List of possible properties and their description and default value
   */
  customProperties?: CustomFeatureProperty[];
  /**
   * Custom properties for UI configuration
   */
  properties?: any;
  /**
   * Whether the feature can be added/edited by the user
   */
  editable: boolean;
  /**
   * If it is mandatory, it will be added by default to an new feature.
   */
  mandatory: boolean;
  /**
   * Whether the change history of this feature will be tracked or not.
   */
  tracked: boolean;
  /**
   * Whether this is a virtual feature, that only exists on the UI, but not in the Thing.
   * If it is virtual, editable, mandatory and multiple are ignored
   */
  virtual?: boolean;
  /**
   * The icon to be displayed from the rb-icon font
   */
  icon?: string;
}

export enum BookableStatus {
  NOT_BOOKABLE = 'NOT_BOOKABLE',
  BOOKABLE = 'BOOKABLE',
  MANDATORY = 'MANDATORY',
  BOOKABLE_WITH_CONFIRMATION = 'BOOKABLE_WITH_CONFIRMATION'
}

export interface DeviceTypeDefinition {
  /**
   * Identifier of the type
   */
  type: string;
  /**
   * Label for the Type (displayed in the UI)
   */
  label: TranslatedString;
  /**
   * Optional definition, this device type is based on
   */
  definition?: string;
  /**
   * Description of features that are offered on the UI for the user to add/modify
   */
  features: DeviceTypeFeature[];

  /**
   * Default icon from the rb-icon font
   */
  icon: string;

  /**
   * Whether the device type is shown in the Navigation
   */
  visible?: boolean;

  /**
   * Whether the device type is bookable, non-bookable or mandatory in the calendar
   */
  bookable?: BookableStatus;

  /**
   * Order in which the device types are displayed
   */
  order?: number;

  /**
   * Confirmation message to be displayed before confirming the booking
   */
  confirmationMessage?: TranslatedString;
}

export const DEFAULT_DEVICE_TYPE: DeviceTypeDefinition = {
  type: 'device',
  definition: 'device',
  label: { en: 'Device' },
  visible: true,
  bookable: BookableStatus.NOT_BOOKABLE,
  icon: 'rb-ic-car',
  features: [
    {
      definition: 'insights:general',
      defaultName: 'general',
      editable: true,
      mandatory: true,
      tracked: false
    },
    {
      definition: 'insights:details',
      defaultName: 'details',
      editable: true,
      mandatory: false,
      tracked: false
    },
    {
      definition: 'insights:images',
      defaultName: 'images',
      editable: true,
      mandatory: true,
      tracked: false
    },
    {
      definition: 'insights:devicelinks',
      defaultName: 'devicelinks',
      editable: true,
      mandatory: true,
      tracked: false
    }
  ]
};

export class DeviceType implements DeviceTypeDefinition {
  type: string;
  definition: string;
  features: DeviceTypeFeature[];
  label: TranslatedString;
  icon: string;
  bookable: BookableStatus;

  constructor(def?: DeviceTypeDefinition) {
    if (def) {
      this.type = def.type || '';
      this.definition = def.definition || null;
      this.features = def.features || [];
      this.label = def.label || { en: '' };
      this.icon = def.icon || null;
      this.bookable = def.bookable;
    }
  }

  getFeatures(definition: string): DeviceTypeFeature[] {
    return this.features.filter((f) => f.definition === definition);
  }

  getFeatureByDefinitionAndName(definition: string, name: string): DeviceTypeFeature {
    return this.features.find((f) => f.definition === definition && f.defaultName === name);
  }
}
