import { PathLocationStrategy } from '@angular/common';

export function isInFrame() {
  return window.top !== window;
}

let embeddingPath: string = null; // e.g. /embed/proj1234/embedId.html?params=xy
let initialPath: string = null; // e.g. /ui/project/proj1234/views/dashboards/ov
let initialLang: string = null; // e.g. en
let fullscreen = null; // e.g. true
let prefixUrl: string = null; // e.g. https://staging.onedesk.bosch.io/external-app/

export function detectInitialValues(location: Location) {
  embeddingPath = location.href; // assumption, this is the embedding path when loaded within an iframe
  const initialPathElement: HTMLMetaElement = document.querySelector('meta[name="initialPath"]');

  const searchParams = new URLSearchParams(location.search);
  if (searchParams.has('initialLang')) {
    initialLang = searchParams.get('initialLang');
    searchParams.delete('initialLang');
  }
  if (searchParams.has('fullscreen')) {
    fullscreen = searchParams.get('fullscreen') !== 'false';
    searchParams.delete('fullscreen');
  }
  if (searchParams.has('prefixUrl')) {
    prefixUrl = searchParams.get('prefixUrl');
    searchParams.delete('prefixUrl');
  }
  if (searchParams.has('initialPath')) {
    initialPath = searchParams.get('initialPath');
    searchParams.delete('initialPath');
    initialPath += '?' + searchParams.toString();
  } else if (initialPathElement) {
    initialPath = initialPathElement.content + '?' + searchParams.toString();
  }
}

export function getInitialLang() {
  return initialLang;
}

export function getFullScreen() {
  return fullscreen;
}

export function getPrefixURL() {
  return prefixUrl;
}

export function reloadEmbeddedUi(redirectPath?: string) {
  const embeddingOnlyPath = embeddingPath.split('?')[0];
  if (redirectPath) {
    const [path, query] = redirectPath.split('?');
    const params = new URLSearchParams(query);
    params.set('initialPath', path);
    window.location.href = embeddingOnlyPath + '?' + params.toString();
  } else {
    const params = new URLSearchParams(location.search);
    params.set('initialPath', location.pathname);
    window.location.href = embeddingOnlyPath + '?' + params.toString();
  }
}

export function goToInitialPathIfSpecified() {
  if (initialPath) {
    window.history.replaceState(undefined, 'initial redirect', getWithBasePath(initialPath));
  }
}

function getWithBasePath(path: string): string {
  const basePath = new URL(document.baseURI).pathname;
  if (!path.startsWith(basePath)) {
    return basePath.slice(0, -1) + path;
  }
  return path;
}

export class EmbeddedLocationStrategy extends PathLocationStrategy {
  pushState(state: any, title: string, url: string, queryParams: string) {
    // do nothing, responsible for the history is the parent frame
  }

  replaceState(state: any, title: string, url: string, queryParams: string) {
    // do nothing, responsible for the history is the parent frame
  }
}
