import {
  Component,
  createComponent,
  Directive,
  EnvironmentInjector,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  DialogCloseEventWithResult,
  LoadingEntity,
  ModalService
} from '@inst-iot/bosch-angular-ui-components';
import { Observable } from 'rxjs';

@Component({
  selector: 'confirmation-dialog',
  template: `
    <ng-template #dialog>
      <rb-dialog
        [dialogTitle]="confirmTitle || 'confirmation.title' | translate"
        [alert]="{ type: 'warning', errorCode: '' }"
        [confirmationButtonLabel]="
          isLoaderDoneOnce
            ? isActionFailed
              ? ('confirmation.retry' | translate)
              : false
            : (confirmLabel || 'confirmation.confirm' | translate)
        "
        [cancelButtonLabel]="
          isActionSuccessful
            ? ('confirmation.close' | translate)
            : (abortLabel || 'confirmation.abort' | translate)
        "
        [optionalButtonLabel]="customButtonLabel"
        [headline]="headline"
        [confirmationButtonCloseOnClick]="!confirmationObservable"
        [confirmationButtonAction]="confirmAction.bind(this)"
      >
        <span *ngIf="text && !getTemplateRef()" [innerHTML]="text"></span>
        <ng-container *ngIf="!text">{{ 'confirmation.text' | translate }}</ng-container>
        <ng-container *ngIf="text && getTemplateRef()">
          <ng-container *ngTemplateOutlet="getTemplateRef()"></ng-container>
        </ng-container>
        <ng-container *ngIf="hint">
          <ng-container *ngTemplateOutlet="hint"></ng-container>
        </ng-container>
        <ng-container *ngIf="confirmationObservable">
          <div *ngIf="loader.loading || loader.error || loader.result" class="mt-3">
            <div *rbLoading="loader"></div>
            <rb-callout *ngIf="!loader.loading && loader.result" [type]="'success'" class="mt-3">
              <div>
                {{ 'confirmation.actionSuccess' | translate }}
                <div *ngIf="confirmationSuccessMessage" class="mt-2">
                  {{ confirmationSuccessMessage | translate: loader.result }}
                </div>
              </div>
            </rb-callout>
          </div>
        </ng-container>
      </rb-dialog>
    </ng-template>
  `
})
export class ConfirmationDialogComponent {
  @Input() text: string | TemplateRef<any>;
  @Input() confirmTitle: string;
  @Input() abortLabel: string;
  @Input() confirmLabel: string;
  @Input() headline: string;

  @Input() confirmationObservable: Observable<any>;
  @Input() confirmationSuccessMessage: string;

  @Input() hint: TemplateRef<any>;
  @Input() customButtonLabel: string;

  @Output() confirmed = new EventEmitter();

  @ViewChild('dialog', { static: true }) templateRef: TemplateRef<any>;

  loader = new LoadingEntity<any>();

  getTemplateRef(): TemplateRef<any> {
    return this.text instanceof TemplateRef ? this.text : null;
  }

  confirmAction(): void {
    if (!this.confirmationObservable) {
      return;
    }
    this.loader.run(this.confirmationObservable).subscribe(() => {
      this.confirmed.next(true);
    });
  }

  get isLoaderDoneOnce(): boolean {
    return this.loader.runs > 0;
  }

  get isActionSuccessful(): boolean {
    return this.loader.runs > 0 && !this.loader.error;
  }

  get isActionFailed(): boolean {
    return this.loader.runs > 0 && this.loader.error;
  }

  protected readonly JSON = JSON;
}

@Directive({
  selector: '[confirmation]'
})
export class ConfirmationDirective {
  @Input() confirmTitle: string;

  /**
   * Represents the content of the confirmation dialogue. <br/>
   * Might be set to a falsy value, in which case the confirmation dialog is not displayed at all.
   * Instead, the (confirmed) event is immediately fired when clicking on the button.
   */
  @Input() confirmation: string | TemplateRef<any>;

  @Input() confirmLabel: string;

  @Input() maxWidth = 'unset';

  @Input() abortLabel: string;

  @Input() customButtonLabel: string;

  @Input() hint: TemplateRef<any>;

  @Input() headline: string;

  @Input() confirmationObservable: Observable<any>;

  @Input() confirmationSuccessMessage: string;

  @Output() confirmed = new EventEmitter();

  @Output() aborted = new EventEmitter();

  @Output() customButtonClicked = new EventEmitter();

  constructor(
    private modalService: ModalService,
    private injector: Injector,
    private environmentInjector: EnvironmentInjector
  ) {}

  @HostListener('click') onClick() {
    if (this.confirmation) {
      this.showDialog();
    } else {
      this.confirmed.next(true);
    }
  }

  showDialog() {
    const componentRef = createComponent(ConfirmationDialogComponent, {
      environmentInjector: this.environmentInjector,
      elementInjector: this.injector
    });

    componentRef.instance.text = this.confirmation;
    componentRef.instance.confirmTitle = this.confirmTitle;
    componentRef.instance.confirmLabel = this.confirmLabel;
    componentRef.instance.abortLabel = this.abortLabel;
    componentRef.instance.headline = this.headline;
    componentRef.instance.confirmationObservable = this.confirmationObservable;
    componentRef.instance.confirmationSuccessMessage = this.confirmationSuccessMessage;
    componentRef.instance.confirmed = this.confirmed;

    if (this.customButtonLabel) {
      componentRef.instance.customButtonLabel = this.customButtonLabel;
    }
    componentRef.instance.hint = this.hint;

    this.modalService
      .open<DialogCloseEventWithResult>(componentRef.instance.templateRef, {
        stacked: true,
        maxWidth: this.maxWidth
      })
      .then((close: DialogCloseEventWithResult) => {
        if (close.event === 'confirmed') {
          this.confirmed.next(close);
        } else if (close.event === 'optional') {
          this.customButtonClicked.next(close);
        } else if (close.event === 'cancelled') {
          this.aborted.next(close);
        }
      });
  }
}
