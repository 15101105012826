/* tslint:disable */
import { DashboardWidgetConfig } from './DashboardWidgetConfig';
import { User } from '../../shared/api-model';

export class DashboardConfig {
  isLocalDashboard?: boolean; // is only used for Frontend, not any longer part of the Backend model
  name: string;
  label: Record<string, string> | string;
  requiredRoles: string[];
  widgets: DashboardWidgetConfig[];
  layout?: DashboardLayout;
  properties?: Record<string, any>;
  tags?: string[];
  updatedAt?: string; // ISO-Date, managed by backend
  updatedBy?: User;
}

// GRID corresponds to Compact Grid
export enum DashboardLayout {
  ColumnBased = 'columnBased',
  GRID = 'grid',
  GridFreeFloating = 'gridFreeFloating'
}
