<rb-dialog
  dialogTitle="{{ 'userRoleOverwrite.title' | translate }}"
  [confirmationButtonAction]="saveUserRoles.bind(this)"
  confirmationButtonLabel="{{ 'userRoleOverwrite.save' | translate }}"
  [confirmationButtonCloseOnClick]="false"
  optionalButtonLabel="{{ 'userRoleOverwrite.reset' | translate }}"
  [disableConfirmationButton]="!roleSelection?.length"
  [optionalButtonAction]="resetUserRoles.bind(this)"
  [optionalButtonCloseOnClick]="false"
  [cancelButtonAction]="cancelUserRoles.bind(this)"
  cancelButtonLabel="{{ 'userRoleOverwrite.cancel' | translate }}"
>
  <rb-callout [type]="'warning'">
    {{ 'userRoleOverwrite.temporaryChangeWarning' | translate }}
  </rb-callout>

  <ng-container *ngIf="!hasRolesOverwritten">
    <h4>
      {{ 'userRoleOverwrite.selectExistingUser' | translate }}
    </h4>

    <user-selector
      *rbLoading="usersLoader"
      [label]="'userRoleOverwrite.userSelectorLabel' | translate"
      (userSelected)="onUserSelected($event)"
    ></user-selector>

    <p>
      {{ 'userRoleOverwrite.customizeRoleOfUser' | translate }}
    </p>
  </ng-container>

  <p *ngIf="hasRolesOverwritten" class="mb-2">
    {{ 'userRoleOverwrite.selectedRolesText' | translate }}
  </p>

  <user-role-selector
    *rbLoading="rolesLoader"
    [showTitle]="false"
    [userRoles]="userRoles"
    (roleSelectionChange)="onRoleSelectionChange($event)"
  ></user-role-selector>
</rb-dialog>
