import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RbUiComponentsModule } from '@inst-iot/bosch-angular-ui-components';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideDirective, ClickOutsideModule } from 'ng-click-outside';
import { NgMathPipesModule, NgPipesModule } from 'ngx-pipes';
import { AuthorizationConfigComponent } from './authorization-config/authorization-config.component';
import { CenteredMessageComponent } from './centered-message/centered-message.component';
import { DateTimeRangePickerComponent } from './date-time-range-picker/date-time-range-picker.component';
import { DateTimeRangePickerDirective } from './date-time-range-picker/date-time-range-picker.directive';
import { DateTimeRangeValidatorDirective } from './date-time-range-picker/date-time-range-validator.directive';
import { DateTimeRangePopoverComponent } from './date-time-range-popover/date-time-range-popover.component';
import { AddTemplateDirective } from './directives/add-hoc.directive';
import { ChildFormDirective } from './directives/child-form.directive';
import {
  ConfirmationDialogComponent,
  ConfirmationDirective
} from './directives/confirmation.directive';
import { DisableOnClickDirective } from './directives/disable-on-click.directive';
import { EqualValidatorDirective } from './directives/equal-validator.directive';
import { DragDropDirective } from './directives/file-drag-drop.directive';
import { HasRoleDirective } from './directives/has-role.directive';
import { HrefHashDirective } from './directives/href-hash.directive';
import { JsonFormatterDirective } from './directives/json-formatter.directive';
import { RegexValidatorDirective } from './directives/regex-validator.directive';
import { UnsafeUrlHandlerDirective } from './directives/unsafe-url-handler.directive';
import { DotsLoaderComponent } from './dots-loader/dots-loader.component';
import { EllipsisTextComponent } from './ellipsis-text/ellipsis-text.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { FileLinkCopyDialogComponent } from './file-link-copy-dialog/file-link-copy-dialog.component';
import { FileTypeIconComponent } from './file-type-icon/file-type-icon.component';
import { HintComponent } from './hint/hint.component';
import { LoadableTableProgressViewComponent } from './loadable-table/loadable-table-progress-view/loadable-table-progress-view.component';
import { LoadableTableRowDirective } from './loadable-table/loadable-table-row.directive';
import { LoadableTableDirective } from './loadable-table/loadable-table.directive';
import { LoginProviderSelectComponent } from './login-provider-select/login-provider-select.component';
import { OutletComponent } from './outlet/outlet.component';
import { PaginationBarComponent } from './pagination-bar/pagination-bar.component';
import { ByteUnitPipe } from './pipes/byte-unit.pipe';
import { CalendarAllDayPipe } from './pipes/calendar-all-day.pipe';
import { ClampPipe } from './pipes/clamp.pipe';
import { DatePipe } from './pipes/date.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { Nl2brPipe } from './pipes/nl2br.pipe';
import { NoValuePipe } from './pipes/no-value.pipe';
import { NumberPipe } from './pipes/number.pipe';
import { RemovePipe } from './pipes/remove.pipe';
import { SecondsToDaysPipe } from './pipes/seconds-to-days.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { TranslatedPipe } from './pipes/translated.pipe';
import { UnitPipe } from './pipes/unit.pipe';
import { WatermarkPipe } from './pipes/watermark.pipe';
import { ToggleHeadingComponent } from './toggle-heading/toggle-heading.component';
import { TranslatedTextInputComponent } from './translated-text-input/translated-text-input.component';
import { UrlSchemeErrorComponent } from './url-scheme-error/url-scheme-error.component';
import { DuplicateNameValidatorDirective } from './validators/duplicate-name-validator.directive';
import { ForbiddenValuesValidatorDirective } from './validators/forbidden-values-validator.directive';
import { IsNotWhitespaceValidatorDirective } from './validators/is-not-whitespace-validator.directive';
import { NoWhitespaceValidatorDirective } from './validators/no-whitespace-validator.directive';
import { UrlSchemeValidatorDirective } from './validators/url-scheme-validator.directive';
import { UrlValidatorDirective } from './validators/url-validator.directive';
import { ValueValidatorDirective } from './validators/value-validator.directive';
import { AlphabeticalSortPipe } from './pipes/alphabetical-sort.pipe';
import { DisplayNestedKeyValueComponent } from './display-nested-key-value/display-nested-key-value.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { DisplayDescriptionComponent } from './display-description/display-description.component';
import { TogglerComponent } from './toggler/toggler.component';
import { SkeletonLoadingElementComponent } from './skeleton-loading-element/skeleton-loading-element.component';
import { RequestCachingConfigurerComponent } from './request-caching-configurer/request-caching-configurer.component';
import { IsDuplicateLabelDirective } from './validators/is-duplicate-label.directive';
import { ArrayIncludesPipe } from './pipes/array-includes.pipe';
import { TeleportToDirective } from './directives/teleport-to.directive';
import { WidgetVisibilitySelectorComponent } from './widget-visibility-selector/widget-visibility-selector.component';
import { RoleMultiSelectorComponent } from './role-multi-selector/role-multi-selector.component';
import { ContentSizeValidatorDirective } from './directives/content-size-validator.directive';
import { UserSearchInputComponent } from './user-search-input/user-search-input.component';
import { BookingColorNamePipe } from './pipes/booking-color-name.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RbUiComponentsModule,
    RouterModule,
    NgbModule,
    TranslateModule,
    NgMathPipesModule,
    ClickOutsideModule,
    DragDropModule,
    NgPipesModule
  ],
  declarations: [
    EqualValidatorDirective,
    UnitPipe,
    OutletComponent,
    HasRoleDirective,
    JsonFormatterDirective,
    NoValuePipe,
    WatermarkPipe,
    ConfirmationDirective,
    ConfirmationDialogComponent,
    RemovePipe,
    DurationPipe,
    Nl2brPipe,
    DateTimeRangePickerComponent,
    DateTimeRangePickerDirective,
    DateTimeRangeValidatorDirective,
    DatePipe,
    DisableOnClickDirective,
    PaginationBarComponent,
    LoadableTableDirective,
    LoadableTableRowDirective,
    LoadableTableProgressViewComponent,
    TranslatedPipe,
    CenteredMessageComponent,
    TranslatedTextInputComponent,
    FileTypeIconComponent,
    TimeAgoPipe,
    NumberPipe,
    DuplicateNameValidatorDirective,
    IsDuplicateLabelDirective,
    ForbiddenValuesValidatorDirective,
    UrlValidatorDirective,
    UrlSchemeValidatorDirective,
    AddTemplateDirective,
    HintComponent,
    ByteUnitPipe,
    DotsLoaderComponent,
    DragDropDirective,
    ChildFormDirective,
    ClampPipe,
    LoginProviderSelectComponent,
    HrefHashDirective,
    ToggleHeadingComponent,
    ValueValidatorDirective,
    UnsafeUrlHandlerDirective,
    ErrorDialogComponent,
    UrlSchemeErrorComponent,
    CalendarAllDayPipe,
    DateTimeRangePopoverComponent,
    NoWhitespaceValidatorDirective,
    IsNotWhitespaceValidatorDirective,
    AuthorizationConfigComponent,
    RegexValidatorDirective,
    EllipsisTextComponent,
    SecondsToDaysPipe,
    FileLinkCopyDialogComponent,
    AlphabeticalSortPipe,
    DisplayNestedKeyValueComponent,
    TruncatePipe,
    DisplayDescriptionComponent,
    TogglerComponent,
    SkeletonLoadingElementComponent,
    RequestCachingConfigurerComponent,
    ArrayIncludesPipe,
    TeleportToDirective,
    WidgetVisibilitySelectorComponent,
    RoleMultiSelectorComponent,
    ContentSizeValidatorDirective,
    UserSearchInputComponent,
    BookingColorNamePipe
  ],
  exports: [
    NgbModule,
    FormsModule,
    RbUiComponentsModule,
    TranslateModule,
    NgMathPipesModule,
    EqualValidatorDirective,
    UnitPipe,
    ByteUnitPipe,
    OutletComponent,
    HasRoleDirective,
    JsonFormatterDirective,
    NoValuePipe,
    WatermarkPipe,
    ConfirmationDirective,
    RemovePipe,
    DurationPipe,
    Nl2brPipe,
    DateTimeRangePickerComponent,
    DateTimeRangePickerDirective,
    DatePipe,
    PaginationBarComponent,
    LoadableTableDirective,
    LoadableTableRowDirective,
    TranslatedPipe,
    CenteredMessageComponent,
    TranslatedTextInputComponent,
    FileTypeIconComponent,
    TimeAgoPipe,
    NumberPipe,
    DuplicateNameValidatorDirective,
    ForbiddenValuesValidatorDirective,
    NoWhitespaceValidatorDirective,
    UrlValidatorDirective,
    UrlSchemeValidatorDirective,
    AddTemplateDirective,
    HintComponent,
    DotsLoaderComponent,
    ClickOutsideDirective,
    DragDropDirective,
    ChildFormDirective,
    DisableOnClickDirective,
    ClampPipe,
    LoginProviderSelectComponent,
    HrefHashDirective,
    ToggleHeadingComponent,
    ConfirmationDialogComponent,
    ValueValidatorDirective,
    UnsafeUrlHandlerDirective,
    ErrorDialogComponent,
    UrlSchemeErrorComponent,
    CalendarAllDayPipe,
    DateTimeRangePopoverComponent,
    IsNotWhitespaceValidatorDirective,
    AuthorizationConfigComponent,
    RegexValidatorDirective,
    EllipsisTextComponent,
    SecondsToDaysPipe,
    FileLinkCopyDialogComponent,
    AlphabeticalSortPipe,
    DisplayNestedKeyValueComponent,
    TruncatePipe,
    DisplayDescriptionComponent,
    TogglerComponent,
    SkeletonLoadingElementComponent,
    RequestCachingConfigurerComponent,
    ArrayIncludesPipe,
    TeleportToDirective,
    WidgetVisibilitySelectorComponent,
    RoleMultiSelectorComponent,
    ContentSizeValidatorDirective,
    ArrayIncludesPipe,
    UserSearchInputComponent,
    BookingColorNamePipe
  ],
  providers: [TimeAgoPipe, TruncatePipe]
})
export class SharedModule {}
