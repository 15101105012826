import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { translate } from '../translation-util';

type formatTypes = 'human' | 'dhm' | 'dhm-s-ms' | 'suitableUnit';

@Pipe({
  name: 'duration',
  pure: true
})
export class DurationPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: any, format: formatTypes | moment.unitOfTime.Base = 'human'): any {
    if (value === undefined || value === null) {
      return NaN;
    }
    const d = moment.duration(value);
    if (format === 'human') {
      return d.humanize();
    } else if (format === 'dhm') {
      const result = this.convertDurationToDaysHoursMinutes(d);
      return this.translateService.instant('pipes.duration.dayHourMinute', result);
    } else if (format === 'dhm-s-ms') {
      return this.convertDuration(d).text;
    } else if (format === 'suitableUnit') {
      return this.convertAndTranslateDurationToSuitableUnit(d);
    } else {
      return Math.round(d.as(format));
    }
  }

  /*
    This method will translate every time unit after the first non-zero time.
    E.g. if the first non-zero time would be minutes with a value of 1, seconds and milliseconds
    will be translated and displayed as well (but not days and hours).
   */
  convertDuration(duration: moment.Duration) {
    let firstNonZeroTimeFound = false;
    const durationArray = [
      { name: 'days', unit: translate('pipes.duration.daysUnit'), value: duration.days() },
      { name: 'hours', unit: translate('pipes.duration.hoursUnit'), value: duration.hours() },
      { name: 'minutes', unit: translate('pipes.duration.minutesUnit'), value: duration.minutes() },
      { name: 'seconds', unit: translate('pipes.duration.secondsUnit'), value: duration.seconds() },
      {
        name: 'milliseconds',
        unit: translate('pipes.duration.millisecondsUnit'),
        value: duration.milliseconds()
      }
    ];

    const filterTimes = (time) => {
      if (time.value !== 0) {
        firstNonZeroTimeFound = true;
      }
      return firstNonZeroTimeFound || time.name === 'milliseconds';
    };

    const terms = durationArray
      .filter((item) => filterTimes(item))
      .map((dur) => dur.value + this.translateService.instant(dur.unit));

    const durations = {
      days: durationArray[0].value,
      hours: durationArray[1].value,
      minutes: durationArray[2].value,
      seconds: durationArray[3].value,
      milliseconds: durationArray[4].value
    };
    return {
      durations: durations,
      text: terms.join(' ')
    };
  }

  convertDurationToDaysHoursMinutes(duration: moment.Duration) {
    return {
      days: Math.floor(duration.asDays()),
      hours: duration.hours(),
      minutes: duration.minutes()
    };
  }

  convertAndTranslateDurationToSuitableUnit(d: moment.Duration) {
    if (Number(d.as('m').toFixed(1)) <= 1) {
      return `${Number(d.as('m').toFixed(1))} ${this.translateService.instant(
        translate('pipes.duration.minute')
      )}`;
    } else if (Number(d.as('m').toFixed(1)) > 1 && Number(d.as('h').toFixed(1)) < 1) {
      return `${Number(d.as('m').toFixed(1))} ${this.translateService.instant(
        translate('pipes.duration.minutes')
      )}`;
    } else if (Number(d.as('h').toFixed(1)) === 1) {
      return `${Number(d.as('h').toFixed(1))} ${this.translateService.instant(
        translate('pipes.duration.hour')
      )}`;
    } else if (Number(d.as('h').toFixed(1)) > 1 && Number(d.as('d').toFixed(1)) < 1) {
      return `${Number(d.as('h').toFixed(1))} ${this.translateService.instant(
        translate('pipes.duration.hours')
      )}`;
    } else if (Number(d.as('d').toFixed(1)) === 1) {
      return `${Number(d.as('d').toFixed(1))} ${this.translateService.instant(
        translate('pipes.duration.day')
      )}`;
    } else {
      return `${Number(d.as('d').toFixed(1))} ${this.translateService.instant(
        translate('pipes.duration.days')
      )}`;
    }
  }
}
