import { Constants } from '../../../constants';
import { DashboardConfig, DashboardLayout } from './DashboardConfig';
import { DashboardWidgetConfig } from './DashboardWidgetConfig';

export class DefaultDashboardParams {
  productName: string;
}

/**
 * Dashboards that have no backend representation by default.
 * After the first change, they will be stored to the backend.
 * For clarity and performance, the defaults have no widgets yet.
 * Widgets need to be generated with the parameters (see function below)
 */
export const defaultDashboards: { [name: string]: DashboardConfig } = {
  home: {
    isLocalDashboard: true,
    name: 'home',
    label: 'Home',
    requiredRoles: null,
    layout: DashboardLayout.ColumnBased,
    properties: {},
    widgets: []
  },
  homegrid: {
    isLocalDashboard: true,
    name: 'homegrid',
    label: 'Home Grid',
    requiredRoles: null,
    layout: DashboardLayout.GridFreeFloating,
    properties: {},
    widgets: []
  }
};

export function generateDefaultDashboard(
  defaultDashboardParams: DefaultDashboardParams,
  name: string
): DashboardConfig {
  let widgets = [];

  if (name === 'home') {
    widgets = generateHomeWidgets(defaultDashboardParams);
  } else if (name === 'homegrid') {
    widgets = generateHomegridWidgets(defaultDashboardParams);
  }

  return {
    ...defaultDashboards[name],
    widgets
  };
}

export function getDefaultDashboardsList(projectName: string): DashboardConfig[] {
  return Object.keys(defaultDashboards).map((name) => {
    return {
      ...defaultDashboards[name],
      project: projectName
    } as DashboardConfig;
  });
}

function generateHomeWidgets(
  defaultDashboardParams: DefaultDashboardParams
): DashboardWidgetConfig[] {
  return [
    {
      id: 'w4',
      columns: 12,
      rows: 1,
      positionX: 0,
      positionY: 0,
      insideTab: false,
      visibility: 'VISIBLE',
      type: 'rich-text',
      properties: {
        text:
          `<h1 class="ql-align-center">Welcome to ${defaultDashboardParams.productName}</h1>` +
          `<p class="ql-align-center">Please make yourself comfortable and begin with our ` +
          `<a href="${Constants.routing.helpGettingStarted}" ` +
          `target="_blank">` +
          `Getting Started Guide</a>.</p>` +
          `<p class="ql-align-center">To examine the details of the Quick Intro continue with our ` +
          `<a href="${Constants.routing.help}" ` +
          `target="_blank">` +
          `Detailed Introduction</a>.</p>`
      }
    },
    {
      id: 'w3',
      columns: 12,
      rows: 1,
      positionX: 0,
      positionY: 0,
      insideTab: false,
      visibility: 'VISIBLE',
      type: 'rich-text',
      properties: {
        text: '<h1>Project description</h1><p>[Please add a description of your project]</p>'
      }
    },
    {
      id: 'w1',
      columns: 4,
      rows: 1,
      positionX: 0,
      positionY: 0,
      insideTab: false,
      visibility: 'VISIBLE',
      type: 'project-stats',
      properties: {
        mode: 'totalDbSize',
        title: {
          en: 'Project stats',
          de: 'Projekt-Statistiken'
        }
      }
    },
    {
      id: 'w5',
      columns: 4,
      rows: 1,
      positionX: 0,
      positionY: 0,
      insideTab: false,
      visibility: 'VISIBLE',
      type: 'duplicates',
      properties: {
        title: {
          en: 'Duplicates',
          de: 'Duplikate'
        }
      }
    },
    {
      id: 'w2',
      columns: 4,
      rows: 1,
      positionX: 0,
      positionY: 0,
      insideTab: false,
      visibility: 'VISIBLE',
      type: 'device-stats',
      properties: {
        title: {
          en: 'Device stats',
          de: 'Device Statistiken'
        }
      }
    },
    {
      id: 'w0',
      columns: 12,
      rows: 1,
      positionX: 0,
      positionY: 0,
      insideTab: false,
      visibility: 'VISIBLE',
      type: 'link-panel',
      properties: {
        links: [],
        mode: 'fillExplore',
        title: {
          en: 'Explore',
          de: 'Explore'
        }
      }
    }
  ];
}

function generateHomegridWidgets(
  defaultDashboardParams: DefaultDashboardParams
): DashboardWidgetConfig[] {
  return [
    {
      id: 'w4',
      columns: 12,
      rows: 4,
      positionX: 0,
      positionY: 0,
      insideTab: false,
      visibility: 'VISIBLE',
      type: 'rich-text',
      properties: {
        text:
          `<h1 class="ql-align-center">Welcome to ${defaultDashboardParams.productName}</h1>` +
          '<p class="ql-align-center">Please make yourself comfortable and begin with our ' +
          '<a href="https://bosch-iot-insights.com/static-contents/docu/html/sfde-cloud-user-guide.html#_quick_intro" ' +
          'target="_blank">' +
          'Quick Intro Guide</a>.</p>' +
          '<p class="ql-align-center">To examine the details of the Quick Intro continue with our ' +
          '<a href="https://bosch-iot-insights.com/static-contents/docu/html/sfde-cloud-user-guide.html#_detailed_introduction" ' +
          'target="_blank">' +
          'Detailed Introduction</a>.</p>'
      }
    },
    {
      id: 'w3',
      columns: 12,
      rows: 3,
      positionX: 0,
      positionY: 3,
      insideTab: false,
      visibility: 'VISIBLE',
      type: 'rich-text',
      properties: {
        text: '<h1>Project description</h1><p>[Please add a description of your project]</p>'
      }
    },
    {
      id: 'w1',
      columns: 4,
      rows: 4,
      positionX: 0,
      positionY: 6,
      insideTab: false,
      visibility: 'VISIBLE',
      type: 'project-stats',
      properties: {
        mode: 'totalDbSize',
        title: {
          en: 'Project stats',
          de: 'Projekt-Statistiken'
        }
      }
    },
    {
      id: 'w5',
      columns: 4,
      rows: 4,
      positionX: 4,
      positionY: 6,
      insideTab: false,
      visibility: 'VISIBLE',
      type: 'duplicates',
      properties: {
        title: {
          en: 'Duplicates',
          de: 'Duplikate'
        }
      }
    },
    {
      id: 'w2',
      columns: 4,
      rows: 4,
      positionX: 8,
      positionY: 6,
      insideTab: false,
      visibility: 'VISIBLE',
      type: 'device-stats',
      properties: {
        title: {
          en: 'Device stats',
          de: 'Device Statistiken'
        }
      }
    },
    {
      id: 'w0',
      columns: 12,
      rows: 4,
      positionX: 0,
      positionY: 10,
      insideTab: false,
      visibility: 'VISIBLE',
      type: 'link-panel',
      properties: {
        links: [],
        mode: 'fillExplore',
        title: {
          en: 'Explore',
          de: 'Explore'
        }
      }
    }
  ];
}
