import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { QueryTemplateParameter } from '../../../../shared/api-model';

@Component({
  selector: 'range-value',
  templateUrl: './range-value.component.html'
})
export class RangeValueComponent implements OnInit {
  @Input() label: string | TemplateRef<any>;

  @Input() parameter: QueryTemplateParameter;

  @Output() valueChange = new EventEmitter<any>();

  @Input() value = { from: undefined, to: undefined };

  from: any;

  to: any;

  ngOnInit() {
    if (this.value) {
      this.from = this.value.from;
      this.to = this.value.to;
    }
  }

  get fromValue() {
    return this.from;
  }

  set fromValue(v: any) {
    this.from = v;
    this.updateValue();
  }

  get toValue() {
    return this.to;
  }

  set toValue(v: any) {
    this.to = v;
    this.updateValue();
  }

  updateValue() {
    this.value = {
      from: this.from,
      to: this.to
    };
    if (this.from === undefined || this.to === undefined) {
      this.valueChange.next(null);
    } else {
      this.valueChange.next(this.value);
    }
  }
}
