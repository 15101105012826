import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {
  CompactType,
  DisplayGrid,
  GridsterComponent,
  GridsterConfig,
  GridsterItem,
  GridType
} from 'angular-gridster2';
import { GridsterItemComponentInterface } from 'angular-gridster2/lib/gridsterItem.interface';
import { Subject, Subscription } from 'rxjs';
import { DashboardWidgetComponent } from '../../dashboard-widget/dashboard-widget.component';
import { DashboardConfig, DashboardLayout } from '../../models/DashboardConfig';
import { DashboardWidgetConfig } from '../../models/DashboardWidgetConfig';
import { DashboardDataService } from '../../services/dashboard-data.service';
import { DashboardGridSingletonService } from '../../services/dashboard-grid-singleton.service';
import { DashboardGridService } from '../../services/dashboard-grid.service';
import { TabConfig } from '../../widgets/tab-widget/tab-widget.model';
import { Router } from '@angular/router';

@Component({
  selector: 'dashboard-grid',
  templateUrl: './dashboard-grid.component.html',
  styleUrls: ['../../dashboard/dashboard.component.scss', './dashboard-grid.component.scss'],
  providers: [DashboardGridService]
})
export class DashboardGridComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input()
  widgets: DashboardWidgetConfig[];

  @Input()
  activateDragAndResize = false;

  @Input()
  canEdit = false;

  @Input()
  editing = false;

  @Input()
  tabWidgetContext = false;

  @Input() tabId: string;

  @Input() currentTabId: string;

  @Input()
  tabList: TabConfig[];

  @Input()
  tabWidgetId: string;

  @Input()
  mobileBreakpoint: number;

  @Output()
  widgetsChange = new EventEmitter<DashboardWidgetConfig[]>();

  @Output() maximize = new EventEmitter<{ max: boolean; index: number }>();

  @ViewChildren(DashboardWidgetComponent) set widgetComponents(
    widgetComponents: QueryList<DashboardWidgetComponent>
  ) {
    this.dashboardGridService.widgetComponents = widgetComponents;
  }

  @ViewChild('gridster') set gridster(gridster: GridsterComponent) {
    this.dashboardGridService.gridster = gridster;
    this.dashboardGridSingletonService.registerGridComponentInstance(
      this.tabWidgetId
        ? this.dashboardGridSingletonService.assembleTabUniqueIdentifier(
            this.tabWidgetId,
            this.tabId
          )
        : 'main',
      this
    );
  }

  maximizedIndex = -1;

  private isExportTabWidget = inject(Router).url.includes('exportTabWidget');

  /**
   * Used to track when the gridster item is resized.
   * We subscribe to it in map widgets to trigger map resize manually.
   */
  widgetContainerResized = new Subject<string>();

  options: GridsterConfig = {
    gridType: GridType.VerticalFixed,
    displayGrid: DisplayGrid.OnDragAndResize,
    compactType: CompactType.CompactUp,
    pushItems: true,
    pushDirections: {
      north: true,
      south: true,
      east: false,
      west: false
    },
    swap: true,
    swapWhileDragging: true,
    draggable: {
      enabled: true,
      dropOverItemsCallback: (source: GridsterItem, target: GridsterItem) => {
        this.dashboardGridSingletonService.dropItemFromMainToTabWidgetLayout(source, target);
      },
      stop: (
        item: GridsterItem,
        itemComponent: GridsterItemComponentInterface,
        event: MouseEvent
      ) => {
        this.dashboardGridSingletonService.dropItemFromTabWidgetToMainLayout(
          item,
          event,
          this.dashboardGridSingletonService.assembleTabUniqueIdentifier(
            this.tabWidgetId,
            this.currentTabId
          )
        );
      },
      dropOverItems: true
    },
    resizable: {
      enabled: true
    },
    minCols: 12,
    maxCols: 18,
    minRows: 1,
    maxRows: 10000,
    fixedRowHeight: 40,
    setGridSize: true,
    maxItemCols: 18,
    minItemCols: 1,
    maxItemRows: 10000,
    minItemRows: 1,
    maxItemArea: 25000,
    minItemArea: 1,
    defaultItemCols: 1,
    defaultItemRows: 1,
    keepFixedHeightInMobile: true,
    margin: 20,
    pushResizeItems: false,
    itemResizeCallback: (item, itemComponent) => {
      this.dashboardGridService.trackWidthOfWidget({
        id: item.widget.id,
        width: Math.trunc(itemComponent.width)
      });
      this.widgetContainerResized.next(item.widget.id);
      setTimeout(() => {
        this.dashboardGridService.itemResize(item, itemComponent);
      });
    },
    itemChangeCallback: (item) => {
      this.dashboardGridService.convertItemsToWidgets(item);
      this.widgets = this.dashboardGridService.widgets;
      this.widgetsChange.emit(this.dashboardGridService.widgets);
    }
  };

  isMainRelayoutContext: boolean;

  disableWidgetPushKey: string;

  /*
    Used to set the push items to false when control (or in case of macOS command) key is pressed
    This is needed to drop gridster items from the outer grid to tab widget grid
   */
  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.setPushItems(event.code, false);
  }
  @HostListener('document:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    this.setPushItems(event.code, true);
  }

  private subscriptions = new Subscription();

  constructor(
    public dashboardGridService: DashboardGridService,
    private changeDetector: ChangeDetectorRef,
    private dashboardGridSingletonService: DashboardGridSingletonService,
    public dashboardService: DashboardDataService
  ) {
    this.disableWidgetPushKey = window.navigator.userAgent.includes('Mac') ? 'Meta' : 'Control';
  }

  ngOnInit() {
    this.options.mobileBreakpoint = this.mobileBreakpoint || 640;
    this.options.api?.optionsChanged();
    this.isMainRelayoutContext = this.dashboardGridSingletonService.isMainRelayoutContext;

    if (this.dashboardService.currentLayout === DashboardLayout.GridFreeFloating) {
      this.options.compactType = CompactType.None;
      this.options.api?.optionsChanged();
    }

    this.subscribeToWidgetRemoved();
    this.subscribeToDashboardConfigChange();
  }

  ngAfterViewInit() {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    this.subscriptions?.unsubscribe();
    this.dashboardGridService.destroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activateDragAndResize) {
      this.dashboardGridService.activateDragAndResize = changes.activateDragAndResize.currentValue;
      if (changes.activateDragAndResize.currentValue) {
        this.options.draggable.enabled = true;
        this.options.resizable.enabled = true;
      } else {
        this.options.draggable.enabled = false;
        this.options.resizable.enabled = false;
      }
      this.options.api?.optionsChanged();
    }

    if (changes.widgets?.firstChange) {
      this.dashboardGridService.widgets = changes.widgets.currentValue;
      if (this.isExportTabWidget) {
        this.tabWidgetContext = true;
      }
      this.dashboardGridService.convertWidgetsToItems(this.tabWidgetContext);
    }

    if (changes.tabId?.currentValue) {
      this.dashboardGridService.tabIdentifier =
        this.dashboardGridSingletonService.assembleTabUniqueIdentifier(
          this.tabWidgetId,
          changes.tabId.currentValue
        );
    }
  }

  subscribeToWidgetRemoved(): void {
    this.subscriptions.add(
      this.dashboardService.widgetRemoved$.subscribe((widgetConfig: DashboardWidgetConfig) => {
        this.dashboardGridService.removeItemByWidgetId(widgetConfig.id);
      })
    );
  }

  subscribeToDashboardConfigChange(): void {
    this.subscriptions.add(
      this.dashboardService.configChange.subscribe((dashboardConfig: DashboardConfig) => {
        this.widgets = dashboardConfig.widgets;
        this.widgetsChange.next(this.widgets);
        this.dashboardGridService.widgets = dashboardConfig.widgets;
      })
    );
  }

  setMaximize(max: boolean, index: number, widgetId: string) {
    if (max) {
      this.maximizedIndex = index;
      document.body.scrollIntoView({ behavior: 'smooth' });
    } else {
      this.maximizedIndex = -1;
    }
    this.maximize.emit({ max, index });
    // when maximized index is set the UI needs time to apply the style and adjust the widget size
    // then we emit the resized event, to resize the map for example
    setTimeout(() => {
      this.widgetContainerResized.next(widgetId);
    }, 200);
  }

  overflowVisible(item) {
    if (item.widget.type === 'tabwidget') {
      return Object.keys(this.dashboardGridSingletonService.gridsterInstances)
        .filter((key) => key.startsWith(item.widget.id + '-'))
        .some(
          (key) => this.dashboardGridSingletonService.gridsterInstances[key].activateDragAndResize
        );
    }

    return true;
  }

  private setPushItems(keyPressed: string, pushItems: boolean) {
    if (keyPressed.includes(this.disableWidgetPushKey) && this.options.pushItems !== pushItems) {
      this.options.pushItems = pushItems;
      this.options.swap = pushItems;
      this.options.api?.optionsChanged();
    }
  }
}
