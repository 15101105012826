import { ConditionChip } from '../models/condition-chip.model';
import { badgeTypes, LogicalOperator, SyntaxNode } from '../models/syntax-node.model';
import { BinaryTreeNode } from '../models/util-objects.model';
import { UrlSearchParamMap } from '../../../dashboards/models/url-search-param-map';
import { escapeRegExp } from 'lodash-es';
import { objectIdMatcher } from './filter-definitions';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

export function parseIfJSON(value: any, fallback = value): string | object {
  try {
    return JSON.parse(value);
  } catch (e) {
    return fallback;
  }
}

export function assembleFindQuery(node: SyntaxNode) {
  const logicalOperators = node.childNodes.filter((child) => child.body instanceof LogicalOperator);
  const statements = node.childNodes.filter((child) => !(child.body instanceof LogicalOperator));
  const binaryTree = createBinaryNode(logicalOperators, statements);

  return createQueryFromBinaryTree(binaryTree);
}

export function chipsToQueryParams(
  chips: ConditionChip[],
  simpleLogic: string,
  collection?: string
) {
  const params = new URLSearchParams();

  chips.forEach((chip) => {
    if (chip.value !== undefined) {
      const fieldKey = chip.fieldName ? `${chip.input}.${chip.fieldName}` : chip.input;
      const value =
        chip.fieldName || collection
          ? {
              [fieldKey]: chip.value,
              type: chip.type,
              operator: chip.operatorID
            }
          : { [fieldKey]: chip.value, operator: chip.operatorID };

      params.set(chip.visualIndex.toString(), JSON.stringify(value));
    }
  });

  params.set('simpleLogic', simpleLogic);
  if (collection) {
    params.set('collection', collection);
  }

  return new UrlSearchParamMap(params);
}

function createQueryFromBinaryTree(tree: BinaryTreeNode) {
  if (tree.isParent) {
    return iterateChildren(tree);
  } else {
    return tree.nodeJSONQuery;
  }
}

function iterateChildren(tree: BinaryTreeNode) {
  tree = mergeAndStatements(tree);
  tree = mergeOrStatements(tree);
  return tree.nodeJSONQuery;
}

function mergeAndStatements(tree: BinaryTreeNode) {
  let statements = [];
  let currentNode = tree;
  let replaceMarker = 0;

  while (currentNode.isParent) {
    if (currentNode.and) {
      currentNode = collectAndNodes(statements, currentNode);
      statements = appendStatements(statements, currentNode, 'and');
      tree = replaceNodeInTree(tree, replaceMarker, currentNode);
    } else {
      currentNode = currentNode.getNextChild;
      replaceMarker++;
    }
  }

  return tree;
}

function appendStatements(statements: any[], currentNode: BinaryTreeNode, operator: 'and' | 'or') {
  if (statements.length > 1) {
    currentNode.nodeJSONQuery = { ['$' + operator]: statements };
    return [];
  } else {
    return statements;
  }
}

function collectAndNodes(statements: any[], currentNode: BinaryTreeNode) {
  while (currentNode.and) {
    if (!statements.length) {
      statements.push(currentNode.nodeJSONQuery);
    }
    statements.push(currentNode.and.nodeJSONQuery);
    currentNode = currentNode.getNextChild;
  }
  return currentNode;
}

function collectOrNodes(statements: any[], currentNode: BinaryTreeNode) {
  while (currentNode.or) {
    if (!statements.length) {
      statements.push(currentNode.nodeJSONQuery);
    }
    statements.push(currentNode.or.nodeJSONQuery);
    currentNode = currentNode.getNextChild;
  }
  return currentNode;
}

function replaceNodeInTree(tree: BinaryTreeNode, position: number, node: BinaryTreeNode) {
  if (position === 0) {
    tree = node;
  } else {
    if (tree.and) {
      tree.and = replaceNodeInTree(tree.and, position - 1, node);
    } else if (tree.or) {
      tree.or = replaceNodeInTree(tree.or, position - 1, node);
    }
  }
  return tree;
}

function mergeOrStatements(tree: BinaryTreeNode) {
  let statements = [];
  let currentNode = tree;

  while (currentNode.isParent) {
    if (currentNode.or) {
      currentNode = collectOrNodes(statements, currentNode);
      statements = appendStatements(statements, currentNode, 'or');
      if (tree !== currentNode) {
        tree = currentNode;
      }
    } else {
      currentNode = currentNode.getNextChild;
    }
  }
  return tree;
}

function createBinaryNode(
  logicalOperators: SyntaxNode[],
  statements: SyntaxNode[],
  nodeIndex = 0
): BinaryTreeNode {
  const statement = statements[nodeIndex];
  let binaryTreeNode = new BinaryTreeNode();

  if (statement) {
    binaryTreeNode.nodeJSONQuery = getQueryFromSyntaxNode(statement);
  }
  if (nodeIndex < logicalOperators.length) {
    const logicalOperator = logicalOperators[nodeIndex].body as LogicalOperator;
    nodeIndex++;
    const childNode = createBinaryNode(logicalOperators, statements, nodeIndex);
    binaryTreeNode = appendChildBinaryNode(binaryTreeNode, logicalOperator, childNode);
  }

  return binaryTreeNode;
}

function appendChildBinaryNode(
  binaryTreeNode: BinaryTreeNode,
  badge: LogicalOperator,
  childNode: BinaryTreeNode
) {
  if (badge.type.toLowerCase() === badgeTypes.or) {
    binaryTreeNode.or = childNode;
  } else {
    binaryTreeNode.and = childNode;
  }
  return binaryTreeNode;
}

function getQueryFromSyntaxNode(node: SyntaxNode) {
  if (node?.body instanceof ConditionChip) {
    return assembleFindQueryFromChip(node.body);
  } else {
    // Else Case: Node.body contains a Group
    return assembleFindQuery(node);
  }
}

function assembleFindQueryFromChip(chip: ConditionChip): Record<string, any> {
  const key = chip.customValuePath || chip.input;
  if (chip.searchType === 'filter') {
    if (key === 'id' || key.endsWith('.id') || key.startsWith('id.')) {
      return { [key.replace('id', '_id')]: { $oid: chip.value } };
    }
    if (key === 'duplicates') {
      return { duplicate: chip.value };
    }
    if (key === 'checksumFailure') {
      return { tags: 'checksumFailure' };
    }
    if (key === 'metaData') {
      return createMetadataFilterQuery(chip);
    }
    if (key === 'queryParameters') {
      return createQueryTemplateParameterFilterQuery(chip);
    }
    if (key === 'processedData') {
      return createProcessedDataFilterQuery(chip);
    }
  }

  switch (chip.operatorID) {
    case 'exists':
      return { [key]: { $exists: chip.value } };
    case 'regex':
      return { [chip.input]: { $regex: chip.value, $options: 'i' } };
    case 'eq':
      return { [key]: getFormattedChipValue(chip) };
    default:
      return {
        [key]: {
          ['$' + chip.operatorID]: getFormattedChipValue(chip)
        }
      };
  }
}

function getFormattedChipValue(chip: ConditionChip): any {
  switch (chip.type) {
    case 'date':
      return { $date: chip.value };
    case 'number':
      return Number.parseFloat(chip.value);
    case 'string':
      return String(chip.value);
    case 'boolean':
      return Boolean(chip.value);
    case 'boolean-selection-with-exists':
      return { $exists: coerceBooleanProperty(chip.value) };
    case 'null':
      return null;
    case 'objectId':
      return typeof chip.value === 'string' && objectIdMatcher.test(chip.value)
        ? { $oid: chip.value }
        : chip.value;
    case 'json':
      return typeof chip.value === 'string' ? JSON.parse(chip.value) : chip.value;
    case 'datetimerange':
      return { $gte: { $date: chip.value[0] }, $lte: { $date: chip.value[1] } };
    case 'range':
      return { $gte: parseInt(chip.value.from, 10), $lte: parseInt(chip.value.to, 10) };
    default:
      if (chip.value === 'true' || chip.value === 'false') {
        return chip.value === 'true';
      }
      return chip.value;
  }
}

/**
 * In case value in the database is possible not to be a string make sure it will search over numbers and boolean too
 * @param chip
 */
function createMetadataFilterQuery(chip: ConditionChip) {
  return createFilterQueryForStaticPaths(chip, 'metaData');
}

function createQueryTemplateParameterFilterQuery(chip: ConditionChip) {
  return createFilterQueryForStaticPaths(chip, 'queryParameters');
}

function createFilterQueryForStaticPaths(
  chip: ConditionChip,
  path: 'metaData' | 'queryParameters'
): Record<string, any> {
  switch (chip.operatorID) {
    case 'exists':
      return { [`${path}.${chip.fieldName}`]: { $exists: chip.value } };
    case 'eq':
    case 'regex':
      if (chip.type === 'string') {
        return {
          [`${path}.${chip.fieldName}`]: {
            $options: 'i',
            $regex: escapeRegExp(chip.value)
          }
        };
      }
      return { [`${path}.${chip.fieldName}`]: getFormattedChipValue(chip) };
    default:
      return {
        [`${path}.${chip.fieldName}`]: { ['$' + chip.operatorID]: getFormattedChipValue(chip) }
      };
  }
}

/**
 * Build filter with objectId($oid) only if it is an objectId
 * @param chip
 */
function createProcessedDataFilterQuery(chip: ConditionChip) {
  if (objectIdMatcher.test(chip.value.id)) {
    return {
      [chip.input + '._id']: { $oid: chip.value.id },
      [chip.input + '.collection']: chip.value.collection
    };
  } else {
    return {
      [chip.input + '._id']: parseIfJSON(chip.value.id),
      [chip.input + '.collection']: chip.value.collection
    };
  }
}
