import { ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { translate } from '../../../../../shared/translation-util';
import { DataLoaderRegistryService } from '../../services/data-loader-registry.service';
import { assignWithDefaults } from '../model-utils';
import { WidgetContext } from '../widget-context';
import { DataSource, dataSourceTypes, removeWhiteSpaceRegex } from './data-source-base';
import { DataSourceType } from './models';
import { ThingDataSource } from './thing';
import {
  DeviceTypeOption,
  DeviceTypesSelection
} from '../../../data-source-config/device-types-selection';
import { isEmpty, isObject } from 'lodash-es';
import { allThingFields } from '../../../../../devices/models/thing';
import { resolvePlaceholders, resolveStringWithModifiers } from '../placeholder-utils';

export class ThingsDataSource extends DataSource {
  type: DataSourceType = 'things';

  label = 'Multiple devices';

  filter: string = null;

  deviceTypes = [];

  /*
  Reduce Type Selection to bookable types only
  */
  bookableDevicesOnly = false;

  /*
  Allow empty DeviceType Selection, if set to <false>
  */
  deviceTypeRequired = true;

  start = 0;

  limit = 200;

  sort = '+thingId';

  /**
   * modifies the options
   */
  pagination = false;

  fields = '';

  namespaces = '';

  constructor(props?: Partial<ThingsDataSource>) {
    super(props);
    assignWithDefaults(this, props);
  }

  validate(): ValidationErrors | null {
    if (this.deviceTypeRequired && isEmpty(this.deviceTypes)) {
      return { missingDeviceType: true };
    }
    return null;
  }

  loadData(ctx: WidgetContext): Observable<any[]> {
    const limit = this.limit ?? 200;
    const sort = this.sort ?? '+thingId';
    const start = this.start ?? 0;
    const namespaces = this.namespaces.replace(/\s/g, '');

    // in case of object structure(old approach), transform to new one
    if (isObject(this.filter)) {
      this.filter = this.filter['value'] || '';
    }

    let filter = this.filter
      ? resolvePlaceholders(this.filter, 0, resolveStringWithModifiers, ctx).replace(
          removeWhiteSpaceRegex,
          ''
        )
      : null;

    filter = DeviceTypesSelection.buildFilter(filter, this.deviceTypes);

    if (this.deviceTypes.includes(DeviceTypeOption.allBookableDeviceTypes)) {
      filter = this.appendBookableFilter(ctx, filter);
    }

    let fieldCond = this.fields;
    if (isEmpty(fieldCond)) {
      fieldCond = allThingFields;
    }
    const namespacesAndParams = `${namespaces}limit(${start}${limit})sort(${this.sort})${filter}${fieldCond}`;

    const devicesService = ThingDataSource.initDevicesService(ctx);
    const source = devicesService
      .getDevices(filter, null, limit, sort, true, fieldCond, namespaces)
      .pipe(map((result) => result.items));
    const registryService = ctx.injector.get(DataLoaderRegistryService, null);
    return registryService
      ? source.pipe(registryService.registerDataSource(namespacesAndParams, ctx.priority))
      : source;
  }

  appendBookableFilter(ctx: WidgetContext, filter: string): string {
    const calendarWidgetService = ThingDataSource.initCalendarWidgetService(ctx);
    calendarWidgetService.getBookableDeviceTypes().subscribe((types) => {
      const bookableDeviceTypes = types.map((t) => t.type).join('","');
      if (bookableDeviceTypes) {
        const bookableFilter = `in(attributes/type,"${bookableDeviceTypes}")`;
        filter = filter ? `and(${filter},${bookableFilter})` : bookableFilter;
      }
    });
    return filter;
  }
}

dataSourceTypes.push({
  name: 'things',
  i18nLabel: translate('dataSourceConfig.things'),
  constructor: ThingsDataSource
});
