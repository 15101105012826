import { OperatorType } from './operator.model';
import { SupportedPathType } from './condition-chip.model';
import { ProcessedData } from '../../../shared/api-model';
import { FieldDefinitionType } from '../../dynamic-filter/dynamic-filter.model';
import { SearchType } from '../../data-selection/data-info-util';

export interface InputObject {
  value: any;
  type: SupportedPathType | FieldDefinitionType;
  searchType: SearchType;
  input: string;
  i18nLabel?: string;
  operatorID: OperatorType;
  fieldName?: string;
  customValuePath?: string;
  valueLabel?: string;
}

export interface CachedResultObject {
  processedData: ProcessedData[] | number;
  pageData: any;
}

export class BinaryTreeNode {
  nodeJSONQuery: any;
  and?: BinaryTreeNode;
  or?: BinaryTreeNode;

  get isParent() {
    return !!this.and || !!this.or;
  }

  get getNextChild() {
    return this.and ? this.and : this.or;
  }
}
