import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserAuthService } from './user-auth.service';
import { Constants } from '../../../constants';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AlreadyLoggedInGuard implements CanActivate {
  constructor(private router: Router, private authService: UserAuthService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isLoggedIn().pipe(
      map((isLoggedIn) => {
        console.log('isLoggedIn check', isLoggedIn);
        if (isLoggedIn) {
          this.router.navigate([Constants.routing.home]);
          return false;
        } else {
          return true;
        }
      }),
      catchError((err) => {
        console.error('failed to check login', err);
        this.router.navigate([Constants.routing.error, 'loginCheckFailed']);
        return of(false);
      })
    );
  }
}
