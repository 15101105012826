import { QueryStatus } from '../../shared/api-model';

/**
 * States in which the query request is finished as error
 */
export const errorStates: QueryStatus[] = [
  'INCORRECT',
  'NO_TICKET',
  'FAILED',
  'EXPIRED',
  'INTERRUPTED',
  'CANCELED',
  'POLLING_TIMEOUT'
];

/**
 * States in which the query request is considered in a loading state
 */
export const loadingStates: QueryStatus[] = [
  'PENDING',
  'QUEUED',
  'RUNNING',
  'SUCCESSFUL',
  'DOWNLOADING'
];
