import { Injector } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { Observable, of, throwError } from 'rxjs';
import { findAggregator } from '../data-aggregators';
import { assignWithDefaults } from '../model-utils';
import { WidgetContext } from '../widget-context';
import { DataSourceType, DsAggregatorConfig, DsAggregatorName } from './models';
import { filterByJmesExpression } from '../filter-utils';

export interface DataSourceTypeInfo<T extends DataSource = any> {
  name: string;
  i18nLabel: string;
  defaults?: Record<string, any>;
  constructor: new (props?: Partial<DataSourceConfig>) => T;
  isAvailable?: (injector: Injector) => boolean;
}

export const dataSourceTypes: DataSourceTypeInfo[] = [];

/* Removes all whitespaces in a Things Filter except within quotation marks.
 This allows values to contain whitespaces, i.e. Device Names
*/
export const removeWhiteSpaceRegex = /[ ](?=(?:[^"]*"[^"]*")*[^"]*$)/g;

export class DataSourceConfig {
  sources: DataSource[] = [];
  aggregator: DsAggregatorName = 'zip';
  aggregatorConfig: DsAggregatorConfig = {};
  jmesPath = '';
  optional = false;

  constructor(props?: Partial<DataSourceConfig>) {
    assignWithDefaults(this, props, {
      sources: (sourcesData) => sourcesData.map((sd) => createDataSource(sd.type, sd))
    });
  }

  loadData(ctx: WidgetContext): Observable<any[]> {
    const dataAggregator = findAggregator(this.aggregator);
    const sourceStreams = this.sources.filter((s) => !s.validate()).map((s) => s.loadData(ctx));
    if (sourceStreams.length > 1) {
      return dataAggregator
        .aggregate(this.aggregatorConfig, sourceStreams)
        .pipe(filterByJmesExpression(this.jmesPath));
    } else if (sourceStreams.length === 1) {
      return sourceStreams[0].pipe(filterByJmesExpression(this.jmesPath));
    } else if (sourceStreams.length === 0 && this.optional) {
      return of([]);
    } else {
      return throwError(new Error('No valid data sources available'));
    }
  }

  getLastUpdate(): Date {
    const dates = this.sources.filter((s) => s.lastUpdate).map((s) => s.lastUpdate.getTime());
    if (!dates.length) {
      return null;
    }
    return new Date(Math.max(...dates));
  }

  validate(): ValidationErrors | null {
    const errors: ValidationErrors = {};
    let hasError = false;
    if (!this.sources.length && !this.optional) {
      hasError = true;
      errors['noDataSources'] = true;
    }
    for (const s of this.sources) {
      const err = s.validate();
      if (err) {
        Object.assign(errors, err);
        hasError = true;
      }
    }
    const dataAggregator = findAggregator(this.aggregator);
    if (!dataAggregator) {
      hasError = true;
      errors['unknownDataAggregator'] = true;
    } else {
      const err = dataAggregator.validateConfig(this.aggregatorConfig);
      if (err) {
        Object.assign(errors, err);
        hasError = true;
      }
    }
    return hasError ? errors : null;
  }
}

export function createDataSource<T extends DataSource>(type: DataSourceType, data?: Partial<T>): T {
  // eslint-disable-next-line sonarjs/no-empty-collection
  const info = dataSourceTypes.find((i) => i.name === type);
  if (info) {
    const initialData = Object.assign(data || {}, info.defaults || {});
    return new info.constructor(initialData);
  }
  throw new Error('Unknown dataSourceType: ' + type);
}

export abstract class DataSource {
  type: DataSourceType = '';
  label = '';
  sourceId: string = Math.floor(0x1000000 + Math.random() * 0x10000000).toString(16);
  private _lastUpdate: Date = null;
  protected constructor(props: Partial<DataSource>) {
    Object.defineProperty(this, '_lastUpdate', { enumerable: false });
    assignWithDefaults(this, props, { _lastUpdate: () => null });
  }

  /**
   * Validates the currently configured parameters
   */
  abstract validate(): ValidationErrors | null;

  /**
   * Loads the data with help of the context
   * @param ctx
   */
  abstract loadData(ctx: WidgetContext): Observable<any[]>;

  /**
   * When the data was last updated
   */
  get lastUpdate(): Date | null {
    return this._lastUpdate;
  }

  protected set lastUpdate(d: Date) {
    this._lastUpdate = d;
  }
}
