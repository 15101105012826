export enum ServicePlanType {
  premium = 'PREMIUM',
  standard = 'STANDARD',
  free = 'FREE',
  manual = 'MANUAL',
  internal = 'INTERNAL',
  poc = 'POC',
  payAsYouGo = 'PAY_AS_YOU_GO'
}

export interface ServicePlan {
  planType: ServicePlanType;
  label: string;
}

export function getServicePlanLabelByType(servicePlanType: ServicePlanType): string {
  return ServicePlanMapping.find((plan: ServicePlan) => plan.planType === servicePlanType)?.label;
}

export const marketplaceServicePlans: Partial<Record<ServicePlanType, ServicePlan>> = {
  [ServicePlanType.premium]: {
    planType: ServicePlanType.premium,
    label: 'Premium'
  },
  [ServicePlanType.standard]: {
    planType: ServicePlanType.standard,
    label: 'Standard'
  },
  [ServicePlanType.payAsYouGo]: {
    planType: ServicePlanType.payAsYouGo,
    label: 'Pay as You Go'
  }
};

export const internalServicePlans: Partial<Record<ServicePlanType, ServicePlan>> = {
  [ServicePlanType.manual]: {
    planType: ServicePlanType.manual,
    label: 'Manual'
  },
  [ServicePlanType.free]: {
    planType: ServicePlanType.free,
    label: 'Free'
  },
  [ServicePlanType.internal]: {
    planType: ServicePlanType.internal,
    label: 'Internal'
  },
  [ServicePlanType.poc]: {
    planType: ServicePlanType.poc,
    label: 'POC'
  }
};

export function isInternalServicePlan(servicePlanType: ServicePlanType): boolean {
  return Object.keys(internalServicePlans).includes(servicePlanType);
}

export const ServicePlanMapping: ServicePlan[] = [
  {
    planType: ServicePlanType.payAsYouGo,
    label: 'Pay as You Go'
  },
  {
    planType: ServicePlanType.standard,
    label: 'Standard'
  },
  {
    planType: ServicePlanType.premium,
    label: 'Premium'
  },
  {
    planType: ServicePlanType.manual,
    label: 'Manual'
  },
  {
    planType: ServicePlanType.internal,
    label: 'Internal'
  },
  {
    planType: ServicePlanType.poc,
    label: 'POC'
  },
  {
    planType: ServicePlanType.free,
    label: 'Free'
  }
];
