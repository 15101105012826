<div [ngClass]="{ 'hint-label': hint }" [class]="'d-flex flex-nowrap w-100 ' + class">
  <div
    [ngClass]="{ 'ellipsis-enabled': ellipsisEnabled }"
    class="ellipsable-content flex-auto flex-grow-1"
    #mainContent
  >
    <ng-container *ngIf="inlineContent && ellipsisEnabled">
      <span [innerHTML]="inlineContent"></span>
    </ng-container>
    <ng-container *ngIf="!(inlineContent && ellipsisEnabled)">
      <ng-content></ng-content>
    </ng-container>
  </div>
  <div
    *ngIf="displayMoreOrLess"
    class="toggle-ellipsis-switch ml-2 no-wrap flex-none"
    (click)="toggleEllipse()"
  >
    <span>[</span>
    <span class="switch-text">
      <span *ngIf="ellipsisEnabled">{{ 'showMore' | translate }}</span>
      <span *ngIf="!ellipsisEnabled">{{ 'showLess' | translate }}</span>
    </span>
    <span>]</span>
  </div>
</div>
