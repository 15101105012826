import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: 'a[href="#"]'
})
export class HrefHashDirective {
  constructor(private el: ElementRef) {
    // @HostListener does not work in all cases, so a native eventListener is used
    el.nativeElement.addEventListener(
      'click',
      (e) => {
        e.stopPropagation();
        e.preventDefault();
      },
      false
    );
  }
}
