import { identity } from 'rxjs';
import {
  getBaseArrayForIterator,
  getPathWithIndices,
  getValueByPath
} from '../../../data-selection/data-info-util';
import { isArray } from 'lodash-es';

export type ValueFormatter = (value: any, path?: string[], modifier?: string[]) => any;

/**
 * ValueFormatter function that receives a value and optional modifiers.
 * If no modifiers are given, returns value as encoded URI
 * If modifier noencode is given, returns unmodified value
 *
 * Unknown modifiers are skipped
 */
export function encodeUriWithModifiers(value: any, path?: string[], modifier?: string[]) {
  const valueResolved = resolveStringWithModifiers(value, path, modifier);

  if (modifier?.includes('noencode') || modifier?.some((m) => m.startsWith('queryParams:'))) {
    return valueResolved;
  }
  // Default case, no modifiers
  return encodeURIComponent(valueResolved);
}

export const placeholderExp = /\${([\w\s?.[\]-]+?)((\s?\|\s?[^|}]+?)*)}/g;

export function resolveStringWithModifiers(value: any, path?: string[], modifier?: string[]) {
  return asString(modifyValues(value, path, modifier));
}

/*
 * Avoid stringifying the resolved values in Slate
 * For handling Arrays to be displayed using line breaks
 * */
export function resolveValueWithModifiersForSlate(
  value: any,
  path?: string[],
  modifier?: string[]
) {
  return modifyValues(value, path, modifier);
}

/**
 * Executes modifiers if matching
 * join: ',' on ['v1', 'v2'] -> v1,v2
 * queryParams: 'multiParam' on ['v1', 'v2'] -> multiParam=v1&multiParam=v2
 * relativeTimestamp: calculates relative time to current time given a value from filter widget
 * addTime:<duration in milliseconds>: calculates relative time to insights.timestamp using value from modifier
 */
function modifyValues(value: any, path?: string[], modifier?: string[]) {
  const modifierImpl = [
    {
      match: /^replace(:\s*['](.+?)['],\s*['](.+?)['])?/, // |replace:'search','replace'
      apply: (origVal: any, searchValue?: string, replaceValue?: string) => {
        if (typeof origVal === 'undefined') {
          origVal = String(origVal);
        }
        if (typeof origVal === 'string' && origVal.length > 0) {
          return origVal.replace(new RegExp(searchValue, 'g'), replaceValue);
        }
        if (Array.isArray(origVal)) {
          return origVal.map((item: any) => {
            if (
              typeof item === 'undefined' ||
              typeof item === 'object' ||
              typeof item === 'number'
            ) {
              item = String(item);
            }
            return item.replace(new RegExp(searchValue, 'g'), replaceValue);
          });
        }
        return origVal;
      }
    },
    {
      match: /^join(:\s*['](.+?)['])?/, // |join:','
      apply: (origVal, args) => {
        let valueArray = origVal;
        if (typeof origVal === 'string' && origVal.length > 0) {
          valueArray = [origVal];
        }
        if (Array.isArray(valueArray)) {
          return valueArray.map((val) => asString(val)).join(args);
        } else {
          return undefined;
        }
      }
    },
    {
      match: /^queryParams(:\s*['"]((.+?)*)['"])?/, // |queryParams:'multiParam'
      apply: (origVal, args) => {
        let valueArray = origVal;
        if (typeof origVal === 'string' && origVal.length > 0) {
          valueArray = [origVal];
        }
        if (Array.isArray(valueArray)) {
          return valueArray
            .map((v) => `${encodeURIComponent(args)}=${encodeURIComponent(asString(v))}`)
            .join('&');
        } else {
          return undefined;
        }
      }
    },
    {
      match: /^relativeTimestamp$/, // |relativeTimestamp
      apply: (origVal) => {
        if (typeof origVal === 'string' && origVal.trim().match(/^[+-]?\d+$/)) {
          const currentTime = new Date();
          const resultTime = new Date(currentTime.getTime() + Number(origVal.trim()));
          return resultTime.toISOString();
        }
        return origVal;
      }
    },
    {
      match: /^addTime(:\s{0,5}(.{1,20}?))?/, // |addTime:milliseconds
      apply: (origVal) => {
        const timeDifference = modifier[0].split(':')[1].trim();
        if (typeof origVal === 'string' && timeDifference.match(/^[+-]?\d+$/)) {
          const currentTime = new Date(origVal);
          const resultTime = new Date(currentTime.getTime() + Number(timeDifference));
          return resultTime.toISOString();
        }
        return origVal;
      }
    }
  ];

  modifierImpl.forEach((modifierRule) => {
    const matchedModifier = modifier?.find((mod) => mod.match(modifierRule.match));
    if (matchedModifier !== undefined) {
      const matches = modifierRule.match.exec(matchedModifier);
      if (matchedModifier.includes('replace')) {
        value = modifierRule.apply(value, matches[2], matches[3]);
      } else {
        value = modifierRule.apply(value, matches[2]);
      }
    }
  });
  return value;
}

/**
 * Identifies all placeholders, gets the length of all iterating placeholders, return the maximum
 * e.g. '${array1[i]} ${array2[i]}', will return the length of the longer array.
 */
export function getMaxLengthOfAllIteratingPlaceholders(
  contentWithPlaceholders: string,
  context: any
): number {
  placeholderExp.lastIndex = 0;
  let match: RegExpExecArray = placeholderExp.exec(contentWithPlaceholders);
  let maxLength = 0;
  while (match !== null) {
    const path = match[1];
    const baseArray = getBaseArrayForIterator(context, path);

    maxLength = maxLength > baseArray.length ? maxLength : baseArray.length;
    match = placeholderExp.exec(contentWithPlaceholders);
  }
  return maxLength;
}

/**
 * Returns an array with the maximum length of all iterating placeholders.
 * Each entry is an array tuple of the contents. If a content is not iterating it will be filled up to the length
 * of the longest iterating.
 * Basically returns a table and the contents define the columns.
 */
export function resolveMultipleIteratingPlaceholders(
  contents: any[],
  valueFormatter: ValueFormatter | ValueFormatter[] = identity,
  ...dataSource: any[]
) {
  const formatterByCol = (i: number): ValueFormatter =>
    Array.isArray(valueFormatter) ? valueFormatter[i] ?? identity : valueFormatter;
  const rowsList = contents.map((content, c) => {
    if (Array.isArray(content)) {
      return resolveMultipleIteratingPlaceholders(content, formatterByCol(c), ...dataSource);
    } else {
      return resolveIteratingPlaceholders(content, formatterByCol(c), ...dataSource);
    }
  });
  const maxLength = Math.max(...rowsList.map((rows) => rows.length));
  for (let c = 0; c < rowsList.length; c++) {
    const rows = rowsList[c];
    if (rows.length < maxLength) {
      for (let r = rows.length; r < maxLength; r++) {
        rows.push(resolvePlaceholders(contents[c], r, formatterByCol(c), ...dataSource));
      }
    }
  }
  const result = new Array(maxLength);
  for (let r = 0; r < maxLength; r++) {
    result[r] = new Array(rowsList.length);
    for (let c = 0; c < rowsList.length; c++) {
      result[r][c] = rowsList[c][r];
    }
  }

  return result;
}

/**
 * Returns a list of content with resolved placeholders.
 * Assumes that placeholders have [i] iterators
 */
export function resolveIteratingPlaceholders(
  content: string,
  valueFormatter: ValueFormatter = identity,
  ...dataSource: any[]
): string[] {
  const maxLength = Math.max(
    ...dataSource.map((data) => getMaxLengthOfAllIteratingPlaceholders(content, data))
  );
  const result = [];
  for (let i = 0; i < maxLength; i++) {
    result.push(resolvePlaceholders(content, i, valueFormatter, ...dataSource));
  }
  return result;
}

export function resolvePlaceholders(
  content: string,
  index?: number,
  valueFormatter: ValueFormatter = identity,
  ...dataSource: any[]
) {
  return replacePlaceholdersWithResolver(content, index, (path: string[], modifier?: string[]) => {
    for (const ds of dataSource) {
      const value = getValueByPath(ds, path);
      if (value === undefined) {
        continue;
      }
      return valueFormatter(value, path, modifier);
    }
    console.warn(`Failed to resolve placeholders for ${content}`);
    return valueFormatter(undefined, path, modifier);
  });
}

export function replacePlaceholders(
  content: string,
  context: any,
  index?: number,
  valueFormatter: ValueFormatter = identity
) {
  return replacePlaceholdersWithResolver(content, index, (path: string[], modifier?: string[]) => {
    return valueFormatter(getValueByPath(context, path), path, modifier);
  });
}

export function replacePlaceholdersWithResolver(
  content: string,
  index: number,
  resolvePath: (path: string[], modifier?: string[]) => any,
  stringifyValues = true
) {
  placeholderExp.lastIndex = 0;
  let match: RegExpExecArray = placeholderExp.exec(content);
  while (match !== null) {
    const path = match[1];
    const modifier = match[2]
      .split('|')
      .map((m) => m.trim())
      .filter((m) => m !== '');
    const resolvedPath = getPathWithIndices(path, { i: index });
    const value = resolvePath(resolvedPath, modifier);

    placeholderExp.lastIndex = match.index + String(value).length;

    content =
      content.substring(0, match.index) +
      (stringifyValues ? value : joinArrayValues(value)) +
      content.substring(match.index + match[0].length);

    match = placeholderExp.exec(content);
  }
  return content;
}

function joinArrayValues(value: any) {
  if (isArray(value)) {
    return value.join(`\n`);
  }
  return value;
}

export function asString(value: any) {
  return typeof value === 'object' && value ? JSON.stringify(value) : value;
}

export function extractChildren(elements: any[]) {
  const result = [];

  elements.forEach((item) => {
    if (item.children) {
      result.push(...item.children);
      result.push(...extractChildren(item.children));
    }
  });

  return result;
}
