import { PropertyEntry } from '../../general-properties.model';
import { isArray, isPlainObject } from 'lodash-es';
import { transformDate } from '../../../../../shared/date-pipe-util';
import { transformNumber } from '../../../../../shared/number-pipe-util';
import { TranslatedString } from '../../../../../shared-projects/models/project.model';
import { transformTranslatedString } from '../../../../../shared/translation-pipe-util';
import { truncateString } from '../../../../../shared/truncate-pipe-util';
import { DevicesService } from '../../../../services/devices.service';
import { Device } from '../../../../models/device';
import { combineLatestWith, Observable, of } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

export function getPropertyValueDisplayElementByType(
  property: PropertyEntry,
  language: string,
  devicesService: DevicesService
): HTMLDivElement | HTMLSpanElement {
  const valueContainer: HTMLSpanElement = document.createElement('span');
  valueContainer.setAttribute('data-cy', 'devicePropertyValue');

  switch (property.type) {
    case 'text':
      valueContainer.innerText = property.value;
      return valueContainer;
    case 'selection':
      valueContainer.innerText = property.selectionOptions
        ? getPropertyValueLabelSelection(property)
        : property.value;
      return valueContainer;
    case 'date':
      valueContainer.innerText = transformDate(property.value, 'll');
      return valueContainer;
    case 'datetime':
      return getDateTimeLabelAsHtmlElement(property.value, 'lll');
    case 'datetimeMediumSeconds':
      return getDateTimeLabelAsHtmlElement(property.value, 'L LTS');
    case 'number':
      valueContainer.innerText = transformNumber(property.value, language);
      return valueContainer;
    case 'double':
      valueContainer.innerText = transformNumber(property.value, language, '1.0-5');
      return valueContainer;
    case 'textMultiline':
      return getMultilineTextAsHtmlElement(property.value);
    case 'boolean':
      valueContainer.classList.add('rb-ic', 'icon', getBooleanValueIcon(property.value));
      valueContainer.setAttribute('rbTooltip', property.value ? 'true' : 'false');
      return valueContainer;
    case 'hyperlink':
      return getHyperLinkAsHtmlElement(property, language);
    case 'device':
      return getDeviceLinkAsHtmlElement(property, devicesService);
    default:
      return valueContainer;
  }
}

function getPropertyValueLabelSelection(property: PropertyEntry): string {
  if (isPlainObject(property.value)) {
    return Object.keys(property.value)
      .map((val) => getSelectOptionLabel(property, val))
      .join(', ');
  } else if (isArray(property.value)) {
    return property.value.map((val) => getSelectOptionLabel(property, val)).join(', ');
  } else {
    return getSelectOptionLabel(property, property.value);
  }
}

function getSelectOptionLabel(property: PropertyEntry, value: string): string {
  const option = property.selectionOptions.find((opt) => opt.value === value);
  if (option) {
    return option.label;
  }
  return value;
}

function getDateTimeLabelAsHtmlElement(date: any, contentFormat: string): HTMLSpanElement {
  const container = document.createElement('span');
  container.title = transformDate(date, 'YYYY-MM-DD HH:mm:ss.SSS');
  container.innerText = transformDate(date, contentFormat);
  return container;
}

function getMultilineTextAsHtmlElement(text: string): HTMLDivElement {
  const container = document.createElement('div');
  container.classList.add('multiline-text');
  container.innerText = text;
  return container;
}

function getBooleanValueIcon(value: unknown): string {
  if (value === true) {
    return 'rb-ic-checkmark';
  } else if (value === '' || value === false) {
    return 'rb-ic-close';
  }
  return '';
}

function getHyperLinkAsHtmlElement(property: PropertyEntry, language: string): HTMLDivElement {
  const classes = ['value-label'];
  const valueDescriptionContainer = document.createElement('div');
  if (property.value) {
    if (property.description) {
      classes.push('d-flex', 'align-items-center');
      valueDescriptionContainer.classList.add(...classes);
      valueDescriptionContainer.appendChild(
        composeDescriptionElementAsHtmlString(property.description, language)
      );
    }
    const hyperlinkAnchor = document.createElement('a');
    hyperlinkAnchor.href = property.value;
    hyperlinkAnchor.target = '_blank';
    hyperlinkAnchor.setAttribute('unsafeUrlHandler', '');
    hyperlinkAnchor.innerText = property.key;

    const externalLinkIcon = document.createElement('span');
    externalLinkIcon.classList.add('rb-ic', 'rb-ic-externallink', 'icon');
    hyperlinkAnchor.appendChild(externalLinkIcon);

    valueDescriptionContainer.append(hyperlinkAnchor);
  } else {
    valueDescriptionContainer.classList.add(...classes);
    valueDescriptionContainer.appendChild(
      composeDescriptionElementAsHtmlString(property.description, language)
    );
  }

  return valueDescriptionContainer;
}

function composeDescriptionElementAsHtmlString(
  description: TranslatedString,
  language: string
): HTMLParagraphElement {
  const descriptionContainer = document.createElement('p');

  if (description) {
    const translation = transformTranslatedString(description, language);
    const truncation = 50;
    descriptionContainer.classList.add('hint-label', 'mb-0');
    descriptionContainer.style.setProperty('font-size', '12px');
    descriptionContainer.innerText = truncateString(translation, truncation);
    descriptionContainer.setAttribute('data-cy', 'displayDescriptionContent');

    if (translation.length > truncation) {
      descriptionContainer.setAttribute('rbToolTip', translation);
    }
  }

  return descriptionContainer;
}

function getDeviceLinkAsHtmlElement(
  property: PropertyEntry,
  deviceService: DevicesService
): HTMLDivElement {
  const deviceLinkElement = document.createElement('div');
  deviceLinkElement.innerText = property.value;

  if (property.value) {
    deviceLinkElement.onclick = () =>
      deviceService.navigateToDeviceOverviewPageByThingId(property.value);
    deviceLinkElement.classList.add('rb-link', 'rb-btn', 'p-0', 'propertyValueDisplayDeviceLink');
  } else {
    deviceLinkElement.classList.add(
      'mr-1',
      'vlign-icons',
      'rb-ic',
      'rb-ic-alert-error-filled',
      'text-danger'
    );
    deviceLinkElement.style.setProperty('font-size', '1.3rem');
  }

  return deviceLinkElement;
}

/**
 * Replacing thingIds within a dedicated container
 * Prevents using observables as they excessively accumulate in the calendar resource view
 */
function replaceResolvedThingIdDeviceLinksInContainer(
  container: HTMLElement,
  devicesCache: { [thingId: string]: Device }
) {
  const deviceLinkElements = container.getElementsByClassName('propertyValueDisplayDeviceLink');

  for (const element of deviceLinkElements) {
    element.innerHTML = devicesCache[element.innerHTML]?.getName() || element.innerHTML;
  }
}

export function loadAndResolveDeviceNamesForDeviceLinkProperties(
  properties: PropertyEntry[] = [],
  container: HTMLElement,
  devicesService: DevicesService
): Observable<any> {
  const deviceLinkIds = properties
    .filter((property) => property.type === 'device')
    .map((property) => devicesService.getDeviceCombinedRequest(property.value));

  // Caching the Device Property in order to resolve the DeviceLinks to Name
  // See replaceResolvedThingIdDeviceLinksInContainer() in property-value-display.util.ts
  return of().pipe(
    combineLatestWith([...deviceLinkIds]),
    finalize(() =>
      replaceResolvedThingIdDeviceLinksInContainer(container, devicesService.cache.devicesCache)
    ),
    take(1)
  );
}
