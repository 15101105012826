<div
  class="widget"
  [class.h-100]="dashboardsService.isSpecificWidgetRoute"
  [class.overflow-y-hidden]="!isGridLayout"
  [class.overflow-x-hidden]="!isGridLayout"
  [class.editable]="editing"
  [class.disabled]="!dashboardsService.isWidgetVisible(widget)"
  [ngClass]="{ hover: mouseIn }"
>
  <div *ngIf="!dashboardsService.isWidgetVisible(widget)" class="disabled-msg">
    <strong>{{ widgetType.label | translated }}</strong>
    <br />
    <span>{{ 'widget.dashboard.hiddenWidget' | translate }}</span>
  </div>
  <div
    #widgetContent
    class="widget-content"
    [class.overflow-y-hidden]="isGridLayout"
    [class.overflow-x-hidden]="isGridLayout"
    [style.height]="getWidgetContainerHeight()"
  ></div>
  <rb-callout *ngIf="error">
    {{ 'dashboard.widgetCouldNotLoad' | translate }} {{ error }}
  </rb-callout>
  <div
    class="widget-controls"
    *ngIf="!isGridLayout || (isGridLayout && !dashboardsService.isWidgetVisible(widget))"
    [class.d-block]="isGridLayout"
    [ngClass]="{ hover: mouseIn, grid: isGridLayout }"
  >
    <a href="#" (click)="openEditWidget()" class="p-1" data-cy="widgetEditButton">
      <span class="rb-ic rb-ic-notepad-edit rb-1-5x"></span>
    </a>
  </div>
</div>
