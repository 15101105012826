import { translate } from '../../../shared/translation-util';

export type SelectedTypesConfig = {
  selectedTypes: SelectType[];
  defaultType: SelectType;
};

export enum SelectType {
  USER_CONTEXT = 'userContext',
  DATA_SOURCE = 'dataSource',
  CUSTOM_VALUE = 'customValue',
  FILTER_WIDGET = 'filterWidget',
  INPUT_WIDGET = 'inputWidget'
}

export type SelectTypeList = {
  value: SelectType;
  label: string;
};

export const selectTypesList: SelectTypeList[] = [
  {
    value: SelectType.CUSTOM_VALUE,
    label: translate('valueOrReferenceInput.customValue')
  },
  {
    value: SelectType.DATA_SOURCE,
    label: translate('valueOrReferenceInput.dataSource')
  },
  {
    value: SelectType.USER_CONTEXT,
    label: translate('valueOrReferenceInput.userContext')
  },
  {
    value: SelectType.FILTER_WIDGET,
    label: translate('valueOrReferenceInput.filterWidget')
  },
  {
    value: SelectType.INPUT_WIDGET,
    label: translate('valueOrReferenceInput.inputWidget')
  }
];

export const CHARTS_SELECTED_TYPES_CONFIG: SelectedTypesConfig = {
  selectedTypes: [SelectType.CUSTOM_VALUE, SelectType.DATA_SOURCE],
  defaultType: SelectType.CUSTOM_VALUE
};
