import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Constants } from '../../../constants';
import { AppThemingService } from '../services/app-theming.service';
import { AuthProviderPrefix } from '../services/models/auth-provider';
import { UserAuthService } from '../services/user-auth.service';
import { AdminDomainService } from '../../project-admin/services/admin-domain.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [AdminDomainService]
})
export class HomeComponent implements OnInit, OnDestroy {
  routing = Constants.routing;

  param = { value: 'User' };

  isLoggedIn = false;

  login: (authProvider: AuthProviderPrefix) => void;

  private authSub: Subscription;

  constructor(public authService: UserAuthService, public appTheming: AppThemingService) {
    this.param.value = authService.userName;
  }

  ngOnInit() {
    this.login = this.authService.createLoginCallback();
    this.authSub = this.authService.isLoggedInStream().subscribe((loggedIn: boolean) => {
      this.isLoggedIn = loggedIn;
    });
  }

  ngOnDestroy(): void {
    this.authSub?.unsubscribe();
  }
}
