import { Injectable, ViewContainerRef } from '@angular/core';
import { GridsterComponent, GridsterItem } from 'angular-gridster2';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { DashboardWidgetConfig } from '../models/DashboardWidgetConfig';
import { DashboardGridComponent } from '../widget-commons/dashboard-grid/dashboard-grid.component';
import { TabConfig } from '../widgets/tab-widget/tab-widget.model';
import { DashboardDataService } from './dashboard-data.service';
import { DashboardGridService } from './dashboard-grid.service';

export type relayoutContext = 'main' | string | null;

@Injectable()
export class DashboardGridSingletonService {
  /**
   * Can be 'main' when changing the main dashboard layout
   * In format {tabWidgetId}-{tabId} in case of changing layout inside in tab widget context
   * Or null when no layout is currently being modified
   **/
  relayoutContext = new BehaviorSubject<relayoutContext>(null);

  /**
   * Used to hold all available grid component instances.
   * The key again is in the same format - 'main' for main layout instance and {tabWidgetId}-{tabId} for specific tab instance
   */
  gridsterInstances: { [relayoutContext: string]: DashboardGridComponent } = {};

  get mainLayoutInstance() {
    return this.gridsterInstances['main'];
  }

  get isMainRelayoutContext() {
    return this.relayoutContext.value === 'main';
  }

  constructor(public dashboardDataService: DashboardDataService) {}

  /**
   * Used to handle dropping of gridster item from main layout to tab widget layout.
   */
  dropItemFromMainToTabWidgetLayout(source: GridsterItem, target: GridsterItem) {
    if (source.widget.type !== 'tabwidget' && target.widget.type === 'tabwidget') {
      const tabWidgetId = target.widget.id;
      const currentTab =
        this.gridsterInstances[
          Object.keys(this.gridsterInstances).find((key) => key.startsWith(tabWidgetId))
        ].currentTabId;
      this.addWidgetToTabWidget(this.assembleTabUniqueIdentifier(tabWidgetId, currentTab), source);
    }
  }

  /**
   * Used to handle dropping of gridster item from tab widget layout to main layout.
   */
  dropItemFromTabWidgetToMainLayout(
    draggedElement: GridsterItem,
    dropPosition: MouseEvent,
    tabIdentifier: string
  ) {
    if (
      draggedElement.widget.visibility === 'tab' &&
      this.isDropPositionOutsideCurrent(
        { x: dropPosition.clientX, y: dropPosition.clientY },
        this.gridsterInstances[tabIdentifier].dashboardGridService.gridster
      )
    ) {
      const widgetsLeft = this.removeWidgetFromTabWidget(
        this.relayoutContext.value,
        draggedElement
      );

      this.mainLayoutInstance.dashboardGridService.widgets.find(
        (widget) => widget.id === draggedElement.widget.id
      ).visibility = null;

      this.mainLayoutInstance.dashboardGridService.convertWidgetsToItems();

      if (widgetsLeft < 1) {
        this.saveRelayout();
      }
    }
  }

  assembleTabUniqueIdentifier(tabWidgetId: string, tabId: string) {
    return `${tabWidgetId}-${tabId}`;
  }

  activateRelayout(relayoutContext: relayoutContext) {
    this.relayoutContext.next(relayoutContext);
  }

  saveRelayout(tabLayoutServiceInstance?: DashboardGridService) {
    if (
      (this.relayoutContext.value && this.relayoutContext.value !== 'main') ||
      tabLayoutServiceInstance
    ) {
      const allWidgets = [...this.mainLayoutInstance.dashboardGridService.widgets];
      const tabWidgets = Object.values(this.gridsterInstances)
        .filter((dashboardGridComponent) => dashboardGridComponent.tabId)
        .flatMap((dashboardGridComponent) => dashboardGridComponent.widgets);

      if (allWidgets && tabWidgets) {
        tabWidgets.forEach((widget) => {
          if (widget) {
            const index = allWidgets.findIndex((widgetConfig) => widgetConfig.id === widget.id);
            if (index !== -1) {
              allWidgets[index] = widget;
            }
          }
        });
        const allUpdatedWidgets = allWidgets.map((widget) => {
          const widgetFound = (
            this.gridsterInstances[this.relayoutContext.value] || tabLayoutServiceInstance
          ).widgets.find((w) => w.id === widget.id);

          return widgetFound || widget;
        });
        this.updateDashboard(allUpdatedWidgets);
      }
    } else {
      this.updateDashboard(this.mainLayoutInstance.dashboardGridService.widgets);
      // often when relayout the main dashboard it's good to also re-render/update the tab widget layouts
      Object.keys(this.gridsterInstances)
        .filter((instance) => this.gridsterInstances[instance].tabWidgetContext)
        .forEach((instance) => {
          this.gridsterInstances[instance].dashboardGridService.convertWidgetsToItems(true);
        });
    }

    this.relayoutContext.next(null);
  }

  registerGridComponentInstance(id: string, gridInstance: DashboardGridComponent) {
    this.gridsterInstances[id] = gridInstance;
  }

  updateDashboard(widgets: DashboardWidgetConfig[]) {
    const updatedWidgets = cloneDeep(widgets).map((widget) => {
      if (widget.type === 'tabwidget') {
        widget.properties.tabConfig.forEach((conf) => {
          delete conf.instances;
        });
      }
      return widget;
    });

    // Make sure the main layout also contains the updated widgets inside tabs
    this.mainLayoutInstance.dashboardGridService.widgets.forEach((widget) => {
      Object.assign(
        widget,
        updatedWidgets.find((w) => w.id === widget.id)
      );
    });

    this.mainLayoutInstance.dashboardGridService.convertWidgetsToItems(false);

    this.dashboardDataService
      .updateDashboard(
        this.dashboardDataService.currentConfig.name,
        {
          ...this.dashboardDataService.currentConfig,
          widgets: updatedWidgets
        },
        true
      )
      .subscribe((config) => {
        this.dashboardDataService.updateConfig(config);
      });
  }

  reset() {
    this.relayoutContext.next(null);
    this.gridsterInstances = {};
  }

  handleTabChange(tabId: string) {
    if (tabId !== this.relayoutContext.value?.split('-')[1] && !this.isMainRelayoutContext) {
      this.relayoutContext.next(null);
    }
  }
  /**
   * Modifies default styles of the gridster layout by making the overflow property of a parent element visible and adjusting the z-index property.
   * @param {ViewContainerRef} viewContainerRef The ViewContainerRef instance.
   * @param {boolean} removeProperties Indicates whether to remove the modified properties.
   */
  static overrideGridsterStyles(viewContainerRef: ViewContainerRef, removeProperties = false) {
    const gridsterParent: HTMLBodyElement = viewContainerRef.element.nativeElement.closest(
      '.gridster-item.overflow-hidden'
    );

    const currentGridsterElement: HTMLBodyElement = viewContainerRef.element.nativeElement.closest(
      '.gridster-item.overflow-visible'
    );

    const columnBased = viewContainerRef.element.nativeElement.closest(
      '.widget.overflow-y-hidden.overflow-x-hidden'
    );

    const hasTabWidget = viewContainerRef.element.nativeElement.closest('tab-widget');
    if (columnBased) {
      const tabWidgetContainer = hasTabWidget
        ? hasTabWidget?.closest('.widget.overflow-y-hidden.overflow-x-hidden')
        : null;
      if (hasTabWidget && tabWidgetContainer) {
        this.setOverflowProperty(tabWidgetContainer);
      }
      this.setOverflowProperty(columnBased);
      if (removeProperties) {
        this.removeOverflowProperty(columnBased);
        if (hasTabWidget && tabWidgetContainer) {
          this.removeOverflowProperty(tabWidgetContainer);
        }
      }
    } else if (currentGridsterElement) {
      this.overrideGridsterOverflow(currentGridsterElement, gridsterParent, removeProperties);
    }
  }

  private static overrideGridsterOverflow(
    currentGridsterElement: HTMLBodyElement,
    gridsterParent: HTMLBodyElement,
    removeProperties: boolean
  ) {
    const isTabWidget = gridsterParent !== null;
    const widgetContentParent: NodeListOf<Element> = document.querySelectorAll('.widget-content');
    const closestGridWidget = gridsterParent?.nextElementSibling as HTMLBodyElement;
    if (isTabWidget) {
      gridsterParent.style.setProperty('overflow', 'visible', 'important');
      closestGridWidget.style.setProperty('z-index', '0');
    }
    currentGridsterElement.style.setProperty('z-index', '5');
    widgetContentParent.forEach((element: HTMLBodyElement) => {
      this.setOverflowProperty(element);
    });

    if (removeProperties) {
      if (isTabWidget) {
        gridsterParent.style.removeProperty('overflow');
        closestGridWidget.style.setProperty('z-index', '1');
      }
      currentGridsterElement.style.setProperty('z-index', '1');
      widgetContentParent.forEach((element: HTMLBodyElement) => {
        this.removeOverflowProperty(element);
      });
    }
  }

  private static setOverflowProperty(element: HTMLBodyElement) {
    element.style.setProperty('overflow-x', 'unset');
    element.style.setProperty('overflow-y', 'unset');
  }

  private static removeOverflowProperty(element: HTMLBodyElement) {
    element.style.removeProperty('overflow-x');
    element.style.removeProperty('overflow-y');
  }

  private removeWidgetFromTabWidget(
    tabWidgetContext: string,
    draggedElement: GridsterItem
  ): number {
    const tabConfig = this.getTabConfig(tabWidgetContext);

    tabConfig.widgets = tabConfig.widgets.filter(
      (widgetId) => widgetId !== draggedElement.widget.id
    );
    tabConfig.instances = tabConfig.instances.filter(
      (instance) => instance.id !== draggedElement.widget.id
    );

    const tabWidgetGridComponent = this.gridsterInstances[tabWidgetContext];
    const tabWidgetGridService = tabWidgetGridComponent?.dashboardGridService;

    tabWidgetGridService.widgets = tabWidgetGridService.widgets.filter(
      (widget) => widget.id !== draggedElement.widget.id
    );
    tabWidgetGridService.convertWidgetsToItems();
    tabWidgetGridComponent.widgetsChange.emit(tabWidgetGridService.widgets);
    return tabWidgetGridService.widgets.length;
  }

  private addWidgetToTabWidget(tabIdentifier: string, draggedElement: GridsterItem) {
    draggedElement.widget.visibility = 'tab';

    this.insertWidgetInTabConfig(tabIdentifier, draggedElement);
    const newItem = this.insertWidgetInGridsterInstance(tabIdentifier, draggedElement);

    const widget = this.mainLayoutInstance.dashboardGridService.widgets.find(
      (gridWidget) => gridWidget.id === draggedElement.widget.id
    );
    widget.insideTab = true;
    widget.positionX = newItem.x;
    widget.positionY = newItem.y;

    this.gridsterInstances[tabIdentifier].widgetsChange.emit(
      this.gridsterInstances[tabIdentifier].dashboardGridService.widgets
    );
    this.mainLayoutInstance.dashboardGridService.convertWidgetsToItems();
  }

  private isDropPositionOutsideCurrent(
    dropPosition: { x: number; y: number },
    gridsterComponent: GridsterComponent
  ): boolean {
    const currentGridRect = gridsterComponent.el.getBoundingClientRect();

    return (
      dropPosition.x > currentGridRect.x + currentGridRect.width ||
      dropPosition.x < currentGridRect.x ||
      dropPosition.y > currentGridRect.y + currentGridRect.height ||
      dropPosition.y < currentGridRect.y
    );
  }

  private getTabConfig(tabWidgetContext: string): TabConfig {
    const selectedTabId = this.gridsterInstances[tabWidgetContext].currentTabId;
    const tabWidget = this.mainLayoutInstance.dashboardGridService.widgets.find(
      (widget) => widget.id === tabWidgetContext.split('-')[0]
    );

    return tabWidget.properties.tabConfig.find((conf) => conf.id === selectedTabId);
  }

  private insertWidgetInTabConfig(tabIdentifier: string, draggedElement: GridsterItem): void {
    const tabConfig = this.getTabConfig(tabIdentifier);
    if (tabConfig.widgets.find((wId) => wId === draggedElement.widget.id)) {
      return;
    }
    tabConfig.widgets.push(draggedElement.widget.id);
    if (tabConfig.instances) {
      tabConfig.instances.push(draggedElement.widget);
    } else {
      tabConfig.instances = [draggedElement.widget];
    }
  }

  private insertWidgetInGridsterInstance(tabIdentifier: string, draggedElement: GridsterItem) {
    const gridServiceInstance = this.gridsterInstances[tabIdentifier].dashboardGridService;

    if (!gridServiceInstance.widgets) {
      gridServiceInstance.widgets = [];
    }
    if (!gridServiceInstance.widgets.find((widget) => widget.id === draggedElement.widget.id)) {
      gridServiceInstance.widgets.push(draggedElement.widget);
    }
    gridServiceInstance.convertWidgetsToItems(true);

    const item = gridServiceInstance.items.find(
      (gridItem) => gridItem.widget.id === draggedElement.widget.id
    );
    const newPositions = gridServiceInstance.gridster.getFirstPossiblePosition(item);
    item.x = newPositions.x;
    item.y = newPositions.y;

    return item;
  }
}
