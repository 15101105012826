import { Injectable } from '@angular/core';
import { DateRangePickerPreset, lastXISoString } from '@inst-iot/bosch-angular-ui-components';
import { TranslateService } from '@ngx-translate/core';
import { translate } from '../translation-util';

@Injectable({
  providedIn: 'root'
})
export class DatePickerService {
  readonly oneHour = 1000 * 60 * 60; // ms * s * m
  readonly oneDay = this.oneHour * 24;
  readonly oneWeek = this.oneDay * 7;

  constructor(private translateService: TranslateService) {}

  getLast1h(): DateRangePickerPreset {
    return {
      label: this.translateService.instant(translate('timeRangePicker.absoluteLast1h')),
      absoluteRange: [lastXISoString(-this.oneHour), lastXISoString(0)]
    };
  }

  getLast6h(): DateRangePickerPreset {
    return {
      label: this.translateService.instant(translate('timeRangePicker.absoluteLast6h')),
      absoluteRange: [lastXISoString(-this.oneHour * 6), lastXISoString(0)]
    };
  }

  getLast12h(): DateRangePickerPreset {
    return {
      label: this.translateService.instant(translate('timeRangePicker.absoluteLast12h')),
      absoluteRange: [lastXISoString(-this.oneHour * 12), lastXISoString(0)]
    };
  }

  getLast24h(): DateRangePickerPreset {
    return {
      label: this.translateService.instant(translate('timeRangePicker.absoluteLast24h')),
      absoluteRange: [lastXISoString(-this.oneDay), lastXISoString(0)]
    };
  }

  getLast48h(): DateRangePickerPreset {
    return {
      label: this.translateService.instant(translate('timeRangePicker.absoluteLast48h')),
      absoluteRange: [lastXISoString(-this.oneDay * 2), lastXISoString(0)]
    };
  }

  getLast7Days(): DateRangePickerPreset {
    return {
      label: this.translateService.instant(translate('timeRangePicker.absoluteLast7Days')),
      absoluteRange: [lastXISoString(-this.oneWeek), lastXISoString(0)]
    };
  }

  getLast1hRelative(): DateRangePickerPreset {
    return {
      label: this.translateService.instant(translate('timeRangePicker.last1h')),
      relativeRange: [-this.oneHour, 0]
    };
  }

  getLast12hRelative(): DateRangePickerPreset {
    return {
      label: this.translateService.instant(translate('timeRangePicker.last12h')),
      relativeRange: [-this.oneHour * 12, 0]
    };
  }

  getLast24hRelative(): DateRangePickerPreset {
    return {
      label: this.translateService.instant(translate('timeRangePicker.last24h')),
      relativeRange: [-this.oneDay, 0]
    };
  }

  getLast7dRelative(): DateRangePickerPreset {
    return {
      label: this.translateService.instant(translate('timeRangePicker.last7Days')),
      relativeRange: [-this.oneWeek, 0]
    };
  }

  getLast14dRelative(): DateRangePickerPreset {
    return {
      label: this.translateService.instant(translate('timeRangePicker.last14Days')),
      relativeRange: [-this.oneWeek * 2, 0]
    };
  }

  getRelativePresets(): DateRangePickerPreset[] {
    return [
      this.getLast1hRelative(),
      this.getLast12hRelative(),
      this.getLast24hRelative(),
      this.getLast7dRelative(),
      this.getLast14dRelative()
    ];
  }

  getAbsolutePresets(): DateRangePickerPreset[] {
    return [
      this.getLast1h(),
      this.getLast6h(),
      this.getLast12h(),
      this.getLast24h(),
      this.getLast48h(),
      this.getLast7Days()
    ];
  }

  getDefaultPresets(): DateRangePickerPreset[] {
    return [
      this.getLast24h(),
      this.getLast48h(),
      this.getLast7Days(),
      this.getLast1hRelative(),
      this.getLast12hRelative(),
      this.getLast24hRelative(),
      this.getLast7dRelative(),
      this.getLast14dRelative()
    ];
  }
}
