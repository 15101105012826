/**
 * Extracts dashboard name from provided url. If the path is not a dashboard one, null will be returned.
 *
 * @example
 * /project/test-project/dashboards/dashboard-name will return dashboard-name
 * /project/test-project/dashboards/dashboard-name/w1/edit will return dashboard-name
 * /project/test-project/dashboards/dashboard-name?query=test will return dashboard-name
 *  /project/test-project will return null
 * /home will return null
 */

export function getDashboardNameFromPath(url: string): string {
  const urlParts = url.split('/dashboards/');
  if (!urlParts[1]) {
    return null;
  }

  if (urlParts[1].includes('/')) {
    return urlParts[1].split('/')[0];
  }
  if (urlParts[1].includes('?')) {
    return urlParts[1].split('?')[0];
  }
  return urlParts[1];
}

export function getUrlParamsFromPath(url: string) {
  const urlParts = url.split('?');
  return urlParts[1] ? `?${urlParts[1]}` : '';
}
