import {
  Component,
  forwardRef,
  HostBinding,
  Input,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PathInfo } from '../data-info-util';
import { availableUserContextParameter } from '../../data-widgets/data-source-config/ds-parameter-input/ds-parameter-input.component';
import { FilterParameterConfig } from '../../../dashboards/widgets/filter-widget/filter-widget.model';
import { DsParameter, DsParameterType } from '../../data-widgets/data-widgets-common';
import { DateRangePickerPreset } from '@inst-iot/bosch-angular-ui-components';
import { FieldConfig } from '../../../dashboards/widgets/input-widget/models/input.model';
import {
  SelectedTypesConfig,
  SelectType,
  SelectTypeList,
  selectTypesList
} from '../models/select-types.model';

@Component({
  selector: 'value-or-reference-input',
  templateUrl: './value-or-reference-input.component.html',
  styleUrls: ['./value-or-reference-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ValueOrReferenceInputComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class ValueOrReferenceInputComponent implements ControlValueAccessor {
  @Input() name: string;

  @Input() label: string | TemplateRef<any> = null;

  @Input() paths: PathInfo[];

  @Input() required = true;

  @Input() hint: string;

  @Input() requireIteration = false;

  @Input() showSelectionMenu = true;

  @Input() disabled = false;

  @Input() placeholder = '';

  @Input() startLabel = '';

  @Input() endLabel = '';

  @Input() presets: DateRangePickerPreset[];

  @HostBinding('class') class = 'value-or-reference';

  /**
   * Allowed types, e.g. number, string, date
   * null = any type allowed
   */
  @Input() allowedTypes: ('number' | 'string')[] = null;

  @Input() filterParameters: FilterParameterConfig[] = [];

  @Input() inputParameters: FieldConfig[] = [];

  @Input() dataType: DsParameterType = 'string';

  @Input() set selectedTypes(config: SelectedTypesConfig) {
    this.selectTypesList = this.assembleSelectedTypesList(config.selectedTypes);
    this.selectedType = config.defaultType ?? this.selectTypesList[0].value;
  }

  selectType = SelectType;

  onChange;
  onTouched;

  selectedType = SelectType.CUSTOM_VALUE;
  selectTypesList = selectTypesList;

  value: string | number[];

  currentColor = '#000000';

  userContextParameter = availableUserContextParameter;

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  updateCurrentColor(color: string): void {
    this.currentColor = color;
    this.value = color;
    this.updateValue(color);
  }

  resetValue(): void {
    this.value = '';
  }

  updateValue(value): void {
    const parameter = {};

    switch (this.selectedType) {
      case SelectType.CUSTOM_VALUE:
        parameter['value'] = this.dataType === 'int' ? parseFloat(value) : value;
        break;
      case SelectType.FILTER_WIDGET:
        parameter['ref'] = `filterParams.${value}`;
        break;
      case SelectType.INPUT_WIDGET:
        parameter['ref'] = `inputFields.${value}`;
        break;
      case SelectType.USER_CONTEXT:
      case SelectType.DATA_SOURCE:
        parameter['ref'] = value;
    }

    this.onChange(parameter);
  }

  writeValue(parameter: DsParameter): void {
    if (parameter) {
      this.value = parameter['ref'] ?? parameter['value'];
      this.selectedType = this.getSelectedTypeByParameter(parameter);
      if (this.selectedType === SelectType.CUSTOM_VALUE && this.dataType === 'datetimerange') {
        this.value = [parameter['min'], parameter['max']];
      }
      if (
        this.selectedType === SelectType.FILTER_WIDGET ||
        this.selectedType === SelectType.INPUT_WIDGET
      ) {
        this.value = String(this.value).split('.')[1];
      }
    } else {
      this.value = null;
    }
  }

  getIcon(selectType: string): string {
    switch (selectType) {
      case SelectType.CUSTOM_VALUE:
        return 'rb-ic-edit';
      case SelectType.DATA_SOURCE:
        return 'rb-ic-core-data';
      case SelectType.USER_CONTEXT:
        return 'rb-ic-people';
      case SelectType.FILTER_WIDGET:
        return 'rb-ic-filter';
      case SelectType.INPUT_WIDGET:
        return 'rb-ic-panel-control';
      default:
        return '';
    }
  }

  private getSelectedTypeByParameter(parameter: DsParameter): SelectType {
    const ref = parameter['ref'];
    if (ref) {
      if (ref.startsWith('insights.')) {
        return SelectType.USER_CONTEXT;
      } else if (ref.startsWith('filterParams.')) {
        return SelectType.FILTER_WIDGET;
      } else if (ref.startsWith('inputFields.')) {
        return SelectType.INPUT_WIDGET;
      } else {
        return SelectType.DATA_SOURCE;
      }
    } else {
      return SelectType.CUSTOM_VALUE;
    }
  }

  private assembleSelectedTypesList(selectedTypes: SelectType[] | undefined): SelectTypeList[] {
    if (!selectedTypes) {
      return selectTypesList;
    }

    return selectTypesList.filter((type) => selectedTypes.includes(type.value));
  }
}
