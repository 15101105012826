import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ConditionChip } from './models/condition-chip.model';
import { Operator, OperatorType } from './models/operator.model';
import { assembleFindQuery } from './utils/query-assembler.util';
import { parseInput } from './utils/syntax-tree-builder.util';
import { QueryConditionBase } from './query-condition-base';
import { badgeTypes, LogicalOperatorType, regex } from './models/syntax-node.model';
import { InputObject } from './models/util-objects.model';
import { loadChipsConfigFromQuerySyntax } from './utils/query-condition.util';
import { isEmpty } from 'lodash-es';
import { isRelative } from '@inst-iot/bosch-angular-ui-components';
import { getAbsoluteFromRelative } from '../../shared/date-time-range-popover/date-time-range-popover.model';

@Component({
  selector: 'query-condition-input',
  templateUrl: './query-condition-input.component.html',
  styleUrls: ['./query-condition-input.component.scss']
})
export class QueryConditionInputComponent extends QueryConditionBase implements OnInit {
  mongoOperators = Operator.operators;

  chips: ConditionChip[] = [ConditionChip.getPrefilledDefaultChip(0)];

  focusedChip: ConditionChip;

  logicalOperators: LogicalOperatorType[] = [];

  @Input() customCSS = '';

  @Input() label: string | TemplateRef<any>;

  @Input() query: Record<string, any> = {};

  @Output() queryChanged = new EventEmitter<{ query: Record<string, any>; valid: boolean }>();

  get queryAsText() {
    return JSON.stringify(this.query, null, 1);
  }

  ngOnInit() {
    if (!isEmpty(this.query)) {
      this.loadConditionsFromFilterValue();
    } else {
      this.initSimpleLogic();
    }
  }

  createChip(inputs?: InputObject, index: number = this.chips.length) {
    this.chips.push(ConditionChip.getPrefilledDefaultChip(index));
    this.validateChangeEvent('INSERT', this.chips[index], this.simpleLogic);
  }

  removeChip(chip: ConditionChip) {
    if (this.chips.length > 1) {
      super.removeChip(chip);
      this.validateChangeEvent('DELETE', chip, this.simpleLogic);
      if (this.isConditionValid()) {
        this.composeQuery();
      }
    } else {
      this.chips = [ConditionChip.getPrefilledDefaultChip(0)];
      this.validateChangeEvent('UPDATE', this.chips[0]);
      this.queryChanged.emit({ query: (this.query = {}), valid: this.isConditionValid() });
      this.initSimpleLogic();
    }
  }

  isConditionValid() {
    return this.chips.every((chip) => chip.isValid) && this.syntaxErrors?.length === 0;
  }

  setChipOperator(operator: Operator, chip: ConditionChip) {
    chip.operatorID = operator.technicalID;
    this.validateChipType(chip);
  }

  validateChipType(chip: ConditionChip) {
    if (chip.operatorID === 'exists') {
      chip.type = 'boolean';
      chip.value = true;
    } else if (
      (chip.type === 'objectId' && chip.operatorID !== 'eq') ||
      chip.operatorID === 'regex'
    ) {
      chip.type = 'string';
      chip.value = String(chip.value);
    }
  }

  composeQuery() {
    this.syntaxTree = parseInput(this.chips, this.syntaxErrors, this.simpleLogic || '');
    this.query = assembleFindQuery(this.syntaxTree);
    this.logicalOperators = this.splitLogicalOperators();
    this.queryChanged.emit({ query: this.query, valid: this.isConditionValid() });
  }

  splitLogicalOperators(): LogicalOperatorType[] {
    return this.simpleLogic
      .split(/(AND|OR)/gi)
      .filter((split) => /(AND|OR)/gi.test(split)) as LogicalOperatorType[];
  }

  switchDataType(chip: ConditionChip) {
    switch (chip.type) {
      case 'number':
        chip.operatorID = 'eq';
        if (regex.containsOnlyNumbers.test(chip.value)) {
          chip.value = parseInt(chip.value);
        } else {
          chip.value = Number();
        }
        break;
      case 'string':
        this.setStringValue(chip);
        break;
      case 'datetimerange':
        chip.operatorID = 'eq';
        chip.value = '';
        break;
      case 'boolean':
        chip.operatorID = 'eq';
        chip.value = true;
        break;
      case 'objectId': {
        if (chip.operatorID !== 'exists' && chip.operatorID !== 'eq') {
          chip.operatorID = 'eq';
        }
        break;
      }
      default: {
        chip.value = '';
      }
    }
  }

  getIncompatibleOperators(dataType: any): OperatorType[] {
    return dataType === 'datetimerange' ? ['gt', 'gte', 'lt', 'lte'] : [];
  }

  switchLogicalOperator(index: number) {
    this.logicalOperators[index] = regex.isAND.exec(this.logicalOperators[index])
      ? badgeTypes.or
      : badgeTypes.and;

    this.simpleLogic = this.simpleLogic.replace(
      new RegExp(index + 1 + '(and|or)', 'ig'),
      index + 1 + this.logicalOperators[index]
    );

    this.composeQuery();
  }

  loadConditionsFromFilterValue() {
    const { chips, simpleLogic } = loadChipsConfigFromQuerySyntax(this.query);
    this.chips = chips;
    this.simpleLogic = String(simpleLogic);
    this.composeQuery();
  }

  trimChipValues(chip: ConditionChip) {
    chip.value = chip.value.trim ? chip.value.trim() : chip.value;
    chip.input = chip.input.trim();
  }

  setBooleanValue(chip: ConditionChip, value: boolean) {
    chip.value = Boolean(value);
  }

  setStringValue(chip: ConditionChip) {
    chip.value = String(chip.value);
  }

  updateDate(chip: ConditionChip, value: any) {
    chip.value = value;
  }

  updateRange(chip: ConditionChip, value: any) {
    if (isRelative(value)) {
      chip.value = getAbsoluteFromRelative(value);
    } else {
      chip.value = value;
    }
  }

  checkOperatorTypeExists(operatorType: OperatorType): boolean {
    return operatorType === 'exists';
  }
}
