import { Pipe, PipeTransform } from '@angular/core';
import { LanguagesService } from '../../core/services/languages.service';
import { transformNumber } from '../number-pipe-util';

@Pipe({
  name: 'number',
  pure: true
})
export class NumberPipe implements PipeTransform {
  constructor(private languageService: LanguagesService) {}

  transform(value: any, args?: any): any {
    return transformNumber(value, this.languageService.currentLanguage, args);
  }
}
