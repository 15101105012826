import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ProjectsService } from '../../shared-projects/services/projects.service';
import { UserAuthService } from './user-auth.service';
import { map } from 'rxjs/operators';
import { UiConfig } from '../../shared/api-model';

@Injectable({
  providedIn: 'root'
})
export class CustomDomainBoundaryGuard implements CanActivate {
  constructor(
    private projectsService: ProjectsService,
    private router: Router,
    private authService: UserAuthService
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.getUiConfig().pipe(
      map((config: UiConfig) => {
        if (
          this.isCustomDomainActivated(config) &&
          this.isCustomDomainNavigationRestricted(state.url, config.currentProject)
        ) {
          console.log('Disallowed Navigation, forward to project: ', config.currentProject);
          this.router.navigateByUrl(`project/${config.currentProject}`);
          return false;
        }
        return true;
      })
    );
  }

  private isCustomDomainActivated(config: UiConfig): boolean {
    return config.currentProject !== '_DEFAULT_';
  }

  private isCustomDomainNavigationRestricted(url: string, currentProjectFlag: string): boolean {
    return (
      (url.includes('ui/projects') || currentProjectFlag !== this.projectsService.projectName) &&
      !!currentProjectFlag
    );
  }
}
