import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeviceTypesService } from '../../../devices/services/device-types.service';
import { DevicesService } from '../../../devices/services/devices.service';
import { isEmpty } from 'lodash-es';

export enum DeviceTypeOption {
  allDevices = 'ALLDEVICES',
  allDeviceTypes = 'ALLDEVICETYPES',
  allBookableDeviceTypes = 'ALLBOOKABLEDEVICETYPES',
  noDeviceType = 'NODEVICETYPE'
}

export class DeviceTypesSelection {
  deviceTypeOptions: any[] = [
    DeviceTypeOption.allDevices,
    DeviceTypeOption.allDeviceTypes,
    DeviceTypeOption.noDeviceType
  ];
  bookableDeviceTypeOptions: any[] = [DeviceTypeOption.allBookableDeviceTypes];
  selectedDeviceTypes: { [key: string]: boolean } = {};
  deviceTypeLabels: { [key: string]: string } = {};

  constructor(
    private translate: TranslateService,
    private deviceTypesService: DeviceTypesService,
    private devicesService: DevicesService
  ) {
    this.deviceTypeLabels = this.translateDeviceTypeOptions();
  }

  private translateDeviceTypeOptions(): { [key: string]: string } {
    const deviceTypeLabels = {};

    deviceTypeLabels[DeviceTypeOption.allDevices] = this.translate.instant(
      'dataWidgets.thingsCount.allDevices'
    );
    deviceTypeLabels[DeviceTypeOption.allDeviceTypes] = this.translate.instant(
      'dataWidgets.thingsCount.allDeviceType'
    );
    deviceTypeLabels[DeviceTypeOption.allBookableDeviceTypes] = this.translate.instant(
      'dataWidgets.thingsCount.allBookableDeviceTypes'
    );
    deviceTypeLabels[DeviceTypeOption.noDeviceType] = this.translate.instant(
      'dataWidgets.thingsCount.noDeviceType'
    );

    return deviceTypeLabels;
  }

  loadDevicesSettings(dsDeviceTypes: string[], bookablesOnly = false): Observable<string> {
    return combineLatest([
      this.devicesService.getNamespace(),
      this.deviceTypesService.getDeviceTypes()
    ]).pipe(
      map(([namespace, deviceTypes]) => {
        deviceTypes
          .filter((dt) => (bookablesOnly ? dt?.bookable : true))
          .forEach((dt) => {
            bookablesOnly
              ? this.bookableDeviceTypeOptions.push(dt.type)
              : this.deviceTypeOptions.push(dt.type);
            this.deviceTypeLabels[dt.type] = dt.type;
          });
        dsDeviceTypes.forEach((dt) => {
          this.selectedDeviceTypes[JSON.stringify(dt)] = true;
        });
        return namespace;
      })
    );
  }

  updateMultiSelectionList(selectionList: string[]): string[] {
    for (const key in this.selectedDeviceTypes) {
      if (this.selectedDeviceTypes[key] === true) {
        if (selectionList.indexOf(JSON.parse(key)) === -1) {
          selectionList.push(JSON.parse(key));
        }
      } else {
        if (selectionList.indexOf(JSON.parse(key)) !== -1) {
          const index = selectionList.indexOf(JSON.parse(key));
          selectionList.splice(index, 1);
        }
      }
    }
    return selectionList;
  }

  static buildFilter(filter: string, selectedDevices: string[]) {
    if (isEmpty(selectedDevices) && isEmpty(filter)) {
      return '';
    }
    if (isEmpty(selectedDevices) && !isEmpty(filter)) {
      return filter;
    }
    const deviceTypeFilters = DeviceTypesSelection.decideOnDeviceTypeFilterPresets(selectedDevices);

    if (!isEmpty(selectedDevices) && isEmpty(filter)) {
      return deviceTypeFilters;
    }
    if (isEmpty(deviceTypeFilters)) {
      return `${filter}`;
    }
    return `and(${deviceTypeFilters},${filter})`;
  }

  static decideOnDeviceTypeFilterPresets(selectedDevices: string[]): string {
    if (isEmpty(selectedDevices)) {
      return '';
    }

    const filters = [];
    for (const s of selectedDevices) {
      switch (s) {
        case DeviceTypeOption.allBookableDeviceTypes:
        case DeviceTypeOption.allDevices:
          return '';
        case DeviceTypeOption.allDeviceTypes:
          filters.push(`exists(attributes/type)`);
          break;
        case DeviceTypeOption.noDeviceType:
          filters.push('not(exists(attributes/type))');
          break;
        default:
          filters.push(`eq(attributes/type,${JSON.stringify(s)})`);
      }
    }
    return 'or(' + filters.join(',') + ')';
  }
}
