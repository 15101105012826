import { Injectable } from '@angular/core';
import { GlobalAlertService } from './global-alert.service';
import { translate } from '../../shared/translation-util';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CookieCheckService {
  constructor(private alertService: GlobalAlertService, private translator: TranslateService) {}

  checkCookieEnabled(): boolean {
    if (!navigator.cookieEnabled) {
      this.translator.get('error.cookiesDisabledDetails').subscribe((res: string) => {
        this.alertService.showError(translate('error.cookiesDisabled'), res);
      });
      return false;
    }
    return true;
  }
}
