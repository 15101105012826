import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { find, first, last } from 'lodash-es';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
import { ProjectsService } from '../../shared-projects/services/projects.service';
import { BookableStatus, DeviceType, DeviceTypeDefinition } from '../models/device-types';
import { TranslatedPipe } from '../../shared/pipes/translated.pipe';
import { DeviceCacheService } from './device-cache.service';

@Injectable({ providedIn: 'root' })
export class DeviceTypesService {
  get deviceTypes(): DeviceTypeDefinition[] {
    return this._deviceTypes.getValue();
  }

  deviceTypes$: Observable<DeviceTypeDefinition[]>;

  private _deviceTypes: BehaviorSubject<DeviceTypeDefinition[]> = new BehaviorSubject<
    DeviceTypeDefinition[]
  >(null);
  private _deviceTypeLoadingObservable;

  private currentDeviceType = new ReplaySubject<DeviceTypeDefinition>(1);

  constructor(
    private http: HttpClient,
    private deviceCache: DeviceCacheService,
    private projectsService: ProjectsService,
    private translated: TranslatedPipe
  ) {
    this.deviceTypes$ = this._deviceTypes.asObservable();
    this.projectsService.projectConfigEvents.subscribe((event) => {
      if (event?.config) {
        this.resetCache();
      }
    });
  }

  get baseUrl(): string {
    return '/project-management-service/v1/' + this.projectsService.projectName + '/device-types';
  }

  /**
   *
   * @example
   * // => vehicle
   * DeviceTypesService.extractTypeFromThingId(andilo_dev:vehicle_112)
   *
   * @param thingId
   */
  extractTypeFromThingId(thingId: string): string {
    return first(last(thingId.split(':')).split('_'));
  }

  findDeviceType(type: string, deviceTypes?): DeviceTypeDefinition | undefined {
    if (deviceTypes && Array.isArray(deviceTypes)) {
      return find(deviceTypes, (deviceType: DeviceTypeDefinition) => deviceType.type === type);
    }
    return find(this.deviceTypes, (deviceType: DeviceTypeDefinition) => deviceType.type === type);
  }

  setCurrentDeviceType(type: DeviceTypeDefinition) {
    this.currentDeviceType.next(type);
  }

  getCurrentDeviceType(): Observable<DeviceTypeDefinition> {
    return this.currentDeviceType;
  }

  getDeviceTypes(): Observable<DeviceTypeDefinition[]> {
    if (this._deviceTypes.value) {
      return this._deviceTypes;
    } else if (this._deviceTypeLoadingObservable) {
      return this._deviceTypeLoadingObservable;
    }

    this._deviceTypeLoadingObservable = this.http.get<DeviceTypeDefinition[]>(this.baseUrl).pipe(
      map((defs: DeviceTypeDefinition[]) => {
        this._deviceTypeLoadingObservable = null;
        defs.sort((a, b) => this.sortDeviceTypes(a, b));
        return defs;
      }),
      tap((defs) => {
        this._deviceTypes.next(defs);
        defs.forEach((d) => this.deviceCache.cacheDeviceType(new DeviceType(d)));
      }),
      share()
    );
    return this._deviceTypeLoadingObservable;
  }

  getMandatoryDeviceTypes(): Observable<DeviceTypeDefinition[]> {
    return this.getDeviceTypesByBookingState([BookableStatus.MANDATORY]);
  }

  getBookableDeviceTypes(): Observable<DeviceTypeDefinition[]> {
    return this.getDeviceTypesByBookingState([
      BookableStatus.BOOKABLE,
      BookableStatus.BOOKABLE_WITH_CONFIRMATION
    ]);
  }

  getDeviceTypesByBookingState(status: BookableStatus[]): Observable<DeviceTypeDefinition[]> {
    return this.getDeviceTypes().pipe(
      map((deviceTypes: DeviceTypeDefinition[]) => {
        return deviceTypes?.filter((dt) => dt.visible && status.includes(dt.bookable));
      })
    );
  }

  private sortDeviceTypes(a: DeviceTypeDefinition, b: DeviceTypeDefinition): number {
    const ordA = a.order || 0;
    const ordB = b.order || 0;
    if (ordA < ordB) {
      return -1;
    }
    if (ordB < ordA) {
      return 1;
    }
    const aLabel = this.translated.transform(a.label).toLowerCase();
    const bLabel = this.translated.transform(b.label).toLowerCase();
    return aLabel < bLabel ? -1 : aLabel > bLabel ? 1 : 0;
  }

  createDeviceType(type: string): Observable<DeviceTypeDefinition> {
    const definition: DeviceTypeDefinition = {
      bookable: BookableStatus.NOT_BOOKABLE,
      type: type,
      definition: null,
      visible: true,
      label: { en: type },
      icon: 'rb-ic-devices',
      features: [
        {
          definition: 'insights:general',
          defaultName: 'general',
          editable: true,
          mandatory: true,
          tracked: false,
          icon: ''
        },
        {
          definition: 'insights:details',
          defaultName: 'details',
          editable: true,
          mandatory: false,
          tracked: false,
          icon: ''
        }
      ]
    };

    this._deviceTypes.next(null);
    return this.http.post<DeviceTypeDefinition>(this.baseUrl, definition);
  }

  saveDeviceType(type: string, data: DeviceTypeDefinition) {
    this.resetCache();
    return this.http.put<DeviceTypeDefinition>(this.baseUrl + '/' + type, data);
  }

  saveDeviceTypesOrder(types: { [type: string]: number }) {
    this.resetCache();
    return this.http.put<DeviceTypeDefinition>(this.baseUrl, types);
  }

  getDeviceType(type: string): Observable<DeviceTypeDefinition> {
    return this.http.get<DeviceTypeDefinition>(this.baseUrl + '/' + type);
  }

  removeDeviceType(type: string): Observable<null> {
    this.resetCache();
    return this.http.delete<null>(this.baseUrl + '/' + type);
  }

  resetCache() {
    this._deviceTypes.next(null);
    this.deviceCache.resetCache();
  }
}
