// https://momentjs.com/docs/#/displaying/format/
// prettier-ignore
import { DateFormatType } from '../shared-modules/data-conditional-formatting/data-conditional-formatting.model';
import * as moment from 'moment/moment';

export const preDefinedFormats = {
  [DateFormatType.MEDIUM]: 'lll', // 20 Jul 2022 15:17
  [DateFormatType.SHORT]: 'l LT', // 2022-7-20 15:17
  [DateFormatType.TIMESTAMP]: 'll llll', // 20 Jul 2022 15:17:42.966
  [DateFormatType.LONG_DATE]: 'LL', // 20 July 2022
  [DateFormatType.MEDIUM_DATE]: 'll', // 20 Jul 2022
  [DateFormatType.SHORT_DATE]: 'l', // 2022-7-20
  [DateFormatType.MEDIUM_TIME]: 'LTS', // 15:17:42
  [DateFormatType.SHORT_TIME]: 'LT', // 15:17
  [DateFormatType.LOG_TIMESTAMP]: 'YYYY-MM-DD HH:mm:ss.SSS', // 2022-07-20 15:17:42.967
  [DateFormatType.LONG]: 'LLLL', // 15:17:42.967
  [DateFormatType.WEEKDAY]: 'ddd', // Wed
  [DateFormatType.ISO]: 'iso', // 2022-07-20T13:17:42.967Z
  [DateFormatType.MEDIUM_SECONDS]: 'll HH:mm:ss', // 20 Jul 2022 15:17:42
  [DateFormatType.MEDIUM_MS]: 'll HH:mm:ss.SSS', // 20 Jul 2022 15:17:42.967
  [DateFormatType.SHORT_SECONDS]: 'l HH:mm:ss', // 2022-7-20 15:17:42
  [DateFormatType.SHORT_MS]: 'l HH:mm:ss.SSS' // 2022-7-20 15:17:42.967
};

export function transformDate(
  value: any,
  format = 'mediumDate',
  disableTimezoneConversion = false
): string {
  let dt = moment(value);
  if (format === 'iso') {
    return dt.toISOString();
  }
  if (preDefinedFormats[format]) {
    format = preDefinedFormats[format];
  } else if (format === 'todayTimeElseDate') {
    const isToday = dt.isSame(moment(), 'd');
    format = isToday ? preDefinedFormats['shortTime'] : preDefinedFormats['shortDate'];
  } else if (format === 'hideDateIfToday') {
    const isToday = dt.isSame(moment(), 'd');
    format = isToday ? preDefinedFormats['shortTime'] : preDefinedFormats['medium'];
  }
  if (disableTimezoneConversion) {
    dt = dt.utc();
  }
  return dt.format(format);
}
