import { ConditionChip } from '../models/condition-chip.model';
import { LogicalOperator, NodeBodyType, regex, SyntaxNode } from '../models/syntax-node.model';

function checkBoundaries(node: SyntaxNode, errors: SyntaxError[]) {
  const beginsWithValidElement = node.childNodes[0].type !== 'LogicalOperator';
  const endsWithValidElement =
    node.childNodes[node.childNodes.length - 1].type !== 'LogicalOperator';

  if (!beginsWithValidElement || !endsWithValidElement) {
    errors.push(new SyntaxError(node.type + ' starts or ends with an invalid Element'));
  }
}

function checkLogicalOperators(node: SyntaxNode, errors: SyntaxError[]) {
  node.childNodes.forEach((child) => {
    if (child.body instanceof LogicalOperator && !regex.isANDorOR.exec(child.body.type)) {
      errors.push(new SyntaxError('Invalid or unidentified Logic Operator in: ' + child.body.type));
    }
  });
}

function checkChipIndex(node: SyntaxNode, conditionChips: ConditionChip[], errors: SyntaxError[]) {
  node.childNodes.forEach((child) => {
    if (child.type === 'ConditionChip') {
      if (!(child.body instanceof ConditionChip)) {
        const chipIndex = findChipByStoredIndex(child.body, conditionChips);
        if (chipIndex === -1) {
          errors.push(new SyntaxError('Unidentified Reference for Chip Index ' + child.body));
        } else {
          child.body = conditionChips[chipIndex];
        }
      }
    } else if (child.type === 'Group') {
      checkChipIndex(child, conditionChips, errors);
    }
  });
}

function checkGrouping(node: SyntaxNode, errors: SyntaxError[]) {
  node.childNodes.forEach((child) => {
    if (child.childNodes.length) {
      checkBoundaries(child, errors);
      checkGrouping(child, errors);
    }
    if (child.type === 'Group' && child.childNodes.length < 3) {
      errors.push(
        new SyntaxError('Group needs to contain a statement consisting of at least 3 elements')
      );
    }
    if (child.type === 'GroupMarkerOpen' || child.type === 'GroupMarkerClose') {
      errors.push(new SyntaxError('Group is missing a closing/opening Bracket'));
    }
  });
}

function checkInnerSyntax(node: SyntaxNode, errors: SyntaxError[]) {
  const previousTypes = [];
  node.childNodes.forEach((child, idx) => {
    if (node.childNodes.length) {
      checkInnerSyntax(child, errors);
    }
    previousTypes[idx] = child.type;

    if (idx === 0) {
      return;
    }

    if (previousTypes[idx - 1] === child.type) {
      errors.push(
        new SyntaxError('The consecutive usage of two ' + child.type + 's is not allowed')
      );
    } else if (previousTypes[idx - 1] === 'Group' && child.type !== 'LogicalOperator') {
      errors.push(new SyntaxError('Invalid element after ' + previousTypes[idx - 1]));
    }
  });
}

function findChipByStoredIndex(chipID: NodeBodyType, conditionChips: ConditionChip[]) {
  return conditionChips.findIndex((chip) => chip.index === +chipID - 1);
}

export function checkSyntax(node: SyntaxNode, chips: ConditionChip[], errors: SyntaxError[]) {
  if (node.childNodes.length) {
    checkGrouping(node, errors);
    checkChipIndex(node, chips, errors);
    checkLogicalOperators(node, errors);
    checkBoundaries(node, errors);
    checkInnerSyntax(node, errors);
  }
}
