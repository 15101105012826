import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { filter } from 'rxjs/operators';
import { UserAuthService } from './user-auth.service';

const messagePrefix = '[IoTInsights]';

interface EmbeddingNavigation {
  path: string;
}

@Injectable({
  providedIn: 'root'
})
export class EmbeddingService implements OnDestroy {
  private actionHandlers = {
    navigate: this.handleNavigateAction.bind(this)
  };

  messageListener = (event) => {
    this.handleRawMessage(event.data);
  };

  constructor(
    private router: Router,
    private location: Location,
    private zone: NgZone,
    private route: ActivatedRoute,
    private http: HttpClient,
    private authService: UserAuthService
  ) {
    window.addEventListener('message', this.messageListener, false);
    this.sendTopPostMessage({ action: 'ready' });
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
      const currentContextParams = this.getProjectAndDashboard();
      if (currentContextParams.internalUrl.startsWith('/user/login')) {
        return;
      }
      const action = {
        action: 'paramsChange',
        params: this.route.snapshot.queryParams,
        replaceUrl: this.router.getCurrentNavigation()?.extras?.replaceUrl ?? false,
        ...currentContextParams
      };
      this.sendTopPostMessage(action);
    });

    this.authService.getUiConfigStream().subscribe((config) => {
      if (config) {
        this.sendTopPostMessage({
          action: 'userInfo',
          userName: config.userName,
          userEmail: config.userEmail,
          userDisplayName: config.userDisplayName,
          userId: config.userId,
          systemRoles: config.systemRoles,
          projectRoles: config.projectRoles,
          loggedIn: config.loggedIn
        });
      }
    });
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.messageListener, false);
  }

  private getProjectAndDashboard() {
    const currentUrl = this.router.url;
    const regexMatch = currentUrl.match(/^\/project\/([\w-]+?)\/views\/dashboards\/(\w+)/);
    if (regexMatch !== null) {
      return { project: regexMatch[1], dashboard: regexMatch[2], internalUrl: currentUrl };
    }
    return { internalUrl: currentUrl };
  }

  private sendTopPostMessage(action: any) {
    const message = messagePrefix + JSON.stringify(action);
    window.top.postMessage(message, '*');
  }

  private handleRawMessage(data) {
    if (typeof data === 'string' && data.startsWith(messagePrefix)) {
      this.zone.run(() => {
        try {
          const actionData = JSON.parse(data.substring(messagePrefix.length));
          if (this.actionHandlers[actionData.action]) {
            this.actionHandlers[actionData.action](actionData);
            if (actionData.requestId) {
              this.sendTopPostMessage({ action: 'response_' + actionData.requestId });
            }
          } else {
            console.error(
              `EmbeddingService: No handler for action ${actionData.action} found`,
              data
            );
          }
        } catch (e) {
          console.error('EmbeddingService failed to handle message', data, e);
        }
      });
    }
  }

  private handleNavigateAction(data: Record<string, any>) {
    const url = new URL(data.url);

    const regexMatch = url.pathname.match(/^\/embed\/(.+?)\/(\w+?)\.html$/);
    if (regexMatch !== null) {
      const embeddingNavigationUrl = `/embed/${regexMatch[1]}/${regexMatch[2]}`;
      const initialPath = url.searchParams.get('initialPath');
      if (initialPath) {
        const newSearch = new URLSearchParams(url.searchParams);
        newSearch.delete('initialPath');
        this.router.navigateByUrl(this.removeBaseHref(initialPath) + '?' + newSearch.toString());
      } else {
        this.http.get<EmbeddingNavigation>(embeddingNavigationUrl).subscribe((emNav) => {
          const navPath = this.removeBaseHref(emNav.path) + url.search;
          this.router.navigateByUrl(navPath);
        });
      }
    } else {
      const navigatePath = this.removeBaseHref(url.pathname) + url.search;
      this.router.navigateByUrl(navigatePath);
    }
  }

  private removeBaseHref(path) {
    const baseHref = this.location.prepareExternalUrl('');
    if (path.startsWith(baseHref)) {
      return path.substring(baseHref.length - 1);
    }
    return path;
  }
}
