import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishReplay, refCount, tap } from 'rxjs/operators';
import { AppThemingService } from '../../../core/services/app-theming.service';
import { ProjectsService } from '../../../shared-projects/services/projects.service';
import { getPagableParams, HttpStatusCode } from '../../../shared/http-utils';
import { Role } from '../../role/models/role.model';
import { InviteUserRequestModel } from '../models/invite-user-request.model';
import { UserPending } from '../models/user-pending.model';
import { User, UserRoleUpdate } from '../models/user.model';

@Injectable()
export class UserApiService {
  private cachedUsers: Observable<User[]>;
  private pendingUserCount = 0;
  private usersCount = 0;

  get baseUrl(): string {
    return `/project-management-service/v1/${this.projectsService.projectName}`;
  }

  get usersUrl(): string {
    return `${this.baseUrl}/users`;
  }

  get rolesUrl(): string {
    return `${this.baseUrl}/roles`;
  }

  get invitesUrl(): string {
    return `${this.baseUrl}/invites`;
  }

  constructor(
    private http: HttpClient,
    private appTheming: AppThemingService,
    private projectsService: ProjectsService
  ) {}

  /* User API */

  getUserById(userId: string): Observable<User> {
    return this.http.get<User>(`${this.usersUrl}/${userId}`).pipe(
      map((user: User) => {
        return new User(user);
      })
    );
  }

  getAllUsers() {
    if (!this.cachedUsers) {
      this.cachedUsers = this.getUserList(0, 1000).pipe(
        map((res) => res.body),
        publishReplay(1),
        refCount()
      );
    }
    return this.cachedUsers;
  }

  getUserList(
    page = 0,
    pageSize = 0,
    searchParams?: Record<string, any>,
    retrieveAllUserDetails = false
  ): Observable<HttpResponse<User[]>> {
    let params = getPagableParams({ page, pageSize });
    // send it only when it's true, default in BE is false
    if (retrieveAllUserDetails) {
      params = params.append('retrieveAllUserDetails', retrieveAllUserDetails);
    }
    if (searchParams) {
      params = params.append('searchParams', JSON.stringify(searchParams));
    }

    return this.http
      .get<User[]>(`${this.usersUrl}`, {
        params,
        observe: 'response'
      })
      .pipe(
        map((response: HttpResponse<User[]>) => {
          const users = response.body.map((user) => new User(user));
          this.usersCount = parseInt(response.headers.get('x-total-count'));
          return new HttpResponse<User[]>({ ...response, body: users });
        })
      );
  }

  deleteUser(userId: string): Observable<null> {
    return this.http
      .delete<null>(`${this.usersUrl}/${userId}`)
      .pipe(tap(() => (this.usersCount -= 1)));
  }

  createApiUser(username?: string, displayName?: string): Observable<User> {
    return this.http.post<User>(`${this.usersUrl}/technical-user`, { username, displayName }).pipe(
      map((apiUser: User) => {
        return new User(apiUser);
      })
    );
  }

  updateApiUser(
    displayName: string,
    username: string,
    roles: string[],
    userId: string
  ): Observable<User> {
    return this.http
      .put<User>(`${this.usersUrl}/${userId}/update-details`, {
        displayName,
        username,
        projectRoles: roles
      })
      .pipe(
        map((apiUser: User) => {
          return new User(apiUser);
        })
      );
  }

  /* Role-Mappings API */

  getUserRoleMappings(userId: string): Observable<Role[]> {
    return this.http.get<Role[]>(`${this.usersUrl}/${userId}/role-mappings`).pipe(
      map((roles: Role[]) => {
        return roles.map((role) => new Role(role));
      })
    );
  }

  updateUserRoleMappings(userId: string, roles: string[]): Observable<UserRoleUpdate> {
    return this.http.put<UserRoleUpdate>(`${this.usersUrl}/${userId}/role-mappings`, {
      projectRoles: roles
    });
  }

  /* Invite API */

  getPendingUserById(inviteId: string): Observable<UserPending> {
    return this.http.get<UserPending>(`${this.invitesUrl}/${inviteId}`).pipe(
      map((user: UserPending) => {
        return new UserPending(user);
      })
    );
  }

  getPendingUsersList(): Observable<UserPending[]> {
    return this.http.get<UserPending[]>(`${this.invitesUrl}`).pipe(
      map((users: UserPending[]) => {
        this.pendingUserCount = users.length;
        return users.map((user) => new UserPending(user));
      })
    );
  }

  isUserLimitReached(): boolean {
    const sum = this.pendingUserCount + this.usersCount;
    return sum >= 10 && this.projectsService.isFreePlan;
  }

  createUserInvite(email: string, projectRoles: string[]): Observable<UserPending | true> {
    const payload: InviteUserRequestModel = {
      email: email,
      projectRoles: projectRoles
    };
    return this.http
      .post<UserPending>(this.invitesUrl, payload, {
        observe: 'response'
      })
      .pipe(
        map((response: HttpResponse<UserPending>) => {
          if (response.status === HttpStatusCode.noContent) {
            return true;
          }
          return new UserPending(response.body);
        })
      );
  }

  updatePendingUserRoles(inviteId: string, projectRoles: string[]): Observable<UserPending> {
    return this.http.patch<UserPending>(`${this.invitesUrl}/${inviteId}`, { projectRoles }).pipe(
      map((user: UserPending) => {
        return new UserPending(user);
      })
    );
  }

  deleteInvite(inviteId: string): Observable<null> {
    return this.http
      .delete<null>(`${this.invitesUrl}/${inviteId}`)
      .pipe(tap(() => (this.pendingUserCount -= 1)));
  }

  /**
   * Regenerates Password for technical Users
   */
  regeneratePassword(userId: string): Observable<User> {
    return this.http.post<User>(`${this.usersUrl}/${userId}/regenerate-password`, {}).pipe(
      map((user: User) => {
        return new User(user);
      })
    );
  }
}
