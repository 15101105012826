import { ValidationErrors } from '@angular/forms';
import { concat, defer, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, map, skipWhile, switchMap, tap } from 'rxjs/operators';
import { QueryService } from '../../../../../shared-query/services/query.service';
import { RequestStatusComposite } from '../../../../../shared/api-model';
import { translate } from '../../../../../shared/translation-util';
import { DataLoaderRegistryService } from '../../services/data-loader-registry.service';
import { resolveParameter } from '../data-source-utils';
import { assignWithDefaults } from '../model-utils';
import { WidgetContext } from '../widget-context';
import { DataSource, dataSourceTypes } from './data-source-base';
import { DataSourceType, DsParameter, DsParameters } from './models';
import { QueryConfig } from '../../../../../shared-query/models/query-config';
import { isEmpty } from 'lodash-es';

export class QueryTemplateDataSource extends DataSource {
  type: DataSourceType = 'queryTemplate';
  label = 'Query Template';
  templateId = '';
  parameters: DsParameters = {};
  targetCollection: DsParameter = null;
  tag: '';
  cacheTime = 0;
  private queryStatus$ = new Subject<RequestStatusComposite>();

  constructor(props?: Partial<QueryTemplateDataSource>) {
    super(props);
    Object.defineProperty(this, 'queryStatus$', { enumerable: false });
    if (isEmpty(props)) {
      this.cacheTime = 1800;
    }
    assignWithDefaults(this, props);
  }

  get queryStatus(): Observable<RequestStatusComposite> {
    return this.queryStatus$;
  }

  validate(): ValidationErrors | null {
    return this.templateId ? null : { missingTemplateId: true };
  }

  loadData(ctx: WidgetContext): Observable<any[]> {
    const params: Record<string, any> = {};

    // resolve dashboard params for query execution
    Object.keys(this.parameters).forEach((key) => {
      const resolved = resolveParameter(this.parameters[key], ctx);
      if (Array.isArray(resolved) && resolved.length === 0) {
        params[key] = undefined;
      } else {
        params[key] = resolved;
      }
    });

    const targetCollection = this.targetCollection
      ? resolveParameter(this.targetCollection, ctx)
      : null;
    const tag =
      this.tag || ctx.dashboardName ? ctx.dashboardName + ' ' + ctx.widgetId : 'data-source';
    if (!ctx.injector) {
      throw new Error('No injector supplied in WidgetContext for DataSource');
    }
    const dataLoaderRegistryService = ctx.injector.get(DataLoaderRegistryService, null);
    const id = this.generateDataSourceId(params);
    const source = this.runQueryAndRefreshCachedResult(ctx, params, targetCollection, tag);

    if (dataLoaderRegistryService) {
      return source.pipe(dataLoaderRegistryService.registerDataSource(id, ctx.priority));
    } else {
      return source;
    }
  }

  private runQueryAndRefreshCachedResult(
    ctx: WidgetContext,
    templateParameters: Record<string, any>,
    targetCollection: string,
    tag: string
  ): Observable<any[]> {
    const queryConfig = new QueryConfig({
      templateId: this.templateId,
      targetCollection: targetCollection,
      templateParameters,
      tag
    });
    let cacheTime = this.cacheTime;
    const queryService: QueryService = ctx.injector.get(QueryService);
    this.lastUpdate = null;
    return defer(() => {
      const config = new QueryConfig({ ...queryConfig, cacheTime });
      cacheTime = 0; // Only the first subscription should respect the cache
      return queryService.runQueryConfig(config);
    }).pipe(
      tap((result) => this.queryStatus$.next(result.query)),
      skipWhile((status) => status.result === undefined),
      switchMap((status) => {
        if (!status.isCachedResult) {
          this.lastUpdate = new Date();
          return of(status);
        }
        this.lastUpdate = new Date(status.lastCached);
        return concat(
          of(status),
          queryService.runQueryConfig(new QueryConfig({ ...queryConfig, cacheTime: 0 })).pipe(
            skipWhile((status) => status.result === undefined),
            tap((status) => {
              this.lastUpdate = status.isCachedResult ? new Date(status.lastCached) : new Date();
            }),
            catchError(() => EMPTY) // Errors of the refresh request should be ignored
          )
        );
      }),
      map((status) => {
        return status.result;
      })
    );
  }

  private generateDataSourceId(params) {
    return this.templateId + ';' + JSON.stringify(params);
  }
}

dataSourceTypes.push({
  name: 'queryTemplate',
  i18nLabel: translate('dataSourceConfig.queryTemplate'),
  constructor: QueryTemplateDataSource
});
