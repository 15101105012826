import { AsyncScheduler } from 'rxjs/internal/scheduler/AsyncScheduler';
import { SchedulerAction } from 'rxjs';
import { AsyncAction } from 'rxjs/internal/scheduler/AsyncAction';
import { Action } from 'rxjs/internal/scheduler/Action';

declare const isTest;
class WorkerScheduler extends AsyncScheduler {
  private worker: Worker = null;
  private index = 0;
  private workerTasks = new Map<number, () => void>();
  private ready: Promise<void> = null;

  constructor(action: typeof Action, now?: () => number) {
    super(action, now);
    this.ready = this.init();
  }

  async init() {
    if (typeof Worker === 'undefined') {
      // No Worker available, when this.worker is not set, the WorkerAction will use AsyncAction
      return;
    }
    if (typeof isTest === 'undefined') {
      // Workaround with import() so Jest tests work and do not throw errors for import.meta.url
      this.worker = (await import('./worker-loader')).workerScheduler();
    } else {
      this.worker = new Worker('./worker-scheduler.worker');
    }

    this.worker.onmessage = ({ data }) => {
      const task = this.workerTasks.get(data.id);
      if (task) {
        this.workerTasks.delete(data.id);
        task();
      }
    };
    this.worker.onerror = (err) => {
      console.error(`No Workers can be used for scheduling.`, err);
      this.worker = null;
    };
  }

  hasWorker(): boolean {
    return !!this.worker;
  }

  addTask(timeout: number, work: () => void): number {
    this.index = (this.index + 1) % Number.MAX_SAFE_INTEGER;
    this.workerTasks.set(this.index, work);
    this.worker.postMessage({ action: 'schedule', id: this.index, timeout });
    return this.index;
  }

  cancelTask(id: number): void {
    this.workerTasks.delete(id);
    this.worker.postMessage({ action: 'cancel', id });
  }

  terminate() {
    if (this.worker) {
      this.worker.terminate();
    }
  }
}

export class WorkerAction<T> extends AsyncAction<T> {
  constructor(
    protected scheduler: WorkerScheduler,
    protected work: (this: SchedulerAction<T>, state?: T) => void
  ) {
    super(scheduler, work);
  }

  protected requestAsyncId(scheduler: WorkerScheduler, id?: any, delay = 0): any {
    if (!scheduler.hasWorker()) {
      return super.requestAsyncId(scheduler, id, delay);
    }
    return scheduler.addTask(delay, () => scheduler.flush(this));
  }

  protected recycleAsyncId(scheduler: WorkerScheduler, id?: any, delay = 0): any {
    if (!scheduler.hasWorker()) {
      return super.recycleAsyncId(scheduler, id, delay);
    }
    if (id) {
      scheduler.cancelTask(id);
    }
    return undefined;
  }
}

export const workerScheduler = new WorkerScheduler(WorkerAction);
