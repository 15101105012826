import { PickListItem } from '../model/picklist.model';

export function toItemsWithCheckedProperty(
  availableItems: PickListItem[],
  selectedItems: PickListItem[]
): PickListItem[] {
  const selected = selectedItems.reduce((obj, item) => {
    const id = item.id ?? item.thingId;

    return {
      ...obj,
      [id]: true
    };
  }, {});

  return availableItems.map((item) => {
    const id = item.id ?? item.thingId;
    const isChecked = !!selected[id];

    return { ...item, checked: isChecked };
  });
}

export function uncheckItem(item: PickListItem, availableItems: PickListItem[]) {
  const itemToUncheck = availableItems.find((availableItem) => {
    const id = item.id ?? item.thingId;
    return id === availableItem.thingId || id === availableItem.id;
  });

  if (itemToUncheck) {
    itemToUncheck.checked = false;
  }
}

export function uncheckAllItems(availableItems: PickListItem[], exceptionId?: string) {
  for (const item of availableItems) {
    const id = item.thingId ?? item.id;

    if (item.checked && exceptionId !== id) {
      item.checked = false;
    }
  }
}

export function searchItems(items: PickListItem[], searchTerm: string): PickListItem[] {
  return items.filter((item) => item.label.includes(searchTerm));
}

export function findItemIndex(items: PickListItem[], targetedItem: PickListItem): number {
  return items.findIndex((item) => {
    const id = item.id ?? item.thingId;
    return id === targetedItem.thingId || id === targetedItem.id;
  });
}
