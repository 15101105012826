import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { translate } from '../../../../shared/translation-util';
import { PathInfo } from '../../../data-selection/data-info-util';
import {
  ConditionChip,
  SupportedPathType
} from '../../../query-condition-input/models/condition-chip.model';
import { Operator } from '../../../query-condition-input/models/operator.model';
import { InputObject } from '../../../query-condition-input/models/util-objects.model';
import { SimpleSearchInputService } from './simple-search-input.service';

const supportedDefaultPathDataTypes = [
  'boolean',
  'date',
  'null',
  'number',
  'string',
  'objectId',
  'json'
];
const defaultPathDataType = 'string';
export const filterParamsOrDataPlaceholderRegex =
  /^\[\d+\]\.|^\[i\]\.|^filterParams\.|^inputFields\.|^\d+\./;

@Injectable()
export class SimpleSearchInputPathService extends SimpleSearchInputService<PathInfo> {
  constructor() {
    super();
    this.searchType = 'path';
    this.dataTypeInput = defaultPathDataType;
    this.operatorInput = Operator.byTechnicalId('eq');
    this.defaultDataType = defaultPathDataType;
    this.searchInputLabel = translate('simpleSearch.path');
  }

  getInputsAsObject(): InputObject {
    {
      return {
        type: this.dataTypeInput,
        value: this.validateValue(),
        fieldName: this.fieldName,
        searchType: this.searchType,
        input: this.inputLabel.displayLabel,
        i18nLabel: this.inputLabel.i18nLabel,
        operatorID: this.operatorInput.technicalID
      };
    }
  }

  setInputs(values: PathInfo[]) {
    this.inputs = values.filter((path) => path.path !== '');
    this.displayLabels = this.inputs.map((path: PathInfo) => path.path);
  }

  setChips(chipsList: ConditionChip[]) {
    this._chips = chipsList;
  }

  setShowDuplicateButton() {
    this.showDuplicateButton = true;
  }

  search(text: string): Observable<string[]> {
    // Regex for matching case insensitive entries
    const rgx = new RegExp(`^.*${text}.*`, 'i');

    return of(
      this.inputs
        .map((pathInfo) => pathInfo.path)
        .filter((path) => (path ? path.match(rgx) : false))
    ).pipe(
      delay(50),
      map((entries: string[]) => {
        this.displayLabels = entries;
        return entries;
      })
    );
  }

  detectDataType(path: string) {
    if (this.activeChip) {
      // Deactivate Detection while editing a Chip
      return;
    }

    const foundPath: PathInfo = this.inputs.find((val: PathInfo) => val.path === path);
    this.input = path;

    if (path === '_id') {
      this.dataTypeInput = supportedDefaultPathDataTypes.find(
        (type) => type === 'objectId'
      ) as SupportedPathType;
    } else if (foundPath) {
      this.inputLabel.displayLabel = foundPath.path;
      this.dataTypeInput = supportedDefaultPathDataTypes.includes(foundPath.types[0])
        ? (foundPath.types[0] as SupportedPathType)
        : defaultPathDataType;
    } else {
      this.dataTypeInput = defaultPathDataType;
    }
  }

  setSupportedDataTypes(dataTypes = supportedDefaultPathDataTypes) {
    this.supportedDataTypes = dataTypes;
  }
}
