import { camelCase } from 'lodash-es';
import { v4 as uuid } from 'uuid';
import { CustomFeatureProperty } from '../../../../devices/models/device-types';
import { getFeatureProperties } from '../../../../shared-modules/devices-common/device.utils';
import { FieldType } from '../../../../shared-modules/static-filter/static-filter.model';
import {
  FilterParameterConfig,
  filterParameterToFieldDefinition,
  parameterTypes
} from '../filter-widget.model';
import { ParameterInfo } from './filter-widget-edit.model';

export const techNameRegExSupported = /^[a-zA-Z0-9_-]+$/;
export const techNameRegExUnsupported = /[^a-zA-Z0-9_-]+/g;

export const GLOBAL_PARAMETERS_PREFIX = 'g_';

export const globalParamPrefixRegex = new RegExp(GLOBAL_PARAMETERS_PREFIX);

export function toggleGlobalParametersPrefix(
  currentTechnicalName: string,
  action: 'add' | 'remove' = 'add'
) {
  if (action === 'add') {
    return GLOBAL_PARAMETERS_PREFIX + currentTechnicalName;
  }

  return currentTechnicalName.replace(globalParamPrefixRegex, '');
}

export function getAvailableTechnicalName(
  originalName: string,
  parameters: string[],
  addGlobalPrefix = false
): string {
  const nameTrimmed = addGlobalPrefix
    ? GLOBAL_PARAMETERS_PREFIX + camelCase(originalName.trim())
    : camelCase(originalName.trim());
  if (!parameters.find((name) => name === nameTrimmed)) {
    return replaceSpecialCharacters(nameTrimmed);
  }
  let index = 1;
  while (parameters.find((name) => name === `${nameTrimmed}_${index}`)) {
    index++;
  }
  return replaceSpecialCharacters(`${nameTrimmed}_${index}`);
}

export function replaceSpecialCharacters(text: string): string {
  const replacedSpaces = text.replace(/\s/g, '_');
  return replacedSpaces.replace(techNameRegExUnsupported, '');
}

export function isValidParameter(param: FilterParameterConfig): boolean {
  if (param.type === 'queryCondition') {
    return !!param.name.length;
  } else {
    return !!(param.name.length && param.label.en.length);
  }
}

export function createParameter(type: FieldType, parameterDetailsLabel: string): ParameterInfo {
  const paramType = parameterTypes.find((pt) => pt.type === type);
  const p = {
    label: { en: '' },
    name: '',
    required: true,
    type: type,
    visible: true,
    multipleValues: false,
    global: false,
    defaultValue: '',
    ...paramType.defaultProperties
  };
  const paramInfo: ParameterInfo = {
    config: p,
    type: paramType,
    valid: isValidParameter(p),
    initialValid: isValidParameter(p),
    field: filterParameterToFieldDefinition(p),
    defaultValue: null,
    open: true,
    generateTechnicalName: true,
    generatedId: uuid(),
    parameterDetailsLabel
  };
  if (paramInfo.field.modelToView) {
    paramInfo.defaultValue = paramInfo.field.modelToView(p.defaultValue);
  } else {
    paramInfo.defaultValue = p.defaultValue;
  }
  return paramInfo;
}

export function createParameterInfoFromFilterParameter(
  p: FilterParameterConfig,
  parameterLabel
): ParameterInfo {
  const paramType = parameterTypes.find((pt) => pt.type === p.type);

  const paramInfo = {
    config: p,
    type: paramType,
    valid: isValidParameter(p),
    initialValid: isValidParameter(p),
    field: filterParameterToFieldDefinition(p),
    defaultValue: null,
    open: false,
    generateTechnicalName: false,
    generatedId: uuid(),
    parameterDetailsLabel: parameterLabel
  };
  if (paramInfo.field.modelToView) {
    paramInfo.defaultValue = paramInfo.field.modelToView(p.defaultValue);
  } else {
    paramInfo.defaultValue = p.defaultValue;
  }
  return paramInfo;
}

export function findParameterTypeForDeviceType(customProperty: CustomFeatureProperty): FieldType {
  switch (customProperty.type) {
    case 'text':
    case 'textMultiline': {
      return 'text';
    }
    case 'selection': {
      return 'selection';
    }
    case 'boolean': {
      return 'checkbox';
    }
    case 'device': {
      return 'device';
    }
    case 'number': {
      return 'number';
    }
    case 'datetime': {
      return 'datetimerange2';
    }
    case 'date': {
      return 'date';
    }
    default: {
      throw new Error('unsupported type ' + customProperty.type);
    }
  }
}

export function getPropertyPath(featureDefaultName: string, propertyName: string): string {
  if (propertyName === 'thingId') {
    return 'thingId';
  } else {
    return `features/${featureDefaultName}/properties/${propertyName}`;
  }
}

export function filterOutInvalidDeviceProperties(deviceTypeDefinition, deviceParameters) {
  const deviceFeatureProperties = getFeatureProperties(deviceTypeDefinition, [])
    .map((item) => {
      return item.properties.map((property) =>
        getPropertyPath(property.featureDefaultName, property.propertyName)
      );
    })
    .flat(1);
  return deviceParameters.filter(
    (param) => deviceFeatureProperties.indexOf(param.propertyPath) >= 0
  );
}
