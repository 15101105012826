<div class="main-bg">
  <div class="container text-center">
    <h1 class="mt-3 componentHeader u-TextColor--darkBlue">
      {{ 'userInfo.sessionEnd' | translate }}
    </h1>

    <p class="subline-scaled">
      {{ 'userInfo.sessionEndDesc' | translate }}
    </p>

    <p>
      <a [routerLink]="routing.home" class="rb-btn">{{ 'userInfo.backHome' | translate }}</a>
    </p>
  </div>
</div>
