import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { AppThemingService } from '../../core/services/app-theming.service';
import { AuthProviderPrefix } from '../../core/services/models/auth-provider';
import { translate } from '../translation-util';

@Component({
  selector: 'login-provider-select',
  templateUrl: './login-provider-select.component.html',
  styleUrls: ['./login-provider-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginProviderSelectComponent {
  @Input() inHeader = false;
  @Input() buttonText = translate('nav.login');

  @Output() loginClicked = new EventEmitter<AuthProviderPrefix>();

  constructor(public appTheming: AppThemingService) {}

  login(authProviderPrefix: AuthProviderPrefix) {
    this.loginClicked.emit(authProviderPrefix);
  }
}
