<button
  class="rb-btn rb-link filter-button m-0"
  type="button"
  [rbPopover]="deviceDetails"
  [position]="'bottom'"
>
  <span *ngIf="!isFilterApplied" class="rb-ic rb-ic-filter"></span>
  <span *ngIf="isFilterApplied" class="rb-ic rb-ic-filter-success"></span>
</button>

<ng-template #deviceDetails>
  <div class="p-4">
    <h5 class="mb-2">
      {{ 'calendar.showDeviceDetails' | translate }}
    </h5>

    <ng-container *ngIf="deviceGroup?.length">
      <div *ngFor="let deviceGroup of deviceGroup">
        <ng-container *ngIf="deviceGroup?.deviceProperties?.length">
          <a class="border-0 font-weight-bold mb-2" href="#">
            {{ deviceGroup?.label }}
          </a>
          <ng-container *ngFor="let property of deviceGroup?.deviceProperties">
            <rb-form-multi-checkbox
              class="list-item-input"
              [ngModel]="property.state"
              (ngModelChange)="
                notifyDevicePropertiesChange(deviceGroup.type, property.item, $event)
              "
            >
              {{ property.item }}
            </rb-form-multi-checkbox>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>
