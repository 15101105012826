import { DeviceUser } from './device-user';
import { TranslateService } from '@ngx-translate/core';
import { translate } from '../../shared/translation-util';

export enum SimpleActionType {
  THING_CREATED,
  THING_MODIFIED,
  THING_DELETED,
  FEATURE_CREATED,
  FEATURE_MODIFIED,
  FEATURE_DELETED,
  PROPERTY_MODIFIED,
  PROPERTY_DELETED,
  POLICY_MODIFIED,
  DEFINITION_MODIFIED,
  INCONSISTENT,
  UNTRACKED,
  LINKED,
  UNLINKED,
  HISTORY_ACTIVATED,
  UNSPECIFIC_ENTRY
}

export const ActionTypeMapping = {
  THING_CREATED: SimpleActionType.THING_CREATED,

  THING_CREATE_OR_UPDATE_SPECIFIC: SimpleActionType.THING_MODIFIED,

  THING_DELETED: SimpleActionType.THING_DELETED,

  ATTRIBUTE_CREATE_OR_UPDATE_ALL_AT_ONCE: SimpleActionType.PROPERTY_MODIFIED,

  ATTRIBUTE_DELETE_ALL: SimpleActionType.PROPERTY_DELETED,

  ATTRIBUTE_CREATE_OR_UPDATE_SPECIFIC: SimpleActionType.PROPERTY_MODIFIED,

  ATTRIBUTE_DELETE_SPECIFIC: SimpleActionType.PROPERTY_DELETED,

  FEATURE_CREATE_OR_MODIFY_ALL_AT_ONCE: SimpleActionType.FEATURE_MODIFIED,

  FEATURES_DELETE_ALL: SimpleActionType.FEATURE_DELETED,

  FEATURE_CREATE_OR_UPDATE_SPECIFIC: SimpleActionType.FEATURE_MODIFIED,

  FEATURE_DELETE_SPECIFIC: SimpleActionType.FEATURE_DELETED,

  FEATURE_DEFINITION_CREATE_OR_UPDATE: SimpleActionType.FEATURE_MODIFIED,

  FEATURE_DEFINITION_DELETE: SimpleActionType.FEATURE_MODIFIED,

  FEATURE_CREATE_OR_UPDATE_PROPS_ALL: SimpleActionType.PROPERTY_MODIFIED,

  FEATURE_DELETE_PROPS_ALL: SimpleActionType.PROPERTY_DELETED,

  FEATURE_CREATE_OR_UPDATE_SPECIFIC_PROPERTY: SimpleActionType.PROPERTY_MODIFIED,

  FEATURE_DELETE_SPECIFIC_PROPERTY: SimpleActionType.PROPERTY_DELETED,

  POLICY_MODIFIED: SimpleActionType.POLICY_MODIFIED,

  DEFINITION_MODIFIED: SimpleActionType.DEFINITION_MODIFIED,

  INCONSISTENT: SimpleActionType.INCONSISTENT,

  UNTRACKED: SimpleActionType.UNTRACKED,

  /** UI only */
  MODIFIED_LINKED: SimpleActionType.LINKED,
  /** UI only */
  MODIFIED_UNLINKED: SimpleActionType.UNLINKED,
  /** UI only */
  HISTORY_ACTIVATED: SimpleActionType.HISTORY_ACTIVATED
};

export enum ActionType {
  thing_created = 'THING_CREATED',

  thing_deleted = 'THING_DELETED',

  /** triggered by request: PUT /things/{thingId} */
  thing_create_or_update_specific = 'THING_CREATE_OR_UPDATE_SPECIFIC',

  /** triggered by requests: PUT or DELETE /things/{thingId}/attributes */
  attribute_create_or_modify_all_at_once = 'ATTRIBUTE_CREATE_OR_UPDATE_ALL_AT_ONCE',

  /** triggered by requests: DELETE /things/{thingId}/attributes */
  attribute_delete_all = 'ATTRIBUTE_DELETE_ALL',

  /** triggered by request: PUT /things/{thingId}/attributes/{attributePath} */
  attribute_create_or_modify_specific = 'ATTRIBUTE_CREATE_OR_UPDATE_SPECIFIC',

  /** triggered by request: DELETE /things/{thingId}/attributes/{attributePath} */
  attribute_delete_specific = 'ATTRIBUTE_DELETE_SPECIFIC',

  /** triggered by request: PUT /things/{thingId}/features */
  feature_create_or_modify_all_at_once = 'FEATURE_CREATE_OR_MODIFY_ALL_AT_ONCE',

  /** triggered by requests: DELETE /things/{thingId}/features */
  features_delete_all = 'FEATURE_DELETE_ALL',

  /** triggered by request: PUT /things/{thingId}/features/{featureId} */
  feature_create_or_update_specific = 'FEATURE_CREATE_OR_UPDATE_SPECIFIC',

  /** triggered by request: DELETE /things/{thingId}/features/{featureId} */
  feature_delete_specific = 'FEATURE_DELETE_SPECIFIC',

  /** triggered by request: PUT /things/{thingId}/features/{featureId}/definition */
  feature_definition_create_or_update = 'FEATURE_DEFINITION_CREATE_OR_UPDATE',

  /** triggered by request: DELETE /things/{thingId}/features/{featureId}/definition */
  feature_definition_delete = 'FEATURE_DEFINITION_DELETE',

  /** triggered by request: PUT /things/{thingId}/features/{featureId}/properties */
  feature_create_or_update_props_all = 'FEATURE_CREATE_OR_UPDATE_PROPS_ALL',

  /** triggered by request: DELETE /things/{thingId}/features/{featureId}/properties */
  feature_delete_props_all = 'FEATURE_DELETE_PROPS_ALL',

  /** triggered by request: PUT /things/{thingId}/features/{featureId}/properties/{propertyPath} */
  feature_create_or_update_specific_property = 'FEATURE_CREATE_OR_UPDATE_SPECIFIC_PROPERTY',

  /**
   * triggered by request: DELETE /things/{thingId}/features/{featureId}/properties/{propertyPath}
   */
  feature_delete_specific_property = 'FEATURE_DELETE_SPECIFIC_PROPERTY',

  /**
   * triggered by request: PUT /things/{thingId}/policies/{policyId}
   */
  policy_modified = 'POLICY_MODIFIED',

  /**
   * triggered by request: PUT /things/{thingId}/definition
   */
  definition_modified = 'DEFINITION_MODIFIED',

  /**
   * Represents inconsistent changes, i. e. Things actions type ('created', 'modified' or 'deleted')
   * does not fit to change value (should not occur and if it does probably indicates some bug in any
   * of the involved components).
   */
  inconsistent = 'INCONSISTENT',

  untracked = 'UNTRACKED',

  /**
   * triggered by request: POST /v1/{project}/thing-history/initialize
   * UI only
   * */
  history_activated = 'HISTORY_ACTIVATED',

  /** UI only */
  linked = 'MODIFIED_LINKED',

  /** UI only */
  unlinked = 'MODIFIED_UNLINKED',

  unspecific = 'UNSPECIFIC'
}

export enum DeviceRelation {
  none = 'NONE',
  linking = 'LINKING',
  unlinking_by_feature_modified = 'UNLINKING_BY_FEATURE_MODIFIED',
  unlinking_by_feature_deleted = 'UNLINKING_BY_FEATURE_DELETED',
  unlinking_by_thing_deleted = 'UNLINKING_BY_THING_DELETED'
}

export class Event {
  static readonly unspecific = {
    type: ActionType.unspecific,
    deviceRelation: DeviceRelation.none
  } as Event;

  type: ActionType;
  deviceRelation: DeviceRelation;

  constructor(event = {} as Event) {
    const { type = ActionType.unspecific, deviceRelation = DeviceRelation.none } = event;

    this.type = type;
    this.deviceRelation = deviceRelation;
    return this;
  }

  isUnlinking(): boolean {
    return this.deviceRelation === DeviceRelation.unlinking_by_feature_modified;
  }
}

export class DeviceHistoryChange {
  static readonly unspecificChange = new DeviceHistoryChange({
    event: Event.unspecific,
    value: undefined
  });

  get value(): any | undefined {
    return this._value;
  }

  /**
   * value can contain the new properties or an object with the feature definition and a properties object
   * @param value
   * @example
   * {
   *     "definition": [
   *         "insights:images:1"
   *     ],
   *     "properties": {
   *         "images": [
   *             {
   *                 "url": "/project-management-service/v1/testmarius/attachments/devices/testmarius_dev:car_testCar/IWT-IWZT.png",
   *                 "label": "IWT-IWZT.png",
   *                 "addedDate": "2022-07-06T14:19:34.537Z"
   *             }
   *         ]
   *     }
   * }
   *
   * or
   *
   * {
   *     "car": {
   *         "color": "red"
   *     }
   * }
   */
  set value(value: any | undefined) {
    if (value && value['properties']) {
      this._value = value.properties;
    } else {
      this._value = value;
    }
  }

  actionType?: ActionType;
  thingId?: string;
  path?: string;
  timestamp?: string;
  revision?: string | number;
  trigger?: string;
  topic?: string;
  private _value?: any;
  user?: DeviceUser;
  event?: Event;

  constructor(change = {} as DeviceHistoryChange) {
    const { path = '', timestamp = '', topic = '', revision = 0, value, user, event } = change;

    const tmpEvent = new Event(event);

    this.actionType = DeviceHistoryChange.mapActionType(tmpEvent);
    this.thingId = DeviceHistoryChange.getThingId(topic);
    this.path = path;
    this.timestamp = timestamp;
    this.topic = topic;
    this.revision = revision;
    this.value = value;
    this.user = new DeviceUser(user);
    this.event = tmpEvent;
    return this;
  }

  static getThingId(topic: string): string {
    const topicParts = topic.split('/');
    const namespace = topicParts[0];
    const thingIdWithoutNamespace = topicParts[1];
    return namespace + ':' + thingIdWithoutNamespace;
  }

  static mapActionType(event: Event): ActionType {
    if (event.isUnlinking()) {
      return ActionType.unlinked;
    }
    if (event.deviceRelation === DeviceRelation.linking) {
      return ActionType.linked;
    }
    return event.type;
  }
}
export function filterDuplicatedKeysByValue(obj: Record<string, any>): Record<string, any> {
  const result: Record<string, any> = {};
  const seenValues = new Set<any>();

  for (const key in obj) {
    const value = obj[key];
    if (!seenValues.has(value) && isNaN(Number(key))) {
      result[key] = value;
      seenValues.add(value);
    }
  }
  return result;
}

export function mapDeviceHistoryActions(translateService: TranslateService, type: string): string {
  switch (SimpleActionType[type]) {
    case SimpleActionType.THING_CREATED: {
      return translateService.instant('devices.deviceCreated');
    }
    case SimpleActionType.THING_MODIFIED: {
      return translateService.instant('devices.deviceModified');
    }
    case SimpleActionType.THING_DELETED: {
      return translateService.instant('devices.deviceDeleted');
    }
    case SimpleActionType.FEATURE_CREATED: {
      return translateService.instant('devices.blockCreated');
    }
    case SimpleActionType.FEATURE_MODIFIED: {
      return translateService.instant('devices.featureCreateOrUpdateSpecific');
    }
    case SimpleActionType.FEATURE_DELETED: {
      return translateService.instant('devices.blockDeleted');
    }
    case SimpleActionType.PROPERTY_MODIFIED: {
      return translateService.instant('devices.propertyModified');
    }
    case SimpleActionType.HISTORY_ACTIVATED: {
      return translateService.instant('devices.historyActivated');
    }
    case SimpleActionType.UNTRACKED: {
      return translateService.instant('devices.historyUntrackedEntry');
    }
    case SimpleActionType.PROPERTY_DELETED: {
      return translateService.instant('devices.propertyDeleted');
    }
    case SimpleActionType.POLICY_MODIFIED: {
      return translateService.instant('devices.policyModified');
    }
    case SimpleActionType.DEFINITION_MODIFIED: {
      return translateService.instant('devices.definitionModified');
    }
    case SimpleActionType.INCONSISTENT: {
      return translateService.instant('devices.deviceInconsistent');
    }
    case SimpleActionType.LINKED: {
      return translateService.instant('devices.deviceLinked');
    }
    case SimpleActionType.UNSPECIFIC_ENTRY: {
      return translateService.instant('devices.unspecifiedDevice');
    }
    case SimpleActionType.UNLINKED: {
      return translateService.instant('devices.deviceUnlinked');
    }
    default:
      return type;
  }
}

export const actionTypeDetails = Object.freeze({
  [SimpleActionType.THING_CREATED]: {
    class: 'badge-success',
    title: translate('devices.deviceCreated'),
    tooltip: translate('devices.deviceCreatedDetails')
  },
  [SimpleActionType.THING_MODIFIED]: {
    class: 'badge-success',
    title: translate('devices.deviceModified'),
    tooltip: translate('devices.deviceModifiedDetails')
  },
  [SimpleActionType.THING_DELETED]: {
    class: 'badge-danger',
    title: translate('devices.deviceDeleted'),
    tooltip: translate('devices.deviceDeletedDetails')
  },
  [SimpleActionType.FEATURE_CREATED]: {
    class: 'badge-success',
    title: translate('devices.blockCreated'),
    tooltip: translate('devices.blockCreatedDetails')
  },
  [SimpleActionType.FEATURE_DELETED]: {
    class: 'badge-danger',
    title: translate('devices.blockDeleted'),
    tooltip: translate('devices.blockDeletedDetails')
  },
  [SimpleActionType.PROPERTY_MODIFIED]: {
    class: 'badge-info',
    title: translate('devices.propertyModified'),
    tooltip: translate('devices.propertyModifiedDetails')
  },
  [SimpleActionType.HISTORY_ACTIVATED]: {
    class: 'badge-info',
    title: translate('devices.historyActivated'),
    tooltip: translate('devices.historyActivatedDetails')
  },
  [SimpleActionType.UNTRACKED]: {
    class: 'badge-secondary',
    title: translate('devices.historyUntrackedEntry'),
    tooltip: translate('devices.historyUntrackedEntryDetails')
  },
  [SimpleActionType.FEATURE_MODIFIED]: {
    class: 'badge-success',
    title: translate('devices.featureCreateOrUpdateSpecific'),
    tooltip: translate('devices.featureCreateOrUpdateSpecificDetails')
  },
  [SimpleActionType.LINKED]: {
    class: 'badge-info',
    title: translate('devices.deviceLinked'),
    tooltip: translate('devices.deviceLinkedDetails')
  },
  [SimpleActionType.UNLINKED]: {
    class: 'badge-info',
    title: translate('devices.deviceUnlinked'),
    tooltip: translate('devices.deviceUnlinkedDetails')
  },
  [SimpleActionType.INCONSISTENT]: {
    class: 'badge-danger',
    title: translate('devices.deviceInconsistent'),
    tooltip: translate('devices.deviceInconsistentDetails')
  },
  [SimpleActionType.POLICY_MODIFIED]: {
    class: 'badge-success',
    title: translate('devices.policyModified'),
    tooltip: translate('devices.policyModifiedDetails')
  },
  [SimpleActionType.DEFINITION_MODIFIED]: {
    class: 'badge-success',
    title: translate('devices.definitionModified'),
    tooltip: translate('devices.definitionModifiedDetails')
  },
  [SimpleActionType.PROPERTY_DELETED]: {
    class: 'badge-danger',
    title: translate('devices.propertyDeleted'),
    tooltip: translate('devices.propertyDeletedDetails')
  }
});
