<ellipsis-text *ngIf="hint">{{ hint }}</ellipsis-text>
<div class="d-flex align-items-center">
  <rb-form-custom-select
    *ngIf="showSelectionMenu"
    [disabled]="disabled"
    name="selectedType"
    class="form-custom-select-small-width form-custom-select-small-padding-dropdown background-white"
    data-cy="valueFormattingTypeSelector"
    [selectionTpl]="selectMenu"
    [(ngModel)]="selectedType"
  >
    <span class="rb-ic {{ getIcon(selectedType) }} type-icon" title="Value"></span>
  </rb-form-custom-select>
  <ng-template #selectMenu let-select="select" let-close="close">
    <a
      href="#"
      class="rb-dropdown-item"
      [attr.data-cy]="'valueFormattingTypeSelector-' + type.value"
      *ngFor="let type of selectTypesList"
      [class.active]="selectedType === type.value"
      (click)="select(type.value); resetValue(); close()"
    >
      {{ type.label | translate }}
    </a>
  </ng-template>

  <div class="form-custom-dropdown">
    <data-selector-input
      *ngIf="selectedType === selectType.DATA_SOURCE"
      [name]="name"
      [paths]="paths"
      [allowedTypes]="allowedTypes"
      [requireIteration]="requireIteration"
      [(ngModel)]="value"
      [disabled]="disabled"
      class="data-selector-custom-width background-white"
      data-cy="valueFormattingInputDataSource"
      (ngModelChange)="updateValue($event)"
      [label]="label"
    ></data-selector-input>

    <div *ngIf="selectedType === selectType.CUSTOM_VALUE" class="d-flex">
      <rb-form-input
        *ngIf="dataType !== 'datetime' && dataType !== 'datetimerange' && dataType !== 'int'"
        [placeholder]="placeholder"
        [name]="name"
        [(ngModel)]="value"
        [disabled]="disabled"
        class="w-100 background-white"
        data-cy="valueFormattingInputString"
        (ngModelChange)="updateValue($event)"
        [label]="label"
      ></rb-form-input>

      <rb-form-date-input
        *ngIf="dataType === 'datetime'"
        [label]="label"
        [disabled]="disabled"
        [options]="{ enableTime: true }"
        [(ngModel)]="value"
        (ngModelChange)="updateValue($event)"
        [name]="name"
        class="w-100 background-white"
      ></rb-form-date-input>

      <rb-form-date-range-input
        *ngIf="dataType === 'datetimerange'"
        [label]="label"
        [name]="name"
        [disabled]="disabled"
        [startLabel]="startLabel"
        [endLabel]="endLabel"
        [options]="{ enableTime: true }"
        [presets]="presets"
        [allowRelative]="true"
        [(ngModel)]="value"
        (ngModelChange)="updateValue($event)"
        class="w-100 background-white"
      ></rb-form-date-range-input>

      <rb-form-input
        *ngIf="dataType === 'int'"
        [name]="name"
        [(ngModel)]="value"
        [disabled]="disabled"
        class="w-100 background-white"
        type="number"
        (ngModelChange)="updateValue($event)"
        [label]="label"
      ></rb-form-input>

      <button
        *ngIf="dataType === 'color'"
        [ngClass]="{ disabled: disabled }"
        class="rb-btn color-picker-input-button"
        [rbDropdown]="colorDropdown"
        [style.backgroundColor]="value"
        [autoClose]="false"
      >
        &nbsp;
      </button>
    </div>

    <rb-form-custom-select
      *ngIf="selectedType === selectType.USER_CONTEXT"
      data-cy="valueFormattingInputUserContextSelector"
      [name]="name"
      class="form-custom-select-small-padding-dropdown background-white"
      [label]="label"
      [disabled]="disabled"
      [(ngModel)]="value"
      (ngModelChange)="updateValue($event)"
    >
      <rb-form-select-option *ngFor="let param of userContextParameter" [value]="param.value">
        {{ param.label | translated }}
      </rb-form-select-option>
    </rb-form-custom-select>

    <rb-form-custom-select
      *ngIf="selectedType === selectType.FILTER_WIDGET"
      [name]="name"
      class="form-custom-select-small-padding-dropdown background-white"
      [label]="label"
      [disabled]="disabled"
      [(ngModel)]="value"
      (ngModelChange)="updateValue($event)"
    >
      <rb-form-select-option *ngFor="let param of filterParameters" [value]="param.name">
        {{ param.label | translated }}
      </rb-form-select-option>
    </rb-form-custom-select>

    <rb-form-custom-select
      *ngIf="selectedType === selectType.INPUT_WIDGET"
      [name]="name"
      class="form-custom-select-small-padding-dropdown background-white"
      [label]="label"
      [disabled]="disabled"
      [(ngModel)]="value"
      (ngModelChange)="updateValue($event)"
    >
      <rb-form-select-option *ngFor="let param of inputParameters" [value]="param.technicalName">
        {{ param.technicalName | translated }}
      </rb-form-select-option>
    </rb-form-custom-select>
  </div>
</div>

<ng-template #colorDropdown>
  <color-selector
    [color]="currentColor"
    *ngIf="selectedType === selectType.CUSTOM_VALUE"
    (ngModelChange)="updateValue($event)"
    (colorChange)="updateCurrentColor($event)"
    [alpha]="false"
  ></color-selector>
</ng-template>
