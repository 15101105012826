import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Optional } from '@angular/core';
import { isEmpty } from 'lodash-es';
import {
  combineLatest,
  forkJoin,
  from,
  lastValueFrom,
  MonoTypeOperatorFunction,
  Observable,
  of,
  Subject,
  throwError
} from 'rxjs';
import { catchError, map, mergeMap, shareReplay, switchMap } from 'rxjs/operators';
import { ProjectConfig } from '../../shared-projects/models/project.model';
import { ProjectsService } from '../../shared-projects/services/projects.service';
import { httpDetailError } from '../../shared/http-utils';
import { DEVICE_LINK_FEATURE_ID } from '../features/devicelinks-feature/devicelinks-feature.model';
import { Device } from '../models/device';
import { DEFAULT_DEVICE_TYPE, DeviceType, DeviceTypeDefinition } from '../models/device-types';
import {
  allThingFields,
  DeviceProvisioning,
  DeviceProvisioningResponse,
  NewThing,
  Thing,
  ThingFeature,
  ThingProperties
} from '../models/thing';
import { ThingPolicy } from '../models/thing-policy';
import { DeviceCacheService } from './device-cache.service';
import { DeviceHistoryService } from './device-history.service';
import { DeviceTypesService } from './device-types.service';
import { FeatureDefinitionsService } from './feature-definitions.service';
import { Router } from '@angular/router';
import { Constants } from '../../../constants';

export interface ThingSearchResult {
  items: Thing[];
  cursor?: string;
}

export interface DeviceSearchResult {
  items: Device[];
  cursor?: string;
}

export interface UpdateFeatureBlock {
  properties?: ThingProperties;
  feature?: ThingFeature;
  updateInfo?: HttpHeaders;
}

const handleThingsError: MonoTypeOperatorFunction<any> = catchError(
  (error: Error | HttpErrorResponse) => {
    throw httpDetailError(error);
  }
);

@Injectable({ providedIn: 'root' })
export class DevicesService {
  changed = new EventEmitter<Device>();
  removed = new EventEmitter<Device>();

  readonly MAX_THINGS_COUNT = 200;

  private thingRequestsCache: { thingId: string; device: Subject<Device> }[] = [];
  private deviceRequestMinQueueTime = 10;

  constructor(
    public projectsService: ProjectsService,
    private router: Router,
    private http: HttpClient,
    private deviceHistoryService: DeviceHistoryService,
    private deviceTypesService: DeviceTypesService,
    public cache: DeviceCacheService,
    @Optional() private featureDefinitionsService: FeatureDefinitionsService
  ) {}

  get thingsBaseUrl(): string {
    return '/iot-things-api/' + this.projectsService.projectName + '/2';
  }

  get deviceTypeBaseUrl(): string {
    return '/project-management-service/v1/' + this.projectsService.projectName + '/device-types';
  }

  get deviceProvisioningBaseUrl(): string {
    return '/deviceprovisioning/' + this.projectsService.projectName;
  }

  get projectName() {
    return this.projectsService.projectName;
  }

  /**
   * The namespace of the current project.
   */
  getNamespace(): Observable<string> {
    return this.projectsService.getCurrentProject().pipe(
      map((config: ProjectConfig) => config.thingsNamespace),
      handleThingsError
    );
  }

  getCopyPolicy(): Observable<boolean> {
    return this.projectsService
      .getCurrentProject()
      .pipe(map((config: ProjectConfig) => config.options['thingsCopyPolicy']));
  }

  getDefaultPolicyId(): Observable<string> {
    return this.projectsService.getCurrentProject().pipe(
      map((config: ProjectConfig) => config.thingsDefaultPolicyId),
      handleThingsError
    );
  }

  canAddDevices(): Observable<boolean> {
    return this.projectsService.getCurrentProject().pipe(
      map((config: ProjectConfig) => !!(config.thingsNamespace && config.thingsDefaultPolicyId)),
      handleThingsError
    );
  }

  getDeviceProvisioningDeleteUrl(thingId): string {
    return this.deviceProvisioningBaseUrl + '/' + thingId;
  }

  getThingUrl(thingId): string {
    return this.thingsBaseUrl + '/things/' + thingId;
  }

  getPolicyUrl(policyId): string {
    return this.thingsBaseUrl + '/policies/' + policyId;
  }

  getFeatureUrl(thingId, featureId): string {
    return this.thingsBaseUrl + '/things/' + thingId + '/features/' + featureId;
  }

  getSearchUrl() {
    return this.thingsBaseUrl + '/search/things';
  }

  getQRCodeUrl(): string {
    return this.deviceTypeBaseUrl + '/qr-code';
  }

  getPolicy(policyId: string): Observable<ThingPolicy> {
    return this.http.get<ThingPolicy>(this.getPolicyUrl(policyId)).pipe(
      map((policy) => {
        return new ThingPolicy(policy, this.projectsService.projectName);
      })
    );
  }

  /**
   * @param {ThingPolicy} policy
   * @returns {Observable<null>} httpStatus 204 without content on success
   */
  updatePolicy(policy: ThingPolicy): Observable<null> {
    return this.http.put<null>(this.getPolicyUrl(policy.policyId), policy);
  }

  hasUserDeviceWriteAccess(device: Device): boolean {
    const thingWriteRoles = ThingPolicy.getResourceWriteAccessRoles(
      device._policy,
      'thing:/.grant',
      this.projectsService.projectName
    );
    return this.checkIfUserHasRoles(thingWriteRoles);
  }

  checkIfUserHasRoles(roles: string[]): boolean {
    if (isEmpty(roles)) {
      return false;
    }
    return roles.some((role: string) => {
      return this.projectsService.hasRoleOfCurrentProject(role);
    });
  }

  createAndCache(thing: Thing): Device {
    const device = new Device(thing, this.projectsService.projectName);
    this.cache.devicesCache[device.thingId] = device;
    return device;
  }

  navigateToDeviceOverviewPageByThingId(thingId: string): void {
    this.router.navigateByUrl(
      'project/' + this.projectName + Constants.routing.devicesAll + '/' + thingId
    );
  }

  /**
   * Find devices in IoT Things.
   * Filter and sort are specified in the Things API Docs:
   * https://things.s-apps.de1.bosch-iot-cloud.com/documentation/rest/#!/Things45Search/get_search_things
   */
  findDevices(
    filter: string,
    cursor = '',
    size = 20,
    sort = '+thingId',
    useNamespace = true,
    fields?: string,
    namespaces?: string
  ): Observable<DeviceSearchResult> {
    return this.getDevices(filter, cursor, size, sort, useNamespace, fields, namespaces).pipe(
      map((result: ThingSearchResult) => {
        result.items = result.items.map((thing: Thing) => this.createAndCache(thing));
        return result;
      }),
      handleThingsError
    );
  }

  getDevicesByPage(
    _filter: string,
    cursor = '',
    page: number,
    size: number,
    fields?: string
  ): Observable<DeviceSearchResult> {
    const resultSize = (page + 1) * size;
    if (resultSize <= this.MAX_THINGS_COUNT && !cursor) {
      const start = page * size;
      const end = start + size;
      return this.getDevices(_filter, null, end, '+thingId', true, fields).pipe(
        map((result: ThingSearchResult) => {
          result.items = result.items
            .map((thing: Thing) => this.createAndCache(thing))
            .slice(start);
          return result;
        }),
        handleThingsError
      );
    } else {
      return from(this.findDeviceCursorForPage(_filter, page, size, cursor)).pipe(
        switchMap((cursor) => this.getDevices(_filter, cursor, size, '+thingId', true, fields)),
        map((result: ThingSearchResult) => {
          result.items = result.items.map((thing: Thing) => this.createAndCache(thing));
          return result;
        }),
        handleThingsError
      );
    }
  }

  async findDeviceCursorForPage(
    _filter: string,
    page: number,
    size: number,
    cursor?: string
  ): Promise<string> {
    if (!isEmpty(cursor)) {
      return cursor;
    }
    let _cursor: string = null;
    const startIndex = page * size;

    for (let i = 0; i < startIndex; i += this.MAX_THINGS_COUNT) {
      const result = await lastValueFrom(
        this.getDevices(
          _filter,
          _cursor,
          Math.min(this.MAX_THINGS_COUNT, startIndex - i),
          '+thingId',
          true,
          'thingId'
        )
      );
      _cursor = result?.cursor ?? null;
    }
    return _cursor;
  }

  getDevices(
    filter: string,
    cursor = '',
    size = 20,
    sort = '+thingId',
    useNamespace = true,
    fields?: string,
    namespaces?: string
  ): Observable<ThingSearchResult> {
    const cursorParam = !isEmpty(cursor) ? `cursor(${cursor}),` : '';
    const params = {
      option: `${cursorParam}size(${size}),sort(${sort.trim()})`
    };
    if (filter) {
      params['filter'] = filter.trim();
    }
    if (fields) {
      params['fields'] = fields.trim();
    }

    return this.getNamespace().pipe(
      switchMap((namespace) => {
        if (useNamespace && !namespaces) {
          params['namespaces'] = namespace;
        } else if (namespaces) {
          params['namespaces'] = namespaces.trim();
        }
        const paramsAsString = new URLSearchParams(params).toString();
        return this.http.get<ThingSearchResult>(`${this.getSearchUrl()}?${paramsAsString}`);
      }),
      map((result: ThingSearchResult) => {
        result.items = result.items.map((thing: Thing) => this.createAndCache(thing));
        return result;
      }),
      handleThingsError
    );
  }

  // use only if devices have not been updated right before, it takes a few seconds until Things Search Index is updated
  getChildrenOfDevice(
    device: Device,
    cursor = '',
    fields?: string
  ): Observable<DeviceSearchResult> {
    return this.getDevices(
      'eq(features/deviceLinks/properties/parentId,' + JSON.stringify(device.thingId) + ')',
      cursor,
      undefined,
      undefined,
      undefined,
      fields
    ).pipe(
      map((result: ThingSearchResult) => ({
        items: result.items.map((thing) => this.createAndCache(thing)),
        cursor: result.cursor ?? null
      })),
      handleThingsError
    );
  }

  createDeviceLink(from_device: Device, to_device: Device) {
    const url = this.getThingUrl(to_device.thingId) + '/features/devicelinks/properties';

    return this.http.put<any>(url, {
      parentId: from_device.thingId
    });
  }

  findDeviceRelations(deviceId: string): Observable<DeviceSearchResult> {
    return this.findDevices(
      `or(
        eq(features/relation/properties/target,"${deviceId}"),
        eq(features/relation/properties/source,"${deviceId}")
      )`,
      '',
      20,
      '+thingId',
      false
    );
  }

  findDevicesLinkingTo(deviceId: string): Observable<DeviceSearchResult> {
    return this.findDevices(
      'eq(features/relation/properties/target,"' + deviceId + '")',
      '',
      20,
      '+thingId',
      false
    );
  }

  findDevicesLinkingFrom(deviceId: string): Observable<DeviceSearchResult> {
    return this.findDevices(
      'eq(features/relation/properties/source,"' + deviceId + '")',
      '',
      20,
      '+thingId',
      false
    );
  }

  findDevicesByIds(
    thingIds: string[],
    sort = '+thingId',
    cached = false,
    fields?
  ): Observable<Device[]> {
    if (!thingIds || !thingIds.length) {
      return of([]);
    }
    thingIds = thingIds.slice();
    const devices = cached ? this.cache.findDevicesByIdsInCache(thingIds) : [];
    if (!thingIds.length) {
      return of(devices);
    }

    let filter = thingIds.map((id) => `eq(thingId,"${id}")`).join(',');
    if (thingIds.length > 1) {
      filter = 'or(' + filter + ')';
    }
    return this.findDevices(filter, '', thingIds.length, sort, true, fields).pipe(
      map((loadedDevices) => [...devices, ...loadedDevices.items]),
      handleThingsError
    );
  }

  countDevices(filter?: string, namespaces?: string): Observable<number> {
    const params = {};
    if (filter) {
      params['filter'] = filter.trim();
    }

    return this.getNamespace().pipe(
      switchMap((_namespace) => {
        params['namespaces'] = namespaces ? namespaces.trim() : _namespace;
        return this.http.get<number>(this.getSearchUrl() + '/count', {
          params: params
        });
      }),
      handleThingsError
    );
  }

  /**
   * Maps each Device Type in the supplied Array to the count of its Devices
   * @param deviceTypes Array of Device Types
   */
  countDevicesByType(
    deviceTypes: DeviceTypeDefinition[]
  ): Observable<{ type: DeviceTypeDefinition; count: number }[]> {
    const countDevicesOfType = (type: DeviceTypeDefinition) => {
      return this.countDevices(`eq(attributes/type,"${type.type}")`).pipe(
        map((deviceCount: number) => ({ type: type, count: deviceCount }))
      );
    };

    const mapDeviceTypesToCount = (types: DeviceTypeDefinition[]) => {
      return types.map((type) => countDevicesOfType(type));
    };

    return forkJoin(mapDeviceTypesToCount(deviceTypes));
  }

  getDevice(thingId: string, useCache = false): Observable<Device> {
    const devices = useCache ? this.cache.findDevicesByIdsInCache([thingId]) : null;
    if (devices?.length) {
      return of(devices[0]);
    }

    return this.http
      .get<Thing>(this.getThingUrl(thingId), {
        params: {
          fields: allThingFields
        }
      })
      .pipe(
        map((thing) => this.createAndCache(thing)),
        handleThingsError
      );
  }

  getMandatoryDevices() {
    return this.deviceTypesService.getMandatoryDeviceTypes().pipe(
      switchMap((deviceTypes: DeviceTypeDefinition[]) => {
        if (!deviceTypes) {
          return [];
        }
        const mandatoryDeviceTypeLabels = deviceTypes.map((type) => type.type);
        const thingsFilter = `in(attributes/type,"${mandatoryDeviceTypeLabels.join('","')}")`;
        return this.getDevices(thingsFilter, null, 200);
      }),
      map((res) => res.items)
    );
  }

  // collects thingsIds until page is loaded (and deviceRequestMinQueueTime has passed)  and sends a combined thing request
  getDeviceCombinedRequest(thingId: string): Observable<Device> {
    if (this.cache.devicesCache[thingId]) {
      return of(this.cache.devicesCache[thingId]);
    }

    if (isEmpty(this.thingRequestsCache)) {
      setTimeout(() => {
        const tempCache = [...this.thingRequestsCache];
        this.thingRequestsCache = [];
        const uniqueThingIds = [...new Set(tempCache.map((t) => t.thingId))];
        const thingIdFilter =
          'or(' + uniqueThingIds.map((id) => `eq(thingId,"${id}")`).join(',') + ')';

        this.findDevices(
          thingIdFilter,
          '',
          uniqueThingIds.length,
          undefined,
          true,
          'thingId,features,attributes'
        ).subscribe((result: DeviceSearchResult) => {
          tempCache.forEach((request) => {
            request.device.next(result.items.find((t) => t.thingId === request.thingId));
            request.device.complete();
          });
        });
        return of(this.thingRequestsCache[thingId]);
      }, this.deviceRequestMinQueueTime);
    }

    const subject = new Subject<Device>();
    this.thingRequestsCache.push({ thingId: thingId, device: subject });
    return subject;
  }

  getDeviceType(type: string): Observable<DeviceType> {
    if (!this.cache.deviceTypeCache[type]) {
      this.cache.deviceTypeCache[type] = this.http
        .get<DeviceTypeDefinition>(this.deviceTypeBaseUrl + '/' + type)
        .pipe(
          catchError((err) => {
            if (type === 'device') {
              return of(DEFAULT_DEVICE_TYPE);
            }
            throw httpDetailError(err);
          }),
          map((raw) => new DeviceType(raw)),
          shareReplay(1)
        );
    }
    return this.cache.deviceTypeCache[type];
  }

  /**
   * Requires the FeatureDefinitionsService
   */
  createDevice(
    type: string,
    id: string,
    name = '',
    features?: { [name: string]: ThingFeature },
    backdateHeaders?: HttpHeaders
  ): Observable<Device> {
    if (!id || !name) {
      throw new Error('Invalid Create Device Parameters');
    }
    let typeName = 'device';

    const preparedThing = this.getDeviceType(type).pipe(
      map((deviceType) => {
        typeName = deviceType.type;
        const thing = this.featureDefinitionsService.getDefaultThing(deviceType);
        if (features) {
          Object.keys(features).forEach(
            (featureId) => (thing.features[featureId] = features[featureId])
          );
        }
        if (thing.features.general) {
          thing.features.general.properties.name = name;
        }
        return thing;
      })
    );
    return combineLatest([
      preparedThing,
      this.getNamespace(),
      this.getDefaultPolicyId(),
      this.getCopyPolicy()
    ]).pipe(
      switchMap(([thing, namespace, policyId, copyPolicy]) => {
        const thingId = `${namespace}:${typeName}_${id}`;
        if (copyPolicy) {
          thing._copyPolicyFrom = policyId;
        } else {
          thing.policyId = policyId;
        }
        return this.sendThingsCreateRequest(thingId, thing, backdateHeaders);
      }),
      map((thing) => this.createAndCache(thing)),
      handleThingsError
    );
  }

  copyDevice(
    device: Device,
    deviceType: string,
    id: string,
    name = '',
    backdateHeaders?: HttpHeaders
  ): Observable<Device> {
    if (!id || !name) {
      throw new Error('Invalid Copy Device Parameters');
    }
    const thing: NewThing & Thing = JSON.parse(JSON.stringify(device));
    delete thing.thingId;
    if (thing.features.general) {
      thing.features.general.properties.name = name;
    }
    thing.attributes.type = deviceType;

    return combineLatest([
      this.getNamespace(),
      this.getDefaultPolicyId(),
      this.getCopyPolicy()
    ]).pipe(
      switchMap(([namespace, policyId, copyPolicy]) => {
        const thingId = `${namespace}:${deviceType}_${id}`;
        if (copyPolicy) {
          thing._copyPolicyFrom = thing.policyId;
          delete thing.policyId;
          delete thing._policy;
        } else {
          thing.policyId = policyId;
        }
        return this.sendThingsCreateRequest(thingId, thing, backdateHeaders);
      }),
      map((createdThing) => this.createAndCache(createdThing)),
      handleThingsError
    );
  }

  updatePolicyIdOfThing(device: Thing, policyId: string) {
    return this.http.put<any>(this.getThingUrl(device.thingId) + '/policyId', policyId, {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain'
      })
    });
  }

  updateAttributes(device: Thing, attributes: ThingProperties) {
    return this.http.put<any>(this.getThingUrl(device.thingId) + '/attributes', attributes);
  }

  updateProperties(
    thingId: string,
    featureId: string,
    properties: ThingProperties,
    httpHeaders?: HttpHeaders,
    method: 'PUT' | 'PATCH' = 'PUT'
  ) {
    if (method === 'PUT') {
      return this.http.put<any>(
        this.getFeatureUrl(thingId, featureId) + '/properties',
        properties,
        {
          headers: httpHeaders
        }
      );
    }

    return this.http.patch<any>(
      this.getFeatureUrl(thingId, featureId) + '/properties',
      properties,
      {
        headers: this.assemblePatchHeaders(httpHeaders)
      }
    );
  }

  updateProperty(device: Thing, featureId: string, propertyKey: string, propertyValue) {
    return this.http.put<any>(
      this.getFeatureUrl(device.thingId, featureId) + '/properties/' + propertyKey,
      propertyValue,
      {
        headers: { 'Content-Type': 'application/json' }
      }
    );
  }

  /**
   * Used to update specific feature block. Can be used to update only properties or the whole feature.
   * @param thingId Id of the device
   * @param featureId Id of the feature to be updated
   * @param update Data about the update
   * @param method
   */
  updateFeatureBlock(
    thingId: string,
    featureId: string,
    update: UpdateFeatureBlock,
    method: 'PUT' | 'PATCH' = 'PUT'
  ) {
    const updateBlock = this.updateBlock.bind(this, thingId, featureId, update, method);

    // change is backdated
    if (update.updateInfo && update.updateInfo.get('x-insights-backdate')) {
      const backdateTimestamp = new Date(update.updateInfo.get('x-insights-backdate'));
      return this.deviceHistoryService.isBackdateTimestampValid(thingId, backdateTimestamp).pipe(
        switchMap((timestampValid) => {
          if (timestampValid) {
            return updateBlock();
          } else {
            throw new Error('invalid backdating timestamp');
          }
        })
      );
    } else {
      return updateBlock();
    }
  }

  updateFeature(
    thingId: string,
    featureId: string,
    feature: ThingFeature,
    httpHeaders?: HttpHeaders,
    method: 'PUT' | 'PATCH' = 'PUT'
  ) {
    if (method === 'PUT') {
      return this.http.put<any>(this.getFeatureUrl(thingId, featureId), feature, {
        headers: httpHeaders
      });
    }

    return this.http.patch<any>(this.getFeatureUrl(thingId, featureId), feature, {
      headers: this.assemblePatchHeaders(httpHeaders)
    });
  }

  removeFeature(device: Device, featureId: string, httpHeaders?: HttpHeaders) {
    return this.http.delete<any>(this.getFeatureUrl(device.thingId, featureId), {
      headers: httpHeaders
    });
  }

  removeFeatureProperty(device: Device, featureId: string, propertyPath: string) {
    return this.http.delete<any>(
      this.getFeatureUrl(device.thingId, featureId) + '/properties/' + propertyPath
    );
  }

  removeDevice(device: Device, purge = false) {
    let requestUrl = this.getThingUrl(device.thingId);
    if (this.projectsService.useDeviceProvisioning) {
      requestUrl = this.getDeviceProvisioningDeleteUrl(device.thingId);
    }

    return this.http
      .delete(requestUrl, {
        params: new HttpParams().set('purge', purge)
      })
      .pipe(
        switchMap((deleteResult) => {
          if (!this.projectsService.useDeviceProvisioning && device.thingId === device.policyId) {
            return this.http
              .delete(this.getPolicyUrl(device.thingId))
              .pipe(map(() => deleteResult));
          } else {
            return of(deleteResult);
          }
        })
      );
  }

  removeDeviceLinksToDevice(device: Device) {
    return this.getChildrenOfDevice(device).pipe(
      mergeMap((result) =>
        result.items.length !== 0
          ? forkJoin(
              result.items.map((child) =>
                this.updateProperties(child.thingId, DEVICE_LINK_FEATURE_ID, {})
              )
            )
          : of(null)
      )
    );
  }

  isDeviceExisting(deviceType: string, deviceId: string): Observable<boolean> {
    return new Observable((obs) => {
      combineLatest([this.getNamespace()])
        .pipe(
          switchMap(([namespace]) => {
            const thingId = `${namespace}:${deviceType}_${deviceId}`;
            return this.getDevice(thingId);
          })
        )
        .subscribe({
          next: () => {
            obs.next(true);
          },
          error: () => {
            obs.next(false);
          }
        });
    });
  }

  private assemblePatchHeaders(httpHeaders?: HttpHeaders) {
    return (httpHeaders || new HttpHeaders()).set('Content-Type', 'application/merge-patch+json');
  }

  private updateBlock(
    thingId: string,
    featureId: string,
    update: UpdateFeatureBlock,
    method: 'PUT' | 'PATCH' = 'PUT'
  ) {
    if (update.properties) {
      return this.updateProperties(
        thingId,
        featureId,
        update.properties,
        update.updateInfo,
        method
      );
    } else if (update.feature) {
      return this.updateFeature(thingId, featureId, update.feature, update.updateInfo, method);
    } else {
      return throwError('properties or feature not provided');
    }
  }

  private sendThingsCreateRequest(
    thingId: string,
    thing: NewThing,
    backdateHeaders?: HttpHeaders
  ): Observable<Thing> {
    if (this.projectsService.useDeviceProvisioning) {
      const deviceProvisioningThing: DeviceProvisioning = {
        id: thingId,
        hub: {
          device: {
            enabled: true
          }
        },
        things: { thing: thing }
      };
      return this.http
        .post<DeviceProvisioningResponse>(this.deviceProvisioningBaseUrl, deviceProvisioningThing, {
          headers: backdateHeaders
        })
        .pipe(
          map((response) => {
            return response.things.thing;
          })
        );
    } else {
      return this.http.put<Thing>(this.getThingUrl(thingId), thing, { headers: backdateHeaders });
    }
  }

  getDeviceQRCode(device: Thing): Observable<Blob> {
    const headers = new HttpHeaders({ 'Content-Type': 'image/png' });
    return this.http.get(this.getQRCodeUrl(), {
      headers: headers,
      responseType: 'blob',
      params: { thing_id: device.thingId }
    });
  }
}
