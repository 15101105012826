export const Constants = {
  msToDays: 1 / (24 * 60 * 60 * 1000.0),
  archiving: {
    minArchivingDays: 90,
    minArchivingDurationMs: 90 * 24 * 60 * 60 * 1000,
    minDaysWithoutAccess: 100
  },
  routing: {
    home: '/home',
    projects: '/projects',
    processing: '/processing',
    overview: '/overview',
    explore: '/explore',
    devices: '/devices',
    devicesAll: '/devices/all',
    devicesOverview: '/devices/overview',
    deviceType: '/devices/devicetype',
    deviceHistory: '/devices/history',
    exploreExplorer: '/explore/explorer',
    exploreTemplateEditor: '/explore/explorer/editor',
    exploreBrowser: '/explore/browser',
    exploreAnalyzer: '/explore/analyzer',
    exploreQueryHistory: '/explore/query-history',
    exploreQueryReference: '/explore/query-references',
    exploreInputHistory: '/processing/input-history',
    processingPipelines: '/processing/pipelines',
    processingAddNewPipeline: '/processing/pipelines/create',
    processingStatistics: '/processing/statistics',
    exploreProcessingInfo: '/processing/processing-info',
    processingOverview: '/processing/overview',
    ingestionMonitoring: '/explore/ingestion-monitoring',
    services: '/services',
    servicesOverview: '/services/overview',
    servicesDecoder: '/services/decoder',
    servicesDataUpload: '/processing/data-upload',
    servicesNotifications: '/services/conditionalactions',
    servicesNotificationsAdd: '/services/conditionalactions/add',
    views: '/views',
    dashboards: '/views/dashboards',
    admin: '/sfde-admin',
    licenses: '/pages/licenses',
    help: '/static-contents/docu/html/Introduction.html',
    helpUserManagement: '/static-contents/docu/html/Users.html',
    helpGettingStarted: '/static-contents/docu/html/Getting-Started.html',
    helpConfiguringPipeline: '/static-contents/docu/html/Configuring-a-pipeline.html',
    helpEnableCookies: '/static-contents/docu/html/Visiting-an-embedded-view.html',
    helpViewsAndDashboards: '/static-contents/docu/html/Dashboards.html',
    userLogin: '/user/login',
    userLegacyLoginInfo: '/user/legacy-login-info',
    userLogout: '/logout',
    iframeLoginStart: '/user/login-start',
    userInfo: '/user-internal/info',
    userIncorrectIdentityProvider: '/user/incorrect-identity-provider',
    userNoProjectAccess: '/user/no-project-access',
    userNoDomainAccess: '/user/no-domain-access',
    userSettings: '/user-internal/settings',
    userGuides: '/pages/guides',
    userApi: '/pages/api',
    error: '/error',
    servicesDecoderHistory: '/services/decoder/history',
    serviceDataUpload: '/processing/data-upload',
    subscriptionStandby: '/standby',
    adminDbStats: '/admin/db-stats',
    adminCreateProject: '/sfde-admin/create-project',
    adminUsers: '/admin/users',
    adminRoles: '/admin/roles',
    customWidgets: '/admin/custom-widgets',
    customWidgetsDetails: '/admin/custom-widgets/repository',
    adminCustomMenu: '/admin/custom-menu',
    adminSubscriptionDetails: '/admin/subscription-details',
    adminUsageConfig: '/admin/usage-config',
    adminConsentConfig: '/admin/consent-config',
    adminProjectConfig: '/admin/project-config',
    adminProjectConfigPerformance: '/admin/project-config/performance',
    adminProjectConfigNotifications: '/admin/project-config/notifications',
    adminProjectConfigNotificationBanner: '/admin/project-config/notification-banner',
    adminProjectConfigAccess: '/admin/project-config/access',
    adminProjectConfigMapExtensions: '/admin/project-config/map-extensions',
    adminProjectConfigThings: '/admin/project-config/things',
    adminProjectConfigUpload: '/admin/project-config/upload',
    adminProjectConfigSecurity: '/admin/project-config/security',
    adminProjectConfigArchiving: '/admin/project-config/archiving',
    adminViewsDashboardsConfig: '/admin/views-config',
    adminCollectionConfig: '/admin/collection-config',
    adminLogs: '/admin/logs',
    adminJobs: '/sfde-admin/jobs',
    adminRetentionConfig: '/admin/retention-config',
    adminWhiteLabeling: '/admin/white-labeling',
    adminWhiteLabelingBasic: '/admin/white-labeling/basic',
    adminWhiteLabelingColor: '/admin/white-labeling/color',
    adminWhiteLabelingDomain: '/admin/white-labeling/domain',
    adminWhiteLabelingHomepage: '/admin/white-labeling/homepage',
    adminWhiteLabelingEmail: '/admin/white-labeling/email',
    adminWhiteLabelingFooter: '/admin/white-labeling/footer',
    adminWhiteLabelingCustomMenu: '/admin/white-labeling/custom-menu',
    adminCustomDomainsOverview: '/sfde-admin/domains',
    adminExtendedSupport: '/sfde-admin/extended-support'
  },
  roles: {
    powerUser: 'power_user',
    manager: 'manager',
    admin: 'admin',
    owner: 'owner'
  },
  errors: {
    loginCheckFailed: 'Failed to check the Login',
    resourceNotFound: 'Oops, the resource you requested was not found.',
    invalidPermission: 'You have no permission to open the requested page.',
    sessionTimeout: 'Your session has expired. Please login again.',
    login_required: 'Login required to see the requested resource',
    missingConsent: 'You have not consented to the latest Consent Document'
  },
  notificationBanner: {
    storageKey: 'hideNotificationBanner'
  },
  validation: {
    customDomainRegEx: /^(?!-)[a-z0-9-]+([-.][a-z0-9]+)*\.[a-z]{2,6}$/,
    maxDescriptionLength: 500,
    thingIdRegEx:
      /^[a-zA-Z]\w*(\.[a-zA-Z]\w*)*:([-\w:@&=+,.!~*'_;]|%[A-Fa-f0-9]{2})([-\w:@&=+,.!~*'$_;]|%[A-Fa-f0-9]{2})*$/,
    urlRegEx: /^(?:(ws|http)(s)?:\/\/|mailto:)[\w.-]+(?:\.[\w.-]+)*[\w\-._~:/?#[\]@!$&'()*+,;=]+$/i,
    uuidPattern: /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
  },
  mongoDbVersion: 'v7.0'
};
