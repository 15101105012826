<span class="text-center center" [rbDropdown]="operatorDropdown" [class.disabled]="disabled">
  <span class="rb-ic circled-selector">
    {{ this.selectedOperator?.visualID }}
  </span>
</span>

<ng-template #operatorDropdown>
  <ng-container *ngFor="let operator of operatorsList">
    <a
      class="rb-dropdown-item"
      *ngIf="!unsupportedOperators.includes(operator.technicalID)"
      [class.active]="operator === selectedOperator"
      [attr.data-cy]="'simpleSearchOperatorSelector-' + operator.technicalID"
      (click)="setOperator(operator)"
    >
      <strong>{{ operator.visualID }}</strong>
      &nbsp;
      {{ operator.translationPath | translate }}
    </a>
  </ng-container>
</ng-template>
