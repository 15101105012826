import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PicklistComponent } from './picklist.component';
import { SharedModule } from '../../shared/shared.module';
import { MediaQueryModule } from '../media-query/media-query.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SearchItemsDirective } from './directives/search-items.directive';

@NgModule({
  declarations: [PicklistComponent, SearchItemsDirective],
  imports: [CommonModule, SharedModule, MediaQueryModule, InfiniteScrollModule],
  exports: [PicklistComponent]
})
export class PicklistModule {}
