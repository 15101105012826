<div class="main-bg">
  <div class="container text-center">
    <h1 class="mt-3 componentHeader u-TextColor--fuchsia">
      {{ 'projectConfig.noAccess' | translate }}
    </h1>

    <p>
      {{ 'projectConfig.noAccessToProject' | translate }}
    </p>

    <p>
      {{ 'projectConfig.emailInviteToProject' | translate }}
    </p>

    <p>
      <a [routerLink]="routing.home" class="rb-btn">{{ 'generalConfig.backToHome' | translate }}</a>
    </p>
  </div>
</div>
