import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export const INITIAL_FULLSCREEN_MODE = new InjectionToken<boolean>('INITIAL_FULLSCREEN_MODE');

@Injectable({
  providedIn: 'root'
})
export class FullscreenService {
  isActive = new BehaviorSubject<boolean>(false);

  constructor(@Optional() @Inject(INITIAL_FULLSCREEN_MODE) private initialFullScreen: boolean) {
    if (this.initialFullScreen === true) {
      this.activate();
    }
  }

  activate() {
    this.isActive.next(true);
    setTimeout(() => {
      triggerResizeEvent();
    });
  }

  deactivate() {
    this.isActive.next(false);
    setTimeout(() => {
      triggerResizeEvent();
    });
  }
}

export function triggerResizeEvent() {
  window.dispatchEvent(new Event('resize'));
}
