<div
  *ngIf="(appTheming.appTheme$ | async)?.authenticationProviders as enabledProviders"
  [ngClass]="{ etas: appTheming.appTheme.isEtasTheme() }"
  data-cy="loginProviderSelect"
>
  <ng-container *ngIf="enabledProviders.length > 1; else singleProvider">
    <button
      class="rb-btn rb-passive-link no-wrap px-0 mx-2"
      type="button"
      *ngIf="inHeader"
      [rbPopover]="signInPopover"
      [autoClose]="true"
    >
      <i class="rb-ic rb-ic-login ml-0 mr-2"></i>
      <span class="d-inline-block flex-grow-1">{{ buttonText | translate }}</span>
    </button>

    <button
      *ngIf="!inHeader"
      type="button"
      class="rb-btn rb-primary rb-dropdown-toggle login-button"
      [rbDropdown]="signInDropdown"
    >
      <span class="rb-ic rb-ic-login mr-2"></span>
      <span class="d-inline-block flex-grow-1">{{ buttonText | translate }}</span>
    </button>
  </ng-container>

  <ng-template #signInDropdown let-close="close">
    <div
      [ngClass]="{ etas: appTheming.appTheme.isEtasTheme() }"
      data-cy="loginProviderSelectMenuItem"
    >
      <a
        *ngFor="let provider of enabledProviders"
        class="rb-dropdown-item"
        href="#"
        (click)="login(provider.providerPrefix)"
      >
        {{ provider.label | translate }}
      </a>
    </div>
  </ng-template>

  <ng-template #signInPopover>
    <ul class="o-context-menu__menuItems">
      <li class="a-menu-item" data-cy="loginProviderSelectMenuItem">
        <a
          class="a-menu-item__link"
          href="#"
          *ngFor="let provider of enabledProviders"
          (click)="login(provider.providerPrefix)"
        >
          {{ provider.label | translate }}
        </a>
      </li>
    </ul>
  </ng-template>

  <ng-template #singleProvider>
    <button
      class="rb-btn rb-passive-link no-wrap px-0 mx-2"
      type="button"
      *ngIf="inHeader"
      (click)="login(enabledProviders[0].providerPrefix)"
    >
      <i class="rb-ic rb-ic-login ml-0 mr-2"></i>
      <span class="d-inline-block flex-grow-1">{{ buttonText | translate }}</span>
    </button>

    <button
      type="button"
      class="rb-btn rb-primary login-button"
      *ngIf="!inHeader"
      (click)="login(enabledProviders[0].providerPrefix)"
    >
      <i class="a-icon boschicon-bosch-ic-login mr-2"></i>
      <span class="d-inline-block flex-grow-1">{{ buttonText | translate }}</span>
    </button>
  </ng-template>
</div>
