<div class="main-bg">
  <div class="container text-center">
    <h1 class="mt-3 componentHeader u-TextColor--darkBlue">
      {{ 'userInfo.loginComplete' | translate }}
    </h1>

    <p class="subline-scaled">
      {{ 'userInfo.loginCompleteDescription' | translate }}
    </p>

    <p>
      <button type="button" (click)="closeWindow()" class="rb-btn">
        {{ 'userInfo.closeWindow' | translate }}
      </button>
    </p>
  </div>
</div>
