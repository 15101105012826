export function getInheritedBackgroundColor(el: Element): string {
  const defaultStyle = getDefaultBackground(); // typically "rgba(0, 0, 0, 0)"

  // get computed color for el
  const backgroundColor = window.getComputedStyle(el).backgroundColor;

  // if we got a real value, return it
  if (backgroundColor !== defaultStyle) {
    return backgroundColor;
  }

  // if we've reached the top parent el without getting an explicit color, return default
  if (!el.parentElement) {
    return defaultStyle;
  }

  // otherwise, recurse and try again on parent element
  return getInheritedBackgroundColor(el.parentElement);
}

export function outerHeight(el: any) {
  if (el) {
    let height = el.offsetHeight;
    const style = window.getComputedStyle(el);
    height += parseInt(style.marginTop) + parseInt(style.marginBottom);
    return height;
  }
  return 0;
}

function getDefaultBackground(): string {
  // have to add to the document in order to use getComputedStyle
  const div = document.createElement('div');
  document.head.appendChild(div);
  const bg = window.getComputedStyle(div).backgroundColor;
  document.head.removeChild(div);
  return bg;
}
