<div class="container" *rbLoading="infoLoader">
  <h1 class="mt-3">{{ 'userInfo.invite' | translate: { project: project } }}</h1>
  <p>{{ 'userInfo.inviteDesc' | translate: { project: project } }}</p>

  <form (submit)="acceptInvite()">
    <ng-container *ngIf="infoLoader.result?.validRequest">
      <ng-container *rbLoading="acceptLoader"></ng-container>
      <ng-container *ngIf="acceptLoader.result && acceptLoader.result?.emailMismatch">
        <rb-callout>{{ 'userInfo.emailMismatch' | translate }}</rb-callout>
      </ng-container>

      <button type="submit" data-cy="acceptInviteSubmit" class="rb-btn rb-primary">
        {{ 'userInfo.acceptInvite' | translate }}
      </button>
    </ng-container>
  </form>

  <ng-container *ngIf="infoLoader.result && !infoLoader.result?.validRequest">
    <rb-callout>{{ 'userInfo.invalidInvite' | translate }}</rb-callout>
  </ng-container>
</div>
