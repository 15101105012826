import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterChipComponent } from './filter-chip/filter-chip.component';
import { OperatorSelectorComponent } from './operator-selector/operator-selector.component';
import { QueryConditionInputComponent } from './query-condition-input.component';
import {
  DropdownModule,
  FormFieldsModule,
  PopoverModule,
  RenderTplModule
} from '@inst-iot/bosch-angular-ui-components';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { LogicalOperatorDisplayComponent } from './logical-operator-display/logical-operator-display.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [
    FilterChipComponent,
    OperatorSelectorComponent,
    QueryConditionInputComponent,
    LogicalOperatorDisplayComponent
  ],
  imports: [
    CommonModule,
    FormFieldsModule,
    DropdownModule,
    SharedModule,
    TranslateModule,
    FormsModule,
    PopoverModule,
    RenderTplModule
  ],
  exports: [FilterChipComponent, OperatorSelectorComponent, QueryConditionInputComponent]
})
export class QueryConditionInputModule {}
