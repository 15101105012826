<ng-template #freeplanNotification>
  <ng-container *ngIf="isFreeplanExpired">
    <rb-callout
      [type]="isFreeplanProlongated ? 'success' : 'warning'"
      [closeable]="true"
      *hasProjectRole="roles.admin"
      class="no-margin"
    >
      <div *ngIf="!isFreeplanProlongated">
        <span>{{ 'freeplanExpirationWarning.firstPart' | translate }}</span>
        <button class="rb-btn rb-link p-0" type="button" (click)="prolongateFreeplan()">
          {{ 'freeplanExpirationWarning.prolongationLink' | translate }}
        </button>
        <span>{{ 'freeplanExpirationWarning.secondPart' | translate }}</span>
      </div>
      <div *rbLoading="prolongationLoader">
        <div *ngIf="isFreeplanProlongated">
          {{ 'freeplanExpirationWarning.successfullyProlongated' | translate }}
        </div>
      </div>
    </rb-callout>
  </ng-container>
</ng-template>

<ng-template #projectNotification>
  <ng-container *ngIf="notification">
    <rb-callout
      data-cy="headerNotificationBanner"
      *ngIf="hasNotificationBannerEnabled"
      [type]="convertNotificationType(notification)"
      [closeable]="true"
      class="no-margin"
      (closeChange)="dismissNotificationBanner()"
    >
      <div class="notification-message">
        {{ notification.message }}
      </div>
      <a *ngIf="notification?.url" [href]="notification.url" target="_blank" class="rb-btn rb-link">
        {{ 'admin.projectConfig.readMore' | translate }}
      </a>
    </rb-callout>
  </ng-container>
</ng-template>
