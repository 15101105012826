import { Injectable } from '@angular/core';
import { translate } from '../../shared/translation-util';
import {
  DialogCloseEventWithResult,
  ModalOptions,
  ModalService
} from '@inst-iot/bosch-angular-ui-components';
import { GlobalAlertComponent } from '../global-alert/global-alert.component';

export const GlobalError = {
  NavigationFailed: translate('error.navigationFailed'),
  LoginStatusFailed: translate('error.loginStatusFailed'),
  SessionTimedOut: translate('error.sessionTimeout')
};

type AlertInfoType = 'success' | 'error' | 'warning' | 'info';

export interface AlertInfo {
  type: AlertInfoType;
  description: string;
  details?: any;
  title: string;
  okBtnLabel?: string;
  cancelBtnLabel?: string;
}

@Injectable({
  providedIn: 'root'
})
export class GlobalAlertService {
  alertInfo: AlertInfo = null;

  constructor(private modal: ModalService) {}

  /**
   * The Promise will return true, when the specialBtn was pressed, otherwise undefined
   */
  private openAlert(
    type: AlertInfoType,
    description: string,
    details?: string,
    title?: string,
    okBtnLabel?: string,
    cancelBtnLabel?: string
  ): Promise<DialogCloseEventWithResult> {
    if (type === 'success') {
      title = title || translate('alert.successTitle');
    }
    if (type === 'error') {
      title = title || translate('alert.errorTitle');
    }
    if (type === 'warning') {
      title = title || translate('alert.warningTitle');
    }
    if (type === 'info') {
      title = title || translate('alert.infoTitle');
    }

    this.alertInfo = {
      title,
      description,
      type,
      cancelBtnLabel,
      details
    };

    if (okBtnLabel) {
      this.alertInfo.okBtnLabel = okBtnLabel;
    }

    // null values trigger the cancel button to hide
    if (cancelBtnLabel === undefined) {
      this.alertInfo.cancelBtnLabel = translate('alert.dismiss');
    }

    const options: ModalOptions = {
      showClose: false,
      stacked: true
    };

    const modalRef = this.modal.openComponent<GlobalAlertComponent, DialogCloseEventWithResult>(
      GlobalAlertComponent,
      options
    );
    modalRef.instance.alertInfo = this.alertInfo;
    return modalRef.result.then((reason) => {
      return reason;
    });
  }

  showSuccess(description: string, okBtnLabel?: string) {
    return this.openAlert('success', description, null, null, okBtnLabel);
  }

  showInfo(description: string, okBtnLabel?: string) {
    return this.openAlert('info', description, null, null, okBtnLabel, null);
  }

  showError(description: string, details?: string, okBtnLabel?: string) {
    return this.openAlert('error', description, details, null, okBtnLabel);
  }

  showWarning(description: string, title: string, okBtnLabel?: string, cancelBtnLabel?: string) {
    return this.openAlert('warning', description, null, title, okBtnLabel, cancelBtnLabel);
  }
}
