import { Component, ViewEncapsulation } from '@angular/core';
import { Constants } from '../../../../../constants';
import { LayoutService } from '../../layout.service';
import { ProjectsService } from '../../../../shared-projects/services/projects.service';

@Component({
  selector: 'project-selector',
  templateUrl: './project-selector.component.html',
  styleUrls: ['./project-selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProjectSelectorComponent {
  readonly routing = Constants.routing;

  constructor(public layoutService: LayoutService, public projectsService: ProjectsService) {}
}
