import { DataSource, dataSourceTypes } from './data-source-base';
import { DataSourceType } from './models';
import { assignWithDefaults } from '../model-utils';
import { ValidationErrors } from '@angular/forms';
import { WidgetContext } from '../widget-context';
import { Observable, throwError } from 'rxjs';
import { translate } from '../../../../../shared/translation-util';
import { InjectionToken, Injector } from '@angular/core';
import { DashboardDataService } from '../../../../../dashboards/services/dashboard-data.service';
import { DataLoaderRegistryService } from '../../services/data-loader-registry.service';

export const isPlaybackWidgetEdit = new InjectionToken('PlaybackWidgetEdit');

export class PlaybackDataSource extends DataSource {
  type: DataSourceType = 'playbackAll';
  label = 'Playback All';
  mode: 'all' | 'frame' = 'all';

  constructor(props?: Partial<PlaybackDataSource>) {
    super(props);
    assignWithDefaults(this, props);
  }

  validate(): ValidationErrors | null {
    return null;
  }

  loadData(ctx: WidgetContext): Observable<any[]> {
    const dashboardDataService = ctx.injector.get(DashboardDataService, null);
    if (!dashboardDataService || !dashboardDataService.getWidget('playback')) {
      return throwError('No Playback widget available.');
    }

    const dataLoaderRegistryService = ctx.injector.get(DataLoaderRegistryService, null);
    if (!dataLoaderRegistryService) {
      return throwError('No data loader registry to get data sources');
    }

    return dataLoaderRegistryService.getProducer<any[]>(
      this.mode === 'all' ? 'playbackAll' : 'playbackFrame'
    );
  }
}

dataSourceTypes.push({
  name: 'playbackAll',
  i18nLabel: translate('dataSourceConfig.playbackAll'),
  constructor: PlaybackDataSource,
  isAvailable: isDataSourceAvailable
});

dataSourceTypes.push({
  name: 'playbackFrame',
  i18nLabel: translate('dataSourceConfig.playbackFrame'),
  constructor: PlaybackDataSource,
  defaults: {
    type: 'playbackFrame',
    mode: 'frame',
    label: 'Current playback frame'
  },
  isAvailable: isDataSourceAvailable
});

function isDataSourceAvailable(injector: Injector): boolean {
  const dashboardService = injector.get(DashboardDataService, null);
  return (
    dashboardService &&
    !!dashboardService.getWidget('playback') &&
    !injector.get(isPlaybackWidgetEdit, false)
  );
}
