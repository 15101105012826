import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { QueryTemplateParameter } from '../../../../shared/api-model';
import { createDefaultValueByType } from '../../models/query-parameter-converter';

@Component({
  selector: 'list-value',
  templateUrl: './list-value.component.html'
})
export class ListValueComponent implements OnInit, OnChanges {
  @Input() label: string | TemplateRef<any>;

  @Input() parameter: QueryTemplateParameter;

  @Output() valueChange = new EventEmitter<any>();

  @Input() value: any;

  list = [];

  ngOnInit() {
    if (!this.value || !Array.isArray(this.value)) {
      this.value = [];
    }
    this.list = this.value.map((v, i) => this.createEntry(i));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!Array.isArray(changes.value?.currentValue) && !changes.value?.firstChange) {
      console.warn('Reference value is not a list');
      this.value = [];
    }
  }

  createEntry(index) {
    // TODO check if this works with reordering
    return {
      valueHolder: this,
      get value() {
        return this.valueHolder.value[index];
      },
      set value(v: any) {
        this.valueHolder.value[index] = v;
        this.valueHolder.valueChange.next(this.valueHolder.value);
      }
    };
  }

  add() {
    const v = createDefaultValueByType(this.parameter.dataType);
    const index = this.value.length;
    this.value.push(v);
    this.list.push(this.createEntry(index));
    this.valueChange.next(this.value);
  }

  remove(i) {
    this.value.splice(i, 1);
    this.list.pop(); // Remove the last value accessor from the list
    this.valueChange.next(this.value);
  }
}
