<div class="d-xl-flex align-items-center">
  <div class="row align-items-center">
    <div class="col-3">
      <p class="mb-2">{{ 'widgets.actionButton.buttonBackgroundLabel' | translate }}</p>
    </div>
    <div class="col-9 d-flex">
      <value-or-reference-input
        #backgroundInputComponent
        name="'buttonBackground'"
        [dataType]="'color'"
        [paths]="paths"
        style="width: 300px; overflow: hidden"
        [filterParameters]="filterParameters"
        [inputParameters]="inputParameters"
        [label]="'widgets.actionButton.color' | translate"
        (ngModelChange)="changeColorStyle({ background: $event })"
        [ngModel]="style?.background"
        [placeholder]="'generalConfig.default' | translate"
      ></value-or-reference-input>
      <button
        style="height: 48px"
        (click)="
          resetStyleAttribute('backgroundColor');
          backgroundInputComponent.selectedType = selectType.CUSTOM_VALUE
        "
        type="button"
        class="rb-btn rb-link ml-2 mb-3"
      >
        <i class="rb-ic rb-ic-reset"></i>
      </button>
    </div>
  </div>
  <div class="row align-items-center">
    <div class="col-3">
      <p class="mb-2 ml-xl-3 text-nowrap">
        {{ 'widgets.actionButton.buttonTextColorLabel' | translate }}
      </p>
    </div>
    <div class="col-9 d-flex">
      <value-or-reference-input
        #buttonTextInputComponent
        name="'buttonTextColor'"
        [dataType]="'color'"
        [paths]="paths"
        style="width: 300px; overflow: hidden"
        [filterParameters]="filterParameters"
        [inputParameters]="inputParameters"
        [label]="'widgets.actionButton.color' | translate"
        (ngModelChange)="changeColorStyle({ color: $event })"
        [ngModel]="style?.color"
        [placeholder]="'generalConfig.default' | translate"
      ></value-or-reference-input>
      <button
        type="button"
        style="height: 48px"
        (click)="
          resetStyleAttribute('textColor');
          buttonTextInputComponent.selectedType = selectType.CUSTOM_VALUE
        "
        class="rb-btn rb-link ml-2 mb-3"
      >
        <i class="rb-ic rb-ic-reset"></i>
      </button>
    </div>
  </div>
  <div class="row align-items-center" *ngIf="!hideBorderColor">
    <div class="col-3">
      <p class="mb-2 ml-xl-3 text-prewrap">
        {{ 'widgets.actionButton.buttonBorderColorLabel' | translate }}
      </p>
    </div>
    <div class="col-9 d-flex">
      <value-or-reference-input
        #buttonBorderInputComponent
        name="'buttonBorderColor'"
        [dataType]="'color'"
        [paths]="paths"
        style="width: 300px; overflow: hidden"
        [filterParameters]="filterParameters"
        [inputParameters]="inputParameters"
        [label]="'widgets.actionButton.color' | translate"
        (ngModelChange)="changeColorStyle({ 'border-color': $event })"
        [ngModel]="borderColor"
        [placeholder]="'generalConfig.default' | translate"
      ></value-or-reference-input>
      <button
        type="button"
        style="height: 48px"
        (click)="
          resetStyleAttribute('border-color');
          buttonBorderInputComponent.selectedType = selectType.CUSTOM_VALUE
        "
        class="rb-btn rb-link ml-2 mb-3"
      >
        <i class="rb-ic rb-ic-reset"></i>
      </button>
    </div>
  </div>
</div>
