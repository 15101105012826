import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QueryService } from './services/query.service';
import { DataPipelineService } from './services/data-pipeline.service';
import { QueryStatusMessageComponent } from './query-status-message/query-status-message.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [QueryStatusMessageComponent],
  exports: [QueryStatusMessageComponent],
  providers: [QueryService, DataPipelineService]
})
export class SharedQueryModule {}
