import { Injectable, OnDestroy } from '@angular/core';
import { ComponentResolver } from '../../../../shared-modules/dynamic-charts/dyn-chart/dyn-map/component-resolver';
import { BookableStatus, DeviceTypeDefinition } from '../../../../devices/models/device-types';
import { Device, DeviceViewDetails } from '../../../../devices/models/device';
import { DeviceTypesService } from '../../../../devices/services/device-types.service';
import { Observable, Subject, Subscription } from 'rxjs';
import {
  HyperlinkService,
  HyperlinkTarget,
  LinkDefinition
} from '../../../../shared-modules/data-widgets/data-widgets-common/lib/hyperlink.service';
import { CalendarWidgetConfig } from '../models/calendar-widget-config';
import { DeviceProperty } from '../../filter-widget/filter-widget-edit/filter-widget-edit.model';
import {
  getPropertyEntries,
  PropertyEntry
} from '../../../../devices/features/device-general-feature/general-properties.model';
import {
  CommandbarFilter,
  FilterTrigger
} from '../calendar-command-bar/calendar-command-bar.component';
import { TranslateService } from '@ngx-translate/core';
import { LanguagesService } from '../../../../core/services/languages.service';
import { ViewApi } from '@fullcalendar/core';
import { DeviceDetailsFilterComponent } from '../device-details-filter/device-details-filter.component';
import { LocalStorageService } from 'ngx-localstorage';
import { splitStringOnWhitespace } from '../../../../shared/functional-utils';
import { ProjectsService } from '../../../../shared-projects/services/projects.service';
import { isDevicePropertyFilterEnabled } from './calendar-widget.util';
import {
  getPropertyValueDisplayElementByType,
  loadAndResolveDeviceNamesForDeviceLinkProperties
} from '../../../../devices/features/device-general-feature/property-value-display/property-value/property-value-display.util';
import { DevicesService } from '../../../../devices/services/devices.service';
import { format } from 'date-fns';

export interface GroupLabelContent {
  groupValue?: string;
  view: ViewApi;
}

export interface PropertyState {
  item: string;
  state?: boolean;
}

export interface DevicePropertyFilter {
  type: string;
  label: string;
  deviceProperties: PropertyState[];
}

export interface CalendarConfiguration {
  event: CalendarConfigurationEvent;
  value: any;
}

export enum CalendarConfigurationEvent {
  DISPLAY_MODE = 'DISPLAY_MODE',
  MY_EVENTS = 'MY_EVENTS',
  HIGHLIGHT_WEEKENDS = 'HIGHLIGHT_WEEKENDS',
  CUSTOM_DEVICE_LINK = 'CUSTOM_DEVICE_LINK',
  EXTEND_DEVICE_DETAILS = 'EXTEND_DEVICE_DETAILS'
}

export type LabelInfo = GroupLabelContent & { el: HTMLElement };

@Injectable()
export class CalendarWidgetService implements OnDestroy {
  deviceTypes: DeviceTypeDefinition[];

  private deviceTypeSub: Subscription;
  private subs = new Subscription();

  private calendarApiRerender = new Subject<boolean>();
  triggerCalendarApiRerender = this.calendarApiRerender.asObservable();

  private calendarConfigurationChange = new Subject<CalendarConfiguration>();
  calendarConfiguration = this.calendarConfigurationChange.asObservable();

  private filterTriggerChange = new Subject<FilterTrigger>();
  filterTrigger = this.filterTriggerChange.asObservable();

  constructor(
    private componentResolver: ComponentResolver,
    private deviceTypesService: DeviceTypesService,
    private hyperlinkService: HyperlinkService,
    private languagesService: LanguagesService,
    private localStorageService: LocalStorageService,
    public translate: TranslateService,
    private projectsService: ProjectsService,
    private devicesService: DevicesService
  ) {
    this.subs.add(
      this.projectsService.projectConfigEvents.subscribe((event) => {
        if (event?.config) {
          this.loadDeviceTypes();
        }
      })
    );
  }

  private loadDeviceTypes() {
    if (this.deviceTypeSub) {
      this.deviceTypeSub.unsubscribe();
    }
    this.deviceTypeSub = this.deviceTypesService.getDeviceTypes().subscribe((deviceTypes) => {
      this.deviceTypes = deviceTypes;
    });
  }

  ngOnDestroy(): void {
    this.deviceTypeSub.unsubscribe();
    this.subs.unsubscribe();
  }

  setFilterTrigger(filterTrigger: FilterTrigger) {
    this.filterTriggerChange.next(filterTrigger);
  }

  setCalendarConfiguration(calendarConfiguration: CalendarConfiguration) {
    this.calendarConfigurationChange.next(calendarConfiguration);
  }

  setCalendarApiRenderState(state: boolean) {
    this.calendarApiRerender.next(state);
  }

  getCalendarDeviceDetailsFilterFromLocalStorage(): DevicePropertyFilter[] {
    return this.localStorageService.get('calendarDeviceDetailsFilter');
  }

  createResourceHeaderLayout(
    things: Device[],
    typeDefinitions: DeviceTypeDefinition[],
    { deviceDetailsConfig }: CalendarWidgetConfig,
    showDeviceDetails: boolean
  ) {
    const htmlElement = document.createElement('div');
    const resourceTitle = document.createElement('span');
    resourceTitle.classList.add('d-inline-block');
    resourceTitle.innerText = this.translate.instant('calendar.resources');
    htmlElement.classList.add('d-flex', 'justify-content-between', 'align-items-center', 'w-100');
    htmlElement.appendChild(resourceTitle);

    if (showDeviceDetails && deviceDetailsConfig) {
      const deviceGroup = this.groupDeviceResourceHeader(
        typeDefinitions,
        deviceDetailsConfig,
        things
      );

      if (deviceGroup?.length) {
        const deviceDetails = this.componentResolver.instantiateComponent(
          DeviceDetailsFilterComponent,
          (component) => {
            component.deviceGroup = deviceGroup;
          }
        );
        htmlElement.appendChild(deviceDetails);
      }
    } else {
      this.localStorageService.remove('calendarDeviceDetailsFilter');
    }
    return { domNodes: [htmlElement] };
  }

  getBookableDeviceTypes(): Observable<DeviceTypeDefinition[]> {
    return this.deviceTypesService.getDeviceTypesByBookingState([
      BookableStatus.BOOKABLE,
      BookableStatus.BOOKABLE_WITH_CONFIRMATION
    ]);
  }

  getDevicesMatchingFilterTextInDetails(config: {
    filterText: string;
    isShowDeviceDetails: boolean;
    devices: Device[];
    calendarConfig: CalendarWidgetConfig;
  }): Device[] {
    if (!config.isShowDeviceDetails) {
      return [];
    }

    const allDevices = [...config.devices];
    const words = splitStringOnWhitespace(config.filterText);

    return allDevices.filter((device) =>
      words.every((word) => this.deviceDetailsContainFilterWord(device, config, word))
    );
  }

  deviceDetailsContainFilterWord(device: Device, config: any, filterWord: string) {
    const visibleProperties = this.resolveVisibleDeviceProperties(
      device,
      this.deviceTypes.find((dt) => dt.type === device.getType()),
      config.calendarConfig.deviceDetailsConfig
    ).filter((property) => property.key !== 'link');

    return visibleProperties.some((property) => {
      let propertyValue = property.value?.toString().toLowerCase() ?? '';

      if (property.type === 'boolean' && !propertyValue) {
        propertyValue = 'false';
      }

      return propertyValue.includes(filterWord);
    });
  }

  getDevicesMatchingFilterTextInName(filterText: string, devices: Device[]) {
    const words = splitStringOnWhitespace(filterText);

    return devices.filter((device) => {
      const deviceName = device.getName().toLowerCase();
      return words.some((word) => deviceName.includes(word));
    });
  }

  filterMatchingDevices(
    commandBarFilter: CommandbarFilter,
    devices: Device[],
    calendarConfig: CalendarWidgetConfig
  ) {
    const filterTextAsLowerCase = commandBarFilter.bookingFilterText.toLowerCase();

    const calendarFilterContext = {
      filterText: filterTextAsLowerCase,
      isShowDeviceDetails: commandBarFilter.showDeviceDetails,
      devices,
      calendarConfig
    };

    const filterWords = splitStringOnWhitespace(filterTextAsLowerCase);

    return devices.filter((device) =>
      this.deviceContainsAllFilterWords(device, calendarFilterContext, filterWords)
    );
  }

  deviceContainsAllFilterWords(device: Device, calendarFilterContext: any, filterWords: string[]) {
    return filterWords.every(
      (word) =>
        this.deviceNameContainsFilterWord(device, word) ||
        this.deviceDetailsContainFilterWord(device, calendarFilterContext, word)
    );
  }

  deviceNameContainsFilterWord(device: Device, filterWord: string): boolean {
    return device.getName().toLowerCase().includes(filterWord);
  }

  createDeviceViewDetails(
    device: Device,
    { defaultDeviceTypeUrl, deviceTypeUrls, deviceDetailsConfig }: CalendarWidgetConfig
  ): DeviceViewDetails {
    return {
      thingId: device.thingId,
      linkDefinition: this.resolveDeviceLink(device, defaultDeviceTypeUrl, deviceTypeUrls),
      visiblePropertyEntries: this.resolveVisibleDeviceProperties(
        device,
        this.deviceTypes.find((dt) => dt.type === device.getType()),
        deviceDetailsConfig
      ).filter((filterDevice) =>
        isDevicePropertyFilterEnabled(
          device.getType(),
          filterDevice.key,
          this.getCalendarDeviceDetailsFilterFromLocalStorage()
        )
      )
    };
  }

  resolveDeviceLink(
    device: Device,
    defaultUrl: string,
    typeUrl: Record<string, string> = {}
  ): LinkDefinition {
    const deviceUrl =
      typeUrl[device.getType()] || defaultUrl || this.hyperlinkService.createDeviceLink(device);
    return this.hyperlinkService.resolveLink(
      { label: device.getName(), path: deviceUrl, target: HyperlinkTarget.SELF },
      device
    );
  }

  resolveVisibleDeviceProperties(
    device: Device,
    deviceType: DeviceTypeDefinition,
    deviceDetailsConfig: Record<string, DeviceProperty[]>
  ): PropertyEntry[] {
    const visiblePropertyEntries: PropertyEntry[] = [];
    const defaultPropertyEntries = [
      {
        id: 'image',
        key: 'image',
        value: 'visible',
        type: 'text',
        description: null,
        regexPattern: '',
        selectionOptions: []
      }
    ];
    if (deviceDetailsConfig && deviceDetailsConfig[deviceType?.type]) {
      const deviceTypePropertyEntries = {};
      deviceType.features.forEach((feature) => {
        deviceTypePropertyEntries[feature.defaultName] = getPropertyEntries(
          feature.customProperties,
          device.getFeatureProperties(feature.definition)
        );
      });

      deviceDetailsConfig[deviceType.type].forEach(({ propertyName, featureDefaultName }) => {
        const propertyEntry = deviceTypePropertyEntries[featureDefaultName].find(
          (entry) => entry.key === propertyName
        );

        if (propertyEntry) {
          visiblePropertyEntries.push(propertyEntry);
        }
      });
    }

    return [...visiblePropertyEntries, ...defaultPropertyEntries];
  }

  getDeviceDetailsPropertiesHTMLElements(
    device: Device,
    deviceDetailsConfig: Record<string, DeviceProperty[]>
  ): HTMLDivElement {
    const deviceProperties = document.createElement('div');

    deviceProperties.style.display = 'grid';
    deviceProperties.style.gridTemplateColumns = 'max-content max-content';

    const enabledVisibleProperties = this.resolveVisibleDeviceProperties(
      device,
      this.deviceTypes.find((dt) => dt.type === device.getType()),
      deviceDetailsConfig
    );

    const visibleNonImageProperties: PropertyEntry[] = (enabledVisibleProperties || []).filter(
      (filterDevice) =>
        isDevicePropertyFilterEnabled(
          device.getType(),
          filterDevice.key,
          this.getCalendarDeviceDetailsFilterFromLocalStorage()
        ) && filterDevice.key !== 'image'
    );

    loadAndResolveDeviceNamesForDeviceLinkProperties(
      visibleNonImageProperties,
      deviceProperties,
      this.devicesService
    ).subscribe();

    visibleNonImageProperties.forEach((property: PropertyEntry) => {
      const labelComponent = document.createElement('div');
      labelComponent.innerText = property.key;
      labelComponent.classList.add(
        'value-label',
        'd-flex',
        'align-items-center',
        'p-0',
        'pr-2',
        'mr-3'
      );

      const valueComponent = getPropertyValueDisplayElementByType(
        property,
        this.languagesService.currentLanguage,
        this.devicesService
      );
      valueComponent.classList.add('pl-2');
      valueComponent.style.setProperty('overflow-wrap', 'break-word');
      valueComponent.style.setProperty('font-size', '12px');

      deviceProperties.appendChild(labelComponent);
      deviceProperties.appendChild(valueComponent);
    });

    return deviceProperties;
  }

  formatEventTime(start: Date, end: Date, timeZoneOffset = 0): string {
    const timeFormat = 'HH:mm dd MMM';

    if (start) {
      const localStart = new Date(start);
      localStart.setMinutes(localStart.getMinutes() - timeZoneOffset);

      if (end) {
        const localEnd = new Date(end);
        localEnd.setMinutes(localEnd.getMinutes() - timeZoneOffset);

        return format(localStart, timeFormat) + ' - ' + format(localEnd, timeFormat);
      }

      return format(localStart, timeFormat);
    }

    return '';
  }

  private getTranslatedDeviceLabel(deviceType: DeviceTypeDefinition): string {
    const currentLang = this.languagesService.currentLanguage;
    const fallbackLang = Object.keys(this.languagesService.languages)
      .filter((lang) => lang !== currentLang)
      .join('');

    return deviceType.label[currentLang] ?? deviceType.label[fallbackLang];
  }

  private groupDeviceResourceHeader(
    typeDefinitions: DeviceTypeDefinition[],
    deviceDetailsConfig: Record<string, DeviceProperty[]>,
    things: Device[]
  ): DevicePropertyFilter[] {
    const deviceGroup: DevicePropertyFilter[] = [];

    if (typeDefinitions?.length) {
      things?.forEach((device: Device) => {
        const deviceType = typeDefinitions.find((dt) => dt.type === device.getType());
        const visibleProperties = this.resolveVisibleDeviceProperties(
          device,
          deviceType,
          deviceDetailsConfig
        ).map((property) => ({
          item: property.key,
          state: true
        }));

        const labelDevice = this.getTranslatedDeviceLabel(deviceType);
        const hasDevice = deviceGroup.some((el) => el.type === device.getType());
        if (hasDevice) {
          if (deviceGroup['deviceProperties']) {
            visibleProperties.forEach((property) => {
              if (!deviceGroup.some((el) => el.deviceProperties.includes(property))) {
                deviceGroup[deviceType?.type]['deviceProperties'].push({
                  item: property,
                  state: true
                });
              }
            });
          }
        } else {
          deviceGroup.push({
            label: labelDevice,
            type: device.getType(),
            deviceProperties: visibleProperties
          });
        }
      });
    }
    return deviceGroup;
  }
}
