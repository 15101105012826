import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SlateModule } from 'slate-angular';
import { ColorPickerModule } from '../../shared-modules/color-picker/color-picker.module';
import { SlateRichtextComponent } from './slate-richtext.component';
import { TextMarkComponent } from './components/text-mark/text-mark.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { ElementImageComponent } from './components/element-image/element-image.component';
import {
  CalloutModule,
  DropdownModule,
  FormFieldsModule
} from '@inst-iot/bosch-angular-ui-components';
import { SlateEditorComponent } from './slate-editor/slate-editor.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared.module';
import { LinkPopoverComponent } from './components/link-popover/link-popover.component';
import { ReferenceSuggestionListComponent } from './components/reference-suggestion-list/reference-suggestion-list.component';
import { ImagePropertiesEditorComponent } from './components/image-properties-editor/image-properties-editor.component';
import { DataSelectionModule } from '../../shared-modules/data-selection/data-selection.module';
import { ImageDimensionEditorComponent } from './components/image-properties-editor/image-dimension-editor/image-dimension-editor.component';
import { ValueFormattingModule } from '../../shared-modules/value-formatting/value-formatting.module';
import { ReferenceConfigurationComponent } from './components/reference-configuration/reference-configuration.component';
import { ResizableDirective } from './directives/resizable.directive';
import { TableSelectorComponent } from './components/table-selector/table-selector.component';
import { TablePopoverComponent } from './components/table-popover/table-popover.component';
import { TableMouseEventsDirective } from './directives/table-mouse-events.directive';
import { PopoverContentPipe } from './pipes/popover-content.pipe';
import { TooltipContentPipe } from './pipes/tooltip-content.pipe';
import { TooltipLeafComponent } from './components/array-tooltip-leaf/tooltip-leaf.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SlateModule,
    ColorPickerModule,
    DropdownModule,
    TranslateModule,
    CalloutModule,
    FormFieldsModule,
    SharedModule,
    DataSelectionModule,
    ValueFormattingModule
  ],
  declarations: [
    SlateRichtextComponent,
    TextMarkComponent,
    ToolbarComponent,
    ElementImageComponent,
    SlateEditorComponent,
    LinkPopoverComponent,
    ReferenceSuggestionListComponent,
    ImagePropertiesEditorComponent,
    ImageDimensionEditorComponent,
    TablePopoverComponent,
    ReferenceConfigurationComponent,
    TableSelectorComponent,
    ResizableDirective,
    TableMouseEventsDirective,
    PopoverContentPipe,
    TooltipContentPipe,
    TooltipLeafComponent
  ],
  exports: [SlateRichtextComponent, SlateEditorComponent]
})
export class SlateRichtextModule {}
