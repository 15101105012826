import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { QueryTemplateParameter } from '../../../../shared/api-model';

import { createDefaultValueByType } from '../../models/query-parameter-converter';

@Component({
  selector: 'map-value',
  templateUrl: './map-value.component.html'
})
export class MapValueComponent implements OnInit {
  @Input() label: string | TemplateRef<any>;

  @Input() parameter: QueryTemplateParameter;

  @Output() valueChange = new EventEmitter<any>();

  @Input() value: any;

  list = [];

  ngOnInit() {
    if (!this.value) {
      this.value = {};
    }
    this.list = Object.keys(this.value).map((key) => this.createEntry(key));
  }

  createEntry(key) {
    let editKey = key;
    return {
      valueHolder: this,
      get key() {
        return editKey;
      },
      updateKey(k: string) {
        console.log('update key', k);
        if (editKey !== k) {
          this.valueHolder.value[k] = this.valueHolder.value[editKey];
          delete this.valueHolder.value[editKey];
        }
        editKey = k;
      },
      get value() {
        return this.valueHolder.value[editKey];
      },
      set value(v: any) {
        this.valueHolder.value[editKey] = v;
        this.valueHolder.valueChange.next(this.valueHolder.value);
      }
    };
  }

  add() {
    const v = createDefaultValueByType(this.parameter.dataType);
    const count = Object.keys(this.value).length;
    let key = '';
    if (this.value[''] !== undefined) {
      key = 'key' + count;
    }
    this.value[key] = v;
    this.list.push(this.createEntry(key));
    this.valueChange.next(this.value);
  }

  remove(i) {
    const entry = this.list[i];
    this.list.splice(i, 1);
    delete this.value[entry.key];
    this.valueChange.next(this.value);
  }

  isLabelTemplate() {
    return this.label instanceof TemplateRef;
  }
}
