<div class="main-bg">
  <div class="container text-center">
    <h1 class="mt-3 componentHeader u-TextColor--red" *ngIf="error !== 'sessionTimeout'">
      {{ 'error.error' | translate }}
    </h1>
    <h1 class="mt-3 componentHeader u-TextColor--fuchsia" *ngIf="error === 'sessionTimeout'">
      {{ 'error.sessionTimeoutTitle' | translate }}
    </h1>

    <p class="subline-scaled">
      {{ errorDetail }}
    </p>

    <p>
      {{ 'error.code' | translate }}:
      <strong>{{ error }}</strong>
      <br />
      {{ 'error.time' | translate }}: {{ timeStamp }}
    </p>

    <p>
      <a [routerLink]="routing.home" class="rb-btn">{{ 'generalConfig.backToHome' | translate }}</a>
    </p>
  </div>
</div>
