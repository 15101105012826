import { CustomFeatureProperty, SelectionOption } from '../../models/device-types';
import { isEmpty } from 'lodash-es';
import { TranslatedString } from '../../../shared-projects/models/project.model';

export interface PropertyEntry {
  id: string;
  key: string;
  value: any;
  type: string;
  description: TranslatedString;
  regexPattern: string;
  selectionOptions: SelectionOption[];
  allowedDeviceType?: string;
  multipleValuesAllowed?: boolean;
  customValuesAllowed?: boolean;
  icon?: string;
}

export function getPropertyEntries(
  customProperties: CustomFeatureProperty[],
  properties: any
): PropertyEntry[] {
  const propertyList = [];
  const usedPropNames = [];
  if (customProperties) {
    customProperties.forEach((prop) => {
      propertyList.push({
        key: prop.name,
        value: properties?.[prop.name] !== undefined ? properties[prop.name] : '',
        type: prop.type,
        description: prop.description,
        icon: prop.icon,
        regexPattern: prop.regexPattern,
        selectionOptions: prop.selectOptions,
        allowedDeviceType: prop.allowedDeviceType,
        multipleValuesAllowed: prop.multipleValuesAllowed,
        customValuesAllowed: prop.customValuesAllowed
      });
      usedPropNames.push(prop.name);
    });
  }
  if (properties && !isEmpty(properties)) {
    Object.keys(properties).forEach((name) => {
      if (!usedPropNames.includes(name)) {
        propertyList.push({
          key: name,
          value: properties[name],
          type: 'text',
          description: null,
          regexPattern: '',
          selectionOptions: null
        });
      }
    });
  }

  return propertyList;
}
