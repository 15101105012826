import { ParamMap } from '@angular/router';
import { DataType, ParameterType } from '../../../shared-query/services/query.service';
import { parseIfJSON } from '../../query-condition-input/utils/query-assembler.util';

function identity(value) {
  return value;
}

function parseNumber(value) {
  const num = parseFloat(value);
  if (isNaN(num)) {
    return 0;
  }
  return num;
}

function toString(value) {
  return value ? value.toString() : '';
}

function toJSON(value) {
  try {
    return JSON.parse(value);
  } catch (e) {
    return {};
  }
}

function parseDate(value) {
  const date = new Date(value);
  if (isNaN(date.getTime())) {
    return new Date().toISOString();
  } else {
    return date.toISOString();
  }
}

function stringifyJson(value) {
  return JSON.stringify(value);
}

const dataTypeMigrations = {
  BOOLEAN_JSON: toJSON,
  BOOLEAN_STRING: toString,

  JSON_BOOLEAN(value) {
    return value === 'true';
  },
  JSON_FLOAT: parseNumber,
  JSON_INT(value) {
    return Math.floor(parseNumber(value));
  },
  JSON_STRING: stringifyJson,
  JSON_TIMESTAMP: parseDate,

  FLOAT_JSON: toJSON,
  FLOAT_INT(value) {
    return parseInt(value, 10);
  },
  FLOAT_STRING: toString,

  INT_JSON: toJSON,
  INT_FLOAT: identity,
  INT_STRING: toString,

  STRING_BOOLEAN(value) {
    return value === 'true';
  },
  STRING_JSON: toJSON,
  STRING_FLOAT: parseNumber,
  STRING_INT(value) {
    return Math.floor(parseNumber(value));
  },
  STRING_TIMESTAMP: parseDate,

  TIMESTAMP_JSON: toJSON,
  TIMESTAMP_STRING: toString
};

const parameterTypeMigrations = {
  SCALAR_RANGE(value) {
    return {
      from: value,
      to: value
    };
  },
  SCALAR_LIST(value) {
    return [value];
  },
  SCALAR_MAP(value) {
    return { '': value };
  },
  RANGE_SCALAR(value) {
    return value.from;
  },
  RANGE_LIST(value) {
    return [value.from];
  },
  RANGE_MAP(value) {
    return { '': value.from };
  },
  LIST_SCALAR(value) {
    if (value.length) {
      return value[0];
    }
    return undefined;
  },
  LIST_RANGE(value) {
    if (value.length >= 2) {
      return {
        from: value[0],
        to: value[1]
      };
    }
    if (value.length) {
      return {
        from: value[0],
        to: value[0]
      };
    }
    return undefined;
  },
  LIST_MAP(value) {
    const result = {};
    for (let i = 0; i < value.length; i++) {
      const key = i === 0 ? '' : 'key' + i;
      result[key] = value[i];
    }
    return result;
  },
  MAP_SCALAR(value) {
    const keys = Object.keys(value);
    if (keys.length) {
      return value[keys[0]];
    }
    return undefined;
  },
  MAP_RANGE(value) {
    const keys = Object.keys(value);
    if (keys.length >= 2) {
      return {
        from: value[keys[0]],
        to: value[keys[1]]
      };
    }
    if (keys.length) {
      return {
        from: value[keys[0]],
        to: value[keys[0]]
      };
    }
    return undefined;
  },
  MAP_LIST(value) {
    const keys = Object.keys(value);
    const result = [];
    for (const key of keys) {
      result.push(value[key]);
    }
    return result;
  }
};

/**
 * Converts the value from an old parameter type to a new one.
 * If no conversion is possible, the default value will be returned.
 * @param value
 * @param dataType
 * @param oldType
 * @param newType
 */
export function convertParameterType(
  value: any,
  dataType: DataType,
  oldType: ParameterType,
  newType: ParameterType
) {
  const conversionKey = oldType + '_' + newType;
  let result;
  if (parameterTypeMigrations[conversionKey]) {
    result = parameterTypeMigrations[conversionKey](value);
  }
  if (result === undefined) {
    result = createDefaultValue(dataType, newType);
  }
  return result;
}

export function convertDataTypeForParameterValue(
  value: any,
  oldType: DataType,
  newType: DataType,
  paramType: ParameterType
) {
  if (value === null) {
    return null;
  }
  if (paramType === 'SCALAR') {
    return convertDataType(value, oldType, newType);
  }
  if (paramType === 'RANGE' && value) {
    const range = {
      from: convertDataType(value.from, oldType, newType),
      to: convertDataType(value.to, oldType, newType)
    };
    return range.from !== null && range.to !== null ? range : null;
  }
  if (paramType === 'LIST' && Array.isArray(value)) {
    return value.map((v) => convertDataType(v, oldType, newType));
  }
  if (paramType === 'MAP' && value) {
    const keys = Object.keys(value);
    keys.forEach((key) => {
      value[key] = convertDataType(value[key], oldType, newType);
    });
    return value;
  }
  return null;
}

export function convertDataType(value: any, oldType: DataType, newType: DataType) {
  if (oldType === newType) {
    return value;
  }
  const conversionKey = oldType + '_' + newType;
  let result;
  if (dataTypeMigrations[conversionKey]) {
    result = dataTypeMigrations[conversionKey](value);
  }
  if (result === undefined) {
    result = createDefaultValueByType(newType);
  }
  return result;
}

export function createDefaultValue(dataType: DataType, parameterType: ParameterType) {
  if (parameterType === 'SCALAR') {
    return createDefaultValueByType(dataType);
  }
  if (parameterType === 'RANGE') {
    return {
      from: createDefaultValueByType(dataType, true),
      to: createDefaultValueByType(dataType)
    };
  }
  if (parameterType === 'LIST') {
    return [];
  }
  if (parameterType === 'MAP') {
    return {};
  }
  if (parameterType === 'QUERY') {
    return {};
  }
  return undefined;
}

export function createDefaultValueByType(dataType: DataType, past = false) {
  if (dataType === 'TIMESTAMP') {
    if (past) {
      return new Date(new Date().getTime() - 86400000 * 7).toISOString();
    }
    return new Date().toISOString();
  }
  if (dataType === 'BOOLEAN') {
    return false;
  }
  if (dataType === 'INT' || dataType === 'FLOAT') {
    return 0;
  }
  if (dataType === 'JSON') {
    return {};
  }
  return '';
}

export function getParameterValueFromQuery(
  name: string,
  dataType: DataType,
  parameterType: ParameterType,
  query: ParamMap
) {
  if (parameterType === 'SCALAR') {
    return getSingleParameterValueFromQuery(name, dataType, query);
  }
  if (parameterType === 'RANGE') {
    const result = {
      from: getSingleParameterValueFromQuery(name + 'From', dataType, query),
      to: getSingleParameterValueFromQuery(name + 'To', dataType, query)
    };
    if (result.from === undefined || result.to === undefined) {
      return undefined;
    }
    return result;
  }
  if (parameterType === 'LIST') {
    return query.getAll(name).map((value) => convertDataType(value, 'STRING', dataType));
  }
  if (parameterType === 'MAP') {
    const result = {};
    query.keys
      .filter((key) => key.startsWith(name + '.'))
      .forEach((key) => {
        const entryKey = key.split('.')[1];
        result[entryKey] = getSingleParameterValueFromQuery(key, dataType, query);
      });
    return result;
  }
  if (parameterType === 'QUERY') {
    const result = {};
    if (query.has(name)) {
      const json = parseIfJSON(query.get(name));
      return typeof json === 'string' ? result : json;
    }
    return result;
  }
  return undefined;
}

function getSingleParameterValueFromQuery(name: string, dataType: DataType, query: ParamMap) {
  if (!query.has(name)) {
    return undefined;
  }
  const value = query.get(name);
  return convertDataType(value, 'STRING', dataType);
}

export function getUrlQueryParameter(
  name: string,
  dataType: string,
  parameterType: string,
  value: any
): string[] {
  if (parameterType === 'SCALAR') {
    return [name + '=' + encodeURIComponent(value)];
  }
  if (parameterType === 'RANGE') {
    return [
      name + 'From=' + encodeURIComponent(value.from),
      name + 'To=' + encodeURIComponent(value.to)
    ];
  }
  if (parameterType === 'LIST' && Array.isArray(value)) {
    return value.map((v) => name + '=' + encodeURIComponent(v));
  }
  if (parameterType === 'MAP' && value) {
    return Object.keys(value).map((key) => name + '.' + key + '=' + encodeURIComponent(value[key]));
  }
  return [];
}
