import { ProjectsService } from '../../shared-projects/services/projects.service';
import { WidgetVisibility } from './widget-registry.model';

export enum LayoutBehavior {
  Fixed = 'fixed',
  Adapt = 'adapt'
}

export class DashboardWidgetConfig {
  // widgetId
  id: string;

  columns: number;
  rows: number;

  // roles for which the widget is shown or hidden, depending on what is selected in the "visibility" property
  selectedRoles?: string[];

  // visibility state of the widget. Use "isActive()" to check if the widget is visible for a users
  visibility: WidgetVisibility;

  // if widgets is inside of a tab or not
  insideTab: boolean;

  type: string;
  properties: Record<string, any>;
  layoutBehavior?: LayoutBehavior;
  positionX?: number;
  positionY?: number;

  constructor(widget: Partial<DashboardWidgetConfig> = {}) {
    const {
      id = null,
      type = null,
      columns = 12,
      rows = null,
      positionX = 0,
      positionY = 0,
      properties = {
        title: { en: '' },
        lazyLoading: true
      },
      layoutBehavior = null,
      insideTab = false,
      visibility = 'VISIBLE',
      selectedRoles = []
    } = widget;

    this.id = id;
    this.type = type;
    this.columns = columns;
    this.rows = rows;
    this.positionX = positionX;
    this.positionY = positionY;
    this.properties = properties;
    this.layoutBehavior = layoutBehavior;
    this.insideTab = insideTab;
    this.visibility = visibility;
    this.selectedRoles = selectedRoles;

    return this;
  }

  /**
   * Determines the visibility of the widget or tab based on its configuration.
   *
   * - `VISIBLE_FOR_ROLES`: The widget is visible if at least one of the `selectedRoles` matches the current project's roles.
   * - `HIDDEN_FOR_ROLES`: The widget is hidden if any of the `selectedRoles` matches the current project's roles.
   * - `VISIBLE`: The widget is always visible.
   * - `HIDDEN`: The widget is always hidden.
   * If none of these conditions are met, the widget is considered visible by default.
   *
   * @returns {boolean} True if the widget is visible based on the above conditions, false otherwise.
   */
  static isVisible(
    visibility: WidgetVisibility,
    selectedRoles: string[],
    projectsService: ProjectsService
  ): boolean {
    if (visibility === 'VISIBLE_FOR_ROLES') {
      return projectsService.hasAnySpecificRole(selectedRoles);
    } else if (visibility === 'HIDDEN_FOR_ROLES') {
      return !projectsService.hasAnySpecificRole(selectedRoles);
    } else if (visibility === 'VISIBLE') {
      return true;
    } else if (visibility === 'HIDDEN') {
      return false;
    } else {
      return true;
    }
  }
}
