import { DataSource, dataSourceTypes, removeWhiteSpaceRegex } from './data-source-base';
import { DataSourceType, DsParameterValue } from './models';
import { assignWithDefaults } from '../model-utils';
import { ValidationErrors } from '@angular/forms';
import { WidgetContext } from '../widget-context';
import { Observable, of, throwError } from 'rxjs';
import { translate } from '../../../../../shared/translation-util';
import { Injector } from '@angular/core';
import { DashboardDataService } from '../../../../../dashboards/services/dashboard-data.service';
import { DataLoaderRegistryService } from '../../services/data-loader-registry.service';
import { ThingDataSource } from './thing';
import { map, switchMap } from 'rxjs/operators';
import { buildThingsFilter } from '../filter-utils';
import { resolvePlaceholders, resolveStringWithModifiers } from '../placeholder-utils';
import { DeviceTypesService } from '../../../../../devices/services/device-types.service';
import { DeviceTypeDefinition } from '../../../../../devices/models/device-types';

export class FilterDevicesDataSource extends DataSource {
  type: DataSourceType = 'filterDevices';
  label = 'Devices from Filter Selection';

  limit = '200';
  sort = '+thingId';

  // Custom Pre-Filter Things Layer
  filter: DsParameterValue = null;

  constructor(props?: Partial<FilterDevicesDataSource>) {
    super(props);
    assignWithDefaults(this, props);
  }

  validate(): ValidationErrors | null {
    return null;
  }

  loadData(ctx: WidgetContext): Observable<any[]> {
    const dashboardDataService = ctx.injector.get(DashboardDataService, null);
    const deviceTypeService = initDeviceTypeService(ctx);

    if (!dashboardDataService || !dashboardDataService.getWidget('filter')) {
      return throwError('No filter widget available.');
    }

    const filterProperties = dashboardDataService.getWidget('filter').properties;
    const deviceTypeName = filterProperties?.filterConfig?.deviceType;
    const getFilterDeviceType: Observable<DeviceTypeDefinition> = deviceTypeName
      ? deviceTypeService.getDeviceType(deviceTypeName)
      : of(null);

    return getFilterDeviceType.pipe(
      switchMap((deviceTypeDef) => {
        return this.getThingsSource(filterProperties, ctx, deviceTypeDef);
      })
    );
  }

  getThingsSource(
    filterProperties: Record<string, any>,
    ctx: WidgetContext,
    deviceTypeDef: DeviceTypeDefinition
  ) {
    const devicesService = ThingDataSource.initDevicesService(ctx);
    const registryService = ctx.injector.get(DataLoaderRegistryService, null);
    const deviceType = deviceTypeDef;

    const customFilter = this.filter?.value
      ? this.filter.value.replace(removeWhiteSpaceRegex, '')
      : null;
    const resolvedCustomFilter = resolvePlaceholders(
      customFilter,
      0,
      resolveStringWithModifiers,
      ctx
    );
    const extendedFilterProperties = deviceType
      ? { ...filterProperties, deviceType: deviceType }
      : filterProperties;
    const filter = buildThingsFilter(extendedFilterProperties, ctx, resolvedCustomFilter);
    const size = this.limit ? parseInt(this.limit, 0) : 200;
    const sort = this.sort ? this.sort : '+thingId';

    const params = `size(${size})sort(${sort})${filter})`;
    const source = devicesService
      .getDevices(filter, null, size, sort, true)
      .pipe(map((result) => result.items));

    return registryService
      ? source.pipe(registryService.registerDataSource(params, ctx.priority))
      : source;
  }
}

dataSourceTypes.push({
  name: 'filterDevices',
  i18nLabel: translate('dataSourceConfig.filterDevices'),
  constructor: FilterDevicesDataSource,
  isAvailable: isDataSourceAvailable
});

function isDataSourceAvailable(injector: Injector): boolean {
  const dashboardService = injector.get(DashboardDataService, null);
  return dashboardService && !!dashboardService.getWidget('filter');
}

function initDeviceTypeService(ctx: WidgetContext) {
  let deviceTypeService = ctx.injector.get(DeviceTypesService, null);
  if (!deviceTypeService) {
    const injector = Injector.create({
      parent: ctx.injector,
      providers: [{ provide: DeviceTypesService }]
    });
    deviceTypeService = injector.get(DeviceTypesService);
  }

  return deviceTypeService;
}
