<div class="container-fluid">
  <h1 class="mt-3" translate>userInfo.title</h1>

  <p>
    {{ 'userInfo.userId' | translate }}:
    <strong>{{ userId }}</strong>
  </p>
  <p>
    {{ 'userInfo.username' | translate }}:
    <strong>{{ userName }}</strong>
  </p>
  <p>
    {{ 'userInfo.email' | translate }}:
    <strong>{{ userEmail }}</strong>
  </p>
  <p>
    {{ 'userInfo.provider' | translate }}:
    <strong>{{ provider }}</strong>
  </p>
  <p>
    {{ 'userInfo.identityProvider' | translate }}:
    <strong>{{ identityProviderLabel | translate }}</strong>
  </p>

  <p translate>userInfo.followingRoles</p>

  <ul class="mb-3 pl-0">
    <li *ngFor="let sysRole of systemRoles">
      <span [ngSwitch]="sysRole" [title]="sysRole">
        <ng-container *ngSwitchCase="systemRole.admin">System admin</ng-container>
        <ng-container *ngSwitchCase="systemRole.manager">System manager</ng-container>
        <ng-container *ngSwitchDefault>{{ sysRole }}</ng-container>
      </span>
    </li>
  </ul>

  <table data-cy="userInfoTableRoles" class="table table-sm">
    <thead>
      <tr>
        <th translate>userInfo.project</th>
        <th translate>userInfo.roles</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let info of projects">
        <td [attr.data-cy]="'userInfoProject-' + info.project">{{ info.project }}</td>
        <td [attr.data-cy]="'userInfoProjectRoles-' + info.project">
          {{ info.roles.join(',  ') }}
        </td>
      </tr>
    </tbody>
  </table>

  <button type="button" (click)="login()" class="rb-btn">
    {{ 'nav.user.refreshSession' | translate }}
  </button>
</div>
