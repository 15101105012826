import { DeviceTypeDefinition } from '../../../../devices/models/device-types';
import {
  CalendarWidgetService,
  DevicePropertyFilter,
  PropertyState
} from './calendar-widget.service';
import { TranslateService } from '@ngx-translate/core';
import { translate } from '../../../../shared/translation-util';
import { Device } from '../../../../devices/models/device';
import { flatten } from 'lodash-es';
import { CalendarWidgetConfig } from '../models/calendar-widget-config';
import { LinkDefinition } from '../../../../shared-modules/data-widgets/data-widgets-common/lib/hyperlink.service';
import { getDeviceTypeIcon } from '../../../../shared-modules/devices-common/device.utils';
import { TemplateRef, ViewContainerRef } from '@angular/core';

/*
 * Resource Content Layout in Calendar Timeline View
 *  _______________________________________________________________________________________
 * |__ Collapsable Device Type Group Header ______________________________________________/
 *  _________      _______________________________________________________________________
 * |DeviceImg|    |DeviceDetailsElement                                                   |
 * |         |    | <Device Name link to Device Overview Page>                            |
 * |  <img>  |    |    <Device Feature Property, when Device Details Option is active>    |
 * |_________|    |____<Device Feature Property, when Device Details Option is active>____|
 *
 * DeviceImg is composed by composeDeviceImageElement()
 * DeviceDetailsElement is composed by composeDeviceDetailsElement()
 * */

export function composeDeviceImageElement(
  device: Device,
  deviceTypes: DeviceTypeDefinition[],
  deviceImageTemplate: TemplateRef<any>,
  viewContainerRef: ViewContainerRef
): HTMLDivElement {
  const imageFrame = document.createElement('div');
  imageFrame.classList.add('cropped-framed-img', 'my-2', 'mr-3');
  let deviceImageIcon: HTMLImageElement | HTMLSpanElement;

  if (device?.hasDeviceImageProperty()) {
    // Dynamically Load Device Image Template with NgLazyLoading Directive
    const embeddedView = viewContainerRef.createEmbeddedView(deviceImageTemplate, {
      imageSrc: device.getImageUrl(null, 64)
    });
    deviceImageIcon = embeddedView.rootNodes[0];
  } else {
    // Load default device type icon
    deviceImageIcon = document.createElement('span');
    deviceImageIcon.classList.add('device-img', 'rb-ic', getDeviceTypeIcon(device, deviceTypes));
    deviceImageIcon.style.setProperty('font-size', '45px');
    deviceImageIcon.style.setProperty('max-height', '45px');
    deviceImageIcon.style.setProperty('max-width', '45px');
  }

  imageFrame.appendChild(deviceImageIcon);

  return imageFrame;
}

export function composeDeviceDetailsElement(
  title: string,
  device: Device,
  calendarWidgetConfig: CalendarWidgetConfig,
  showDeviceDetails: boolean,
  calendarWidgetService: CalendarWidgetService
): HTMLDivElement {
  const deviceContainer = document.createElement('div');
  if (device?.thingId && device?.getType()) {
    const deviceLink = createDeviceLinkElement(
      device,
      calendarWidgetConfig,
      calendarWidgetService,
      title
    );
    deviceContainer.appendChild(deviceLink);
  } else {
    deviceContainer.classList.add('font-weight-bold');
    deviceContainer.innerText = title;
  }

  if (showDeviceDetails) {
    const deviceDetailsContainer = calendarWidgetService.getDeviceDetailsPropertiesHTMLElements(
      device,
      calendarWidgetConfig.deviceDetailsConfig
    );
    deviceContainer.append(deviceDetailsContainer);
  }

  return deviceContainer;
}

/*
 * Resource Group Label
 *
 * */

export function createResourceDeviceTypeGroupLabel(
  deviceTypeGroup: string,
  deviceTypesFrequencyCount: Record<string, number>,
  deviceTypeDefinitions: DeviceTypeDefinition[],
  currentLanguage: string,
  translateService: TranslateService
): Record<'domNodes', HTMLElement[]> {
  const deviceTypeGroupLabel = deviceTypeGroup;
  const deviceTypeLabel = deviceTypeDefinitions?.find(
    (dt) => dt.type === deviceTypeGroupLabel
  )?.label;

  const fallbackLang = 'en';

  const label =
    deviceTypeLabel?.[currentLanguage] ?? deviceTypeLabel?.[fallbackLang] ?? deviceTypeGroupLabel;

  const groupLabelEl = document.createElement('span');

  if (deviceTypeGroupLabel) {
    groupLabelEl.innerText = `${label} (${deviceTypesFrequencyCount[deviceTypeGroupLabel]})`;
  } else {
    groupLabelEl.innerText = translateService.instant(translate('calendar.notFound'));
  }
  groupLabelEl.classList.add('group-label-content', 'pl-1');

  return { domNodes: [groupLabelEl] };
}

/*
 * Further Utilities
 * */

function createDeviceLinkElement(
  device: Device,
  calendarConfig: CalendarWidgetConfig,
  calendarWidgetService: CalendarWidgetService,
  title: string
): HTMLAnchorElement {
  const deviceLink: LinkDefinition = calendarWidgetService.resolveDeviceLink(
    device,
    calendarConfig.defaultDeviceTypeUrl,
    calendarConfig.deviceTypeUrls
  );

  const deviceNameLink = document.createElement('a');
  deviceNameLink.innerText = title;
  deviceNameLink.href = deviceLink.fullUrl;
  deviceNameLink.classList.add('mb-1', 'font-weight-bold');
  deviceNameLink.setAttribute('data-cy', 'deviceNameLink');
  return deviceNameLink;
}

export function calculateValueFrequency(resources: any[]): Record<string, number> {
  return resources.reduce((acc, resource) => {
    const type = resource.extendedProps.type;

    if (acc[type]) {
      acc[type] += 1;
    } else {
      acc[type] = 1;
    }

    return acc;
  }, {});
}

export function isDevicePropertyFilterEnabled(
  deviceType: string,
  name: string,
  localStorageFilter: DevicePropertyFilter[]
): boolean {
  if (localStorageFilter) {
    const isDeviceTypeExist = localStorageFilter.some(
      (device) => device.type?.toLowerCase() === deviceType?.toLowerCase()
    );
    const filteredDeviceProperties = localStorageFilter
      .filter((device) => device.type?.toLowerCase() === deviceType?.toLowerCase())
      .map((device) => device.deviceProperties);
    if (isDeviceTypeExist && flatten(filteredDeviceProperties)?.length) {
      return filteredDeviceProperties.some((property: PropertyState[]) =>
        property.some((prop) => prop.item?.toLowerCase() === name?.toLowerCase() && prop?.state)
      );
    }
  }
  return true;
}
