import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AggregationPreviewComponent } from './aggregation-preview/aggregation-preview.component';
import { JsonEditorComponent } from './json-editor/json-editor.component';
import { CsvSettingNameDialogComponent } from './download-dialog/user-csv-setting/csv-setting-name-dialog.component';
import { DownloadDialogDirective } from './download-dialog/download-dialog.directive';
import { DownloadDialogComponent } from './download-dialog/download-dialog.component';
import { SharedModule } from '../../shared/shared.module';
import { CsvSettingNameValidatorDirective } from './download-dialog/user-csv-setting/csv-setting-name-validator.directive';
import { TableViewComponent } from './table-view/table-view.component';
import { ValueViewComponent } from './value-view/value-view.component';
import { ExceptionStacktraceComponent } from './exception-stacktrace/exception-stacktrace.component';
import { RouterModule } from '@angular/router';
import { TableFilterComponent } from './table-view/table-filter/table-filter.component';
import { TableFilterBarComponent } from './table-view/table-filter-bar/table-filter-bar.component';
import { ActionButtonsModule } from '../action-buttons/action-buttons.module';
import { AceModule } from 'ngx-ace-wrapper';
import { DevicesCommonModule } from '../devices-common/devices-common.module';
import { TableImageDisplayComponent } from './table-view/table-image-display/table-image-display.component';
import { DataConditionalFormattingModule } from '../data-conditional-formatting/data-conditional-formatting.module';
import { QueryInfoDialogComponent } from './query-info-dialog/query-info-dialog.component';
import { SlateRichtextModule } from '../../shared/slate-richtext/slate-richtext.module';
import { DataConditionalFormattingPipe } from '../data-conditional-formatting/data-conditional-formatting.pipe';
import { CustomColumnFixedDirective } from './table-view/custom-column-fixed.directive';
import { TableArrayDisplayComponent } from './table-view/table-array-display/table-array-display.component';
import { TooltipContentPipe } from './table-view/pipes/tooltip-content.pipe';
import { TableViewWidgetPlaceholderModule } from '../../dashboards/widgets/table-view-widget/table-view-widget-placeholder/table-view-widget-placeholder.component.module';
import { ChipDirective } from './table-view/directives/chip.directive';

@NgModule({
  imports: [
    CommonModule,
    AceModule,
    SharedModule,
    RouterModule,
    ActionButtonsModule,
    DevicesCommonModule,
    DataConditionalFormattingModule,
    SlateRichtextModule,
    TableViewWidgetPlaceholderModule
  ],
  declarations: [
    AggregationPreviewComponent,
    JsonEditorComponent,
    CsvSettingNameDialogComponent,
    DownloadDialogComponent,
    DownloadDialogDirective,
    CsvSettingNameValidatorDirective,
    TableViewComponent,
    CustomColumnFixedDirective,
    ValueViewComponent,
    ExceptionStacktraceComponent,
    TableFilterComponent,
    TableFilterBarComponent,
    TableImageDisplayComponent,
    QueryInfoDialogComponent,
    TableArrayDisplayComponent,
    TooltipContentPipe,
    ChipDirective
  ],
  exports: [
    AggregationPreviewComponent,
    JsonEditorComponent,
    DownloadDialogComponent,
    DownloadDialogDirective,
    TableViewComponent,
    ValueViewComponent,
    ExceptionStacktraceComponent,
    QueryInfoDialogComponent
  ],
  providers: [DataConditionalFormattingPipe]
})
export class DataInspectionModule {}
